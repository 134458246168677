import { memo } from "react";
import DynamicSwitchOptionFixedFields from "./DynamicSwitchOptionFixedFields";
import EditExpandButtons from "../EditExpandButtons";
import DrugDeltaIcon from "../DrugDeltaIcon";
import Card from "react-bootstrap/Card";
import CheckField from "../../../commons/fields/CheckField";
import { useParams } from "react-router-dom";

const DrugOptionBoxHeader = ({
  medName = "",
  medForm = "",
  showDetails = false,
  showDelta = false,
  deltaClassName = "",
  isEditMode = false,
  handleChange = () => { },
  handleBlur = () => { },
  isActive = undefined,
  dynamicSwitchOptionId = undefined,
  alternativeDrugNames = undefined,
  brandGenType = undefined,
  itemType = undefined,
  routeOfAdministration = "",
  estimatedCost = 0,
  gpiCode = "",
  isPharmacistReviewRequired = true,
  toggleShowDetails = () => { },
  allowEditing = undefined,
  handleSubmit = (e) => { },
  handleReset = (e) => { },
  handleEnableEditMode = () => { },
  refreshList = undefined,
  dynamicSwitchGroupId = undefined,
  workingCodexVersionId = undefined,
  isMultiTargets = false,
  toggleMultiTargets = () => { },
  showMultiTargets = false,
  dynamicSwitchItems = [],
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const enableEditing = !searchParams.has('showDisplayCodexBar');


  return (
    <Card.Title
      key={`${medName} ${medForm}`}
      className={!showDetails ? "no-margin" : ""}
    >
      <span>
        {showDelta ? (
          <DrugDeltaIcon showDelta={true} className={deltaClassName} />
        ) : (
          <CheckField
            title={"Active"}
            isEditMode={isEditMode}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={isActive}
            compKey={"drug-option-form-is-enabled-for-fax"}
            id={"isActive"}
            key={`drug-option-form-is-enabled-for-fax-${dynamicSwitchOptionId}`}
          />
        )}
      </span>
      <DynamicSwitchOptionFixedFields
        showDetails={showDetails}
        key={`drug-option-fixed-fields-${dynamicSwitchOptionId}`}
        alternativeDrugNames={alternativeDrugNames}
        medName={medName}
        medForm={medForm}
        brandGenType={brandGenType}
        itemType={itemType}
        routeOfAdministration={routeOfAdministration}
        estimatedCost={estimatedCost}
        gpiCode={gpiCode}
        isPharmacistReviewRequired={isPharmacistReviewRequired}
        dynamicSwitchItems={dynamicSwitchItems}
      />
      <div className="pull-right drug-title-link-container">
        <EditExpandButtons
          showDetails={showDetails}
          toggleShowDetails={toggleShowDetails}
          allowEditing={allowEditing && enableEditing}
          isEditMode={isEditMode}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          handleEnableEditMode={handleEnableEditMode}
          onSuccessDelete={refreshList}
          dsoId={dynamicSwitchOptionId}
          name={medName}
          dsgId={dynamicSwitchGroupId}
          codexId={workingCodexVersionId}
          isMultiTargets={isMultiTargets}
          toggleMultiTargets={toggleMultiTargets}
          isMultiTargetsDisplayed={showMultiTargets}
          key={`drug-option-form-buttons-${dynamicSwitchOptionId}`}
        />
      </div>
    </Card.Title>
  );
};

export default memo(DrugOptionBoxHeader);
