import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const submitDsg = (dsgDto, codexVersionId) => {

    return {
      [RSAA]: {
  
        endpoint: `${config.apiRoot}/cat/codex-version/${codexVersionId}/dynamic-switch-groups/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken()
        },
        body: JSON.stringify(dsgDto),
        types: [
          'DSG_SUBMIT_REQUEST',
          'DSG_SUBMIT_SUCCESS',
          'DSG_SUBMIT_FAILURE'
        ]
      }
    };
  };
  