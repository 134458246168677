import React from "react";
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import ResetListState from "components/ResetListState";
import { MdRefresh } from "react-icons/md";
import "./DisplayBar.css";
import "assets/css/components.css";

const DisplayBar = ({
  dataTestId,
  tableTitle,
  totalRecords,
  refreshIcon = false,
  refresh,
  reset,
  externalLinks,
}) => {
  return (
    <div className="display-bar">
      <Row>
        <Col md={12}>
          <Row className="codex-version-display-bar">
            <Col md={4} className="my-auto">
              <span style={{ float: "left" }}>
                {" "}
                Total {externalLinks ? "" : tableTitle}:{" "}
                <strong id="callgroup-total-count" data-testid={dataTestId}>
                  {totalRecords}
                </strong>
              </span>
            </Col>
            <Col md={4} className="my-auto">
              <span>
                <strong style={{ float: "center" }}>{tableTitle}</strong>
              </span>
            </Col>
            {refreshIcon ? (
              <Col md={3} className="my-auto">
                <OverlayTrigger
                  overlay={<Tooltip>Refresh Page and Activity List</Tooltip>}
                >
                  <div className="aw-refresh-link">
                    <MdRefresh
                      onClick={() => {
                        refresh();
                      }}
                    />
                    <span
                      style={{ fontSize: "0.50em" }}
                      data-testid="btn-refresh-page"
                    >
                      Refresh Page
                    </span>
                  </div>
                </OverlayTrigger>
              </Col>
            ) : null}
            {reset ? (
              <Col md={!refreshIcon ? 4 : 1} className="my-auto">
                <span className="tiny-bottom-spacer">
                  <ResetListState
                    onReset={() => reset()}
                    // resetterDisabled={
                    //   JSON.stringify(listState.filtered) === JSON.stringify(defaultFilters) &&
                    //     JSON.stringify(listState.sorted) === JSON.stringify(defaultSorts)
                    // }
                  />
                </span>
              </Col>
            ) : null}
            {externalLinks ? (
              <Col md={4}>
                <ButtonGroup
                  className="pull-right"
                  aria-label="External Links Group"
                >
                  {externalLinks.map((link, idx) => {
                    return (
                      <Button
                        key={link.url}
                        href={link.url}
                        target="_blank"
                        variant="light"
                      >
                        {link.title}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default DisplayBar;
