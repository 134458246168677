import { useHistory, withRouter } from "react-router-dom";
import EntityDetails from "components/SwitchDetails/EntityDetails";
import { formatDateForDisplay } from "services/MomentUtils";
import DismissableModal from "components/DismissableModal";
import SecurePdfViewer from "components/SecurePdfViewer";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import { useRef } from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Button, Col, Row, Badge } from "react-bootstrap";
import "assets/css/agentworkspace.css";

const LeftBar = (props) => {
  const {
    activityDetails,
    isDisabled,
    isInAW,
    showPrescriberColumn,
    callGroupData,
  } = props;
  const securePdfModalRef = useRef("redactedPdfViewerModal");


  const history = useHistory();
  const handleNavigation = (activity) => {
    if (activity === AgentWorkspaceConstants.PREDECESSOR) {
      history.push({
        pathname: `/agent-workspace/call-group-details/${activityDetails.predecessorActivity
            ? activityDetails.predecessorActivity.callGroupId
            : ""
          }`,
        state: {
          status: activityDetails.predecessorActivity
            ? activityDetails.predecessorActivity.status
            : "OPEN",
          navigation: AgentWorkspaceConstants.PREDECESSOR,
        },
      });
    } else if (activity === AgentWorkspaceConstants.SUCCESSOR) {
      history.push({
        pathname: `/agent-workspace/call-group-details/${activityDetails.successorActivity
            ? activityDetails.successorActivity.callGroupId
            : ""
          }`,
        state: {
          status: activityDetails.successorActivity
            ? activityDetails.successorActivity.status
            : "OPEN",
          navigation: AgentWorkspaceConstants.SUCCESSOR,
        },
      });
    }
    props.handleActivityNavigation();
  };

  return (
    <>
      <div className="entity-font">
        <EntityDetails
          prescriber={
            activityDetails.wfItem.switchInfo.prescriberInfo
              ? activityDetails.wfItem.switchInfo.prescriberInfo
              : {}
          }
          pharmacy={
            activityDetails.wfItem.switchInfo.pharmaInfo
              ? activityDetails.wfItem.switchInfo.pharmaInfo
              : {}
          }
          patient={
            activityDetails.wfItem.switchInfo.rirMemberInfo
              ? activityDetails.wfItem.switchInfo.rirMemberInfo
              : {}
          }
          globalSwitchId={
            activityDetails.wfItem.switchInfo.globalSwitchId
              ? activityDetails.wfItem.switchInfo.globalSwitchId
              : null
          }
          updateData={props.updateData.bind(this)}
          wfItemId={activityDetails.wfItem.wfItemId}
          isDisabled={isDisabled}
          isInAW={isInAW}
        />
        <div className="boxed-div-blue">
          <div className="boxed-div-center">
            <DismissableModal
              ref={securePdfModalRef}
              openModalButtonText="Redacted Pdf"
              openModalButtonBlock={true}
              openModalButtonStyle="primary"
              openModalButtonClassName="btn-block"
              title="Redacted Pdf"
              size="lg"
            >
              {
                <SecurePdfViewer
                  displayToolBar={false}
                  url={`/pdf/${activityDetails.wfItem.switchInfo.rirCustomerInfo.customerId}/prescriber-inbound-or-generated-redacted?switch_id=${activityDetails.wfItem.switchInfo.rirSwitchId}`}
                />
              }
            </DismissableModal>
          </div>
          <div className="boxed-div-center">
            <Badge bg="warning" size="lg">
              {activityDetails.switchType ? activityDetails.switchType : 'N/A'}
            </Badge>
          </div>
          <div className="boxed-div-center">
            <div>
              <strong>Last Fax date: </strong>
              {activityDetails.lastFaxSentAt ? formatDateForDisplay(activityDetails.lastFaxSentAt) : 'N/A'}
            </div>
          </div>
          <div className="boxed-div-center">
            <Row>
              <Col md={6}>
                <div>
                  Predecessor
                  <Button
                    size="md"
                    className="btn-block"
                    variant="primary"
                    disabled={activityDetails.predecessorActivity === null}
                    onClick={handleNavigation.bind(
                      this,
                      AgentWorkspaceConstants.PREDECESSOR
                    )}
                  >
                    <FaArrowLeft />
                  </Button>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  Successor
                  <Button
                    size="md"
                    className="btn-block"
                    variant="primary"
                    disabled={activityDetails.successorActivity === null}
                    onClick={handleNavigation.bind(
                      this,
                      AgentWorkspaceConstants.SUCCESSOR
                    )}
                  >
                    <FaArrowRight />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="boxed-div-center">
            <div>
              RazorMetrics Fax #: {AgentWorkspaceConstants.MainFaxNumber}
            </div>
            <div>
              Alternate Fax #: {AgentWorkspaceConstants.AlternateFaxNumber}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(LeftBar);
