import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';
import { dateFormatter } from 'services/UtilityService';

export const getMemberDetailNotes = (memberId) => {
    return {
      [RSAA]: {
        endpoint: `${config.apiRoot}/member/${memberId}/member-notes`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
        types: [
          'GET_MEMBER_DETAIL_NOTE_REQUEST',
          'GET_MEMBER_DETAIL_NOTE_SUCCESS',
          'GET_MEMBER_DETAIL_NOTE_FAILURE'
        ]
      }
    };
  }