import { getLoggedInUser } from "services/AuthService";
import ROLES from "rbac/Roles.json";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import { isCollectionNullOrEmpty } from "./UtilityService";

export function isLoggedInUserAWAdmin() {
    return getLoggedInUser().authorities.includes(ROLES.AW_ADMIN);
}

export function isLoggedInUserAWUser() {
    return getLoggedInUser().authorities.some(authority => [ROLES.AW_CALL_ACTIVITY, ROLES.AW_TASK_ACTIVITY, ROLES.AW_ADMIN].includes(authority));
}

export function isOwnedByLoggedInUser(callGroupData) {
    return callGroupData != null ? callGroupData.claimerUuid === getLoggedInUser().uuid : false;
}

export function isOwnedByAnyone(callGroupData) {
    return (
      callGroupData.claimerName !== null
    );
  }

export function goBackIfCallGroupClosed(data, history) {
    const inProgressData = data.filter(x => AgentWorkspaceConstants.IN_PROGRESS_STATUSES.includes(x.status));
    if (inProgressData.length <= 1) {
        redirectToListingPage(history);
    }
}

export function redirectToListingPage(history) {
    if (history.length > 2) {
        history.goBack();
    }
    else {
        history.push(`/agent-workspace/list`);
    }
}

export function extractStatusFilterForDetailsPage(status) {
    if(status instanceof Array && (isCollectionNullOrEmpty(status) || status.every(s => s === "ALL"))) {
        return null;
    }
    else {
        return status;
    }
}