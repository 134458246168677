import React from "react";
import Landing from "views/Landing/Landing";
import Header from "../Header/Header";
import { withRouter } from "react-router-dom";

const DashboardDetails = (props) => {
  const dashboardId = props.match.params.dashboardId;

  return (
    <>
      <Header dashboardId={dashboardId}></Header>
      <Landing isSourceSWIM={false} dashboardId={dashboardId} />
    </>
  );
};

export default withRouter(DashboardDetails);
