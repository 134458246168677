import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { getRoutes } from "../../../routes/DashboardRoutes";

import "./Sidebar.css";
import colors from "lookup/Colors";
import { useRef } from "react";

const sideBarStyle = {
  backgroundColor: colors["rm-blue"],
};

const Sidebar = (props) => {
  const prevProps = useRef(props);
  const [currentPath, setCurrentPath] = useState(props.location.pathname);
  const [routes] = useState(getRoutes());

  useEffect(() => {
    const currPathNameValue = props.location.pathname;
    const prevPathNameValue = prevProps.current.location.pathname;
    if (typeof currPathNameValue !== "undefined" && prevPathNameValue !== currPathNameValue) {
      setCurrentPath(currPathNameValue);
    }
    prevProps.current = props;
  }, [props]);

  const navigate = (path) => {
    props.history.push(path);
  };

  const isCurrentPath = (route) => {
    return currentPath === route;
  };
  const isAtStartOfCurrentPath = (route) => {
    return currentPath.startsWith(route);
  };

  const childRouteActive = (route) => {
    let isChildActive = false;

    if (route.path && isCurrentPath(route.path)) isChildActive = true;

    if (!isChildActive && route.subRoutes) {
      route.subRoutes.forEach((sr) => {
        if (isCurrentPath(sr.path)) {
          isChildActive = true;
        }
      });
    }

    if (!isChildActive && isAtStartOfCurrentPath(route.basePath))
      isChildActive = true;

    return isChildActive;
  };

  const activeClass = (path) =>
    isCurrentPath(path) ? "rm-nav-link-active" : "";
  const rootOrChildActiveClass = (route) =>
    isCurrentPath(route.path)
      ? "rm-nav-link-active"
      : childRouteActive(route)
      ? "rm-root-has-child-active"
      : "";
  const normalizeName = (name, type, i) =>
    name ? name.toLowerCase().split(" ").join("") : `missingname${type}-${i}`;
  return !routes ? null : (
    <div className="rm-sidebar" style={sideBarStyle}>
      {routes.map((route, idx) => {
        if (route.redirect || route.excludeFromNavigation) return null;
        if (!route.subRoutes) {
          return (
            <div
              id={`parent-${normalizeName(route.name, "parent", idx)}`}
              className={`rm-nav-link ${activeClass(route.path)}`}
              onClick={() => navigate(route.path)}
              key={idx}
            >
              <div className="rm-nav-link-title">{route.name}</div>
            </div>
          );
        } else {
          return (
            <div key={idx}>
              <div
                id={`parent-${normalizeName(route.name, "parent", idx)}`}
                className={`rm-nav-link rm-nav-parent ${rootOrChildActiveClass(
                  route
                )}`}
                onClick={() => {
                  let routeToNavigateTo;
                  if (route.path && route.component)
                    routeToNavigateTo = route.path;
                  else routeToNavigateTo = route.subRoutes[0].path;
                  navigate(routeToNavigateTo);
                }}
              >
                <div className="rm-nav-link-title">{route.name}</div>
              </div>
              <div
                className={`rm-sidebar-subsection ${
                  childRouteActive(route) ? "" : "rm-hidden"
                }`}
              >
                {route.subRoutes.map((subRoute, sidx) => {
                  if (subRoute.excludeFromNavigation) return null;
                  else {
                    return (
                      <div
                        id={`child-${normalizeName(
                          subRoute.name,
                          "child",
                          sidx
                        )}`}
                        className={`rm-nav-link ${activeClass(subRoute.path)}`}
                        onClick={() => navigate(subRoute.path)}
                        key={sidx}
                      >
                        <div className="rm-nav-link-title">{subRoute.name}</div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default withRouter(Sidebar);
