import { createSlice } from '@reduxjs/toolkit';
import { unlinkDeclinedFaxRequestAction, unlinkDeclinedFaxSuccessAction, unlinkDeclinedFaxFailureAction } from "./UnlinkDeclinedFax.actions"


const initialState = {
    isSubmitting: false,
    isSuccessful: false
}


export const unlinkDeclinedFaxSlice = createSlice({
    name: "unlinkDeclinedFax",
    initialState: initialState,
    reducers: {
        unlinkDeclinedFaxRequest: (state, action) => {
            state.isSubmitting = true;
            state.isSuccessful = false;
        },
        unlinkDeclinedFaxSuccess: (state, action) => {
            state.isSubmitting = false;
            state.isSuccessful = true;
        },
        unlinkDeclinedFaxFailure: (state, action) => {
            state.isSubmitting = false;
        },
        resetDeclinedFax: (state, action) => {
            state.isSubmitting = false;
            state.isSuccessful = false;
        }

    },
    extraReducers: (builder) => {
        builder
            // Handle the pending, fulfilled, and rejected states of the "PAW_GROUP" RSAA action.
            .addCase(unlinkDeclinedFaxRequestAction, (state, action) => {
                unlinkDeclinedFaxSlice.caseReducers.unlinkDeclinedFaxRequest(state, action);
            })
            .addCase(unlinkDeclinedFaxSuccessAction, (state, action) => {
                unlinkDeclinedFaxSlice.caseReducers.unlinkDeclinedFaxSuccess(state, action);
            })
            .addCase(unlinkDeclinedFaxFailureAction, (state, action) => {
                unlinkDeclinedFaxSlice.caseReducers.unlinkDeclinedFaxFailure(state, action);
            });
    }
});


export const { unlinkDeclinedFaxRequest, unlinkDeclinedFaxSuccess, resetDeclinedFax } = unlinkDeclinedFaxSlice.actions;
export const unlinkDeclinedFaxState = (state) => state.unlinkDeclinedFax;
export default unlinkDeclinedFaxSlice.reducer;