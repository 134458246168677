import React from "react";
import { withRouter } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  SWITCH_NUMBER_STRING,
  SWITCH_NUMBER_OP,
  SWITCH_STATE_OP,
} from "lookup/GlobalConstants";
import {
  SWIM_STATES_FOR_SELECT,
  DEFAULT_SWIM_SORT,
  SWIM_FILTER,
  DEFAULT_FILTER,
} from "lookup/SwimConstants";
import { showNotification } from "services/NotificationService";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import { createColumnListForSwitchList } from "./CreateColumnListForSwitchList";

const Tip = (props) => (
  <div style={{ textAlign: "center", color: "#888", fontSize: "0.9em" }}>
    <em>{props.children}</em>
  </div>
);

const Resize = (col) => (
  <div style={{ textAlign: "center", fontSize: "0.9em" }}>{col}</div>
);

const DEFAULT_PAGE_SIZE = 15;

// updateData
// selectable
class SwitchListCommon extends React.Component {
  state = {
    columnList: createColumnListForSwitchList(
      this.props.columnList,
      this.props.isSortFilterEnabledForCust
    ),
  };

  updateData = (listOptions, preventUpdate) => {
    if (!preventUpdate) {
      listOptions.filtered = listOptions.filtered.filter(
        (element) => element.value.length > 0
      );
      if (listOptions.sorted !== DEFAULT_SWIM_SORT) {
        listOptions.sorted.forEach((element, i) => {
          element.isDefault = false;
        });
      }

      const index = listOptions.filtered.findIndex(
        (element) => element.id === "switchNumber"
      );
      if (index !== -1) {
        listOptions.filtered[index].operator = SWITCH_NUMBER_OP;
        listOptions.filtered[index].id = SWITCH_NUMBER_STRING;
      }

      const filtered = listOptions.filtered.filter(
        (element) =>
          !(element.id === "state" && element.value === DEFAULT_FILTER)
      );

      if (filtered.length !== listOptions.filtered.length) {
        filtered.push(SWIM_FILTER.defualtFrontEnd);
        listOptions.filtered = filtered;
      }
      const index_state = listOptions.filtered.findIndex(
        (element) => element.id === "state"
      );

      if (index_state !== -1) {
        listOptions.filtered[index_state].value = listOptions.filtered[
          index_state
        ].value.map((value) =>
          typeof value === "string" ? value : value.name
        );
      }

      if (
        listOptions.filtered.filter(
          (element) =>
            element.id === "state" && element.value.includes("DEFAULT_FILTERS")
        ).length > 0
      ) {
        listOptions.filtered[index_state] = SWIM_FILTER.defualtFrontEnd;
      }

      if (index_state !== -1) {
        if (Array.isArray(listOptions.filtered[index_state].value) === false) {
          listOptions.filtered[index_state].operator = SWITCH_STATE_OP;
        }
      }
      if (this.props.updateData) {
        this.props.updateData(listOptions);
      } else {
        this.props.updateInterventionList(
          listOptions.pageSize,
          listOptions.page,
          listOptions.sorted,
          listOptions.filtered,
          listOptions.resized,
          listOptions.expanded
        );
      }
    } else {
      // should have a separate action
      this.setState({ listState: listOptions });
    }
  };

  componentDidMount() {
    this.updateData(this.props.listState);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedClient != this.props.selectedClient) {
      this.updateData(this.props.listState);
    }
    if (
      this.props.isError === true &&
      this.props.isError !== prevProps.isError
    ) {
      showNotification({
        title: "Error Loading Data!",
        message: "You have entered invalid information.",
        position: "bl",
        type: "error",
      });
      this.props.resetError();
    }
  }

  render() {
    const { data, pages, loading, totalElements, listState, isViewable } =
      this.props;
    const index = listState.filtered.findIndex(
      (element) => element.id === SWITCH_NUMBER_STRING
    );
    if (index !== -1) listState.filtered[index].id = "switchNumber";

    const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
      const listState = {
        page: pageNo,
        pageSize: pageSize,
        sorted: sortBy || [],
        filtered: filterValues,
        resized: [],
        expanded: {},
      };
      this.updateData(listState);
    };

    const statusFilterMap = [];
    SWIM_STATES_FOR_SELECT.forEach((state, i) =>
      statusFilterMap.push({ id: state.key, name: state.value })
    );

    return (
      <Row>
        <Col md={12}>
          <ServerSideReactTable
            columnDefination={this.state.columnList}
            data={data}
            currentPage={listState.page}
            sorted={listState.sorted}
            filters={listState.filtered}
            totalPages={pages}
            totalRecords={totalElements == "loading" ? 0 : totalElements}
            loading={loading}
            overrideRowId={"wfItemId"}
            pageSizeOptions={[5, 10, 15, 20, 30, 50]}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            onUpdateTableData={onUpdateTable}
            onRowClick={(row) => {
              if (row) {
                this.props.updateIndexOnList(row.index);
                this.props.history.push(`/switch/${row.original.wfItemId}`);
              }
            }}
            enableMultiRowSelection={!!this.props.toggleSelection}
            addRowSelection={
              isViewable !== undefined && isViewable === true
                ? false
                : !!this.props.toggleSelection
            }
            onRowSelect={this.props.toggleSelection}
            toggleAllRowsSelect={this.props.isSelectAll}
            globalSelected={this.props.selected ? this.props.selected : []}
            resetTableSelection={
              this.props.resetTableSelection
                ? this.props.resetTableSelection
                : null
            }
          />
          <Tip>
            Tip: Hold shift when sorting to multi-sort and disable sort!
          </Tip>
        </Col>
      </Row>
    );
  }
}

export default withRouter(SwitchListCommon);
