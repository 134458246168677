import react from "react";
import {
  Badge,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

export default function InconclusiveBadge(props) {
  const { inconclusiveResult, responseTypeList } = props;
  return inconclusiveResult ? (
    <>
      <br />
      <span id="auto-accept-result">
        Auto Accept Evaluation:
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              <ListGroup>
                {responseTypeList.map((rTL) => (
                  <ListGroupItem key={rTL}>{rTL}</ListGroupItem>
                ))}
              </ListGroup>
            </Tooltip>
          }
        >
          <Badge pill bg="danger" className="ml-1">
            INCONCLUSIVE
          </Badge>
        </OverlayTrigger>
      </span>
    </>
  ) : null;
}
