import React, { useState } from 'react';
import ButtonWithPopover from './ButtonWithPopover';
import Button from 'react-bootstrap/Button';


const PushToPharmacyPopover = (props) => {

    const reference = "pushToPharmacy";
    const values = props.values;

    const [note, setNote] = useState('');

    const handleChange = (event) => {
        setNote(event.target.value);
    };

    const validate = (values, note) => {
        if (note === null || note === '') {
            return "Notes are required!";
        }
    }

    const submitPushToPharmacy = (values) => {
        const validationMessage = validate(values, note);
        if (validationMessage != null) {
            alert(validationMessage);
            return;
        }
        const disposition = {
            disposition: "ACCEPTED",
            selectedTargets: values.targets,
            rejectionReason: values.rejectionReason,
            futureSwitchTreatment: values.futureSwitchTreatment,
            note: {note: note},
            pushToPharmacy: true
        };
        props.submitPushToPharmacy(disposition);
    }

    const textareaStyle = {
        width: '100%',
        resize: 'none',
        boxSizing: 'border-box',
        borderRadius: '0.375rem',
    };

    return (
        <ButtonWithPopover
            placement="top"
            customRef={reference + "bwp"}
            buttonVariant="danger"
            buttonText="Push To Pharmacy"
            buttonIsBlock={true}
            buttonIsDisabled={props.disallowEditing || props.disableActions}
            popoverTitle="Push To Pharmacy"
            buttonId='switchdisposition-btn-pushToPharmacy'
        >
            <div>
                <label><b>Notes:</b></label>
                <textarea
                    value={note}
                    style={textareaStyle}
                    onChange={handleChange}
                    rows={3}
                    placeholder="Notes are required!"
                />
            </div>
            <p className="confirmation-text">
                You are about to manually push the fax to the pharmacy for <b>switch# {props.globalSwitchId}</b>.
                <br /><br />
                Are you sure you want to send the fax?
            </p>
            <div className="align-content-right">
                <Button id='switchdisposition-btn-yes' className="left-spacer" variant="success" onClick={() => submitPushToPharmacy(values)}>
                    Yes
                </Button>
            </div>
        </ButtonWithPopover>
    );
}

export default PushToPharmacyPopover;
