import { createSlice } from '@reduxjs/toolkit';
import { PawConstants } from 'lookup/PawConstants';

const initialState = {
  pawGroups: [],
  totalPages: 0,
  pageSize: 20,
  currentPage:0,
  error: false,
  loading: false,
  totalElements: "Loading",
  sorted:PawConstants.DEFAULT_PAW_GROUP_SORT,
  filtered:[],
  resized: [],
  expanded: {}
}


export const pawGroupSlice = createSlice({
  name: "pawGroup",
  initialState: initialState,
  reducers: {
    getPAWGroupListSuccess: (state, action) => {
      state.pawGroups = action.payload.records;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.page;
      state.pageSize=action.payload.pageSize;
      state.sorted = action.payload.sorted;
      state.filtered=action.payload.filtered;
      state.totalElements=action.payload.totalElements;
      state.error = false;
      state.loading = false;
    },
    getPAWGroupListFailure: (state, action) => {
      state.loading = false;
      state.error = false;
      state.totalElements = "Failed";
    },
    getPAWGroupListLoading: (state, action) => {
      state.loading = true;
      state.totalElements = "Loading";
    }

  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "PAW_GROUP" RSAA action.
      .addCase('GET_PAW_GROUPS_REQUEST', (state, action) => {
        pawGroupSlice.caseReducers.getPAWGroupListLoading(state, action);
      })
      .addCase('GET_PAW_GROUPS_SUCCESS', (state, action) => {
         pawGroupSlice.caseReducers.getPAWGroupListSuccess(state, action);
      })
      .addCase('GET_PAW_GROUPS_FAILURE', (state, action) => {
        pawGroupSlice.caseReducers.getPAWGroupListFailure(state, action);
      });
  }
});


export const { getPAWGroupListSuccess, getPAWGroupListFailure } = pawGroupSlice.actions;
export const pawGroupState = (state) => state.pawGroup;
export default pawGroupSlice.reducer;