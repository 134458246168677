import { DateConstants } from "lookup/DateConstants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatDateForDisplay } from "services/MomentUtils";
import { isCollectionNullOrEmpty } from "services/UtilityService";

export const renderDateCell = (date) => {
  return (
    <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {date}
    </span>
  );
};

export const renderScheduledDateCell = (date, status) => {
  return (
    <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <span style={{ color: DateConstants.statusColors[status] }}>
        &#x25cf; &nbsp;
      </span>
      {date}
    </span>
  );
};

export const renderAllSwitchesCell = (aggregatedSwitchesStr) => {
  let dispSwitches = aggregatedSwitchesStr;
  if (aggregatedSwitchesStr && aggregatedSwitchesStr.length > 12) {
    dispSwitches = aggregatedSwitchesStr.substring(0, 12) + "...";
    return (
      <OverlayTrigger overlay={<Tooltip>{aggregatedSwitchesStr}</Tooltip>}>
        <span
          style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {" "}{dispSwitches}{" "}
        </span>
      </OverlayTrigger>
    );
  }
  return (
    <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {" "}
      {dispSwitches}{" "}
    </span>
  );
};

export const renderScheduledDateCol = (date, days) => {
  if (date) {
    const status = days >= 0 ? "ComingDue" : "PastDue";
    return renderScheduledDateCell(formatDateForDisplay(date), status);
  } else {
    return null;
  }
};

export const renderGeneratedDateCol = (date) => {
  if (date) {
    return renderDateCell(formatDateForDisplay(date));
  }
  return <span className="center">N/A</span>;
};

export const renderAWStatusFilterValue = (filter, row) => {
  const filterObj = filter.filter((e) => e.id === "status")[0];
  const filterValue = !filterObj ? null : filterObj.value;
  if (filterValue instanceof Array && (isCollectionNullOrEmpty(filterValue) || filterValue.length > 1)){
    return row.original.status;
  }
  else if(filterValue[0] === 'ALL' || filterValue === 'ALL'){
    return row.original.containsNewActivities ? 'NEW' : row.original.containsEscalatedActivities ? 'ESCALATED' : row.original.containsHeldActivities ? 'ON_HOLD' : row.original.status;
  }
  else {
    return filterValue;
  }
}

