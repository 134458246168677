import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "services/UtilityService";
import {
  getDrugGroupDetailFailureAction,
  getDrugGroupDetailRequestAction,
  getDrugGroupDetailSuccessAction,
  getDrugGroupSwitchesFailureAction,
  getDrugGroupSwitchesRequestAction,
  getDrugGroupSwitchesSuccessAction,
  getNextDrugGroupDetailRequestAction,
  updateDrugGroupDetailFailureAction,
  updateDrugGroupDetailRequestAction,
  updateDrugGroupDetailSuccessAction,
} from "./DrugGroupDetails.actions";

const initialState = {
  drugGroupDetail: {},
  switch: null,
  totalSwitches: 0,
  error: false,
  fetchDrugGroupError: "",
  loading: false,
  statusUpdate: false,
  currentSwitchPage: 0,
  statusAction: "",
  statusLoader: false,
  isApprovalModalActivated: false,
  switchLoader: false,
  drugDetailLoader: false,
  isSwitchUpdateRequired: false,
};

export const drugGroupDetailSlice = createSlice({
  name: "drugGroupDetail",
  initialState: initialState,

  reducers: {
    getDrugGroupDetailSuccess: (state, action) => {
      state.drugGroupDetail = action.payload;
      state.error = false;
      state.fetchDrugGroupError = "";
      state.drugDetailLoader = false;
    },
    getDrugGroupDetailFailure: (state, action) => {
      state.drugDetailLoader = false;
      state.error = true;
      state.fetchDrugGroupError = getErrorMessage(action.payload.response);
    },
    getDrugGroupDetailLoading: (state, action) => {
      state.drugDetailLoader = true;
      state.error = false;
      state.fetchDrugGroupError = "";
    },
    getNextDrugGroupDetailLoading: (state, action) => {
      state.drugDetailLoader = true;
      state.error = false;
      state.switchLoader = true;
      state.isSwitchUpdateRequired = true;
      state.fetchDrugGroupError = "";
    },
    updateDrugGroupDetailRequest: (state, action) => {
      state.statusLoader = true;
      state.statusAction = "";
      state.error = false;
    },
    RESET_DRUG_GROUP_STATUS: (state, action) => {
      state.statusLoader = false;
      state.statusAction = "";
      state.error = false;
      state.fetchDrugGroupError = "";
      state.isSwitchUpdateRequired = false;
      state.drugDetailLoader = false;
      state.switchLoader = false;
    },
    updateDrugGroupDetailSuccess: (state, action) => {
      state.statusLoader = false;
      state.statusAction = action.payload.status;
    },
    updateDrugGroupDetailFailure: (state, action) => {
      state.statusLoader = false;
      state.statusAction = "error";
      state.error = true;
    },
    getDrugGroupSwitchesRequest: (state, action) => {
      state.switchLoader = true;
      state.isSwitchUpdateRequired = false;
    },
    getDrugGroupSwitchesSuccess: (state, action) => {
      state.switchLoader = false;
      state.totalSwitches = action.payload.totalElements;
      state.switch = action.payload.records[0];
      state.currentSwitchPage = action.payload.page;
    },
    getDrugGroupSwitchesFailure: (state, action) => {
      state.switchLoader = false;
      state.error = false;
    },
    setDrugGroupApprovalModalStatus: (state, action) => {
      state.isApprovalModalActivated = action.payload;
    },
    resetSwitchDetailFetch: (state, action) => {
      state.switchLoader = false;
      state.isSwitchUpdateRequired = false;
    },
    resetDrugGroupDetail: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "DRUG_GROUP" RSAA action.
      .addCase(getDrugGroupDetailRequestAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.getDrugGroupDetailLoading(
          state,
          action
        );
      })
      .addCase(getNextDrugGroupDetailRequestAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.getNextDrugGroupDetailLoading(
          state,
          action
        );
      })
      .addCase(getDrugGroupDetailSuccessAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.getDrugGroupDetailSuccess(
          state,
          action
        );
      })
      .addCase(getDrugGroupDetailFailureAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.getDrugGroupDetailFailure(
          state,
          action
        );
      })
      .addCase(updateDrugGroupDetailRequestAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.updateDrugGroupDetailRequest(
          state,
          action
        );
      })
      .addCase(updateDrugGroupDetailSuccessAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.updateDrugGroupDetailSuccess(
          state,
          action
        );
      })
      .addCase(updateDrugGroupDetailFailureAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.updateDrugGroupDetailFailure(
          state,
          action
        );
      })
      .addCase(getDrugGroupSwitchesRequestAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.getDrugGroupSwitchesRequest(
          state,
          action
        );
      })
      .addCase(getDrugGroupSwitchesFailureAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.getDrugGroupSwitchesFailure(
          state,
          action
        );
      })
      .addCase(getDrugGroupSwitchesSuccessAction, (state, action) => {
        drugGroupDetailSlice.caseReducers.getDrugGroupSwitchesSuccess(
          state,
          action
        );
      });
  },
});

export const {
  getDrugGroupDetailSuccess,
  resetDrugGroupDetail,
  getDrugGroupDetailFailure,
  getDrugGroupDetailLoading,
  RESET_DRUG_GROUP_STATUS,
  setDrugGroupApprovalModalStatus,
  resetSwitchDetailFetch,
} = drugGroupDetailSlice.actions;
export const drugGroupDetailState = (state) => state.drugGroupDetail;
export default drugGroupDetailSlice.reducer;
