import React from "react";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import { FilterTypes, ColumnTypes } from "commons/table/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getMemberSwitchList,
  resetMemberSwitchList,
} from "./MemberSwitchList.actions";
import { allowedSwitchStatusesKeywordsForLinking } from "./MemberSwitchList.constant";
import { Link } from "react-router-dom";
import { hasRoleIn } from "rbac/RbacHelper";
import ROLES from "rbac/Roles.json";

const MemberSwitchList = ({
  setSelectedSwitches = (switchIds) => {},
  values,
  setValues,
}) => {
  const dispatch = useDispatch();
  const memberSwitchListState = useSelector(
    (state) => state.memberSpecificSwitchList
  );
  const memberSwitchLoading = memberSwitchListState.loading;
  const totalRecords = memberSwitchListState.totalElements;
  const memberSwitchList = useSelector(
    (state) => state.memberSpecificSwitchList.data
  );
  const columns = [
    {
      header: "Prescriber Name",
      accessor: "prescriberName",
      accessorKey: "prescriberName",
      width: 60,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Member ID",
      accessor: "memberNumber",
      accessorKey: "memberNumber",
      width: 200,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Member Name",
      accessor: "memberName",
      accessorKey: "memberName",
      width: 250,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Prescriber Phone",
      accessor: "prescriberPhone",
      accessorKey: "prescriberPhone",
      width: 200,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Switch #",
      accessor: "switchNumber",
      accessorKey: "switchNumber",
      width: 200,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SearchableInput,
      customComponent: (value, row) => {
        if (hasRoleIn([ROLES.SWIM_USER])) {
          return (
            <Link
              to={`/switch/${row.original.wfItemId}`}
              className={"column-left"}
            >
              {value}
            </Link>
          );
        } else {
          return value;
        }
      },
    },
    {
      header: "Status",
      accessor: "status",
      accessorKey: "status",
      width: 200,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterReturnKey: true,
      filterType: FilterTypes.SingleSelect,
      filterType: FilterTypes.SearchableInput,
    },
  ];

  React.useEffect(() => {
    return () => {
      dispatch(resetMemberSwitchList());
    };
  }, []);
  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    if (
      filterValues.length == 1 &&
      (filterValues[0].value == "DEFAULT_FILTER" || filterValues[0].isDefault)
    ) {
      dispatch(resetMemberSwitchList());
    } else {
      dispatch(
        getMemberSwitchList(
          pageSize,
          pageNo,
          sortBy ? sortBy : [],
          filterValues
        )
      );
    }
  };

  const onRowSelect = (selectedRows) => {
    setSelectedSwitches(Object.keys(selectedRows), values, setValues);
  };

  const showRowCheckBox = (row) => {
    return allowedSwitchStatusesKeywordsForLinking.some((item) =>
      row.original.status.toLowerCase().includes(item.toLowerCase())
    );
  };

  return (
    <ServerSideReactTable
      columnDefination={columns}
      data={memberSwitchList}
      currentPage={memberSwitchListState.page}
      sorted={memberSwitchListState.sorted}
      filters={memberSwitchListState.filtered}
      totalPages={memberSwitchListState.totalPages}
      totalRecords={totalRecords == "loading" ? 0 : totalRecords}
      loading={memberSwitchLoading}
      enableMultiRowSelection={true}
      onRowSelect={onRowSelect}
      enableRowSelection={(row) => showRowCheckBox(row)}
      overrideRowId={"globalSwitchId"}
      enableAllRowSelect={false}
      addRowSelection={true}
      defaultPageSize={5}
      pageSizeOptions={[5]}
      onUpdateTableData={onUpdateTable}
    />
  );
};

export default MemberSwitchList;
