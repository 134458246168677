export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);
  switch (action.type) {
    case 'DSG_SUBMIT_SUCCESS':
      newState = Object.assign({}, previousState, {
        dynamicSwitchGroupId: action.payload.dynamicSwitchGroupId
      });
      break;
    case 'DSG_SUBMIT_REQUEST':
      newState = Object.assign({}, previousState, {});
      break;
    case 'DSG_SUBMIT_FAILURE':
      newState = Object.assign({}, previousState, {
        groupName: '',
        additionalClassname: '',
        groupClassname: '',
        groupSubClassname: '',
        activeRadarClassname: '',
        notes: '',
        dynamicSwitchGroupId: ''
      });
      break;
    case 'RESET_SUCCESS_MESSAGE_DSG_SUBMISSION':
      newState = Object.assign({}, previousState, {});
      break;
    default:
      break;
  }
  return newState;
}