import { RSAA } from "redux-api-middleware";
import config from "../../../config";
import {
  AUTH_TOKEN_HEADER_KEY,
  getAuthorizationToken,
} from "../../../services/AuthService";
import {
  LINK_SWITCH_REQUEST,
  LINK_SWITCH_SUCCESS,
  LINK_SWITCH_FAILURE,
  DISCARD_FAX_REQUEST,
  DISCARD_FAX_SUCCESS,
  DISCARD_FAX_FAILURE,
  FETCH_UNPROCESSED_FAX_REQUEST,
  FETCH_UNPROCESSED_FAX_SUCCESS,
  FETCH_UNPROCESSED_FAX_FAILURE,
  LINK_REQUEST_STATUS_RESET,
  SAVE_FAX_NOTE_REQUEST,
  SAVE_FAX_NOTE_SUCCESS,
  SAVE_FAX_NOTE_FAILURE,
  UPDATE_FAX_NOTE_REQUEST,
  UPDATE_FAX_NOTE_SUCCESS,
  UPDATE_FAX_NOTE_FAILURE,
  DELETE_FAX_NOTE_REQUEST,
  DELETE_FAX_NOTE_SUCCESS,
  DELETE_FAX_NOTE_FAILURE,
  RESET_SAVE_NOTE_SUCCESS,
  RESET_UPDATE_NOTE_SUCCESS,
  RESET_DELETE_NOTE_SUCCESS,
  RESET_DISCARDED_FAX,
} from "./UnprocessedFaxDetails.constant";
import { resetLinkSwitchRequest } from "./UnprocessedFaxDetails.slice";

export const linkUnprocessedFaxToSwitch =
  (faxId, linkPages) => (dispatch, getState) => {
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/fax/link-fax-page`,
        method: "POST",
        body: JSON.stringify({
          faxInfoId: faxId,
          noOfLinkPages: linkPages.length,
          linkPages,
        }),
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        types: [LINK_SWITCH_REQUEST, LINK_SWITCH_SUCCESS, LINK_SWITCH_FAILURE],
      },
    };
    return dispatch(rsaaObject);
  };

export const saveNote = (faxId, noteObject) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/fax/unprocessed-fax-details/${faxId}/fax-note`,
      method: "POST",
      body: JSON.stringify(noteObject),
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        SAVE_FAX_NOTE_REQUEST,
        SAVE_FAX_NOTE_SUCCESS,
        SAVE_FAX_NOTE_FAILURE,
      ],
    },
  };
};

export const updateNote = (faxId, noteObject) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/fax/unprocessed-fax-details/${faxId}/fax-note`,
      method: "POST",
      body: JSON.stringify(noteObject),
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        UPDATE_FAX_NOTE_REQUEST,
        UPDATE_FAX_NOTE_SUCCESS,
        UPDATE_FAX_NOTE_FAILURE,
      ],
    },
  };
};

export const setPriorityOfUnprocessedFax = (faxId, priority) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/fax/unprocessed-fax-details/fax-priority?fax_info_id=${faxId}`,
      method: "PUT",
      body: priority ? priority : "",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        "UPDATE_FAX_PRIORITY_REQUEST",
        "UPDATE_FAX_PRIORITY_SUCCESS",
        "UPDATE_FAX_PRIORITY_FAILURE",
      ],
    },
  };
};
export const fetchNoteForUnlinkedFax = (faxId) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/fax/unprocessed-fax-details/${faxId}/get-note`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        "GET_FAX_NOTE_REQUEST",
        "GET_FAX_NOTE_SUCCESS",
        "GET_FAX_NOTE_FAILURE",
      ],
    },
  };
};
export const discardFax = (faxInfoId) => (dispatch, getState) => {
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/fax/discard-fax?fax_info_id=${faxInfoId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [DISCARD_FAX_REQUEST, DISCARD_FAX_SUCCESS, DISCARD_FAX_FAILURE],
    },
  };

  return dispatch(rsaaObject);
};

export const deleteNote = (noteId) => (dispatch, getState) => {
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/fax/unprocessed-fax-details/${noteId}/delete-note`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        DELETE_FAX_NOTE_REQUEST,
        DELETE_FAX_NOTE_SUCCESS,
        DELETE_FAX_NOTE_FAILURE,
      ],
    },
  };
  return dispatch(rsaaObject);
};

export const fetchUnprocessedFaxDetails =
  (faxInfoId) => (dispatch, getState) => {
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/fax/unprocessed-fax-details?fax_info_id=${faxInfoId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        types: [
          FETCH_UNPROCESSED_FAX_REQUEST,
          FETCH_UNPROCESSED_FAX_SUCCESS,
          FETCH_UNPROCESSED_FAX_FAILURE,
        ],
      },
    };
    return dispatch(rsaaObject);
  };

export const resetLinkRequestStatus = () => (dispatch, getState) => {
  return dispatch(resetLinkSwitchRequest());
};

export const resetNoteSuccess = () => {
  return {
    type: RESET_SAVE_NOTE_SUCCESS,
  };
};

export const resetUpdateNoteSuccess = () => {
  return {
    type: RESET_UPDATE_NOTE_SUCCESS,
  };
};

export const resetDeleteNoteSuccess = () => {
  return {
    type: RESET_DELETE_NOTE_SUCCESS,
  };
};

export const resetDiscardedFax = () => {
  return {
    type: RESET_DISCARDED_FAX,
  };
};
