
export function Reducer (previousState, action) {
  let newState = Object.assign({}, previousState);
  switch (action.type) {
    case 'FETCH_METRICS_REQUEST':
      newState = Object.assign({}, previousState, {metricsFetcherStatus: 'requested'});
      break;
    case 'FETCH_METRICS_SUCCESS':
      newState = Object.assign({}, previousState, {
        metrics: {...action.payload, requestDateTime: new Date()},
        metricsFetcherStatus: 'success'
      });
      break;
    case 'FETCH_METRICS_FAILURE':
      newState = Object.assign({}, previousState, {metricsFetcherStatus: 'failure'}); 
      break;
    case 'METRICS_FETCHER_STATUS_RESET':
      newState = Object.assign({}, previousState, {metricsFetcherStatus: 'idle'}); 
      break;
    default:
      break;
  }

  return newState;
}