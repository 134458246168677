import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';
import { dateFormatter, isCollectionNullOrEmpty } from 'services/UtilityService';

export const updateSwimSwitchList = (pageSize, page, sorted, filtered, resized, expanded) => {
  const filteredDeepCopy = filterModification(filtered);
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/agent-workspace/swim-switch/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered: filteredDeepCopy }),
      types: [
        {
            type: 'UPDATE_SWIM_SWITCH_LIST_REQUEST',
            payload: { listState: { pageSize, page, sorted, filtered, resized, expanded } }
          },
        'UPDATE_SWIM_SWITCH_LIST_SUCCESS',
        'UPDATE_SWIM_SWITCH_FAILURE'
      ]
    }
  };
};

export const resetError = () => {
    return {
      type: "RESET_ERROR_MESSAGE_SWIM_SWITCH_LIST"
  
    }
};
  
export const updateIndexOnList = (index) => {
    return {
      type: "UPDATE_SWIM_SWITCH_INDEX_ON_LIST",
      indexOnList: index
    }
};

const filterModification = (filtered) => {
    let filteredDeepCopy = _.cloneDeep(filtered);
    filteredDeepCopy.forEach(function (part, index, filteredArray) {
      if (filteredArray[index].id === 'scheduledDate' && filteredArray[index].value != null) {
        filteredArray[index].value[0] = dateFormatter(filteredArray[index].value[0])
        filteredArray[index].value[1] = dateFormatter(filteredArray[index].value[1])
      }
      filteredDeepCopy = filteredDeepCopy.filter(f => !(f.value instanceof Array) || (f.value instanceof Array && !isCollectionNullOrEmpty(f.value)));
    });
    return filteredDeepCopy;
};
