import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const getDrugs = (pageSize, page, sorted, filtered, resized, expanded) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/cat/medispan-drugs/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        {
          type: 'FETCH_ALL_DRUG_LIST',
          payload: { listState: { pageSize, page, sorted, filtered, resized, expanded } }
        },
        'FETCH_ALL_DRUG_LIST_SUCCESS',
        'FETCH_ALL_DRUG_LIST_FAILURE'
      ]
    }
  };
};

export const updateIndexOnList = (index) => {
  return {
    type: "FETCH_ALL_DRUG_LIST_INDEX",
    payload: {index: index}
  }
};

export const resetError = () => {
  return {
    type: "RESET_ERROR_ALL_DRUG_LIST"
  }
};

export const resetMedispanDrugList = () => {
  return {
    type: "RESET_ALL_DRUG_LIST",
  }
}