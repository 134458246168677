import React, { Component } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';


class SwitchTimelineInfo extends Component {

  getTooltipContent(wfItemId) {
    if (wfItemId < 161) {
      return <span id='pre-swim-switch-missing'><strong>Pre-SWIM Switch</strong> <br /> Missing timeline data</span>;
    } else if (wfItemId < 563) {
      return <span id='pre-swim-switch-incomplete'><strong>Pre-SWIM Switch</strong> <br /> Incomplete timeline data</span>;
    } else {
      return <span id='timeline-is-active'>Timeline is active</span>;
    }
  }

  render() {
    return (
      <Card style={{ padding: 10 }}>
        <div id="switchTimelineInfoContainer" style={{ textAlign: 'center' }}>
          <strong>
            <span style={{ fontSize: '1.2em' }}>
              <span id="switchState">{this.props.state}</span> <small >(<span id="switchStatus">{this.props.status}</span>)</small>&nbsp;
              <OverlayTrigger overlay={<Tooltip>{this.getTooltipContent(this.props.wfItemId)}</Tooltip>} placement='right'>
                <Badge bg={this.props.wfItemId >= 563 ? 'info' : 'warning'} pill>?</Badge>
              </OverlayTrigger>
            </span>
          </strong>
          {
            this.props.workflowRestarted ?
              <span>
                <br />
                <Badge bg='success'>Workflow Restarted</Badge>
              </span>
              : null
          }
        </div>
      </Card>
    );
  }
}

export default SwitchTimelineInfo;