import React from 'react';
import Tip from 'components/Tip';
import { CodexVersionStatus } from './CodexDisplay';
import { WrapTextAndCenterAlign } from 'components/WrapText';
import ClientSideReactTable from 'commons/table/ClientSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';

const DEFAULT_PAGE_SIZE = 10;



export default function CodexVersionList(props) {
  const { data, loading, noDataText, selectedCodexVersionId, onSelect } = props;
  data.sort((c1, c2) => c2.version - c1.version);

  const columns = [
    {
      header: 'Created on',
      accessor: 'creationDate',
      accessorKey: 'creationDate',
      columnType: ColumnTypes.DateColumn,
      filterType: FilterTypes.Date,
      enableSorting: true,
      enableColumnFilter: true,
      filterFn:'date'
    },
    {
      header: 'Version',
      accessor: 'version',
      accessorKey: 'version',
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
      filterFn:"number",
      enableSorting: true,
      enableColumnFilter: true,
     },
    {
      header: 'Status',
      accessor: 'status',
      accessorKey: 'status',
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return (<WrapTextAndCenterAlign>{<CodexVersionStatus codexVersionStatus={value} />}</WrapTextAndCenterAlign>);
      },
      filterType: FilterTypes.SearchableInput,
      enableSorting: true,
      enableColumnFilter: true,
    }
  ];

  return (
    <div className="list-container">
      <ClientSideReactTable
        columnDefination={columns}
        data={data}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        addRowSelection={false}
        loading={loading}
        onRowClick={(row)=>{
          onSelect(row ? row.original : row);
        }}
        pageSizeOptions={[5, 10, 15, 20]}
        overrideRowId={'codexVersionId'}
      />
      <Tip>
        Tip: Hold shift when sorting to multi-sort and disable sort!
      </Tip>
    </div>
  );
}
