import { withRouter } from "react-router-dom";
import { getGender } from "services/UtilityService";
import { calculateAge } from "services/MomentUtils";
import { Row, Col, Badge, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { MemberConstants } from "lookup/MemberConstants";
import UpdateMemberInfo from "components/UpdateMemberInfo";

const MemberPhiPanel = (props) => {
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(true);
  const memberInfoId = props.memberInfoId;

  useEffect(() => {
    fetchMember();
  }, []);

  const fetchMember = () => {
    sendAuthenticatedAsyncRequest(
      `/phi/member-info/${memberInfoId}`,
      "GET",
      null,
      (r) => {
        setMember(r.data);
        setLoading(false);
      },
      (r) => {
        setMember({});
        setLoading(false);
      }
    );
  }

  const updateMemberData = (updatedMember) => {
    setMember(updatedMember);
  };

  return (
    <div className="boxed-div-blue">
      {loading ?
        <Row>
          <Col md={12} className="text-center">
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
        :
        <>
          <Row>
            <Col md={12}>
              <div className="entity-heading">{member.memberName} {" "}
                <Badge bg="info" style={{ verticalAlign: "top" }}>
                  {MemberConstants.MEMBER_RELATIONSHIP_MAPPING[member.memberRelationshipToEmployee] ? MemberConstants.MEMBER_RELATIONSHIP_MAPPING[member.memberRelationshipToEmployee] : "Unknown"}
                </Badge>
                <span style={{ float: "right", fontSize: "1.2em", marginTop: "-0.2em" }}>
                  <UpdateMemberInfo
                    patient={{ memberNumber: member.memberUniqueAccountId }}
                    phi={member}
                    isDisabled={false}
                    useIcon={true}
                    globalSwitchId={""}
                    onSubmission={updateMemberData}
                    updateData={() => { }}
                  />
                </span>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="font-weight-bold text-center text-capitalize" style={{fontSize: "1.2em"}}>{member.memberName}</div>
            </Col>
          </Row> */}
          <Row>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Member Id</div>
              <div>{member.memberUniqueAccountId ? member.memberUniqueAccountId : "N/A"}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Time Zone</div>
              <div>{member.timeZone ? member.timeZone : "N/A"}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Gender</div>
              <div>{getGender(member.gender)}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Email</div>
              <div>{member.memberEmail?member.memberEmail:"N/A"}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Phone 1</div>
              <div>{member.mobileNum1?member.mobileNum1:"N/A"}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Phone 2</div>
              <div>{member.mobileNum2?member.mobileNum2:"N/A"}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Phone 3</div>
              <div>{member.mobileNum3?member.mobileNum3:"N/A"}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">D.O.B</div>
              <div>{member.dateOfBirth}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Age</div>
              <div>{calculateAge(member.dateOfBirth)} years</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Client</div>
              <div>{member.memberClientName}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Plan</div>
              <div>{member.planName ? member.planName : "N/A"}</div>
            </Col>
            <Col md={12}>
              <div className="rm-tiny-top-label small-top-spacer">Address</div>
              <div>{member.memberAddr}</div>
            </Col>
          </Row>
        </>
      }
    </div>
  );
};

export default withRouter(MemberPhiPanel);
