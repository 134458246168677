import { useEffect } from "react";
import { ColumnTypes } from "commons/table/Constants";
import { useDispatch, useSelector } from "react-redux";
import EditDashboard from "components/Analytics/EditDashboard";
import DeleteDashboard from "components/Analytics/DeleteDashboard";
import FavoriteDashboard from "components/Analytics/FavoriteDashboard";
import { Container } from "react-bootstrap";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import DisplayBar from "commons/table/DisplayBar/DisplayBar";
import {
  COLUMNS,
  DEFAULT_FILTER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT,
  FIRST_PAGE_NUMBER,
  EXTERNAL_LINKS,
  isLoggedInUserAnalyticsAdmin,
} from "../Constants/AnalyticsConstants";
import { hasRoleIn } from "rbac/RbacHelper";
import { getTableOfContents } from "./TableOfContents.actions";
import { getUserFavoriteDashboards } from "./UserFavoriteDashboards.actions";
import ROLES from "rbac/Roles.json";

const TableOfContents = ({ history }) => {
  const dispatch = useDispatch();
  const tableOfContentsState = useSelector((state) => state.tableOfContents);
  const tableOfContentsLoading = tableOfContentsState.loading;
  const tableOfContents = useSelector((state) => state.tableOfContents.data);
  const userFavoriteDashboardsState = useSelector(
    (state) => state.userFavoriteDashboards
  );
  const userFavoriteDashboardsLoading = userFavoriteDashboardsState.loading;
  const userFavoriteDashboards = useSelector(
    (state) => state.userFavoriteDashboards.data
  );

  useEffect(() => {
    dispatch(
      getUserFavoriteDashboards(
        DEFAULT_PAGE_SIZE,
        FIRST_PAGE_NUMBER,
        DEFAULT_SORT,
        DEFAULT_FILTER
      )
    );
    dispatch(
      getTableOfContents(
        DEFAULT_PAGE_SIZE,
        FIRST_PAGE_NUMBER,
        DEFAULT_SORT,
        DEFAULT_FILTER
      )
    );
  }, []);

  const onTableOfContentsUpdate = (filterValues, sortBy, pageSize, pageNo) => {
    dispatch(getTableOfContents(pageSize, pageNo, sortBy, filterValues));
  };

  const onUserFavoriteDashboardsUpdate = (
    filterValues,
    sortBy,
    pageSize,
    pageNo
  ) => {
    dispatch(getUserFavoriteDashboards(pageSize, pageNo, sortBy, filterValues));
  };

  const onRowClick = (rowInfo) => {
    console.log(rowInfo);
    if (
      rowInfo !== undefined &&
      rowInfo.column.id !== "action" &&
      rowInfo.column.id !== "confluenceDocumentLink"
    ) {
      history.push(`/analytics/${rowInfo.original.dashboardUuid}/details`);
    }
  };

  const handler = () => {
    dispatch(
      getUserFavoriteDashboards(
        DEFAULT_PAGE_SIZE,
        FIRST_PAGE_NUMBER,
        DEFAULT_SORT,
        DEFAULT_FILTER
      )
    );
    dispatch(
      getTableOfContents(
        DEFAULT_PAGE_SIZE,
        FIRST_PAGE_NUMBER,
        DEFAULT_SORT,
        DEFAULT_FILTER
      )
    );
  };

  const renderFavoriteAction = (rowData) => {
    if (rowData) {
      return (
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <FavoriteDashboard
            dashboardId={rowData.dashboardUuid}
            isUserFavorite={rowData.isUserFavorite}
            handler={handler}
          ></FavoriteDashboard>
        </span>
      );
    }
  };

  const renderAllActions = (rowData) => {
    if (rowData) {
      return (
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {isLoggedInUserAnalyticsAdmin() ? (
            <>
              <EditDashboard
                isEdit={true}
                dashboard={rowData}
                showButton={true}
                handler={handler}
              ></EditDashboard>
              <DeleteDashboard
                dashboardId={rowData.dashboardUuid}
                handler={handler}
              ></DeleteDashboard>
            </>
          ) : null}
          <FavoriteDashboard
            dashboardId={rowData.dashboardUuid}
            isUserFavorite={rowData.isUserFavorite}
            handler={handler}
          ></FavoriteDashboard>
        </span>
      );
    }
  };

  const DASHBOARD_ACTIONS_COLUMN = {
    header: "Actions",
    accessor: "action",
    accessorKey: "action",
    width: 200,
    enableSorting: false,
    enableColumnFilter: false,
    columnType: ColumnTypes.Custom,
    customComponent: (value, row) => {
      return renderAllActions(row.original);
    },
  };

  const FAVORITE_DASHBOARD_ACTIONS_COLUMN = {
    header: "Actions",
    accessor: "action",
    accessorKey: "action",
    width: 200,
    enableSorting: false,
    enableColumnFilter: false,
    columnType: ColumnTypes.Custom,
    customComponent: (value, row) => {
      return renderFavoriteAction(row.original);
    },
  };

  const TOC_COLUMNS = [...COLUMNS, DASHBOARD_ACTIONS_COLUMN];
  const FAVORITES_COLUMNS = [...COLUMNS, FAVORITE_DASHBOARD_ACTIONS_COLUMN];

  return (
    <Container className="list-container" fluid>
      <DisplayBar
        tableTitle={"Table of Contents"}
        totalRecords={
          tableOfContentsState.totalElements == "Loading"
            ? 0
            : tableOfContentsState.totalElements
        }
        refreshIcon={false}
        dataTestId={"text-total-dashboard-count"}
        externalLinks={EXTERNAL_LINKS}
      />
      <h3>Favourites</h3>
      <ServerSideReactTable
        columnDefination={FAVORITES_COLUMNS}
        data={userFavoriteDashboards}
        currentPage={userFavoriteDashboardsState.page}
        filters={userFavoriteDashboardsState.filtered}
        totalPages={userFavoriteDashboardsState.totalPages}
        totalRecords={userFavoriteDashboardsState.totalElements}
        pageSizeOptions={[5, 10, 15, 20]}
        onUpdateTableData={onUserFavoriteDashboardsUpdate}
        loading={userFavoriteDashboardsLoading}
        defaultPageSize={userFavoriteDashboardsState.pageSize}
        onRowClick={onRowClick}
      />
      <h3>
        All Dashboards{" "}
        {isLoggedInUserAnalyticsAdmin() ? (
          <EditDashboard isEdit={false} handler={handler}></EditDashboard>
        ) : null}
      </h3>
      <ServerSideReactTable
        columnDefination={TOC_COLUMNS}
        data={tableOfContents}
        currentPage={tableOfContentsState.page}
        filters={tableOfContentsState.filtered}
        totalPages={tableOfContentsState.totalPages}
        totalRecords={tableOfContentsState.totalElements}
        pageSizeOptions={[5, 10, 15, 20]}
        onUpdateTableData={onTableOfContentsUpdate}
        loading={tableOfContentsLoading}
        defaultPageSize={tableOfContentsState.pageSize}
        onRowClick={onRowClick}
      />
    </Container>
  );
};
export default TableOfContents;
