import config from 'config';
import { RSAA } from 'redux-api-middleware';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';


export const getDynamicSwitchGroupDiff = (codexVersion,dynamicSwitchGroupId) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/cat/codex-version/${codexVersion}/updated-dynamic-switch-groups/${dynamicSwitchGroupId}/dynamic-switch-options`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        'DELTA_DSG_DETAIL_REQUEST',
        'DELTA_DSG_DETAIL_SUCCESS',
        'DELTA_DSG_DETAIL_FAILURE'
      ]
    }
  };
}
