import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import { useRef } from "react";

/**
 * placement: top, right
 * customRef: reference
 * buttonVariant: success, danger ...
 * buttonText: "Click ..."
 * buttonIcon: icon component to be placed at the end of the button text
 * buttonSize: sm, lg
 * buttonIsBlock: true, false
 * buttonId: button id
 * popoverTitle:
 * children: popover data
 */

const ButtonWithPopover = (props) => {
  const ref = useRef(props.customRef ? props.customRef : null);
  return (
    <div ref={ref}>
      <OverlayTrigger
        trigger="click"
        placement={props.placement ? props.placement : "top"}
        rootClose
        overlay={
          <Popover
            id={props.customRef + "id"}
            className="popover-for-btn-popover"
            title={props.popoverTitle}
          >
            {props.children}
          </Popover>
        }
      >
        <Button
          variant={props.buttonVariant}
          block={props.buttonIsBlock}
          size={props.buttonSize}
          disabled={props.buttonIsDisabled}
          id={props.buttonId}
          className={props.buttonClassName ? props.buttonClassName : " btn-block"}
          data-testid={props.dataTestId}
          >
          {props.buttonText}
          {props.buttonIcon ? <span> {props.buttonIcon}</span> : ""}
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default ButtonWithPopover;
