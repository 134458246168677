import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const scheduleCycle = (cycleDto) => {

  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/swim/cycle`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken()
      },
      body: JSON.stringify(cycleDto),
      types: [
        'SCHEDULE_CYCLE_REQUEST',
        'SCHEDULE_CYCLE_SUCCESS',
        'SCHEDULE_CYCLE_FAILURE'

      ]
    }
  };
};
export const resetSuccess = () => {
  return {
    type: "RESET_SUCCESS_MESSAGE_CYCLE_SCHEDULER"

  }
}
