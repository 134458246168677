
export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);
  switch (action.type) {
    case 'UPDATE_EMBED_URL':
      newState = Object.assign({}, previousState, {
        embedUrl: action.payload.embedUrl
      });
      break;
    default:
      break;
  }

  return newState;
}