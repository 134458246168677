import Colors from "../lookup/Colors.json";
import ErrorCodes from "../lookup/ErrorCodes";
import { format } from "date-fns";

export const twoDecimalPlaceRounder = (value) => {
  return value === 0
    ? value
    : parseFloat(Math.round(value * 100) / 100).toFixed(2);
};

export const oneDecimalPlaceRounder = (value) => {
  return value === 0
    ? value
    : parseFloat(Math.round(value * 10) / 10).toFixed(1);
};

export const getGender = (gender) => {
  return gender === "m" || gender === "M" ? `Male` : `Female`;
};

export const capitalizeString = (value) => {
  if (value === null || value === undefined) return value;

  var listOfWords = value.split(" ");
  var listOfCapitalizedWords = listOfWords.map(
    (word) => word.charAt(0).toUpperCase() + word.substring(1)
  );

  return listOfCapitalizedWords.join(" ");
};

export const getColorForPatientAge = (age) => {
  var color = Colors["rm-success"];
  if (age > 65) color = Colors["rm-warning"];
  else if (age < 18) color = Colors["rm-danger"];

  return color;
};

export const makeSentenceFromStringArray = (strArr) => {
  if (strArr.length === 0) return "";
  else if (strArr.length === 1) return strArr[0];
  else
    return (
      strArr.slice(0, strArr.length - 1).join(", ") + " & " + strArr.slice(-1)
    );
};

export const formatPhoneNumber = (phone) => {
  var phoneNumber;
  if (phone) {
    phoneNumber = phone.replace(/-/g, "").replace("+1", "");
    if (phoneNumber.length === 10)
      return (
        "(" +
        phoneNumber.substring(0, 3) +
        ") " +
        phoneNumber.substring(3, 6) +
        "-" +
        phoneNumber.substring(6, 10)
      );
  }
  return phone;
};

// WOW!
export const parseLowerCamelCase = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const convertNumberToOnePrecisionPoint = (number) =>
  Number(number.toFixed(1));

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export function getErrorMessage(errorData) {
  if (errorData) {
    if (errorData.additionalErrorInfo == null) {
      return ErrorCodes[errorData.errorCode] || ErrorCodes[errorData];
    } else {
      var errorMessage = ErrorCodes[errorData.errorCode];
      for (var additionalInfo in errorData.additionalErrorInfo) {
        errorMessage = errorMessage.replace(
          "%s",
          errorData.additionalErrorInfo[additionalInfo]
        );
      }
      return errorMessage;
    }
  } else {
    return null;
  }
}

export function isStringEmptyOrNull(str) {
  return str == null || str === "";
}

export function isNull(obj) {
  return obj == null;
}

export function isCollectionNullOrEmpty(arr) {
  return arr == null || arr.length === 0;
}

export function getInitials(fullName) {
  if (fullName) {
    let words = fullName.split(" ");
    let initials = "";
    if (words.length == 1) {
      initials += words[0].charAt(0).toUpperCase();
      initials += words[0].charAt(1).toLowerCase();
    } else {
      words.forEach((word) => {
        initials += word.charAt(0).toUpperCase();
      });
    }
    return initials;
  } else {
    return "N/A";
  }
}

export const dateFormatter = (date) => {
  return format(date, "yyyy-MM-dd");
};

export const setTimeTo8am = (dateTime) => {
  if (dateTime == null) return dateTime;
  dateTime.setHours(8);
  dateTime.setMinutes(0);
  return dateTime;
};

export const extractDefaultFilters = (filterMap) => {
  let defaultFilters = [];
  if (filterMap) {
    for (const key in filterMap) {
      for (const filterOptions in filterMap[key]) {
        if (filterMap[key][filterOptions]["isDefault"]) {
          defaultFilters.push({ ...filterMap[key][filterOptions], id: key });
        }
      }
    }
    return defaultFilters;
  } else return [];
};

export const compareEmptyOrNull = (str1, str2) => {
  return isStringEmptyOrNull(str1) && isStringEmptyOrNull(str2);
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addWorkDays = (startDate, days) => {
  if (isNaN(days)) {
    console.log('Value provided for "days" was not a number');
    return;
  }
  if (!(startDate instanceof Date)) {
    console.log('Value provided for "startDate" was not a Date object');
    return;
  }
  // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
  var dow = startDate.getDay();
  var daysToAdd = parseInt(days);
  // If the current day is Sunday add one day
  if (dow == 0) daysToAdd++;
  // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
  if (dow + daysToAdd >= 6) {
    //Subtract days in current working week from work days
    var remainingWorkDays = daysToAdd - (5 - dow);
    //Add current working week's weekend
    daysToAdd += 2;
    if (remainingWorkDays > 5) {
      //Add two days for each working week by calculating how many weeks are included
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
      //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
      if (remainingWorkDays % 5 == 0) daysToAdd -= 2;
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd);
  return startDate;
};

export const areArraysDifferent = (arr1, arr2) => {
  if ((!arr1 && !arr2) || (arr1 && !arr2) || (!arr1 && arr2)) {
    return true;
  }

  if (arr1.length !== arr2.length) {
    return true;
  }

  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  if (set1.size !== set2.size) {
    return true;
  }

  for (let value of set1) {
    if (!set2.has(value)) {
      return true;
    }
  }

  return false;
};

export const getPageSize = (defaultPageSize, pageSizeValue, attributeName) => {
  let size = defaultPageSize;
  const LOGGED_IN_USER_STORE_KEY = "logged_in_user";
  const tableAttributes =
    localStorage.getItem(LOGGED_IN_USER_STORE_KEY) &&
    JSON.parse(localStorage.getItem(LOGGED_IN_USER_STORE_KEY)).tableAttributes
      ? JSON.parse(localStorage.getItem(LOGGED_IN_USER_STORE_KEY))
          .tableAttributes
      : null;
  if (tableAttributes != null) {
    size = tableAttributes[attributeName] || defaultPageSize;
  }
  if (pageSizeValue != 0 && pageSizeValue != size) {
    size = pageSizeValue;
  }
  return size;
};
