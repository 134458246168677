import Colors from "./Colors";

export const PawConstants = {
  status: {
    New: "New",
    Rejected: "Rejected",
    SubmittedForReview: "SubmittedForReview",
    Approved: "Approved",
    SubmittedForManualApproval: "SubmittedForManualApproval",
    AutoApproved: "AutoApproved",
    AutoRejected: "AutoRejected",
  },
  statuses: [
    { key: "Default Filters", value: "Default Filters" },
    { key: "New", value: "New" },
    { key: "Rejected", value: "Rejected" },
    { key: "SubmittedForReview", value: "Submitted For Review" },
    { key: "Approved", value: "Approved" },
    {
      key: "SubmittedForManualApproval",
      value: "Submitted For Manual Approve",
    },
    { key: "AutoApproved", value: "Auto Approved" },
    { key: "AutoRejected", value: "Auto Rejected" },
  ],
  statusLabels: {
    New: "New",
    Rejected: "Manual Rejected",
    SubmittedForReview: "Submitted For Review",
    Approved: "Manual Approved",
    SubmittedForManualApproval: "Submitted For Manual Approve",
    AutoApproved: "Auto Approved",
    AutoRejected: "Auto Rejected",
    AutoApprovalInProgress: "Auto Approval in Progress",
    AutoRejectionInProgress: "Auto Rejection in Progress",
  },
  statusColors: {
    New: Colors["rm-blue"],
    Rejected: Colors["rm-danger"],
    SubmittedForReview: Colors["rm-warning"],
    Approved: Colors["rm-success"],
    SubmittedForManualApproval: Colors["rm-warning"],
    AutoApprovalInProgress: Colors["rm-blue"],
    AutoRejectionInProgress: Colors["rm-yellow-very-light"],
    AutoApproved: Colors["rm-success"],
    ManualReview: Colors["rm-warning"],
    AutoRejected: Colors["rm-yellow-light"],
  },

  DEFAULT_INTERVENTION_LIST_SORTING: {
    ownerName: { id: "ownerName", desc: false, isDefault: true },
    switchId: { id: "switchNumber", desc: true, isDefault: true },
  },

  DEFAULT_FILTER_FOR_BACKEND: {
    id: "status",
    value: ["New", "SubmittedForReview", "SubmittedForManualApproval"],
    isdefault: true,
  },
  DEFAULT_PAW_GROUP_SORT: [
    { id: "switchCreationDate", desc: true, isDefault: true },
  ],
  DEFAULT_DRUG_GROUP_SORT: [{ id: "status", desc: false, isDefault: true }],

  DEFAULT_SORT: [
    { id: "ownerName", desc: false, isDefault: true },
    { id: "switchNumber", desc: true, isDefault: true },
  ],

  DEFAULT_FILTER: [{ id: "status", value: "Default Filters", isdefault: true }],
  DEFAULT_FILTER_FOR_DISPLAY: {
    id: "status",
    value: ["Not Approved", "Not Rejected"],
    isdefault: true,
  },
  DEFAULT_FILTER_CONST: "Default Filters",

  AutoApprovalLkp: {
    NO_PREVIOUS_TRACK_AVAILABLE_FOR_THE_DRUG: {
      name: "NO_PREVIOUS_TRACK_AVAILABLE_FOR_THE_DRUG",
      message: "Not enough data available",
    },
    NUMBER_OF_APPROVALS_LESS_THEN_REQUIRED: {
      name: "NUMBER_OF_APPROVALS_LESS_THEN_REQUIRED",
      message: "Not enough past approvals",
    },
    DATE_OLDER_THAN_THE_REQUIRED_DATE: {
      name: "DATE_OLDER_THAN_THE_REQUIRED_DATE",
      message: "Last approval was before the required date",
    },
    ELIGIBLE_FOR_AUTO_APPROVAL: {
      name: "ELIGIBLE_FOR_AUTO_APPROVAL",
      message: "Eligible for Auto-Approval",
    },
    AUTO_APPROVED: { name: "AUTO_APPROVED", message: "Auto Approved" },
    AUTO_REJECTED: { name: "AUTO_REJECTED", message: "Auto Rejected" },
    AUTO_REJECTED_BY_DSG: { name: "AUTO_REJECTED_BY_DSG", message: "Auto RE" },
    MANUAL_REJECTED: { name: "MANUAL_REJECTED", message: "Manual Rejected" },
    MANUAL_REVIEW: { name: "MANUAL_REVIEW", message: "Manual Review" },
  },
};
