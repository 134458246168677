import React from 'react';
import DismissableModal from 'components/DismissableModal';
import MedicineHistoryModal from './MedicineHistoryModal';
import { Container } from 'react-bootstrap';
import { useRef } from 'react';

const MedicineHistory = (props) => {
  const modalRef = useRef("medicineHistoryRef");

  return (
    <DismissableModal
      ref={modalRef}
      customModalStyle={{ height: '100%' }}
      openModalButtonText="Medicine History"
      openModalButtonBlock={true}
      openModalButtonStyle="primary"
      title="Patient History"
      modalClassName="super-wide-modal">
      <Container>
        <MedicineHistoryModal
          switchId={props.switchId}
        />
      </Container>
    </DismissableModal>
  )
};

export default MedicineHistory;