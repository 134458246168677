import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useDispatch, useSelector } from "react-redux";
import { getActiveRadarList  } from "./ActiveRadar.actions";
import "./ActiveRadar.css";
import CAT_CONSTANTS from "lookup/CatConstants";
import DebouncedFilter from 'components/DebouncedFilter';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResetListState from "components/ResetListState";
import { formatDateInEnglish } from 'services/MomentUtils';
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';



const ActiveRadar = () => {
  const dispatch = useDispatch();
  const activeRadarState = useSelector((state) => state.activeRadar);
  const activeRadarLoading = activeRadarState.loading;
  const activeRadarError = activeRadarState.error;
  const totalRecords = activeRadarState.totalElements;
  const lastDate = activeRadarState.lastDate;
  const activeRadarList = useSelector((state) => state.activeRadar.activeRadarDrugs);
  React.useEffect(() => {
    dispatch(getActiveRadarList(activeRadarState.pageSize, 0, activeRadarState.sorted, activeRadarState.filtered));
  }, [dispatch]);

  const debouncedFilter = (filterData) => {
    updateActiveRadarTable(activeRadarState.pageSize, 0, activeRadarState.sorted, filterData);
  }

  const updateActiveRadarTable = (pageSize, pageNo, sorted, filtered) => {
    dispatch(getActiveRadarList(pageSize, pageNo, sorted, filtered));
  }

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    updateActiveRadarTable(pageSize, pageNo, sortBy ? sortBy : [], filterValues ? filterValues : []);
  }

  const resetList = () => {
    dispatch(getActiveRadarList(listState.pageSize, 0, CAT_CONSTANTS.defaultActiveRadarSort, []));
  }

  const [resized, setResized] = useState([]);
  const [expanded, setExpanded] = useState({});

  const listState = {
    page: activeRadarState.currentPage,
    pageSize: activeRadarState.pageSize,
    sorted: activeRadarState.sorted,
    filtered: activeRadarState.filtered,
    totalPages: activeRadarState.totalPages,
    resized,
    expanded
  };

  const textfilter = React.useCallback(({ filter, onChange, column }) => {
    return (
      <DebouncedFilter
        key={`${column.id}Filter`}
        value={filter ? filter.value : ''}
        onFilter={onChange}
      />
    );
  }, []);

  const valueToPrice = (value) => {
    return value > -1 ? value.toFixed(2) : 'N/A'
  };

  const columns = React.useMemo(
    () => [
      {
        header: "AR UUID",
        accessor: "activeRadarId",
        accessorKey: "activeRadarId",
        width: 100,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Text,
      },
      {
        header: "Target Drug Name (RM Switch)",
        accessor: "targetDrugName",
        accessorKey: "targetDrugName",
        width: 250,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Target GPI12",
        accessor: "targetGpiCode",
        accessorKey: "targetGpiCode",
        width: 125,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Alternate Drug Name (RM Target)",
        accessor: "alternateDrugName",
        accessorKey: "alternateDrugName",
        width: 250,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Alt GPI12",
        accessor: "alternateGpiCode",
        accessorKey: "alternateGpiCode",
        width: 125,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "AR Drug Category",
        accessor: "arDrugCategory",
        accessorKey: "arDrugCategory",
        width: 175,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Drug Subclass Name",
        accessor: "drugSubclassName",
        accessorKey: "drugSubclassName",
        width: 175,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Target Cost",
        accessor: "targetCost",
        accessorKey: "targetCost",
        width: 75,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return valueToPrice(value)
        },
      },
      {
        header: "Alt Cost",
        accessor: "alternateCost",
        accessorKey: "alternateCost",
        width: 75,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return valueToPrice(value)
        },
      },
    ],
    [listState, textfilter]
  );
  return (
    <Container className="list-container" fluid>
      <div className='codex-version-display-bar'>
        <Row>
          <Col className="d-flex justify-content-start align-items-center">
            <p>{"Total Drugs: "} <Badge className={'badge-text'} pill bg={"info"} text="dark"><strong>{totalRecords}</strong></Badge> &nbsp;
                {"Last Updated: "} <Badge className={'badge-text'} pill bg={"info"} text="dark"><strong>{activeRadarError || activeRadarLoading ? lastDate : formatDateInEnglish(lastDate)}</strong></Badge></p>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <strong style={{ float: "center" }}>ActiveRadar List</strong>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <ResetListState
              onReset={resetList}
              resetterDisabled={
                listState.filtered === [] &&
                listState.sorted === CAT_CONSTANTS.defaultActiveRadarSort
              }
            />
          </Col>

        </Row>
      </div>
      <ServerSideReactTable
        columnDefination={columns}
        data={activeRadarList}
        overrideRowId={'activeRadarId'}
        currentPage={listState.page}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={listState.totalPages}
        totalRecords={totalRecords=="loading"?0:totalRecords}
        loading={activeRadarLoading}
        enableMultiRowSelection={false}
        defaultPageSize={listState.pageSize}
        onUpdateTableData={onUpdateTable}
        pageSizeOptions={[5, 10, 15, 20, 30, 50]}
      />
      <div className='tip'>
        <em>{"Tip: Hold shift when sorting to multi-sort and disable sort!"}</em>
      </div>
    </Container>
  )
}

export default ActiveRadar;