import React from "react";
import DismissableModal from "components/DismissableModal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'assets/css/agentworkspace.css';
import ExpandSwitchHistory from "./ExpandSwitchHistory";

const ExpandSwitchTimeline = (props) => {
  return (
    <DismissableModal
      openModalButtonText="Switch Timeline"
      openModalButtonStyle="primary"
      openModalButtonClassName="btn-text-white btn-block"
      title="Switch Timeline"
      size="lg"
      centered
      openModalButtonTestId="btn-expand-switch-history"
    >
      <Container>
        <Row>
          <Col md={12}>
            <ExpandSwitchHistory
              activityId={props.activityId}
              switchData={props.switchData}
              isFromAW={props.isFromAW}
              isAWAdmin={props.isAWAdmin} />
          </Col>
        </Row>
      </Container>
    </DismissableModal>
  );
};

export default ExpandSwitchTimeline;