import React from 'react';
import Form from 'react-bootstrap/Form';


const UserDropDown = ({ selectedValue='', handleChange,userList }) => {
  
  return (
    <span className={"column"} >
    <Form.Control
      id={`userSelect`}
      as="select"
      size="sm"
      value={selectedValue || ''} // Use an empty string to display the placeholder
      onChange={(e) => handleChange(e.target.value)}
      placeholder="Select an owner" // Add the placeholder here
    >
      <option value="">Change owner</option>
      {userList.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </Form.Control>
    </span>
  );
};

export default UserDropDown;

