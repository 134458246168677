import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import {sendAuthenticatedAsyncRequest} from 'services/AsyncRequestService';
import { showNotification } from 'services/NotificationService';

class CloseWorkflowForSwitches extends Component {
  state = {
    asyncInProgress: false,
  }

  closeWorkflowForSwitches = () => {
    this.setState({asyncInProgress: true});
    sendAuthenticatedAsyncRequest(
      `/wf/close-switches-on-no-response`,
      "GET", 
      null,
      (r) => {
        this.setState({asyncInProgress: false})
        let switches = r.data;
        showNotification({
          title: 'Action successful!',
          message: switches > 0 
            ? `Workflow successfully closed for ${switches} switch(es)`
            : 'No switches avaialable to be closed in database',
          position: 'tc',
          type: "info"
        });
      },
      (r) => this.setState({asyncInProgress: false})
    );
  }

  render() {
    const {asyncInProgress} = this.state;
    return (
      <Card style={{textAlign:'center'}}>
        <Card.Body>
          <Card.Text>
            Close old switches?
          </Card.Text>
          <Button variant='danger' disabled={asyncInProgress} onClick={this.closeWorkflowForSwitches}>
            {!asyncInProgress ? 'Close Switches' : 'Closing Switches ...'}
          </Button>
        </Card.Body>
      </Card>
    );
  }
}

export default CloseWorkflowForSwitches;
