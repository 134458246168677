import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from "../../services/AuthService";
import { resetDeclinedFax } from "./UnlinkDeclinedFax.slice";

export const unlinkDeclinedFaxRequestAction = 'UNLINK_DECLINED_FAX_REQUEST_REQUEST';
export const unlinkDeclinedFaxSuccessAction = 'UNLINK_DECLINED_FAX_REQUEST_SUCCESS';
export const unlinkDeclinedFaxFailureAction = 'UNLINK_DECLINED_FAX_REQUEST_FAILURE';

export const unlinkDeclinedFaxAction = (wfItemId, notes) => (dispatch, getState) => {
    // const sorted={id: "status", desc: false, isDefault: true};
    const rsaaObject = {
        [RSAA]: {
            endpoint: `${config.apiRoot}/raw/unlink-fax/${wfItemId}`,
            method: 'PUT',
            body: JSON.stringify({ note: notes }),
            headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
            types: [
                unlinkDeclinedFaxRequestAction,
                {
                    type: unlinkDeclinedFaxSuccessAction,
                    payload: async (action, state, res) => {
                        const data = await res.json();
                        return data;
                    }
                },
                unlinkDeclinedFaxFailureAction
            ],
        }
    }
    return dispatch(rsaaObject);
}

export const resetUnlinkDeclinedFax = () => (dispatch, getState) => {
    return dispatch(resetDeclinedFax());
}