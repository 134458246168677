export const LINK_SWITCH_REQUEST = "LINK_SWITCH_REQUEST";
export const LINK_SWITCH_SUCCESS = "LINK_SWITCH_SUCCESS";
export const LINK_SWITCH_FAILURE = "LINK_SWITCH_FAILURE";
export const DISCARD_FAX_REQUEST = "DISCARD_FAX_REQUEST";
export const DISCARD_FAX_SUCCESS = "DISCARD_FAX_SUCCESS";
export const DISCARD_FAX_FAILURE = "DISCARD_FAX_FAILURE";
export const FETCH_UNPROCESSED_FAX_REQUEST = "FETCH_UNPROCESSED_FAX_REQUEST";
export const FETCH_UNPROCESSED_FAX_SUCCESS = "FETCH_UNPROCESSED_FAX_SUCCESS";
export const FETCH_UNPROCESSED_FAX_FAILURE = "FETCH_UNPROCESSED_FAX_FAILURE";
export const LINK_REQUEST_STATUS_RESET = "LINK_REQUEST_STATUS_RESET";
export const SAVE_FAX_NOTE_REQUEST = "SAVE_FAX_NOTE_REQUEST";
export const SAVE_FAX_NOTE_SUCCESS = "SAVE_FAX_NOTE_SUCCESS";
export const SAVE_FAX_NOTE_FAILURE = "SAVE_FAX_NOTE_FAILURE";
export const UPDATE_FAX_NOTE_REQUEST = "UPDATE_FAX_NOTE_REQUEST";
export const UPDATE_FAX_NOTE_SUCCESS = "UPDATE_FAX_NOTE_SUCCESS";
export const UPDATE_FAX_NOTE_FAILURE = "UPDATE_FAX_NOTE_FAILURE";
export const DELETE_FAX_NOTE_REQUEST = "DELETE_FAX_NOTE_REQUEST";
export const DELETE_FAX_NOTE_SUCCESS = "DELETE_FAX_NOTE_SUCCESS";
export const DELETE_FAX_NOTE_FAILURE = "DELETE_FAX_NOTE_FAILURE";
export const RESET_SAVE_NOTE_SUCCESS = "RESET_SAVE_NOTE_SUCCESS";
export const RESET_UPDATE_NOTE_SUCCESS = "RESET_UPDATE_NOTE_SUCCESS";
export const RESET_DELETE_NOTE_SUCCESS = "RESET_DELETE_NOTE_SUCCESS";
export const RESET_DISCARDED_FAX = "RESET_DISCARDED_FAX";
