const config = {
  apiRoot: process.env.REACT_APP_ENV 
      ? `https://${process.env.REACT_APP_ENV}-api.rxa.${process.env.REACT_APP_HOSTED_ZONE_NAME}.com`
      : "http://localhost:8090",
  tokenKey: "authorization_token",
  redirectRouteOnAuthenticationFaliure: "/login"
}

export const env = {
  environment: process.env.REACT_APP_ENV 
}
export const version = {
  commitVersion : process.env.REACT_APP_COMMIT_VERSION,
  releaseNumber : '9.7'

}

export default config;
 