import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';

import {twoDecimalPlaceRounder} from 'services/UtilityService.js';

export default class ProjectedSavings extends Component {
  render() {
    return (
      <div>
        <div className='small-label'>
          Savings
        </div>
          <Container fluid style={{paddingTop: 10}}>
            <Row>
              <Col md={6} className="rm-horizontal-center">
                <div>
                  <div className='rm-tiny-top-label'>Paid</div>
                  <div>$ {twoDecimalPlaceRounder(this.props.paid)}</div>
                </div>
              </Col>
              <Col md={6} className="rm-horizontal-center">
                <div>
                  <div className='rm-tiny-top-label'>Projected</div>
                  <div>$ {twoDecimalPlaceRounder(this.props.projected)}</div>
                </div>
              </Col>
            </Row>
            <Row className="tiny-top-spacer">
              <Col md={12} className="rm-horizontal-center">
                <div>
                  <div className='rm-tiny-top-label' style={{marginBottom: 5}}>Savings</div>
                  <Badge className={ this.props.savings > 0 ? 'badge-success' : 'badge-danger'} style={{fontSize: "inherit"}}>$ {twoDecimalPlaceRounder(this.props.savings)}</Badge>
                </div>
              </Col>
            </Row>
          </Container>
      </div>
    )
  }
}
