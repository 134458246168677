import { ColumnTypes, FilterTypes } from "commons/table/Constants";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import UserDropDown from "components/PawGroups/UserDropDown";
import { hasRoleIn } from "rbac/RbacHelper";
import ROLES from "rbac/Roles.json";
import React, { useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getLoggedInUser } from "services/AuthService";
import { showNotification } from "services/NotificationService";
import { sendAuthenticatedAsyncRequest } from "../../../services/AsyncRequestService";
import {
  assignDrugGroupTracker,
  autoAssignDrugGroup,
  resetDrugGroupStatusState,
} from "../DrugGroupDetails/DrugGroupDetails.actions";
import {
  getPAWGroupDetail,
  resetPawGroupDetail,
} from "../PAWGroupDisplayBar/PAWGroupDisplayBar.actions";
import PawGroupDisplayBar from "../PAWGroupDisplayBar/PawGroupDisplayBar";
import {
  getIfPublishStatus,
  status,
  statusBadgeColors,
} from "../PawGroup/PawGroup.constants";
import { getDrugGroupList } from "./DrugGroup.actions";
import {
  DEFAULT_FILTER,
  DEFAULT_SORT,
  DRUG_GROUP_STATUSES,
  STATUS_BADGE_COLORS,
} from "./DrugGroup.constants";
import "./DrugGroup.css";
import DrugGroupDisableDsg from "./DrugGroupDisableDsg";

const styles = {
  buttonStyle: { margin: 0, padding: "2px 3px" },
  button: { marginRight: 10 },
};

const DrugGroup = ({ history }) => {
  const { pawGroupId } = useParams();
  const dispatch = useDispatch();
  const drugGroupState = useSelector((state) => state.drugGroup);
  const pgStatus = useSelector((state) => state.pawGroupDetail.status);
  const wasLastPawGroupPublished = useSelector(
    (state) => state.pawGroupDetail.wasLastPawGroupPublished
  );
  let pawGroupPublishStatus = getIfPublishStatus(pgStatus);
  const totalRecords = drugGroupState.totalElements;
  const isPAWAdmin = hasRoleIn([ROLES.PAW_ADMIN]);
  const [userList, setUserList] = useState([]);
  const statusloading = useSelector(
    (state) => state.drugGroupDetail.statusLoader
  );
  const statusAction = useSelector(
    (state) => state.drugGroupDetail.statusAction
  );
  const statusError = useSelector((state) => state.drugGroupDetail.error);

  React.useEffect(() => {
    if (!userList || userList.length == 0) {
      getUserList();
    }
    updatePawGroupDetails();
    return () => {
      dispatch(resetDrugGroupStatusState());
      dispatch(resetPawGroupDetail());
    };
  }, []);

  React.useEffect(() => {
    if (pgStatus) {
      pawGroupPublishStatus = getIfPublishStatus(pgStatus);
      fetchDrugGroup(false);
    }
  }, [pgStatus]);

  const getUserList = () => {
    if (userList.length == 0)
      sendAuthenticatedAsyncRequest(
        `/user?exclude_requester=false&context=paw`,
        "GET",
        null,
        (r) => setUserList(r.data)
      );
  };

  const listState = {
    page: drugGroupState.page,
    pageSize: drugGroupState.pageSize,
    sorted: drugGroupState.sorted,
    filtered: drugGroupState.filtered,
    totalPages: drugGroupState.totalPages,
    resized: drugGroupState.resized,
    expanded: drugGroupState.expanded,
  };

  const fetchDrugGroup = (shouldFetchPawGroup = true) => {
    let sorted = [...drugGroupState.sorted];
    let filter = [...drugGroupState.filtered];
    let name = getLoggedInUser()?.name;

    if (
      drugGroupState.isDefaultFilter &&
      filter[1]?.value === DEFAULT_FILTER[1].value
    ) {
      let filterValue = drugGroupState.filtered[1].value + "," + name;
      const copyObject = { ...filter[1], value: filterValue };
      filter[1] = copyObject;
    }
    if (pawGroupPublishStatus) {
      sorted = [];
      filter = [];
    } else if (wasLastPawGroupPublished) {
      sorted = [...DEFAULT_SORT];
      filter = [...DEFAULT_FILTER];
      let filteredCopy = [filter[1].value];
      filteredCopy.push(name);
      const copyObject = { ...filter[1] };
      copyObject.value = filteredCopy;
      filter[1] = copyObject;
    }
    listState.sorted = sorted;
    listState.filtered = filter;
    updateDrugGroupTable(listState, shouldFetchPawGroup);
  };

  React.useEffect(() => {
    if (statusAction && statusAction == "AUTO_ASSIGN" && !statusloading) {
      if (statusError) {
        showNotification({
          title: "Action Failed!",
          message: "Could not assign the drug group .",
          position: "tr",
          type: "error",
        });
      } else {
        showNotification({
          title: "Action Successful!",
          message: "Successfully assign the drug group.",
          position: "tr",
          type: "success",
        });
        updateDrugGroupTable(listState);
      }
    }
    if (
      statusAction &&
      statusAction == "AUTO_REJECTED_BY_DSG" &&
      !statusloading
    ) {
      if (statusError) {
        showNotification({
          title: "Action Failed!",
          message: "Could not disable the DSG .",
          position: "tr",
          type: "error",
        });
      } else {
        showNotification({
          title: "Action Successful!",
          message: "Successfully disabled the DSG.",
          position: "tr",
          type: "success",
        });
        updateDrugGroupTable(listState);
      }
    }
    if (statusAction && statusAction == "error" && !statusloading) {
      if (statusError) {
        showNotification({
          title: "Action Failed!",
          message: "The Action failed. Please try again!!",
          position: "tr",
          type: "error",
        });
      }
    }
  }, [statusloading, statusAction]);

  const updateDrugGroupTable = (state, shouldFetchPawGroup = true) => {
    let pageSize = state.pageSize;
    let filtered = [...state.filtered];
    let pageNo = state.page;
    dispatch(
      getDrugGroupList(pageSize, pageNo, state.sorted, filtered, pawGroupId)
    );
    if (shouldFetchPawGroup) {
      updatePawGroupDetails();
    }
  };

  const updatePawGroupDetails = () => {
    dispatch(getPAWGroupDetail(pawGroupId));
  };

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    listState.page = pageNo;
    listState.pageSize = pageSize;
    listState.sorted = sortBy || [];
    listState.filtered = filterValues;
    updateDrugGroupTable(listState);
  };

  const renderDsgLink = (value, row) => {
    return (
      <Link
        to={`/cat/dynamic-switch-groups/${row.original.dynamicSwitchGroupId}`}
        className={"column-left"}
      >
        {value}
      </Link>
    );
  };

  const owners = drugGroupState.data.map((row, index) => {
    return row.ownerName ? row.ownerName : "unassigned";
  });
  const ownerArr = drugGroupState.filtered.filter((row) => {
    return row.id === "ownerName";
  });
  const defaultOwners = ownerArr[0] ? ownerArr[0].value : [];
  const ownerFilterMap = [...new Set(owners.concat(defaultOwners))].map(
    (row, index) => {
      return { id: row, name: row };
    }
  );
  const statusFilterMap = [];
  for (const [keyVal, valueVal] of Object.entries(DRUG_GROUP_STATUSES)) {
    statusFilterMap.push({ key: keyVal, value: keyVal });
  }

  const renderTargets = (r) => {
    if (r != null && r != "") {
      const targets = r
        .map(function (dto) {
          return dto.medName;
        })
        .join(", ");
      return <span className={"column"}> {targets} </span>;
    } else {
      return <span className={"column"}> {"N/A"} </span>;
    }
  };

  const renderStatus = (r) => {
    return (
      <span className={"column"}>
        <Badge
          className={"paw-group-pill"}
          bg={
            pawGroupPublishStatus
              ? statusBadgeColors[pawGroupPublishStatus]
              : STATUS_BADGE_COLORS[r]
          }
        >
          {pawGroupPublishStatus
            ? status[pawGroupPublishStatus]
            : DRUG_GROUP_STATUSES[r]}
        </Badge>
      </span>
    );
  };

  const renderActions = (row) => {
    if (!isPAWAdmin) {
      if (
        getLoggedInUser()?.name != row.original.ownerName &&
        row.original.ownerId == null
      ) {
        return (
          <Button
            variant="primary"
            style={styles.buttonStyle}
            onClick={() => {
              dispatch(autoAssignDrugGroup(row.original.drugGroupTrackerId));
            }}
          >
            Assign to me
          </Button>
        );
      } else {
        return null;
      }
    } else {
      return (
        <UserDropDown
          selectedValue={""}
          handleChange={(ownerId) => {
            dispatch(
              assignDrugGroupTracker(row.original.drugGroupTrackerId, ownerId)
            );
          }}
          userList={userList}
          isPAWAdmin={isPAWAdmin}
        />
      );
    }
  };

  const disableDSG = (action, row) => {
    return (
      <DrugGroupDisableDsg
        dsgId={row.original?.dynamicSwitchGroupId}
        dsgName={row.original?.dynamicSwitchGroupName}
        codexVersion={row.original?.codexVersion}
        drugGroupId={row.original?.drugGroupId}
        isPAWAdmin={isPAWAdmin}
        drugGroupStatus={row.original?.status}
        refreshList={() => {
          updateDrugGroupTable(listState);
        }}
        key={`drugGroupDisableDsg_${row.original?.drugGroupId}`}
      />
    ); // Return null or some default value if not isPAWAdmin
  };

  const columns = [
    {
      header: "Source Drug",
      accessor: "sourceMedName",
      accessorKey: "sourceMedName",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Dynamic Switch Group",
      accessor: "dynamicSwitchGroupName",
      accessorKey: "dynamicSwitchGroupName",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SearchableInput,
      customComponent: (value, row) => {
        return renderDsgLink(value, row);
      },
    },
    {
      header: "Dynamic Switch Group ID",
      accessor: "dynamicSwitchGroupId",
      accessorKey: "dynamicSwitchGroupId",
      show: false,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Target Drugs",
      accessor: "targets",
      accessorKey: "targets",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return renderTargets(value);
      },
    },
    {
      header: "Total Switches",
      accessor: "totalSwitches",
      accessorKey: "totalSwitches",
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Reviews Needed",
      accessor: "manualReviewsNeeded",
      accessorKey: "remainingSwitchCountForAutoApproval",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return row.original.remainingSwitchCountForAutoApproval <
          row.original.remainingSwitchCountForAutoRejection
          ? row.original.remainingSwitchCountForAutoApproval
          : row.original.remainingSwitchCountForAutoRejection;
      },
    },
    {
      header: "remainingSwitchCountForAutoRejection",
      accessor: "remainingSwitchCountForAutoRejection",
      accessorKey: "remainingSwitchCountForAutoRejection",
      show: false,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Auto Reviewed",
      accessor: "autoReviewedSwitches",
      accessorKey: "autoReviewedSwitches",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Manually Approved",
      accessor: "manuallyApprovedSwitches",
      accessorKey: "manuallyApprovedSwitches",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Manually Rejected",
      accessor: "manuallyRejectedSwitches",
      accessorKey: "manuallyRejectedSwitches",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Owner",
      accessor: "ownerName",
      accessorKey: "ownerName",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      customComponent: (value) => {
        return value ? value : "unassigned";
      },
      filterOptions: ownerFilterMap,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Status",
      accessor: "status",
      accessorKey: "status",
      enableSorting: true,
      enableColumnFilter: !pawGroupPublishStatus,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return renderStatus(value);
      },
      filterType: FilterTypes.SingleSelect,
      filterOptions: statusFilterMap,
    },
    {
      header: "Action",
      accessor: "action",
      accessorKey: "action",
      enableSorting: false,
      enableFiltering: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => renderActions(row),
    },
    {
      header: "Disable DSG",
      accessor: "disableDSG",
      accessorKey: "disableDSG",
      enableSorting: false,
      enableFiltering: false,
      columnType: ColumnTypes.Custom,
      customComponent: disableDSG,
    },
  ];
  return (
    <Container fluid>
      <PawGroupDisplayBar showGroupStats history={history} />
      <ServerSideReactTable
        columnDefination={columns}
        overrideRowId={"drugGroupId"}
        data={drugGroupState.data}
        currentPage={listState.page}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={listState.totalPages}
        totalRecords={totalRecords == "loading" ? 0 : totalRecords}
        loading={drugGroupState.loading}
        enableMultiRowSelection={false}
        defaultPageSize={drugGroupState.pageSize}
        pageSizeOptions={[5, 10, 15, 20, 30, 50]}
        onUpdateTableData={onUpdateTable}
        onRowClick={(row) => {
          if (
            row &&
            row.column.id != "dynamicSwitchGroupName" &&
            row.column.id != "disableDSG" &&
            row.column.id != "action"
          ) {
            history.push(
              `/paw/paw-groups/${pawGroupId}/drug-groups/${row.original.drugGroupId}`
            );
          }
        }}
      />
    </Container>
  );
};
export default DrugGroup;
