import React, { Component } from 'react';
import '../Login.css';

import {sendAsyncRequest} from '../../../services/AsyncRequestService';
import {cognitoLoginSuccess} from '../../../services/AuthService';
import { Redirect } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import jwt from 'jwt-decode';

import Spinner from 'components/Spinner';

class LoginCallback extends Component{
  state = {
    redirectToReferrer: false,
  }

  handleLogin = (code) => {
    let url = "/login/oauth2/code/cognito?code=" + code;
    let method = "GET";
    sendAsyncRequest(url, method, null, this.onLoginSuccess, this.onLoginFailure);
  }

  onLoginSuccess = (response) => {
    let data = response.data;//jwt(response.data.cognitoJwt.id_token);
    let userObjToSave = {
      uuid: data.uuid,
      name: data.name,
      authorities: data.authorities,
      email: data.email,
      tableAttributes: data.tableAttributes
      // [
      //   "SWIM_USER",
      //   "PAW_USER",
      //   "API",
      //   "SWIM_USER",
      //   "PAW_USER",
      //   "CODEX_STATUS_CHANGE",
      //   "CODEX_DIFF_READ",
      //   "CODEX_DIFF_WRITE",
      //   "CODEX_ENTITIES_CRUD"
      // ]
    };
    cognitoLoginSuccess(userObjToSave, data.cognitoJwt.token_type, data.cognitoJwt.id_token, data.cognitoJwt.refresh_token,
       data.cognitoJwt.access_token, data.commitVersionId, data.currentReleaseNumber);
  	this.setState({redirectToReferrer: true});
  }

  onLoginFailure = (error) => {
  	this.setState({error: "Something went wrong"});
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let code = query.get("code");
    this.handleLogin(code);
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    return (
      <div className="">
      <Container fluid >
        <Row>
          <Col className="text-align-center" md={{ span: 4, offset: 4}}>
            <Spinner />
            {/* <h2>Cognito Callback</h2> */}
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default LoginCallback;