import { RSAA } from 'redux-api-middleware';
import config from '../../../config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';
import { resetList } from './CurrentPrograms.slice';

export const getMemberProgramsRequestAction = "GET_MEMBER_PROGRAMS_REQUEST";
export const getMemberProgramsSuccessAction = "GET_MEMBER_PROGRAMS_SUCCESS";
export const getMemberProgramsFailureAction = "GET_MEMBER_PROGRAMS_FAILURE";

export const fetchMemberPrograms = (memberInfoUuid) => (dispatch, getState) => {
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/member/${memberInfoUuid}/current-programs`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        getMemberProgramsRequestAction,
        {
          type: getMemberProgramsSuccessAction,
          payload: async (action, state, res) => {
            const data = await res.json();
            return { ...data };
          }
        },
        getMemberProgramsFailureAction
      ],
    }
  }
  return dispatch(rsaaObject);
};

export const resetMemberPrograms = () => (dispatch, getState) => {
  dispatch(resetList);
}
