import React, { Fragment } from "react";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import arrow from "../../assets/images/arrow.png";
import { MdCheckCircle, MdCancel } from 'react-icons/md';
import { capitalizeString } from "../../services/UtilityService";

import "../../assets/css/ribbon.css";
import { PawConstants } from "lookup/PawConstants";

const styles = {
  div: {
    paddingLeft: "5px",
    paddingTop: "5px"
  },
  heading: {
    textAlign: "left",
  }
}

const DrugGroupCard = (props) => {
  const targets = props.target;
  const autoApprovalStatus = props.switchInfo.autoApprovalStatus;
  const targetMap = new Map();

  const getColourForAutoApprovalStatus = (status) => {
    return (status === PawConstants.AutoApprovalLkp.AUTO_APPROVED.name ||
      status === PawConstants.AutoApprovalLkp.ELIGIBLE_FOR_AUTO_APPROVAL.name
      ? PawConstants.statusColors.AutoApproved
      : PawConstants.statusColors.ManualReview);
  }

  const getIconForAutoApprovalStatus = (status) => {
    return (status === PawConstants.AutoApprovalLkp.AUTO_APPROVED.name ||
      status === PawConstants.AutoApprovalLkp.ELIGIBLE_FOR_AUTO_APPROVAL.name
      ? <MdCheckCircle className="auto-approval-icon-displacement-fix" />
      : <MdCancel className="auto-approval-icon-displacement-fix" />);
  }

  const getAutoApprovalStatusMessage = (status) => {
    let statusArr = status.split(",");
    return statusArr.map(s => PawConstants.AutoApprovalLkp[s.trim()].message).join(", ");
  }

  if (targets) {
    targets.forEach((t) => {
      let key;
      key =
        t.isMultiDrug && t.multiDrugIdentifier
          ? t.multiDrugIdentifier
          : t.dynamicSwitchOptionIdentifier;

      if (targetMap.has(key)) {
        targetMap.get(key).push(t);
      } else {
        targetMap.set(key, [t]);
      }
    });
  }

  const StrengthInstruction = (props) => {
    const item = props.item;
    const option = props.option;
    return (
      <span>
        {!item.itemStrength ||
          item.itemStrength === "NONE" ? null : !item.itemUnitOfMeasure ? (
            <strong>({item.itemStrength})</strong>
          ) : (
          <strong>({`${item.itemStrength} ${item.itemUnitOfMeasure}`})</strong>
        )}
        &nbsp;
        {option.isMultiDrug
          ? item.instructions
            ? `${option.instructions.endsWith(";")
              ? option.instructions
              : `${option.instructions};`
            } ${item.instructions}`
            : option.instructions
          : item.instructions
            ? item.instructions
            : null}
      </span>
    );
  };
  return (
    <div style={styles.div}>
      {props.switchInfo.templateIndicator && props.switchInfo.isControlled ? (
        <div className="ribbon ribbon-top-left">
          <span>controlled</span>
        </div>
      ) : null}
      <Card.Body>
        <Row>
          <Col md={12}>
            <div className="switch-sec-heading" style={styles.heading}>Source</div>
            <div className='source-info'>
              <div className="medicine-name">
                {props.switchInfo.sourceMedName} ({props.switchInfo.sourceDynamicSwitchOption.medForm})
              </div><br></br>
              <div>
                <Badge
                  bg={
                    props.switchInfo.sourceDynamicSwitchOption.brandGenType === "B"
                      ? "danger"
                      : "success"
                  }
                  className="drug-option-title-badge"
                >
                  {props.switchInfo.sourceDynamicSwitchOption.brandGenType == "B" ? "Brand" : "Generic"}
                </Badge>{" "}
                <Badge
                  bg="dark"
                  className="drug-option-title-badge"
                >
                  {props.switchInfo.sourceDynamicSwitchOption.gpiCode}
                </Badge>{" "}
                <Badge
                  bg="success"
                  className="drug-option-title-badge"
                >
                  {props.switchInfo.sourceDynamicSwitchOption.totalDispensedUnitQuantity || "0"} units
                </Badge>{" "}
                <Badge
                  bg="primary"
                  className="drug-option-title-badge"
                >
                  {props.switchInfo.sourceDynamicSwitchOption.routeOfAdministration}
                </Badge>{" "}
              </div>
            </div>
            <ul className="target-options">
              {props.switchInfo.sourceDynamicSwitchOption.dynamicSwitchItems
                .filter((item) => item.isActive && !item.isDeleted)
                .map((item, index) => (
                  <li key={index}>
                     <strong>({`${item.itemStrength} ${item.itemUnitOfMeasure}`})</strong>
                  </li>
                ))}
            </ul>
          </Col>
          {/* <Col md={12} className="top-spacer">
            <img src={arrow} className="arrow" style={{width:'5em'}} alt="Source being replaced by" />
          </Col> */}
          <Col md={12}>
            <div className="switch-sec-heading" style={styles.heading}>Target Options</div>
            <Row>
              {[...targetMap.entries()].map((entry) => {
                const key = entry[0];
                const value = entry[1];
                if (value && value.length > 1) {
                  // Multi Drug Combo
                  return (
                    <Col md={12}>
                      <span key={key}>
                        <Row>
                          <Col md={12}><div className="medicine-name">Multi Drug Combo</div></Col>
                          {value.map((option, index) => (
                            <Col md={6}>
                              <span key={index}>
                                <ul className="target-options">
                                  {option.dynamicSwitchItems != null && option.dynamicSwitchItems
                                    .filter((item) => item.isActive && !item.isDeleted)
                                    .map((item, index) => (
                                      <li key={index}>
                                        {capitalizeString(
                                          option.faxDisplayName &&
                                          option.faxDisplayName.trim() || option.medName
                                        )}
                                        &nbsp;
                                        <strong>({option.medForm})</strong>
                                        &nbsp;
                                        <StrengthInstruction
                                          option={option}
                                          item={item}
                                        />
                                      </li>
                                    ))}
                                </ul>
                              </span>
                            </Col>
                          ))}
                        </Row>
                      </span>
                    </Col>
                  );
                } else {
                  // single drug target
                  return value.map((option, index) => (
                    <Col md={12}>
                      <span key={index}>
                        <div className="medicine-name">
                          {option.faxDisplayName || option.medName}
                          &nbsp;
                          <strong>({option.medForm})</strong>
                        </div>
                        {option.instructions && (
                          <Fragment>
                            <strong>Form Level Instructions: </strong>
                            <span>{option.instructions}</span>
                          </Fragment>
                        )}
                        <ul className="target-options">
                        {option.dynamicSwitchItems != null && option.dynamicSwitchItems
                            .filter((item) => item.isActive && !item.isDeleted)
                            .map((item, index) => (
                              <li key={index}>
                                <StrengthInstruction option={option} item={item} />
                              </li>
                            ))}
                        </ul>
                      </span>
                    </Col>
                  ));
                }
              })}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </div>
  );
};

export default DrugGroupCard;
