import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateInterventionList, updateIndexOnList, resetError } from './SwitchList.actions';
import { withRouter } from 'react-router-dom';
import ResetListState from 'components/ResetListState';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { DEFAULT_SWIM_SORT, SWIM_FILTER } from 'lookup/SwimConstants';
import SwitchListCommon from './SwitchListCommon';
import { COLUMN } from './CreateColumnListForSwitchList';

const DEFAULT_PAGE_SIZE = 15;

const COLUMN_LIST = [
  COLUMN.customerNameAbbreviation,
  COLUMN.clientNameAbbreviation,
  COLUMN.switchNumber,
  COLUMN.state,
  COLUMN.status,
  COLUMN.prescriberCallCounter,
  COLUMN.daysAfterLastModifiedDate,
  COLUMN.lastModifiedDate,
  COLUMN.pharmacyName,
  COLUMN.pharmacyCallCounter,
  COLUMN.isPickupConfirmed,
  COLUMN.memberNumberWithoutCustId,
  COLUMN.memberNumber,
  COLUMN.memberCallCounter,
  COLUMN.effectiveDate,
  COLUMN.projectedSavings,
  COLUMN.prescriberName,
  COLUMN.prescriberPhone,
  COLUMN.prescriberFax,
  COLUMN.pharmacyPhone,
  COLUMN.prescriberNPI,
  COLUMN.pharmacyFax,
  COLUMN.pharmacyNPI,
];

class SwitchList extends React.Component {

  resetList = () => {
    const { pageSize, resized, expanded } = this.props.listState;
    this.props.updateInterventionList(
      pageSize
      , 0
      , DEFAULT_SWIM_SORT
      , SWIM_FILTER.defualt
      , resized
      , expanded
    )
  }

  render() {
    return (
      <Container className="list-container" fluid>
        <Row >
          <Col md={12}>
            <div className='codex-version-display-bar'>
              <span style={{ float: 'left' }} >
                Total Switches: <strong>{this.props.totalElements}</strong>
              </span>
              <strong style={{ float: 'center' }}>Switch List</strong>
              <ResetListState
                onReset={this.resetList}
                resetterDisabled={this.props.listState.filtered === SWIM_FILTER.defualt && this.props.listState.sorted === DEFAULT_SWIM_SORT}
              />
            </div>
          </Col>
        </Row>
        <SwitchListCommon
          updateInterventionList={this.props.updateInterventionList}
          updateIndexOnList={this.props.updateIndexOnList}
          resetError={this.props.resetError}
          data={this.props.data}
          pages={this.props.pages}
          totalElements={this.props.totalElements}
          loading={this.props.loading}
          listState={this.props.listState}
          isError={this.props.isError}
          columnList={COLUMN_LIST}
          isSortFilterEnabledForCust={true}
        />
      </Container>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.switchList.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: DEFAULT_SWIM_SORT,
      filtered: SWIM_FILTER.defualt,
      resized: [],
      expanded: {}
    }
  }
  return {
    data: state.switchList.data !== undefined ? state.switchList.data : [],
    pages: state.switchList.pages ? state.switchList.pages : 0,
    totalElements: state.switchList.totalElements !== undefined ? state.switchList.totalElements : "Loading ...",
    loading: state.switchList.loading,
    listState: listState,
    isError: state.switchList.isError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateInterventionList: bindActionCreators(updateInterventionList, dispatch),
    updateIndexOnList: bindActionCreators(updateIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch)

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SwitchList));