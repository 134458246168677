import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Container, Button, Row, Col, FormLabel, FormGroup, FormControl, Card ,Alert} from 'react-bootstrap';
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import { geMemberList, resetMemberList } from "./MemberList.actions";
import { hasRoleIn } from 'rbac/RbacHelper';
import ROLES from 'rbac/Roles.json';

const MemberList = ({ history }) => {
  const dispatch = useDispatch();
  const memberListState = useSelector(state => state.memberList);
  const memberListLoading = memberListState.loading;
  const memberList = useSelector(state => state.memberList.data);
  const lastNameState = useSelector(state => state.memberList.lastName);
  const dobState = useSelector(state => state.memberList.dob);
  const [lastName, setLastName] = useState(lastNameState);
  const [dob, setDOB] = useState(dobState);
  const [isMemberSearched, isSearched] = useState(false);

  React.useEffect(() => {
    return () => {
      dispatch(resetMemberList());
    }
  }, []);

  const columns = [
    {
      header: "Member Name",
      accessor: "memberName",
      accessorKey: "memberName",
      width: 200,
      show: true,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Member ID",
      accessor: "memberUniqueAccountId",
      accessorKey: "memberUniqueAccountId",
      width: 100,
      show: true,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "DOB",
      accessor: "memberDateOfBirth",
      accessorKey: "memberDateOfBirth",
      width: 100,
      show: true,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Date,
    },
    {
      header: "Phone #",
      accessor: "phone",
      accessorKey: "phone",
      width: 100,
      show: true,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Customer",
      accessor: "customerNameAbbreviation",
      accessorKey: "customerNameAbbreviation",
      width: 60,
      show: true,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Client",
      accessor: "clientNameAbbreviation",
      accessorKey: "clientNameAbbreviation",
      width: 60,
      show: true,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    }
  ]

  const onSearch = () => {
    isSearched(true);
    let filtered = [];
    filtered.push({ "id": "memberLastName", "value": lastName });
    filtered.push({ "id": "memberDateOfBirth", "value": dob, "operator": "EQ" });
    dispatch(geMemberList(memberListState.pageSize, 0, filtered, dob, lastName));
  }

  const onTableUpdate = (filterValues, sortBy, pageSize, pageNo) => {

    dispatch(geMemberList(pageSize, pageNo, filterValues));


  }

  const onRowClick = (rowInfo) => {
    if (rowInfo !== undefined) {
      history.push(
        `/member/${rowInfo.original.memberInfoUuid}/details`
      );
    }
  }


  return !hasRoleIn([ROLES.ROLE_PHI_ACCESS]) ? (
    <Container className="list-container" fluid>
      <Card variant="primary" className={"mb-3"}>
        <Card.Title className="text-center"><h5>User does not have PHI access</h5></Card.Title>
        </Card>
    </Container>
  ) : (
    <Container className="list-container" fluid>
      {memberListState.error ? <Alert variant="danger">{"User does not have PHI access"}</Alert> : null}
      <Card variant="primary" className={"mb-3"}>
        <Card.Header>
          <Card.Title className="text-center"><h5>Please enter member's LAST NAME and DATE OF BIRTH to begin.</h5></Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={12}>
              <FormGroup className='small-top-spacer' as={Row}>
                <Col md={3}>
                  <FormLabel>Last Name</FormLabel>
                </Col>
                <Col md={9}>
                  <FormControl
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value == "") {
                        dispatch(resetMemberList());
                        isSearched(false);
                      }
                      setLastName(value);
                    }
                    }
                    placeholder="Enter LastName"
                  />
                </Col>
              </FormGroup>
              <FormGroup className='small-top-spacer' as={Row}>
                <Col md={3}>
                  <FormLabel>DOB</FormLabel>
                </Col>
                <Col md={9}>
                  <FormControl
                    type="date"
                    name="dob"
                    value={dob}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value == "") {
                        dispatch(resetMemberList());
                        isSearched(false);
                      }
                      setDOB(value);
                    }
                    }
                    placeholder="Enter Date of Birth"
                  />
                </Col>
              </FormGroup>
              <FormGroup className='small-top-spacer' as={Row}>
                <Col md={3}></Col>
                <Col md={3}>
                  <Button disabled={lastName == "" || dob == ""} className="btn-block btn-text-white" variant="success" onClick={onSearch}>Search</Button>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isMemberSearched ? (<ServerSideReactTable
        columnDefination={columns}
        data={memberList}
        currentPage={memberListState.page}
        filters={memberListState.filtered}
        totalPages={memberListState.totalPages}
        totalRecords={memberListState.totalElements}
        pageSizeOptions={[5, 10, 15, 20]}
        onUpdateTableData={onTableUpdate}
        loading={memberListLoading}
        defaultPageSize={memberListState.pageSize}
        onRowClick={onRowClick}
      />) : null}
    </Container>
  );
}
export default MemberList;