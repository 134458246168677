import PersonBadge from "components/PersonBadge";
import React, { memo } from "react";
import { Alert } from "react-bootstrap";


const ClaimInfoDialog = ({ status, ownerId, ownerName }) => {
  if (status === "requested"){
    return (
    <Alert variant="info">
        Autoclaiming this intervention ...
    </Alert>);
  } else if (status === "failure") {
    return (
      <Alert variant="danger">
          Autoclaiming intervention failed!
      </Alert>);
  }
  let variant = "warning";
  if (status === "success" || status === "dead")
    variant = "success";
  else if (status === "ready"){
    if (this.isOwnedByLoggedInUser(intervention))
      variant = "success";
  }
if (ownerId) {
    return (
      <Alert variant={variant} title={ownerName}>
        <span>Claimed by: </span>
        <PersonBadge variant={variant} name={ownerName} />
        <strong>{ownerName}</strong>
      </Alert>
    );
  }
  else
    return (
      <Alert variant={"danger"}>
        This Drug Group is not claimed by anyone
      </Alert>
    );
}
export default memo(ClaimInfoDialog)