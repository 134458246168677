
import { useDispatch, useSelector } from 'react-redux';
import { updateDynamicSwitchGroupList, updateDynamicSwitchGroupListState, resetError } from './DynamicSwitchGroupList.action';
import { Link, useHistory } from 'react-router-dom';
import { CODEX } from "rbac/RbacContentProvider";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tip from 'components/Tip';
import CodexVersionDisplayBar from 'components/CodexVersionDisplayBar';
import { showNotification } from 'services/NotificationService';
import { useEffect } from 'react';
import { ColumnTypes, FilterTypes } from 'commons/table/Constants';
import ClientSideReactTable from 'commons/table/ClientSideReactTable';

const DEFAULT_PAGE_SIZE = 15;

export default function DynamicSwitchGroupList() {
  const history = useHistory();
  useEffect(() => {
    updateData();
  }, []);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dynamicSwitchGroupList.loading);
  const data = useSelector((state) =>
    state.dynamicSwitchGroupList.data ? state.dynamicSwitchGroupList.data : []
  );
  const totalElements = useSelector((state) =>
    state.dynamicSwitchGroupList.data
      ? state.dynamicSwitchGroupList.data.length
      : "Loading ..."
  );
  const errorFetchingList = useSelector((state) => state.dynamicSwitchGroupList.errorFetchingList);
  const listState = useSelector((state) =>
    state.dynamicSwitchGroupList.listState !== undefined
      ? state.dynamicSwitchGroupList.listState
      : {
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        sorted: [],
        filtered: [],
        resized: [],
        expanded: {}
      }
  );
  const codexVersionId = useSelector((state) => state.codexVersion
    ? state.codexVersion.codexVersionId : null);
  const isWorkingVersionEditable = useSelector((state) => state.codexVersion
    ? state.codexVersion.isWorkingVersionEditable : null)


  useEffect(() => {
    if (errorFetchingList === true) {
      showNotification({
        title: 'Error fetching Data!',
        message: 'There appears to be an issue while fetching the list.',
        position: 'bl',
        type: "error"
      });
      dispatch(resetError());
    }
  }, [errorFetchingList])

  const updateData = (preventUpdate) => {
    if (!preventUpdate && codexVersionId) {
      const { pageSize, page, sorted, filtered } = listState;
      dispatch(updateDynamicSwitchGroupList(pageSize, page, sorted, filtered, codexVersionId));
    } else if (!codexVersionId) {
      showNotification({
        title: 'Oops!',
        message: 'You haven\'t selected a Codex Version to work on.',
        position: 'bl',
        type: "error"
      });
      history.push('/cat');
    }
    dispatch(updateDynamicSwitchGroupListState(listState));
  }

  const allColumnsSortable = true;
  const allColumnsFilterable = true;

  const columns = [
    {
      header: 'Switch Group Name',
      accessor: 'dynamicSwitchGroupName',
      accessorKey: 'dynamicSwitchGroupName',
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
      enableSorting: allColumnsSortable,
      enableColumnFilter: allColumnsFilterable,
    },
    {
      header: 'Active',
      accessor: 'isActive',
      accessorKey: 'isActive',
      columnType: ColumnTypes.Boolean,
      enableSorting: false,
      enableColumnFilter: false,
    }
    , {
      header: 'Projected Success Rate',
      accessor: 'projectedSuccessRate',
      accessorKey: 'projectedSuccessRate',
      columnType: ColumnTypes.Text,
      enableSorting: false,
      enableColumnFilter: false,

    }, {
      header: 'Locked By',
      accessor: 'editableBy.name',
      accessorKey: 'editableBy.name',
      columnType: ColumnTypes.Text,
      enableSorting: true,
      enableColumnFilter: false,
    }
    , {
      header: 'Effective Date',
      accessor: 'effectiveDate',
      accessorKey: 'effectiveDate',
      columnType: ColumnTypes.DateTimeColumn,
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: 'Modified Date',
      accessor: 'lastModifiedDate',
      accessorKey: 'lastModifiedDate',
      columnType: ColumnTypes.DateTimeColumn,
      enableSorting: true,
      enableColumnFilter: false,
    }

  ]

  const onSelect = (row) => {
    if (row !== undefined) {// change with the call to me made to update traget list
      history.push(`/cat/dynamic-switch-groups/${row.original.dynamicSwitchGroupId}`);
    }
  }

  return (
    <Container className="list-container" fluid>
      <CodexVersionDisplayBar />
      <Row className="tiny-bottom-spacer">
        <Col md={6}>
          Total Items: <strong id='sllist-total-count'>{
            totalElements && !loading ? data.length : "Loading ..."}</strong>
        </Col>
        {CODEX.renderComponentIfCanChangeDSG(
          isWorkingVersionEditable ?
            <Col md={6}>
              <Link to={{
                pathname: '/cat/codex/dsg/new',
                state: { params: { codexVersionId: codexVersionId } }
              }}
                className={`btn btn-success btn-sm pull-right`}
              > Add New Group </Link>
            </Col>
            : null
        )}
      </Row>
      <Row>
        <Col md={12}>
          <ClientSideReactTable
            columnDefination={columns}
            data={data}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            addRowSelection={false}
            loading={loading}
            defaultSort={[{
              id: "dynamicSwitchGroupName",
              desc: false,
            }]}
            overrideRowId={'dynamicSwitchGroupId'}
            onRowClick={onSelect}
          />
          <Tip>
            Tip: Hold shift when sorting to multi-sort and disable sort!
          </Tip>
        </Col>
      </Row>
    </Container>
  );
}
