import React, { useState, useRef } from "react";
import { MdDeleteForever } from "react-icons/md";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { showNotification } from "services/NotificationService";
import "assets/css/user.css";

const DeleteUser = (props) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [dashboardId, setDashboardId] = useState(props.dashboardId);

  const deleteDashboard = () => {
    sendAuthenticatedAsyncRequest(
      `/analytics/delete-dashboard/${dashboardId}`,
      "DELETE",
      null,
      (r) => {
        showNotification({
          title: "Action successful!",
          message: "Dashboard deleted successfully.",
          position: "tc",
          type: "success",
        });
        props.handler();
      },
      (r) => {
        showNotification({
          title: "Error!",
          message:"Something went wrong when deleting dashboard. Please review that you have the relevant permissions to perform the requested action.",
          position: "tc",
          type: "error",
        });
      }
    );
  };

    return (
      <OverlayTrigger overlay={<Tooltip>Delete Dashboard</Tooltip>}>
        <MdDeleteForever
          className="user-action-button delete-user"
          size={20}
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this dashboard?")) {
              deleteDashboard();
            }
          }}
        ></MdDeleteForever>
      </OverlayTrigger>
    );
}

export default DeleteUser;
