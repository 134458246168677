import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateSwimSwitchList,
  updateIndexOnList,
  resetError,
} from "./SwimSwitchList.actions";
import { withRouter } from "react-router-dom";
import ResetListState from "components/ResetListState";
import {
  COLUMN,
  createColumnListForSwimSwitchList,
} from "./CreateColumnListForSwimSwitchList";
import CreateActivity from "components/SwitchDetails/CreateActivity";
import Container from "react-bootstrap/Container";
import SwimSwitchListCommon from "./SwimSwitchListCommon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import colors from "lookup/Colors";
import {
  DEFAULT_SWIM_SWITCH_SORT,
  SWIM_SWITCH_FILTER,
} from "lookup/SwimConstants";

const DEFAULT_PAGE_SIZE = 15;

const COLUMN_LIST = [
  COLUMN.customerNameAbbreviation,
  COLUMN.clientNameAbbreviation,
  COLUMN.switchNumber,
  COLUMN.scheduledDate,
  COLUMN.prescriberName,
  COLUMN.prescriberFax,
  COLUMN.prescriberPhone,
  COLUMN.prescriberNpi,
  COLUMN.pharmacyName,
  COLUMN.pharmacyFax,
  COLUMN.pharmacyPhone,
  COLUMN.pharmacyNpi,
  COLUMN.type,
  COLUMN.activityCount,
  COLUMN.status,
];
class SwimSwitchList extends React.Component {
  
  state = {selectedSwitches: []}
  resetList = () => {
    const { pageSize, resized, expanded } = this.props.listState;
    this.props.location.state = null;
    this.props.updateSwimSwitchList(
      pageSize,
      0,
      DEFAULT_SWIM_SWITCH_SORT,
      SWIM_SWITCH_FILTER.defualt,
      resized,
      expanded
    );
  };

  toggleSelection = (selected) => {
    selected = Object.keys(selected).map((row, index) => {
      return row;
    });
    this.setState({ selectedSwitches: selected });
  };

  render() {
    return (
      <Container className="list-container" fluid>
        <Row>
          <Col md={12}>
            <div className="codex-version-display-bar">
              <div style={{ float: "left" }}>
                <span>
                  Total Switches: <strong>{this.props.totalElements}</strong>
                </span>
                <span style={{ paddingLeft: "2vh" }}>
                  {
                    <CreateActivity
                      wfItemId={
                        this.state.selectedSwitches.length > 0
                          ? this.state.selectedSwitches[0]
                          : null
                      }
                      isMemberActivityCreatable={false}
                      disableButton={
                        this.state.selectedSwitches.length > 0 ? false : true
                      }
                      selectedSwitches={this.state.selectedSwitches}
                      openModalButtonStyle="warning"
                      contextIsAW={this.props.location.state ? true : false}
                    />
                  }
                </span>
              </div>
              <strong style={{ float: "center" }}>Swim Switch List</strong>
              <ResetListState
                onReset={this.resetList}
                resetterDisabled={
                  this.props.listState.filtered ===
                    SWIM_SWITCH_FILTER.defualt &&
                  this.props.listState.sorted === DEFAULT_SWIM_SWITCH_SORT
                }
              />
            </div>
          </Col>
        </Row>

        <SwimSwitchListCommon
          updateInterventionList={this.props.updateSwimSwitchList}
          updateIndexOnList={this.props.updateIndexOnList}
          resetError={this.props.resetError}
          data={this.props.data}
          pages={this.props.pages}
          totalElements={this.props.totalElements}
          loading={this.props.loading}
          listState={this.props.location.state ? this.props.location.state : this.props.listState}
          isError={this.props.isError}
          columnList={COLUMN_LIST}
          isSortFilterEnabledForCust={true}
          toggleSelection={this.toggleSelection}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.swimSwitchList.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: DEFAULT_SWIM_SWITCH_SORT,
      filtered: SWIM_SWITCH_FILTER.defualt,
      resized: [],
      expanded: {},
    };
  }
  return {
    data:
      state.swimSwitchList.data !== undefined ? state.swimSwitchList.data : [],
    pages: state.swimSwitchList.pages ? state.swimSwitchList.pages : 0,
    totalElements:
      state.swimSwitchList.totalElements !== undefined
        ? state.swimSwitchList.totalElements
        : "Loading ...",
    loading: state.swimSwitchList.loading,
    listState: listState,
    isError: state.swimSwitchList.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSwimSwitchList: bindActionCreators(updateSwimSwitchList, dispatch),
    updateIndexOnList: bindActionCreators(updateIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwimSwitchList));
