import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DynamicSwitchGroupForm from 'components/DynamicSwitchGroup/DynamicSwitchGroupForm';
import CodexVersionDisplayBar from 'components/CodexVersionDisplayBar';


class DynamicSwitchGroup extends Component{
  state = {
    codexVersionId: this.props.location.state.params.codexVersionId
  }
  render() {
    return (
      <Container fluid>
        <CodexVersionDisplayBar />
        <Row>
          <Col xs={12}>
            <DynamicSwitchGroupForm
              codexVersionId={this.state.codexVersionId}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(DynamicSwitchGroup);

