import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import DescriptionField from "../../../commons/fields/DescriptionField";
import MedispanDrugList from "../MedispanDrugs/MedispanDrugList";
import { getValidItemType } from "./NewDrug.action";
import Spinner from "components/Spinner";
import NewDrugRestrictedItemTypeField from "./NewDrugRestrictedItemTypeField";
import { Button } from "react-bootstrap";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import CheckField from "../../../commons/fields/CheckField";
import "./NewDrug.css";
import TagField from "./TagField";
import { DRUG_OPTION_SCHEMA } from "components/DynamicSwitchGroup/DrugOptionBox/DrugOptionBoxCompact";
import { showNotification } from "services/NotificationService";
import { getDynamicSwitchGroup } from "views/Cat/DynamicSwitchGroupDetails/DynamicSwitchGroupDetails.actions";
import { AgeFields } from "./AgeFields";

const CHOOSE_DEFAULT_DRUG = "Choose a drug from the following list";
const DRUG_VARIABLES = {
  itemType: "",
  instructions: "",
  faxDisplayName: "",
  daysSupply: "",
  priority: "",
  daysSupplyError: null,
  ageLmtReqd: false,
  minAge: null,
  maxAge: null,
  ageError: null,
  offMarketStrengthsExist: false,
};
class NewDrug extends React.Component {
  constructor(props) {
    super(props);
    if (!props.dynamicSwitchGroup) {
      props.history.goBack();
    }
    this.state = this.returnInitailState();
  }

  refreshList = () => {
    this.props.getDynamicSwitchGroup(this.props.dynamicSwitchGroupId);
  };

  onSelect = (selected) => {
    if (!selected) {
      this.setState(this.returnInitailState());
      return;
    }
    const drugName = selected?.medName;
    let drugValue = this.state.drugValue;
    if (selected.medispanDrugId !== this.state.selected.medispanDrugId) {
      drugValue = { ...DRUG_VARIABLES };
    } else {
      drugValue = { ...this.state.drugValue };
    }
    drugValue.faxDisplayName = drugName;
    drugValue.instructions = "Use as Directed";
    drugValue.daysSupply = "30;60;90";
    drugValue.offMarketStrengthsExist =
      selected?.offMarketStrengths && selected.offMarketStrengths !== ""
        ? true
        : false;
    if (drugValue.offMarketStrengthsExist) {
      showNotification({
        title: "Warning!",
        message: "The selected drug contains some off-market strengths",
        position: "tr",
        type: "warning",
      });
    }
    this.setState({
      selected,
      drugValue,
    });
  };

  addDays = (days) => {
    let lastDayList = days ? days.split(";") : [];
    let daysSupplyError = null;
    for (let i = 0; i < lastDayList.length; i++) {
      let lastDay = lastDayList[i];
      if (!lastDay) {
        if (i != lastDayList.length - 1) {
          daysSupplyError = "Days supply cannot be empty";
          break;
        } else {
          continue;
        }
      } else if (lastDayList.length > 5) {
        daysSupplyError = "You cannot add more than 5 supplies!";
        break;
      }
      if (lastDayList.indexOf(lastDay) < i) {
        daysSupplyError = "You cannot add duplicate value!";
        break;
      } else if (lastDay < 15 || lastDay > 365) {
        daysSupplyError = "You can add days from 15 to 365 only!";
        break;
      }
    }
    this.setState({ daysSupplyError });
    return days;
  };

  returnInitailState = () => {
    let shouldAddMoreDrug = false;
    if (this.state?.shouldAddMoreDrug) {
      shouldAddMoreDrug = true;
    }
    return {
      selected: {},
      drugValue: { ...DRUG_VARIABLES },
      isLoadingValidCases: false,
      shouldAddMoreDrug,
    };
  };

  setIsLoadingValidCases = (isLoading = false) => {
    this.setState({
      isLoadingValidCases: isLoading,
    });
  };

  getDrug = () => {
    const { selected } = this.state;
    let drug = selected?.medispanDrugId
      ? `${selected.medName} - ${selected.gpiCode} - ${
          selected.brandGenType == "G" ? "Generic" : "Brand"
        } - ${selected.medForm} - ${selected.routeOfAdministration}`
      : CHOOSE_DEFAULT_DRUG;
    return drug;
  };

  updatedShouldAddMoreDrug = () => {
    this.setState({
      shouldAddMoreDrug: !this.state.shouldAddMoreDrug,
    });
  };

  submitDrug = (values, setSubmitting) => {
    let medDrug = [];
    medDrug.push({ ...values });
    const { workingCodexVersionId, dynamicSwitchGroup } = this.props;
    if (medDrug[0].itemType == "both") {
      medDrug[0].itemType = "target";
      medDrug.push({ ...DRUG_VARIABLES });
      medDrug[1].itemType = "switch";
    } else if (medDrug[0].itemType == "switch") {
      medDrug[0] = { ...medDrug[0], ...DRUG_VARIABLES, itemType: "switch" };
    }
    if (medDrug[0].daysSupply === "") {
      medDrug[0].daysSupply = "30,60,90";
    }
    if (medDrug[0].faxDisplayName === "") {
      medDrug[0].faxDisplayName = this.state.drugValue.faxDisplayName;
    }
    if (medDrug[0].instructions === "") {
      medDrug[0].instructions = "Use as Directed";
    }
    if (!medDrug[0].ageLmtReqd) {
      medDrug[0].minAge = null;
      medDrug[0].maxAge = null;
    }
    for (const element of medDrug) {
      element.daysSupply = element.daysSupply.replace(/,+$/, "");
      element.daysSupply = element.daysSupply
        ? element.daysSupply.trim().replace(" ", "").replace(/,/g, ";")
        : element.daysSupply;
      element.medispanDrugId = this.state.selected.medispanDrugId;
    }
    setSubmitting(true);
    sendAuthenticatedAsyncRequest(
      `/cat/codex-version/${workingCodexVersionId}/dynamic-switch-groups/${dynamicSwitchGroup.dynamicSwitchGroupId}/add-drugs`,
      "POST",
      medDrug,
      (r) => {
        setSubmitting(false);
        if (values.offMarketStrengthsExist) {
          showNotification({
            title: "Warning!",
            message: "The added drug contains some off-market strengths",
            position: "tr",
            type: "warning",
          });
        }
        showNotification({
          title: "New Drug added successfully!",
          position: "tr",
          type: "success",
        });
        if (this.state.shouldAddMoreDrug) {
          this.setState(this.returnInitailState());
        } else {
          this.props.history.goBack();
        }
      },
      (r) => {
        setSubmitting(false);
        showNotification({
          title: "Could not add the drug",
          message: "Something went wrong. Please try again later",
          position: "tr",
          type: "error",
        });
      }
    );
  };

  addDaysSupply = (days) => {
    if (days !== "" || days === 0) {
      let daysSupply = this.addDays(days);
      this.setState({
        drugValue: { ...this.state.drugValue, daysSupply: daysSupply },
      });
    } else {
      this.setState({
        drugValue: { ...this.state.drugValue, daysSupply: "" },
        daysSupplyError: null,
      });
    }
  };

  setAgeError = (ageError) => {
    this.setState({
      ageError,
    });
  };

  render() {
    let drugName = this.getDrug();
    const { drugValue, daysSupplyError } = this.state;
    return (
      <Container fluid className="top-spacer">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <div>
              <Card variant="primary">
                <Card.Header>
                  <Card.Title className="text-center">
                    <h4>{"Add New Drug"}</h4>
                  </Card.Title>
                </Card.Header>
                <Card.Body className="mt-3">
                  <DescriptionField
                    isEditMode={true}
                    name={"Dynamic Switch group Name"}
                    id={"dynamicSwitchGroupName"}
                    error={false}
                    savedValue={
                      this.props.dynamicSwitchGroup?.dynamicSwitchGroupName
                    }
                    updatedValue={
                      this.props.dynamicSwitchGroup?.dynamicSwitchGroupName
                    }
                    handleChange={null}
                    handleBlur={null}
                    compKey={"dynamicSwitchGroupName"}
                    key={`dynamicSwitchGroupName`}
                    isDisabled={true}
                    isHorizontalView={true}
                  />
                  <DescriptionField
                    isEditMode={true}
                    name={"Drug To Add"}
                    id={"drugToAdd"}
                    error={false}
                    savedValue={CHOOSE_DEFAULT_DRUG}
                    updatedValue={drugName}
                    handleChange={null}
                    handleBlur={null}
                    compKey={"drugToAdd"}
                    key={`drugToAdd`}
                    isDisabled={true}
                    isHorizontalView={true}
                  />
                  {drugName != CHOOSE_DEFAULT_DRUG &&
                  this.state.isLoadingValidCases ? (
                    <Spinner />
                  ) : null}
                  <Formik
                    enableReinitialize={true}
                    initialValues={drugValue}
                    onSubmit={(values, { setSubmitting }) => {
                      this.submitDrug(values, setSubmitting);
                    }}
                    validationSchema={DRUG_OPTION_SCHEMA}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="mb-2">
                          <NewDrugRestrictedItemTypeField
                            medispanDrugId={this.state.selected.medispanDrugId}
                            savedValue={drugValue.itemType}
                            updatedValue={values.itemType}
                            values={values}
                            handleBlur={handleBlur}
                            setIsLoadingValidCases={this.setIsLoadingValidCases}
                            isLoadingValidCases={this.state.isLoadingValidCases}
                            isVisible={
                              drugName != CHOOSE_DEFAULT_DRUG &&
                              !this.state.isLoadingValidCases
                            }
                          />
                        </div>
                        {drugName != CHOOSE_DEFAULT_DRUG &&
                        !this.state.isLoadingValidCases &&
                        (values.itemType == "target" ||
                          values.itemType == "both") ? (
                          <>
                            <div className="mb-2">
                              <DescriptionField
                                isEditMode={true}
                                name={"Priority"}
                                id={"priority"}
                                error={
                                  errors.priority &&
                                  touched.priority &&
                                  errors.priority
                                }
                                savedValue={
                                  drugValue.priority &&
                                  drugValue.priority != 999
                                    ? drugValue.priority
                                    : "-"
                                }
                                updatedValue={values.priority}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                compKey={"drug-priority"}
                                controlProps={{
                                  type: "number",
                                }}
                                key={`drug-option-priority-${drugValue.medispanDrugId}`}
                                isHorizontalView={true}
                              />
                            </div>
                            <div className="mb-2">
                              <DescriptionField
                                isEditMode={true}
                                name={"Display Name"}
                                id={"faxDisplayName"}
                                error={
                                  errors.faxDisplayName &&
                                  touched.faxDisplayName &&
                                  errors.faxDisplayName
                                }
                                savedValue={drugValue.faxDisplayName}
                                updatedValue={values.faxDisplayName}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                compKey={"faxDisplayName"}
                                key={`faxDisplayName`}
                                isHorizontalView={true}
                              />
                            </div>
                            <div className="mb-2">
                              <DescriptionField
                                isEditMode={true}
                                name={"Instructions"}
                                id={"instructions"}
                                error={errors.instructions}
                                savedValue={drugValue.instructions}
                                updatedValue={values.instructions}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                compKey={"instructions"}
                                key={`instructions`}
                                isHorizontalView={true}
                                placeholder={"Use as Directed"}
                              />
                            </div>
                            <AgeFields
                              handleChange={handleChange}
                              values={values}
                              drugValue={drugValue}
                              handleBlur={handleBlur}
                              setAgeError={this.setAgeError}
                              ageError={this.state.ageError}
                            />
                            <div className="mb-2 days-supply row">
                              <Col md={3}>
                                Days Supply (Add comma separated values)
                              </Col>
                              <Col className="days-supply-field" md={9}>
                                <TagField
                                  value={drugValue.daysSupply}
                                  error={daysSupplyError}
                                  onChange={this.addDaysSupply}
                                />
                              </Col>
                            </div>
                          </>
                        ) : null}
                        <Row className="align-items-md-center bottom-spacer top-spacer">
                          <Col md={7}>
                            <CheckField
                              key={`shouldAddMoreDrug`}
                              shouldDisplayTitle={false}
                              compKey={"should-add-more-drug"}
                              id={"shouldAddMoreDrug"}
                              title={""}
                              isEditMode={true}
                              label={"Add more Drugs"}
                              handleBlur={() => {}}
                              handleChange={this.updatedShouldAddMoreDrug}
                              value={this.state.shouldAddMoreDrug}
                            />
                          </Col>
                          <Col md={2}>
                            <Button
                              variant="outline-dark"
                              block
                              onClick={this.props.history.goBack}
                              disabled={isSubmitting}
                              className="btn-block"
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col md={3}>
                            <Button
                              className="btn-block"
                              type="submit"
                              variant="success"
                              block
                              disabled={
                                drugName == CHOOSE_DEFAULT_DRUG ||
                                this.state.isLoadingValidCases ||
                                !values.itemType ||
                                isSubmitting ||
                                daysSupplyError ||
                                this.state.ageError
                              }
                            >
                              {isSubmitting ? "Adding Drug... " : "Add Drug"}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
                <MedispanDrugList
                  showResetListButton
                  placedOnLightBg
                  isSelectRow={true}
                  selected={this.state.selected}
                  onSelect={this.onSelect}
                />
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    workingCodexVersionId: state.codexVersion
      ? state.codexVersion.codexVersionId
      : null,
    dynamicSwitchGroup: state.dynamicSwitchGroupDetails
      ? state.dynamicSwitchGroupDetails.dynamicSwitchGroup
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getValidItemType: bindActionCreators(getValidItemType, dispatch),
    getDynamicSwitchGroup: bindActionCreators(getDynamicSwitchGroup, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewDrug));
