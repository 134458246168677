import React, { useState, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { FormControl } from 'react-bootstrap';

const SearchableInput = ({ column, handleFilterChange, filterValue }) => {
  const { id } = column;
  const value = filterValue || '';

  const [searchTerm, setSearchTerm] = useState(value);
  const didMountRef = useRef(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const debouncedFilter = useCallback(debounce(handleFilterChange, 500), [handleFilterChange]);

  React.useEffect(() => {
    setSearchTerm(value)
  }, [value]);


  React.useEffect(() => {
    if (didMountRef.current) {
      // do something on state change here
      debouncedFilter(id, searchTerm,column);
    } else {
      didMountRef.current = true;
    }
    return () => {
      debouncedFilter.cancel();
    };
  }, [searchTerm]);

  return (
    <FormControl
      type="text"
      id={`searchableinput${id}`}
      placeholder=""
      value={searchTerm}
      onChange={handleInputChange}
      onBlur={() => {
        if (searchTerm === '') {
          handleFilterChange(id, '',column);
        }
      }}
    />
  );
};

export default SearchableInput;