import { RSAA } from "redux-api-middleware";
import config from "config";
import {
  AUTH_TOKEN_HEADER_KEY,
  getAuthorizationToken,
} from "services/AuthService";

export const updateUnprocessedTranscriberFaxList = (
  pageSize,
  page,
  sorted,
  filtered,
  resized,
  expanded
) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/agent-workspace/transcription-list`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        {
          type: "UPDATE_UNPROCESSED_TRANSCRIBER_FAX_REQUEST",
          payload: {
            listState: { pageSize, page, sorted, filtered, resized, expanded },
          },
        },
        "UPDATE_UNPROCESSED_TRANSCRIBER_FAX_SUCCESS",
        "UPDATE_UNPROCESSED_TRANSCRIBER_FAX_FAILURE",
      ],
    },
  };
};
export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_UNPROCESSED_TRANSCRIBER_FAX",
  };
};
export const updateTranscriberIndexOnList = (index) => {
  return {
    type: "UPDATE_UNPROCESSED_TRANSCRIBER_FAX_INDEX_ON_LIST",
    indexOnList: index,
  };
};
