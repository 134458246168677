
import { createSlice } from '@reduxjs/toolkit';
import { getSwitchActivitiesFailureAction, getSwitchActivitiesRequestAction, getSwitchActivitiesSuccessAction } from './ActivitiesPopup.actions';
import { AgentWorkspaceConstants } from 'lookup/AgentWorkspaceConstants';

export const INITAL_STATE = {
  data: [],
  totalPages: 0,
  pageSize: 20,
  page: 0,
  error: false,
  loading: false,
  totalElements: "Loading",
  filtered: [{id: "status", value: AgentWorkspaceConstants.activityStatusOpenFilter}],
  sorted: [AgentWorkspaceConstants.DEFAULT_SORT],
  isDefaultFilter:true,
  resized: [],
  expanded: {},
}


export const switchActivitiesSlice = createSlice({
  name: "switchActivities",
  initialState: INITAL_STATE,
  reducers: {
    getSwitchActivitiesSuccess: (state, action) => {
      state.data = action.payload.records;
      state.totalPages = action.payload.totalPages;
      state.pageSize = action.payload.pageSize;
      state.page = action.payload.page;
      state.sorted = action.payload.sorted;
      state.filtered = action.payload.filtered;
      state.totalElements = action.payload.totalElements;
      state.error = false;
      state.loading = false;
    },
    getSwitchActivitiesFailure: (state, action) => {
      state.loading = false;
      state.error = false;
      state.totalElements = "Failed";
      state.data =[];
    },
    getSwitchActivitiesRequest: (state, action) => {
      state.loading = true;
      state.isDefaultFilter = false;
      state.totalElements = "Loading";
    },
    resetList: (state, action) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "member list" RSAA action.
      .addCase(getSwitchActivitiesRequestAction, (state, action) => {
        switchActivitiesSlice.caseReducers.getSwitchActivitiesRequest(state, action);
      })
      .addCase(getSwitchActivitiesSuccessAction, (state, action) => {
        switchActivitiesSlice.caseReducers.getSwitchActivitiesSuccess(state, action);
      })
      .addCase(getSwitchActivitiesFailureAction, (state, action) => {
        switchActivitiesSlice.caseReducers.getSwitchActivitiesFailure(state, action);
      })

  }
});


export const { getSwitchActivitiesSuccess, getSwitchActivitiesFailure, getSwitchActivitiesRequest, resetList } = switchActivitiesSlice.actions;
export const switchActivitiesState = (state) => state.switchActivities;
export default switchActivitiesSlice.reducer;