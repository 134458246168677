import React from 'react';
import { DateRangePicker } from "rsuite";
import Form from 'react-bootstrap/Form';

const DateRangePickerFilter = ({ column, handleFilterChange, filterValue }) => {
    const { id } = column;
    const value = filterValue || null;
    return (
            <DateRangePicker
                id={`${column.id}Filter`}
                value={value}
                onChange={e => handleFilterChange(id, e, column)}
                format='yyyy-MM-dd'
                placeholder='Select Date Range'
                placement={column.placeDatePickerAtEnd ? 'autoVerticalEnd' : 'autoVerticalStart'}
            />
    );
}

export default DateRangePickerFilter;