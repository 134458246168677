import React, {Component} from 'react';

import ButtonWithPopover from './ButtonWithPopover';


import Button from 'react-bootstrap/Button';


class SaveDispositionPopover extends Component {

  submitDisposition(values) {
    this.props.submitDisposition(values);
  }

  render() {
     const reference = "saveConfirmation";
     const values=this.props.values;
     return (
         <ButtonWithPopover
          placement= "top"
          customRef={reference+"bwp"}
          buttonVariant="success"
          buttonText="Save Disposition"
          buttonIsBlock={true}
          buttonIsDisabled={this.props.disallowEditing || this.props.disableActions}
          popoverTitle="Save Switch Disposition"
          buttonId='switchdisposition-btn-saveDisposition'
          >
          <p className="confirmation-text">
            Do you want to <b>save</b> this switch disposition?
          </p>
          <div className="align-content-right">
            <Button id='switchdisposition-btn-save' className="left-spacer" variant="success" onClick={() => this.submitDisposition(values)}>
              Save 
            </Button>
          </div>
         </ButtonWithPopover>
    );
  }
}

export default SaveDispositionPopover;
