import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-bootstrap';
import { Formik } from "formik";
import * as Yup from 'yup';
import { submitDsg } from 'views/Cat/DynamicSwitchGroup/DynamicSwitchGroup.action';
import DescriptionField from "../../commons/fields/DescriptionField";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormLabel from 'react-bootstrap/FormLabel';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CAT_CONSTANTS from 'lookup/CatConstants';
import { withRouter } from 'react-router-dom';

class DynamicSwitchGroupForm extends React.Component {
  state = {
    dynamicSwitchGroupId: '',
    groupName: '',
    additionalClassname: '',
    groupClassname: '',
    groupSubClassname: '',
    activeRadarClassname: '',
    notes: '',
    codexVersionId: this.props.codexVersionId
  }

  submitDynamicSwitchGroup = (values, setSubmitting) => {
    {
      var dsgDto = {
        "dynamicSwitchGroupName": values.groupName || "",
        "dynamicSwitchGroupClassName": values.groupClassname || "",
        "dynamicSwitchGroupSubClassName": values.groupSubClassname || "",
        "additionalClassName": values.additionalClassname || "",
        "activeRadarClassname": values.activeRadarClassname,
        "isActive": "false",
        "isDeleted": "false",
        "pharmacistNotes": values.notes || ""
      };
      setSubmitting(false);
      this.props.submitDsg(dsgDto, this.state.codexVersionId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dynamicSwitchGroupId !== prevProps.dynamicSwitchGroupId) {
      this.setState({ dynamicSwitchGroupId: this.props.dynamicSwitchGroupId });
      this.props.history.push(`/cat/dynamic-switch-groups/${this.props.dynamicSwitchGroupId}`);
    }
  }
  render() {
    const { groupName, additionalClassname, groupClassname, groupSubClassname, activeRadarClassname, notes } = this.state;
    const DrugGroupSchema = Yup.object().shape({
      groupName: Yup.string()
        .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255)
        .required("Required"),
      additionalClassname: Yup.string()
        .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255),
      groupClassname: Yup.string()
        .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255),
      groupSubClassname: Yup.string()
        .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255),
      activeRadarClassname: Yup.string()
        .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255),
      notes: Yup.string()
        .max(1024, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 1024),
    });
    return (
      <Container fluid className='top-spacer'>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <div>
              <Card variant="primary">
                <Card.Header>
                  <Card.Title className="text-center"><h4>Dynamic Switch Group </h4></Card.Title>
                </Card.Header>
                <Card.Body>
                  <Formik
                    initialValues={{
                      groupName: groupName || "",
                      additionalClassname: additionalClassname || "",
                      groupClassname: groupClassname || "",
                      groupSubClassname: groupSubClassname || "",
                      activeRadarClassname: activeRadarClassname || "",
                      notes: notes || ""
                    }}
                    validationSchema={DrugGroupSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      this.submitDynamicSwitchGroup(values, setSubmitting);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      handleReset,
                      isSubmitting
                    }) => (
                      <Form onSubmit={handleSubmit} onReset={handleReset}>
                        <Row>
                          <Col md={12}>
                            <div className="small-top-spacer">
                              <Row>
                                <Col md={12}>
                                  <FormGroup as={Row} style={{ marginBottom: '0rem' }}>
                                    <Col md={3} style={{ paddingTop: '26px' }}>
                                      <FormLabel>Group Name*</FormLabel>
                                    </Col>
                                    <Col md={9}>
                                      <DescriptionField
                                        isEditMode={true}
                                        //name={"Group Name"}
                                        id={"groupName"}
                                        error={errors.groupName && touched.groupName && errors.groupName}
                                        savedValue={groupName}
                                        updatedValue={values.groupName}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        isFieldRequired={this.isFieldRequired}
                                        compKey={"dsg-form-group-name"}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup as={Row} style={{ marginBottom: '0rem' }}>
                                    <Col md={3} style={{ paddingTop: '26px' }}>
                                      <FormLabel>Group Classname</FormLabel>
                                    </Col>
                                    <Col md={9}>
                                      <DescriptionField
                                        isEditMode={true}
                                        // name={"Group Classname"}
                                        id={"groupClassname"}
                                        error={errors.groupClassname && touched.groupClassname && errors.groupClassname}
                                        savedValue={groupClassname}
                                        updatedValue={values.groupClassname}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        isFieldRequired={this.isFieldRequired}
                                        compKey={"dsg-form-group-classname"}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup as={Row} style={{ marginBottom: '0rem' }}>
                                    <Col md={3} style={{ paddingTop: '26px' }}>
                                      <FormLabel>Additional Classname</FormLabel>
                                    </Col>
                                    <Col md={9}>
                                      <DescriptionField
                                        isEditMode={true}
                                        //name={"Additional Classname"}
                                        id={"additionalClassname"}
                                        error={errors.additionalClassname && touched.additionalClassname && errors.additionalClassname}
                                        savedValue={additionalClassname}
                                        updatedValue={values.additionalClassname}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        isFieldRequired={this.isFieldRequired}
                                        compKey={"dsg-form-additional-classname"}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup as={Row} style={{ marginBottom: '0rem' }}>
                                    <Col md={3} style={{ paddingTop: '26px' }}>
                                      <FormLabel>Group Sub-Classname</FormLabel>
                                    </Col>
                                    <Col md={9}>
                                      <DescriptionField
                                        isEditMode={true}
                                        //name={"Group Sub-Class Name"}
                                        id={"groupSubClassname"}
                                        error={errors.groupSubClassname && touched.groupSubClassname && errors.groupSubClassname}
                                        savedValue={groupSubClassname}
                                        updatedValue={values.groupSubClassname}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        isFieldRequired={this.isFieldRequired}
                                        compKey={"dsg-form-group-sub-classname"}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup as={Row} style={{ marginBottom: '0rem' }}>
                                    <Col md={3} style={{ paddingTop: '26px' }}>
                                      <FormLabel>Active Radar Classname</FormLabel>
                                    </Col>
                                    <Col md={9}>
                                      <DescriptionField
                                        isEditMode={true}
                                        // name={"Active Radar Classname"}
                                        id={"activeRadarClassname"}
                                        error={errors.activeRadarClassname && touched.activeRadarClassname && errors.activeRadarClassname}
                                        savedValue={activeRadarClassname}
                                        updatedValue={values.activeRadarClassname}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        isFieldRequired={this.isFieldRequired}
                                        compKey={"dsg-form-active-radar-classname"}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup as={Row}>
                                    <Col md={3} style={{ paddingTop: '26px' }}>
                                      <FormLabel>Pharmacist Notes</FormLabel>
                                    </Col>
                                    <Col md={9}>
                                      <DescriptionField
                                        isEditMode={true}
                                        //name={"Notes"}
                                        id={"notes"}
                                        error={errors.notes && touched.notes && errors.notes}
                                        savedValue={notes}
                                        updatedValue={values.notes}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        isFieldRequired={this.isFieldRequired}
                                        compKey={"dsg-form-notes"}
                                        controlProps={{
                                          as: "textarea",
                                          rows: 1
                                        }}
                                      />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col md={{ span: 3, offset: 9 }}>
                                  <Button type="submit" block disabled={isSubmitting} className="btn-block mt-1">
                                    {
                                      isSubmitting ? 'Submitting... ' : 'Submit'
                                    }
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container >
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    groupName: state.dynamicSwitchGroup.groupName,
    dynamicSwitchGroupId: state.dynamicSwitchGroup.dynamicSwitchGroupId,
    additionalClassname: state.dynamicSwitchGroup.additionalClassname,
    groupClassname: state.dynamicSwitchGroup.groupClassname,
    groupSubClassname: state.dynamicSwitchGroup.groupSubClassname,
    activeRadarClassname: state.dynamicSwitchGroup.activeRadarClassname,
    notes: state.dynamicSwitchGroup.notes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitDsg: bindActionCreators(submitDsg, dispatch)
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DynamicSwitchGroupForm));

