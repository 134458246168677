import React from 'react';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import { calculateAge } from '../../services/MomentUtils';
import { getGender } from '../../services/UtilityService';
import { getColorForPatientAge } from '../../services/UtilityService';
import Colors from 'lookup/Colors';

const styles = {
  card: {padding: 10, border: "none"},
  cardHeader: {paddingLeft: 0,backgroundColor: 'white', letterSpacing: 2}
}
const PatientInformationCard = (props) => (
  <Card style={styles.card} variant="info">
    <Card.Header style={styles.cardHeader}>
         MEMBER INFORMATION
        </Card.Header>
    <Card.Body>
      <Table hover className="intv-details fixed" >
        <tbody>
          <tr>
            <th>Age </th>
            <td>
              <Badge
                style={{
                  backgroundColor: getColorForPatientAge(calculateAge(props.patient.dateOfBirth)),
                  color: Colors['rm-white'],
                  fontSize: "1.2em"
                }}
              >
                {calculateAge(props.patient.dateOfBirth)} years
              </Badge>
            </td>
          </tr>
          <tr>
            <th>Mem #</th>
            <td>{props.patient.memberNumber}</td>
          </tr>
          <tr>
            <th>Gender</th>
            <td>
              <span>
                {getGender(props.patient.gender)}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);

export default PatientInformationCard;