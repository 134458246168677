import { memo } from "react";
import TagBox from 'components/TagBox/TagBox';
import './NewDrug.css';
import Form from 'react-bootstrap/Form';

const TagField = ({
  value = undefined,
  error = undefined,
  onChange = () => {},
}) => {
  const onKeyPress = (e) => {
    if ((e.key === 'Enter' || e.key === ',') && e.target.value !== "") {
      e.preventDefault();
      if (!value.split(";").includes(e.target.value)) {
        let newValue = value;
        newValue = (!newValue?
        e.target.value
          : newValue + ";" + e.target.value);
        onChange(newValue);
      }
      e.target.value = "";
    }
  }

  const removeSwitch = (idx) => {
    let dayList = value.split(';');
    let index = dayList.indexOf(idx);
    if (index > -1) {
      dayList.splice(index, 1);
    }
    let newValue = dayList.join(';');
    onChange(newValue);
  }

  return (
    <>
      <span className='days-supply-row form-control'>
        {value ?
          <TagBox
            tags={value.split(";")}
            removeSwitch={removeSwitch}
          /> : undefined
        }
        <input
          className='days-supply-input w-100'
          onKeyPress={onKeyPress}
          placeholder={!value ? "30;60;90" : ""}
          type={"number"}
        />
      </span>
      <Form.Text
        id={"new-drug-days-supply-error-msg"}
        className="text-small text-danger"
      >
        {error}
      </Form.Text>
    </>
  );
};

export default memo(TagField);