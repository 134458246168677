export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {

    case 'UPDATE_SWITCHES_FOR_BATCH_LIST_REQUEST':
      newState = Object.assign({}, previousState, {
        switchListState: action.payload.listState,
        switchLoading: true,
        loading: false,
        isError: false
      });
      break;
    case 'UPDATE_SWITCHES_FOR_BATCH_LIST_SUCCESS':
      newState = Object.assign({}, previousState, {
        switchData: action.payload.records,
        loading: false,
        switchLoading: false,
        isError: false,
      });
      break;
    case 'UPDATE_SWITCHES_FOR_BATCH_LIST_FAILURE':
      newState = Object.assign({}, previousState, {
        switchData: [],
        switchLoading: false,
        loading: false,
        isError: true,
      });
      break;
    case 'UPDATE_BATCH_LIST_SUCCESS':
      newState = Object.assign({}, previousState, {
        data: action.payload.records,
        pages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        loading: false,
        isError: false
      });
      newState.listState.page = action.payload.page;
      break;
    case 'UPDATE_BATCH_LIST_REQUEST':
      newState = Object.assign({}, previousState, {
        listState: action.payload.listState,
        totalElements: "Loading ...",
        loading: true,
        isError: false
      });

      break;
    case 'UPDATE_BATCH_LIST_FAILURE':
      newState = Object.assign({}, previousState, {
        data: undefined,
        pages: 0,
        totalElements: 0,
        loading: false,
        isError: true,
      });

      break;
    case 'RESET_ERROR_MESSAGE_BATCH_LIST':
      newState = Object.assign({}, previousState, {
        isError: false
      });
      break;
    default:
      break;
  }

  return newState;
}