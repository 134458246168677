
import React, { Component } from 'react';

import '../Login.css';

import {sendAsyncRequest} from '../../../services/AsyncRequestService';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'components/Spinner';

class CognitoLogin extends Component{
  state = {
  }
  
  handleLogin = () => {
    let url = "/login";
    let method = "GET";
    sendAsyncRequest(url, method, null, this.onLoginSuccess, this.onLoginFailure);
  }

  onLoginSuccess = (response) => {
    window.location = response.data;
  }

  onLoginFailure = (error) => {
  	this.setState({error: "Something went wrong"});
  }

  componentDidMount() {
    this.handleLogin();
  }

  render() {
    return (
      <div className="">
      <Container fluid >
        <Row>
          <Col className="text-align-center" md={{ span: 4, offset: 4}}>
            <Spinner />
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default CognitoLogin;