import React from "react";
import { FilterTypes } from "./Constants";
import Badge from "react-bootstrap/Badge";
import "./style.css";

export const FilterView = ({ column, filterValues, customFilter = false }) => {
  const { filterType } = column;
  let filterValue = filterValues ? filterValues : "";
  filterValue = filterValue instanceof Array ? filterValue.join(", ") : filterValue;
  if (filterValue) {
    switch (filterType) {
      case FilterTypes.SingleSelect:
      case FilterTypes.SearchableInput:
      case FilterTypes.Date:
        return (
          <Badge className="customBadge" bg="success">
            {filterValue}
          </Badge>
        );
      case FilterTypes.MultiSelect: 
      default:
        return null;
    }
  } else return null;
};
