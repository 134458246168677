import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import TopBar from "../components/Topbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import Container from "react-bootstrap/Container";

import { VersionBadge } from "../components/VersionBadge";
import { version } from "config";
import { flattenedRoutes } from "../../routes/DashboardRoutes";
import { DASHBOARD_LAYOUT } from "rbac/RbacContentProvider";
import {
  getBackendCommitVersionIdPromise,
  getBackendReleaseNumberPromise,
} from "../../services/AuthService";
//had to use both because on login, the value gets stored in storage a bit late hence the use of promise.
//and if browser window gets refreshed then promise becomes unresolved again hence the use of func that fetched from storage

//React Error Handler
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../components/ErrorFallback";
import { Row, Col, Collapse } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { hasOnlyAWRole } from "rbac/RbacHelper";

const Dashboard = (props) => {
  const [dashboardFlattenedRoutes] = useState(flattenedRoutes());
  const [backendCommitVersion, setBackendCommitVersion] = useState(null);
  const [backendReleaseNumber, setBackendReleaseVersion] = useState(null);
  const [showSideBar, setShowSidebar] = useState(!(hasOnlyAWRole()));

  useEffect(() => {
    getBackendCommitVersionIdPromise()
      .then((ver) => setBackendCommitVersion(ver))
      .catch((err) => setBackendCommitVersion("error"));
    getBackendReleaseNumberPromise()
      .then((ver) => setBackendReleaseVersion(ver))
      .catch((err) => setBackendReleaseVersion("error"));
  }, []);

  const handleSidebarClick = () => setShowSidebar(!showSideBar);

  return (
    <div>
      <TopBar handleSidebarClick={handleSidebarClick} />
      <Container fluid className="top-spacer">
        <Row>
          <Collapse in={showSideBar} dimension="width" timeout={300}>
            <Col md={2}>
              {DASHBOARD_LAYOUT.provideMetricsBox()}
              <Sidebar />
              <VersionBadge
                frontendCommitVersionId={version.commitVersion}
                backendCommitVersionId={backendCommitVersion}
                frontendReleaseNumber={version.releaseNumber}
                backendReleaseNumber={backendReleaseNumber}
              />
            </Col>
          </Collapse>
          <Col md={showSideBar ? 10 : 12}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Switch>
                {!dashboardFlattenedRoutes
                  ? null
                  : dashboardFlattenedRoutes.map((route, idx) => {
                      if (route.redirect)
                        return (
                          <Redirect from={route.path} to={route.to} key={idx} />
                        );
                      else if (route.path && route.component) {
                        return (
                          <Route
                            path={route.path}
                            component={route.component}
                            key={idx}
                            exact
                          />
                        );
                      } else return null;
                    })}
              </Switch>
            </ErrorBoundary>
          </Col>
        </Row>
        <Toaster />
      </Container>
      <Footer />
    </div>
  );
};

export default Dashboard;
