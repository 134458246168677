import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Multiselect } from "multiselect-react-dropdown";

import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import AdminPopupDismissableModal from "components/adminPopupDismissableModal";
import { getErrorMessage } from "services/UtilityService";
import { showNotification } from "services/NotificationService";

class RegisterNewUser extends Component {
  state = {
    showButton: this.props.showButton,
    roles: this.props.roles,
  };



  registerUser = (values, setSubmitting, closeModalHandle) => {
    sendAuthenticatedAsyncRequest(
      `/user/register`,
      "POST",
      values,
      (r) => {
        this.setState({ asyncInProgress: false });
        setSubmitting(false);
        showNotification({
          title: "Action successful!",
          message: "User registered successfully.",
          position: "tc",
          type: "success"
        });
        closeModalHandle();
        this.props.handler();
      },
      (r) => {
        this.setState({ asyncInProgress: false });
        setSubmitting(false);
        showNotification({
          title: "Error!",
          message:
            getErrorMessage(r.data) ||
            getErrorMessage("UNABLE_TO_REGISTER_USER"),
          position: "tc",
          type: "error"
        });
      }
    );
  };

  onMultiSelectChange = (options, values) => {
    let tempRolesList = [];
    options.map((x) => tempRolesList.push(x.key));
    this.setState({ rolesList: tempRolesList });
    values.roles = tempRolesList;
  };
  invertShowbutton = () => {
    this.setState((prevState) => ({
      showButton: !prevState.showButton,
    }));
  };

  render() {
    const { showButton } = this.state;
    if (showButton) {
      return (
        <Button
          onClick={() => {
            this.invertShowbutton();
          }}
          size="sm"
          style={{
            float: "right",
            marginLeft: "1rem",
          }}
          variant="success"
          id="reset-state-btn-filter"
          // variant="outline-light"
        >
          Add Users
        </Button>
      );
    }
    const modalRef = "registerUserModal";
    const { invertAddUser } = this.state;

    const rolesList = this.props.roles
      ? this.props.roles.map((c) => ({
          value: c.roleDisplayName,
          key: c.name,
        }))
      : [];
    const registerUserFormSchema = Yup.object().shape({
      name: Yup.string()
        .max(60, "Maximum Length: " + 60)
        .required("Required"),
      email: Yup.string().email().required("Required"),
      roles: Yup.array().min(1, "Atleast select 1 role"),
      ipAddress: Yup.string().matches(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i,
        "Not a valid IP address"
      ),
    });
    return (
      <>
        <Button
          onClick={() => {
            this.invertShowbutton();
          }}
          size="sm"
          style={{
            float: "right",
            marginLeft: "1rem",
          }}
          variant="success"
          id="reset-state-btn-filter"
          // variant="outline-light"
        >
          Add Users
        </Button>

        <AdminPopupDismissableModal
          ref={modalRef}
          openModalButtonText="Register User"
          openModalButtonStyle="info"
          openModalButtonBlock={true}
          openModalButtonDisabled={false}
          openModalButtonId="user-register-btn-popup"
          preventCloseWhenClickedOutsideModal={true}
          hideFooter={true}
          initialStateOpen={true}
          onClose={invertAddUser}
          title="Register User"
        >
          <Formik
            initialValues={{ name: "", email: "", roles: [], ipAddress: "" }}
            onSubmit={(values, { setSubmitting }) => {
              this.registerUser(
                values,
                setSubmitting,
                this.refs[modalRef].handleClose
              );
            }}
            validationSchema={registerUserFormSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              disableAction,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        name="name"
                        onBlur={handleBlur}
                      ></Form.Control>
                      <Form.Text className="text-small text-danger">
                        {errors.name && touched.name && errors.name}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        name="email"
                        onBlur={handleBlur}
                      ></Form.Control>
                      <Form.Text className="text-small text-danger">
                        {errors.email && touched.email && errors.email}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Roles</Form.Label>
                      <Multiselect
                        placeholder="Select Role"
                        options={rolesList}
                        displayValue="value"
                        name="roles"
                        value={values.roles}
                        onSelect={(e) => this.onMultiSelectChange(e, values)}
                        onRemove={(e) => this.onMultiSelectChange(e, values)}
                        style={{
                          chips: {
                            background: "#007BBB",
                          },
                        }}
                        showArrow
                        hidePlaceholder
                        disable={disableAction}
                      ></Multiselect>
                      <Form.Text className="text-small text-danger">
                        {errors.roles && touched.roles && errors.roles}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>IP Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.ipAddress}
                        onChange={handleChange}
                        name="ipAddress"
                        onBlur={handleBlur}
                      ></Form.Control>
                      <Form.Text className="text-small text-danger">
                        {errors.ipAddress &&
                          touched.ipAddress &&
                          errors.ipAddress}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="bottom-spacer top-spacer">
                  <Col md={{ offset: "4", span: "4" }}>
                    <Button
                      variant="outline-dark"
                      block
                      onClick={() => this.refs[modalRef].handleClose()}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      type="submit"
                      variant="success"
                      block
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Registering... " : "Register"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </AdminPopupDismissableModal>
      </>
    );
  }
}

export default RegisterNewUser;
