import React, { useEffect, useState, useRef } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
  updateCallGroupList,
  updateCallGroupIndexOnList,
  updateCallGroupListState,
  resetError,
  updateCallGroupClaimer,
} from "./CallGroup.actions";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import { WrapTextAndJustifyCenter } from "components/WrapText";
import {
  renderAllSwitchesCell,
  renderScheduledDateCol,
  renderAWStatusFilterValue,
} from "components/ReactTableColumnHelper";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEqual } from "lodash";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import {
  extractDefaultFilters,
  isCollectionNullOrEmpty,
  isNull,
  addWorkDays,
  getPageSize,
  getErrorMessage,
} from "services/UtilityService";
import Colors from "lookup/Colors.json";
import { showNotification } from "services/NotificationService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import DisplayBar from "commons/table/DisplayBar/DisplayBar";
import { FilterTypes, ColumnTypes } from "commons/table/Constants";

const CallGroupList = (props) => {
  const ref = useRef(true);
  const [filters, setFilters] = useState(null);
  const [defaultSorts, setDefaultSorts] = useState([
    AgentWorkspaceConstants.DEFAULT_SORT,
  ]);
  const [defaultFilters, setDefaultFilters] = useState([]);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.callGroupList.loading);
  const data = useSelector((state) =>
    state.callGroupList.data !== undefined ? state.callGroupList.data : []
  );
  const pages = useSelector((state) =>
    state.callGroupList.pages ? state.callGroupList.pages : 0
  );
  const pageSizeValue = useSelector((state) =>
    state.callGroupList.pageSize ? state.callGroupList.pageSize : 0
  );
  const CALL_GROUP_LIST_ROW_COUNT = "CALL_GROUP_LIST_ROW_COUNT";
  const pageSize = getPageSize(
    AgentWorkspaceConstants.DEFAULT_PAGE_SIZE,
    pageSizeValue,
    CALL_GROUP_LIST_ROW_COUNT
  );
  const totalElements = useSelector((state) =>
    state.callGroupList.totalElements !== undefined
      ? state.callGroupList.totalElements
      : "Loading ..."
  );
  const isError = useSelector((state) => state.callGroupList.isError);
  const listState = useSelector((state) =>
    state.callGroupList.listState !== undefined
      ? state.callGroupList.listState
      : {
          page: 0,
          pageSize: AgentWorkspaceConstants.DEFAULT_PAGE_SIZE,
          sorted: defaultSorts || [],
          filtered: defaultFilters || [],
          resized: [],
          expanded: {},
        }
  );

  const [startDate, setStartDate] = useState(new Date());

  const showFilterAlter =
    listState.filtered.some((f) =>
      AgentWorkspaceConstants.CUSTOMER_CLIENT_FILTER_KEYS.includes(f.id)
    ) && data.length === 0;

  useEffect(() => {
    //fetch filters from backend
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/call-group/list-filters`,
      "GET",
      null,
      (r) => {
        const defFilters = extractDefaultFilters(r.data.filters);
        setFilters(r.data.filters);
        setDefaultSorts(r.data.sorts);
        setDefaultFilters(defFilters);
        if (isCollectionNullOrEmpty(listState.filtered)) {
          updateData(
            { ...listState, filtered: defFilters, sorted: r.data.sorts },
            true
          );
        } else {
          updateData(listState);
        }
      },
      (r) => {
        showNotification({
          title: "Error fetching Filter Values!",
          message: "Something went Wrong.",
          position: "tr",
          type: "error",
        });
        dispatch(resetError());
      }
    );
  }, []);

  useEffect(() => {
    const firstRender = ref.current;
    if (firstRender) {
      ref.current = false;
    }
  });

  const filterSelectMap = {};
  if (filters !== null) {
    Object.keys(filters).forEach(
      (colKey, i) =>
        (filterSelectMap[colKey] = filters[colKey].map((row, index) => {
          return { id: row["id"], name: row["value"] };
        }))
    );
    //logic start to dynamically populate client filter based on customer selected
    const appliedCustomerFilters =
      listState.filtered.filter((e) => e.id === "customerNameAbbreviation")
        .length > 0
        ? listState.filtered.filter(
            (e) => e.id === "customerNameAbbreviation"
          )[0].value
        : [];
    const extractedCustomers = filterSelectMap["customerClientMapping"].filter(
      (e) => appliedCustomerFilters.includes(e.id)
    );
    const extractedClients = extractedCustomers.reduce((result, entry) => {
      return result.concat(entry.name);
    }, []);
    if (extractedClients.length > 0) {
      filterSelectMap["clientNameAbbreviation"] = filterSelectMap[
        "clientNameAbbreviation"
      ].filter((e) => extractedClients.includes(e.id));
      // logic end
      // Filter out the entries from 'listState.filtered.clientNameAbbreviation' that do not exist in 'extractedClients'
      let isFilterValueChanged = false;
      const filteredFilters = listState.filtered.map((filter) => {
        if (
          filter.id === "clientNameAbbreviation" &&
          Array.isArray(filter.value)
        ) {
          const filteredValue = filter.value.filter((val) =>
            extractedClients.includes(val)
          );
          if (!isEqual(filteredValue, filter.value)) {
            isFilterValueChanged = true;
          }
          return { ...filter, value: filteredValue };
        }
        return filter;
      });
      if (isFilterValueChanged) listState.filtered = filteredFilters;
    }
  }
  useEffect(() => {
    setColumns(cols);
  }, [filters, listState.filtered]);

  let cols = [
    {
      header: "Customer",
      accessor: "customerNameAbbreviation",
      accessorKey: "customerNameAbbreviation",
      size: 60,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.MultiSelect,
      filterOptions: Object.keys(filterSelectMap).includes(
        "customerNameAbbreviation"
      )
        ? filterSelectMap["customerNameAbbreviation"]
        : [],
    },
    {
      header: "Client",
      accessor: "clientNameAbbreviation",
      accessorKey: "clientNameAbbreviation",
      size: 60,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.MultiSelect,
      filterOptions: Object.keys(filterSelectMap).includes(
        "clientNameAbbreviation"
      )
        ? filterSelectMap["clientNameAbbreviation"]
        : [],
    },
    {
      header: "Switches",
      accessor: "aggregatedSwitchList",
      accessorKey: "aggregatedSwitchList",
      size: 90,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SearchableInput,
      customComponent: (value, row) => {
        return renderAllSwitchesCell(row.original.aggregatedSwitchList);
      },
    },
    {
      header: "Open Switches",
      accessor: "openActivitiesCount",
      accessorKey: "openActivitiesCount",
      size: 60,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Rank",
      accessor: "priority",
      accessorKey: "priority",
      size: 50,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Assigned To",
      accessor: "assigneeName",
      accessorKey: "assigneeName",
      size: 90,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Claimed By",
      accessor: "claimerName",
      accessorKey: "claimerName",
      size: 90,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Scheduled For",
      accessor: "scheduledDate",
      accessorKey: "scheduledDate",
      size: 160,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.DateRangePicker,
      customComponent: (value, row) => {
        return renderScheduledDateCol(
          row.original.scheduledDate,
          row.original.daysToScheduledDate
        );
      },
    },
    {
      header: "Days to Scheduled Date",
      accessor: "daysToScheduledDate",
      accessorKey: "daysToScheduledDate",
      size: 200,
      show: false,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.DateColumn,
    },
    {
      header: "Entity",
      accessor: "entityType",
      accessorKey: "entityType",
      size: 100,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.MultiSelect,
      filterOptions: Object.keys(filterSelectMap).includes("entityType")
        ? filterSelectMap["entityType"]
        : [],
    },
    {
      header: "Name",
      accessor: "entityName",
      accessorKey: "entityName",
      size: 90,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Phone #",
      accessor: "phoneNumber",
      accessorKey: "phoneNumber",
      size: 70,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Time Zone",
      accessor: "timeZone",
      accessorKey: "timeZone",
      size: 60,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.MultiSelect,
      filterOptions: Object.keys(filterSelectMap).includes("timeZone")
        ? filterSelectMap["timeZone"]
        : [],
    },
    {
      header: "Type",
      accessor: "type",
      accessorKey: "type",
      size: 70,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.MultiSelect,
      filterOptions: Object.keys(filterSelectMap).includes("type")
        ? filterSelectMap["type"]
        : [],
    },
    {
      header: "Status",
      accessor: "status",
      accessorKey: "status",
      size: 120,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.MultiSelect,
      filterOptions: Object.keys(filterSelectMap).includes("status")
        ? filterSelectMap["status"]
        : [],
      customComponent: (value, row) => {
        return renderStatus(row);
      },
    },
  ];

  const [columns, setColumns] = useState(cols);

  const renderStatus = (row) => {
    return row.original.escalatedNotes ? (
      <OverlayTrigger
        overlay={
          <Tooltip>
            {row.original.escalatedNotes.split("||").map((note) => {
              return <p key={note}>{note}</p>;
            })}
          </Tooltip>
        }
        placement="top"
      >
        <div>
          <WrapTextAndJustifyCenter>
            {renderAWStatusFilterValue(listState.filtered, row)}
          </WrapTextAndJustifyCenter>
        </div>
      </OverlayTrigger>
    ) : (
      <div>
        <WrapTextAndJustifyCenter>
          {renderAWStatusFilterValue(listState.filtered, row)}
        </WrapTextAndJustifyCenter>
      </div>
    );
  };

  const getRowStyle = (rowInfo) => {
    const applyStyle =
      !isNull(rowInfo) && rowInfo.original.containsNewActivities;
    const rowColor = applyStyle ? Colors["rm-gray-shadow"] : Colors["rm-white"];
    const textColor = applyStyle ? Colors["rm-black"] : Colors["rm-gray-dark"];
    return { background: rowColor, color: textColor };
  };

  const debounceUpdateData = debounce((listState) => {
    updateData(listState, false);
  }, 200);

  const handleFilterChange = (listState) => {
    dispatch(updateCallGroupListState(listState));
    debounceUpdateData(listState);
  };

  const updateData = (state, preventUpdate) => {
    AgentWorkspaceConstants.CALL_GROUP_LIST_MULTI_FILTER_IDS.forEach((item) => {
      const index = state.filtered.findIndex((element) => element.id === item);
      if (index !== -1 && Array.isArray(state.filtered[index].value)) {
        state.filtered[index].value = state.filtered[index].value.map((value) =>
          typeof value === "string" ? value : value.name
        );
      }
    });
    if (!preventUpdate) {
      dispatch(
        updateCallGroupList(
          state.pageSize,
          state.page,
          state.sorted,
          state.filtered,
          state.resized,
          state.expanded
        )
      );
    }
    dispatch(updateCallGroupListState(state));
  };

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    listState.pageSize = pageSize;
    listState.page = pageNo;
    listState.sorted = sortBy ? sortBy : [];
    listState.filtered = filterValues;
    updateData(listState);
  };

  const reset = () => {
    listState.pageSize = AgentWorkspaceConstants.DEFAULT_PAGE_SIZE;
    listState.page = 0;
    listState.sorted = defaultSorts;
    listState.filtered = defaultFilters;
    updateData(listState);
  };

  const handleRowClick = async (rowInfo) => {
    const isCallGroupClaimed = await checkIfCallGroupClaimed(
      rowInfo.original.callGroupId
    );
    if (!isCallGroupClaimed) {
      dispatch(updateCallGroupIndexOnList(rowInfo.index));
      const status =
        listState.filtered.length !== 0 &&
        listState.filtered.filter((e) => e.id === "status").length > 0
          ? listState.filtered.filter((e) => e.id === "status")[0].value
          : null;
      props.history.push({
        pathname: `/agent-workspace/call-group-details/${rowInfo.original.callGroupId}`,
        state: { status: status },
      });
    }
  };

  const checkIfCallGroupClaimed = async (callGroupId) => {
    const response = await new Promise((resolve, reject) => {
      sendAuthenticatedAsyncRequest(
        `/agent-workspace/${callGroupId}/is-callgroup-claimed`,
        "GET",
        null,
        (r) => {
          resolve(r);
        },
        (r) => {
          showNotification({
            title: "Unable to claim this call group!",
            message:
              getErrorMessage(r.data) ||
              getErrorMessage("SOMETHING_WENT_WRONG"),
            position: "tr",
            type: "error",
          });
          dispatch(
            updateCallGroupClaimer(
              data,
              callGroupId,
              r.data.additionalErrorInfo[0]
            )
          );
          return true;
        }
      );
    });
  };

  const setScheduledDateRangeFilter = (startDate, defFilters) => {
    defFilters.push({
      id: "scheduledDate",
      value: [
        startDate,
        addWorkDays(
          new Date(),
          AgentWorkspaceConstants.SCHEDULE_DATE_WITHIN_DAYS
        ),
      ],
      isDefault: true,
    });
  };

  const getStartDate = (filters) => {
    let dateStr = filters.scheduledDate[0].value;
    dateStr = dateStr.replace(/-/g, "/");
    const date = new Date(dateStr);
    delete filters.scheduledDate;
    return date;
  };

  useEffect(() => {
    if (isError === true) {
      showNotification({
        title: "Error Loading Data!",
        message: "Something went Wrong.",
        position: "bl",
        type: "error",
      });
      dispatch(resetError());
    }
  }, [isError]);

  return (
    <Container className="list-container" fluid>
      {showFilterAlter ? (
        <Row>
          <Col md={12}>
            <Alert variant={"warning"}>
              {
                "No more items left in the list. Please try removing/changing client and customer filters for more results."
              }
            </Alert>
          </Col>
        </Row>
      ) : null}
      <DisplayBar
        tableTitle={"Call Groups"}
        totalRecords={totalElements == "loading" ? 0 : totalElements}
        refreshIcon={false}
        reset={reset}
        dataTestId={"text-total-call-group-count"}
      />
      <ServerSideReactTable
        columnDefination={columns}
        data={data}
        currentPage={listState.page}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={pages}
        totalRecords={totalElements == "loading" ? 0 : totalElements}
        loading={loading}
        enableMultiRowSelection={false}
        addRowSelection={false}
        defaultPageSize={pageSize}
        attributeName={CALL_GROUP_LIST_ROW_COUNT}
        pageSizeOptions={AgentWorkspaceConstants.DEFAULT_PAGE_SIZE_OPTIONS}
        onUpdateTableData={onUpdateTable}
        onRowClick={(row) => {
          handleRowClick(row);
        }}
        trStyle={getRowStyle}
      />
    </Container>
  );
};

export default withRouter(React.memo(CallGroupList));
