
export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case 'UPDATE_SWITCH_LIST_SUCCESS':
      newState = Object.assign({}, previousState, {
        data: action.payload.records,
        pages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        loading: false,
        isError: false
      });
      newState.listState.page = action.payload.page;
      break;
    case 'UPDATE_SWITCH_LIST_REQUEST':
      newState = Object.assign({}, previousState, {
        listState: action.payload.listState,
        totalElements: "Loading ...",
        loading: true,
        isError: false
      });
      break;
    case 'UPDATE_SWITCH_INDEX_ON_LIST':
      newState = Object.assign({}, previousState, { indexOnList: action.indexOnList });
      break;
    case 'UPDATE_SWITCH_LIST_FAILURE':
      newState = Object.assign({}, previousState, {
        data: [],
        pages: 0,
        totalElements: 0,
        loading: false,
        isError: true,

      });

      break;
    case 'RESET_ERROR_MESSAGE_SWITCH_LIST':
      newState = Object.assign({}, previousState, {
        isError: false
      });
      break;
    default:
      break;
  }

  return newState;
}