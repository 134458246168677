import React from 'react';

import Card from 'react-bootstrap/Card';
import { CSVLink } from "react-csv";

import DismissableModal from '../DismissableModal';
import PatientMedicineList from './PatientMedicineList';
import { Button } from 'react-bootstrap';
import { formatDateForDisplay } from '../../services/MomentUtils';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import ClientSideReactTable from 'commons/table/ClientSideReactTable';

const styles = {
  card: {
    padding: 10
  },
  cardHeader: { paddingLeft: 0, backgroundColor: 'white', letterSpacing: 2 },
  cardBody: { paddingLeft: 0, paddingRight: 0, paddingBottom: 0, alignItems: 'center' }
}
class PatientHistory extends React.Component {

  state = {
    transformedData: []
  }

  tableRef = 'patient_history';
  csvLinkRef = 'csv_downloader_link';

  columns = () => {
    return ([
      {
        header: "Drug",
        accessor: "claimDrugName",
        accessorKey: "claimDrugName",
        width: 160,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Filled on",
        accessor: "claimFillDate",
        accessorKey: "claimFillDate",
        width: 60,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (r) => {
          return r
        },
      },
      {
        header: "Normalized Name",
        accessor: "sourceRxnormName",
        accessorKey: "sourceRxnormName",
        width: 250,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Strength",
        accessor: "drugStrength",
        accessorKey: "drugStrength",
        width: 40,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Supply (days)",
        accessor: "sourceDaysSupply",
        accessorKey: "sourceDaysSupply",
        width: 40,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "NDC #",
        accessor: "sourceNdcNumber",
        accessorKey: "sourceNdcNumber",
        width: 70,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Controlled Substance",
        accessor: "targetControlledSubstance",
        accessorKey: "targetControlledSubstance",
        width: 80,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
    ]);
  }

  downloadToCsv = () => {
    const tableData = this.refs[this.tableRef].getResolvedState().sortedData;
    var dataToDownload = [];
    let columns = this.columns();
    for (var groupedRowsIndex = 0; groupedRowsIndex < tableData.length; groupedRowsIndex++) {
      for (var rowIndex = 0; rowIndex < tableData[groupedRowsIndex]._subRows.length; rowIndex++) {
        let recordToDownload = {}
        for (var colIndex = 0; colIndex < columns.length; colIndex++) {
          recordToDownload[columns[colIndex].Header] = tableData[groupedRowsIndex]._subRows[rowIndex][columns[colIndex].accessor]
        }
        dataToDownload.push(recordToDownload)
      }
    }
    this.setState({ transformedData: dataToDownload }, () => this.refs[this.csvLinkRef].link.click())
  }

  render() {
    let data = JSON.parse(JSON.stringify(this.props.patientHistory));

    data = data.slice().sort(function (a, b) {
      let keyA = new Date(a.claimFillDate), keyB = new Date(b.claimFillDate);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });


    data.forEach(medicine => {
      medicine.claimDrugName = medicine.claimDrugName || 'DRUG NAME N/A';
    });
    var groupedDataDict = {};
    var i = 0;
    data.forEach(medicine => {
      if (!(medicine.claimDrugName in groupedDataDict)) {
        groupedDataDict[medicine.claimDrugName] = {};
        groupedDataDict[medicine.claimDrugName]["items"] = [];
      }
      medicine.id = medicine.claimDrugName + "-" + i;
      i++;
      groupedDataDict[medicine.claimDrugName].items.push(medicine)
    });
    var j = 0;
    Object.keys(groupedDataDict).forEach(medName => {
      let dates = groupedDataDict[medName]["items"].map(medicines => { return medicines["claimFillDate"] })
      let newestDate = dates.length > 0 ? dates.sort()[dates.length - 1] : ""
      let ind = 0
      if (newestDate.length > 0) {
        ind = dates.findIndex(elem => elem === newestDate)
      }
      groupedDataDict[medName]["id"] = j;
      j++;
      groupedDataDict[medName]["items"].forEach(medicines => medicines["claimFillDate"] = formatDateForDisplay(medicines["claimFillDate"]))
      const defaultMed = groupedDataDict[medName]["items"][ind];
      groupedDataDict[medName]["claimDrugName"] = defaultMed["claimDrugName"] + " (" + groupedDataDict[medName]["items"].length.toString() + ")"
      groupedDataDict[medName]["claimFillDate"] = 'Last: '.concat(defaultMed["claimFillDate"])
    });
    const groupedData = Object.keys(groupedDataDict).map(medName => groupedDataDict[medName]);

    return (
      <Card style={styles.card}
      // className="top-spacer" 
      >
        <Card.Header style={styles.cardHeader}>
          MEDICINE HISTORY
        </Card.Header>
        <Card.Body style={styles.cardBody}>
          <PatientMedicineList history={data}
            listGroupStyle={this.props.listGroupStyle} />
          <DismissableModal
            customModalStyle={{ height: '100%' }}
            openModalButtonText="Full History"
            openModalButtonBlock={true}
            openModalButtonStyle="link"
            openModalButtonDisabled={data ? false : true}
            title="Member History"
            modalClassName="super-wide-modal">
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button onClick={() => this.downloadToCsv()} variant="link" className="pull-right">Export to CSV</Button>
              <CSVLink ref={this.csvLinkRef} data={this.state.transformedData} filename={`switch_${this.props.switchId}_medical_history.csv`} className="hidden" target="_blank" />
            </div>
            <ClientSideReactTable
              columnDefination={this.columns()}
              data={groupedData}
              overrideRowId="id" 
              totalRecords={groupedData ? groupedData.length : 0}
              enableMultiRowSelection={false}
              enableExpansion={true}
              pivot={"items"}
              addRowSelection={false}
              defaultPageSize={10}
            />
          </DismissableModal>
        </Card.Body>
      </Card>

    );
  }
}

export default PatientHistory;