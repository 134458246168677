import React, { Component } from 'react';

import DismissableModal from '../DismissableModal';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import './PawStatus.css';

class ReOpenDialog extends Component {

  state = {
    isCommentValid: false,
    comments: "",
    failure: false,
    context: this.props.context || 'paw'
  }

 
  reOpenDrugGroup(closeModal) {
    if (this.state.isCommentValid ) {
       this.props.reOpenDrugGroup(this.state.comments);
      closeModal();
    }
  }

  render() {
    const { comments, isCommentValid } = this.state;
    const modalRef = "unclaimConfirmationModal";
    const customFooterContent = (
      <div>
        <Button id='switchdetails-btn-sfr-cancel' variant="outline-dark" onClick={() => this.refs[modalRef].handleClose()}>Cancel</Button>
        <Button
          id='switchdetails-btn-sfr'
          className="left-spacer"
          variant="warning"
          onClick={() => this.reOpenDrugGroup(this.refs[modalRef].handleClose)}>
          Yes, reopen the drug group
        </Button>
      </div>
    );
    return (
      <DismissableModal
        ref={modalRef}
        customFooterContent={customFooterContent}
        openModalButtonText="Reopen DrugGroup"
        openModalButtonStyle="primary"
        openModalButtonBlock={true}
        openModalButtonDisabled={this.props.disallowEditing}
        openModalButtonId='switchdetails-btn-sfr-popup'
        openModalButtonClassName="btn-block"
        title="Submit Drug Group for Review" >
        <p className="confirmation-text">
          You have selected to <b>Reopen</b> this Drug Group for review.
        </p>
        <p className="text-muted">Once you reopen this drug group, all the drug group with same Source Drug Group and Dynamic Search Group will reopen as well</p>
        <Form.Group>
          <Form.Label>Please enter comments for reviewer</Form.Label>
          <Form.Control
            id='switchdetails-textarea-sfr-comments'
            as="textarea"
            rows="3"
            value={comments}
            onChange={(e) => this.setState({ comments: e.target.value, isCommentValid: e.target.value !== "" })} />
          {
            !isCommentValid ? <span className="text-danger smaller-feedback">Comments are mandatory!</span> : <span className="text-success smaller-feedback">Looks good!</span>
          }
        </Form.Group>
      </DismissableModal>
    );
  }
}

export default ReOpenDialog;
