import React from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
const ScheduleBulkActionModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  title,
  cancelDanger,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="confirmation-text">{message}</p>
        <Formik
          initialValues={{
            note: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleConfirm(values);
            setSubmitting(false);
            handleClose();
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div className="align-content-right">
                <Button
                  id="schedule-bulk-action-btn-save"
                  type="submit"
                  className="left-spacer"
                  variant={cancelDanger ? "danger" : "success"}
                  disabled={isSubmitting}
                  block
                  onClick={handleSubmit}
                  data-testid="btn-schedule-bulk-action-submit"
                >
                  {isSubmitting ? "Updating ... " : "Yes"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(ScheduleBulkActionModal);
