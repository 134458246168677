import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DynamicSwitchBox from 'components/DynamicSwitchGroup/DynamicSwitchBox';
import CodexVersionDisplayBar from 'components/CodexVersionDisplayBar';

import './DynamicSwitchGroupDetails.css';
import { bindActionCreators } from 'redux';
import { getDynamicSwitchGroup } from './DynamicSwitchGroupDetails.actions';
import { connect } from 'react-redux';

class DynamicSwitchGroupDetails extends Component {

  componentDidMount() {
    this.props.getDynamicSwitchGroup(this.props.match.params.dynamicSwitchGroup);
  }

  render() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const IsCodexVersionDisplayBarVisible = queryParams.get('showDisplayCodexBar') ? false : true;
    return (
      <Container fluid>
        {IsCodexVersionDisplayBarVisible ? <CodexVersionDisplayBar /> : null}
        <Row>
          <Col xs={12}>
            <DynamicSwitchBox />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDynamicSwitchGroup: bindActionCreators(getDynamicSwitchGroup, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(DynamicSwitchGroupDetails));

