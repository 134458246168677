import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const searchDrugs = (pageSize, page, searchTerm) => {
  searchTerm = encodeURIComponent(searchTerm)
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/cat/es/drug-search?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        'DRUG_SEARCH_REQUEST',
        'DRUG_SEARCH_SUCCESS',
        'DRUG_SEARCH_FAILURE'
      ]
    }
  };
};

export const updateDrugListState = (page, pageSize) => {
  return {
    type: "UPDATE_DRUG_LIST_STATE",
    payload: {page, pageSize}
  }
};

export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_DRUG_LIST"
  }
};
