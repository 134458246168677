import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const scheduleBatch = (batchDto) => {

  return {
    [RSAA]: {

      endpoint: `${config.apiRoot}/swim/batch`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken()
      },
      body: JSON.stringify(batchDto),
      types: [
        'SCHEDULE_BATCH_REQUEST',
        'SCHEDULE_BATCH_SUCCESS',
        'SCHEDULE_BATCH_FAILURE'
      ]
    }
  };
};
export const resetSuccess = () => {
  return {
    type: "RESET_SUCCESS_MESSAGE_BATCH_SCHEDULER"

  }
}
