import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  updateUserList,
  updateUserIndexOnList,
  resetError,
} from "./User.actions";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import ResetListState from "components/ResetListState";
import Tip from "components/Tip";
import { withRouter } from "react-router-dom";
const DEFAULT_PAGE_SIZE = 15;
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import ReactBadge from "react-bootstrap/Badge";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
const Badge = (props) => (
  <div>
    <ReactBadge {...props}>{props.children}</ReactBadge>
  </div>
);
const spaceyStyle = {
  letterSpacing: "0.6px",
};

import EditUser from "components/User/EditUser";
import RegisterNewUser from "components/User/RegisterNewUser";
import DisableUser from "components/User/DisableUser";
import DeleteUser from "components/User/DeleteUser";
import { showNotification } from "services/NotificationService";

class UserList extends React.Component {
  constructor(props) {
    super(props)

    this.handler = this.handler.bind(this)
  }


  state = {
    loading: true,
    data: this.props.data,
    pages: this.props.pages,
    totalElements: this.props.totalElements,
    listState: this.props.listState,
    isError: false,
    showEdit: false,
    addUser: false,
    selectedUserName: "",
    selectedUserEmail: "",
    reset: "reset"
  };

  listRole = () => {
    sendAuthenticatedAsyncRequest(
      `/user/list_roles`,
      "GET",
      null,
      (r) => {
        this.setState({ roles: r.data });
      },
      (r) => {
        this.setState({
          roles: null,
          errorMsg:
            getErrorMessage(r.data) || getErrorMessage("SOMETHING_WENT_WRONG"),
        });
      }
    );
  };


  updateData = (state, preventUpdate) => {
    if (!preventUpdate) {
      this.props.updateUserList(
        state.pageSize,
        state.page,
        state.sorted,
        state.filtered,
        state.resized,
        state.expanded
      );
    } else {
      this.setState({ listState: state });
    }
  };
  handler() {
    this.updateData(this.state.listState, false);
  }

  componentDidMount() {
    this.updateData(this.state.listState);
    this.listRole();
  }

  static getDerivedStateFromProps(props, state) {
    const { data, pages, totalElements, listState, loading, isError } = props;
    if (isError === true) {
      showNotification({
        title: "Error Loading Data!",
        message: "Something went Wrong.",
        position: "bl",
        type: "error"
      });
      props.resetError();
      return null;
    } else {
      return {
        data,
        pages,
        totalElements,
        listState,
        loading,
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.listState.page !== 0 &&
      this.props.listState.page >= this.props.pages
    ) {
      this.updateData({ ...this.props.listState, page: 0 });
    }
    if (!_.isEqual(prevProps.listState, this.props.listState)) {
      this.updateData(this.state.listState, true);
    }
  }

  handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value, error: "" });
  };

  columns = [
    {
      header: "Name",
      accessor: "userName",
      accessorKey: "userName",
      width: 200,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Email",
      accessor: "email",
      accessorKey: "email",
      width: 350,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "IP Address",
      accessor: "ipAddress",
      accessorKey: "ipAddress",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
      show: false,
    },
    {
      header: "User Info Id",
      accessor: "userInfoId",
      accessorKey: "userInfoId",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
      show: false,
    },
    {
      header: "Roles",
      accessor: "rolesList",
      accessorKey: "rolesList",
      width: 350,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value) => {
        return this.renderAllRoles(value)
      }
    },
    {
      header: "Actions",
      accessor: "action",
      accessorKey: "action",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return this.renderAllActions(row.original)
      }
    },
  ];

  onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    const listState = {
      page: pageNo,
      pageSize: pageSize,
      sorted: sortBy ? sortBy : [],
      filtered: filterValues,
      resized: [],
      expanded: {}
    }
    this.updateData(listState);
  }

  renderAllRoles = (roles) => {
    if (roles) {
      return (
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            "flex-wrap": "wrap",
            rowGap: "4px"
          }}
        >
          {" "}
          {roles
            ? roles.split(",").map((role, counter) => (
                <Badge
                  key={counter}
                  variant="primary"
                  id={`${role}-${counter}`}
                  pill
                  style={{
                    ...spaceyStyle,
                    marginLeft: "0.5rem",
                    fontSize: "0.7rem",
                  }}
                >
                  {role}
                </Badge>
              )
            )
            : ""}{" "}
        </span>
      )
    }
    return <span></span>;
  }

  renderAllActions = (rowData) => {
    if (rowData) {
      return (
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {" "}
          <EditUser
            user={rowData}
            showButton={true}
            roles={this.state.roles}
            handler={this.handler}
          ></EditUser>
          <DisableUser userInfoId={rowData.userInfoId} handler={this.handler}></DisableUser> <DeleteUser userInfoId={rowData.userInfoId} handler={this.handler}></DeleteUser>{" "}
        </span>
      )
    }
  }

  render() {
    const {
      data,
      pages,
      loading,
      totalElements,
      listState,
      showEdit,
      addUser,
      selectedUserName,
      selectedUserEmail,
      user,
      roles
    } = this.state;
    return (
      <Container className="list-container" fluid>
        <Row>
          <Col md={12}>
            <div className="codex-version-display-bar">
              <span style={{ float: "left" }}>
                {" "}
                Total Users:{" "}
                <strong id="user-total-count">{totalElements}</strong>
              </span>
              <strong style={{ float: "center" }}>Users List</strong>
              <span className="tiny-bottom-spacer">
                <RegisterNewUser showButton={true} roles={roles} handler={this.handler}></RegisterNewUser>
                <ResetListState
                  onReset={() =>
                    this.updateData(
                      {
                        ...listState,
                        filtered: [],
                        sorted: [],
                      },
                      false
                    )
                  }
                />
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ServerSideReactTable
              columnDefination={this.columns}
              data={data}
              overrideRowId={'userInfoId'}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              currentPage={listState.page}
              sorted={listState.sorted}
              filters={listState.filtered}
              totalPages={pages}
              totalRecords={totalElements=="loading"?0:totalElements}
              loading={loading}
              enableMultiRowSelection={false}
              onUpdateTableData={this.onUpdateTable}
            />
            <Tip>
              Tip: Hold shift when sorting to multi-sort and disable sort!
            </Tip>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.userList !== undefined ? state.userList.data : [],
    pages: state.userList.pages ? state.userList.pages : 0,
    totalElements:
      state.userList.totalElements !== undefined
        ? state.userList.totalElements
        : "Loading ...",
    loading: state.userList.loading,
    isError: state.userList.isError,
    listState:
      state.userList.listState !== undefined
        ? state.userList.listState
        : {
            page: 0,
            pageSize: DEFAULT_PAGE_SIZE,
            sorted: [],
            filtered: [],
            resized: [],
            expanded: {},
          },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserList: bindActionCreators(updateUserList, dispatch),
    updateUserIndexOnList: bindActionCreators(updateUserIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserList));
