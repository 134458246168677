import Dashboard from "layouts/Dashboard/Dashboard";
import MemberPHI from 'layouts/MemberPHI/MemberPHI';
// import Login from 'views/Login/Login';

import CognitoLogin from 'views/Login/Cognito/CognitoLogin';
import LoginCallback from 'views/Login/Cognito/LoginCallback';
import LogoutCallback from 'views/Login/Cognito/LogoutCallback';

// please make sure to keep all public (non-protected) routes above the protected routes

// TODO: make two nested objects for indexRoutes namely protectedRoutes and publicRoutes
// and render the publicRoutes first in index.js to ensure right order
var indexRoutes = [
  { path: "/login", name: "Login", component: CognitoLogin },
  { path: "/public/member-phi/:globalSwitchId/:zohoAccessToken/:zohoUserId", name: "Member PHI", component: MemberPHI },
  {
    path: "/login/cognito", name: "Cognito Login", component: CognitoLogin
  },
  {
    path: "/login/oauth2/code/cognito", name: "Login Callback", component: LoginCallback
  },
  {
    path: "/logout", name: "Logout Callback", component: LogoutCallback
  },
	{ path: "/", name: "Home", component: Dashboard, isProtected: true },
];

export default indexRoutes;
