import { withRouter } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import CodexVersionDisplayBar from 'components/CodexVersionDisplayBar';
import DynamicSwitchBox from 'components/DynamicSwitchGroup/DynamicSwitchBox';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../UpdatedDynamicSwitchGroupDetails.css';
import { getDynamicSwitchGroupDiff } from './UpdatedDynamicSwitchGroupDetails.actions';

class UpdatedDynamicSwitchGroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.props.getDynamicSwitchGroupDiff(this.props.codexVersionId,
      this.props.match.params.dynamicSwitchGroup);
  }

  render() {
    return (
      <Container fluid>
        <CodexVersionDisplayBar />
        <Row>
          <Col xs={12}>
            <DynamicSwitchBox />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    codexVersionId: state.codexVersion ? state.codexVersion.codexVersionId : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDynamicSwitchGroupDiff: bindActionCreators(getDynamicSwitchGroupDiff, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdatedDynamicSwitchGroupDetails));

