import Form from "react-bootstrap/Form";
import React from 'react';
import Row from 'react-bootstrap/Row';
import { DescriptionDeltaField } from "./DeltaFieldUtils";

const DescriptionField = ({
  id = '',
  isEditMode = false,
  name = '',
  error = '',
  savedValue = '',
  compKey = '',
  updatedValue = '',
  handleChange = () => { },
  handleBlur = () => { },
  isFieldRequired = () => { },
  controlProps = {},
  displayIfEmpty = true,
  predecessorValue = '',
  isUpdated = false,
  isDisabled = false,
  isHorizontalView = false,
  placeholder = undefined,
  inputType = undefined,
  additionalClassName = '',
  additionalTitleClassName = ''
}) => {
  if (!isEditMode) {
    if (displayIfEmpty || savedValue) {
      return <DescriptionDeltaField isUpdated={isUpdated} oldField={predecessorValue} newField={savedValue} heading={name} />
    }
  } else {
    return (
      <Form.Group as={isHorizontalView ? Row : undefined} className={isHorizontalView ? ("mb-2 " + additionalClassName) : ""}>
        <span className={isHorizontalView ? ("col-md-3 " + additionalTitleClassName) : ""}>
          <Form.Label
            className={
              isFieldRequired(id)
                ? "rm-required-field rm-small-top-label"
                : "rm-small-top-label"
            }
          >
            {name}
          </Form.Label>
        </span>
        <span className={isHorizontalView ? "col-md-9" : ""}>
          <Form.Control
            {...controlProps}
            onChange={handleChange}
            onBlur={handleBlur}
            value={updatedValue}
            name={id}
            id={compKey}
            disabled={isDisabled}
            placeholder={placeholder || savedValue}
            type={inputType}
          />
          <Form.Text
            id={compKey + "-error-msg"}
            className="text-small text-danger"
          >
            {error}
          </Form.Text>
        </span>
      </Form.Group>
    )
  }
  return null;
}
export default React.memo(DescriptionField);
