// A simple content provider which checks for access by logged id user to restricted components
// TODO: create a HOC which does this job

import React from 'react';

import {hasRoleIn} from 'rbac/RbacHelper';
import ROLES from 'rbac/Roles.json';

import UsabilityContext from 'lookup/UsabilityContext';
import DashboardMetrics from 'components/DashboardMetrics/DashboardMetrics';
import CodexStatusModal from 'components/CodexStatusModal';

export const ROUTES = {
  // TODO: need to create a common route lookup or route provide
  // bad idea to use string literals, true for some other places in 
  // the application
  // This method basically prioritizes landing pages for a user with multiple roles
  provideRedirectRoute: () => {
    if (hasRoleIn([ROLES.SWIM_USER]))
      return '/dashboard';
    else if (hasRoleIn([ROLES.PAW_USER]))
      return '/paw/list';
    else if (hasRoleIn([ROLES.CODEX_READ]))
      return '/cat';
    else if(hasRoleIn([ROLES.AW_CALL_ACTIVITY, ROLES.AW_TASK_ACTIVITY]))
      return '/agent-workspace/dashboard'
    else return '/';
  }
}

export const CODEX = {
  provideCodexStatusModal: (disabledApprovalButton) => {
    if (hasRoleIn([ROLES.CODEX_STATUS_CHANGE]))
      return <CodexStatusModal disabledButton={disabledApprovalButton}/>
    else return null;
  },
  renderComponentIfCanChangeDSG: (component) => {
    if(hasRoleIn([ROLES.CODEX_ENTITIES_CRUD]))
      return component;
    else return null;
  }
}

export const DASHBOARD_LAYOUT = {
  provideMetricsBox: () => {
    if (hasRoleIn([ROLES.PAW_USER]))
      return <DashboardMetrics />;
    else return null;
  }
}

export const SWITCH_DISPOSITION = {
  provideSelectableDispositions : (context) => {
    const selectableDispositions = {
      ACCEPTED: 'ACCEPTED',
      SUBMITTED_VIA_EHR: 'SUBMITTED_VIA_EHR',
    }
    if (((hasRoleIn([ROLES.PAW_USER]) || hasRoleIn([ROLES.SWIM_USER])) && context === UsabilityContext.RAW) || context === UsabilityContext.AW) 
      selectableDispositions.DECLINED = 'DECLINED';
      
    return selectableDispositions;
  },

  provideSubmitToPaw : (context) => {
    if (hasRoleIn([ROLES.SWIM_USER]) && context === UsabilityContext.SWIM) 
      return <option value="PAW" >PAW</option>;
  }
}