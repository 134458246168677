import React, {Component} from 'react';

import ButtonWithPopover from '../ButtonWithPopover';

import Button from 'react-bootstrap/Button';

import './PawStatus.css';

class UnclaimPaw extends Component {

  unclaimSwitch = () => {
    this.props.attemptUnclaim();
  }

  render() {
    const reference = "unclaimConfirmationModal";
    return (
      <ButtonWithPopover
        placement= "top"
        customRef={reference+"bwp"}
        buttonVariant="info"
        buttonText="Unclaim"
        buttonIsBlock={true}
        buttonIsDisabled={this.props.disallowEditing}
        popoverTitle="Unclaim Switch"
        buttonId='switchdetails-btn-unclaim-popover'
        >
        <p className="confirmation-text">
          Do you want to <b>unclaim</b> this Drug Group? This will unclaim all drug groups with same Source Drug and Dynamic Switch Group
        </p>
        <div className="align-content-right">
          <Button className="left-spacer" variant="info" 
            onClick={this.unclaimSwitch} id='switchdetails-btn-unclaim'>
            Unclaim
          </Button>
        </div>
      </ButtonWithPopover>
    );
  }
}

export default UnclaimPaw;
