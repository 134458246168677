import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import Colors from "lookup/Colors.json";
import { formatDateWithTime } from "services/MomentUtils";
import {
  ActivityEvents,
  closeReasonDisplayEnabledForEvents,
} from "lookup/AgentWorkspaceConstants";
import { formatDateForDisplay } from "services/MomentUtils";
import { isStringEmptyOrNull } from "services/UtilityService";
import { Col, Row } from "react-bootstrap";

const colorMapForEvents = {
  [ActivityEvents.ESCALATE]: Colors["yellow"],
  [ActivityEvents.ADDED_TO_AW]: Colors["rm-success"],
  [ActivityEvents.HOLD]: Colors["rm-gray-dark"],
  [ActivityEvents.DEESCALATE]: Colors["orange"],
  [ActivityEvents.EXTERNAL_CHANGE]: Colors["yellow"],
  [ActivityEvents.REOPEN]: Colors["green"],
  [ActivityEvents.CLOSE]: Colors["rm-danger"],
  [ActivityEvents.UPDATE_STATE]: Colors["rm-blue"],
  [ActivityEvents.RESCHEDULE]: Colors["rm-blue"],
  [ActivityEvents.FAX_UNLINKED]: Colors["rm-danger"]
};

const ActivityTimeline = (props) => {
  const { activityEvents } = props;
  return (
    <VerticalTimeline
      lineColor={Colors["rm-gray-shadow"]}
      className="rm-timeline big-top-space"
      layout="1-column-left"
    >
      {activityEvents.map((activityEvent, i) => (
        <VerticalTimelineElement
          key={i}
          id={"timelineItemTitleContainer " + activityEvent.creationDate}
          date={formatDateWithTime(activityEvent.creationDate)}
          contentArrowStyle={{
            borderRight: `7px solid ${colorMapForEvents[activityEvent.event]}`,
          }}
          iconStyle={{
            background: `${colorMapForEvents[activityEvent.event]}`,
            color: `${Colors["rm-white"]}`,
          }}
          dateClassName={"grouped-state-title"}
        >
          <span>
            <Row>
              <Col md={6}>
                <div
                  className="pull-left"
                  style={{
                    color: `${colorMapForEvents[activityEvent.event]}`,
                    fontSize: "1.1em",
                  }}
                >
                  <strong>{activityEvent.event}</strong>
                </div>
              </Col>
              <Col md={6}>
                <div
                  className="pull-right"
                  style={{
                    color: `${colorMapForEvents[activityEvent.event]}`,
                  }}
                >
                  <strong>{activityEvent.name}</strong>
                </div>
              </Col>
            </Row>
            <div className="text-mute">
              {!isStringEmptyOrNull(activityEvent.activityName) ? (
                <>
                  <strong>Activity Name:</strong>{" "}
                  {activityEvent.activityName}
                </>
              ) : null}
            </div>
            <div className="text-mute">
              {!isStringEmptyOrNull(activityEvent.state) ? (
                <>
                  <strong>State:</strong> {activityEvent.state}
                </>
              ) : null}
            </div>
            <div className="text-mute">
              {closeReasonDisplayEnabledForEvents(activityEvent.event) &&
                !isStringEmptyOrNull(activityEvent.closeReason) ? (
                <>
                  <strong>Close Reason:</strong> {activityEvent.closeReason}
                </>
              ) : null}
            </div>
            <div className="text-mute">
              <strong>Priority:</strong> {activityEvent.priority}
            </div>
            <div className="text-mute">
              <strong>Scheduled Date:</strong>{" "}
              {formatDateForDisplay(activityEvent.scheduledDate)}
            </div>
            <div className="text-mute">
              {!isStringEmptyOrNull(activityEvent.taskReason) ? (
                <>
                  <strong>Task Reason:</strong>{" "}
                  {activityEvent.taskReason}
                </>
              ) : null}
            </div>
            <div className="text-mute">
              {!isStringEmptyOrNull(activityEvent.note) ? (
                <>
                  <strong>Note:</strong>{" "}
                  {activityEvent.note}
                </>
              ) : null}
            </div>
          </span>
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default ActivityTimeline;
