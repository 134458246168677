import React from 'react';
import './TagBox.css';
import Tag from './Tag';

const TagBox = ({ tags, removeSwitch, placeholder }) => {
    const allTags = tags.map(x => {
        return <Tag tagName={x} key={x} removeSwitch={removeSwitch} />
    })
    return <div className="d-flex flex-wrap">
        {allTags.length == 0 ?
            placeholder :
            allTags
        }
    </div>
}
export default TagBox;