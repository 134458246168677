import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const getValidItemType = (codexVersionId, dynamicSwitchGroupId, medDrugs) => {
    return {
        [RSAA]: {
            endpoint: `${config.apiRoot}/cat/codex-version/${codexVersionId}/dynamic-switch-groups/${dynamicSwitchGroupId}/check-drugs-validity`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
            body: JSON.stringify(medDrugs),
            types: [
                'CHECK_VALID_NEW_DRUG_TYPE',
                'CHECK_VALID_NEW_DRUG_TYPE_SUCCESS',
                'CHECK_VALID_NEW_DRUG_TYPE_FAILURE'
            ]
        }
    };
};
