import React, { useState } from 'react';
import { WrapTextAndCenterAlign } from "components/WrapText";
import { BsBoxArrowUpRight } from "react-icons/bs";
import ActivitiesPopup from "components/MemberModule/ActivitiesPopup/ActivitiesPopup";

const ActivityCounterSwimSwitchList = (props) => {
  const count = props.activityCount;
  const row = props.rowData;
  const [shouldShowActivityPopup, setShouldShowActivityPopup] = useState(false);
  const [switchId, setSwitchId] = useState(null);

  const openActivitiesModal = (switchDetail) => {
    setSwitchId(row.original.switchId);
    changeActivityPopupVisibility();
  };

  const changeActivityPopupVisibility = () => {
    if (shouldShowActivityPopup) {
      setSwitchId(null);
    }
    setShouldShowActivityPopup(!shouldShowActivityPopup);
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      <WrapTextAndCenterAlign>{count}</WrapTextAndCenterAlign>
      {count && count > 0 ? (
        <BsBoxArrowUpRight onClick={openActivitiesModal} />
      ) : null}
      <ActivitiesPopup
        changeActivityPopupVisibility={changeActivityPopupVisibility}
        shouldShowActivityPopup={shouldShowActivityPopup}
        switchId={switchId}
        isForSwimSwitchList={true}
      />
    </div>
  );
};

export default ActivityCounterSwimSwitchList;
