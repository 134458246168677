
const CONTACTMANAGEMENT_CONSTANTS = {

    fieldMaxLengthValidationPrefix: 'Maximum Length: ',
    fieldMinLengthValidationPrefix: 'Minimum Length: ',
    numberValidationError: 'Enter numbers only',
    fieldLengthValidationPrefix:'Allowed Length: '
  
  }
  
  export default CONTACTMANAGEMENT_CONSTANTS;