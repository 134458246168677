import React, { useMemo, useState, useEffect, useReducer } from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  flexRender,
} from 'react-table-7';
import { Table, Spinner } from 'react-bootstrap';
import ColumnFilter from './filters/ColumnFilter';
import PaginationComponent from './PaginationComponent';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import './style.css';
import { FilterView } from './FilterView';
import { Column } from './Column';
import Checkbox from './Checkbox';
import moment from 'moment';
import {
  columnsContent,
  useReactTableHooks,
  renderNoDataComponent,
  renderTableHeaderContent,
  renderTableRowsContent,
  getRowId
} from './commonReactTableFunctions';


const ClientSideReactTable = ({
  columnDefination,
  data = [],
  onRowClick,
  rowKey,
  loading,
  addRowSelection = false,
  enableMultiRowSelection = true,
  enableExpansion = false,
  enableAllRowSelect=true,
  pivot,
  totalRecords,
  onRowSelect,
  centeredContent = false,
  columnShow = Object.fromEntries(columnDefination.map((column) => [column.accessor, (column.show === undefined || column.show) ? true : column.show])),
  trStyle = (row) => { return {} },
  tdStyle = (row, column) => { return {} },
  clicked = [],
  defaultSort = [],
  pageSizeOptions = [5, 10, 15, 20, 30, 50],
  overrideRowId = null
}) => {
  const [selectedRowIds, setSelectedRows] = useState({}); // NEW: Selected row IDs state
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState(defaultSort)
  const [columnVisibility, setColumnVisibility] = useReducer(columnVisibilityReducer, columnShow);
  const [expanded, setExpanded] = useState({});
  function columnVisibilityReducer(state, action) {
    let newState = {}
    newState = Object.assign(newState, action)
    return newState;
  }

  const dateFilter = (row, columnId, value, addMeta) => {
    const date = row.getValue(columnId);

    const momentDate1 = moment(date);
    const momentDate2 = moment(value);

    const isDateMatch = momentDate1.isSame(momentDate2, 'day');
    const isMonthMatch = momentDate1.isSame(momentDate2, 'month');
    const isYearMatch = momentDate1.isSame(momentDate2, 'year');

    // Return true if all three components match
    return isDateMatch && isMonthMatch && isYearMatch;
  }

  const numberFilter = (row, columnId, value, addMeta) => {
    const numberValue = row.getValue(columnId);
    const filterValue = parseInt(value);
    const rowValue = parseInt(numberValue);

    return rowValue === filterValue;

  }

  const handleRowClick = (row, cell) => {
    row.column = cell.column
    // Call the onRowClick function with the row data
    if (onRowClick && cell.column.id !== "select" && cell.column.id !== "option") {
      onRowClick(row);
      clicked.push(true);
    }
  };

  const columns = useMemo(
    () => columnsContent(columnDefination, addRowSelection, enableExpansion,enableAllRowSelect),
    [columnDefination]
  );

  const getRowKey = (row, index, page) => {
    return row.id || index; // Assuming each row has an 'id' property, or else fallback to index
  }

  const table = useReactTable({
    columns,
    data,
    getRowId: getRowKey,
    state: {
      rowSelection: selectedRowIds,
      columnFilters,
      sorting,
      columnVisibility: columnVisibility,
      expanded,
    },
    filterFns: {
      date: dateFilter,
      number: numberFilter
    },
    enableRowSelection: addRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setSelectedRows,
    enableColumnResizing: true,
    enableMultiRowSelection: enableMultiRowSelection,
    enableMultiSort: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: enableExpansion ? getExpandedRowModel() : null,
    getPaginationRowModel: getPaginationRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (originalRow) =>
      originalRow[pivot],
  },
    (hooks) => { useReactTableHooks(hooks) }
  );

  useEffect(() => {
    if (addRowSelection) {
      onRowSelect(selectedRowIds);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (JSON.stringify(columnShow) !== JSON.stringify(columnVisibility)) {
      setColumnVisibility(columnShow, true);
    }
  }, [columnShow]);

  useEffect(() => {
    toggleColumnVisibility();
  }, [columnVisibility]);

  const handleFilterChange = (columnId, value) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [columnId]: value,
    }));
  };

  const renderTableHeader = useMemo(
    () => renderTableHeaderContent(table.getHeaderGroups()),
    [table, handleFilterChange, clicked]
  );
  const renderLoader = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={columns.length} className="text-center">
            <Spinner animation="border" variant="primary" />
          </td>
        </tr>
      );
    }
    return null;
  };
  const toggleColumnVisibility = () => {
    table.getAllLeafColumns().forEach(column => { column.toggleVisibility(columns.filter(col => col.id === column.id)[0].show) });
  }
  const getTotalRecords = () => {
    return table.getFilteredRowModel()
      .rows.length > 0 ? table.getFilteredRowModel().rows.length : table.getPageCount() * table.getState().pagination.pageSize;
  }
  const renderTableRows = useMemo(
    () => renderTableRowsContent(table.getRowModel().rows, handleRowClick, trStyle, tdStyle),
    [table, selectedRowIds, data, sorting, columnFilters, table.getState().pagination.pageIndex, table.getState().pagination.pageSize, clicked]
  );
  return (
    <>
      {loading ? (
        <div className="overlay">
          <div className="overlay-opacity" />
        </div>
      ) : null}
      <Table className={centeredContent ? "text-center" : ""} bordered>
        <thead>{renderTableHeader}</thead>
        <tbody>
          {loading ? null : (
            <>
              {renderTableRows}
              {renderNoDataComponent(table.getRowModel().rows.length, columns.length)}
            </>
          )}
          {renderLoader()}
        </tbody>
      </Table>
      <PaginationComponent
        currentPage={table.getState().pagination.pageIndex}
        totalPages={table.getPageCount()}
        pageSizeOptions={pageSizeOptions}
        selectedPageSize={table.getState().pagination.pageSize}
        onChangePage={pageNo => {
          table.setPageIndex(pageNo);
        }}
        onChangePageSize={e => {
          table.setPageIndex(0);
          table.setPageSize(e);

        }}
        totalRecords={getTotalRecords()}
      />
    </>
  );
};

export default ClientSideReactTable;
