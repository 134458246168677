export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case 'SCHEDULE_BATCH_REQUEST':
      newState = Object.assign({}, previousState, {
        error: '',
        submittingRequest: 'Submitting Scheduled Batch'
      });
      break;

    case 'SCHEDULE_BATCH_SUCCESS':
      newState = Object.assign({}, previousState, {
        error: '',
        submittingRequest: '',
        isSuccess: true,
        eventRequestCompleted: true,
        dateTime: new Date(),
        description: '',
        clientList: [],
        switchCreationDateRange: new Date()
      });
      break;

    case 'SCHEDULE_BATCH_FAILURE':
      newState = Object.assign({}, previousState, {
        error: 'Unable to Create Batch',
        submittingRequest: '',
        dateTime: new Date()
      });
      break;

    case 'RESET_SUCCESS_MESSAGE_BATCH_SCHEDULER':
      newState = Object.assign({}, previousState, {
        error: '',
        isSuccess: false,
        disableAction: true
      });
      break;

    default:
      break;
  }
  return newState;
}