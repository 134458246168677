import Button from "react-bootstrap/Button";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { useState, useEffect } from "react";
import { BiMessageAdd } from "react-icons/bi";
import { compareDates } from "services/MomentUtils";
import "plugins/Lexical/styles.css";
import { showNotification } from "services/NotificationService";
import { Container } from "react-bootstrap";
import { getLoggedInUser } from "services/AuthService";
import NoteItem from "components/ScrollableNotes/NoteItem";
import AddEditNoteModal from "components/ScrollableNotes/AddEditNoteModal";
import { useDispatch, useSelector } from "react-redux";
import { getMemberDetailNotes } from "./MemberDetail.actions";


const MemberDetailNotes = (props) => {
  const { entityId, entitySaveUrl, noteEditStatus, sortByDate, memberNotes, excludeId } = props;
  const [notes, setNotes] = useState(memberNotes ?? {});
  const totalNotes = useSelector((state) => state.memberDetail?.data?.totalElements ?? 0);
  const [showMore, setShowMore] = useState(notes.length === totalNotes ? false : true);
  const [page, setPage] = useState(0);
  const pinnedNotes = useSelector((state) => state.memberDetail?.data?.pinnedNotesCount ?? 0);
  const [excludeIds, setExcludeIds] = useState(excludeId ? excludeId : null)
  const [pinnedNotesCount, setPinnedNotesCount] = useState(pinnedNotes ? pinnedNotes : 0);
  const [totalNotesCount, setTotalNotesCount] = useState(0);
  const DEFAULT_PAGE_SIZE = 15


  useEffect(() => {
    if (
      memberNotes &&
      memberNotes.length > 0 &&
      pinnedNotes >= 0 &&
      totalNotes >= 0
    ) {
      setNotes(memberNotes);
      setPinnedNotesCount(pinnedNotes);
      setTotalNotesCount(totalNotes);
    }
  }, [memberNotes, pinnedNotes, totalNotes]);
  const addNote = (note) => {
    const prePinnedNotes = notes.filter((n) => n.isPinned); // pinned notes -- sorted
    var unPinnedNotes = notes.filter((n) => !n.isPinned); // unpinned notes -- sorted
    prePinnedNotes.forEach((n) => (n.new = false));
    unPinnedNotes.forEach((n) => (n.new = false));
    note.new = true;
    setTotalNotesCount((prev) => prev + 1);
    setExcludeIds([...excludeIds, note.noteId]) //the new note also must be added in exclude ids, otherwise it might be retrieved again
    setNotes([...prePinnedNotes, note, ...unPinnedNotes]);
  };

  const editNote = (note) => {
    const updatedNotes = notes.map((n) =>
      n.noteId === note.noteId
        ? { ...n, note: note.note, isEdited: true, new: false }
        : n
    );
    setNotes(updatedNotes);
  };



  const pinNote = (data, isPinned) => {
    setNotes(data.noteDtoList);
    setPage(0) //this method invokes a backend api call to retrive all notes in correct order with the pinned at top (if pinned) hence the page is set to zero in case if this page is used in future to load more notes
    setExcludeIds(data.excludeIds); //since this retrieves all the notes again we need to reset the excludes ids to make sure they are set to correct values
    data.totalElements === totalNotesCount ? setShowMore(false) : setShowMore(true) //need to set this again here bcz of notes being retrieved 
    if (isPinned) {
      setPinnedNotesCount(prev => prev + 1);
    } else {
      setPinnedNotesCount(prev => prev - 1);
    }
  };

  const loadMore = () => {
    sendAuthenticatedAsyncRequest(
      `/member/load-member-notes/${entityId}`,
      "POST",
      { page, pageSize: DEFAULT_PAGE_SIZE, excludeIds },
      (r) => {
        if (r.data.totalElements > 0 && showMore) {
          if (notes.length + r.data.totalElements === totalNotesCount)
            setShowMore(false);
          setNotes([...notes, ...r.data.noteDtoList]);
          setPage(page + 1);
        } else {
          setShowMore(false);
        }
      },
      (r) => {
        showNotification({
          title: "Unable to load Notes!",
          message: "SOMETHING WENT WRONG",
          position: "tr",
          type: "error",
        });
      }
    );
  };

  return (
    <Container fluid className="notes-container">
      <div className="small-label-notes">
        <div>NOTES ({notes.length})</div>
        <div className="add-notes-modal">
          <AddEditNoteModal
            toolTipText={"Add Note"}
            icon={<BiMessageAdd style={{ color: "#FFAA3B" }} />}
            entityId={entityId}
            newNote={addNote}
            entitySaveUrl={entitySaveUrl}
            openModalButtonDisabled={false}
            openModalButtonClassName={"create-note-btn"}
          />
        </div>
      </div>
      <div className="rm-notes-activity">
        {notes.length > 0 ? (
          <>
            {notes.map((note, idx) => {
              if (idx < notes.length) {
                return (
                  <NoteItem
                    key={note.noteId}
                    claimedBy={getLoggedInUser()?.uuid}
                    pinNote={pinNote}
                    noteEditStatus={noteEditStatus}
                    pinnedNotesCount={pinnedNotesCount}
                    updateNote={editNote}
                    entityId={entityId}
                    entitySaveUrl={entitySaveUrl}
                    note={note}
                    clipped={false}
                  />
                );
              } else return null;
            })}
            {showMore ? (
              <Button
                variant="link"
                size="sm"
                onClick={loadMore}
                className="btn-block"
                data-testid="btn-activity-show-more-notes"
              >
                Show More
              </Button>
            ) : (
              <span
                className="rm-horizontal-center rm-switch-notes-end"
                data-testid="text-activity-no-more-notes"
              >
                NO MORE NOTES
              </span>
            )}
          </>
        ) : (
          <div
            className="rm-horizontal-center text-muted tiny-top-spacer rm-switch-notes-end"
            data-testid=""
            text-activity-no-notes="true"
          >
            NO NOTES
          </div>
        )}
      </div>
    </Container>
  );
};

export default MemberDetailNotes;
