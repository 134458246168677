import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const updateBatchList = (pageSize, page, sorted, filtered, resized, expanded) => {
  return {

    [RSAA]: {
      endpoint: `${config.apiRoot}/swim/batch-list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        {
          type: 'UPDATE_BATCH_LIST_REQUEST',
          payload: { listState: {pageSize, page, sorted, filtered, resized, expanded} }
        },
        'UPDATE_BATCH_LIST_SUCCESS',
        'UPDATE_BATCH_LIST_FAILURE'
      ]
    }
  };
};

export const updateBatchIndexOnList = (index) => {
  return {
    type: "UPDATE_BATCH_INDEX_ON_LIST",
    indexOnList: index
  }

};
export const updateSwitchList = (wfBatchId) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/swim/batch-details?wf_batch_id=${wfBatchId}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        {
          type: 'UPDATE_SWITCHES_FOR_BATCH_LIST_REQUEST',
          payload: { listState: {} }
        },
        'UPDATE_SWITCHES_FOR_BATCH_LIST_SUCCESS',
        'UPDATE_SWITCHES_FOR_BATCH_LIST_FAILURE'

      ]
    }
  };
};
export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_BATCH_LIST"

  }
}
