import Spinner from "components/Spinner";
import React, { useState, useEffect } from "react";
import { Alert, Tab, Tabs } from "react-bootstrap";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { showNotification } from "services/NotificationService";
import ActivityTimeline from "./ActivityTimeline";

const ActivityHistory = (props) => {
  const { activityId } = props;
  const [activityHistoryDetails, setActivityHistoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/${activityId}/activity-history`,
      "GET",
      null,
      (r) => {
        setActivityHistoryDetails(r.data);
        setLoading(false);
      },
      (r) => {
        setLoading(false);
        showNotification({
          title: "Error!",
          message:
            getErrorMessage(r.data) ||
            getErrorMessage("UNABLE_TO_FETCH_ACTIVITY_HISTORY"),
          position: "tr",
          type: "error",
        });
      }
    );
  }, []);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Tabs
          defaultActiveKey="timeline"
          id="activity-history-tabs"
          className="mb-3"
          fill
        >
          <Tab eventKey="timeline" title="Activity Timeline">
            {activityHistoryDetails &&
            activityHistoryDetails.events.length > 0 ? (
              <ActivityTimeline
                activityEvents={activityHistoryDetails.events}
              />
            ) : (
              <Alert variant="warning" style={{ marginBottom: "10px" }}>
                History for this activity is not available. 
              </Alert>
            )}
          </Tab>
          <Tab eventKey="priority" title="Applied Priority Rules">
            <Alert variant="info" style={{ marginBottom: "10px" }}>
              <p>This <strong>priority/rank may differ</strong> from the one mentioned in the
              activity list as it calculated at runtime and contains dynamically
              changing parameters.</p>
              <p>e.g: CLOSED activity will show <strong>0</strong> as rank in the activity list.</p>
            </Alert>
            <ul>
              {activityHistoryDetails
                ? activityHistoryDetails.appliedPriorityRules.map((rule) => (
                    <li key={rule}>{rule}</li>
                  ))
                : null}
            </ul>
          </Tab>
        </Tabs>
      )}
    </>
  );
};

export default ActivityHistory;
