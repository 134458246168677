import React, { Component } from 'react';
import { formatDateForDisplay } from '../services/MomentUtils';
import { WrapTextAndCenterAlign, WrapTextOnNoSpace } from 'components/WrapText';
import DismissableModal from 'components/DismissableModal';
import { twoDecimalPlaceRounder } from 'services/UtilityService';
import ClientSideReactTable from 'commons/table/ClientSideReactTable';
import { ColumnTypes } from 'commons/table/Constants';

const DEFAULT_PAGE_SIZE = 5;
export default class FillDetailViewer extends Component {

  renderSavingsCell = (savings) => `$${twoDecimalPlaceRounder(savings)}`;

  columns = [
    {
      header: "Filled on",
      accessor: "claimFillDate",
      accessorKey: "claimFillDate",
      columnType: ColumnTypes.DateColumn,
      enableSorting: true,
      width: 30,
    },
    {
      header: "Drug Name",
      accessor: "preferredDrugName",
      accessorKey: "preferredDrugName",
      columnType: ColumnTypes.Text,
      enableSorting: true,
      width: 90,
    },
    {
      header: "Strength",
      accessor: "claimDrugStrength",
      accessorKey: "claimDrugStrength",
      columnType: ColumnTypes.Text,
      enableSorting: true,
      width: 30,
    },
    {
      header: "Supply (days)",
      accessor: "totalDaysSupply",
      accessorKey: "totalDaysSupply",
      columnType: ColumnTypes.Text,
      enableSorting: true,
      width: 30,
    },
    {
      header: "Plan Savings",
      accessor: "planSavings",
      accessorKey: "planSavings",
      columnType: ColumnTypes.Text,
      enableSorting: true,
      width: 30,
    },
    {
      header: "Member Savings",
      accessor: "memberSavings",
      accessorKey: "memberSavings",
      columnType: ColumnTypes.Custom,
      customComponent: (value) => {
        return (<WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>);
      },
      enableSorting: true,
      width: 30,
    },
    {
      header: "NDC #",
      accessor: "claimNdc",
      accessorKey: "claimNdc",
      columnType: ColumnTypes.Text,
      enableSorting: true,
      width: 40,
    },
    {
      header: "Claim Info Id",
      accessor: "claimInfoId",
      accessorKey: "claimInfoId",
      columnType: ColumnTypes.Text,
      enableSorting: true,
      width: 30,
    }
  ]

  render() {
    const data = this.props.fillDetails;

    data.forEach(fillDetail => {
      fillDetail.preferredDrugName = fillDetail.preferredDrugName || 'DRUG NAME N/A';
      fillDetail.claimFillDate = formatDateForDisplay(fillDetail.claimFillDate)
    });
    return (
      <DismissableModal
        customModalStyle={{ height: '100%' }}
        openModalButtonText="View Fill Detail"
        openModalButtonId='switchdetails-fill-details-popup'
        title="Fill Detail"
        openModalButtonStyle="outline-warning"
        openModalButtonSize='sm'
        modalId='fill-detail"'
        modalClassName="super-wide-modal"
      >
        <ClientSideReactTable
          data={data}
          columnDefination={this.columns}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          centeredContent={true}
        />

      </DismissableModal>
    )
  }
}

