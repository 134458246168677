import { Row, Col, Button, Spinner } from "react-bootstrap";
import "./Header.css";
import "assets/css/components.css";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { useState, useEffect } from "react";
import { showNotification } from "services/NotificationService";
import { formatDateWithTime } from "services/MomentUtils";

const Header = (props) => {
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const dashboardId = props.dashboardId;

  useEffect(() => {
    fetchDashboardDetails();
  }, []);

  const fetchDashboardDetails = () => {
    sendAuthenticatedAsyncRequest(
      `/analytics/dashboard-details/${dashboardId}`,
      "GET",
      null,
      (r) => {
        setDashboardDetails(r.data);
        setLoading(false);
      },
      (r) => {
        showNotification({
          title: "Error!",
          message: "Unable to fetch dashboard details",
          position: "tr",
          type: "error",
        });
        setDashboardDetails(null);
        setLoading(false);
      }
    );
  };
  return loading ? (
    <div className="rm-horizontal-center">
      <Spinner />
    </div>
  ) : (
    <div className="display-bar">
      <Row className="codex-version-display-bar">
        {dashboardDetails ? (
          <Col md={4} className="my-auto">
            <span style={{ float: "left" }}>
              {" "}
              Last Modified:{" "}
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {formatDateWithTime(dashboardDetails.lastModifiedDate)}
              </span>
            </span>
          </Col>
        ) : null}

        <Col md={4} className="my-auto">
          <span>
            <strong style={{ float: "center" }}>
              {dashboardDetails
                ? dashboardDetails.dashboardName
                : "Unable to fetch dashboard name"}
            </strong>
          </span>
        </Col>
        {dashboardDetails ? (
          <Col md={4}>
            <Button
              style={{ float: "right" }}
              key={dashboardDetails.confluenceDocumentLink}
              href={dashboardDetails.confluenceDocumentLink}
              target="_blank"
              variant="light"
            >
              Confluence Document
            </Button>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};
export default Header;
