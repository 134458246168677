import Colors from './Colors';

export const DateConstants = {
  status: {
    ComingDue : "ComingDue",
    PastDue : "PastDue"
  },
  statusColors : {
    PastDue : Colors['rm-danger'],
    ComingDue: Colors['rm-success']  }
}