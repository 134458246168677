import { RSAA } from "redux-api-middleware";
import config from "config";
import {
  AUTH_TOKEN_HEADER_KEY,
  getAuthorizationToken,
} from "services/AuthService";

export const scheduleBulkActionRequest = (bulkActionDto) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/bulk-action`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      body: JSON.stringify(bulkActionDto),
      types: [
        "SCHEDULE_BULK_ACTION_REQUEST",
        "SCHEDULE_BULK_ACTION_SUCCESS",
        "SCHEDULE_BULK_ACTION_FAILURE",
      ],
    },
  };
};

export const updateBulkActionRequest = (requestDto, bulkActionUuid) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/bulk-action/${bulkActionUuid}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      body: JSON.stringify(requestDto),
      types: [
        "UPDATE_BULK_ACTION_REQUEST",
        "UPDATE_BULK_ACTION_SUCCESS",
        "UPDATE_BULK_ACTION_FAILURE",
      ],
    },
  };
};

export const createNewChildBulkActionRequest = (requestDto, bulkActionUuid) => {
  console.log("This is the request dto", requestDto);
  console.log("This is the bulk action uuid", bulkActionUuid);
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/bulk-action/${bulkActionUuid}/create-child`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      body: JSON.stringify(requestDto),
      types: [
        "CREATE_NEW_CHILD_BULK_ACTION_REQUEST",
        "CREATE_NEW_CHILD_BULK_ACTION_SUCCESS",
        "CREATE_NEW_CHILD_BULK_ACTION_FAILURE",
      ],
    },
  };
};

export const cancelBulkActionRequest = (bulkActionUuid) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/bulk-action/${bulkActionUuid}/cancel`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        "CANCEL_BULK_ACTION_REQUEST",
        "CANCEL_BULK_ACTION_SUCCESS",
        "CANCEL_BULK_ACTION_FAILURE",
      ],
    },
  };
};

export const resetSuccess = () => ({
  type: "RESET_SUCCESS_MESSAGE_BULK_ACTION_SCHEDULER",
});

export const resetSuccessCancel = () => ({
  type: "RESET_SUCCESS_MESSAGE_BULK_ACTION_CANCEL",
});

export const resetSuccessUpdation = () => ({
  type: "RESET_SUCCESS_MESSAGE_BULK_ACTION_UPDATION",
});

export const resetSuccessChildCreation = () => ({
  type: "RESET_SUCCESS_MESSAGE_BULK_ACTION_CHILD_CREATION",
});
