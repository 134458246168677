import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from 'views/Login/Login.actions';
import {sendAuthenticatedAsyncRequest} from '../../services/AsyncRequestService';
import Button from "react-bootstrap/Button";
import {Navbar, NavDropdown, Dropdown, Nav} from 'react-bootstrap';
import { getLoggedInUser} from '../../services/AuthService';
import { isLoggedInUserAWUser } from 'services/AWUtilityService';
import { MdAccountCircle, MdMenu } from 'react-icons/md';
import logo from "../../assets/images/rm-logo.png";
import {EnvironmentBadge} from '../../components/EnvironmentBadge';
import './Topbar.css';
import colors from 'lookup/Colors';

const topbarStyle = {
  backgroundColor: colors['rm-orange']
}

const navBarLinkStyle = {
  color: colors['rm-gray-light']
}

class Topbar extends React.Component {
  state = {
    loggedInUser: getLoggedInUser()
  }

  getLogoutUrl = () => {
    let url = "/logout-cognito";
    let method = "GET";
    sendAuthenticatedAsyncRequest(url, method, null, this.getLogoutUrlSuccess, this.getLogoutUrlFailure);
  }

  getLogoutUrlSuccess = (response) => {
    this.props.logout(() => {});
    window.location = response.data;
  }

  getLogoutUrlFailure = (error) => {
  	console.log("something went wrong", error.data);
  }

  getHeaderButtonText() {
    if(this.props.location.pathname === '/agent-workspace/list'){
      return 'Agent Dashboard';
    } else return 'Agent Workspace';
    
  }

  render () {
    const { loggedInUser } = this.state;
    const LogoutButton = withRouter(
      ({ history }) =>
      <Nav.Link style={navBarLinkStyle}
        onClick={() => {
          // this.props.logout(() => history.push("/login"));
          this.getLogoutUrl();
        }} >
        Logout
      </Nav.Link>
    );
  
    return (
      <Navbar style={topbarStyle} variant="dark" >
        <MdMenu className="sidebar-menu-btn" onClick={this.props.handleSidebarClick}/>
        <Navbar.Brand style={{padding: "0"}}> <img style={{height: "40px", width: "auto"}} className="logo-large" alt="Imagine RazorMetrics Logo" src={logo} /> </Navbar.Brand>
        <EnvironmentBadge/>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
            {
              isLoggedInUserAWUser()
              ?
              <Button 
                color={colors['rm-blue']}
                className="btn-text-white"
                onClick={() => this.props.location.pathname === '/agent-workspace/list' ? this.props.history.push("/agent-workspace/dashboard") : this.props.history.push("/agent-workspace/list")}
              >
                {this.getHeaderButtonText()}
              </Button>
              :
              null
            }
              <NavDropdown title={loggedInUser ? <span style={navBarLinkStyle}><MdAccountCircle className="account-nav-dropdown" /> {loggedInUser.name}</span> : 'Someone'} className="custom-dd-position" >
                <Dropdown.Item  onClick={() => this.props.history.push("/account/change-password")}>Change Password</Dropdown.Item>
              </NavDropdown>
              <LogoutButton />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: bindActionCreators(logout, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Topbar));