import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'react-bootstrap/Button';

import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import DismissableModal from 'components/DismissableModal';
import {saveWorkingCodexInfo} from 'views/Cat/CodexVersion/CodexVersion.actions';
import CAT_CONSTANTS from 'lookup/CatConstants';
import { getErrorMessage } from 'services/UtilityService';
import { showNotification } from 'services/NotificationService';

const lookup = {
  ACTIVE: {
    preventAction: true,
    messageOnAttempt: 'Cannot change state of active version!',
  },
  IN_DEVELOPMENT: {
    modalBody: workingCodexVersion => 
      <p>
        Are you sure you want to <strong>Approve</strong> changes made in Codex <strong>V {workingCodexVersion}</strong>? <br /> 
        <span className='text-muted'>Another CT member needs to approve the Codex for testing.</span>
      </p>,
    nextStatus: CAT_CONSTANTS.codexStatuses.APPROVED,
    openModalButtonText: 'Approve Codex',
    openModalButtonVariant: 'warning'
  },
  APPROVED_REVERSE: {
    modalBody: workingCodexVersion => 
    <p>
      Are you sure you want to revert the codex <strong>V {workingCodexVersion}</strong> back to <strong>Development</strong> state? <br /> 
      <span className='text-muted'>Doing so will allow the users to make and commit changes to the codex.</span>
    </p>,
    nextStatus: CAT_CONSTANTS.codexStatuses.IN_DEVELOPMENT,
    openModalButtonText: 'Reopen for Development',
    openModalButtonVariant: 'outline-danger',
  },
  PARTIALLY_APPROVED: {
    modalBody: workingCodexVersion => 
      <p>
        Are you sure you want to <strong>Approve</strong> the codex <strong>V {workingCodexVersion}</strong>? <br /> 
        <span className='text-muted'>Doing so will lock the codex for testing.</span>
      </p>,
    nextStatus: CAT_CONSTANTS.codexStatuses.APPROVED,
    openModalButtonText: 'Approve Codex',
    openModalButtonVariant: 'warning',
    reverseAction: 'APPROVED_REVERSE' // must be a key in the same lookup
  },
  APPROVED: {
    modalBody: workingCodexVersion => 
      <p>
        Are you sure you want to <strong>Publish</strong> the codex <strong>V {workingCodexVersion}</strong>? <br /> 
        <span className='text-muted'>Doing so will publish the codex in production and will mark all previous versions as archived.</span>
      </p>,
    nextStatus: CAT_CONSTANTS.codexStatuses.ACTIVE,
    openModalButtonText: 'Publish Codex',
    openModalButtonVariant: 'danger',
    reverseAction: 'APPROVED_REVERSE' // must be a key in the same lookup
  },
  ARCHIVED: {
    preventAction: true,
    messageOnAttempt: 'Cannot change state of archived version!'
  }
}

class CodexStatusModal extends Component {
  state = {
    workingCodexVersion: this.props.workingCodexVersion,
    workingCodexVersionId: this.props.workingCodexVersionId,
    workingCodexVersionStatus: this.props.workingCodexVersionStatus,
    lookupKey: this.props.lookupKey,
    loading: false,
    disabledButton: this.props.disabledButton,
    firstApprovalBy: this.props.firstApprovalBy
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {workingCodexVersionStatus, workingCodexVersionId, lookupKey, disabledButton} = nextProps;
    if (
      workingCodexVersionStatus !== prevState.workingCodexVersionStatus ||
      workingCodexVersionId !== prevState.workingCodexVersionId ||
      lookupKey !== prevState.lookupKey ||
      disabledButton !== prevState.disabledButton
    ) {
      return { workingCodexVersionStatus, workingCodexVersionId, lookupKey, disabledButton };
    }
    return null;
  }

  changeCodexStatus = (closeModal) => {
    const {lookupKey, workingCodexVersionId} = this.state;
    if (!lookup[lookupKey].nextStatus){
      showNotification({
        title: 'Action not allowed!',
        message: lookup[lookupKey].messageOnAttempt,
        position: 'tr',
        type: "error"
      });
      return;
    }
    this.setState({loading: true});
    sendAuthenticatedAsyncRequest(
      `/cat/codex-version/${workingCodexVersionId}/status/${lookup[lookupKey].nextStatus}`,
      'PUT', 
      null,
      (r) => {
        const {version, codexVersionId, creationDate, tag, status, firstApprovalBy} = r.data;
        this.props.saveWorkingCodexInfo(version, codexVersionId, creationDate, tag, status, firstApprovalBy);
        closeModal();
        this.setState({loading: false});
        // dispatch success notification
        showNotification({
          title: `Codex Status Updated Successfully!`,
          message: 'The codex status has been updated successfully',
          position: 'tr',
          type: "success"
        });
      },
      (r) => {
        this.setState({loading: false});
        showNotification({
          title: 'Something went wrong!',
          message: (getErrorMessage(r.data) || getErrorMessage('UNABLE_TO_CHANGE_CODEX_STATUS')),
          position: 'tr',
          type: "error"
        });
      },
    );
  }

  render() {
    const { workingCodexVersion, lookupKey, loading} = this.state;

    if (!lookupKey || lookup[lookupKey].preventAction)
      return null;

    const title = "Change Codex Status"
    const modalRef = `CodexStatusModal${lookupKey}`;
    const modalOpener = 
      <Button size='sm' style={{float: 'right', marginRight: 5}} key={1}
        variant={lookup[lookupKey].openModalButtonVariant || 'outline-light'}  
        onClick={ () => this.refs[modalRef].handleShow()}
        disabled={this.props.disabledButton}>
          {lookup[lookupKey].openModalButtonText || 'Change Codex Status'}
        </Button>;
    const customFooterContent = (
      <div>
        <Button variant="outline-dark" onClick={ () => this.refs[modalRef].handleClose() } disabled={loading}>Cancel</Button>
        <Button 
          className="left-spacer" 
          variant={lookup[lookupKey].openModalButtonVariant || 'warning'}
          disabled={loading}
          onClick={() => this.changeCodexStatus(this.refs[modalRef].handleClose)}>
          {loading ? 'Updating Codex Status ...' : lookup[lookupKey].openModalButtonText || 'Change Codex Status'}
        </Button>
      </div>
    );
    return (
      <>
      <DismissableModal
        ref={modalRef}
        openModalButton={modalOpener}
        title={title}
        customFooterContent={customFooterContent}
        >
        {lookup[lookupKey].modalBody(workingCodexVersion)}
      </DismissableModal>
      {
        lookup[lookupKey].reverseAction
        ? <Exportable lookupKey={lookup[lookupKey].reverseAction}/>
        : null
      }
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const workingCodexVersionStatus = state.codexVersion ? state.codexVersion.codexVersionStatus : null;
  const lookupKey = ownProps.lookupKey || workingCodexVersionStatus;
  return {
    lookupKey,
    workingCodexVersion: state.codexVersion ? state.codexVersion.codexVersion : null,
    workingCodexVersionStatus, 
    workingCodexVersionId: state.codexVersion ? state.codexVersion.codexVersionId : null,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveWorkingCodexInfo: bindActionCreators(saveWorkingCodexInfo, dispatch)
  }
}

const Exportable = connect(mapStateToProps, mapDispatchToProps)(CodexStatusModal);
export default Exportable;