import { getErrorMessage } from "./UtilityService"

export function init(inProgress){
  return {
    inProgress: inProgress || false,
    error: false,
    message: null
  }
}

export function start(){
  return {
    inProgress: true,
    error: false,
    message: null
  }
}

export function success(message){
  return {
    inProgress: false,
    error: false,
    message: message || null
  }
}
export function failure(message){
  console.log("ART Failure: ", message);
  return {
    inProgress: false,
    error: true,
    message: getErrorMessage(message.data) || getErrorMessage(message) || null
  }
}