import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'components/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {sendAuthenticatedAsyncRequest} from '../../services/AsyncRequestService';
import { updateEmbedUrl } from "./Landing.actions";

export class Landing extends Component{

  state = {
    embedUrl: this.props.embedUrl,
    dashboardUrlLoading: false,
    embeddedDashboard: null
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.embedUrl !== prevState.embedUrl) {
      return {
        embedUrl: nextProps.embedUrl
      };
    }
    return null;
  }

  componentDidMount() {
    if(!this.state.embedUrl) {
      this.sendEmbeddedDashboardUrlRequest();
    } else {
      this.initializeQuicksightDashboard(this.state.embedUrl);
    }
  }

  sendEmbeddedDashboardUrlRequest() {
    let uri = this.props.isSourceSWIM === false 
    ? 
      this.props.dashboardId
      ? 
        `/analytics/quicksight-dashboard-url/${this.props.dashboardId}` 
      : "/agent-workspace/quicksight-dashboard-url" 
    : "/swim/quicksight-dashboard-url";
    let method = "GET";
    this.setState({dashboardUrlLoading: true});
    sendAuthenticatedAsyncRequest(uri, method, null, this.onEmbedUrlFound, this.onEmbedUrlNotFound);
  }

  initializeQuicksightDashboard (embedUrl) {
    let QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");
    let options = {
      url: embedUrl,
      errorCallback: this.onQuicksightDashboardError,
      container: "#dashboardContainer",
      // parameters: {
      //     country: "United States",
      //     states: [
      //         "California",
      //         "Washington"
      //     ]
      // },
      scrolling: "no",
      width: "100%",
      height: "AutoFit",
      loadingHeight: '700px',
    };
    if(this.state.embeddedDashboard) { //if previous embedded dashboard exists
      this.state.embeddedDashboard.container.removeChild(this.state.embeddedDashboard.iframe); //remove previous dashboard
    }
    let embeddedDashboard = QuickSightEmbedding.embedDashboard(options);
    this.setState({embeddedDashboard});
  }

  onEmbedUrlFound = (response) => {
    this.setState({dashboardUrlLoading: false});
    this.props.updateEmbedUrl(response.data);
    this.initializeQuicksightDashboard(response.data);
  }

  onEmbedUrlNotFound = (response) => {
    this.setState({dashboardUrlLoading: false});
  }

  onQuicksightDashboardError = (res) => {
    console.log("Dashboard Loading err")
    console.log(res);
    if(res.errorCode === "Forbidden" || res.errorCode === "Unauthorized") {
      this.sendEmbeddedDashboardUrlRequest();
    }
  }

  render() {
    return (
      <Container fluid className='top-spacer'>
        <Row>
          <Col md={12}>
            {
              this.state.dashboardUrlLoading ?
              <Spinner/>
              : null
            }
            <div id="dashboardContainer"></div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    embedUrl: state.landing.embedUrl
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateEmbedUrl: bindActionCreators(updateEmbedUrl, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);

