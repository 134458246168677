import DismissableModal from "components/DismissableModal";
import { useRef } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { disableDsgForDrugGroup } from "../DrugGroupDetails/DrugGroupDetails.actions";

const styles = {
  buttonStyle: { margin: 0, padding: "3px 10px" },
  button: { marginRight: 10 },
};
const DrugGroupDisableDsg = ({
  dsgId,
  dsgName,
  codexVersion,
  drugGroupId,
  isPAWAdmin,
  drugGroupStatus,
}) => {
  const modalRef = useRef("disableDsg");
  const dispatch = useDispatch();
  const statusloading = useSelector(
    (state) => state.drugGroupDetail.statusLoader
  );
  const open = () => {
    modalRef.current.handleShow();
  };

  const disableDSG = () => {
    dispatch(disableDsgForDrugGroup(dsgId));
    close();
  };

  const modalOpener =
    isPAWAdmin && drugGroupStatus !== "AUTO_REJECTED_BY_DSG" ? (
      <Button variant="primary" style={styles.buttonStyle} onClick={open}>
        Disable
      </Button>
    ) : drugGroupStatus == "AUTO_REJECTED_BY_DSG" ? (
      "DSG Disabled"
    ) : (
      ""
    );

  const close = () => {
    if (modalRef?.current) {
      modalRef.current.handleClose();
    }
  };

  const customFooterContent = (
    <div>
      <Button
        variant="outline-dark"
        style={styles.button}
        disabled={statusloading}
        onClick={close}
      >
        Cancel
      </Button>
      <Button
        className="left-spacer"
        variant={"success"}
        disabled={statusloading}
        onClick={disableDSG}
      >
        {statusloading ? "Disabling DSG..." : "Disable DSG"}
      </Button>
    </div>
  );

  return (
    <DismissableModal
      ref={modalRef}
      key={drugGroupId}
      openModalButton={modalOpener}
      title={`Disable this DSG?`}
      customFooterContent={customFooterContent}
      disabled={statusloading}
    >
      <p>
        This would disable all the drug groups created from this DSG{" "}
        <strong>{dsgName}</strong> of Codex Version{" "}
        <strong>V{codexVersion}</strong>.
      </p>
    </DismissableModal>
  );
};
export default DrugGroupDisableDsg;
