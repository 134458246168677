export const UnprocessedFaxConstants = {
  DEFAULT_UNPROCESSED_FAX_LIST_SORTING: [
    { id: "generatedDate", desc: true, isDefault: true },
  ],
  DEFAULT_UNPROCESSED_FAX_LIST_FILTER: [
    { id: "associatedSwitchId", value: false, isdefault: true },
    { id: "showMyRecentsOnly", value: false },
  ],
  OVERRIDABLE_STATES: [
    "ACCEPTED_RESPONSE_FOLLOWUP",
    "DECLINED_RESPONSE_FOLLOWUP",
    "ERROR_RESPONSE_FOLLOWUP",
  ],
  UNPROCESSED_FAX_PRIORITY_SINGLE_SELECT_OPTIONS: [
    { key: "", value: "" },
    { key: "ALERT", value: "Alert" },
    { key: "CONTACT", value: "Contact" },
    { key: "REVIEW", value: "Review" },
    { key: "PROCESSING", value: "Processing" },
    { key: "UNLINKED", value: "Unlinked" },
    { key: "OTHER", value: "Other" },
  ],
  UNPROCESSED_FAX_PRIORITY: {
    ALERT: "Alert",
    CONTACT: "Contact",
    REVIEW: "Review",
    PROCESSING: "Processing",
    UNLINKED: "Unlinked",
    OTHER: "Other",
  },
};
