import { RSAA } from 'redux-api-middleware';
import config from '../../../config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';

export const updateRawList = (pageSize, page, sorted, filtered, resized, expanded) => (dispatch, getState) => {
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/raw/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        {
          type: 'UPDATE_RAW_LIST_REQUEST',
          // payload: async (action, state, res) => {
          //   const data = await res.json();
          //   return { pageSize: pageSize, sorted: sorted, filtered: filtered,...data };
          // }
          payload: { pageSize: pageSize, sorted: sorted, filtered: filtered, page: page }
        },
        'UPDATE_RAW_LIST_SUCCESS',
        'UPDATE_RAW_LIST_FAILURE'
      ]
    }
  }
  return dispatch(rsaaObject);
};

export const updateRawIndexOnList = (index) => (dispatch, getState) => {
  return {
    type: "UPDATE_RAW_INDEX_ON_LIST",
    indexOnList: index
  }
}

export const resetError = () => (dispatch, getState) => {
  return {
    type: "RESET_ERROR_MESSAGE_RAW_LIST"
  }
}
