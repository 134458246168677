import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';

export const updateUserList = (pageSize, page, sorted, filtered, resized, expanded) => {

  return {
   
    [RSAA]: {
      endpoint: `${config.apiRoot}/user/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        {
          type: 'UPDATE_USER_LIST_REQUEST',
          payload: { listState: { pageSize, page, sorted, filtered, resized, expanded } }
        },
        'UPDATE_USER_LIST_SUCCESS',
        'UPDATE_USER_LIST_FAILURE'
      ]
    }
  };
};


export const updateUserIndexOnList = (index) => {
  return {
    type: "UPDATE_USER_INDEX_ON_LIST",
    indexOnList: index
  }

};
export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_USER_LIST"
  }
};
