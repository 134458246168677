import React, { Component } from "react";
import { MdDeleteForever } from "react-icons/md";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { getErrorMessage } from "services/UtilityService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { showNotification } from "services/NotificationService";
import "assets/css/user.css";

class DeleteUser extends Component {
  state = {
    errorMsg: null,
    userInfoId: this.props.userInfoId,
  };

  deleteUser = () => {
    sendAuthenticatedAsyncRequest(
      `/user/${this.state.userInfoId}`,
      "DELETE",
      null,
      (r) => {
        this.setState({ asyncInProgress: false });
        showNotification({
          title: "Action successful!",
          message: "User deleted successfully.",
          position: "tc",
          type: "success",
        });
        
        this.props.handler();
      },
      (r) => {
        this.setState({ asyncInProgress: false });
        showNotification({
          title: "Error!",
          message:
            getErrorMessage(r.data) || getErrorMessage("UNABLE_TO_DELETE_USER"),
          position: "tc",
          type: "error",
        });
      }
    );
  };

  render() {
    return (
      <OverlayTrigger overlay={<Tooltip>Delete User</Tooltip>}>
        <MdDeleteForever
          className="user-action-button delete-user"
          size={20}
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this user?")) {
              this.deleteUser();
            }
          }}
        ></MdDeleteForever>
      </OverlayTrigger>
    );
  }
}

export default DeleteUser;
