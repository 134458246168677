import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from '../services/AuthService';
import config from '../config';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
	    return isAuthenticated() ? 
        <Component {...props} /> : 
        <Redirect to={{ pathname: config.redirectRouteOnAuthenticationFaliure, state: { from: props.location }}} />
	}
  } />
)

export default PrivateRoute;