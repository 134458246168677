import React from "react";

import BPagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "./Pagination.css";
import { FormSelect } from "react-bootstrap";

class Pagination extends React.Component {
  state = this.prepareState(this.props);

  componentWillReceiveProps(props) {
    this.setState(this.prepareState(props));
  }
  
  prepareState(props) {
    const { currentPage, totalPages, pageSize, pageSizeOptions } = props;
    const firstPage = 0;
    const lastPage = totalPages > 0 ? totalPages - 1 : 0;
    const previousPage = currentPage > firstPage ? currentPage - 1 : firstPage;
    const nextPage = currentPage < lastPage ? currentPage + 1 : lastPage;
    return {
      currentPage,
      firstPage,
      lastPage,
      previousPage,
      nextPage,
      totalPages,
      pageSize,
      pageSizeOptions,
    };
  }

  onPageChangeManually = (e) => {
    const { value } = e.target;
    const { lastPage, firstPage } = this.state;
    if (value) {
      let page = parseInt(e.target.value, 10) - 1;
      if (page > lastPage || page < firstPage) return;
      this.props.onPageChange(page);
    } else {
      this.setState({ currentPage: "" });
    }
  };

  render() {
    const {
      currentPage,
      firstPage,
      lastPage,
      previousPage,
      nextPage,
      totalPages,
      pageSize,
      pageSizeOptions,
    } = this.state;
    const { onPageChange, onPageSizeChange } = this.props;
    return (
      <div className="rm-pagination-box">
        <BPagination className="rm-pagination">
          <OverlayTrigger overlay={<Tooltip>First</Tooltip>}>
            <BPagination.First
              id="paginator-btn-first"
              onClick={() => onPageChange(firstPage)}
              disabled={currentPage === firstPage}
            />
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Previous</Tooltip>}>
            <BPagination.Prev
              id="paginator-btn-prev"
              onClick={() => onPageChange(previousPage)}
              disabled={currentPage === firstPage}
            />
          </OverlayTrigger>
          <div className="rm-page-selector-box">
            Page
            <Form.Control
              className="rm-page-selector"
              type="number"
              min={firstPage + 1}
              max={lastPage + 1}
              value={currentPage !== "" ? currentPage + 1 : ""}
              onChange={this.onPageChangeManually}
              id={`paginator-page-textbox`}
            />
            of {totalPages === 0 ? 1 : totalPages}
          </div>
          <OverlayTrigger overlay={<Tooltip>Next</Tooltip>}>
            <BPagination.Next
              id="paginator-btn-next"
              onClick={() => onPageChange(nextPage)}
              disabled={currentPage === lastPage}
            />
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Last</Tooltip>}>
            <BPagination.Last
              id="paginator-btn-last"
              onClick={() => onPageChange(lastPage)}
              disabled={currentPage === lastPage}
            />
          </OverlayTrigger>
        </BPagination>
        {pageSizeOptions.length > 1 && (
          <div className="rm-page-size-selector-box">
            <FormSelect
              style={{ fontSize: "0.75em" }}
              onChange={(e) => onPageSizeChange(parseInt(e.target.value))}
              defaultValue={pageSize}
              className="rm-page-size-selector"
            >
              {pageSizeOptions.map((o, i) => (
                <option value={o} key={i} id={`paginator-rows-dd-${o}`}>
                  {o} rows
                </option>
              ))}
            </FormSelect>
          </div>
        )}
      </div>
    );
  }
}

export default Pagination;
