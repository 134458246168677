import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SwitchListCommon from "views/Switch/SwitchList/SwitchListCommon";
import { COLUMN } from "../../Switch/SwitchList/CreateColumnListForSwitchList";
import {
  DEFAULT_BULK_ACTION_LIST_SORT,
  DEFAULT_BULK_ACTION_FILTER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_BULK_ACTION_HOLD_FILTER,
} from "lookup/BulkActionListConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
  Container,
} from "react-bootstrap";
import { MdHelp } from "react-icons/md";
import {
  updateInterventionList,
  updateIndexOnList,
  resetError,
  updateBulkActionListState,
} from "./BulkActionList.actions";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { DEFAULT_BULK_ACTION_CLIENT_PAUSE_FILTER } from "lookup/BulkActionListConstants";

const columnListWithUuid = [
  COLUMN.customerNameAbbreviation,
  COLUMN.clientNameAbbreviation,
  COLUMN.switchNumber,
  COLUMN.generatedDate,
  COLUMN.projectedSavings,
  COLUMN.state,
  COLUMN.bulkActionStatus,
  COLUMN.bulkActionItemStatus,
];

const columnListWithoutUuid = [
  COLUMN.customerNameAbbreviation,
  COLUMN.clientNameAbbreviation,
  COLUMN.switchNumber,
  COLUMN.generatedDate,
  COLUMN.projectedSavings,
  COLUMN.state,
  COLUMN.bulkActionStatus,
];
const BulkActionList = (props) => {
  const dispatch = useDispatch();
  const {
    selectedCustomerNameAbbreivation,
    selectedClientNameAbbreviation,
    selected,
    setSelectedSwitches,
    actionType,
    bulkActionUuid,
    foundClientPause,
    isViewable,
  } = props;
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [resetTableSelection, setTableSelection] = useState(false);
  const [toggleAllSwitches, setToggleAllSwitches] = useState(false);
  const [isFetchedOnce, setIsFetchedOnce] = useState(false);
  const [disableTable, setDisableTable] = useState(false);

  const data = useSelector((state) =>
    state.bulkActionList.data !== undefined ? state.bulkActionList.data : []
  );

  const pages = useSelector((state) =>
    state.bulkActionList.pages !== undefined ? state.bulkActionList.pages : 0
  );

  const totalElements = useSelector((state) =>
    state.bulkActionList.totalElements !== undefined
      ? state.bulkActionList.totalElements
      : "Loading ..."
  );

  const loading = useSelector((state) => state.bulkActionList.loading);

  const isError = useSelector((state) => state.bulkActionList.isError);

  const listState = useSelector((state) =>
    state.bulkActionList.listState !== undefined
      ? state.bulkActionList.listState
      : {
          page: 0,
          pageSize: DEFAULT_PAGE_SIZE,
          sorted: DEFAULT_BULK_ACTION_LIST_SORT || [],
          filtered: bulkActionUuid
            ? []
            : actionType.value === "Client Pause"
            ? DEFAULT_BULK_ACTION_CLIENT_PAUSE_FILTER || []
            : DEFAULT_BULK_ACTION_FILTER || [],
          resized: [],
          expanded: {},
        }
  );

  const getSwitchesForBulkAction = (bulkActionUuid, checked) => {
    const { filtered, sorted } = listState;
    if (checked) {
      sendAuthenticatedAsyncRequest(
        `/bulk-action/${bulkActionUuid}/select-all-switches`, // Updated endpoint
        "POST",
        { filtered, sorted },
        (response) => {
          setSelectedSwitches(response.data);
          setToggleAllSwitches(checked);
          selectAllRecords(response.data, checked); // Mark all as selected.
        },
        (error) => {
          console.error("Error fetching switches:", error);
          setSelectedSwitches([]);
          setToggleAllSwitches(false);
          selectAllRecords([], false);
        }
      );
    } else {
      setSelectedSwitches([]);
      setToggleAllSwitches(false);
      selectAllRecords([], false);
    }
  };

  const toggleAllEditMode = (e) => {
    const { checked } = e.target;
    if (checked) {
      getSwitchesForBulkAction(bulkActionUuid, true);
    } else {
      getSwitchesForBulkAction(bulkActionUuid, false);
    }
  };

  useEffect(() => {
    if (bulkActionUuid !== undefined) {
      getSwitchesForBulkAction(bulkActionUuid, true);
    }
  }, [bulkActionUuid]);

  const getSwitchesForSelectAll = (e) => {
    const { checked } = e.target;
    const { filtered, sorted } = listState;
    if (!bulkActionUuid && checked) {
      let filteredFinal = [...filtered];
      if (selectedCustomerNameAbbreivation.length > 0) {
        filteredFinal.push({
          id: "customerNameAbbreviation",
          value: selectedCustomerNameAbbreivation,
          isdefault: true,
        });
      }
      if (selectedClientNameAbbreviation.length > 0) {
        filteredFinal.push({
          id: "clientNameAbbreviation",
          value: selectedClientNameAbbreviation,
          isdefault: true,
        });
      }
      sendRequestForSelectAll(filteredFinal, sorted, checked);
    } else {
      setSelectedSwitches([]);
      setToggleAllSwitches(checked);
      selectAllRecords([], checked);
    }
  };

  const sendRequestForSelectAll = (filtered, sorted, checked) => {
    sendAuthenticatedAsyncRequest(
      `/swim/get-switches-for-select-all`, // review this api call
      "POST",
      { filtered, sorted },
      (r) => {
        setSelectedSwitches(r.data);
        setToggleAllSwitches(checked);
        selectAllRecords(r.data, checked);
      },
      (e) => {
        setSelectedSwitches([]);
        setToggleAllSwitches(checked);
        selectAllRecords([], checked);
      }
    );
  };

  const toggleAll = () => {
    if (selected.length > 0) {
      let selectedSwictches = [...selected];
      let isAllSelected = selectAll();
      data.forEach((item) => {
        const { wfItemId } = item;
        const index = selectedSwictches.indexOf(wfItemId);
        if (isAllSelected) {
          selectedSwictches.splice(index, 1);
        } else if (index < 0) {
          selectedSwictches.push(wfItemId);
        }
      });
      setSelectedSwitches(selected);
    }
  };
  useEffect(() => {
    if (
      actionType.value === "Client Pause" &&
      (selectedClientNameAbbreviation.length > 0 ||
        selectedCustomerNameAbbreivation.length > 0) &&
      data !== undefined
    ) {
      const { sorted } = listState;
      setDisableTable(true);
      let filtered = [...DEFAULT_BULK_ACTION_CLIENT_PAUSE_FILTER];
      if (selectedClientNameAbbreviation.length > 0) {
        filtered.push({
          id: "clientNameAbbreviation",
          value: selectedClientNameAbbreviation,
          isdefault: true,
        });
      }
      if (selectedCustomerNameAbbreivation.length > 0) {
        filtered.push({
          id: "customerNameAbbreviation",
          value: selectedCustomerNameAbbreivation,
          isdefault: true,
        });
      }
      sendRequestForSelectAll(filtered, sorted, true);
    }
  }, [actionType]);

  const selectAll = () => {
    let isAllSelected = true;
    data.forEach((x) => {
      if (selected.indexOf(x.wfItemId) < 0) {
        isAllSelected = false;
        return;
      }
    });
    return isAllSelected;
  };

  const selectAllRecords = (wfItemIds, checked) => {
    toggleAll();
    setSelectedSwitches(wfItemIds);
    setIsSelectAll(checked);
  };
  const updateData = (state, preventUpdate) => {
    if (!preventUpdate) {
      state.filtered = state.filtered.filter(
        (element) => element.value?.length > 0
      );
      console.log("state.filtered", state.filtered);
      if (state.sorted !== DEFAULT_BULK_ACTION_LIST_SORT) {
        state.sorted.forEach((element, i) => {
          element.isDefault = false;
        });
      }
      const clientFilterIndex = state.filtered.findIndex(
        (element) => element.id === "clientNameAbbreviation"
      );
      const customerFilterIndex = state.filtered.findIndex(
        (element) => element.id === "customerNameAbbreviation"
      );
      const bulkActionItemStatus = state.filtered.findIndex(
        (element) => element.id === "bulkActionItemStatus"
      );
      const bulkActionStatus = state.filtered.findIndex(
        (element) => element.id === "status"
      );
      if (bulkActionStatus !== -1) {
        state.filtered[bulkActionStatus].value = state.filtered[
          bulkActionStatus
        ].value.map((value) => (value.name ? value.name : value));
      }
      if (bulkActionItemStatus !== -1) {
        state.filtered[bulkActionItemStatus].value = state.filtered[
          bulkActionItemStatus
        ].value.map((value) => (value.name ? value.name : value));
      }

      if (
        clientFilterIndex === -1 &&
        selectedClientNameAbbreviation.length > 0
      ) {
        state.filtered.push({
          id: "clientNameAbbreviation",
          value: selectedClientNameAbbreviation,
          isdefault: true,
        });
      }
      if (
        customerFilterIndex === -1 &&
        selectedCustomerNameAbbreivation &&
        selectedCustomerNameAbbreivation.length > 0
      ) {
        state.filtered.push({
          id: "customerNameAbbreviation",
          value: selectedCustomerNameAbbreivation,
          isdefault: true,
        });
      }
      if (bulkActionUuid && !isFetchedOnce) {
        state.filtered = [];
        setIsFetchedOnce(true);
      }
      dispatch(
        updateInterventionList(
          state.pageSize,
          state.page,
          state.sorted,
          state.filtered,
          state.expanded,
          state.resized,
          bulkActionUuid
        )
      );
    } else {
      dispatch(updateBulkActionListState(state));
    }
  };

  useEffect(() => {
    if (!bulkActionUuid) {
      let defualtListState = {};
      if (actionType.value === "Client Pause") {
        defualtListState = {
          page: 0,
          pageSize: DEFAULT_PAGE_SIZE,
          sorted: DEFAULT_BULK_ACTION_LIST_SORT,
          filtered: DEFAULT_BULK_ACTION_CLIENT_PAUSE_FILTER,
          resized: [],
          expanded: {},
        };
      } else {
        defualtListState = {
          page: 0,
          pageSize: DEFAULT_PAGE_SIZE,
          sorted: DEFAULT_BULK_ACTION_LIST_SORT,
          filtered:
            actionType.value === "Hold Resume"
              ? DEFAULT_BULK_ACTION_HOLD_FILTER
              : DEFAULT_BULK_ACTION_FILTER,
          resized: [],
          expanded: {},
        };
      }
      dispatch(updateBulkActionListState(defualtListState));
    } else {
      const defualtListState = {
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        sorted: DEFAULT_BULK_ACTION_LIST_SORT,
        filtered: [],
        resized: [],
        expanded: {},
      };
      dispatch(updateBulkActionListState(defualtListState));
    }
  }, [actionType, bulkActionUuid]);

  const toggleSelection = (selected) => {
    selected = Object.keys(selected).map((row, index) => {
      return row;
    });
    setSelectedSwitches(selected);
  };
  return (
    !disableTable && (
      <Col md={{ span: 13 }} style={{ paddingTop: "10px" }}>
        <div style={{ paddingBottom: "10px" }}>
          <Card variant="primary">
            <Card.Body>
              <Row>
                <Col>
                  <Form.Check
                    type="switch"
                    id="selectAll"
                    label="Select All Switches"
                    onChange={
                      bulkActionUuid
                        ? toggleAllEditMode
                        : getSwitchesForSelectAll
                    }
                    //value={isSelectAll}
                    style={{ display: "inline", paddingRight: "5px" }}
                    checked={toggleAllSwitches}
                    disabled={isViewable}
                  />
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        This will select all the switches from all pages
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <MdHelp
                        style={{ color: "#FFAA3B" }}
                        ref={ref}
                        {...triggerHandler}
                      >
                        The help
                      </MdHelp>
                    )}
                  </OverlayTrigger>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <SwitchListCommon
            updateInterventionList={updateInterventionList}
            updateIndexOnList={updateIndexOnList}
            resetError={resetError}
            data={data}
            pages={pages}
            totalElements={totalElements}
            loading={loading}
            listState={listState}
            sorted={listState.sorted}
            filters={listState.filters}
            isError={isError}
            updateData={updateData}
            columnList={
              bulkActionUuid !== undefined
                ? columnListWithUuid
                : columnListWithoutUuid
            }
            selectedClient={selectedClientNameAbbreviation}
            isSortFilterEnabledForCust={false}
            toggleSelection={toggleSelection}
            isSelectAll={isSelectAll}
            selected={selected}
            resetTableSelection={resetTableSelection}
            isViewable={isViewable}
          />
        </div>
      </Col>
    )
  );
};
export default withRouter(BulkActionList);
