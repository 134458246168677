import { RSAA } from 'redux-api-middleware';
import config from 'config';
import {AUTH_TOKEN_HEADER_KEY, getAuthorizationToken} from 'services/AuthService';

export const dispatchEvent = (event, wfItemId, notes) => {
  let endpoint = `${config.apiRoot}/wf/${wfItemId}`;
  if(event === 'SEND_PRESCRIBER_FAX' || event === 'SEND_PHARMACY_FAX') {
      endpoint += `/fax-resend`;
  }
  return {
    [RSAA]: {
      endpoint: `${endpoint}?event=${event}`,
      method: 'POST',
      headers: {'Content-Type':'application/json',[AUTH_TOKEN_HEADER_KEY] : getAuthorizationToken()},
      body: JSON.stringify({note: notes}),
      types: [
        {
          type: 'PERFORM_EVENT_REQUEST',
          payload: {event: event}
        },
        {
          type: 'PERFORM_EVENT_SUCCESS',
          payload: {event: event}
        },
        {
          type: 'PERFORM_EVENT_FAILURE',
          payload: {event: event}
        }
      ]
    }
  };
};

export const resetEventExecutorStatus = () => {
  return {
    type: "EVENT_EXECUTOR_STATUS_RESET"
  }
}