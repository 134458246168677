import { useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { compareDates } from "services/MomentUtils";
import NoteItem from "./ScrollableNotes/NoteItem";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { showNotification } from "services/NotificationService";

const CollapsedNotes = (props) => {
  const expiredSwitchListObj = props.expiredSwitchList;
  const entitySaveUrl = props.entitySaveUrl;
  const expiredSwitchList = expiredSwitchListObj ? expiredSwitchListObj.predecessorNotes : []
  const [currentActivityNotesList, setCurrentActivityNotesList] = useState([])
  const [notesLoading, setNotesLoading] = useState(false);

  const getCurrentActivityNotes = (wfItemId) => {
    // this will send api call to retrieve notes
    setNotesLoading(true);
    sendAuthenticatedAsyncRequest(
      `${entitySaveUrl}/${wfItemId}`,
      "POST",
      {},
      (r) => {
        setCurrentActivityNotesList(r.data.noteDtoList);
        setNotesLoading(false);
      },
      (r) => {
        showNotification({
          title: "Unable to load Activity Notes!",
          message: "SOMETHING WENT WRONG",
          position: "tr",
          type: "error",
        });
      }
    )
  }

  // currentActivityNotesList.sort((n1, n2) =>
  //       compareDates(n2.creationDate, n1.creationDate)
  //     );

  return (
    <>
      {expiredSwitchList.length > 0 ? (
        <Accordion flush>
          {expiredSwitchList.map((switchInfo, idx) => {
            if (idx < expiredSwitchList.length) {
              return (
                <Accordion.Item eventKey={switchInfo.predecessorSwitchInfoId} key={switchInfo.predecessorSwitchInfoId}>
                  <Accordion.Header style={{lineHeight: "normal", fontWeight: "normal"}}>
                    {"SW " + switchInfo.predecessorSwitchInfoId}
                  </Accordion.Header>
                  <Accordion.Body
                    style={{ maxHeight: "350px", overflowY: "auto", paddingLeft: "5px" }}
                    onExited={()=>setNotesLoading(false)}
                    onEntering={()=>getCurrentActivityNotes(switchInfo.predecessorWfItemUuid)}
                  >{
                    notesLoading ?
                    <div className="rm-horizontal-center">
                      <Spinner />
                    </div>
                     :
                    currentActivityNotesList.length > 0 ? (
                      currentActivityNotesList.map((note, pos) => {
                        if (pos < currentActivityNotesList.length) {
                          return (
                            <NoteItem
                              key={note.noteId}
                              note={note}
                              clipped={false}
                            />
                          );
                        }
                      })
                    ) : (
                      <div className="rm-horizontal-center text-muted tiny-top-spacer">
                        No notes found
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
          })}
        </Accordion>
      ) : (
        <div className="rm-horizontal-center text-muted tiny-top-spacer">No predecessors found</div>
      )}
    </>
  );
};

export default CollapsedNotes;
