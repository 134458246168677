import CheckField from "commons/fields/CheckField";
import { Formik } from "formik";
import CAT_CONSTANTS from "lookup/CatConstants";
import { Component } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { withRouter } from "react-router-dom";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { showNotification } from "services/NotificationService";
import { areArraysDifferent, getErrorMessage } from "services/UtilityService";
import { AgeFields } from "views/Cat/NewDrug/AgeFields";
import * as Yup from "yup";
import {
  BooleanDeltaField,
  getDeltaBoxClassName,
} from "../../../commons/fields/DeltaFieldUtils";
import DescriptionField from "../../../commons/fields/DescriptionField";
import DynamicSwitchOptionDropDownField from "../../../commons/fields/DynamicSwitchOptionItemTypeField";
import ErrorCodes from "../../../lookup/ErrorCodes";
import DrugOptionBoxHeader from "./DrugOptionBoxHeader";
import DrugOptionDaysSupply from "./DrugOptionDaysSupply";
import DrugOptionPharmacistNotes from "./DrugOptionPharmacistNotes";
import DrugOptionStrengths from "./DrugOptionStrengths";

export const DRUG_OPTION_SCHEMA = Yup.object().shape({
  faxDisplayName: Yup.string()
    .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255)
    .trim()
    .min(4, CAT_CONSTANTS.fieldMinLengthValidationPrefix + 4),
  instructions: Yup.string()
    .max(500, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 500)
    .trim()
    .min(15, CAT_CONSTANTS.fieldMinLengthValidationPrefix + 15),
  pharmacistNotes: Yup.string().max(
    500,
    CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 500
  ),
  priority: Yup.number()
    .moreThan(-1, CAT_CONSTANTS.negativeFieldValueError)
    .max(
      2147483647,
      CAT_CONSTANTS.maxIntegerValueValidationPrefix + 2147483647
    ),
  isActive: Yup.boolean(),
  daysSupplyFiltered: Yup.array(
    Yup.number()
      .max(365, CAT_CONSTANTS.maxIntegerValueValidationPrefix + 365)
      .min(15, CAT_CONSTANTS.minIntegerValueValidationPrefix + 15)
  ).max(5, "You cannot add more than 5 supplies!"),
});

const DELTA_FIELDS = {
  faxDisplayName: "faxDisplayName",
  instructions: "instructions",
  itemType: "itemType",
  priority: "priority",
  isPharmacistReviewRequired: "isPharmacistReviewRequired",
  pharmacistNotes: "pharmacistNotes",
  daysSupply: "daysSupply",
  isActive: "isActive",
  ageLmtReqd: "ageLmtReqd",
  minAge: "minAge",
  maxAge: "maxAge",
};
class DrugOptionBoxCompact extends Component {
  state = {
    isEditMode: false,
    showDetails: false,
    showMultiTargets:
      false ||
      (this.props.showDelta &&
        this.props.otherDynamicSwitchOptions?.length > 0),
    ageError: null,
  };

  toggleShowDetails = () => {
    const { showDetails } = this.state;
    this.setState({ showDetails: !showDetails });
  };

  handleEnableEditMode = () => {
    this.setState({ isEditMode: true, showDetails: true });
  };

  handleCancelEditMode = () => {
    this.setState({
      isEditMode: false,
      showDetails: false,
    });
  };

  handleSuccess = (r, setSubmitting) => {
    setSubmitting(false);
    this.props.refreshList();
  };

  noChangeMessage = (setSubmitting) => {
    setSubmitting(false);
    // dispatch warning notification
    showNotification({
      title: "No Change Detected!",
      message: `You are trying to save without making any update.`,
      position: "tr",
      autoDismiss: 5,
      type: "warning",
    });
    this.setState({
      isEditMode: false,
      showDetails: false,
    });
  };

  getErrorWithLink = (errorData) => {
    if (
      errorData?.additionalErrorInfo != null ||
      errorData.errorCode ==
        ErrorCodes.DYNAMIC_SWITCH_OPTION_ACTIVE_IN_ANOTHER_GROUP
    ) {
      let link = (
        <a
          href={`/cat/dynamic-switch-groups/${errorData.additionalErrorInfo[4]}`}
        >
          {errorData.additionalErrorInfo[3]}
        </a>
      );
      let errorMessage = ErrorCodes[errorData.errorCode];
      for (let additionalInfo in errorData.additionalErrorInfo) {
        errorMessage = errorMessage.replace(
          "%s",
          errorData.additionalErrorInfo[additionalInfo]
        );
      }
      let index = errorMessage.indexOf("%l");
      errorMessage = errorMessage.replace("%l", "");
      let startMessage = errorMessage.substring(0, index);
      let endMessage = errorMessage.substring(index);
      errorMessage = (
        <div>
          {startMessage} {link} {endMessage}
        </div>
      );
      return errorMessage;
    } else {
      return null;
    }
  };

  handleError = (r, setSubmitting) => {
    setSubmitting(false);
    // dispatch failure notification
    showNotification({
      title: "Something went wrong!",
      message:
        this.getErrorWithLink(r.data) ||
        getErrorMessage(r.data) ||
        getErrorMessage("UNABLE_TO_UPDATE_DYNAMIC_SWITCH_OPTION"),
      position: "tr",
      type: "error",
    });
  };

  setFieldsForDrug = (dso) => {
    if (dso.itemType == "target") {
      dso.instructions = dso.instructions.trim()
        ? dso.instructions
        : "Use as Directed";
      dso.faxDisplayName =
        dso.faxDisplayName?.trim() != "" && dso.faxDisplayName != "N/A"
          ? dso.faxDisplayName
          : dso.medName;
      dso.daysSupply = dso.daysSupply ? dso.daysSupply : "30;60;90";
      dso.daysSupplyList =
        dso.daysSupplyList?.length > 0 ? dso.daysSupplyList : [30, 60, 90];
    }
  };

  handleSaveEditMode = (updatedDynamicSwitchOption, setSubmitting) => {
    const { workingCodexVersionId, dynamicSwitchGroupId } = this.props;
    let updatedItemType = updatedDynamicSwitchOption.updatedItemType;
    delete updatedDynamicSwitchOption.updatedItemType;
    if (updatedItemType == "both") {
      let dynamicSwitchOption = { ...updatedDynamicSwitchOption };
      updatedDynamicSwitchOption.itemType =
        updatedDynamicSwitchOption.itemType == "switch" ? "target" : "switch";
      this.setFieldsForDrug(updatedDynamicSwitchOption);
      sendAuthenticatedAsyncRequest(
        `/cat/codex-version/${workingCodexVersionId}/dynamic-switch-groups/${dynamicSwitchGroupId}/dynamic-switch-options`,
        "POST",
        [updatedDynamicSwitchOption],
        (r) => {
          this.setFieldsForDrug(dynamicSwitchOption);
          sendAuthenticatedAsyncRequest(
            `/cat/codex-version/${workingCodexVersionId}/dynamic-switch-groups/${dynamicSwitchGroupId}/dynamic-switch-options/${updatedDynamicSwitchOption.dynamicSwitchOptionId}`,
            "PUT",
            dynamicSwitchOption,
            (r) => {
              this.handleSuccess(r, setSubmitting);
            },
            (r) => {
              this.handleError(r, setSubmitting);
            }
          );
        },
        (r) => {
          this.handleError(r, setSubmitting);
        }
      );
    } else {
      updatedDynamicSwitchOption.itemType = updatedItemType;
      this.setFieldsForDrug(updatedDynamicSwitchOption);
      sendAuthenticatedAsyncRequest(
        `/cat/codex-version/${workingCodexVersionId}/dynamic-switch-groups/${dynamicSwitchGroupId}/dynamic-switch-options/${updatedDynamicSwitchOption.dynamicSwitchOptionId}`,
        "PUT",
        updatedDynamicSwitchOption,
        (r) => {
          this.handleSuccess(r, setSubmitting);
        },
        (r) => {
          this.handleError(r, setSubmitting);
        }
      );
    }
  };

  toggleMultiTargets = () => {
    let { showMultiTargets } = this.state;
    this.setState({
      showMultiTargets: !showMultiTargets,
    });
  };

  getUpdatedDsoFields = (newDso, oldDso, isDelta = true) => {
    let updated = Object.keys(DELTA_FIELDS).filter((field) => {
      if (field == DELTA_FIELDS.priority) {
        let oldResolvedP = this.resolveOptionPriority(oldDso[field]);
        let newResolvedP = this.resolveOptionPriority(newDso[field]);
        return newResolvedP != oldResolvedP && (newResolvedP || oldDso[field]);
      } else if (field == DELTA_FIELDS.daysSupply && !isDelta) {
        return areArraysDifferent(
          newDso.daysSupplyFiltered,
          oldDso.daysSupplyList
        );
      } else {
        return (
          newDso[field] != oldDso[field] && (newDso[field] || oldDso[field])
        );
      }
    });
    return updated;
  };

  getIsChanged = (newDso, oldDso) => {
    if (
      oldDso &&
      oldDso.dynamicSwitchOptionVersion != newDso.dynamicSwitchOptionVersion &&
      !newDso.isDeleted
    ) {
      let updated = this.getUpdatedDsoFields(newDso, oldDso, true);
      if (updated.length == 0) {
        // Check for DSI
        updated = newDso.dynamicSwitchItems.filter((dsi) => {
          let oldDsi = oldDso.dynamicSwitchItems.find(
            (x) => x.gpiCode == dsi.gpiCode
          );
          if (!oldDsi) return dsi.isNotMainTargetStrength ? false : true;
          return (
            dsi.isDeleted != oldDsi.isDeleted || dsi.isActive != oldDsi.isActive
          );
        });
      }
      let isActiveUpdated = updated.findIndex((x) => x == "isActive");
      if (isActiveUpdated > -1) {
        if (
          newDso.isPharmacistReviewRequired &&
          oldDso.isPharmacistReviewRequired
        ) {
          updated.splice(isActiveUpdated, 1);
        }
      }
      return updated.length > 0;
    }
    return false;
  };

  getClassName = () => {
    const { showDelta, dynamicSwitchOption, oldDynamicSwitchOption } =
      this.props;
    const isChanged = this.getIsChanged(
      dynamicSwitchOption,
      oldDynamicSwitchOption
    );
    const isOldDrugPresent = oldDynamicSwitchOption ? true : false;
    const isNewDrugDeleted =
      dynamicSwitchOption && dynamicSwitchOption.isDeleted ? true : false;
    return getDeltaBoxClassName(
      showDelta,
      isChanged,
      isOldDrugPresent,
      isNewDrugDeleted
    );
  };

  getIsUpdatedDelta = () => {
    const { oldDynamicSwitchOption, dynamicSwitchOption } = this.props;
    //  if empty or value is returned, it will be displayed in color code. if null is returned, color code won't be applied
    return oldDynamicSwitchOption && !dynamicSwitchOption.isDeleted
      ? true
      : false;
  };

  getDeltaPriority = () => {
    const { oldDynamicSwitchOption } = this.props;
    const priority =
      oldDynamicSwitchOption?.priority &&
      oldDynamicSwitchOption.priority !== 999
        ? oldDynamicSwitchOption.priority
        : "-";
    return priority;
  };

  resolveOptionPriority = (priority) => {
    return priority != null && priority !== 999 ? priority : "";
  };

  isPriorityNotNullOrZero = (priority) => {
    return priority || priority === 0;
  };

  setAgeError = (error) => {
    this.setState({ ageError: error });
  };

  render() {
    const { isEditMode, showDetails, showMultiTargets } = this.state;
    const {
      workingCodexVersionId,
      dynamicSwitchGroupId,
      otherDynamicSwitchOptions,
      oldDynamicSwitchOption,
      showDelta,
      dynamicSwitchOption,
      allowEditing,
      hideInactive,
    } = this.props;
    const {
      dynamicSwitchOptionId,
      medName,
      medForm,
      gpiCode,
      brandGenType,
      routeOfAdministration,
      itemType,
      estimatedCost,
      dynamicSwitchItems,
      faxDisplayName,
      instructions,
      isPharmacistReviewRequired,
      pharmacistNotes,
      isActive,
      alternativeDrugNames,
      isDrugTypeBoth,
      ageLmtReqd,
      minAge,
      maxAge,
    } = dynamicSwitchOption;
    let deltaClassName = this.getClassName();
    let boxClassName = deltaClassName ? deltaClassName + "-card-border" : "";
    const isDeltaUpdated = this.getIsUpdatedDelta();
    const otherDsoList =
      otherDynamicSwitchOptions?.length > 0
        ? otherDynamicSwitchOptions.sort((a, b) => {
            return a.medName == b.medName
              ? 1
              : a.medName.localeCompare(b.medName);
          })
        : null;
    const priority = this.resolveOptionPriority(dynamicSwitchOption.priority);

    const submitDynamicSwitchOption = (values, setSubmitting) => {
      if (this.state.ageError) {
        setSubmitting(false);
        return;
      }
      let isDsoUpdated =
        this.getUpdatedDsoFields(values, dynamicSwitchOption, false).length > 0;
      dynamicSwitchOption.faxDisplayName = values.faxDisplayName;
      dynamicSwitchOption.instructions = values.instructions;
      dynamicSwitchOption.isActive = values.isActive;
      dynamicSwitchOption.isPharmacistReviewRequired =
        values.isPharmacistReviewRequired;
      dynamicSwitchOption.pharmacistNotes = values.pharmacistNotes;
      dynamicSwitchOption.priority = this.isPriorityNotNullOrZero(
        values.priority
      )
        ? values.priority
        : 999; // sending 999, which is the default value
      dynamicSwitchOption.daysSupplyList = values.daysSupplyFiltered;
      dynamicSwitchOption.ageLmtReqd = values.ageLmtReqd;
      dynamicSwitchOption.minAge = values.ageLmtReqd ? values.minAge : null;
      dynamicSwitchOption.maxAge = values.ageLmtReqd ? values.maxAge : null;
      dynamicSwitchOption.updatedItemType = values.itemType;

      let dsi = [];
      let offMarketStrengthsSelected = false;
      for (let dynamicSwitchItem of dynamicSwitchOption.dynamicSwitchItems) {
        if (!dynamicSwitchItem.isNotMainTargetStrength) {
          let updatedDsi =
            values.dynamicSwitchItems[dynamicSwitchItem.dynamicSwitchItemId];
          updatedDsi = updatedDsi || dynamicSwitchItem;
          if (
            updatedDsi.isActive != dynamicSwitchItem.isActive ||
            updatedDsi.instructions != dynamicSwitchItem.instructions
          ) {
            isDsoUpdated = true;
          }
          if (
            updatedDsi.isActive === true &&
            dynamicSwitchItem.isNdcPresent === false
          ) {
            offMarketStrengthsSelected = true;
          }
          dynamicSwitchItem.isActive = updatedDsi.isActive;
          dynamicSwitchItem.instructions = updatedDsi.instructions;
          dsi.push(dynamicSwitchItem);
        }
        dynamicSwitchOption.dynamicSwitchItems = dsi;
      }
      if (offMarketStrengthsSelected && isDsoUpdated) {
        showNotification({
          title: "Warning!",
          message: "Your selected strengths include off-market ones as well.",
          position: "tr",
          type: "warning",
        });
      }
      if (!isDsoUpdated) {
        this.noChangeMessage(setSubmitting);
        return;
      }
      this.handleSaveEditMode(dynamicSwitchOption, setSubmitting);
    };

    const ageValues = { ageLmtReqd, minAge, maxAge };
    const prevAgeValues = {
      ageLmtReqd: oldDynamicSwitchOption?.ageLmtReqd,
      minAge: oldDynamicSwitchOption?.minAge,
      maxAge: oldDynamicSwitchOption?.maxAge,
    };

    return (
      <div className={showMultiTargets ? "multiple-targets" : ""}>
        <Formik
          key={dynamicSwitchOptionId}
          initialValues={{
            dynamicSwitchOptionId: dynamicSwitchOptionId || "",
            faxDisplayName: faxDisplayName || "",
            instructions: instructions || "",
            pharmacistNotes: pharmacistNotes || "",
            isActive: isActive || false,
            isPharmacistReviewRequired: isPharmacistReviewRequired || false,
            priority: this.isPriorityNotNullOrZero(priority) ? priority : "",
            daysSupplyFiltered: this.props.dynamicSwitchOption.daysSupplyList,
            ageLmtReqd: ageLmtReqd,
            minAge: minAge,
            maxAge: maxAge,
            dynamicSwitchItems: dynamicSwitchItems.reduce(
              (acc, cur) => ({
                ...acc,
                [cur.dynamicSwitchItemId]: {
                  isActive: cur.isActive,
                  instructions: cur.instructions,
                },
              }),
              {}
            ),
            itemType: itemType || "",
          }}
          validationSchema={DRUG_OPTION_SCHEMA}
          onSubmit={(values, { setSubmitting }) => {
            submitDynamicSwitchOption(values, setSubmitting);
          }}
          onReset={() => {
            this.handleCancelEditMode();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <Row>
                <Col md={12}>
                  <Card className={"drug-option-box " + boxClassName}>
                    <Card.Body
                      className={
                        !showDetails ? "card-body-padding-override" : ""
                      }
                    >
                      <DrugOptionBoxHeader
                        medName={medName}
                        medForm={medForm}
                        showDetails={showDetails}
                        showDelta={showDelta}
                        deltaClassName={deltaClassName}
                        isEditMode={isEditMode}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isActive={values.isActive}
                        dynamicSwitchOptionId={dynamicSwitchOptionId}
                        alternativeDrugNames={alternativeDrugNames}
                        brandGenType={brandGenType}
                        itemType={itemType}
                        routeOfAdministration={routeOfAdministration}
                        estimatedCost={estimatedCost}
                        gpiCode={gpiCode}
                        isPharmacistReviewRequired={isPharmacistReviewRequired}
                        toggleShowDetails={this.toggleShowDetails}
                        allowEditing={allowEditing}
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        handleEnableEditMode={this.handleEnableEditMode}
                        refreshList={this.props.refreshList}
                        dynamicSwitchGroupId={dynamicSwitchGroupId}
                        workingCodexVersionId={workingCodexVersionId}
                        isMultiTargets={otherDsoList?.length > 0}
                        toggleMultiTargets={this.toggleMultiTargets}
                        showMultiTargets={showMultiTargets}
                        dynamicSwitchItems={dynamicSwitchItems}
                      />
                      <Collapse in={showDetails}>
                        <div className="small-top-spacer">
                          <Row>
                            <Col md={4}>
                              <DescriptionField
                                isEditMode={isEditMode}
                                name={"Display Name"}
                                id={"faxDisplayName"}
                                error={
                                  errors.faxDisplayName &&
                                  touched.faxDisplayName &&
                                  errors.faxDisplayName
                                }
                                savedValue={faxDisplayName}
                                updatedValue={values.faxDisplayName}
                                predecessorValue={
                                  oldDynamicSwitchOption?.faxDisplayName
                                }
                                isUpdated={isDeltaUpdated}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                compKey={"drug-option-form-fax-display-name"}
                                key={`drug-option-display-name-${dynamicSwitchOptionId}`}
                              />
                            </Col>
                            <Col md={8}>
                              <DescriptionField
                                isEditMode={isEditMode}
                                name={"Instructions"}
                                id={"instructions"}
                                error={
                                  errors.instructions &&
                                  touched.instructions &&
                                  errors.instructions
                                }
                                savedValue={instructions}
                                updatedValue={values.instructions}
                                predecessorValue={
                                  oldDynamicSwitchOption?.instructions
                                }
                                isUpdated={isDeltaUpdated}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                compKey={"drug-option-form-fax-display-name"}
                                key={`drug-option-instructions-${dynamicSwitchOptionId}`}
                                placeholder={"Use as Directed"}
                              />
                            </Col>
                            <Col md={8}>
                              {oldDynamicSwitchOption &&
                              oldDynamicSwitchOption?.isActive !== isActive ? (
                                <BooleanDeltaField
                                  oldField={oldDynamicSwitchOption?.isActive}
                                  isUpdated={isDeltaUpdated}
                                  newField={
                                    dynamicSwitchOption.isPharmacistReviewRequired
                                      ? oldDynamicSwitchOption?.isActive
                                      : isActive
                                  }
                                  heading={"Active"}
                                />
                              ) : null}
                            </Col>
                            <Col md={8} className="dso-pharmacist-review">
                              <CheckField
                                key={`pharmacists-${dynamicSwitchOptionId}`}
                                compKey={
                                  "drug-option-form-is-pharmacist-review-required"
                                }
                                id={"isPharmacistReviewRequired"}
                                isEditMode={isEditMode}
                                label={"Review Required"}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                value={values.isPharmacistReviewRequired}
                                oldValue={
                                  oldDynamicSwitchOption?.isPharmacistReviewRequired
                                }
                                isDeltaUpdated={isDeltaUpdated}
                                deltaHeading={"Review Required"}
                                showDelta={
                                  showDelta ||
                                  !values.isPharmacistReviewRequired
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="small-top-spacer">
                            <Col md={6}>
                              <DynamicSwitchOptionDropDownField
                                dynamicSwitchOptionId={dynamicSwitchOptionId}
                                key={`itemType${dynamicSwitchOptionId}`}
                                isEditMode={isEditMode}
                                savedValue={itemType}
                                updatedValue={values.itemType}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                isDrugTypeBoth={isDrugTypeBoth}
                                predecessorValue={
                                  oldDynamicSwitchOption?.itemType
                                }
                                isUpdated={isDeltaUpdated}
                              />
                            </Col>
                          </Row>
                          <Row className="small-top-spacer">
                            <DrugOptionPharmacistNotes
                              isEditMode={isEditMode}
                              value={pharmacistNotes}
                              error={
                                errors.pharmacistNotes &&
                                touched.pharmacistNotes
                              }
                              updatedValue={values.pharmacistNotes}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              dynamicSwitchOptionId={dynamicSwitchOptionId}
                              predecessorValue={
                                oldDynamicSwitchOption?.pharmacistNotes
                              }
                              isDeltaUpdated={isDeltaUpdated}
                            />
                          </Row>
                          {itemType === "target" ? (
                            <Row className="small-top-spacer">
                              <Col md={4}>
                                <DescriptionField
                                  isEditMode={isEditMode}
                                  name={"Priority"}
                                  id={"priority"}
                                  error={
                                    errors.priority &&
                                    touched.priority &&
                                    errors.priority
                                  }
                                  savedValue={
                                    this.isPriorityNotNullOrZero(priority)
                                      ? priority
                                      : "-"
                                  }
                                  updatedValue={values.priority}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  compKey={"drug-option-form-priority"}
                                  controlProps={{
                                    type: "number",
                                  }}
                                  key={`drug-option-priority-${dynamicSwitchOptionId}`}
                                  predecessorValue={this.getDeltaPriority()}
                                  isUpdated={isDeltaUpdated}
                                />
                              </Col>
                            </Row>
                          ) : null}
                          {itemType === "target" ? (
                            <AgeFields
                              handleChange={handleChange}
                              values={values}
                              drugValue={ageValues}
                              handleBlur={handleBlur}
                              setAgeError={this.setAgeError}
                              ageError={this.state.ageError}
                              isEditMode={isEditMode}
                              isNewDrugScreen={false}
                              showDelta={showDelta}
                              isDeltaUpdated={isDeltaUpdated}
                              predecessorValue={prevAgeValues}
                            />
                          ) : null}
                          <Row className="small-top-spacer-important">
                            <DrugOptionStrengths
                              showDelta={showDelta}
                              isDeltaUpdated={isDeltaUpdated}
                              savedValue={
                                this.props.dynamicSwitchOption
                                  .dynamicSwitchItems
                              }
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              updatedValue={values.dynamicSwitchItems}
                              isEditMode={isEditMode}
                              predecessorValue={
                                this.props.oldDynamicSwitchOption
                                  ?.dynamicSwitchItems
                              }
                            />
                          </Row>
                          {dynamicSwitchOption.itemType === "target" ? (
                            <Row className="small-top-spacer">
                              <DrugOptionDaysSupply
                                isEditMode={isEditMode}
                                showDelta={showDelta}
                                value={values.daysSupplyFiltered}
                                predecessorValue={
                                  oldDynamicSwitchOption?.daysSupply
                                }
                                isUpdated={isDeltaUpdated}
                                error={errors.daysSupplyFiltered}
                                setFieldValue={setFieldValue}
                              />
                            </Row>
                          ) : null}
                        </div>
                      </Collapse>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        {otherDsoList?.length > 0 ? (
          <Collapse in={showMultiTargets}>
            <div className="collapsed-alternatives">
              {otherDsoList
                .filter(
                  (i) =>
                    (!hideInactive || i.currentDynamicSwitchOption.isActive) &&
                    (!showDelta ||
                      !i.currentDynamicSwitchOption.isDeleted ||
                      i.oldDynamicSwitchOption)
                )
                .map((dynamicSwitchOption, idx) => (
                  <DrugOptionBoxCompact
                    dynamicSwitchOption={
                      dynamicSwitchOption.currentDynamicSwitchOption
                    }
                    oldDynamicSwitchOption={
                      dynamicSwitchOption.oldDynamicSwitchOption
                    }
                    showDelta={showDelta}
                    allowEditing={allowEditing}
                    workingCodexVersionId={workingCodexVersionId}
                    dynamicSwitchGroupId={dynamicSwitchGroupId}
                    hideInactive={hideInactive}
                    id={`option-sub-${idx}`}
                    key={`option-sub-${idx}`}
                    refreshList={this.props.refreshList}
                  />
                ))}
            </div>
          </Collapse>
        ) : null}
      </div>
    );
  }
}

export default withRouter(DrugOptionBoxCompact);
