import {getLoggedInUser} from 'services/AuthService';

const onlyAWRoles = ["AW_ADMIN", "AW_CALL_ACTIVITY", "AW_TASK_ACTIVITY", "AW_MEM_CALLER"]; //modify this if new agent specific roles are added.

export const hasRoleIn = (roles) => {
  if (!roles || roles.length === 0) return true; 

  const user = getLoggedInUser();
  return user && user.authorities && user.authorities.some( userRole => roles.some(role => role === userRole));
}

export const hasOnlyAWRole = () => {
  const user = getLoggedInUser();
  return user && user.authorities && user.authorities.every(userRole => onlyAWRoles.includes(userRole));
}