import { createSlice } from '@reduxjs/toolkit';
import CAT_CONSTANTS from "lookup/CatConstants";

const initialState = {
  activeRadarDrugs: [],
  totalPages: 0,
  pageSize: 20,
  currentPage:0,
  error: false,
  loading: false,
  totalElements: "Loading",
  sorted:CAT_CONSTANTS.defaultActiveRadarSort,
  filtered:[],
  resized: [],
  expanded: {},
  lastDate: "Loading"
}


export const activeRadarSlice = createSlice({
  name: "activeRadar",
  initialState: initialState,
  reducers: {
    getActiveRadarListSuccess: (state, action) => {
      state.activeRadarDrugs = action.payload.records;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.page;
      state.pageSize=action.payload.pageSize;
      state.sorted = action.payload.sorted;
      state.filtered=action.payload.filtered;
      state.totalElements=action.payload.totalElements;
      state.error = false;
      state.loading = false;
      state.lastDate = action.payload.lastDate;
    },
    getActiveRadarListFailure: (state, action) => {
      state.loading = false;
      state.error = true;
      state.totalElements = "Failed";
      state.lastDate = "Failed";
    },
    getActiveRadarListLoading: (state, action) => {
      state.loading = true;
      state.totalElements = "Loading";
      state.lastDate = "Loading";
    }

  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "PAW_GROUP" RSAA action.
      .addCase('GET_ACTIVE_RADAR_DRUGS_REQUEST', (state, action) => {
        activeRadarSlice.caseReducers.getActiveRadarListLoading(state, action);
      })
      .addCase('GET_ACTIVE_RADAR_DRUGS_SUCCESS', (state, action) => {
        activeRadarSlice.caseReducers.getActiveRadarListSuccess(state, action);
      })
      .addCase('GET_ACTIVE_RADAR_DRUGS_FAILURE', (state, action) => {
        activeRadarSlice.caseReducers.getActiveRadarListFailure(state, action);
      })
  }
});


export const { getActiveRadarListSuccess, getActiveRadarListFailure } = activeRadarSlice.actions;
export const activeRadarState = (state) => state.activeRadar;
export default activeRadarSlice.reducer;