import React, {Component} from 'react';

import ButtonWithPopover from './ButtonWithPopover';

import Button from 'react-bootstrap/Button';

class RmPopoverButton extends Component {

  callbackFunction() {
    this.props.callbackFunction();
  }

  render() {
    return (
      <ButtonWithPopover
        placement= "top"
        buttonVariant={this.props.btnVariant || "info"}
        buttonText={this.props.btnText}
        buttonIsBlock={true}
        buttonIsDisabled={this.props.disallowEditing}
        buttonId={`${this.props.btnText}-popover`}
        buttonClassName={this.props.btnClassName}
        dataTestId={this.props.dataTestId}
        >
        <p className="confirmation-text">
          {this.props.confirmationText}
        </p>
        <div className="align-content-right">
          <Button className="left-spacer" variant={this.props.btnVariant || "info"} 
            onClick={() => this.callbackFunction()} id={`${this.props.btnText}-callback-function`}>
            {this.props.btnText}
          </Button>
        </div>
      </ButtonWithPopover>
    );
  }
}

export default RmPopoverButton;
