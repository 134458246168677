import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';

export const getUserFavoriteDashboardsRequestAction = "GET_USER_FAVORITE_DASHBOARDS_REQUEST";
export const getUserFavoriteDashboardsSuccessAction = "GET_USER_FAVORITE_DASHBOARDS_SUCCESS";
export const getUserFavoriteDashboardsFailureAction = "GET_USER_FAVORITE_DASHBOARDS_FAILURE";

export const getUserFavoriteDashboards = (pageSize, page, sorted, filteredData) => (dispatch, getState) => {
    let filtered = JSON.parse(JSON.stringify(filteredData));
    const rsaaObject = {
        [RSAA]: {
            endpoint: `${config.apiRoot}/analytics/user-favorite-dashboards`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
            body: JSON.stringify({ pageSize, page, filtered, sorted }),
            types: [
              getUserFavoriteDashboardsRequestAction,
                {
                    type: getUserFavoriteDashboardsSuccessAction,
                    payload: async (action, state, res) => {
                        const data =await res.json();
                        return { pageSize: pageSize, filtered: filteredData, ...data };
                    }
                },
                getUserFavoriteDashboardsFailureAction
            ],
        }
    }
  
    return dispatch(rsaaObject);
  }