import CAT_CONSTANTS from 'lookup/CatConstants';

export const saveWorkingCodexInfo = (codexVersion, codexVersionId, creationDate, tag, status, firstApprovalBy) => {
  const isWorkingVersionEditable = status === CAT_CONSTANTS.codexStatuses.IN_DEVELOPMENT ? true : false;
  return {
    type: "SAVE_WORKING_CODEX_INFO",
    payload: {
      codexVersion, codexVersionId, creationDate, tag, isWorkingVersionEditable, status, firstApprovalBy
    }
  }
}

export const saveWorkingCodexTag = (tag) => ({
  type: "SAVE_WORKING_CODEX_TAG",
  payload: {tag}
});


