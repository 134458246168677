export const MemberConstants = {

  MEMBER_RELATIONSHIP_MAPPING: {
    "CHD": "Child",
    "SPS": "Spouse",
    "SUB": "Employee",
    "sub": "Employee",
    "USE": "Other",
    "OTHER": "Other",
  }
};
