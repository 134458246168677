export const BatchConstants = {

    statuses: [
     {key: '', value: 'ALL_FILTERS'},
      { key: 'CLOSED', value: 'CLOSED'},
      {key: 'OPEN', value: 'OPEN'},
      {key: 'PENDING', value: 'PENDING'},
      {key: 'IN_PROGRESS', value: 'IN_PROGRESS'}
    ],
    
  DEFAULT_BATCH_LIST_SORTING: [ {id: "dateScheduled", desc: true, isDefault: true}],
  ALL_FILTER:{ id: 'status', value: 'ALL_FILTERS'},
  DEFAULT_BATCH_LIST_FILTERS:[{ id: 'status', value:'', isdefault: true }],
  DEFAULT_BATCH_LIST_FILTERS_FRONTEND:{ id: 'status', value:'ALL_FILTERS', isdefault: true },
  
}
