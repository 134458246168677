import { isError } from "lodash";
import { WorkflowConstants } from "lookup/WorkflowConstants";

export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case "GET_MEMBER_DETAIL_NOTE_REQUEST":
      newState = Object.assign({}, previousState, {
        memberInfoLoading: true,
        isError: false,
      });
      break;
    case "GET_MEMBER_DETAIL_NOTE_SUCCESS":
      newState = Object.assign({}, previousState, {
        data: action.payload,
        notes: action.payload.noteDtoList,
        memberInfoLoading: false,
        isError: false,
        excludeIds:action.payload.excludeIds,
      });
      break;
    case "GET_MEMBER_DETAIL_NOTE_FAILURE":
      newState = Object.assign({}, previousState, {
        data: undefined,
        notes: undefined,
        memberInfoLoading: false,
        isError: true,
      });
      break;
    default:
      break;
  }

  return newState;
}

function selectedActivityInContext(selectedActivity, activityList) {
  if (selectedActivity) {
    for (let i = 0; i < activityList.length; i++) {
      if (selectedActivity.activityId === activityList[i].activityId) return i;
    }
  }
  return 0;
}
