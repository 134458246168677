import React from 'react';
import { Badge, FormSelect, FormControl } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import { useDispatch, useSelector } from "react-redux";
import { getPAWGroupList, refreshPawGroupList } from "./PawGroup.actions";
import "./PawGroup.css";
import { statusBadgeColors, status } from "./PawGroup.constants";
import PawGroupDisplayBar from '../PAWGroupDisplayBar/PawGroupDisplayBar';
import { formatDateInEnglish } from 'services/MomentUtils';
import Pagination from '../../../components/Pagination/Pagination';
import { hasRoleIn } from 'rbac/RbacHelper';
import ROLES from 'rbac/Roles.json';
import PublishPaw from 'components/PawStatus/PublishPaw';


const PawGroup = ({ history }) => {
  const dispatch = useDispatch();
  const pawGroupState = useSelector(state => state.pawGroup);
  const pawGroupLoading = pawGroupState.loading;
  const totalRecords = pawGroupState.totalElements;
  const pawGroupList = useSelector(state => state.pawGroup.pawGroups);
  let isPAWAdmin = hasRoleIn([ROLES.PAW_ADMIN]);

  React.useEffect(() => {
    dispatch(getPAWGroupList(pawGroupState.pageSize, 0, pawGroupState.sorted, pawGroupState.filtered));
  }, [dispatch]);

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    updatePAWGroupTable(pageSize, pageNo, sortBy ? sortBy : [], filterValues);
  }

  const updatePAWGroupTable = (pageSize, pageNo, sorted, filtered) => {
    const index_status = filtered.findIndex(element => element.id === 'status' && element.value === 'ALL')
    if (index_status !== -1) {
      filtered[index_status].value = "";
    }
    dispatch(getPAWGroupList(pageSize, pageNo, sorted, filtered));
  }
  const refreshList = () => {
    dispatch(refreshPawGroupList());
  }

  const listState = {
    page: pawGroupState.currentPage,
    pageSize: pawGroupState.pageSize,
    sorted: pawGroupState.sorted,
    filtered: pawGroupState.filtered,
    totalPages: pawGroupState.totalPages,
    resized: [],
    expanded: {}
  };

  const statusFilterMap = []
  for (const [keyVal, valueVal] of Object.entries(status)) {
    statusFilterMap.push({key: keyVal, value: valueVal});
  }
  
  const columns = [
    {
      Header: "PawGroup Id",
      accessor: "pawGroupUuid",
      accessorKey: "pawGroupUuid",
      width: 60,
      show: false,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "PAW Group",
      accessor: "switchCreationDate",
      accessorKey: "switchCreationDate",
      width: 200,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.DateColumn,
      filterType: FilterTypes.DateRangePicker,
    },
    {
      header: "Client",
      accessor: "clientNameAbbreviation",
      accessorKey: "clientNameAbbreviation",
      width: 200,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Customer",
      accessor: "customerNameAbbreviation",
      accessorKey: "customerNameAbbreviation",
      width: 200,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Total Switches",
      accessor: "totalSwitches",
      accessorKey: "totalSwitches",
      width: 200,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Open Switches",
      accessor: "openSwitches",
      accessorKey: "openSwitches",
      width: 200,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Auto Approved",
      accessor: "autoApprovedSwitches",
      accessorKey: "autoApprovedSwitches",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Manual Approved",
      accessor: "manuallyApprovedSwitches",
      accessorKey: "manuallyApprovedSwitches",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Auto Rejected",
      accessor: "autoRejectedSwitches",
      accessorKey: "autoRejectedSwitches",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Manual Rejected",
      accessor: "manualRejectedSwitches",
      accessorKey: "manuallyRejectedSwitches",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Status",
      accessor: "status",
      accessorKey: "status",
      width: 350,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SingleSelect,
      filterOptions: statusFilterMap,
      filterReturnKey: true,
      customComponent: (value) => {
        return renderAllStatuses(value)
      },
    },
    {
      header: "Action",
      accessor: "actionButton",
      accessorKey: "actionButton",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return renderActions(row)
      }
    },
  ];
  const renderAllStatuses = (statuses) => {
    if (statuses) {
      return (
        <span className={"column"}>
          <Badge className={"paw-group-pill text-wrap"} pill bg={statusBadgeColors[statuses]} text={"light"}>
            {status[statuses]}
          </Badge>
        </span>
      )
    }
    return <span></span>;
  }

  const renderActions = (rowData) => {
    if (rowData) {
      return ((status[rowData.original.status] == status.PENDING_APPROVAL
        || status[rowData.original.status] == status.ERROR_PUBLISHED) && isPAWAdmin ?
        <span className={"column"} >
          <PublishPaw
            date={rowData.original.switchCreationDate}
            client={rowData.original.clientNameAbbreviation}
            pawGroupId={rowData.original.pawGroupUuid}
            refreshList={refreshList}
          />
        </span> : null
      )
    }
    return <span></span>;
  }

  return (
    <Container className="list-container" fluid>
      <PawGroupDisplayBar history={history} groupCount={totalRecords} />
      <ServerSideReactTable
        columnDefination={columns}
        overrideRowId={'pawGroupId'}
        data={pawGroupList}
        currentPage={listState.page}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={listState.totalPages}
        totalRecords={totalRecords=="loading"?0:totalRecords}
        loading={pawGroupLoading}
        enableMultiRowSelection={false}
        defaultPageSize={listState.pageSize}
        onUpdateTableData={onUpdateTable}
        trStyle={(rowInfo) => {
            if (rowInfo && status[rowInfo.original.status] == status.IN_PROGRESS) {
              return (
                {
                  cursor: 'not-allowed',
                  opacity: 0.6,
                })
            } else {
              return {}
            }
          }
        }
        onRowClick={(row) => {
          if (row && row.column.id != 'actionButton' && row.original.status != 'IN_PROGRESS') {
            const pawGroupId = pawGroupList[row.index].pawGroupUuid;
            history.push(`/paw/paw-groups/${pawGroupId}/drug-groups`);
          }
        }}
      />
    </Container>
  )
}

export default PawGroup;