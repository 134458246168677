import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CurrentPrograms from "components/MemberModule/CurrentPrograms/CurrentPrograms";
import React, { useState } from "react";
import MemberPhiPanel from "components/MemberModule/MemberPhiPanel/MemberPhiPanel";
import { Row, Col } from "react-bootstrap";
import MemberSwitchesGrid from "components/MemberModule/MemberSwitchesGrid/MemberSwitchesGrid";
import ActivitiesPopup from "components/MemberModule/ActivitiesPopup/ActivitiesPopup";
import MemberDetailNotes from "./MemberDetailNotes";
import { getMemberDetailNotes } from "./MemberDetail.actions";
import Spinner from "components/Spinner";


const MemberDetail = (props) => {
  const dispatch = useDispatch();
  const memberInfoId = props.match.params.memberId;
  const [shouldShowActivityPopup, setShouldShowActivityPopup] = useState(false);
  const [switchId, setSwitchId] = useState(null);

  const openActivitiesModal = (switchDetail) => {
    setSwitchId(switchDetail.switchId);
    changeActivityPopupVisibility();
  };

  const changeActivityPopupVisibility = () => {
    if (shouldShowActivityPopup) {
      setSwitchId(null);
    }
    setShouldShowActivityPopup(!shouldShowActivityPopup);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    dispatch(getMemberDetailNotes(memberInfoId));
  };

  const memberNotes = useSelector((state) => state.memberDetail.notes);
  const memberInfoLoading = useSelector((state) => state.memberDetail.memberInfoLoading)
  const excludeIds = useSelector((state) => state.memberDetail.excludeIds);

  return (
    <div>
      <Row>
        <Col sm={4} md={3}>
          <MemberPhiPanel memberInfoId={memberInfoId} />
        </Col>
        <Col sm={8} md={9}>
          <MemberSwitchesGrid
            memberInfoId={memberInfoId}
            onSelect={openActivitiesModal}
          />
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <CurrentPrograms memberInfoId={memberInfoId} />
        </Col>
          {memberInfoLoading ? (
            <Spinner />
          )  : (
          <Col md={2} className="boxed-div-blue">
            <MemberDetailNotes
              entityId={memberInfoId}
              entitySaveUrl={"/member/note"}
              memberNotes={memberNotes}
              noteEditStatus={""}
              activityType={""}
              activityEntityType={""}
              sortByDate={
                memberNotes && memberNotes.length > 0
                  ? memberNotes[memberNotes.length - 1].creationDate
                  : null
              }
              excludeId = {excludeIds}
            />
          </Col>
        )}

      </Row>
      <ActivitiesPopup
        changeActivityPopupVisibility={changeActivityPopupVisibility}
        shouldShowActivityPopup={shouldShowActivityPopup}
        switchId={switchId}
      />
      {/* <ActivitiesPopup /> */}
    </div>
  );
};
export default MemberDetail;
