import { ColumnTypes, FilterTypes } from "commons/table/Constants";
import { renderScheduledDateCol } from "components/ReactTableColumnHelper";
import {
  SWIM_SWITCH_INDICATOR_FILTER,
  SWIM_SWITCH_ACTIVITY_TYPE_FILTER_FOR_SELECT,
} from "lookup/SwimConstants";
import { WrapTextAndCenterAlign } from "components/WrapText";
import { Badge } from "react-bootstrap";
import ActivityCounterSwimSwitchList from "./ActivityCounterSwimSwitchList";
import { hasRoleIn } from "rbac/RbacHelper";
import { RoutableColumn } from "../../../commons/table/Column.js";
import ROLES from "rbac/Roles.json";

export const COLUMN = {
  customerNameAbbreviation: "customerNameAbbreviation",
  clientNameAbbreviation: "clientNameAbbreviation",
  switchNumber: "switchNumber",
  scheduledDate: "scheduledDate",
  prescriberName: "prescriberName",
  prescriberFax: "prescriberFax",
  prescriberPhone: "prescriberPhone",
  prescriberNpi: "prescriberNpi",
  pharmacyName: "pharmacyName",
  pharmacyFax: "pharmacyFax",
  pharmacyPhone: "pharmacyPhone",
  pharmacyNpi: "pharmacyNpi",
  type: "type",
  activityCount: "activityCount",
  status: "status",
};

export const createColumnListForSwimSwitchList = (
  columns = [],
  enableSortFilterForCust = true
) => {
  let columnList = [];

  const typeFilterMap = [];
  SWIM_SWITCH_ACTIVITY_TYPE_FILTER_FOR_SELECT.forEach((state, i) =>
    typeFilterMap.push({ id: state.key, name: state.value })
  );

  for (const element of columns) {
    switch (element) {
      case COLUMN.customerNameAbbreviation:
        columnList.push({
          header: "Customer",
          accessor: "customerNameAbbreviation",
          accessorKey: "customerNameAbbreviation",
          enableSorting: enableSortFilterForCust,
          enableColumnFilter: enableSortFilterForCust,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.clientNameAbbreviation:
        columnList.push({
          header: "Client",
          accessor: "clientNameAbbreviation",
          accessorKey: "clientNameAbbreviation",
          enableSorting: enableSortFilterForCust,
          enableColumnFilter: enableSortFilterForCust,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.switchNumber:
        columnList.push({
          header: "Switch Id",
          accessor: "switchNumber",
          accessorKey: "switchNumber",
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Custom,
          filterType: FilterTypes.SearchableInput,
          customComponent: (value, row) => {
            if (hasRoleIn([ROLES.SWIM_USER])) {
              return (
                <RoutableColumn
                  link={`/switch/${row.original.wfItemId}`}
                  value={value}
                />
              );
            } else {
              return value;
            }
          },
        });
        break;

      case COLUMN.scheduledDate:
        columnList.push({
          header: "Scheduled For",
          accessor: "scheduledDate",
          accessorKey: "scheduledDate",
          size: 160,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Custom,
          filterType: FilterTypes.DateRangePicker,
          customComponent: (value, row) => {
            return renderScheduledDateCol(
              row.original.scheduledDate,
              row.original.daysToScheduledDate
            );
          },
        });
        break;

      case COLUMN.prescriberName:
        columnList.push({
          header: "Prescriber",
          accessor: "prescriberName",
          accessorKey: "prescriberName",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.prescriberFax:
        columnList.push({
          header: "Prescriber Fax",
          accessor: "prescriberFax",
          accessorKey: "prescriberFax",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.prescriberPhone:
        columnList.push({
          header: "Prescriber Phone",
          accessor: "prescriberPhone",
          accessorKey: "prescriberPhone",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.prescriberNpi:
        columnList.push({
          header: "Prescriber NPI",
          accessor: "prescriberNpi",
          accessorKey: "prescriberNpi",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.pharmacyName:
        columnList.push({
          header: "Pharmacy",
          accessor: "pharmacyName",
          accessorKey: "pharmacyName",
          width: 140,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.pharmacyFax:
        columnList.push({
          header: "Pharmacy Fax",
          accessor: "pharmacyFax",
          accessorKey: "pharmacyFax",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.pharmacyPhone:
        columnList.push({
          header: "Pharmacy Phone",
          accessor: "pharmacyPhone",
          accessorKey: "pharmacyPhone",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.pharmacyNpi:
        columnList.push({
          header: "Pharmacy NPI",
          accessor: "pharmacyNpi",
          accessorKey: "pharmacyNpi",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;

      case COLUMN.type:
        columnList.push({
          header: "Type",
          accessor: "type",
          accessorKey: "type",
          width: 70,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.MultiSelect,
          filterOptions: typeFilterMap,
        });
        break;

      case COLUMN.activityCount:
        columnList.push({
          header: "Activity Count",
          accessor: "activityCount",
          accessorKey: "activityCount",
          size: 60,
          enableSorting: true,
          enableColumnFilter: false,
          columnType: ColumnTypes.Custom,
          customComponent: (value, row) => {
            return (
              <ActivityCounterSwimSwitchList
                rowData={row}
                activityCount={value}
              />
            );
          },
        });
        break;

      case COLUMN.status:
        columnList.push({
          header: "Status",
          accessor: "isSwimSwitch",
          accessorKey: "isSwimSwitch",
          width: 150,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Custom,
          customComponent: (value) => {
            return (
              <WrapTextAndCenterAlign>
                {value ? (
                  <Badge bg="success">Swim Switch</Badge>
                ) : (
                  <Badge bg="warning">N/A</Badge>
                )}
              </WrapTextAndCenterAlign>
            );
          },
          filterType: FilterTypes.SingleSelect,
          filterOptions: SWIM_SWITCH_INDICATOR_FILTER,
          filterReturnKey: true,
        });
        break;
    }
  }
  return columnList;
};
