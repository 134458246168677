const INITIAL_STATE = {
    medDrugs: [],
    error: '',
    isLoading: false,
};

export function Reducer(previousState = INITIAL_STATE, action) {
    let newState = previousState;
    switch (action.type) { 
        case 'CHECK_VALID_NEW_DRUG_TYPE_SUCCESS':
            newState = { ...newState, isLoading: false, medDrugs: action.payload }
            break;
        case 'CHECK_VALID_NEW_DRUG_TYPE':
            newState = { ...newState, isLoading: true, medDrugs: [], error: '' }
            break;
        case 'CHECK_VALID_NEW_DRUG_TYPE_FAILURE':
            newState = { ...newState, isLoading: false, error: action.payload.error }
            break;
        default:
            break;
    }
    return newState;
}