import React from 'react';

// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withRouter} from 'react-router-dom';
import {saveWorkingCodexInfo} from './CodexVersion.actions';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CodexVersionDisplayBar from 'components/CodexVersionDisplayBar';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import CodexVersionList from 'components/CodexVersionList';

class CodexVersion extends React.Component {

  
  state = {
    codexVersions: null,
    loadingCodexVersions: false,
    selectedCodexVersion: null,
    disableApproveButton: false,
  }

  componentDidMount() {
    this.fetchCodexVersions();
  }
  
  fetchCodexVersions(selectedCustomer) {
    this.setState({loadingCodexVersions: true})
    sendAuthenticatedAsyncRequest(
      `/cat/codex-versions`,
      "GET", 
      null,
      (r) => { this.setState({codexVersions: r.data, loadingCodexVersions: false})},
      (r) => this.setState({codexVersions: null, loadingCodexVersions: false})
    );
  }
  
  handleCodexVersionChange = selectedCodexVersion => {
    this.setState({ selectedCodexVersion });
    const {version, codexVersionId, creationDate, tag, status, firstApprovalBy} = selectedCodexVersion;

    this.props.saveWorkingCodexInfo(version, codexVersionId, creationDate, tag, status, firstApprovalBy);
    this.props.history.push('/cat/codex/dsg');
  };

  render() {
    const { codexVersions, selectedCodexVersion, loadingCodexVersions } = this.state;
    
    const {disableApproveButton} = this.state.disableApproveButton;
    return (
      <Container fluid>
        <CodexVersionDisplayBar />
        <Row className='top-spacer'>
          <Col md={{offset: '2', span: '8'}}>
            <h4>Select a Codex Version to get started </h4>
            <div className='big-top-spacer'>
              <label>
                Select a Working Codex Version
              </label>
              <CodexVersionList
                noDataText={'No codex versions found'} 
                data={codexVersions || []} 
                loading={loadingCodexVersions}
                selectedCodexVersionId={selectedCodexVersion ? selectedCodexVersion.codexVersionId : -1}
                onSelect={this.handleCodexVersionChange}
              />
            </div>

          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveWorkingCodexInfo: bindActionCreators(saveWorkingCodexInfo, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CodexVersion));