export const statusBadgeColors = {
  "IN_REVIEW": "primary",
  "PUBLISHED": "success",
  "IN_PROGRESS": "secondary",
  "ERROR_PUBLISHED": "danger",
  "PENDING_APPROVAL": "primary",
  "PUBLISHING_IN_PROGRESS": "dark"
}

export const status = {
  "ALL": "All",
  "IN_PROGRESS": "Creating Paw Group",
  "IN_REVIEW": "In Review",
  "PENDING_APPROVAL": "Pending Approval",
  "ERROR_PUBLISHED": "Error Published",
  "PUBLISHING_IN_PROGRESS": "Publishing in Progress",
  "PUBLISHED": "Published",
}

export const getIfPublishStatus = (pgStatus) => {
  if (status[pgStatus] == status.ERROR_PUBLISHED
    || status[pgStatus] == status.PUBLISHING_IN_PROGRESS
    || status[pgStatus] == status.PUBLISHED) {
    return pgStatus;
  }
  return null;
}