import { withRouter } from "react-router-dom";
import { getColorForPatientAge, getGender } from "services/UtilityService";
import { calculateAge } from "services/MomentUtils";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProtectedHealthInformation from "./ProtectedHealthInformation";
import UpdateMemberInfo from "components/UpdateMemberInfo";
import { useState } from "react";

const MemberDetails = (props) => {
  const [patient, setPatient] = useState(props.patient);
  const { globalSwitchId, isDisabled } = props;

  const updateMemberData = (updatedMember) => {
    //setPatient(updatedMember); //commenting it as plan number/name is commented at the backend as well, so updatedMember contains null and after update it shows empty plan name in widget
  };

  return (
    <>
      <Row>
        <Col md={8}>
          <div className="rm-tiny-top-label">Member</div>
          <div data-testid='text-member-number'>{patient.externalMemberId} &nbsp;
            {patient.optedOut ?
              <Badge bg="warning" pill data-testid='badge-member-opt-out'>
                Opted Out
              </Badge>
              :
              <></>
            }
          </div>
        </Col>
        <Col md={4}>
          <div className="rm-tiny-top-label">Time Zone</div>
          <div data-testid='text-member-timezone'>{patient.timeZone ? patient.timeZone : "N/A"}</div>
        </Col>
        {!isDisabled ? (
          <Col md={12}>
            <UpdateMemberInfo
              patient={patient}
              isDisabled={isDisabled}
              globalSwitchId={globalSwitchId}
              onSubmission={updateMemberData}
              updateData={props.updateData}
              wfItemId={props.wfItemId}
              isFromAW={props.isFromAW}
            />
          </Col>) : null}
        <Col md={6}>
          <div className="rm-tiny-top-label">Gender</div>
          <div data-testid='text-member-gender'>{getGender(patient.gender)}</div>
        </Col>
        <Col md={6}>
          <div className="rm-tiny-top-label tiny-top-spacer">Age</div>
          <div data-testid='text-member-age'>{calculateAge(patient.memberDateOfBirth)} years</div>
        </Col>
        <Col md={6}>
          <div className="rm-tiny-top-label tiny-top-spacer">Plan</div>
          <div data-testid='text-member-plan-name'>{patient.planName}</div>
        </Col>
        <Col md={6}>
          <div className="rm-tiny-top-label tiny-top-spacer">PHI</div>
          <div>
            <ProtectedHealthInformation globalSwitchId={globalSwitchId} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(MemberDetails);
