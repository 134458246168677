
import { createSlice } from '@reduxjs/toolkit';
import { getMemberSwitchesSuccessAction, getMemberSwitchesFailureAction, getMemberSwitchesRequestAction } from './MemberSwitchGrid.actions';

export const INITAL_STATE = {
    data: [],
    totalPages: 0,
    pageSize: 20,
    page: 0,
    error: false,
    loading: false,
    totalElements: "Loading",
    filtered: [],
    sorted: [],
    resized: [],
    expanded: {},
}


export const memberSwitchesSlice = createSlice({
    name: "memberSwitches",
    initialState: INITAL_STATE,
    reducers: {
        getMemberSwitchesSuccess: (state, action) => {
            state.data = action.payload.records;
            state.totalPages = action.payload.totalPages;
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.page;
            state.sorted = action.payload.sorted;
            state.filtered = action.payload.filtered;
            state.totalElements = action.payload.totalElements;
            state.error = false;
            state.loading = false;
        },
        getMemberSwitchesFailure: (state, action) => {
            state.loading = false;
            state.error = false;
            state.totalElements = "Failed";
            state.data = [];
        },
        getMemberSwitchesRequest: (state, action) => {
            state.loading = true;
            state.isDefaultFilter = false;
            state.totalElements = "Loading";
        },
        resetList: (state, action) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            // Handle the pending, fulfilled, and rejected states of the "member list" RSAA action.
            .addCase(getMemberSwitchesRequestAction, (state, action) => {
                memberSwitchesSlice.caseReducers.getMemberSwitchesRequest(state, action);
            })
            .addCase(getMemberSwitchesSuccessAction, (state, action) => {
                memberSwitchesSlice.caseReducers.getMemberSwitchesSuccess(state, action);
            })
            .addCase(getMemberSwitchesFailureAction, (state, action) => {
                memberSwitchesSlice.caseReducers.getMemberSwitchesFailure(state, action);
            })

    }
});


export const { getMemberSwitchesSuccess, getMemberSwitchesFailure, getMemberSwitchesRequest, resetList } = memberSwitchesSlice.actions;
export const memberSwitchesState = (state) => state.memberSwitches;
export default memberSwitchesSlice.reducer;