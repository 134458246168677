import React from "react";
import Select from 'react-select';

import { withRouter } from "react-router-dom";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import { isStringEmptyOrNull } from "services/UtilityService";
const UpdateActivityState = (props) => {

  const activityId = props.activityId;
  const showPrescriberOptions = props.showPrescriberOptions;
  const isActionCallable = props.isActionCallable || false;
  const activityState = props.activityState;
  let activityStates = showPrescriberOptions ? AgentWorkspaceConstants.PRESCRIBER_ACTIVITY_STATES : AgentWorkspaceConstants.PHARMACY_ACTIVITY_STATES;
  if (props.isMemberActivityCreatable) {
    activityStates = [...activityStates, {
      label: "Member Call",
      value: "Member Call",
    }]
  }

  const handleStateChange = (state) => {
    props.onUpdateState(state);
  }

  return (
    <div data-testid="input-close-activity-activity-state">
      <Select
        name="activity-state-update"
        isDisabled={!isActionCallable}
        value={isStringEmptyOrNull(activityState) ? "" : { label: activityState, value: activityState }}
        options={activityStates}
        onChange={(state) => handleStateChange(state)}
        placeholder={"Update Activity State"}
        data-testid="input-close-activity-activity-state"
      >
      </Select>
    </div>
  );
}


export default withRouter(UpdateActivityState);
