import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

import KeyValueTable from 'components/KeyValueTable';
import Spinner from 'components/Spinner';
import { sendAsyncRequest } from 'services/AsyncRequestService';
import { parseLowerCamelCase } from 'services/UtilityService';
import * as ART from 'services/ApiRequestTrackerService';

class MemberPHI extends Component {
  state = {
    globalSwitchId: this.props.match.params.globalSwitchId,
    zohoAccessToken: this.props.match.params.zohoAccessToken,
    zohoUserId: this.props.match.params.zohoUserId,
    apiRequestTracker: ART.init(true),
    phi: null,
  }

  componentDidMount () {
    this.fetchPHI();
  }
 

  static getDerivedStateFromProps(props, state) {
    const { globalSwitchId, zohoAccessToken, zohoUserId } = props.match.params;

    if (zohoAccessToken !== state.zohoAccessToken) {
      return { globalSwitchId, zohoAccessToken, zohoUserId };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.zohoAccessToken !== prevState.zohoAccessToken) {
      this.fetchPHI();
    }
  }


  fetchPHI () {
    const {globalSwitchId, zohoAccessToken, zohoUserId} = this.state;
    this.setState({apiRequestTracker: ART.start()});
    sendAsyncRequest(
      `/phi/zoho?globalSwitchId=${globalSwitchId}&zohoAccessToken=${zohoAccessToken}&zohoUserId=${zohoUserId}`,
      `GET`, 
      null,
      (r) => {
        this.setState({
          phi: r.data,
          apiRequestTracker: ART.success()
        });
      },
      (r) => {
        this.setState({
          phi: null,
          apiRequestTracker: ART.failure(r.data || 'The content you are trying to access is not available!')
        })
      },
    );
  }

  render() {
    const {apiRequestTracker, phi} = this.state;
    if (apiRequestTracker.inProgress)
      return <Spinner />;

    return (
      <Container className='big-top-spacer'>
        <Row>
          <Col md={{span: 6, offset: 3}}>
            <h1>Member PHI</h1>
            <hr />
            {
              apiRequestTracker.error
              ? 
                <Alert variant='danger'>
                  <strong>Oops!</strong> {apiRequestTracker.message}
                </Alert>
              : <Card className='big-top-spacer'>
                  <KeyValueTable rows={ Object.keys(phi).map( k => ({label: parseLowerCamelCase(k), value: phi[k]}) ) } />
                </Card>
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(MemberPHI);