import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../services/AuthService';
import { GET_MEMBER_SWITCH_LIST_FAILURE, GET_MEMBER_SWITCH_LIST_REQUEST, GET_MEMBER_SWITCH_LIST_SUCCESS } from './MemberSwitchList.constant';
import { cloneDeep } from 'lodash';
import {resetList} from './MemberSwitchList.slice';
 
export const getMemberSwitchList = (pageSize, page, sorted, filtered) => (dispatch, getState) => {
   const apiFilteredValue=cloneDeep(filtered);
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/member/switch/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered: apiFilteredValue }),
      types: [
        GET_MEMBER_SWITCH_LIST_REQUEST,
        {
          type: GET_MEMBER_SWITCH_LIST_SUCCESS,
          payload: async (action, state, res) => {
            const data = await res.json();
            return { pageSize: pageSize, sorted: sorted, filtered: filtered, ...data };
          },
          meta: (action, state, res) => {
            if (res.status === 404) {
              return ;
            }
          }
        },
        GET_MEMBER_SWITCH_LIST_FAILURE
      ],
    }
  }

  return dispatch(rsaaObject);
}

export const resetMemberSwitchList = () => (dispatch) => {
  dispatch(resetList());
}

