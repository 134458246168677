import React, { useState, useRef } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import colors from "lookup/Colors";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import DismissableModal from "components/DismissableModal";
import {
  getErrorMessage,
  isObjectEmpty,
  isStringEmptyOrNull,
} from "services/UtilityService";
import CONTACTMANAGEMENT_CONSTANTS from "lookup/ContactManagementConstants";
import PrescribersList from "./PrescribersList";
import { showNotification } from "services/NotificationService";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";

function SwitchPrescriberInfo(props) {
  const [selectedPrescriber, setSelectedPrescriber] = useState(
    props.prescriber
  );
  const [isCreating, setIsCreating] = useState(false);

  const UpdateInfoFormSchema = Yup.object().shape({
    prescriberFirstName: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberLastName: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberPractice: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberNameCredentialText: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberPhone: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      ),
    prescriberFax: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      ),
    prescriberAddressLine1: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberNpi: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .length(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldLengthValidationPrefix + "10"
      )
      .required("Npi is required"),
  });

  const CreateInfoFormSchema = Yup.object().shape({
    prescriberFirstName: Yup.string()
      .max(
        255,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
      )
      .required("First Name is required"),
    prescriberLastName: Yup.string()
      .max(
        255,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
      )
      .required("Last Name is required"),
    prescriberPractice: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberNameCredentialText: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberPhone: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .required("Phone is required"),
    prescriberFax: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .required("Fax is required"),
    prescriberAddressLine1: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberAddressLine2: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberCityName: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    prescriberZipCode: Yup.string()
      .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .matches(/^.{5}$|^.{9}$/, CONTACTMANAGEMENT_CONSTANTS.fieldLengthValidationPrefix + "5 or 9"
    ),
    prescriberNpi: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .length(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldLengthValidationPrefix + "10"
      )
      .required("Npi is required"),
  });

  const isFieldRequired = (key) =>
    isCreating && CreateInfoFormSchema.fields[key]
      ? CreateInfoFormSchema.fields[key].exclusiveTests.required
      : false;

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
    setValues
  } = useFormik({
    initialValues: {
      ...selectedPrescriber,
    },
    onSubmit: (values, { setSubmitting, setValues }) => {
      if (isCreating) {
        addPrescriber(
          values,
          setSubmitting,
          setValues,
          props.prescriber,
          modalRef.current.handleClose
        );
      } else {
        switchPrescriber(
          selectedPrescriber,
          setSubmitting,
          setValues,
          props.prescriber,
          modalRef.current.handleClose
        );
      }
    },
    validationSchema: isCreating ? CreateInfoFormSchema : UpdateInfoFormSchema,
  });

  function addPrescriber(
    originalValues,
    setSubmitting,
    setValues,
    oldPrescriber,
    closeModal
  ) {
    // it's best to create a clone because params are passed through reference
    const values = cloneDeep(originalValues);
    if (
      isStringEmptyOrNull(values.prescriberNpi) ||
      isStringEmptyOrNull(values.prescriberFax) ||
      isStringEmptyOrNull(values.prescriberFirstName) ||
      isStringEmptyOrNull(values.prescriberLastName) ||
      isStringEmptyOrNull(values.prescriberPhone)
    ) {
      showNotification({
        title: "Invalid Change Detected!",
        message: `Provide all required fields`,
        position: "tr",
        autoDismiss: 5,
        type: "warning",
      });
      setSubmitting(false);
      return;
    }

    sendAuthenticatedAsyncRequest(
      `/contact-management/add-and-switch-prescriber/${props.wfItemId}`,
      "POST",
      values,
      (r) => {
        setSubmitting(false);
        const updatePrescriberInfoDto = r.data;
        closeModal();
        if (props.onSubmission && typeof props.onSubmission === "function") {
          props.onSubmission(updatePrescriberInfoDto);
        }

        showNotification({
          title: `Successful Update!`,
          message: `Prescriber Info create and switched successfully`,
          position: "tr",
          type: "success",
        });
        clearForm();
      },
      (r) => {
        setSubmitting(false);
        //setValues(oldPrescriber);
        // dispatch failure notification
        showNotification({
          title: "Could not change Prescriber!",
          message:
            getErrorMessage(r.data) ||
            getErrorMessage("FAILED_TO_UPDATE_PRESCRIBER_INFO"),
          position: "tr",
          type: "error",
        });
      }
    );
  }

  function switchPrescriber(
    originalValues,
    setSubmitting,
    setValues,
    oldPrescriber,
    closeModal
  ) {
    // it's best to create a clone because params are passed through reference
    const values = cloneDeep(originalValues);
    if (
      oldPrescriber.prescriberPractice === values.prescriberPractice &&
      oldPrescriber.prescriberFirstName === values.prescriberFirstName &&
      oldPrescriber.prescriberLastName === values.prescriberLastName &&
      oldPrescriber.prescriberFax === values.prescriberFax &&
      oldPrescriber.prescriberPhone === values.prescriberPhone &&
      oldPrescriber.prescriberNpi === values.prescriberNpi
    ) {
      showNotification({
        title: "No Switch Detected!",
        message: `You are trying to switch to already selected prescriber`,
        position: "tr",
        autoDismiss: 5,
        type: "success",
      });
      setSubmitting(false);
      return;
    }

    sendAuthenticatedAsyncRequest(
      `/contact-management/switch-prescriber/${props.wfItemId}/prescriber/${originalValues.prescriberInfoUuid}`,
      "PUT",
      values,
      (r) => {
        setSubmitting(false);
        const updatePrescriberInfoDto = r.data;
        closeModal();
        if (props.onSubmission && typeof props.onSubmission === "function") {
          props.onSubmission(updatePrescriberInfoDto);
        }

        // dispatch success notification
        showNotification({
          title: `Successful Switch!`,
          message: `Prescriber switched successfully`,
          position: "tr",
          type: "success",
        });
      },
      (r) => {
        setSubmitting(false);
        setValues(oldPrescriber);
        // dispatch failure notification
        showNotification({
          title: "Could not change Prescriber!",
          message:
            getErrorMessage(r.data) ||
            getErrorMessage("FAILED_TO_UPDATE_PRESCRIBER_INFO"),
          position: "tr",
          autoDismiss: 5,
          type: "error",
        });
      }
    );
  }

  const handleSpecialOpen = (handleRealOpen) => {
    handleRealOpen();
  };

  const onChange = (value, field, setFieldValue) => {
    const numberRegex = /^[0-9\b]+$/;
    value = value.replace(/\s/g, "");

    if (value === "" || numberRegex.test(value)) {
      setFieldValue(field, value);
    }
  };

  const clearForm = () => {
    setFieldValue("prescriberFirstName", "");
    setFieldValue("prescriberLastName", "");
    setFieldValue("prescriberName", "");
    setFieldValue("prescriberPhone", "");
    setFieldValue("prescriberPractice", "");
    setFieldValue("prescriberFax", "");
    setFieldValue("prescriberNpi", "");
    setFieldValue("prescriberAddressLine1", "");
    setFieldValue("prescriberAddressLine2", "");
    setFieldValue("prescriberCityName", "");
    setFieldValue("prescriberStateAbbreviation", "");
    setFieldValue("prescriberZipCode", "");
    setFieldValue("prescriberNameCredentialText", "");
    setSelectedPrescriber(null);
  };

  const handleSelectionChange = (selectedPrescriberValue) => {
    setFieldValue(
      "prescriberFirstName",
      selectedPrescriberValue.prescriberFirstName ?? ""
    );
    setFieldValue(
      "prescriberName",
      selectedPrescriberValue.prescriberName ?? ""
    );
    setFieldValue(
      "prescriberLastName",
      selectedPrescriberValue.prescriberLastName ?? ""
    );
    setFieldValue(
      "prescriberPhone",
      selectedPrescriberValue.prescriberPhone ?? ""
    );
    setFieldValue(
      "prescriberPractice",
      selectedPrescriberValue.prescriberPractice ?? ""
    );
    setFieldValue("prescriberFax", selectedPrescriberValue.prescriberFax ?? "");
    setFieldValue(
      "prescriberAddressLine1",
      selectedPrescriberValue.prescriberAddressLine1 ?? ""
    );
    setFieldValue(
      "prescriberNameCredentialText",
      selectedPrescriberValue.prescriberNameCredentialText ?? ""
    );
    setSelectedPrescriber(selectedPrescriberValue);
  };

  const { isDisabled } = props;
  const title = "Switch Prescriber";
  const modalRef = useRef(
    "UpdateInfoModal " +
      ((selectedPrescriber && selectedPrescriber.prescriberGlobalId) || "New")
  );
  const modalOpener = (
    <Button
      size="sm"
      variant="primary"
      style={{ marginLeft: "10px", marginBlock: "5px" }}
      key={1}
      disabled={
        (selectedPrescriber && isObjectEmpty(selectedPrescriber)) || isDisabled
      }
      onClick={() => {
        setIsCreating(false);
        setSelectedPrescriber(props.prescriber);
        handleSelectionChange(props.prescriber);
        handleSpecialOpen(modalRef.current.handleShow);
      }}
      data-testid='btn-prescriber-switch'
    >
      Change
    </Button>
  );

  const getButtonText = () => {
    let buttonText = "";
    if (isSubmitting && !isCreating) {
      buttonText = "Switching ... ";
    } else if (isSubmitting && isCreating) {
      buttonText = "Creating...";
    } else if (!isSubmitting && isCreating) {
      buttonText = "Create and Switch";
    } else {
      buttonText = "Switch";
    }
    return buttonText;
  };

  return (
    <DismissableModal
      ref={modalRef}
      openModalButton={modalOpener}
      title={title}
      size="xl"
      preventCloseWhenClickedOutsideModal={true}
      hideFooter={true}
    >
      <Row
        style={{ margin: "10px", fontSize: "15px", color: colors["rm-orange"] }}
      >
        <p>
          {isCreating
            ? "Switch to an already present prescriber?"
            : "Don't see the prescriber you want to switch to?"}
          <span
            onClick={() => {
              if (isCreating) {
                setSelectedPrescriber(props.prescriber);
                handleSelectionChange(props.prescriber);
              } else {
                setSelectedPrescriber(null);
                clearForm();
              }
              setIsCreating((prev) => !prev);
            }}
            style={{
              backgroundColor: "transparent",
              color: "gray",
              textDecoration: "underline",
              border: "none",
              marginBlock: "2px",
            }}
            data-testid={`link-prescriber-switch-${isCreating ?  'existing': "new"}`}
          >
            &nbsp;{isCreating ? "Update to Existing" : "Add new Prescriber"}
          </span>
        </p>
      </Row>
      <Container>
        {!isCreating && (
          <Row>
            <span
              style={{
                backgroundColor: "transparent",
                color: "gray",
                border: "none",
                marginBlock: "15px",
                fontSize: "15px",
              }}
            >
              Select a prescriber from the table below:{" "}
            </span>
          </Row>
        )}

        <Form onSubmit={handleSubmit}>
          <Row>
            {isCreating ? (
              <>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("prescriberFirstName")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Prescriber First Name
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prescriberFirstName}
                      autoComplete="new-password"
                      name="prescriberFirstName"
                      disabled={!isCreating}
                      data-testid='input-prescriber-create-first-name'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.prescriberFirstName &&
                        touched.prescriberFirstName &&
                        errors.prescriberFirstName}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("prescriberLastName")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Prescriber Last Name
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prescriberLastName}
                      autoComplete="new-password"
                      name="prescriberLastName"
                      disabled={!isCreating}
                      data-testid='input-prescriber-create-last-name'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.prescriberLastName &&
                        touched.prescriberLastName &&
                        errors.prescriberLastName}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("prescriberNameCredentialText")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Suffix
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prescriberNameCredentialText}
                      autoComplete="new-password"
                      name="prescriberNameCredentialText"
                      data-testid='input-prescriber-create-credentials'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.prescriberNameCredentialText &&
                        touched.prescriberNameCredentialText &&
                        errors.prescriberNameCredentialText}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </>
            ) : (
              <Col md={12}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("prescriberName")
                        ? "rm-required-field"
                        : ""
                    }
                  >
                    Prescriber Name
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.prescriberName}
                    autoComplete="new-password"
                    name="prescriberName"
                    disabled={true}
                    data-testid='input-prescriber-create-name'
                  />
                  <Form.Text className="text-small text-danger">
                    {errors.prescriberName &&
                      touched.prescriberName &&
                      errors.prescriberName}
                  </Form.Text>
                </Form.Group>
              </Col>
            )}
          </Row>

          {isCreating && (
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("prescriberNpi")
                        ? "rm-required-field"
                        : ""
                    }
                  >
                    Prescriber NPI
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.prescriberNpi}
                    autoComplete="new-password"
                    name="prescriberNpi"
                    data-testid='input-prescriber-create-npi'
                  />
                  <Form.Text className="text-small text-danger">
                    {errors.prescriberNpi &&
                      touched.prescriberNpi &&
                      errors.prescriberNpi}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("prescriberAddressLine1")
                        ? "rm-required-field"
                        : ""
                    }
                  >
                    Prescriber Street Address Line 1
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.prescriberAddressLine1}
                    autoComplete="new-password"
                    name="prescriberAddressLine1"
                    data-testid='input-prescriber-edit-address-line-1'
                  />
                  <Form.Text className="text-small text-danger">
                    {errors.prescriberAddressLine1 &&
                      touched.prescriberAddressLine1 &&
                      errors.prescriberAddressLine1}
                  </Form.Text>
                </Form.Group>
              </Col>
          </Row>
          )}

          <Row>
          {isCreating ? (
            <>
              <Col md={6}>
              <Form.Group>
                <Form.Label
                  className={
                    isFieldRequired("prescriberPractice")
                      ? "rm-required-field"
                      : ""
                  }
                >
                  Practice Name
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.prescriberPractice}
                  autoComplete="new-password"
                  name="prescriberPractice"
                  disabled={!isCreating}
                  data-testid='input-prescriber-create-practice'
                />
                <Form.Text className="text-small text-danger">
                  {errors.prescriberPractice &&
                    touched.prescriberPractice &&
                    errors.prescriberPractice}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label
                  className={
                    isFieldRequired("prescriberAddressLine2")
                      ? "rm-required-field"
                      : ""
                  }
                >
                  Prescriber Street Address Line 2
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.prescriberAddressLine2}
                  autoComplete="new-password"
                  name="prescriberAddressLine2"
                  data-testid='input-prescriber-edit-address-line-2'
                />
                <Form.Text className="text-small text-danger">
                  {errors.prescriberAddressLine2 &&
                    touched.prescriberAddressLine2 &&
                    errors.prescriberAddressLine2}
                </Form.Text>
              </Form.Group>
            </Col>
            </>
          ) : (
              <>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("prescriberPractice")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Practice Name
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prescriberPractice}
                      autoComplete="new-password"
                      name="prescriberPractice"
                      disabled={!isCreating}
                      data-testid='input-prescriber-create-practice'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.prescriberPractice &&
                        touched.prescriberPractice &&
                        errors.prescriberPractice}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("prescriberPhone")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Prescriber Phone
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        onChange(e.target.value, "prescriberPhone", setFieldValue)
                      }
                      onBlur={handleBlur}
                      value={values.prescriberPhone}
                      autoComplete="new-password"
                      name="prescriberPhone"
                      disabled={!isCreating}
                      data-testid='input-prescriber-create-phone'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.prescriberPhone &&
                        touched.prescriberPhone &&
                        errors.prescriberPhone}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </>
              )}
          </Row>

          {isCreating && (
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("prescriberFax")
                        ? "rm-required-field"
                        : ""
                    }
                  >
                    Prescriber Fax
                  </Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      onChange(e.target.value, "prescriberFax", setFieldValue)
                    }
                    onBlur={handleBlur}
                    value={values.prescriberFax}
                    autoComplete="new-password"
                    name="prescriberFax"
                    disabled={!isCreating}
                    data-testid='input-prescriber-create-fax'
                  />
                  <Form.Text className="text-small text-danger">
                    {errors.prescriberFax &&
                      touched.prescriberFax &&
                      errors.prescriberFax}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("prescriberCityName")
                        ? "rm-required-field"
                        : ""
                    }
                  >
                    City
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.prescriberCityName}
                    autoComplete="new-password"
                    name="prescriberCityName"
                    data-testid='input-prescriber-edit-city-name'
                  />
                  <Form.Text className="text-small text-danger">
                    {errors.prescriberCityName &&
                      touched.prescriberCityName &&
                      errors.prescriberCityName}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          )}

          {isCreating && (
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("prescriberPhone")
                        ? "rm-required-field"
                        : ""
                    }
                  >
                    Prescriber Phone
                  </Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      onChange(e.target.value, "prescriberPhone", setFieldValue)
                    }
                    onBlur={handleBlur}
                    value={values.prescriberPhone}
                    autoComplete="new-password"
                    name="prescriberPhone"
                    disabled={!isCreating}
                    data-testid='input-prescriber-create-phone'
                  />
                  <Form.Text className="text-small text-danger">
                    {errors.prescriberPhone &&
                      touched.prescriberPhone &&
                      errors.prescriberPhone}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("prescriberStateAbbreviation")
                        ? "rm-required-field"
                        : ""
                    }
                  >
                    State
                  </Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    value={values.prescriberStateAbbreviation}
                    name="prescriberStateAbbreviation"
                    data-testid="input-prescriber-edit-state-name"
                  >
                    {/* Render selected value only if it's not in the dropdown options */}
                    {!AgentWorkspaceConstants.stateAbbreviations.find(option => option.value === values.prescriberStateAbbreviation
                    ) && values.prescriberStateAbbreviation && (
                      <option value="">{values.prescriberStateAbbreviation}</option>
                    )}
                    {/* Render "None" if no selected value */}
                    {!values.prescriberStateAbbreviation && 
                      <option value="">None</option>
                    }
                    {/* Render dropdown options */}
                    {AgentWorkspaceConstants.stateAbbreviations.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.key}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          )}

          {isCreating && (
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("prescriberZipCode")
                        ? "rm-required-field"
                        : ""
                    }
                  >
                    Zip Code
                  </Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      onChange(e.target.value, "prescriberZipCode", setFieldValue)
                    }
                    onBlur={handleBlur}
                    value={values.prescriberZipCode}
                    autoComplete="new-password"
                    name="prescriberZipCode"
                    data-testid='input-prescriber-edit-zip-code'
                  />
                  <Form.Text className="text-small text-danger">
                    {errors.prescriberZipCode &&
                      touched.prescriberZipCode &&
                      errors.prescriberZipCode}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          )}

          {!isCreating && (
            <PrescribersList
              selectedPrescriber={selectedPrescriber}
              onSelect={handleSelectionChange}
            />
          )}

          <Row className="top-spacer">
            <Col md={{ offset: "4", span: "4" }}>
              <Button
                className="btn-block"
                variant="outline-dark"
                block="true"
                onClick={() => modalRef.current.handleClose()}
                disabled={isSubmitting}
                data-testid='btn-prescriber-create-cancel'
              >
                Cancel
              </Button>
            </Col>
            <Col md={4} className="bottom-spacer">
              <Button
                type="submit"
                variant="success"
                block="true"
                disabled={isSubmitting}
                className="btn-block"
                data-testid='btn-prescriber-create-save'
              >
                {getButtonText()}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </DismissableModal>
  );
}

export default connect()(withRouter(SwitchPrescriberInfo));
