
import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';


export const getPAWGroupList = (pageSize, page, sorted, filtered) => (dispatch, getState) => {

  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/paw-group/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        'GET_PAW_GROUPS_REQUEST',
        {
          type: 'GET_PAW_GROUPS_SUCCESS',
          payload: async (action, state, res) => {
            const data = await res.json();
            return { pageSize: pageSize, sorted: sorted, filtered: filtered,...data };
          }
        },
        'GET_PAW_GROUPS_FAILURE'
      ],
    }
  }

  return dispatch(rsaaObject);
}

export const refreshPawGroupList = () => (dispatch, getState) => {
  const {sorted,filtered,pageSize} = getState().pawGroup;
  console.log('filterr', sorted,filtered,pageSize)
  dispatch(getPAWGroupList(pageSize, 0, sorted, filtered))
}
