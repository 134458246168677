
export function Reducer(previousState, action) {
    let newState = Object.assign({}, previousState);
  
    switch (action.type) {
      case 'DELTA_DSG_LIST_SUCCESS':
        newState = Object.assign({}, previousState, {
          data: action.payload.records,
          loading: false,
          errorFetchingList: false
        });
        
        break;
      case 'DELTA_DSG_LIST_REQUEST':
        newState = Object.assign({}, previousState, {
          loading: true,
          errorFetchingList: false
        });

        break;
      case 'DELTA_DSG_LIST_FAILURE':
        newState = Object.assign({}, previousState, {
          data: undefined,
          loading: false,
          errorFetchingList: true
        });
        break;
  
      case 'DELTA_DSG_LIST_STATE':
        newState = Object.assign({}, previousState, {
          listState: action.payload.listState
        });
        break;
      
      case 'SAVE_WORKING_CODEX_INFO':
        // whenever codex info is updated, we want to reset the pages to 0
        const {listState} = previousState;
        if (listState)
          listState.page = 0;
        newState = Object.assign({}, previousState, {listState});
        break;
  
      case 'RESET_ERROR_DELTA_DSG_LIST':
        newState = Object.assign({}, previousState, {
          errorFetchingList: false
        });
        break;
      default:
        break;
    }
  
    return newState;
  }