import React, { Component } from 'react';

import DismissableModal from '../DismissableModal';
import { PawConstants } from '../../lookup/PawConstants';

import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';

import './PawStatus.css';
import { FormSelect } from 'react-bootstrap';

class RejectPaw extends Component {

  state = {
    comments: "",
    rejectionReasons: this.props.rejectionReasons,
    pawRejectionReason: "",
    failure: false,
    isRejectionReasonSelected: false,
    isMandatory: false,
    targetOptions: this.props.targetOptions,
    pawRejectionTargetDrugs: [],
    rejectionReasonsToNotConsiderTargetDrug: this.props.rejectionReasonsToNotConsiderTargetDrug,
    selectTargets: false,
    isTargetSelected: false,
    wfId: this.props.wfId
  }

  rejectSwitch(closeModal) {
    if (this.state.selectTargets && this.state.targetOptions.length === 1) {
      this.state.pawRejectionTargetDrugs.push(this.state.targetOptions[0].dynamicSwitchOptionId);
    }


    if (this.state.isRejectionReasonSelected && (!this.state.selectTargets || (this.state.selectTargets &&
      this.state.pawRejectionTargetDrugs && this.state.pawRejectionTargetDrugs.length > 0)) && ((this.state.isMandatory && this.state.comments) || !this.state.isMandatory)) {
      this.props.attemptStatusUpdate(this.props.wfId, PawConstants.status.Rejected, this.state.pawRejectionReason, this.state.comments);
      closeModal();
    }
  }

  setCheckboxValue = (e) => {
    const { value, checked } = e;

    if (checked) {
      this.state.pawRejectionTargetDrugs.push(value);
    } else {
      this.state.pawRejectionTargetDrugs.splice(
        this.state.pawRejectionTargetDrugs.indexOf(value), 1);
    }
    this.setState({ isTargetSelected: this.state.pawRejectionTargetDrugs.length === 0 ? false : true })
  };

  renderTargetOption = (t) => {
    return (
      <span>
        {t.faxDisplayName || t.medName}
        &nbsp;({t.medForm})
      </span>
    );
  };

  setRejectionReason = (e, otherOptionValueForPawRejectionReason, rejectionReasonsToNotConsiderTargetDrug) => {
    var selectTargets = false
    if (rejectionReasonsToNotConsiderTargetDrug.indexOf(e.target.value) < 0)
      selectTargets = true

    this.setState({
      pawRejectionReason: e.target.value,
      isRejectionReasonSelected: e.target.value ? true : false,
      isMandatory: e.target.value === otherOptionValueForPawRejectionReason ? true : false,
      selectTargets: selectTargets
    })
  };

  render() {

    const { comments, rejectionReasons, isRejectionReasonSelected, pawRejectionReason, targetOptions,
      rejectionReasonsToNotConsiderTargetDrug, selectTargets, isTargetSelected } = this.state;

    const modalRef = "rejectConfirmationModal";
    const otherOptionValueForPawRejectionReason = 'other';

    const customFooterContent = (
      <div>
        <Button id='switchdetails-btn-reject-cancel' variant="outline-dark" onClick={() => this.refs[modalRef].handleClose()}>Cancel</Button>
        <Button
          id='switchdetails-btn-reject'
          className="left-spacer"
          variant="danger"
          onClick={() => this.rejectSwitch(this.refs[modalRef].handleClose)}>
          Yes, reject this switch
        </Button>
      </div>
    );
    return (
      <DismissableModal
        ref={modalRef}
        customFooterContent={customFooterContent}
        openModalButtonText="Reject"
        openModalButtonStyle="danger"
        openModalButtonBlock={true}
        openModalButtonDisabled={this.props.disallowEditing}
        openModalButtonId='switchdetails-btn-reject-popup'
        openModalButtonClassName="btn-block"
        title="Reject Switch" >
        <p className="confirmation-text">
          You have selected to <b>reject</b> this switch.
        </p>
        <p className="text-muted">Once a switch is rejected, its status can't be changed and it won't be communicated to the prescriber.</p>
        <Form.Group >
          <Form.Label>Please select a reason for rejecting this switch</Form.Label>
          <FormSelect
            id='paw-reject-reason'
            value={pawRejectionReason}
            onChange={(e) => this.setRejectionReason(e, otherOptionValueForPawRejectionReason, rejectionReasonsToNotConsiderTargetDrug)}
          >
            <option value="">Select a rejection reason</option>
            {
              rejectionReasons.map((r, i) =>
                <option value={r} key={i}>{i + 1 + ' - ' + r}</option>)
            }
          </FormSelect>
          {
            !isRejectionReasonSelected ? <span className="text-danger smaller-feedback">Rejection reason is mandatory!</span> : <span className="text-success smaller-feedback">Looks good!</span>
          }
        </Form.Group>
        <Form.Group hidden={!selectTargets || targetOptions.length <= 1} >
          <Form.Label>Please select a target drug for rejecting this switch</Form.Label>
          {targetOptions.map((option, id) => (
            <div key={id}>
              {
                <Form.Check
                  id={`drug-for-switchrejection${id + 1
                    }`}
                  type="checkbox"
                  value={option.dynamicSwitchOptionId}
                  name="targets"
                  style={{ float: "left" }}
                  onChange={(e) =>
                    this.setCheckboxValue(e.target)
                  }
                />
              }
              {this.renderTargetOption(option)}
            </div>
          ))}
          {
            !isTargetSelected ? <span className="text-danger smaller-feedback">Select atleast one drug!</span> : <span className="text-success smaller-feedback">Looks good!</span>
          }

        </Form.Group>
        <Form.Group>
          <Form.Label>Comments</Form.Label>
          <Form.Control
            id='switchdetails-textarea-reject-reason'
            as="textarea"
            rows="2"
            value={comments}
            onChange={(e) => this.setState({ comments: e.target.value })} />
          <Form.Text className="text-muted">
            {`Notes or comments about this switch ${this.state.isMandatory ? '(mandatory)' : '(optional)'}`}
          </Form.Text>
        </Form.Group>
      </DismissableModal>
    );
  }
}

export default RejectPaw;
