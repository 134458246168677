import React, { Component } from "react";
import { MdBlock } from "react-icons/md";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getErrorMessage } from "services/UtilityService";
import Tooltip from "react-bootstrap/Tooltip";
import { showNotification } from "services/NotificationService";
import "assets/css/user.css";

class DisableUser extends Component {
  state = {
    errorMsg: null,
    userInfoId: this.props.userInfoId,
  };

  disableUser = () => {
    sendAuthenticatedAsyncRequest(
      `/user/disable/${this.state.userInfoId}`,
      "PUT",
      null,
      (r) => {
        this.setState({ asyncInProgress: false });
        showNotification({
          title: "Action successful!",
          message: "User disabled successfully.",
          position: "tc",
          type: "success",
        });
        
        this.props.handler();
      },
      (r) => {
        this.setState({ asyncInProgress: false });
        showNotification({
          title: "Error!",
          message:
            getErrorMessage(r.data) || getErrorMessage("UNABLE_TO_DISABLE_USER"),
          position: "tc",
          type: "error",
        });
      }
    );
  };

  render() {
    return (
      <OverlayTrigger overlay={<Tooltip>Disable User</Tooltip>}>
        <MdBlock
          className="user-action-button disable-user"
          size={20}
          onClick={() => {
            if (window.confirm("Are you sure you want to disable this user?")) {
              this.disableUser();
            }
          }}
        ></MdBlock>
      </OverlayTrigger>
    );
  }
}

export default DisableUser;
