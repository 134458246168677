import React, {Component} from 'react';

import ButtonWithPopover from '../ButtonWithPopover';
import {PawConstants} from '../../lookup/PawConstants';

import Button from 'react-bootstrap/Button';

import './PawStatus.css';

class ApprovePaw extends Component {

  acceptSwitch() {
    this.props.attemptStatusUpdate(this.props.wfId,PawConstants.status.Approved);
    document.body.click();
  }

  render() {
    const reference = "acceptConfirmation";

    return (
      <ButtonWithPopover
        placement= "top"
        customRef={reference+"bwp"}
        buttonVariant="success"
        buttonText="Approve"
        buttonIsBlock={true}
        buttonIsDisabled={this.props.disallowEditing}
        popoverTitle="Approve Switch"
        buttonId='switchdetails-btn-approve-popover'
        >
        <p className="confirmation-text">
          Do you want to <b>approve</b> this switch?
        </p>
        <div className="align-content-right">
          <Button id='switchdetails-btn-approve' className="left-spacer" variant="success" onClick={() => this.acceptSwitch()}>
            Approve
          </Button>
        </div>
      </ButtonWithPopover>
    );
  }
}

export default ApprovePaw;
