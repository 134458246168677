export const DEFAULT_FILTER='DEFAULT_FILTERS'
export const ALL_FILTER='ALL_FILTERS'
export const SWIM_STATES_FOR_SELECT = [
  { key: 'DEFAULT_FILTERS', value: 'DEFAULT_FILTERS'},
  { key: 'PAW_REVIEW', value: 'PAW_REVIEW'},
  { key: 'ACTIVE', value: 'ACTIVE'},
  { key: 'AWAITING_RESPONSE', value: 'AWAITING_RESPONSE'},
  { key: 'ACCEPTED', value: 'ACCEPTED'},
  { key: 'DECLINED', value: 'DECLINED'},
  { key: 'EXPIRED', value: 'EXPIRED'},
  { key: 'FILL_PENDING', value: 'FILL_PENDING'},
  { key: 'FILL', value: 'FILL'},
  { key: 'HOLD', value: 'HOLD'},
  { key: 'CLOSED', value: 'CLOSED'}
]
export const SWIM_STATES_FOR_CYCLE_SELECT = [
  { key: 'DEFAULT_FILTERS', value: 'DEFAULT_FILTERS'},
  { key: 'ACTIVE', value: 'ACTIVE'},
  { key: 'AWAITING_RESPONSE', value: 'AWAITING_RESPONSE'}
]
export const SWIM_STATUSES_FOR_CYCLE_SELECT = [
  { key: 'DEFAULT_FILTERS', value: 'DEFAULT_FILTERS'},
  { key: 'ACTIVE_DELAYED', value: 'ACTIVE_DELAYED'},
  { key: 'GENERATED_PDF', value: 'GENERATED_PDF'},
  { key: 'DELIVERED_PRESCRIBER_FAX', value: 'DELIVERED_PRESCRIBER_FAX'},
  { key: 'FAILED_PRESCRIBER_FAX', value: 'FAILED_PRESCRIBER_FAX'},
  { key: 'ERROR_PRESCRIBER_FAX', value: 'ERROR_PRESCRIBER_FAX'}
]
const DEFAULT_SWIM_CYCLE_STATE_FILTER_FRONTEND = { id: 'state', value: ['ACTIVE','AWAITING_RESPONSE'], isdefault: true };
const DEFAULT_SWIM_CYCLE_STATUS_FILTER_FRONTEND = { id: 'status', value: ['ACTIVE_DELAYED','GENERATED_PDF', 'DELIVERED_PRESCRIBER_FAX', 'FAILED_PRESCRIBER_FAX', 'ERROR_PRESCRIBER_FAX'], isdefault: true };
const DEFAULT_SWIM_CYCLE_FILTER = [
  { id: 'state', value: ['ACTIVE','AWAITING_RESPONSE'], isdefault: true },
  { id: 'status', value: ['ACTIVE_DELAYED','GENERATED_PDF', 'DELIVERED_PRESCRIBER_FAX', 'FAILED_PRESCRIBER_FAX', 'ERROR_PRESCRIBER_FAX'], isdefault: true }
];

const DEFAULT_SWIM_FILTER_FRONTEND ={ id: 'state', value: ['ACTIVE','ACCEPTED'], isdefault: true };
const DEFAULT_SWIM_FILTER = [{ id: 'state', value: ['ACTIVE','ACCEPTED'], isdefault: true }];

// Move to AgWS Constants
const DEFAULT_SWIM_SWITCH_FILTER_FRONTEND = { id: 'isSwimSwitch', value: true, isdefault: true };
const DEFAULT_SWIM_SWITCH_FILTER = [{ id: 'isSwimSwitch', value: true, isdefault: true }];
// END
 
export const SWIM_FILTER={
  defualt:DEFAULT_SWIM_FILTER,
  defualtFrontEnd:DEFAULT_SWIM_FILTER_FRONTEND,
}

// Move to AgWS Constants 
export const SWIM_SWITCH_FILTER={
  defualt:DEFAULT_SWIM_SWITCH_FILTER,
  defualtFrontEnd:DEFAULT_SWIM_SWITCH_FILTER_FRONTEND,
}
// END

export const SWIM_CYCLE_FILTER={
  default:DEFAULT_SWIM_CYCLE_FILTER,
  defaultStateFrontEnd:DEFAULT_SWIM_CYCLE_STATE_FILTER_FRONTEND,
  defaultStatusFrontEnd:DEFAULT_SWIM_CYCLE_STATUS_FILTER_FRONTEND
}

export const SWIM_STATUSES = {
  Active: 'Active',
  OnHold: 'OnHold',
  Completed: 'Completed'
}

export const SWIM_HISTORY_STATE = 'HISTORY';

export const SWIM_STATE = {
  InActive: 'inactive',
  History: 'ancient'
}

export const NOT_STARTED_STATUS_WITH_HISTORY_OF_CHUNK =' NOT_STARTED'

export const DEFAULT_SWIM_SORT = [ {id: "lastModifiedDate", desc: true, isDefault: true}];
// Move to AgWS Constants
export const DEFAULT_SWIM_SWITCH_SORT = [ {id: "lastModifiedDate", desc: true, isDefault: true}];
// END

export const TEMPLATE_TYPES = [
  {key: "Standard", value: 0},
  {key: "Controlled Substance", value: 1},
  {key: "EHR Mandated", value: 2},
]

export const PICKUP_OPTIONS_FOR_SELECT = [
  { key: null, value: 'All'},
  { key: true, value: 'Yes'},
  { key: false, value: 'No'},
]

export const SWITCH_CLOSE_REASONS_MAPPING = [
  { key: 'NO_PRESCRIBER_RESPONSE', value: 'No Prescriber Response' },
  { key: 'NO_PRESCRIBER_CONTACT', value: 'No Prescriber Contact' },
  { key: 'DUPLICATE_SWITCH', value: 'Duplicate Switch' }
]

export const SWITCH_CLOSE_REASONS = [
  'NO_PRESCRIBER_RESPONSE',
  'NO_PRESCRIBER_CONTACT',
  'DUPLICATE_SWITCH'
]

export const HIDE_FUTURE_TREATMENT_FIELD_REASONS = [
  'DUPLICATE_SWITCH'
]

export const FUTURE_SWITCH_TREATMENT_OPTIONS = [
  'DY- Declined, Plan Year',
  'DC-Declined, Close',
  'D3-Declined, 3 Months',
  'DO-Declined, Open'
]

export const SWIM_TASK_INDICATOR_FILTER = [
  {key: 'All', value: ''},
  {key: 'True', value: true},
  {key: 'False', value: false},
]

// Move to AgWS Constants
export const SWIM_SWITCH_INDICATOR_FILTER = [
  {key: null, value: 'All'},
  {key: true, value: 'Swim Switch'},
  {key: false, value: 'N/A'},
]

export const SWIM_SWITCH_ACTIVITY_TYPE_FILTER_FOR_SELECT = [
  { key: 'PHONE_TASK', value: 'PHONE_TASK'},
  { key: 'FAX_TASK', value: 'FAX_TASK'},
  { key: 'CALL', value: 'CALL'},
  { key: 'FAX_FAIL', value: 'FAX_FAIL'},
  { key: 'OPT_OUT', value: 'OPT_OUT'}
]
// END