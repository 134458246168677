import CAT_CONSTANTS from 'lookup/CatConstants';
import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MdSort } from 'react-icons/md';
import Select from 'react-select';

export default class DynamicSwitchOptionHeading extends Component {
  handleSortChange = (sortOption) => {
    this.props.handleSortChange(sortOption, null);
  }
  render() {
    return (<Row className='dynamic-switch-options-heading'>
      <Col md={12}>
        <div className='border-bottom'>
          <div className='list-controls'>
            <Row>
              <Col md={5} className='align-self-end'>
                <h4 className='mb-0'>Dynamic Switch Options</h4>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className='align-self-end'>
                {this.props.handleInactiveFilter ?

                  <div className='dsg-inactive-filter'>
                    <Form.Check
                      onChange={this.props.handleInactiveFilter}
                      label='Hide Inactive'
                      name='hide-inactive'
                      id={'hide-inactive'}
                      checked={this.props.hideInactive}
                      disabled={false}
                    />
                  </div>
                  : null}
              </Col>
              <Col md={3}>
                <div className='rm-tiny-top-label'>
                  <MdSort className="icon-displacement-fix"></MdSort>&nbsp; Sort By
                </div>
                <div>
                  <Select
                    name="dsg-sort"
                    isDisabled={false}
                    value={this.props.currentSort}
                    options={CAT_CONSTANTS.dynamicSwitchGroupListSortValues}
                    onChange={this.handleSortChange}
                  >
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
    )
  }
}