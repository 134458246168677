
export function Reducer(previousState, action) {
    let newState = Object.assign({}, previousState);
  
    switch (action.type) {
      case 'DRUG_SEARCH_SUCCESS':
        newState = Object.assign({}, previousState, {
          data: action.payload.records,
          pages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
          loading: false,
          errorFetchingList: false
        });
        
        break;
      case 'DRUG_SEARCH_REQUEST':
        newState = Object.assign({}, previousState, {
          loading: true,
          errorFetchingList: false
        });

        break;
      case 'DRUG_SEARCH_FAILURE':
        newState = Object.assign({}, previousState, {
          data: undefined,
          loading: false,
          errorFetchingList: true
        });
        break;
  
      case 'UPDATE_DRUG_LIST_STATE':
        newState = Object.assign({}, previousState, {
          page: action.payload.page,
          pageSize: action.payload.pageSize
        });
        break;
  
      case 'RESET_ERROR_MESSAGE_DRUG_LIST':
        newState = Object.assign({}, previousState, {
          errorFetchingList: false
        });
        break;
      default:
        break;
    }
  
    return newState;
  }