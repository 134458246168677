import React from 'react';

export const ErrorFallback = ({error}) => {
    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404"></div>
          <h1>Oops!</h1>
          <h2>Something went wrong</h2>
        </div>
      </div>
    )
  }