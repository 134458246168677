import React, { Component } from 'react';
import Card from "react-bootstrap/Card";

class ToggleIconLinkButton extends Component {
  render() {
    const { shouldDisplayFirstTag, toggleButton, firstTag, FirstIcon, secondTag, SecondIcon } = this.props;
    let { linkClassName } = this.props;
    linkClassName = linkClassName ? "link-button " + linkClassName : "link-button";
    return (
      shouldDisplayFirstTag ? (
        <Card.Link
          href="#0"
          className={linkClassName}
          onClick={toggleButton}
        >
          <FirstIcon />{` ${firstTag}`}
        </Card.Link>
      ) : SecondIcon ? (
        <Card.Link
          href="#0"
          className={linkClassName}
          onClick={toggleButton}
        >
          <SecondIcon />{` ${secondTag}`}
        </Card.Link>
      ) : null
    )
  }
}
export default ToggleIconLinkButton;