import React from "react";
import { toast } from "react-hot-toast";
import Colors from "lookup/Colors.json";
import { Col, Container, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const typeToColorMap = {
  error: {
    border: Colors["rm-danger"],
    text: Colors["rm-gray-dark"],
    background: Colors["rm-red-very-light"],
  },
  success: {
    border: Colors["rm-success"],
    text: Colors["rm-gray-dark"],
    background: Colors["rm-green-very-light"],
  },
  info: {
    border: Colors["rm-blue"],
    text: Colors["rm-gray-dark"],
    background: Colors["rm-blue-very-light"],
  },
  warning: {
    border: Colors["rm-warning"],
    text: Colors["rm-gray-dark"],
    background: Colors["rm-yellow-very-light"],
  },
};

const positionsMap = {
  tl: "top-left",
  tc: "top-center",
  tr: "top-right",
  bl: "bottom-left",
  bc: "bottom-center",
  br: "bottom-right",
};

const NotificationBody = (props) => {
  const { title, message, t } = props;
  return (
    <Container fluid className="p-0">
      <Row className="p-0" style={{borderBottom: "1px solid gray"}}>
        <Col md={12}>
          <div className="float-left">
            <b>{title}</b>
          </div>
          <div className="float-right">
            <MdClose onClick={() => toast.dismiss(t.id)} />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <div>{message}</div>
        </Col>
      </Row>
    </Container>
  );
};

export const showNotification = (notification) => {
  const { title, message, position, type, duration } = notification;
  toast((t) => <NotificationBody t={t} title={title} message={message} />, {
    style: {
      border: `2px solid ${typeToColorMap[type]["border"]}`,
      color: `${typeToColorMap[type]["text"]}`,
      backgroundColor: `${typeToColorMap[type]["background"]}`,
      padding: "4px",
    },
    position: positionsMap[position],
    duration: duration ? duration : 5000,
  });
};
