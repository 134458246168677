const USABILITY_CONTEXT = {
  RAW: "RAW",
  PAW: "PAW",
  SWIM: "SWIM",
  AW: "AW",
};

// That's just how enums are supposed to be in JS. Desi. 
export default Object.freeze(USABILITY_CONTEXT);  

