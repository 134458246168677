import React, { memo } from 'react';
import ToggleIconLinkButton from 'components/ToggleIconLinkButton';
import { MdAdd, MdCancel, MdEdit, MdRemove, MdSave, MdLibraryAdd, MdLibraryBooks } from 'react-icons/md';
import { VscCollapseAll, VscExpandAll } from 'react-icons/vsc';
import DynamicSwitchOptionDeleteModal from './DynamicSwitchOptionDeleteModal';
import DynamicSwitchGroupDeleteModal from './DynamicSwitchGroupDeleteModal';

const EditExpandButtons = ({
  showDetails = false,
  toggleShowDetails = () => { },
  allowEditing = false,
  isEditMode = false,
  handleSubmit = () => { },
  handleReset = () => { },
  handleEnableEditMode = () => { },
  onSuccessDelete = () => { },
  dsoId = null,
  name = '',
  dsgId = null,
  codexId = null,
  isMultiTargets = false,
  toggleMultiTargets = () => { },
  isMultiTargetsDisplayed = false,
}) => {

  return (
    <>
      {isMultiTargets ?
        <ToggleIconLinkButton
          shouldDisplayFirstTag={!isMultiTargetsDisplayed}
          toggleButton={toggleMultiTargets}
          FirstIcon={VscExpandAll}
          firstTag={"Show All"}
          SecondIcon={VscCollapseAll}
          secondTag={"Hide"}
        />
        : null}
      <ToggleIconLinkButton
        shouldDisplayFirstTag={showDetails}
        toggleButton={toggleShowDetails}
        FirstIcon={MdRemove}
        firstTag={"Collapse"}
        SecondIcon={MdAdd}
        secondTag={"Expand"}
      />
      {allowEditing ?
        <>
          {isEditMode ?
            <>
              <ToggleIconLinkButton
                shouldDisplayFirstTag={true}
                toggleButton={handleReset}
                FirstIcon={MdCancel}
                firstTag={"Cancel"}
                SecondIcon={null}
                secondTag={null}
              />
              <ToggleIconLinkButton
                shouldDisplayFirstTag={true}
                toggleButton={handleSubmit}
                FirstIcon={MdSave}
                firstTag={"Save"}
                SecondIcon={null}
                secondTag={null}
              />
            </>
            :
            <>
              {dsoId ?
                <DynamicSwitchOptionDeleteModal
                  onSuccessDelete={onSuccessDelete}
                  dsoId={dsoId}
                  medName={name}
                  dsgId={dsgId}
                  codexId={codexId}
                />
                : dsgId?
                <DynamicSwitchGroupDeleteModal
                  onSuccessDelete={onSuccessDelete}
                  dsgId={dsgId}
                  groupName={name}
                  codexId={codexId}
                /> : null
              }
              <ToggleIconLinkButton
                shouldDisplayFirstTag={true}
                toggleButton={handleEnableEditMode}
                FirstIcon={MdEdit}
                firstTag={"Edit"}
                SecondIcon={null}
                secondTag={null}
              />
            </>
          }
        </>
        :
        <></>
      }
    </>
  )
}

export default memo(EditExpandButtons);