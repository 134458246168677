import { RSAA } from 'redux-api-middleware';
import config from '../../../config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';

export const updateCycleList = (pageSize, page, sorted, filtered, resized, expanded) => {

  return {

    [RSAA]: {
      endpoint: `${config.apiRoot}/swim/cycle/list/open`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        {
          type: 'UPDATE_CYCLE_LIST_REQUEST',
          payload: { cycleListState: { pageSize, page, sorted, filtered, resized, expanded } }
        },
        'UPDATE_CYCLE_LIST_SUCCESS',
        'UPDATE_CYCLE_LIST_FAILURE'
      ]
    }
  };
};

export const updateSwitchList = (selectedCycleId, pageSize, page, sorted, filtered, resized, expanded) => {
  return {

    [RSAA]: {
      endpoint: `${config.apiRoot}/swim/cycle/${selectedCycleId}/switches`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        {
          type: 'UPDATE_SWITCHES_FOR_CYCLE_LIST_REQUEST',
          payload: { selectedCycleId: selectedCycleId, switchListState: { pageSize, page, sorted, filtered, resized, expanded } }
        },
        'UPDATE_SWITCHES_FOR_CYCLE_LIST_SUCCESS',
        'UPDATE_SWITCHES_FOR_CYCLE_LIST_FAILURE'
      ]
    }
  };
};

export const updateCycleListState = (cycleListState) => {
  return {
    type: "UPDATE_CYCLE_LIST_STATE",
    payload: { cycleListState }
  }
};

export const updateSwitchListState = (switchListState) => {
  return {
    type: "UPDATE_SWITCH_LIST_STATE",
    payload: { switchListState }
  }
};

export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_CYCLE_LIST"
  }
};
