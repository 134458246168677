import { RSAA } from 'redux-api-middleware';
import config from '../../config';
import {AUTH_TOKEN_HEADER_KEY, getAuthorizationToken} from '../../services/AuthService';

export const fetchMetrics = () => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/paw-group/metrics`,
      method: 'GET',
      headers: {'Content-Type':'application/json',[AUTH_TOKEN_HEADER_KEY] : getAuthorizationToken()},
      types: [
        'FETCH_METRICS_REQUEST',
        'FETCH_METRICS_SUCCESS',
        'FETCH_METRICS_FAILURE'
      ]
    }
  };
};

export const resetMetricsFetcherStatus = () => {
  return {
    type: 'METRICS_FETCHER_STATUS_RESET'
  }
}