import EditDynamicSwitchGroupDetails from 'components/EditDynamicSwitchGroupDetails';
import ToggleIconLinkButton from 'components/ToggleIconLinkButton';
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import React, { Component } from 'react';
import { Badge, Card, Col, Collapse, Row } from 'react-bootstrap';
import { showNotification } from 'services/NotificationService';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from "react-router-dom";
import EditExpandButtons from './EditExpandButtons';
import * as Yup from "yup";
import CAT_CONSTANTS from 'lookup/CatConstants';
import { updateDynamicSwitchGroup } from 'views/Cat/DynamicSwitchGroupDetails/DynamicSwitchGroupDetails.actions';
import { MdNoteAdd } from 'react-icons/md';
import cloneDeep from 'lodash/cloneDeep';
import { BooleanDeltaField, DescriptionDeltaField, getDeltaBoxClassName } from '../../commons/fields/DeltaFieldUtils';
import DrugDeltaIcon from './DrugDeltaIcon';

const DRUG_GROUP_SCHEMA = Yup.object().shape({
  dynamicSwitchGroupName: Yup.string()
    .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255)
    .required(),
  dynamicSwitchGroupClassName: Yup.string()
    .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255),
  dynamicSwitchGroupSubClassName: Yup.string()
    .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255),
  additionalClassName: Yup.string()
    .max(255, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 255),
  pharmacistNotes: Yup.string()
    .max(1024, CAT_CONSTANTS.fieldMaxLengthValidationPrefix + 1024),
  isActive: Yup.boolean().required()
});

class DynamicSwitchGroupBox extends Component {
  constructor(props) {
    super(props);

    this.dsgEditFormRef = React.createRef();
    this.state = {
      showDetails: false,
      groupIsDirty: false,
      isEditMode: false,
      showNotesTextArea: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.allowEditing && prevProps.allowEditing) {
      if (this.dsgEditFormRef.current) this.dsgEditFormRef.current.resetForm();
      this.setState({ isEditMode: false, groupIsDirty: false });
    }
  }

  toggleShowDetails = () => {
    const { showDetails } = this.state;
    this.setState({ showDetails: !showDetails });
  }

  handleEnableEditMode = () => {
    this.setState({ isEditMode: true, showDetails: true, groupIsDirty: true });
  }

  handleCancelEditMode = () => {
    this.setState({ isEditMode: false, showDetails: false, showNotesTextArea: false, groupIsDirty: false });
  }


  handleShowNotesTextArea = () => {
    this.setState({ showNotesTextArea: true });
  }

  handleSaveEditMode = (updatedDynamicSwitchGroup, setSubmitting) => {
    const { workingCodexVersionId, dynamicSwitchGroup } = this.props;
    this.setState({ isEditMode: false, showDetails: false, showNotesTextArea: false, groupIsDirty: false });
    if (updatedDynamicSwitchGroup.dynamicSwitchGroupName === dynamicSwitchGroup.dynamicSwitchGroupName &&
      updatedDynamicSwitchGroup.isActive === dynamicSwitchGroup.isActive &&
      (updatedDynamicSwitchGroup.pharmacistNotes === dynamicSwitchGroup.pharmacistNotes)) {
        showNotification({
          title: 'No Change Detected!',
          message: `You are trying to save without making any update.`,
          position: 'tr',
          autoDismiss: 5,
          type: "warning"
        });
      return;
    }
    let itemTypes = dynamicSwitchGroup.dynamicSwitchOptions ? new Set(dynamicSwitchGroup.dynamicSwitchOptions.map(x => x.itemType)) : new Set();
    let areAllDsoPresent = itemTypes.size >= 2 ? true : false;
    if(updatedDynamicSwitchGroup.isActive && !dynamicSwitchGroup.isActive && !areAllDsoPresent) {
      showNotification({
        title: 'Cannot mark this Group active!',
        message: `There should be atleast one switch and one target to make this group active`,
        position: 'tr',
        autoDismiss: 5,
        type: "error"
      });
      return;
    }
    this.props.updateDynamicSwitchGroup(workingCodexVersionId, dynamicSwitchGroup.dynamicSwitchGroupId, updatedDynamicSwitchGroup);
    setSubmitting(false);
  }

  submitDynamicSwitchGroup = (values, { setSubmitting }) => {
    const { dynamicSwitchGroup } = this.props;
    const updatedDynamicSwitchGroup = cloneDeep(dynamicSwitchGroup);
    updatedDynamicSwitchGroup.dynamicSwitchGroupName = values.dynamicSwitchGroupName;
    updatedDynamicSwitchGroup.isActive = values.isActive;
    updatedDynamicSwitchGroup.pharmacistNotes = values.pharmacistNotes;
    this.handleSaveEditMode(updatedDynamicSwitchGroup, setSubmitting);
  }

  setIsActiveValue = (values, e, handleBlur) => {
    values.isActive = !values.isActive;
    handleBlur(e);
  };

  isFieldRequired = (key) => {
    return DRUG_GROUP_SCHEMA.fields[key]
      ? DRUG_GROUP_SCHEMA.fields[key].exclusiveTests.required
      : false;
  }

  renderCell = (fieldName, className) => {
    const { dynamicSwitchGroup, oldDynamicSwitchGroup } = this.props;
    const isUpdated = oldDynamicSwitchGroup && !dynamicSwitchGroup.isDeleted ? true : false;
    const newValue = dynamicSwitchGroup && dynamicSwitchGroup[fieldName] ? dynamicSwitchGroup[fieldName] : null;
    const oldValue = oldDynamicSwitchGroup && oldDynamicSwitchGroup[fieldName] ? oldDynamicSwitchGroup[fieldName] : null;
    return <DescriptionDeltaField
      key={dynamicSwitchGroup.dynamicSwitchGroupId + fieldName}
      oldField={oldValue}
      newField={newValue}
      className={className}
      isUpdated={isUpdated} />
  }

  renderBoolCell = (fieldName, className) => {
    const { dynamicSwitchGroup, oldDynamicSwitchGroup } = this.props;
    const isUpdated = oldDynamicSwitchGroup && !dynamicSwitchGroup.isDeleted ? true : false;
    const newValue = dynamicSwitchGroup ? dynamicSwitchGroup[fieldName] : null;
    const oldValue = oldDynamicSwitchGroup ? oldDynamicSwitchGroup[fieldName] : null;
    return <BooleanDeltaField
      key={dynamicSwitchGroup.dynamicSwitchGroupId + fieldName}
      oldField={oldValue}
      newField={newValue}
      className={className}
      isUpdated={isUpdated} />
  }

  getClassName = () => {
    const { showDelta, dynamicSwitchGroup, oldDynamicSwitchGroup } = this.props;
    const isChanged = this.getIsDsgChanged();
    const isOldDrugPresent = oldDynamicSwitchGroup ? true : false
    const isNewDrugDeleted = dynamicSwitchGroup && dynamicSwitchGroup.isDeleted ? true : false;
    return getDeltaBoxClassName(showDelta, isChanged, isOldDrugPresent, isNewDrugDeleted)
  }

  getIsDsgChanged = () => {
    const { dynamicSwitchGroup, oldDynamicSwitchGroup, showDelta } = this.props;
    if (!showDelta || !oldDynamicSwitchGroup)
      return false;
    let dsgModifiedFields = Object.keys(DRUG_GROUP_SCHEMA.fields);
    for (let i = 0; i < dsgModifiedFields.length; i++) {
      if (dynamicSwitchGroup && oldDynamicSwitchGroup &&
        dynamicSwitchGroup[dsgModifiedFields[i]] != oldDynamicSwitchGroup[dsgModifiedFields[i]] &&
        (dynamicSwitchGroup[dsgModifiedFields[i]] || oldDynamicSwitchGroup[dsgModifiedFields[i]])) {
        return true;
      }
    }
    return false;
  }

  renderIcon = () => {
    const className = this.getClassName();
    return <DrugDeltaIcon
      showDelta={true}
      className={className}
    />
  }

  refreshList = () => {
    this.props.history.push("/cat/codex/dsg");
  }

  render() {
    const { dynamicSwitchGroup, showDelta } = this.props;
    const { showDetails, groupIsDirty, isEditMode, showNotesTextArea } = this.state;
    const isDsgNameRequired = this.isFieldRequired("dynamicSwitchGroupName");
    const isPharmacistsNotesRequired = this.isFieldRequired("pharmacistNotes")
    let mainClass = this.getClassName();
    const searchParams = new URLSearchParams(window.location.search);
    const enableEditing = !searchParams.has('showDisplayCodexBar');
    mainClass = mainClass ? mainClass + "-card-border" : ""
    return (
      <Row>
        <Col md={12}>
          <Formik
            initialValues={{
              dynamicSwitchGroupId: dynamicSwitchGroup.dynamicSwitchGroupId || "",
              dynamicSwitchGroupName: dynamicSwitchGroup.dynamicSwitchGroupName || "",
              dynamicSwitchGroupClassName: dynamicSwitchGroup.dynamicSwitchGroupClassName || "",
              dynamicSwitchGroupSubClassName: dynamicSwitchGroup.dynamicSwitchGroupSubClassName || "",
              additionalClassName: dynamicSwitchGroup.additionalClassName || "",
              pharmacistNotes: dynamicSwitchGroup.pharmacistNotes || "",
              isActive: dynamicSwitchGroup.isActive || false,
            }}
            validationSchema={DRUG_GROUP_SCHEMA}
            onSubmit={this.submitDynamicSwitchGroup}
            onReset={this.handleCancelEditMode}
            innerRef={this.dsgEditFormRef}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, handleReset }) => (
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                  <Col md={12}>
                    <Card className={'drug-group-box ' + mainClass}>
                      <Card.Body className={`dynamic-switch-group-name ${!showDetails ? 'card-body-padding-override' : ''}`} 
                        style={{ borderColor: "#321323", borderRadius: 2, paddingTop: 5 }}>
                        <Card.Title className={!showDetails ? 'no-margin' : ''}>
                          {showDelta ? null : <EditDynamicSwitchGroupDetails />}
                          <div className='rm-xs-top-label pb-1'>
                            Dynamic Switch Group</div>
                          <span className={"drug-title"}>{this.renderIcon()}{dynamicSwitchGroup.dynamicSwitchGroupName}</span>
                          &nbsp;
                          <Badge className='drug-option-title-badge' bg={dynamicSwitchGroup.isActive ? 'success' : 'warning'} pill>{dynamicSwitchGroup.isActive ? 'Active' : 'Inactive'}</Badge>
                          &nbsp;
                          {
                            groupIsDirty
                              ? <Badge className='drug-option-title-badge' bg='danger' pill>Updating</Badge>
                              : null
                          }
                          <div className='pull-right drug-title-link-container'>
                            <EditExpandButtons
                              showDetails={showDetails}
                              toggleShowDetails={this.toggleShowDetails}
                              allowEditing={this.props.allowEditing && enableEditing}
                              isEditMode={isEditMode}
                              handleSubmit={handleSubmit}
                              handleReset={handleReset}
                              handleEnableEditMode={this.handleEnableEditMode}
                              onSuccessDelete={this.refreshList}
                              dsgId={dynamicSwitchGroup.dynamicSwitchGroupId}
                              name={dynamicSwitchGroup.dynamicSwitchGroupName}
                              codexId={this.props.workingCodexVersionId}
                            />
                          </div>
                        </Card.Title>
                        <div className='border-top mt-1'></div>
                        <Collapse in={showDetails}>
                          <div className="small-top-spacer">
                            <Row >
                              <Col md={12}>
                                {!isEditMode ?
                                  <>
                                    <div className='rm-small-top-label'>Group Name</div>
                                    {this.renderCell("dynamicSwitchGroupName", "")}
                                  </>
                                  :
                                  <Form.Group>
                                    <Form.Label className={isDsgNameRequired ? "rm-required-field" : ""} >
                                      Group Name
                                    </Form.Label>
                                    <Form.Control
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.dynamicSwitchGroupName}
                                      name="dynamicSwitchGroupName"
                                      id="drug-group-name"
                                      disabled={false}
                                    />
                                    <Form.Text id="drug-group-name-error-msg" className="text-small text-danger" >
                                      {errors.dynamicSwitchGroupName && touched.dynamicSwitchGroupName && errors.dynamicSwitchGroupName}
                                    </Form.Text>
                                  </Form.Group>
                                }
                              </Col>
                              <Col md={6}>
                                {!isEditMode ?
                                  <>
                                    <div className='rm-small-top-label'>Active</div>
                                    {this.renderBoolCell("isActive", "")}
                                  </>
                                  :
                                  <Form.Check
                                    inline
                                    onChange={(e) => this.setIsActiveValue(values, e, handleBlur)}
                                    onBlur={handleBlur}
                                    label={'Is Active'}
                                    name={`drug-group-is-active`}
                                    id={'drug-group-is-active'}
                                    // defaultChecked
                                    checked={values.isActive}
                                    disabled={!isEditMode}
                                  />
                                }
                              </Col>
                              <Col md={6}>
                                <div className='rm-small-top-label'>Additional Class Name</div>
                                {this.renderCell("additionalClassName", "")}
                              </Col>
                              <Col md={6}>
                                <div className='rm-small-top-label'>Group Sub Class Name</div>
                                {this.renderCell("dynamicSwitchGroupSubClassName", "")}
                              </Col>
                              <Col md={6}>
                                <div className='rm-small-top-label'>Group Class Name</div>
                                {this.renderCell("dynamicSwitchGroupClassName", "")}
                              </Col>
                            </Row>
                            <Row className="small-top-spacer">
                              <Col md={12}>
                                {isEditMode ?
                                  !dynamicSwitchGroup.pharmacistNotes && !showNotesTextArea ?
                                    <ToggleIconLinkButton
                                      shouldDisplayFirstTag={true}
                                      toggleButton={this.handleShowNotesTextArea}
                                      FirstIcon={MdNoteAdd}
                                      firstTag={"Add Note"}
                                      SecondIcon={null}
                                      secondTag={null}
                                    />
                                    :
                                    <Form.Group>
                                      <Form.Label className={isPharmacistsNotesRequired ? "rm-required-field" : ""} >
                                        Notes
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={1}
                                        placeholder="Notes or comments about this drug"
                                        onChange={handleChange}
                                        value={values.pharmacistNotes}
                                        disabled={false}
                                        name="pharmacistNotes"
                                        id="drug-group-form-pharmacist-notes"
                                        className='rm-switch-note'
                                      />
                                      <Form.Text id="drug-group-form-pharmacist-notes-error-msg" className="text-small text-danger" >
                                        {errors.pharmacistNotes && touched.pharmacistNotes && errors.pharmacistNotes}
                                      </Form.Text>
                                    </Form.Group>
                                  :
                                  dynamicSwitchGroup.pharmacistNotes ?
                                    <>
                                      <div className='rm-small-top-label'>Notes</div>
                                      {this.renderCell("pharmacistNotes", "")}
                                    </>
                                    : <></>
                                }
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  let allowEditing = state.codexVersion?.isWorkingVersionEditable && state.dynamicSwitchGroupDetails?.allowEditing && !state.dynamicSwitchGroupDetails?.showDelta ?
    state.dynamicSwitchGroupDetails.allowEditing : false;
  return {
    allowEditing: allowEditing,
    workingCodexVersionId: state.codexVersion ? state.codexVersion.codexVersionId : null,
    dynamicSwitchGroup: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.dynamicSwitchGroup : null,
    oldDynamicSwitchGroup: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.oldDynamicSwitchGroup : null,
    showDelta: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.showDelta : false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDynamicSwitchGroup: bindActionCreators(updateDynamicSwitchGroup, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicSwitchGroupBox);
