import React, { Component } from "react";

import { Modal, Button } from "react-bootstrap";

class AdminPopupDismissableModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: this.props.initialStateOpen || false,
    };
  }

  componentDidUpdate() {
    if (
      this.props.onComponentUpdated &&
      typeof this.props.onComponentUpdated === "function"
    )
      this.props.onComponentUpdated();
  }

  handleClose(event = null) {
    if (this.props.handleModalVisiblityFromParent) {
      this.props.setModalVisibility();
    } else {
      this.setState({ show: false });
      if (this.props.onClose && typeof this.props.onClose === "function")
        this.props.onClose();
      if (
        this.props.invertButton &&
        typeof this.props.invertButton === "function"
      )
        this.props.invertButton();
    }
  }

  handleShow() {
    if (this.props.handleModalVisiblityFromParent) {
      this.props.setModalVisibility();
    } else {
      this.setState({ show: true });
      if (this.props.onShow && typeof this.props.onShow === "function")
        this.props.onShow();
    }
  }

  render() {
    return (
      <Modal
        id={"modal " + (this.props.modalId ? this.props.modalId : "")}
        key={2}
        show={(this.props.handleModalVisiblityFromParent && this.props.isModalVisible) || this.state.show}
        onHide={this.handleClose}
        style={this.props.customModalStyle}
        size={this.props.size !== undefined ? this.props.size : null}
        dialogClassName={this.props.modalClassName}
        backdrop={
          this.props.preventCloseWhenClickedOutsideModal ? "static" : true
        }
        keyboard={
          this.props.keyboard !== undefined ? this.props.keyboard : true
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        {this.props.hideFooter ? null : (
          <Modal.Footer>
            {this.props.customFooterContent !== undefined ? (
              this.props.customFooterContent
            ) : (
              <Button onClick={this.handleClose} variant="outline-dark">
                Close
              </Button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default AdminPopupDismissableModal;
