import React, { Component } from "react";

import ButtonWithPopover from "../ButtonWithPopover";

import Button from "react-bootstrap/Button";

import "../PawStatus/PawStatus.css";

class DiscardFax extends Component {
  discardFax() {
    this.props.discardFax(this.props.faxId);
  }

  render() {
    const reference = "discardFax";

    return (
      <ButtonWithPopover
        placement="top"
        customRef={reference + "bwp"}
        buttonVariant="warning"
        buttonText="Discard Fax"
        buttonIsBlock={true}
        buttonIsDisabled={this.props.disallowEditing}
        popoverTitle="Discard Response Fax"
        buttonId="unprocessable-fax-details-btn-discard-popover"
      >
        <p className="confirmation-text">
          Do you want to <b>discard</b> this fax?
        </p>
        <div className="align-content-right">
          <Button
            id="switchdetails-btn-approve"
            className="left-spacer"
            variant="danger"
            onClick={() => this.discardFax()}
          >
            Discard
          </Button>
        </div>
      </ButtonWithPopover>
    );
  }
}

export default DiscardFax;
