import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const OpenModalButtonIcon = (props) => {

    const toolTipText = props.toolTipText;
    const icon = props.icon;

    return (
        <OverlayTrigger overlay={<Tooltip>{toolTipText}</Tooltip>} placement={props.placement ? props.placement : 'top'}>
          <div style={props.style ? props.style : null}> {icon} </div>
        </OverlayTrigger>
    )
}

export default OpenModalButtonIcon;