import { memo } from "react"
import Col from "react-bootstrap/Col";
import { DescriptionDeltaField  } from "../../../commons/fields/DeltaFieldUtils";
import TagField from "views/Cat/NewDrug/TagField";

const DrugOptionDaysSupply = ({
  isEditMode = false,
  showDelta = false,
  value = undefined,
  predecessorValue = undefined,
  isUpdated = false,
  error = undefined,
  setFieldValue = () => {},
}) => {
  const onChange = (days) => {
    let daysSupplyFilter = "";
    if (days !== "" || days === 0) {
      daysSupplyFilter = days.split(";").map(function (item) {
        return parseInt(item, 10);
      });
    } else {
      daysSupplyFilter = "";
    }
    setFieldValue('daysSupplyFiltered', daysSupplyFilter);
  }
  const daysError = (daysError) => {
    //shows only first error in Days Supply field
    let error = "";
    Array.isArray(daysError) ?
      error = daysError?.find(e => e != undefined)
      : error = daysError
    return error;
  }

  return (
    <Col md={12}>
      <div className="rm-small-top-label">
        Days Supply
      </div>
      {!isEditMode || showDelta ? (
        <>
          <DescriptionDeltaField
            newField={value?.toString().replace(/,/g, ' , ')}
            oldField={predecessorValue?.replace(/;/g, ' , ')}
            isUpdated={isUpdated}
            className={"days-supply"} />
        </>)
        :
        <div className={'days-supply-box col-md-4'}>
          <TagField
            value={value ? value.join(";") : ""}
            error={daysError(error)}
            onChange={onChange}
          />
        </div>
      }
    </Col>
  );
}

export default memo(DrugOptionDaysSupply);