import React, { Component } from 'react'

import KeyValueTable from 'components/KeyValueTable';
import DismissableModal from 'components/DismissableModal';
import { formatDateForDisplay } from 'services/MomentUtils';

export default class OriginalClaim extends Component {

  render() {
    const switchInfo = this.props.switchInfo;
    const claimInfo = switchInfo.rirClaimInfo;
    const pharmacyInfo = switchInfo.pharmaInfo;
    return (
      <DismissableModal
        openModalButtonText="Original Claim"
        openModalButtonBlock={true}
        title="Original Claim Information"
        openModalButtonStyle="primary"
      >
        <KeyValueTable
          // title="Claim"
          rows={[
            { label: "Filled on", value: claimInfo ? formatDateForDisplay(claimInfo.claimFillDate) : "N/A" },
            { label: "Claim Number", value: switchInfo.claimNumber },
            { label: "Claim Drug Name", value: switchInfo.claimDrugName },
            { label: "Claim Drug Strength", value: switchInfo.claimDrugStrength },
            { label: "Source Days Supply", value: `${switchInfo.sourceDaysSupply} days` },
            { label: "Created on", value: formatDateForDisplay(switchInfo.creationDate) },
            { label: "Source NDC Number", value: switchInfo.sourceNdcNumber },
            { label: "Target Controlled Substance", value: switchInfo.targetControlledSubstance },
            { label: "Prescription Number", value: claimInfo ? claimInfo.rxNumber : "N/A" },
            { label: "Drug Form", value: switchInfo ? switchInfo.claimDrugForm : "N/A" },
            { label: "Drug Indicator", value: switchInfo ? switchInfo.claimBrandGenericCode : "N/A" },
            { label: "Route", value: switchInfo ? switchInfo.claimDrugRoute : "N/A" },
            { label: "Units Quauntity Dispensed", value: claimInfo ? claimInfo.totalDispensedUnitQuantity : "N/A" },
            { label: "Total Cost", value: claimInfo ? claimInfo.totalGrossCost : "N/A" },
            { label: "Plan Cost", value: claimInfo ? claimInfo.totalNetCost : "N/A" },
            { label: "Member Cost", value: claimInfo && claimInfo.totalCopayAmount != 0 ? claimInfo.totalCopayAmount : (claimInfo ? claimInfo.totalCoinsuranceAmount : "N/A") },
            { label: "Pharmacy NABP code", value: pharmacyInfo ? pharmacyInfo.pharmacyGlobalId : "N/A" },
            { label: "Pharmacy NPI", value: pharmacyInfo ? pharmacyInfo.pharmacyNpi : "N/A" }
          ]} />
      </DismissableModal>
    )
  }
}
