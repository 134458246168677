import React, { Component } from 'react';
import '../Login.css';
import {Link} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

class LogoutCallback extends Component{
  state = {
  }

  componentDidMount() {
    localStorage.clear();
  }

  render() {

    return (
      <Container fluid style={{height: "100%", backgroundColor: "#e0e0e0"}}>
        <Row>
          <Col className="text-align-center huge-top-spacer" md={{ span: 4, offset: 4}}>
            <Card border='primary'>
              <Card.Body>
                <h2 >Logged out successfully!</h2>
                <div className="top-spacer">
                  <Link  to="/login" > Return to RxAssist Platform login page</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LogoutCallback;