import { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import Spinner from 'components/Spinner';
import _ from 'lodash';
import { ERROR, SUCCESS } from 'lookup/ResponseCodes';
import Container from 'react-bootstrap/Container';
import { bindActionCreators } from 'redux';
import * as ART from 'services/ApiRequestTrackerService';
import { getErrorMessage } from 'services/UtilityService';
import { resetDsgEventRequest } from 'views/Cat/DynamicSwitchGroupDetails/DynamicSwitchGroupDetails.actions';
import DynamicSwitchGroupBox from './DynamicSwitchGroupBox';
import DynamicSwitchOptions from './DynamicSwitchOptions';
import { IN_PROGRESS } from 'lookup/ResponseCodes';
import FloatingButton from 'components/FloatingButton';
import { showNotification } from 'services/NotificationService';

class DynamicSwitchBox extends Component {
  state = {
    apiRequestTracker: ART.init(true),
    
  }

  static getDerivedStateFromProps(props, state) {
    const { fetchDsgRequestStatus, updateDsgRequestStatus } = props;
    if (fetchDsgRequestStatus === ERROR)
      return { apiRequestTracker: ART.failure('ERROR_FETCHING_TARGET_GROUP') };
    else if (fetchDsgRequestStatus === SUCCESS) {
      props.resetDsgEventRequest();
      return { apiRequestTracker: ART.success() };
    } else if (fetchDsgRequestStatus == IN_PROGRESS) {
      return { apiRequestTracker: ART.init(true) };
    }
    else if (updateDsgRequestStatus === SUCCESS) {
      props.resetDsgEventRequest();
    }
    else if (updateDsgRequestStatus === ERROR) {
      showNotification({
        title: 'Something went wrong!',
        message: getErrorMessage('UNABLE_TO_UPDATE_DYNAMIC_SWITCH_GROUP'),
        position: 'tr',
        type: "error"
      });
    }

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(nextProps, this.props) && _.isEqual(nextState, this.state)) {
      return false;
    }
    return true;
  }

  addDrug = () => {
    this.props.history.push(`/cat/dynamic-switch-groups/${this.props.match.params.dynamicSwitchGroup}/add-new-drug`)
  }

  render() {
    const { apiRequestTracker } = this.state;
    const { allowEditing, showDelta } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    const enableEditing = !searchParams.has('showDisplayCodexBar');

    // on a good day, this will become a HOC
    if (apiRequestTracker.inProgress)
      return <Spinner id='dsg-box-spinner' />
    else if (apiRequestTracker.error)
      return apiRequestTracker.message;
    else {
      return (
        <Container>
          <DynamicSwitchGroupBox />
          <DynamicSwitchOptions showDelta={showDelta} />
          {allowEditing && enableEditing ? <FloatingButton name={"Add new Drug"} onClick={this.addDrug} /> : null}
        </Container>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let allowEditing = state.codexVersion?.isWorkingVersionEditable && state.dynamicSwitchGroupDetails?.allowEditing && !state.dynamicSwitchGroupDetails?.showDelta ?
    state.dynamicSwitchGroupDetails.allowEditing : false;
  return {
    fetchDsgRequestStatus: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.fetchDsgRequestStatus : null,
    updateDsgRequestStatus: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.updateDsgRequestStatus : null,
    allowEditing: allowEditing,
    showDelta: state.dynamicSwitchGroupDetails?.showDelta,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetDsgEventRequest: bindActionCreators(resetDsgEventRequest, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DynamicSwitchBox));
