import React, { useEffect, useState } from "react";
import SwitchTimelineInfo from "components/SwitchDetails/SwitchTimelineInfo";
import SwitchTimeline from "components/SwitchDetails/SwitchTimeline";
import Spinner from "components/Spinner";
import { prepareTimelineForDisplay } from "views/Switch/SwitchDetails/SwitchDetails";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { showNotification } from "services/NotificationService";
import { getErrorMessage } from "services/UtilityService";

const ExpandSwitchHistory = (props) => {
    const { activityId } = props;
    const [loading, setLoading] = useState(true);
    const [timeline, setTimeline] = useState(null);
    const [workflowRestarted, setWorkflowRestarted] = useState(false);
    const switchData = props.switchData;
    const wfItemId = switchData.wfItemId;
    const fillDetails = switchData.fillDetails;

    useEffect(() => {
        sendAuthenticatedAsyncRequest(
            `/agent-workspace/${activityId}/switch-timeline`,
            "GET",
            null,
            (r) => {
                setLoading(false);
                setTimeline(prepareTimelineForDisplay(r.data.timeline));
                setWorkflowRestarted(r.data.timeline[0].history ? true : false);
            },
            (r) => {
                setLoading(false);
                showNotification({
                    title: "Error!",
                    message:
                        getErrorMessage(r.data) ||
                        getErrorMessage("UNABLE_TO_FETCH_SWITCH_TIMELINE"),
                    position: "tr",
                    type: "error",
                });
            }
        );
    }, []);

    return (
        <>
            <SwitchTimelineInfo
                state={switchData.state}
                status={switchData.status}
                wfItemId={switchData.wfItemId}
                workflowRestarted={workflowRestarted}
            />
            {loading ? (<Spinner />) : (
                <SwitchTimeline
                    activityId={activityId}
                    rawId={switchData.rawId}
                    wfItemId={wfItemId}
                    switchHistory={timeline}
                    fillDetails={fillDetails}
                    switchId={switchData.switchInfo.rirSwitchId}
                    switchStatus={switchData.status}
                    switchState={switchData.state}
                    isFromAW={props.isFromAW}
                    isAWAdmin={props.isAWAdmin}
                />
            )}
        </>
    );
};

export default ExpandSwitchHistory;