import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';
import { resetList } from './MemberList.slice';
export const getMemberListRequestAction = 'GET_MEMBER_LIST_REQUEST';
export const getMemberListSuccessAction = 'GET_MEMBER_LIST_SUCCESS';
export const getMemberListFailureAction = 'GET_MEMBER_LIST_FAILURE';


export const geMemberList = (pageSize, page, filteredData,dob,lastName) => (dispatch, getState) => {
    let filtered = JSON.parse(JSON.stringify(filteredData));
    const sorted=[{id: "memberName", desc: false, isDefault: true}]
    const rsaaObject = {
        [RSAA]: {
            endpoint: `${config.apiRoot}/member/list`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
            body: JSON.stringify({ pageSize, page, filtered,sorted }),
            types: [
                getMemberListRequestAction,
                {
                    type: getMemberListSuccessAction,
                    payload: async (action, state, res) => {
                        const data =await res.json();
                        return { dob, lastName, pageSize: pageSize, filtered: filteredData, ...data };
                    }
                },
                getMemberListFailureAction
            ],
        }
    }

    return dispatch(rsaaObject);

}
export const resetMemberList = () => (dispatch, getState) => {
    dispatch(resetList());
}