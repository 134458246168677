import React from "react";
import { connect } from "react-redux";
import TranscriberFaxPaging from "components/AgentWorkspace/TranscriberFaxPaging";
import TranscriberForm from "components/AgentWorkspace/TranscriberForm";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import {
  sendAuthenticatedAsyncRequest,
  sendAuthenticatedAsyncRequestWithBlobReturn,
} from "services/AsyncRequestService";
import Spinner from "react-bootstrap/Spinner";
import { TranscriptionConstants } from "lookup/TranscriptionConstants";
import { showNotification } from "services/NotificationService";
import { getErrorMessage } from "services/UtilityService";

class TranscriberDetails extends React.Component {
  constructor(props) {
    super(props);
    this.rerenderParentCallback = this.rerenderParentCallback.bind(this);
  }
  rerenderParentCallback() {
    this.forceUpdate();
  }

  onPageChange = (page) => {
    this.getFaxPageImage(this.state.faxId, page);
    this.setState({
      currentPage: page,
    });
  };
  convertToComponentStructureResponse = (incomingResponse) => {
    let formattedResponse = {};
    for (let page of incomingResponse[
      TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.pages
    ]) {
      formattedResponse[
        page[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.pageNo]
      ] = {};
      for (let section of page[
        TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.sections
      ]) {
        formattedResponse[
          page[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.pageNo]
        ][
          section[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.sectionName]
        ] = {};
        formattedResponse[
          page[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.pageNo]
        ][section[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.sectionName]][
          TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.selected
        ] =
          section[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.selected] ===
          null
            ? false
            : section[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.selected];

        for (let field of section[
          TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.fields
        ]) {
          formattedResponse[
            page[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.pageNo]
          ][
            section[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.sectionName]
          ][field[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.fieldName]] =
            field;
        }
      }
    }
    return formattedResponse;
  };

  convertToServerResponseFormat = (formattedResponse) => {
    let javaResponse = { pages: [] };

    let pageObj = {};
    for (let [pageNo, page] of Object.entries(formattedResponse)) {
      pageObj = { pageNo: parseInt(pageNo), sections: [] };
      for (let [sectionName, section] of Object.entries(page)) {
        let sectionObj = null;

        sectionObj = {
          sectionName: sectionName,
          fields: [],
          selected:
            section[TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.selected],
        };

        for (let [fieldName, field] of Object.entries(section)) {
          if (
            fieldName !==
            TranscriptionConstants.TRANSCRIBER_OBJECT_KEYS.selected
          ) {
            sectionObj.fields.push(field);
          }
        }
        pageObj.sections.push(sectionObj);
      }

      javaResponse.pages.push(pageObj);
    }
    return javaResponse;
  };

  state = {
    faxId: this.props.match.params.faxId,
    image: null, //this.props.image,
    formData: null,
    onPageChange: this.onPageChange,
    currentPage: 1,
    loading: 0,
    totalPages: 0, // assuming that the initial keys of our data are only pages
    errorStatus: false,
    errorMessage: "",
  };

  getFaxData = (faxId) => {
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/transcription-details/${faxId}`,
      "GET",
      null,
      (r) => {
        this.setState((prevState) => ({
          formData: this.convertToComponentStructureResponse(r.data),
          loading: prevState.loading + 1,
          totalPages: r.data.pages.length,
        }));
      },
      (r) =>{
        this.setState({
          errorStatus: true,
          errorMessage: "unable to fetch transcription form",
        });
        showNotification({
          title: "Error!",
          message:
            getErrorMessage(r.data) || getErrorMessage("Unable to get the form"),
          position: "tr",
          type: "error",
        });
      }
    );
  };

  async blobToJson(blob) {
    return await new Response(blob).json();
  }

  onFetchSuccess(response) {
    let blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    let image = window.URL.createObjectURL(blob);
    this.setState((prevState) => ({
      image: image,
      loading: prevState.loading + 1,
    }));
  }

  onFetchFailure(response) {
    this.setState({
      errorStatus: true,
      errorMessage: "unable to fetch fax image",
    });
    showNotification({
      title: "Error!",
      message:
        getErrorMessage(r.data) || getErrorMessage("Unable to fetch image"),
      position: "tr",
      type: "error",
    });
  }

  getFaxPageImage = (faxId, currentPage) => {
    sendAuthenticatedAsyncRequestWithBlobReturn(
      `/agent-workspace/transcription-details/${faxId}/fax/${currentPage}`,
      "GET",
      null,
      (r) => this.onFetchSuccess(r),
      (r) => this.onFetchFailure(r)
    );
  };

  sendUpdatedResponse = (faxId, updatedResponse) => {
    let serverResponse = this.convertToServerResponseFormat(this.state.formData);
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/save-transcription/${faxId}`,
      "POST",
      serverResponse,
      (r) => {
        showNotification({
          title: "Action successful!",
          message: "Form Saved successfully.",
          position: "tc",
          type: "success",
        });
        this.props.history.goBack();
        this.props.history.goBack();
      },
      (r) =>{
        this.setState({
          errorStatus: true,
          errorMessage: "unable to save the form",
        }),
        showNotification({
          title: "Error!",
          message:
            getErrorMessage(r.data) || getErrorMessage("Unable to save the form"),
          position: "tr",
          type: "error",
        });
      }
    );
  };
  componentDidMount() {
    this.getFaxData(this.state.faxId);
    this.getFaxPageImage(this.state.faxId, this.state.currentPage);
  }

  unSelectOtherTarget = (target) => {
    let newFormData = this.state.formData;
    for (let [pageNo, page] of Object.entries(newFormData)) {
      for (let [sectionName, section] of Object.entries(page)) {
          if (sectionName.includes('target')){
            if (sectionName !== target){
              section.selected = false;
            }
            else{
              section.selected = true;
            }
          }
        
      }
    }
    this.setState({formData: newFormData});
  }

  updateSection = (key, value) => {
    this.setState(prevState => ({formData: {...prevState.formData, 
      [this.state.currentPage]: {
        ...prevState.formData[this.state.currentPage],
        [key]: value
      }
    }}))
    // if (key.includes('target') && value.selected){
    //   this.unSelectOtherTarget(key);
    // }
  };


  render() {
    const {
      image,
      formData,
      currentPage,
      totalPages,
      onPageChange,
      faxId,
      loading,
      errorStatus,
      errorMessage,
    } = this.state;
    if (loading > 1 && !errorStatus) {
      return (
        <Container fluid>
          <Alert variant="primary">Fax Id: {faxId}</Alert>
          <TranscriberForm
            image={image}
            pageData={formData[currentPage]}
            updateSection={this.updateSection}
            rerenderParentCallback={this.rerenderParentCallback}
            faxId={faxId}
            formData={formData}
            sendUpdatedResponse={this.sendUpdatedResponse}
          />

          <TranscriberFaxPaging
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </Container>
      );
    } else if (errorStatus) {
      return (
        <Alert key="error" variant="danger">
          {errorMessage}
        </Alert>
      );
    } else {
      return <Spinner animation="border" role="status" />;
    }
  }
}

export default connect()(withRouter(TranscriberDetails));
