import { FilterTypes, ColumnTypes } from "commons/table/Constants";
import { WrapTextAndCenterAlign } from "components/WrapText";
import { getLoggedInUser } from "services/AuthService";
import { formatDateWithTime } from "services/MomentUtils";
import ROLES from "rbac/Roles.json";

export const DEFAULT_PAGE_SIZE = 15;
export const FIRST_PAGE_NUMBER = 0;
export const DEFAULT_SORT = [
  { id: "lastModifiedDate", desc: true, isDefault: true },
];
export const DEFAULT_FILTER = [];
export const EXTERNAL_LINKS = [
  {
    url:
      "https://razormetrics.atlassian.net/wiki/spaces/~6362e91b548f1fe6f0c7f1c8/pages/2348122152/Reports+Wiki#Client-Reporting",
    title: "Reports Wiki",
  },
  {
    url: "https://razormetrics.atlassian.net/wiki/x/AQD3kQ",
    title: "Reporting Process",
  },
];

const renderDateCell = (date) => {
  return (
    <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {formatDateWithTime(date)}
    </span>
  );
};

export const COLUMNS = [
  {
    header: "Dashboard Name",
    accessor: "dashboardName",
    accessorKey: "dashboardName",
    width: 100,
    show: true,
    enableSorting: true,
    enableColumnFilter: true,
    columnType: ColumnTypes.Text,
    filterType: FilterTypes.SearchableInput,
  },
  {
    header: "Description",
    accessor: "description",
    accessorKey: "description",
    width: 200,
    show: true,
    enableSorting: false,
    enableColumnFilter: false,
    columnType: ColumnTypes.Text,
  },
  {
    header: "Last Modified Date",
    accessor: "lastModifiedDate",
    accessorKey: "lastModifiedDate",
    width: 100,
    show: true,
    enableSorting: true,
    enableColumnFilter: false,
    columnType: ColumnTypes.Custom,
    customComponent: (value) => {
      return renderDateCell(value);
    },
  },
  {
    header: "Confluence Document Link",
    accessor: "confluenceDocumentLink",
    accessorKey: "confluenceDocumentLink",
    width: 100,
    show: true,
    enableSorting: false,
    enableColumnFilter: false,
    columnType: ColumnTypes.Custom,
    customComponent: (value, row) => {
      return value ? (
        <a href={value} target="_blank" rel="noopener noreferrer">
          <WrapTextAndCenterAlign>Link to Confluence</WrapTextAndCenterAlign>
        </a>
      ) : null;
    },
  },
];

export function isLoggedInUserAnalyticsAdmin() {
  return getLoggedInUser().authorities.includes(ROLES.ANALYTICS_WRITER);
}
