import React, { Component } from 'react'

import DismissableModal from 'components/DismissableModal';
import KeyValueTable from 'components/KeyValueTable';

import { parseLowerCamelCase } from 'services/UtilityService';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { formatDateForDisplay } from '../../services/MomentUtils';

export default class ProtectedHealthInformation extends Component {
  state = {
    phi: null,
    loading: false
  }

  fetchPhi (globalSwitchId) {
    if (this.state.loading !== true) {
      this.setState({loading: true});
      sendAuthenticatedAsyncRequest(
        `/phi?globalSwitchId=${globalSwitchId}`,
        "GET", 
        null,
        (r) => this.setState({phi: r.data, loading: false}),
        (r) => this.setState({phi: undefined, loading: false})
      );
    }
  }
  render() {
    const {phi, loading} = this.state;
    return (
      <DismissableModal 
        openModalButtonText="Show PHI"
        openModalButtonStyle="outline-warning"
        openModalButtonSize={this.props.openButtonMediumSize ? '' : 'sm'}
        openModalButtonBlock={this.props.openButtonBlock}
        openModalButtonTestId='btn-member-show-phi'
        title="Protected Health Information for Patient"
        centered
        onShow={() => this.fetchPhi(this.props.globalSwitchId)}
        onClose={() => setTimeout(() => this.setState({phi: null}), 200)}
        >
      
        {
          loading === true
          ? "Loading PHI ..." 
          : phi === undefined
            ? "Unable to access PHI"
            : phi === null  
              ? "PHI Cleared"
              : <KeyValueTable rows={ 
                    Object.keys(phi).map( k => {
                    return parseLowerCamelCase(k)==="Date Of Birth" ?
                    ({label: parseLowerCamelCase(k), value: formatDateForDisplay(phi[k])}) : 
                    ({label: parseLowerCamelCase(k), value: phi[k]}) 
              })} />
        }
      </DismissableModal>
    )
  }
}
