import { createSlice } from '@reduxjs/toolkit';
import { getUserFavoriteDashboardsRequestAction, getUserFavoriteDashboardsSuccessAction, getUserFavoriteDashboardsFailureAction } from './UserFavoriteDashboards.actions';
export const INITAL_STATE = {
    data: [],
    totalPages: 0,
    pageSize: 20,
    page: 0,
    error: false,
    loading: false,
    totalElements: "Loading",
    filtered: [],
    isDefaultFilter: true,
    resized: [],
    expanded: {}
}

export const userFavoriteDashboardsSlice = createSlice({
    name: "userFavoriteDashboards",
    initialState: INITAL_STATE,
    reducers: {
      getUserFavoriteDashboardsSuccess: (state, action) => {
        state.data = action.payload.records;
        state.totalPages = action.payload.totalPages;
        state.pageSize = action.payload.pageSize;
        state.page = action.payload.page;
        state.sorted = action.payload.sorted;
        state.filtered = action.payload.filtered;
        state.totalElements = action.payload.totalElements;
        state.error = false;
        state.loading = false;
      },
      getUserFavoriteDashboardsFailure: (state, action) => {
        state.loading = false;
        state.error = true;
        state.totalElements = "Failed";
        state.data = [];
      },
      getUserFavoriteDashboardsLoading: (state, action) => {
        state.loading = true;
        state.isDefaultFilter = false;
        state.totalElements = "Loading";
      }
    },
    extraReducers: (builder) => {
      builder
        // Handle the pending, fulfilled, and rejected states of the "table of contents" RSAA action.
        .addCase(getUserFavoriteDashboardsRequestAction, (state, action) => {
          userFavoriteDashboardsSlice.caseReducers.getUserFavoriteDashboardsLoading(state, action);
        })
        .addCase(getUserFavoriteDashboardsSuccessAction, (state, action) => {
          userFavoriteDashboardsSlice.caseReducers.getUserFavoriteDashboardsSuccess(state, action);
        })
        .addCase(getUserFavoriteDashboardsFailureAction, (state, action) => {
          userFavoriteDashboardsSlice.caseReducers.getUserFavoriteDashboardsFailure(state, action);
        })
  
    }
  });

export const { getUserFavoriteDashboardsSuccess, getUserFavoriteDashboardsFailure, getUserFavoriteDashboardsLoading } = userFavoriteDashboardsSlice.actions;
export const userFavoriteDashboardsState = (state) => state.userFavoriteDashboards;
export default userFavoriteDashboardsSlice.reducer;