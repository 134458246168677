import { logoutUser, updateAccessToken } from 'services/AuthService';
import axios from 'axios';
import config from 'config';

export function sendAsyncRequest(uri, method, data, onSuccessCallback, onFailureCallback){
	axios ({
		method: method,
		url: url(uri),
		data: data
	})
	.then(function (response) {
   	onSuccessCallback(response);
	})
	.catch(function (error) {
		onFailureCallback(error);
	});
}

function asyncAuthRequest(uri, method, data, onSuccessCallback, onFailureCallback, responseType){
	const authorization_token = localStorage.getItem("authorization_token");
	
	if (authorization_token !== null) {
		axios ({
			method: method,
			url: url(uri),
			data: data,
			responseType: responseType,
			headers: {'Authorization': authorization_token }
		})
		.then(function (response) {
	    	onSuccessCallback(response);
		})
		.catch(function (error) {
			if(error.response && error.response.status === 401) {
				refreshToken(uri, method, data, onSuccessCallback, onFailureCallback, responseType);
			} else {
				if (typeof onFailureCallback === 'function')
					onFailureCallback(error.response || error);
				else
					console.log(error.response);
			}
		});
	}
}

export function sendAuthenticatedAsyncRequest(uri, method, data, onSuccessCallback, onFailureCallback){
	asyncAuthRequest(uri, method, data, onSuccessCallback, onFailureCallback, null);
}

export function sendAuthenticatedAsyncRequestWithBlobReturn(uri, method, data, onSuccessCallback, onFailureCallback){
	asyncAuthRequest(uri, method, data, onSuccessCallback, onFailureCallback, 'blob');
}

function refreshToken(uri, method, data, onSuccessCallback, onFailureCallback, responseType) {
	const refreshToken = localStorage.getItem("refresh_token");

	if (refreshToken !== null) {
		axios ({
			method: "GET",
			url: url(`/login/refresh-token?code=${refreshToken}`),
			data: null,
			responseType: responseType
		})
		.then(function (response) {
      updateAccessToken(response.data.token_type, response.data.id_token);
	    asyncAuthRequest(uri, method, data, onSuccessCallback, onFailureCallback, responseType);
		})
		.catch(function (error) {
			if(!error.response || error.response.status === 401) {
        logoutUser();
			} else {
				if (typeof onFailureCallback === 'function')
					onFailureCallback(error.response || error);
				else
					console.log(error.response);
			}	
		});
	}
}

const url = (uri) => `${config.apiRoot}${uri}`;