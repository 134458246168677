import React from 'react';
import Form from 'react-bootstrap/Form';

const SingleSelectFilter = ({ column, handleFilterChange, filterValue }) => {
  const { id } = column;
  filterValue = filterValue || '';
  return (
    <Form.Control
      id={`singleselect${id}`}
      as="select"
      value={filterValue}
      onChange={(e) => handleFilterChange(id, e.target.value,column)}
    >
      {/* <option value=""></option> */}
      {column.filterOptions.map((option) => (
        <option key={option.key} value={column.filterReturnKey ? option.key : option.value}>
          {option.value}
        </option>
      ))}
    </Form.Control>
  );
};

export default SingleSelectFilter;

