import ExampleTheme from "./themes/ExampleTheme";
import "./styles.css";
import { useState } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevel";
import HTMLToJSONPlugin from "./plugins/HTMLToJSONPlugin";

const EMPTY_EDITOR =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

  const Editor = (props) => {
  // const [initialEditorState, setInitialEditorState] = useState(props.note ? props.note.note : EMPTY_EDITOR);
  const [htmlString, setHtmlString] = useState(
    props.note.note ? props.note.note : ""
  );

  const Placeholder = () => {
    return <div className="editor-placeholder">Enter notes or comments...</div>;
  }

  const editorConfig = {
    editorState: EMPTY_EDITOR, // was initialEditorState
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      // throw error;
      // show notification??
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  const onChange = (editorState, editor) => {
    editor.update(() => {
      const htmlStringToStore = $generateHtmlFromNodes(editor, null);
      props.onNoteUpdate(htmlStringToStore);
    });
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoFocusPlugin />
          <ListPlugin />
          <OnChangePlugin onChange={onChange} />
          <HTMLToJSONPlugin htmlString={htmlString} />{" "}
          <ListMaxIndentLevelPlugin maxDepth={7} />
        </div>
      </div>
    </LexicalComposer>
  );
}

export default Editor;