import React, { Component } from "react";

import { Multiselect } from "multiselect-react-dropdown";

import Button from "react-bootstrap/Button";
import { MdModeEdit } from "react-icons/md";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import AdminPopUpDismissableModal from "components/adminPopupDismissableModal";
import { getErrorMessage } from "services/UtilityService";
import { showNotification } from "services/NotificationService";
import "assets/css/user.css";

class EditUser extends Component {
  state = {
    user: { email: "", name: "", roles: [], ipAddress: "" },
    users: null,
    errorMsg: null,
    userName: this.props.user ? this.props.user.userName : "",
    userEmail: this.props.user ? this.props.user.email : "",
    selectedUser: this.props.user,
    showButton: this.props.showButton,
    selectedUserRolesList: [],
    roles: this.props.roles,
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.roles !== this.props.roles) {
      this.setState({ roles: this.props.roles })
    }
  }
  editUser = (values, setSubmitting, closeModalHandle) => {
    let valuesDto = {};
    valuesDto.email = values.email;
    valuesDto.name = values.userName;
    valuesDto.ipAddress = values.ipAddress;
    valuesDto.roles = values.roles;
    sendAuthenticatedAsyncRequest(
      "/user/update",
      "PUT",
      valuesDto,
      (r) => {
        this.setState({ asyncInProgress: false });
        setSubmitting(false);
        showNotification({
          title: "Action successful!",
          message: "User edited successfully.",
          position: "tc",
          type: "success",
        });
        closeModalHandle();
        this.props.handler();
      },
      (r) => {
        this.setState({ asyncInProgress: false });
        setSubmitting(false);
        showNotification({
          title: "Error!",
          message:
            getErrorMessage(r.data) || getErrorMessage("UNABLE_TO_EDIT_USER"),
          position: "tc",
          type: "error",
        });
      }
    );
  };



  onMultiSelectChange = (options, values) => {
    let tempRolesList = [];
    
    options.map((x) => tempRolesList.push(x.key));
    this.setState({rolesList : tempRolesList});
    values.roles = tempRolesList;
  };

  invertShowbutton = () => {
    this.setState((prevState) => ({
      showButton: !prevState.showButton,
    }));
  };

  render() {
    const modalRef = "editUser";
    const { selectedUser, roles, showButton } = this.state;

    if (showButton) {
      return (
        <OverlayTrigger overlay={<Tooltip>Edit User</Tooltip>}>
          <MdModeEdit
            size={20}
            className="user-action-button edit-user"
            onClick={() => {
              this.invertShowbutton();
            }}
          ></MdModeEdit>
        </OverlayTrigger>
      );
    } else {
      const userRolesList = selectedUser.rolesList
        ? selectedUser.rolesList.split(", ").map((role) => {
            return { key: role, value: role };
          })
        : [];
        const rolesList = roles
        ? roles.map((c) => ({
            value: c.name,
            key: c.name,
          }))
        : [];
      const editUserFormSchema = Yup.object().shape({
        userName: Yup.string()
          .max(60, "Maximum Length: " + 60)
          .required("username is required"),
        email: Yup.string().email().required("email is required"),
        roles: Yup.array().min(1, "Atleast select 1 role"),
        ipAddress: Yup.string()
          .nullable()
          .matches(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i,
            "Not a valid IP address"
          ),
      });
      return (
        <>
          <OverlayTrigger overlay={<Tooltip>Edit User</Tooltip>}>
            <MdModeEdit
              className="user-action-button edit-user"
              size={20}
              onClick={() => {
                this.invertShowbutton();
              }}
            ></MdModeEdit>
          </OverlayTrigger>
          <AdminPopUpDismissableModal
            ref={modalRef}
            openModalButtonText="Edit User"
            openModalButtonStyle="info"
            openModalButtonBlock={true}
            openModalButtonDisabled={false}
            openModalBsuttonId="edit-user-role-btn-popup"
            preventCloseWhenClickedOutsideModal={true}
            initialStateOpen={true}
            hideFooter={true}
            onClose={this.invertShowbutton}
            title={"Edit user details"}
            showIcons={true}
          >
            <Formik
              initialValues={{
                ...selectedUser,
                roles: userRolesList.map((role) => role.key),
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.editUser(
                  values,
                  setSubmitting,
                  this.refs[modalRef].handleClose
                );
              }}
              validationSchema={editUserFormSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          name="email"
                          onBlur={handleBlur}
                          readOnly
                        ></Form.Control>
                        <Form.Text className="text-small text-danger">
                          {errors.name && touched.name && errors.name}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.userName}
                          onChange={handleChange}
                          name="userName"
                          onBlur={handleBlur}
                        ></Form.Control>
                        <Form.Text className="text-small text-danger">
                          {errors.name && touched.name && errors.name}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>IP Address</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.ipAddress}
                          onChange={handleChange}
                          name="ipAddress"
                          onBlur={handleBlur}
                        ></Form.Control>
                        <Form.Text className="text-small text-danger">
                          {errors.ipAddress &&
                            touched.ipAddress &&
                            errors.ipAddress}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Roles</Form.Label>
                        <Multiselect
                          placeholder="Select Role"
                          options={rolesList}
                          displayValue="value"
                          name="roles"
                          value={values.roles}
                          onSelect={(e) => this.onMultiSelectChange(e, values)}
                          onRemove={(e) => this.onMultiSelectChange(e, values)}
                          style={{
                            chips: {
                              background: "#007BBB",
                            },
                          }}
                          showArrow
                          hidePlaceholder
                          selectedValues={userRolesList}
                        ></Multiselect>
                        <Form.Text className="text-small text-danger">
                          {errors.roles && touched.roles && errors.roles}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="bottom-spacer top-spacer">
                    <Col md={{ offset: "4", span: "4" }}>
                      <Button
                        variant="outline-dark"
                        block
                        onClick={() => this.refs[modalRef].handleClose()}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Button
                        type="submit"
                        variant="success"
                        block
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Editing... " : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </AdminPopUpDismissableModal>
        </>
      );
    }
  }
}

export default EditUser;
