export const STATUS_BADGE_COLORS = {
  REOPEN: "danger",
  AUTO_APPROVED: "success",
  AUTO_REJECTED: "dark",
  AUTO_REJECTED_BY_DSG: "dark",
  SUBMITTED_FOR_REVIEW: "secondary",
  OPEN: "primary",
  MANUALLY_REVIEWED: "success",
  PENDING_PAW_APPROVAL: "secondary",
  PENDING_PAW_REJECTION: "secondary",
};

export const DRUG_GROUP_STATUSES = {
  DEFAULT_FILTERS: "Default Filters",
  All: "All",
  OPEN: "Open",
  AUTO_APPROVED: "Auto Approved",
  AUTO_REJECTED: "Auto Rejected",
  AUTO_REJECTED_BY_DSG: "Auto Rejected By DSG",
  REOPEN: "Reopen",
  SUBMITTED_FOR_REVIEW: "Submitted For Review",
  MANUALLY_REVIEWED: "Manually Reviewed",
  AUTO_REJECTED_BY_DSG: "Auto Rejected by DSG",
};

export const SWITCHES_STATUSES = {
  DEFAULT_FILTERS: "Default Filters",
  All: "All",
  OPEN: "Open",
  AUTO_APPROVED: "Auto Approved",
  AUTO_REJECTED: "Auto Rejected",
  AUTO_REJECTED_BY_DSG: "Auto Rejected By DSG",
  REOPEN: "Reopen",
  SUBMITTED_FOR_REVIEW: "Submitted For Review",
  MANUALLY_REVIEWED: "Manually Reviewed",
  PENDING_PAW_APPROVAL: "Manually Approved",
  PENDING_PAW_REJECTION: "Manually Rejected",
  AUTO_REJECTED_BY_DSG: "Auto Rejected by DSG",
};
export const DEFAULT_SORT = [
  { id: "dynamicSwitchGroupName", desc: false, isDefault: true },
  { id: "drugGroupUuid", desc: false, isDefault: true },
];

export const DEFAULT_FILTER = [
  {
    id: "status",
    value: ["OPEN", "REOPEN", "SUBMITTED_FOR_REVIEW"],
    operator: "IN",
    isdefault: true,
  },
  { id: "ownerName", value: "unassigned", isDefault: true },
];
