import React, { useState, useEffect } from "react";
import Tip from "components/Tip";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import ListColumnState from "./ListColumnState";
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import ServerSideReactTable from 'commons/table/ServerSideReactTable';

const DEFAULT_PAGE_SIZE = 7;

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? isNaN(row[id])
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : String(row[id]).includes(filter.value)
    : true;
}

export default function PrescribersList(props) {
  const { selectedPrescriber, onSelect } = props;
  const [prescribers, setPrescribers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [listState, setListState] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sorted: [],
    filtered: [],
    totalElements: 0,
  });

  const updateData = (state) => {
    setListState(state);
    fetchPrescribers(state);
  };

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    const listState = {
      page: pageNo,
      pageSize: pageSize,
      sorted: sortBy ? sortBy : [],
      filtered: filterValues,
    }
    updateData(listState);
  }

  const fetchPrescribers = (state) => {
    setLoading(true);
    sendAuthenticatedAsyncRequest(
      `/contact-management/prescribers`,
      "POST",
      state,
      (r) => {
        setTotalPages(r.data.totalPages);
        setTotalElements(r.data.totalElements);
        setPrescribers(r.data.records);
        setLoading(false);
      },
      (r) => {
        setTotalPages(0);
        setTotalElements(0);
        setPrescribers([]);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchPrescribers(listState);
  }, []);
  
  const columns = [
    {
      header: "Prescriber Name",
      accessor: "prescriberName",
      accessorKey: "prescriberName",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Prescriber Npi",
      accessor: "prescriberNpi",
      accessorKey: "prescriberNpi",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Prescriber Phone",
      accessor: "prescriberPhone",
      accessorKey: "prescriberPhone",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Prescriber Fax",
      accessor: "prescriberFax",
      accessorKey: "prescriberFax",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Prescriber Address",
      accessor: "prescriberPractice",
      accessorKey: "prescriberPractice",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
  ];
  return (
    <div className="list-container">
      <ServerSideReactTable
        columnDefination={columns}
        overrideRowId={'prescriberId'}
        data={prescribers}
        currentPage={listState.page}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={totalPages}
        totalRecords={totalElements?totalElements:0}
        loading={loading}
        enableMultiRowSelection={false}
        addRowSelection={false}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        onUpdateTableData={onUpdateTable}
        pageSizeOptions={[5, 7, 10]}
        onRowClick={(rowInfo) => {
          if (rowInfo !== undefined) {
            onSelect(rowInfo.original);
          }
        }}
        trStyle={(rowInfo) => {
          return (
            {
              background:
                rowInfo &&
                rowInfo.original.prescriberInfoUuid ===
                  selectedPrescriber.prescriberInfoUuid
                  ? "#c6e4f9"
                  : "#ffffff",
              color:
                rowInfo &&
                rowInfo.original.prescriberInfoUuid ===
                  selectedPrescriber.prescriberInfoUuid
                  ? "#008df1"
                  : "",
            })
        }
        }
      />
      <Tip>Tip: Hold shift when sorting to multi-sort and disable sort!</Tip>
    </div>
  );
}
