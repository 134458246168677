import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cloneDeep from "lodash/cloneDeep";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import { Formik } from "formik";
import Card from "react-bootstrap/Card";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { TranscriptionConstants } from "lookup/TranscriptionConstants";
import { connect } from "react-redux";

import {
  MdWarning,
  MdCancel,
  MdEdit,
  MdSave,
  MdAdd,
  MdRemove,
} from "react-icons/md";
import { FaSquare } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Line } from "react-lineto";
import { startCase } from "lodash";
import "./FormSection.css";

class FormSection extends Component {
  drawBox = (box) => {
    let startX =
      this.props.imageRef.current.offsetLeft +
      this.props.imageRef.current.offsetParent.offsetLeft +
      this.props.imageRef.current.offsetParent.offsetParent.offsetLeft;
    let startY =
      this.props.imageRef.current.offsetTop +
      this.props.imageRef.current.offsetParent.offsetTop +
      this.props.imageRef.current.offsetParent.offsetParent.offsetTop;
    let width = 1018;
    let height = 1585;
    box = {
      topX:
        startX +
        parseInt(box.topX * (this.props.imageRef.current.offsetWidth / width)),
      topY:
        startY +
        parseInt(
          box.topY * (this.props.imageRef.current.offsetHeight / height)
        ),
      bottomX:
        startX +
        parseInt(
          box.bottomX * (this.props.imageRef.current.offsetWidth / width)
        ),
      bottomY:
        startY +
        parseInt(
          box.bottomY * (this.props.imageRef.current.offsetHeight / height)
        ),
    };
    return (
      <div>
        <Line
          x0={box.topX}
          y0={box.topY}
          x1={box.bottomX}
          y1={box.topY}
          borderColor={"red"}
          borderWidth={3}
        />
        <Line
          x0={box.topX}
          y0={box.bottomY}
          x1={box.bottomX}
          y1={box.bottomY}
          borderColor={"red"}
          borderWidth={3}
        />
        <Line
          x0={box.topX}
          y0={box.topY}
          x1={box.topX}
          y1={box.bottomY}
          borderColor={"red"}
          borderWidth={3}
        />
        <Line
          x0={box.bottomX}
          y0={box.topY}
          x1={box.bottomX}
          y1={box.bottomY}
          borderColor={"red"}
          borderWidth={3}
        />
      </div>
    );
  };

  handleCustomChange = (fieldKey, field, setFieldValue) => {
    if (this.state.formValues[fieldKey].box) {
      // this.setState({ drawField: null });
      this.setState({ drawField: fieldKey });
    }
    this.setState(prevState => ({
      formValues:{
        ...prevState.formValues,
        [fieldKey]:{
          ...prevState.formValues[fieldKey],
          val: field
        }
      }
    }))
    // this.state.formValues[fieldKey].val = field;

    setFieldValue(fieldKey, this.state.formValues[fieldKey]);
  };

  state = {
    isEditMode: false,
    showDetails: false,
    allowEditing: this.props.allowEditing,
    formSection: this.props.formSection,
    formValues: cloneDeep(this.props.formSection),
    sectionName: this.props.sectionName,
    updateSection: this.props.updateSection,
    handelCustomChange: this.handleCustomChange,
    selectedTarget: this.props.formSection.selected? this.props.sectionName : null,
    targetSet: false,
    drawTheBox: false,
    drawField: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.formSection !== prevProps.formSection || this.props.sectionName !== prevProps.sectionName) {
      this.setState({
        formSection: this.props.formSection,
        sectionName: this.props.sectionName,
        formValues: cloneDeep(this.props.formSection),
        selectedTarget: this.props.formSection.selected ? this.props.sectionName : null,
      });
    }
  }

  toggleShowDetails = () => {
    const { showDetails } = this.state;
    this.setState({ showDetails: !showDetails });
  };

  handleEnableEditMode = () => {
    this.setState({ isEditMode: true, showDetails: true });
  };

  handleCancelEditMode = (setFieldValue) => {
    for (const fieldKey of Object.keys(this.state.formSection)) {
      this.setState(prevState => ({
        formValues:{
          ...prevState.formValues,
          [fieldKey]:this.state.formSection[fieldKey]
        },
        selectedTarget:this.state.formSection.selected? this.state.sectionName : null,
      }))
    
      setFieldValue(fieldKey, this.state.formSection[fieldKey]);
    }
    this.setState({
      isEditMode: false,
      showDetails: false,
      drawField: null,
    });
  };

  handleSaveEditMode = (values, setSubmitting, section, updateSection) => {
    this.setState({formSection:cloneDeep(this.state.formValues) })
    // if (this.state.sectionName.includes('target')) {
    //   this.props.unselectOtherTargets(this.state.sectionName)
    // }
    updateSection(section, this.state.formSection);
    setSubmitting(false);
    this.setState({
      isEditMode: false,
      drawField: null,
    });
  };

  renderClassificationList = (sectionName, handleBlur, setFieldValue) => {
    if (TranscriptionConstants.classificationSections.includes(sectionName)) {
      return (
        <>
          <Row>
            <Form.Group>
              <Form.Label>classifications</Form.Label>
              <Select
                options={TranscriptionConstants.classifications}
                multi={false}
                onChange={(value) =>
                  this.handleCustomChange(
                    "classification",
                    value,
                    setFieldValue
                  )
                }
                onBlur={handleBlur}
                value={`${sectionName}-classification`}
                disabled={!this.state.isEditMode}
                name={`${sectionName}-classification`}
              />
            </Form.Group>
          </Row>
        </>
      );
    }
    return <></>;
  };

  renderIsDetected = (field) => {
    if (field.confidence) {
      if (field.confidence < 30) {
        var confidence = (
          <div className="confidence pull-right">
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  <strong>{"model confidence"}</strong>.
                </Tooltip>
              }
            >
              <Badge pill bg="danger">
                {field.confidence.toFixed(1)}
              </Badge>
            </OverlayTrigger>
          </div>
        );
      }
      if (field.confidence < 75) {
        var confidence = (
          <div className="confidence  pull-right">
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  <strong>{"model confidence"}</strong>.
                </Tooltip>
              }
            >
              <Badge pill bg="warning">
                {field.confidence.toFixed(1)}
              </Badge>
            </OverlayTrigger>
          </div>
        );
      }
      else {
        var confidence = (
          <div className="confidence pull-right">
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  <strong>{"model confidence"}</strong>.
                </Tooltip>
              }
            >
              <Badge pill bg="success">
                {field.confidence.toFixed(1)}
              </Badge>
            </OverlayTrigger>
          </div>
        );
      }
    }
    if (!field.detected) {
      return (
        <>
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip>
                <strong>{"not detected"}</strong>.
              </Tooltip>
            }
          >
            <MdWarning class="not-detected  pull-right"></MdWarning>
          </OverlayTrigger>
          {confidence ? confidence : ""}
        </>
      );
    } else {
      return confidence ? confidence : "";
    }
  };

  renderFormElement = (
    sectionName,
    fieldKey,
    field,
    handleBlur,
    setFieldValue,
    errors
  ) => {
    if (field.type === TranscriptionConstants.TRANSCRIBER_FIELDS.text) {
      if (!this.state.isEditMode) {
        return (
          <>
            <Row>
              {console.log(this.props.formSection)}
              <span className="field-name pull-left">{fieldKey !== "null" ? startCase(fieldKey): ""}</span>
              {this.renderIsDetected(field)}
            </Row>
            <Row className="field-val-text">{field.val || "-"}</Row>
          </>
        );
      } else {
        return (
          <>
            <Form.Group>
              <Row>
                <Form.Label className="edit-field">
                  <span className="field-name pull-left">{fieldKey !== "null" ? startCase(fieldKey): ""}</span>
                  {this.renderIsDetected(field)}
                </Form.Label>
              </Row>
              <Form.Control
                onChange={(e) => {
                  this.state.handelCustomChange(
                    e.target.name,
                    e.target.value,
                    setFieldValue
                  );
                }}
                onBlur={handleBlur}
                value={field.val}
                name={fieldKey}
                key={`${sectionName}-${fieldKey}-Control`}
                id={`${sectionName}-${fieldKey}-Control`}
                disabled={false}
                className="field-val-text"
              />
              <Row>
                <Form.Text
                  id={`${sectionName}-${fieldKey}-text`}
                  className="text-small text-danger"
                  key={`${sectionName}-${fieldKey}-text`}
                >
                  {errors.faxDisplayName &&
                    touched.faxDisplayName &&
                    errors.faxDisplayName}
                </Form.Text>
              </Row>
            </Form.Group>
          </>
        );
      }
    } else if (field.type === TranscriptionConstants.TRANSCRIBER_FIELDS.list) {
      if (field.options === null) {
        if (typeof field.val !== "boolean"){
          if (field.val === null) {
            field.val = false;
          }
          else {
            field.val = true;
          }
      }
        return (
          <>
            <Row>
              <Form.Check
                inline
                onChange={(e) => {
                  this.state.handelCustomChange(
                    e.target.name,
                    e.target.checked,
                    setFieldValue
                  );
                }}
                onBlur={handleBlur}
                label={startCase(fieldKey)}
                type="checkbox"
                name={fieldKey}
                id={fieldKey}
                key={`${sectionName}-${fieldKey}`}
                fieldName={`${fieldKey}`}
                checked={field.val}
                disabled={!this.state.isEditMode}
                className="field-name pull-left"
              />
              {this.renderIsDetected(field)}
            </Row>
          </>
        );
      } else {
        if (fieldKey == "refill") {
          return (
            <>
              <Row>
                <span className="field-name pull-left">{startCase(fieldKey)}</span>
                {this.renderIsDetected(field)}
              </Row>

              <Row className="multiple-options">
                {field.options?.map((option, idx) => (
                  <Form.Check
                    inline
                    onChange={(e) => {
                      this.state.handelCustomChange(
                        e.target.name,
                        e.target.id,
                        setFieldValue
                      );
                    }}
                    onBlur={handleBlur}
                    label={option}
                    type="Radio"
                    name={fieldKey}
                    id={option}
                    key={`${sectionName}-${fieldKey}-item-${idx}`}
                    fieldName={`${fieldKey}-item-${idx}`}
                    checked={option === field.val || (!field.options.filter(item => item !== "Other").includes(field.val) && field.val !== null)}
                    disabled={!this.state.isEditMode}
                    className="field-option"
                  />
                ))}
              </Row>
              <Form.Group>
                <Form.Label>
                  <span className="other-option">other</span>
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    this.state.handelCustomChange(
                      e.target.name,
                      e.target.value,
                      setFieldValue
                    );
                  }}
                  onBlur={handleBlur}
                  value={field.options.includes(field.val)  ? "" : field.val}
                  name={fieldKey}
                  key={`${sectionName}-${fieldKey}-Control`}
                  id={`${sectionName}-${fieldKey}-Control`}
                  disabled={field.options.filter(item => item !== "Other").includes(field.val)}
                  className="field-val-text-other"
                />
              </Form.Group>
            </>
          );
        } else {
          return (
            <>
              <Row>
                <span className="field-name pull-left">{startCase(fieldKey)}</span>
                {this.renderIsDetected(field)}
              </Row>

              <Row className="multiple-options">
                {field.options?.map((option, idx) => (
                  <Form.Check
                    inline
                    onChange={(e) => {
                      this.state.handelCustomChange(
                        e.target.name,
                        e.target.id,
                        setFieldValue
                      );
                    }}
                    onBlur={handleBlur}
                    label={option}
                    type="Radio"
                    name={fieldKey}
                    id={option}
                    key={`${sectionName}-${fieldKey}-item-${idx}`}
                    fieldName={`${fieldKey}-item-${idx}`}
                    checked={option === field.val}
                    disabled={!this.state.isEditMode}
                    className="field-option"
                  />
                ))}
              </Row>
            </>
          );
        }
      }
    }
  };

  render() {
    const {
      isEditMode,
      showDetails,
      allowEditing,
      formValues,
      sectionName,
      updateSection,
      drawField,
      selectedTarget,
      formSection,
      targetSet
    } = this.state;

    const setIsActiveValue = (values, e, handleBlur, sectionName) => {
      // if (!values.selected) {
      //   this.setState({selectedTarget: sectionName})
      // }
      // else {
      //   this.setState({selectedTarget: null})
      // }
      this.setState(prevState => ({
        formValues:{
          ...prevState.formValues,
          ["selected"]: !prevState.formValues.selected
        }
      }));
      
      handleBlur(e);
    };

    return (
      <>
        <Formik
          initialValues={{ ...formValues }}
          onSubmit={(values, { setSubmitting }) => {
            this.handleSaveEditMode(
              values,
              setSubmitting,
              sectionName,
              updateSection
            );
          }}
          onReset={(values, { setFieldValue }) => {
            this.handleCancelEditMode(setFieldValue);
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <Row>
                <Col md={12}>
                  <Card className="form-section">
                    <Card.Body
                      className={
                        !showDetails ? "card-body-padding-override" : ""
                      }
                    >
                      <Card.Title
                        className={
                          !showDetails ? "no-margin form-card-title" : ""
                        }
                      >
                        <div className="pull-right drug-title-link-container">
                          {showDetails ? (
                            <Card.Link
                              href="#0"
                              onClick={this.toggleShowDetails}
                            >
                              <MdRemove className="link-icon" /> Collapse
                            </Card.Link>
                          ) : (
                            <Card.Link
                              href="#0"
                              onClick={this.toggleShowDetails}
                            >
                              <MdAdd className="link-icon" /> Expand
                            </Card.Link>
                          )}
                          {allowEditing ? (
                            <>
                              {isEditMode ? (
                                <>
                                  <Card.Link href="#0" onClick={handleReset}>
                                    <MdCancel className="link-icon" /> Cancel
                                  </Card.Link>
                                  <Card.Link href="#0" onClick={handleSubmit}>
                                    <MdSave className="link-icon" /> Save
                                  </Card.Link>
                                </>
                              ) : (
                                <>
                                  <Card.Link
                                    href="#0"
                                    onClick={this.handleEnableEditMode}
                                  >
                                    <MdEdit className="link-icon" /> Edit
                                  </Card.Link>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        {isEditMode ? <br></br> : <></>}
                        <div className="section-name">
                          <span>
                            {sectionName.split(":")[0] === "target" ? (
                              <Form.Check
                                aria-label="Active"
                                inline
                                type="checkbox"
                                disabled={!isEditMode}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  setIsActiveValue(formValues, e, handleBlur, sectionName)
                                }
                                checked={formValues.selected}
                                name="target"
                                id="target"
                              />
                            ) : (
                              <></>
                            )}
                          </span>
                          <span className="drug-title">
                            {sectionName.split(":")[0] === "target" ? (
                              <>#{sectionName.split(":")[1]} Target</>
                            ) : (
                              <>{sectionName}</>
                            )}
                          </span>
                        </div>
                      </Card.Title>
                      <Collapse in={showDetails}>
                        <div className="small-top-spacer">
                          {this.renderClassificationList(
                            sectionName,
                            handleBlur,
                            setFieldValue
                          )}
                          {Object.keys(formValues).map((fieldKey, ind) => {
                            if (fieldKey !== "selected") {
                              if (
                                ((fieldKey === "refill") &
                                  formValues["selected"]) |
                                (fieldKey != "refill")
                              )
                                return this.renderFormElement(
                                  sectionName,
                                  fieldKey,
                                  formValues[fieldKey],
                                  handleBlur,
                                  setFieldValue,
                                  errors
                                );
                            }
                          })}

                          {Object.keys(formValues).map((fieldKey, ind) => {
                            if (fieldKey === drawField) {
                              return this.drawBox(formValues[fieldKey].box);
                            }
                          })}
                        </div>
                      </Collapse>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
export default connect()(withRouter(FormSection));
