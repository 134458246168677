import config from "config";
import { RSAA } from "redux-api-middleware";
import {
  AUTH_TOKEN_HEADER_KEY,
  getAuthorizationToken,
} from "../../../services/AuthService";
import { RESET_PAW_GROUP_DETAIL } from "./PAWGroupDisplayBar.slice";

export const getPAWGroupDetailRequestAction = "GET_PAW_GROUP_DETAIL_REQUEST";
export const getPAWGroupDetailSuccessAction = "GET_PAW_GROUP_DETAIL_SUCCESS";
export const getPAWGroupDetailFailureAction = "GET_PAW_GROUP_DETAIL_FAILURE";

export const getPAWGroupDetail = (pawGroupId) => (dispatch, getState) => {
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/paw-group/${pawGroupId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        getPAWGroupDetailRequestAction,
        {
          type: getPAWGroupDetailSuccessAction,
          payload: async (action, state, res) => {
            const data = await res.json();
            return data;
          },
        },
        getPAWGroupDetailFailureAction,
      ],
    },
  };

  return dispatch(rsaaObject);
};

export const resetPawGroupDetail = () => (dispatch, getState) => {
  return dispatch(RESET_PAW_GROUP_DETAIL());
};
