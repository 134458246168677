import { isRSAA, apiMiddleware, RSAA } from 'redux-api-middleware';
import { 
  isRefreshTokenExpired, 
  isAccessTokenExpired, 
  refreshAccessToken, 
  AUTH_TOKEN_HEADER_KEY, 
  TOKEN_RECEIVED, 
  TOKEN_FAILURE, 
  updateAccessToken, 
  logoutUser 
} from 'services/AuthService';

export function createTokenRefreshMiddleware() {
  // we will keep api calls in this array if token has expired
  // until the token is refreshed
  let postponedRSAAs = [];
  return ({ dispatch, getState }) => {
    // apiMiddleware returns a function: next => action => { function_body }
    // just what this middleware returns (dictated by redux)
    const rsaaMiddleware = apiMiddleware({dispatch, getState});


    return (next) => (action) => {

      const dispatchPostponedActions = (nextAction) => {

        if (nextAction.type === TOKEN_RECEIVED) {
          // if we have received the token, dispatch
          // postponed actions 

          // Typically there should be a reducer handling this
          next(nextAction); 

          // Since there isn't at the moment, manually storing 
          // new authoritzation token
          const {token_type, id_token} = nextAction.payload;
          updateAccessToken(token_type, id_token);

          postponedRSAAs.forEach((postponed) => {
            // update headers of postponed request
            // TODO: move this out to a middleware when
            // we a reducer stores auth tokens in the store
            postponed[RSAA].headers[AUTH_TOKEN_HEADER_KEY] = token_type + " " + id_token;
            rsaaMiddleware(next)(postponed);
          });

          postponedRSAAs = [];
        } else if (nextAction.type === TOKEN_FAILURE) {
          logoutUser();
        } else
          next(nextAction);
      }

      if(isRSAA(action)) {
        
        if(!isRefreshTokenExpired() && isAccessTokenExpired()) {

          // there is a need to refresh auth token
          // start holding RSAA actions in postponedRSAAs
          postponedRSAAs.push(action);

          // whenever we see first RSAA action, 
          // send request to refreshToken
          // all subsequent RSAAs will be held
          // in postponedRSAAs until token is 
          // refreshed
          if(postponedRSAAs.length === 1) {
            const action = refreshAccessToken();
            return rsaaMiddleware(dispatchPostponedActions)(action)
          } else 
            return; // we have held this RSAA, do nothing
          
        }
        // everything is fine, delegate to middleware
        return rsaaMiddleware(next)(action);
      }

      // not as RSAA action, delegate to redux
      return next(action);
    }
  }
}

export default createTokenRefreshMiddleware();