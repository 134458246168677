import { createSlice } from '@reduxjs/toolkit';
import {RawConstants, FILTER} from 'lookup/RawConstants';
const initialState = {
  data: [],
  pages: 0,
  pageSize: 15,
  currentPage:0,
  error: false,
  loading: true,
  totalElements: "Loading",
  sorted:RawConstants.DEFAULT_SORT,
  filtered: [FILTER.defaultBackend],
  resized: [],
  expanded: {},
  isError: false,
  lastDate: "Loading"
}


export const rawListSlice = createSlice({
  name: "rawList",
  initialState: initialState,
  reducers: {
    getRawListSuccess: (state, action) => {
      state.data = action.payload.records;
      state.pages = action.payload.totalPages;
      state.currentPage = action.payload.page;
      state.totalElements=action.payload.totalElements;
      state.isError = false;
      state.loading = false;
    },
    getRawListFailure: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.totalElements = "Failed";
    },
    getRawListLoading: (state, action) => {
      state.loading = true;
      state.totalElements = "Loading";
      state.filtered = action.payload.filtered;
      state.sorted = action.payload.sorted;
    },
    getRawIndex: (state, action) => {
      state.indexOnList = action.payload.indexOnList;
      state.totalElements = "Loading";
    },
    getResetErrorMessage: (state, action) => {
      state.isError = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase('UPDATE_RAW_LIST_REQUEST', (state, action) => {
        rawListSlice.caseReducers.getRawListLoading(state, action);
      })
      .addCase('UPDATE_RAW_LIST_SUCCESS', (state, action) => {
        rawListSlice.caseReducers.getRawListSuccess(state, action);
      })
      .addCase('UPDATE_RAW_LIST_FAILURE', (state, action) => {
        rawListSlice.caseReducers.getRawListFailure(state, action);
      })
      .addCase('UPDATE_RAW_INDEX_ON_LIST', (state, action) => {
        rawListSlice.caseReducers.getRawIndex(state, action);
      })
      .addCase('RESET_ERROR_MESSAGE_RAW_LIST', (state, action) => {
        rawListSlice.caseReducers.getResetErrorMessage(state, action);
      })
  }
});


export const { getRawListSuccess, getRawListFailure, getResetErrorMessage, getRawIndex } = rawListSlice.actions;
export const rawState = (state) => state.raw;
export default rawListSlice.reducer;