
export function Reducer(previousState, action) {
  let newState = {...previousState}

  switch (action.type) {
    case 'UPDATE_CYCLE_SWITCH_LIST_SUCCESS':
      newState = { ...previousState, 
        data: action.payload.records,
        pages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        loading: false,
        isError: false,
        listState: {...previousState.listState, page: action.payload.page}
      };
      break;
    case 'UPDATE_CYCLE_SWITCH_LIST_REQUEST':
      newState = { ...previousState, 
        listState: action.payload.listState,
        totalElements: "Loading ...",
        loading: true,
        isError: false
      };
      break;
    case 'UPDATE_CYCLE_SWITCH_INDEX_ON_LIST':
      newState = { ...previousState, indexOnList: action.indexOnList};
      break;
    case 'UPDATE_CYCLE_SWITCH_LIST_FAILURE':
      newState = { ...previousState, data: [],
        pages: 0,
        totalElements: 0,
        loading: false,
        isError: true,};

      break;
    case 'RESET_ERROR_MESSAGE_CYCLE_SWITCH_LIST':
      newState = { ...previousState, isError: false};
      break;
    default:
      break;
  }

  return newState;
}