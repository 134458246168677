import { WorkflowConstants } from "lookup/WorkflowConstants";
import { getLoggedInUser } from "services/AuthService";
import { getErrorMessage } from "services/UtilityService";

export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case "AUTO_ASSIGN_CALL_GROUP_SUCCESS":
      const callGroupData = previousState.callGroupData;
      const loggedInUser = getLoggedInUser();
      callGroupData.claimerUuid = loggedInUser.uuid;
      callGroupData.claimerName = loggedInUser.name;
      newState = Object.assign({}, previousState, {
        autoAssignStatus: "success",
        callGroupData: callGroupData,
      });
      break;
    case "AUTO_ASSIGN_CALL_GROUP_REQUEST":
      newState = Object.assign({}, previousState, {
        autoAssignStatus: "requested",
      });
      break;
    case "AUTO_ASSIGN_CALL_GROUP_FAILURE":
      newState = Object.assign({}, previousState, {
        autoAssignStatus: "failure",
        autoAssignError: getErrorMessage(action.payload.response)
      });
      break;
    case "GET_CALL_GROUP_DETAILS_SUCCESS":
      newState = Object.assign({}, previousState, {
        callGroupData: action.payload,
        callGroupLoading: false,
        autoAssignStatus: "ready",
      });
      break;
    case "GET_CALL_GROUP_DETAILS_REQUEST":
      newState = Object.assign({}, previousState, {
        callGroupData: null,
        callGroupLoading: true,
      });
      break;
    case "GET_CALL_GROUP_DETAILS_FAILURE":
      newState = Object.assign({}, previousState, {
        callGroupData: null,
        callGroupLoading: false,
      });
      break;
    case "GET_CALL_GROUP_ACTIVITIES_SUCCESS":
      newState = Object.assign({}, previousState, {
        data: action.payload.records,
        pages: action.payload.totalPages,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        loading: false,
        isError: false,
      });
      newState.listState.page = action.payload.page;
      break;
    case "GET_CALL_GROUP_ACTIVITIES_REQUEST":
      newState = Object.assign({}, previousState, {
        listState: action.payload.listState,
        totalElements: "Loading ...",
        loading: true,
        isError: false
      });
      break;
    case "GET_CALL_GROUP_ACTIVITIES_FAILURE":
      newState = Object.assign({}, previousState, {
        data: [],
        pages: 0,
        totalElements: 0,
        loading: false,
        isError: true,
      });
      break;
    case "RESET_CALL_GROUP_ACTIVITIES":
      newState = Object.assign({}, previousState, {
        data: [],
        pages: 0,
        totalElements: 0,
        loading: false,
        isError: false,
      });
      break;
    case "GET_ACTIVITY_DETAILS_SUCCESS":
      newState = Object.assign({}, previousState, {
        activityDetails: action.payload,
        selectedActivityId: selectedActivityInContext(action.payload, previousState.data),
        activityLoading: false,
        isSwitchDispositionButtonRequired:
          action.payload.wfItem.status ===
          WorkflowConstants.DELIVERED_PRESCRIBER_FAX,
        isError: false,
      });
      break;
    case "GET_ACTIVITY_DETAILS_REQUEST":
      newState = Object.assign({}, previousState, {
        activityDetails: null,
        activityLoading: true,
        isSwitchDispositionButtonRequired: false,
        isError: false,
      });
      break;
    case "GET_ACTIVITY_DETAILS_FAILURE":
      newState = Object.assign({}, previousState, {
        activityDetails: null,
        selectedActivityId: null,
        activityLoading: false,
        isSwitchDispositionButtonRequired: false,
        isError: true,
      });
      break;
    case "RESET_ERROR_MESSAGE_CALL_GROUP_PAGE":
      newState = Object.assign({}, previousState, { isError: false, autoAssignError: null });
      break;
    case "RESET_AUTO_ASSIGN_ERROR":
      newState = Object.assign({}, previousState, { autoAssignStatus: "dead" });
      break;
    case "RESET_ACTIVITY_DETAILS":
      newState = Object.assign({}, previousState, {
        activityDetails: null,
        activityLoading: false,
      });
      break;
    case "RESET_CALL_GROUP_DETAILS":
      newState = Object.assign({}, previousState, {
        callGroupData: null,
        callGroupLoading: false,
        autoAssignStatus: null
      });
      break;
    default:
      break;
  }

  return newState;
}

function selectedActivityInContext(selectedActivity, activityList) {
  if (selectedActivity) {
    for (let i = 0; i < activityList.length; i++) {
      if (selectedActivity.activityId === activityList[i].activityId) return i;
    }
  }
  return 0;
}
