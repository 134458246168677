
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useRef } from "react";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import DismissableModal from "components/DismissableModal";
import { getErrorMessage, isObjectEmpty } from "services/UtilityService";
import CONTACTMANAGEMENT_CONSTANTS from "lookup/ContactManagementConstants";
import { isStringEmptyOrNull, compareEmptyOrNull } from "services/UtilityService";
import { showNotification } from "services/NotificationService";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";


const UpdateMemberInfo = (props) => {
    const { patient, globalSwitchId, isDisabled, useIcon } = props;
    const [phi, setPhi] = useState(props.phi || null);
    const [loading, setLoading] = useState(false);
    const memberInfoCloneForInitialValues = phi
        ? cloneDeep(phi)
        : {};
    const title =
        "Edit Member Detail for " + patient.memberNumber;
    const modalRef = useRef('UpdateInfoModal ' + (patient.memberNumber || 'New'));
    const modalOpener = useIcon ? 
        (
            <a
                style={{color: "#007BBB"}}
                href="#0"
                disabled={isDisabled}
                key={1}
                onClick={() => handleSpecialOpen(modalRef.current.handleShow)}
                data-testid='btn-member-edit'
                >
                <FaEdit />
            </a>
        )
    :
        (
            <Button
                size="sm"
                variant="light"
                key={1}
                disabled={isDisabled}
                onClick={() => handleSpecialOpen(modalRef.current.handleShow)}
                data-testid='btn-member-edit'
            >
                Edit
            </Button>
        )
    ;

    const fetchPhi = (globalSwitchId) => {
        if (loading !== true) {
            setLoading(true);
            sendAuthenticatedAsyncRequest(
                `/phi?globalSwitchId=${globalSwitchId}`,
                "GET",
                null,
                (r) => {
                    setPhi(r.data);
                    setLoading(false);
                },
                (r) => {
                    setPhi(undefined);
                    setLoading(false);
                }
            );
        }
    }

    const UpdateInfoFormSchema = Yup.object().shape({
        memberAddress1: Yup.string().max(
            75,
            CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "75"
        ).required("Required!"),
        memberAddress2: Yup.string().max(
            75,
            CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "75"
        ).nullable(true),
        memberCity: Yup.string().max(
            50,
            CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "50"
        ).required("Required!"),
        memberState: Yup.string().max(
            50,
            CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "50"
        ).required("Required!"),
        mobileNum1: Yup.string()
            .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
            .max(
                10,
                CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
            )
            .min(
                10,
                CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
            ).required("Required!"),
        mobileNum2: Yup.string()
            .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
            .max(
                10,
                CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
            )
            .min(
                10,
                CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
            )
            .nullable(true),
        mobileNum3: Yup.string()
            .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
            .max(
                10,
                CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
            )
            .min(
                10,
                CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
            )
            .nullable(true),
        memberEmail: Yup.string().max(
            255,
            CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
        )
        .email("Enter a valid email")
        .nullable(true),
        zipCode: Yup.string()
            .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
            .max(
                10,
                CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
            ).required("Required!"),
        memberUpdateNotes: Yup.string()
            .nullable()
            .max(4096, "Max Length: 4096")
            .required("Required!")
    });

    const isFieldRequired = (key) => {
        return UpdateInfoFormSchema.fields[key]
            ? UpdateInfoFormSchema.fields[key].exclusiveTests.required
            : false;
    };

    const submitForm = (
        originalValues,
        setSubmitting,
        setValues,
        oldMember,
        closeModal
    ) => {
        // it's best to create a clone because params are passed through reference
        originalValues = {
            ...originalValues,
            isFromAW: props.isFromAW ? true : false,
            wfItemId: props.wfItemId
        };
        const values = cloneDeep(originalValues);
        if (
            (!isStringEmptyOrNull(oldMember.memberAddress1) &&
                isStringEmptyOrNull(values.memberAddress1)) ||
            (!isStringEmptyOrNull(oldMember.memberCity) &&
                isStringEmptyOrNull(values.memberCity)) ||
            (!isStringEmptyOrNull(oldMember.memberState) &&
                isStringEmptyOrNull(values.memberState)) ||
            (!isStringEmptyOrNull(oldMember.mobileNum1) &&
                isStringEmptyOrNull(values.mobileNum1)) ||
            (!isStringEmptyOrNull(oldMember.zipCode) &&
                isStringEmptyOrNull(values.zipCode))
        ) {
            showNotification({
                title: "Invalid Change Detected!",
                message: `Mobile Phone 1, Address 1, City, State and Zip Code cannot be empty.`,
                position: "tr",
                type: "warning",
            });
            setSubmitting(false);
            return;
        }

        if (
            (oldMember.memberAddress1 === values.memberAddress1 || compareEmptyOrNull(oldMember.memberAddress1, values.memberAddress1))
            &&
            (oldMember.memberAddress2 === values.memberAddress2 || compareEmptyOrNull(oldMember.memberAddress2, values.memberAddress2))
            &&
            (oldMember.memberCity === values.memberCity || compareEmptyOrNull(oldMember.memberCity, values.memberCity))
            &&
            (oldMember.memberState === values.memberState || compareEmptyOrNull(oldMember.memberState, values.memberState))
            &&
            (oldMember.mobileNum1 === values.mobileNum1 || compareEmptyOrNull(oldMember.mobileNum1, values.mobileNum1))
            &&
            (oldMember.mobileNum2 === values.mobileNum2 || compareEmptyOrNull(oldMember.mobileNum2, values.mobileNum2))
            &&
            (oldMember.mobileNum3 === values.mobileNum3 || compareEmptyOrNull(oldMember.mobileNum3, values.mobileNum3))
            &&
            (oldMember.memberEmail === values.memberEmail || compareEmptyOrNull(oldMember.memberEmail, values.memberEmail))
            &&
            (oldMember.zipCode === values.zipCode || compareEmptyOrNull(oldMember.zipCode, values.zipCode))
        ) {
            showNotification({
                title: "No Change Detected!",
                message: `You are trying to save without making any update.`,
                position: "tr",
                type: "warning",
            });
            setSubmitting(false);
            return;
        }

        if (isStringEmptyOrNull(values.memberUpdateNotes.trim())) {
            showNotification({
                title: "Invalid Note!",
                message: `You are trying to save an empty note.`,
                position: "tr",
                type: "warning",
            });
            setSubmitting(false);
            return;
        }

        sendAuthenticatedAsyncRequest(
            `/phi/member-info/${phi.memberInfoUuid}`,
            "PUT",
            values,
            (r) => {
                setSubmitting(false);
                const updateMemberInfoDto = r.data;
                closeModal();
                if (props.onSubmission && typeof props.onSubmission === "function") {
                    props.onSubmission(updateMemberInfoDto);
                }

                // dispatch success notification
                showNotification({
                    title: `Successful Update!`,
                    message: `Member Info updated successfully`,
                    position: "tr",
                    type: "success",
                });

                props.updateData();
            },
            (r) => {
                setSubmitting(false);
                setValues(oldMember);
                // dispatch failure notification
                showNotification({
                    title: "Something went wrong!",
                    message:
                        getErrorMessage(r.data) ||
                        getErrorMessage("FAILED_TO_UPDATE_MEMBER_INFO"),
                    position: "tr",
                    type: "error",
                });
            }
        );
    };

    const handleSpecialOpen = (handleRealOpen) => {
        handleRealOpen();
    };

    const onChange = (value, field, setFieldValue) => {
        const numberRegex = /^[0-9\b]+$/;
        value = value.replace(/\s/g, "");

        if (value === "" || numberRegex.test(value)) {
            setFieldValue(field, value);
        }
    };

    const onModalShow = () => {
        if(globalSwitchId) { //if switch id is not passed then PHI would be passed directly in props.
            fetchPhi(globalSwitchId);
        } else {
            setPhi(props.phi);
        }
    };

    return (
        <DismissableModal
            ref={modalRef}
            openModalButton={modalOpener}
            title={title}
            size="lg"
            preventCloseWhenClickedOutsideModal={true}
            hideFooter={true}
            onShow={onModalShow}
            onClose={() => setTimeout(() => setPhi(null), 200)}
        >
            {loading === true
                ? "Loading PHI ..."
                : phi === undefined
                    ? "Unable to access PHI"
                    : phi === null
                        ? "PHI Cleared"
                        :
                        <Container>
                            <Formik
                                initialValues={{
                                    memberName:
                                        memberInfoCloneForInitialValues.memberName || "",
                                    memberAddress1:
                                        memberInfoCloneForInitialValues.memberAddress1 || "",
                                    memberAddress2:
                                        memberInfoCloneForInitialValues.memberAddress2 || "",
                                    memberCity:
                                        memberInfoCloneForInitialValues.memberCity || "",
                                    memberState:
                                        memberInfoCloneForInitialValues.memberState || "",
                                    mobileNum1:
                                        memberInfoCloneForInitialValues.mobileNum1 || "",
                                    mobileNum2:
                                        memberInfoCloneForInitialValues.mobileNum2 || "",
                                    mobileNum3:
                                        memberInfoCloneForInitialValues.mobileNum3 || "",
                                    memberEmail:
                                        memberInfoCloneForInitialValues.memberEmail || "",
                                    planName:
                                        memberInfoCloneForInitialValues.planName || "",
                                    planNumber:
                                        memberInfoCloneForInitialValues.planNumber || "",
                                    dateOfBirth:
                                        memberInfoCloneForInitialValues.dateOfBirth || "",
                                    gender:
                                        memberInfoCloneForInitialValues.gender || "",
                                    zipCode:
                                        memberInfoCloneForInitialValues.zipCode || "",
                                    memberUpdateNotes: "", // initially, notes will always be empty
                                    version: memberInfoCloneForInitialValues.version || 0,
                                }}
                                onSubmit={(values, { setSubmitting, setValues }) => {
                                    submitForm(
                                        values,
                                        setSubmitting,
                                        setValues,
                                        phi,
                                        modalRef.current.handleClose
                                    );
                                }}
                                validationSchema={UpdateInfoFormSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                    isValid,
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("memberName")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Member Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.memberName}
                                                        name="memberName"
                                                        disabled={true}
                                                        data-testid='input-member-edit-name'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.memberName &&
                                                            touched.memberName &&
                                                            errors.memberName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("gender")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Gender
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.gender}
                                                        name="gender"
                                                        disabled
                                                        data-testid='input-member-edit-gender'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.gender &&
                                                            touched.gender &&
                                                            errors.gender}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("mobileNum1")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Mobile Number 1
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            onChange(
                                                                e.target.value,
                                                                "mobileNum1",
                                                                setFieldValue
                                                            )
                                                        }
                                                        onBlur={handleBlur}
                                                        value={values.mobileNum1}
                                                        name="mobileNum1"
                                                        data-testid='input-member-edit-phone-1'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.mobileNum1 &&
                                                            touched.mobileNum1 &&
                                                            errors.mobileNum1}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("mobileNum2")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Mobile Number 2
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            onChange(
                                                                e.target.value,
                                                                "mobileNum2",
                                                                setFieldValue
                                                            )
                                                        }
                                                        onBlur={handleBlur}
                                                        value={values.mobileNum2}
                                                        name="mobileNum2"
                                                        data-testid='input-member-edit-phone-2'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.mobileNum2 &&
                                                            touched.mobileNum2 &&
                                                            errors.mobileNum2}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("mobileNum3")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Mobile Number 3
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            onChange(
                                                                e.target.value,
                                                                "mobileNum3",
                                                                setFieldValue
                                                            )
                                                        }
                                                        onBlur={handleBlur}
                                                        value={values.mobileNum3}
                                                        name="mobileNum3"
                                                        data-testid='input-member-edit-phone-3'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.mobileNum3 &&
                                                            touched.mobileNum3 &&
                                                            errors.mobileNum3}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("memberEmail")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Member Email
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.memberEmail}
                                                        name="memberEmail"
                                                        data-testid='input-member-edit-email'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.memberEmail &&
                                                            touched.memberEmail &&
                                                            errors.memberEmail}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {/* <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("planName")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Plan Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.planName}
                                                        name="planName"
                                                        disabled={true}
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.planName &&
                                                            touched.planName &&
                                                            errors.planName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("planNumber")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Plan Number
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.planNumber}
                                                        name="planNumber"
                                                        disabled={true}
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.planNumber &&
                                                            touched.planNumber &&
                                                            errors.planNumber}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row> */}

                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("dateOfBirth")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Date of Birth
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.dateOfBirth}
                                                        name="dateOfBirth"
                                                        disabled
                                                        data-testid='input-member-edit-dob'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.dateOfBirth &&
                                                            touched.dateOfBirth &&
                                                            errors.dateOfBirth}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("memberAddress1")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Member Address 1
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.memberAddress1}
                                                        name="memberAddress1"
                                                        data-testid='input-member-edit-address'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.memberAddress1 &&
                                                            touched.memberAddress1 &&
                                                            errors.memberAddress1}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("memberAddress2")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Member Address 2
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.memberAddress2}
                                                        name="memberAddress2"
                                                        data-testid='input-member-edit-alt-address'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.memberAddress2 &&
                                                            touched.memberAddress2 &&
                                                            errors.memberAddress2}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("memberCity")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Member City
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.memberCity}
                                                        name="memberCity"
                                                        data-testid='input-member-edit-city'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.memberCity &&
                                                            touched.memberCity &&
                                                            errors.memberCity}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("memberState")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Member State
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.memberState}
                                                        name="memberState"
                                                        data-testid='input-member-edit-state'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.memberState &&
                                                            touched.memberState &&
                                                            errors.memberState}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("zipCode")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Zip Code
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            onChange(
                                                                e.target.value,
                                                                "zipCode",
                                                                setFieldValue
                                                            )
                                                        }
                                                        onBlur={handleBlur}
                                                        value={values.zipCode}
                                                        name="zipCode"
                                                        data-testid='input-member-edit-zip-code'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.zipCode &&
                                                            touched.zipCode &&
                                                            errors.zipCode}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className={
                                                            isFieldRequired("memberUpdateNotes")
                                                                ? "rm-required-field"
                                                                : ""
                                                        }
                                                    >
                                                        Notes
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.memberUpdateNotes}
                                                        as="textarea"
                                                        name="memberUpdateNotes"
                                                        data-testid='input-member-edit-notes'
                                                    />
                                                    <Form.Text className="text-small text-danger">
                                                        {errors.memberUpdateNotes &&
                                                            touched.memberUpdateNotes &&
                                                            errors.memberUpdateNotes}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="top-spacer">
                                            <Col md={{ offset: "4", span: "4" }}>
                                                <Button
                                                    className="btn-block"
                                                    variant="outline-dark"
                                                    block
                                                    onClick={() => modalRef.current.handleClose()}
                                                    disabled={isSubmitting}
                                                    data-testid='btn-member-edit-cancel'
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                            <Col md={4} className="bottom-spacer">
                                                <Button
                                                    type="submit"
                                                    variant="success"
                                                    block
                                                    disabled={isSubmitting || !(dirty && isValid)}
                                                    className="btn-block"
                                                    data-testid='btn-member-edit-save'
                                                >
                                                    {isSubmitting ? "Updating ... " : "Update"}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Container>
            }
        </DismissableModal>
    );

}

export default withRouter(UpdateMemberInfo);