import React from 'react';
import {env} from '../config';

export const EnvironmentBadge = () => {
    return (<>
        {
            env.environment
                ? env.environment === 'master'
                    ? null 
                    : <div className="ribbon-topbar ribbon-top-left-topbar"><span className='rm-blue-light'>{env.environment}</span></div>
                : <div className="ribbon-topbar ribbon-top-left-topbar"><span className='rm-blue-light'>Local</span></div>
        }
    </>
    );
}

