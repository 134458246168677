import React from 'react';
import {Link} from 'react-router-dom';

// variant: outline-{style} | {style}
// size: tradition bs size
// block: true | false

const BootstrapLink = (prop) => (
  <Link 
    to={prop.to} 
    className={`btn btn-${prop.variant} ${prop.block ? "btn-block" : ""} btn-${prop.size}`}
    onClick={prop.onClick}
  >
    {prop.children}
  </Link>
);

export default BootstrapLink;