import React, { useRef } from 'react';
import { Pagination, Dropdown, Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import Checkbox from 'commons/table/Checkbox';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { showNotification } from 'services/NotificationService';
import { AgentWorkspaceConstants } from 'lookup/AgentWorkspaceConstants'
import { DEFAULT_SWIM_SWITCH_SORT } from 'lookup/SwimConstants'
import { withRouter } from "react-router-dom";

const CustomCallGroupDetailPagination = ({ currentPage, totalPages, history, pageSizeOptions, selectedPageSize, onChangePage, onChangePageSize, totalRecords, attributeName, callGroupData }) => {
  const handlePageChange = (page) => {
    onChangePage(page);
  };

  const handlePageSizeChange = (event) => {
    const pageSize = event;
    onChangePageSize(pageSize);
    setChecked(tableAttributesDto && tableAttributesDto[attributeName] === pageSize ? true : false);
  };

  const TABLE_ROW_COUNT = "TABLE_ROW_COUNT";
  const LOGGED_IN_USER_STORE_KEY = "logged_in_user";
  const userDto = JSON.parse(localStorage.getItem(LOGGED_IN_USER_STORE_KEY));
  const tableAttributesDto = userDto ? userDto.tableAttributes : null;
  const [checked, setChecked] = React.useState(tableAttributesDto && tableAttributesDto[attributeName] === selectedPageSize ? true : false);

  const handleDefaultPageSizeCheckBox = (target) => {
    setChecked(!checked);
    if (target.checked) {
      const dto = {
        type: TABLE_ROW_COUNT,
        name: attributeName,
        value: selectedPageSize,
      }
      sendAuthenticatedAsyncRequest(
        `/user/update-user-attribute`,
        "PUT",
        dto,
        (r) => {
          tableAttributesDto[attributeName] = selectedPageSize;
          const updatedUserDto = { ...userDto, tableAttributes: tableAttributesDto };
          localStorage.setItem(LOGGED_IN_USER_STORE_KEY, JSON.stringify(updatedUserDto));
          showNotification({
            title: 'Action successful!',
            message: 'User settings updated successfully.',
            position: 'tr',
            type: "success"
          });
       
        },
        (r) => {
          showNotification({
            title: 'Error!',
            message: "SOMETHING WENT WRONG",
            position: 'tr',
            type: "error"
          });
        }
      );
    }
  }

  const handleNextPage = () => {
    handlePageChange(currentPage + 1);
  }

  const handlePrevPage = () => {
    handlePageChange(currentPage - 1);
  }

  const disabled = typeof totalRecords != "number"

  const handleSwimSwitchCountButtonClick = () => {
    let filtered = [{id: 'wfItemUuidAsString', value: callGroupData.swimSwitches}];
    history.push({
      pathname: "/agent-workspace/swim-switch-list",
      state: { 
        pageSize: AgentWorkspaceConstants.DEFAULT_PAGE_SIZE, 
        page: 0, 
        sorted: DEFAULT_SWIM_SWITCH_SORT, 
        filtered: filtered, 
        resized: null, 
        expanded: null }
    });
  }

  return (
    <>
    <Button className='pull-left' onClick={handleSwimSwitchCountButtonClick} disabled = {callGroupData.swimSwitches ? callGroupData.swimSwitches.length < 1 : true}>Swim Switches: {callGroupData.swimSwitches ? callGroupData.swimSwitches.length : 0}</Button>
    <div className="d-flex justify-content-center align-items-center">
      <div className="mr-2">
        <Pagination>
          <OverlayTrigger overlay={<Tooltip>Previous</Tooltip>}>
            <Pagination.Prev
              disabled={currentPage === 0 || totalRecords === 0 || disabled}
              onClick={handlePrevPage}
            />
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Next</Tooltip>}>
            <Pagination.Next
              disabled={currentPage === totalPages - 1 || totalRecords === 0 || disabled}
              onClick={handleNextPage}
            />
          </OverlayTrigger>
        </Pagination>
      </div>
      <div className="mr-2">
        <span className="mr-2">
          Page {currentPage + 1} of {totalRecords === 0 ? 1 : totalPages}
        </span>
        <span className="mr-2">|</span>
        <span className="mr-2">Total Records: {totalRecords}</span>
      </div>
      <div>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-page-size">
            {selectedPageSize}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {pageSizeOptions.map((pageSize) => (
              <Dropdown.Item
                key={pageSize}
                active={selectedPageSize === pageSize}
                onClick={() => handlePageSizeChange(pageSize)}
              >
                {pageSize}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {attributeName && <div className="ml-2">
        <Checkbox
          checked={checked}
          onChange={e => handleDefaultPageSizeCheckBox(e.target)}
        />
        <label className="ml-1">
          Set as Default
        </label>
      </div>}
    </div>
    </>
  );
};

export default withRouter(CustomCallGroupDetailPagination);