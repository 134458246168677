import { RSAA } from 'redux-api-middleware';
import config from '../../../config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';
import { resetList } from './ActivitiesPopup.slice';

export const getSwitchActivitiesRequestAction = "GET_SWITCH_ACTIVITIES_REQUEST";
export const getSwitchActivitiesSuccessAction = "GET_SWITCH_ACTIVITIES_SUCCESS";
export const getSwitchActivitiesFailureAction = "GET_SWITCH_ACTIVITIES_FAILURE";

export const fetchSwitchActivities = (switchInfoUuid, isForSwimSwitchList, pageSize, page, filteredData, sorted) => (dispatch, getState) => {
  let filtered = JSON.parse(JSON.stringify(filteredData));
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/${isForSwimSwitchList ? `agent-workspace` : `member`}/switch/${switchInfoUuid}/activity/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, filtered, sorted }),
      types: [
        getSwitchActivitiesRequestAction,
        {
          type: getSwitchActivitiesSuccessAction,
          payload: async (action, state, res) => {
            const data = await res.json();
            return { pageSize: pageSize, filtered: filteredData, sorted, ...data };
          }
        },
        getSwitchActivitiesFailureAction
      ],
    }
  }
  return dispatch(rsaaObject);
};

export const resetSwitchActivities = () => (dispatch, getState) => {
  dispatch(resetList);
}
