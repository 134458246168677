import React from 'react';
import { Row, Col, Badge } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { resetMemberPrograms, fetchMemberPrograms } from "./CurrentPrograms.actions";

import './CurrentPrograms.css';

const CurrentPrograms = ({ memberInfoId }) => {
  const dispatch = useDispatch();
  const memberPrograms = useSelector(state => state.memberPrograms);
  const { isNotifyEnabled, hasMemberOptedOut, isRxDiscountEnabled, isLoading, error } = memberPrograms;

  React.useEffect(() => {
    dispatch(fetchMemberPrograms(memberInfoId));
    return () => {
      dispatch(resetMemberPrograms());
    }
  }, []);

  return (
    <Col md={12}>
      <Row className={"underlay"}>
        <Col md={5} lg={3} className={"title"}>
          {"Current Programs:"}
        </Col>
        <Col>
          {isLoading ?
            <div className={"loading"}>{"Loading..."}</div>
            : error ?
              <div className={"loading"}>{"Failed to fetch Member Programs"}</div>
              :
              <div className={"badge-div"}>
                {hasMemberOptedOut && <Badge bg={"secondary"} className={"badge-style"}>Member Opted Out</Badge>}
                {isNotifyEnabled && <Badge bg={"warning"} className={"badge-style"}>Notify</Badge>}
                {isRxDiscountEnabled && <Badge bg={"info"} className={"badge-style"}>Razor Discount</Badge>}
              </div>
          }
        </Col>
      </Row>
    </Col>
  )
}
export default CurrentPrograms;
