import React from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';

import { twoDecimalPlaceRounder } from '../../services/UtilityService.js';

const styles = {
  card: {padding: 10, border: "none"},
  cardHeader: {paddingLeft: 0,backgroundColor: 'white', letterSpacing: 2}
}

const ProjectedSavingsCard = (props) => (
  <Card style={styles.card} variant="info">
    <Card.Header style={styles.cardHeader}>
      PROJECTED SAVINGS
    </Card.Header>
    <Card.Body>
      <Table hover className="intv-details" >
        <tbody>
          <tr>
            <th>Paid</th>
            <td>$ {twoDecimalPlaceRounder(props.paid)}</td>
          </tr>
          <tr>
            <th>Projected</th>
            <td>$ {twoDecimalPlaceRounder(props.projected)}</td>
          </tr>
          <tr>
            <th>Savings</th>
            <td>
              <Badge className={props.savings > 0 ? 'badge-success' : 'badge-danger'} style={{ fontSize: "1.2em" }}>$ {twoDecimalPlaceRounder(props.savings)}</Badge>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);

export default ProjectedSavingsCard;