import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const updateBulkActionList = (pageSize, page, sorted, filtered, resized, expanded) => {
    return {
  
      [RSAA]: {
        endpoint: `${config.apiRoot}/bulk-action/list`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
        body: JSON.stringify({ pageSize, page, sorted, filtered }),
        types: [
          {
            type: 'UPDATE_BULK_ACTION_LIST_REQUEST',
            payload: { listState: {pageSize, page, sorted, filtered, resized, expanded} }
          },
          'UPDATE_BULK_ACTION_LIST_SUCCESS',
          'UPDATE_BULK_ACTION_LIST_FAILURE'
        ]
      }
    };
  };
  