import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const codexVersionPersistConfig = {
  key: 'codex',
  storage
};

export const Reducer = persistReducer(
  codexVersionPersistConfig,
  (previousState, action) => {
    let newState = Object.assign({}, previousState);

    switch (action.type) {
      case 'SAVE_WORKING_CODEX_INFO':
        newState = Object.assign({}, previousState, {
          codexVersion: action.payload.codexVersion,
          codexVersionId: action.payload.codexVersionId,
          codexCreationDate: action.payload.creationDate,
          codexTag: action.payload.tag,
          isWorkingVersionEditable: action.payload.isWorkingVersionEditable,
          codexVersionStatus: action.payload.status,
          firstApprovalBy: action.payload.firstApprovalBy
        });
        break;
      case 'SAVE_SELECTED_CUSTOMER':
        newState = Object.assign({}, previousState, {
          customerId: action.payload.customerId,
          customerLabel: action.payload.customerLabel
        });
        break;
      case 'SAVE_WORKING_CODEX_TAG':
        newState = Object.assign({}, previousState, {
          codexTag: action.payload.tag
        });
        break;
      default:
        break;
    }

    return newState;
  }
);
