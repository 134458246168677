import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { getIfPublishStatus } from "../PawGroup/PawGroup.constants";
import {
  getPAWGroupDetailFailureAction,
  getPAWGroupDetailRequestAction,
  getPAWGroupDetailSuccessAction,
} from "./PAWGroupDisplayBar.actions";

const initialState = {
  clientName: "",
  pawGroupId: null,
  openSwitches: 0,
  autoApprovedSwitches: 0,
  switchCreationDate: "",
  autoRejectedSwitches: 0,
  manualApprovedSwitches: 0,
  manualRejectedSwitches: 0,
  totalSwitches: 0,
  status: "",
  error: false,
  loading: false,
  wasLastPawGroupPublished: false,
};

export const pawGroupDetailSlice = createSlice({
  name: "pawGroupDetail",
  initialState: initialState,
  reducers: {
    getPAWGroupDetailSuccess: (state, action) => {
      state.totalSwitches = action.payload.totalSwitches;
      state.openSwitches = action.payload.openSwitches;
      state.autoApprovedSwitches = action.payload.autoApprovedSwitches;
      state.autoRejectedSwitches = action.payload.autoRejectedSwitches;
      state.switchCreationDate = format(
        new Date(action.payload.switchCreationDate),
        "MM/dd/yyyy"
      );
      state.clientName = action.payload.clientInfoDto
        ? action.payload.clientInfoDto.clientName
        : action.payload.customerInfoDto.customerName;
      state.manualApprovedSwitches = action.payload.manuallyApprovedSwitches;
      state.manualRejectedSwitches = action.payload.manuallyRejectedSwitches;
      state.status = action.payload.status;
      state.pawGroupId = action.payload.pawGroupUuid;
      state.error = false;
      state.loading = false;
    },
    getPAWGroupDetailFailure: (state, action) => {
      state.loading = false;
      state.error = false;
    },
    getPAWGroupDetailLoading: (state, action) => {
      state.loading = true;
    },
    RESET_PAW_GROUP_DETAIL: (state, action) => {
      state.wasLastPawGroupPublished = !!(
        state.status && getIfPublishStatus(state.status)
      );
      state.clientName = "";
      state.pawGroupId = null;
      state.openSwitches = 0;
      state.autoApprovedSwitches = 0;
      state.switchCreationDate = "";
      state.autoRejectedSwitches = 0;
      state.manualApprovedSwitches = 0;
      state.manualRejectedSwitches = 0;
      state.totalSwitches = 0;
      state.status = "";
      state.error = false;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "PAW_GROUP" RSAA action.
      .addCase(getPAWGroupDetailRequestAction, (state, action) => {
        pawGroupDetailSlice.caseReducers.getPAWGroupDetailLoading(
          state,
          action
        );
      })
      .addCase(getPAWGroupDetailSuccessAction, (state, action) => {
        pawGroupDetailSlice.caseReducers.getPAWGroupDetailSuccess(
          state,
          action
        );
      })
      .addCase(getPAWGroupDetailFailureAction, (state, action) => {
        pawGroupDetailSlice.caseReducers.getPAWGroupDetailFailure(
          state,
          action
        );
      });
  },
});

export const {
  getPAWGroupDetailFailure,
  getPAWGroupDetailSuccess,
  getPAWGroupDetailLoading,
  RESET_PAW_GROUP_DETAIL,
} = pawGroupDetailSlice.actions;
export const pawGroupDetailState = (state) => state.pawGroupDetail;
export default pawGroupDetailSlice.reducer;
