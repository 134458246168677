import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Col } from "react-bootstrap";
import BatchScheduler from "views/Batch/BatchScheduler/BatchScheduler";
import CycleScheduler from "views/Cycle/CycleScheduler/CycleScheduler";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import BulkActionForm from "./BulkActionForm";
import PageLoader from "layouts/components/PageLoader";
import { useLocation } from "react-router-dom";
import { drop_down_options } from "lookup/BulkActionListConstants";
const customStyles = {
  multiValue: (base) => ({
    background: "#007BBB",
    display: "inline-flex",
    color: "white",
    borderRadius: "11px",
    alignItems: "center",
    margin: "2px",
  }),
  multiValueLabel: (base) => ({
    fontSize: "13px",
    color: "white",
    padding: "4px 10px 4px 10px",
  }),
};

const getActionTypeValue = (actionType) => {
  if (!actionType || typeof actionType !== "object") return null;
  return actionType.value || null;
};
const SchedularForm = (props) => {
  const location = useLocation();
  const { bulkActionUuid } = props.match.params;
  const [actionType, setActionType] = useState(
    location.state?.bulkActionType
      ? {
          label: location.state.bulkActionType,
          value: location.state.bulkActionType,
        }
      : ""
  );
  const [isFromProps, setIsFromProps] = useState(
    location.state?.bulkActionType ? true : false
  );
  const [filterSelectMap, setFilterSelectMap] = useState({});
  const [filterSelectMapOriginal, setFilterSelectMapOriginal] = useState({});
  const [isRerouting, setIsRerouting] = useState(false);

  const fetchData = async () => {
    sendAuthenticatedAsyncRequest(
      `/swim/batch/get-client-customer-filters`,
      "GET",
      null,
      (r) => {
        if (r.data.filters !== null) {
          updateFilters(r.data.filters);
        }
      },
      (r) => {
        showNotification({
          title: "Error fetching Filter Values!",
          message: "Something went Wrong.",
          position: "tr",
          type: "error",
        });
      }
    );
  };

  const updateFilters = (tempFilters) => {
    let tempFilterSelectMap = {};
    Object.keys(tempFilters).forEach(
      (colKey, i) =>
        (tempFilterSelectMap[colKey] = tempFilters[colKey].map((row, index) => {
          return { value: row["id"], label: row["value"] };
        }))
    );
    tempFilterSelectMap["customerNameAbbreviation"].unshift({
      label: "ALL",
      value: tempFilterSelectMap["customerNameAbbreviation"].map(
        (e) => e.value
      ),
    });
    tempFilterSelectMap["clientNameAbbreviation"].unshift({
      label: "ALL",
      value: tempFilterSelectMap["clientNameAbbreviation"].map((e) => e.value),
    });
    setFilterSelectMap(tempFilterSelectMap);
    setFilterSelectMapOriginal(tempFilterSelectMap);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeAction = (event) => {
    setActionType(event);
  };

  return isRerouting ? (
    <PageLoader />
  ) : (
    <div>
      <Col md={5} style={{ marginTop: "20px" }}>
        <h3>Bulk Action Type</h3>
        <Select
          options={drop_down_options}
          value={actionType}
          onChange={handleChangeAction}
          placeholder="Bulk Action"
          closeMenuOnSelect={false}
          styles={customStyles}
          isDisabled={location.state?.isViewable}
        />
      </Col>
      {actionType && getActionTypeValue(actionType) === "Batch" && (
        <BatchScheduler />
      )}
      {actionType && getActionTypeValue(actionType) === "Cycle" && (
        <CycleScheduler />
      )}
      {actionType &&
        getActionTypeValue(actionType) !== "Batch" &&
        getActionTypeValue(actionType) !== "Cycle" && (
          <BulkActionForm
            actionType={actionType}
            filterSelectMap={filterSelectMap}
            filterSelectMapOriginal={filterSelectMapOriginal}
            setFilterSelectMap={setFilterSelectMap}
            setFilterSelectMapOriginal={setFilterSelectMapOriginal}
            setIsRerouting={setIsRerouting}
            descriptionFromProps={location.state?.description}
            scheduledDate={location.state?.bulkActionScheduledDate}
            clientNames={location.state?.clientList || []}
            customerName={location.state?.customerName || []}
            isFromProps={isFromProps}
            setIsFromProps={setIsFromProps}
            noteFromProps={location.state?.note}
            noOfSwitches={location.state?.noOfSwitches || 0}
            bulkActionUuid={bulkActionUuid}
            isEditable={location.state?.isEditable}
            isViewable={location.state?.isViewable}
            showCancel={location.state?.showCancel}
            isCompleted={location.state?.isCompleted}
          />
        )}
    </div>
  );
};

export default withRouter(SchedularForm);
