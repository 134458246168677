export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case 'SCHEDULE_CYCLE_REQUEST':
      newState = Object.assign({}, previousState, {
        error: '',
        submittingRequest: 'Submiting Scheduled Cycle'
      });
      break;
    case 'SCHEDULE_CYCLE_SUCCESS':
      newState = Object.assign({}, previousState, {
        error: '',
        submittingRequest: '',
        isSuccess: true,
        description: '',
        switchList: [],
        eventRequestCompleted: true
      });
      break;
    case 'SCHEDULE_CYCLE_FAILURE':
      newState = Object.assign({}, previousState, {
        error: 'Unable to create cycle',
        submittingRequest: '',
        description: '',
        eventRequestCompleted: false
      });
      break;
    case 'RESET_SUCCESS_MESSAGE_CYCLE_SCHEDULER':
      newState = Object.assign({}, previousState, {
        error: '',
        isSuccess: false,
      });
      break;
    default:
      break;
  }

  return newState;
}