import { RSAA } from 'redux-api-middleware';
import jwtDecode from 'jwt-decode';
import config from 'config';

const AUTH_TOKEN_STORE_KEY = "authorization_token";
const DECODED_AUTH_TOKEN_STORE_KEY = "decoded_authorization_token";
const AUTH_REFRESH_TOKEN_STORE_KEY = "refresh_token";
const AUTH_ACCESS_TOKEN_STORE_KEY = "access_token";
const LOGGED_IN_USER_STORE_KEY = "logged_in_user";
const BACKEND_COMMIT_VERSION_ID = "commit_version_id";
const BACKEND_RELEASE_NUMBER = "release_number";
export const AUTH_TOKEN_HEADER_KEY = "Authorization";

export const TOKEN_REQUEST = '@@auth/TOKEN_REQUEST';
export const TOKEN_RECEIVED = '@@auth/TOKEN_RECEIVED';
export const TOKEN_FAILURE = '@@auth/TOKEN_FAILURE';

function createPromise(handler){
  var resolve, reject;

  var promise = new Promise(function(_resolve, _reject){
    resolve = _resolve; 
    reject = _reject;
    if(handler) handler(resolve, reject);
  })
  
  promise.resolve = resolve;
  promise.reject = reject;
  return promise;
}
// create
var backendBuildVersionPromise = createPromise(resolveIfBuildVersionAvailable);
var backendReleaseNumberPromise = createPromise(resolveIfReleasNumbereAvailable);

function resolveIfBuildVersionAvailable(resolve, reject) {
  if(localStorage.getItem(BACKEND_COMMIT_VERSION_ID))
    resolve(localStorage.getItem(BACKEND_COMMIT_VERSION_ID));
}


function resolveIfReleasNumbereAvailable(resolve, reject) {
  if(localStorage.getItem(BACKEND_RELEASE_NUMBER))
    resolve(localStorage.getItem(BACKEND_RELEASE_NUMBER));
}

export const refreshAccessToken = () => ({
  [RSAA]: {
    endpoint: `${config.apiRoot}/login/refresh-token?code=${getRefreshToken()}`,
    method: 'GET',
    body: null,
    headers: { 'Content-Type': 'application/json' },
    types: [
      TOKEN_REQUEST, TOKEN_RECEIVED, TOKEN_FAILURE
    ]
  }
})

export function isAccessTokenExpired() {
  const authToken = JSON.parse(localStorage.getItem(DECODED_AUTH_TOKEN_STORE_KEY));
  if ( authToken ) {
    // if difference between authToken.exp and new Date() less than 5 seconds,
    // consider expired
    return 1000 * authToken.exp - (new Date()).getTime() < 5000;
  }
  return true;
}

export function isRefreshTokenExpired() {
  // TODO: add implementation here
  return false;
}

export function updateAccessToken(tokenType, token) {
  const decodedToken = jwtDecode(token);
  localStorage.setItem(AUTH_TOKEN_STORE_KEY, tokenType + " " + token);
  localStorage.setItem(DECODED_AUTH_TOKEN_STORE_KEY, JSON.stringify(decodedToken));
  return;
}

export function isAuthenticated() {
	return getAuthorizationToken() !== null;
}

export function getRefreshToken(){
  return localStorage.getItem(AUTH_REFRESH_TOKEN_STORE_KEY);
}

export function getAuthorizationToken(){
  return localStorage.getItem(AUTH_TOKEN_STORE_KEY);
}

export function getLoggedInUser(){
  return JSON.parse(localStorage.getItem(LOGGED_IN_USER_STORE_KEY));
}

export function getBackendCommitVersionIdPromise(){
  return backendBuildVersionPromise;
}

export function getBackendReleaseNumberPromise(){
  return backendReleaseNumberPromise;
}

export function logoutUser(redirectCallback) {
	localStorage.clear();
  window.location.reload(true);

  if (typeof redirectCallback === 'function')
	  redirectCallback();
}

export function loginUser(response) {
 
  const authorization_token = response.headers.authorization;
  const backend_commit_version_id = response.data.commitVersionId;
  const backend_release_number = response.data.currentReleaseNumber;
  
  // anything stored here must be removed on logout
  localStorage.setItem(AUTH_TOKEN_STORE_KEY, authorization_token);
  localStorage.setItem(BACKEND_COMMIT_VERSION_ID, backend_commit_version_id);
  localStorage.setItem(BACKEND_RELEASE_NUMBER, backend_release_number);
  localStorage.setItem(LOGGED_IN_USER_STORE_KEY, JSON.stringify(response.data));

  backendBuildVersionPromise.resolve(backend_commit_version_id);
  backendReleaseNumberPromise.resolve(backend_release_number);
}

export function cognitoLoginSuccess (loggedInUserData, token_type, authorization_token, refresh_token, access_token,
   backend_commit_version_id,backend_release_number) {
  updateAccessToken(token_type, authorization_token);

  localStorage.setItem(LOGGED_IN_USER_STORE_KEY, JSON.stringify(loggedInUserData));
  localStorage.setItem(AUTH_REFRESH_TOKEN_STORE_KEY, refresh_token);
  localStorage.setItem(AUTH_ACCESS_TOKEN_STORE_KEY, access_token);
  localStorage.setItem(BACKEND_COMMIT_VERSION_ID, backend_commit_version_id);
  localStorage.setItem(BACKEND_RELEASE_NUMBER, backend_release_number);

  backendBuildVersionPromise.resolve(backend_commit_version_id);
  backendReleaseNumberPromise.resolve(backend_release_number);
}
