import './PawGroups.css';

const PawGroupStatsItem = ({StatIcon, statLabel, statValue}) => {
  
  return (
    <div className='paw-group-stats-item'>
      <span className='paw-group-stats-heading'>
        <StatIcon className='paw-group-stats-icon' /> {statLabel}
      </span>
      <span className='paw-group-stats-value'> {statValue}</span>
    </div>
  );
};

export default PawGroupStatsItem;
