import React from 'react';
import Button from 'react-bootstrap/Button';
import DismissableModal from 'components/DismissableModal';
import { useState } from 'react';
import { useRef } from 'react';
import { formatDateForDisplay } from 'services/MomentUtils';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { showNotification } from 'services/NotificationService';

const styles = {
  buttonStyle: { margin: 0, padding: "3px 10px" },
  button: {marginRight: 10}
}
const PublishPaw = ({pawGroupId, date, client, refreshList}) => {
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef("publishPaw");
  const open = () => {
    modalRef.current.handleShow();
  }

  const modalOpener = <Button 
    style={styles.buttonStyle} 
    onClick={open} 
    variant="primary"
  >Publish</Button>

  const close = () => {
    if(modalRef?.current) {
      modalRef.current.handleClose();
    }
  }

  const publish = () => {
    setIsLoading(true);
    sendAuthenticatedAsyncRequest(
      `/paw-group/${pawGroupId}/publish`,
      'PUT', 
      null,
      (r) => {
        refreshList();
        setIsLoading(false);
        close();
        // dispatch success notification
        showNotification({
          title: `Paw Group published Successfully!`,
          message: 'This may take some time before the state gets updated',
          position: 'tr',
          type: "success"
        });
      },
      (r) => {
        setIsLoading(false);
        showNotification({
          title: 'Something went wrong!',
          message: 'Could not publish the Paw Group. Please try again later',
          position: 'tr',
          type: "error"
        });
      },
    );
  }

  const customFooterContent = (
    <div>
      <Button 
        variant="outline-dark" 
        style={styles.button}
        disabled={isLoading}
        onClick={ () => modalRef.current.handleClose() }>
        Cancel
      </Button>
      <Button 
        className="left-spacer" 
        variant={'success'}
        disabled={isLoading}
        onClick={publish}>
        {isLoading ? 'Publishing...' : 'Publish'}
      </Button>
    </div>
  );

  return (
    <DismissableModal
      ref={modalRef}
      key={date + client}
      openModalButton={modalOpener}
      title={`Publish Paw Group ${formatDateForDisplay(date)} (${client}`}
      customFooterContent={customFooterContent}
      disabled={isLoading}
    >
      {`Are you sure you want to publish this paw group?`}
    </DismissableModal>
  );
}
export default PublishPaw;