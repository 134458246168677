import React from 'react';

import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DismissableModal from '../DismissableModal';

import { getGender,formatPhoneNumber  } from '../../services/UtilityService';
import { formatDateForDisplay } from '../../services/MomentUtils';

const LittleList = (props) => (
  <span>
    <div className="medicine-name text-center">{props.label}</div>
    <Table hover size="sm" bordered >
      <tbody>
        {
          props.rows.map((r, i) =>
            <tr key={i}>
              <th>{r.label}</th>
              <td>{r.value}</td>
            </tr>
          )
        }
      </tbody>
    </Table>
  </span>
);

const OriginalClaimPopup = (props) => {
  const switchInfo = props.switchInfo;
  const memberInfo = switchInfo.rirMemberInfo;
  const prescriberInfo = switchInfo.prescriberInfo;
  const pharmacyInfo = switchInfo.pharmaInfo;
  const claimInfo = switchInfo.rirClaimInfo;
  return (
    <DismissableModal
      openModalButtonText="Original Claim"
      openModalButtonBlock={true}
      openModalButtonId='switchdetails-btn-original-claim-popup'
      title="Original Claim Information"
      modalClassName="super-wide-modal" >
      <Container fluid>
        <Row>
          <Col md={{ offset: 2, span: 4 }}>
            {
              memberInfo
                ? <LittleList
                  label="Member"
                  rows={[
                    { label: "Member Number", value: memberInfo.memberNumber },
                    { label: "Member Gender", value: getGender(memberInfo.gender) },
                    { label: "Member Relationship to Employer", value: memberInfo.relationship },
                    { label: "Plan Name", value: memberInfo.planName }
                  ]} />
                : <span>Member info not available. <br /></span>
            }
            {
              prescriberInfo
                ? <LittleList
                  label="Prescriber"
                  rows={[
                    { label: "Name", value: prescriberInfo.prescriberName },
                    { label: "NPI", value: prescriberInfo.prescriberGlobalId },
                    { label: "Phone", value: formatPhoneNumber(prescriberInfo.prescriberPhone) }
                  ]} />
                : <span>Prescriber info not available. <br /></span>
            }

            {
              pharmacyInfo
                ? <LittleList
                  label="Pharmacy"
                  rows={[
                    { label: "Name", value: pharmacyInfo.pharmacyName },
                    { label: "NABPcode", value: pharmacyInfo.pharmacyGlobalId },
                    { label: "Phone", value: formatPhoneNumber(pharmacyInfo.pharmacyPhone)  }
                  ]} />
                : <span>Pharmacy info not available. <br /></span>
            }

          </Col>
          <Col md={4}>
            <LittleList
              label="Claim"
              rows={[
                { label: "Filled on", value: claimInfo ? formatDateForDisplay(claimInfo.claimFillDate) : "N/A" },
                { label: "Claim Number", value: switchInfo.claimNumber },
                { label: "Claim Drug Name", value: switchInfo.claimDrugName },
                { label: "Claim Drug Strength", value: switchInfo.claimDrugStrength },
                { label: "Source Days Supply", value: `${switchInfo.sourceDaysSupply} days` },
                { label: "Created on", value: formatDateForDisplay(switchInfo.creationDate) },
                { label: "Source NDC Number", value: switchInfo.sourceNdcNumber },
                { label: "Target Controlled Substance", value: switchInfo.targetControlledSubstance },
                { label: "Prescription Number", value: claimInfo ? claimInfo.rxNumber : "N/A" }
              ]} />

          </Col>
        </Row>
      </Container>
    </DismissableModal>
  );
}

export default OriginalClaimPopup;