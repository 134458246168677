import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCallGroupDetails,
  getCallGroupActivities,
  getActivityDetails,
  resetError,
  autoAssignCallGroup,
  resetAutoAssignError,
  resetActivityUponClose,
  clearCallGroupDetail,
  resetCallGroupActivities,
} from "./CallGroupDetails.actions";
import CustomCallGroupDetailPagination from 'components/CallGroupDetails/CustomCallGroupDetailPagination';
import { useHistory, useLocation, withRouter } from "react-router-dom";
import ScrollableNotes from "components/ScrollableNotes/ScrollableNotes";
import Container from "react-bootstrap/Container";
import { Row, Col, } from 'react-bootstrap';
import { WrapTextAndJustifyCenter } from "components/WrapText";
import _ from "lodash";
import { Alert, Collapse, FormSelect } from "react-bootstrap";
import CustomerAndClientTitle from "components/CustomerAndClientTitle";
import { formatDateWithTime } from "services/MomentUtils";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import { NotesFilterConstants } from "lookup/NotesFilterConstants";
import {
  isStringEmptyOrNull,
  twoDecimalPlaceRounder,
  getPageSize
} from "services/UtilityService";
import Spinner from "components/Spinner";
import LeftBar from "components/CallGroupDetails/LeftBar";
import BottomBar from "components/CallGroupDetails/BottomBar";
import {
  isOwnedByLoggedInUser,
  isLoggedInUserAWAdmin,
  isOwnedByAnyone,
} from "services/AWUtilityService";
import CollapsedNotes from "components/CollapsedNotes";
import { showNotification } from "services/NotificationService";
import Colors from "lookup/Colors.json";
import RmPopoverButton from "components/RmPopoverButton";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { getErrorMessage } from "services/UtilityService";
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import DisplayBar from 'commons/table/DisplayBar/DisplayBar'
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import { extractStatusFilterForDetailsPage } from "services/AWUtilityService";

const DEFAULT_PAGE_SIZE = 8;

const CallGroupDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ref = useRef(true);
  const [showPrescriberColumn, setShowPrescriberColumn] = useState(true);
  let { callGroupId } = props.match.params;
  const callGroupData = useSelector(
    (state) => state.callGroupDetails.callGroupData
  );
  const loading = useSelector((state) => state.callGroupDetails.loading);
  const data = useSelector((state) =>
    state.callGroupDetails.data !== undefined ? state.callGroupDetails.data : []
  );
  const isError = useSelector((state) => state.callGroupDetails.isError);
  const pages = useSelector((state) =>
    state.callGroupDetails.pages ? state.callGroupDetails.pages : 0
  );
  const pageSizeValue = useSelector((state) =>
    state.callGroupDetails.pageSize ? state.callGroupDetails.pageSize : 0
  );
  const ACTIVITY_LIST_ROW_COUNT = "ACTIVITY_LIST_ROW_COUNT";
  const pageSize = getPageSize(DEFAULT_PAGE_SIZE, pageSizeValue, ACTIVITY_LIST_ROW_COUNT);
  const totalElements = useSelector((state) =>
    state.callGroupDetails.totalElements !== undefined
      ? state.callGroupDetails.totalElements
      : "Loading..."
  );
  const status = props.location.state ? extractStatusFilterForDetailsPage(props.location.state.status) : null;
  const listState = useSelector((state) =>
    state.callGroupDetails.listState !== undefined &&
      !ref.current &&
      !(props.location.state && props.location.state.navigation) &&
      history.action !== "POP"
      ? state.callGroupDetails.listState
      : {
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        sorted: [AgentWorkspaceConstants.DEFAULT_SORT],
        filtered: [{ id: "status", value: status ? status : "" }],
        resized: [],
        expanded: {},
      }
  );
  if (history.action === "POP") {
    //to handle back event
    history.replace(location.pathname, { status: status, navigation: null });
  }
  const activityDetails = useSelector(
    (state) => state.callGroupDetails.activityDetails
  );
  const activityLoading = useSelector((state) =>
    state.callGroupDetails.activityLoading !== undefined
      ? state.callGroupDetails.activityLoading
      : false
  );
  const autoAssignStatus = useSelector(
    (state) => state.callGroupDetails.autoAssignStatus
  );
  const autoAssignError = useSelector(
    (state) => state.callGroupDetails.autoAssignError
  );
  const [selectedActivityId, setSelectedActivityId] = useState(0);
  const [callGroupAssignedToLoggedInUser, setCallGroupAssignedToLoggedInUser] =
    useState(false);
  let navigation = props.location.state
    ? props.location.state.navigation
    : null;
  const [isNavigation, setIsNavigation] = useState(false); // for rerendering when callgroup is same but activity is different
  let isNavigationCase = false;

  const columns = [
    {
      header: "Switch #",
      accessor: "globalSwitchId",
      accessorKey: "globalSwitchId",
      size: 70,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Rank",
      accessor: "priority",
      accessorKey: "priority",
      size: 40,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Prescriber",
      accessor: "prescriberName",
      accessorKey: "prescriberName",
      size: 60,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
      show: showPrescriberColumn,
    },
    {
      header: "Pharmacy",
      accessor: "pharmacyName",
      accessorKey: "pharmacyName",
      size: 60,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
      show: !showPrescriberColumn,
    },
    {
      header: "Activity Name",
      accessor: "activityName",
      accessorKey: "activityName",
      size: 80,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Scheduled At",
      accessor: "scheduledDate",
      accessorKey: "scheduledDate",
      size: 90,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return renderLastModifiedDateCol(
          row.original.scheduledDate,
          row.original.daysAfterLastModifiedDate
        )
      },
      filterType: FilterTypes.DateRangePicker,
    },
    {
      header: "Savings per fill",
      accessor: "projectedSavings",
      accessorKey: "projectedSavings",
      size: 60,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return (<WrapTextAndJustifyCenter>
          ${twoDecimalPlaceRounder(row.original.projectedSavings)}
        </WrapTextAndJustifyCenter>)
      },
    },
    {
      header: "Status",
      accessor: "status",
      accessorKey: "status",
      size: 130,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SingleSelect,
      filterOptions: AgentWorkspaceConstants.activityStatusFilter.map((status, i) => ({ value: status, key: status }))
    },
  ];

  const getRowStyle = (rowInfo) => {
    if (rowInfo == undefined) return;
    if (
      rowInfo.original.status === "NEW" &&
      selectedActivityId !== rowInfo.index
    ) {
      return {
        background: Colors["rm-gray-shadow"],
        color: Colors["rm-black"],
      };
    } else {
      return {
        background: selectedActivityId === rowInfo.index ? "#00afec" : "white",
        color: selectedActivityId === rowInfo.index ? "white" : "black",
      };
    }
  };

  const updateData = (listStateParam = null, preventUpdate = false) => {
    if (!listStateParam) listStateParam = listState;
    const { pageSize, page, sorted, filtered, resized, expanded } =
      listStateParam;
    if (!preventUpdate) {
      dispatch(
        getCallGroupActivities(
          callGroupId,
          pageSize,
          page,
          sorted,
          filtered,
          resized,
          expanded
        )
      );
    }
  };

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    listState.pageSize = pageSize;
    listState.page = pageNo;
    listState.sorted = sortBy ? sortBy : [];
    listState.filtered = filterValues;
    updateData(listState);
  }

  const refresh = () => {
    updateData(listState);
  }

  const reset = () => {
    listState.pageSize = DEFAULT_PAGE_SIZE;
    listState.page = 0;
    listState.sorted = [];
    listState.filtered = [];
    updateData(listState);
  }

  const handleActivityNavigation = () => {
    setIsNavigation(!isNavigation); // to rerender if callgroup is same but activity is diff
  };

  const attemptAutoAssign = (callGroupData) => {
    if (callGroupData === undefined || callGroupData === null) return;
    if (!isOwnedByAnyone(callGroupData)) {
      // no owner assigned, proceed with autoclaim
      dispatch(autoAssignCallGroup(callGroupData.callGroupId)).then(() => {
        setCallGroupAssignedToLoggedInUser(
          isOwnedByLoggedInUser(callGroupData)
        );
      });
    }
  };

  const renderOwnerInformation = (callGroupData, autoAssignStatus) => {
    if (autoAssignStatus === "requested") {
      return <Alert variant="info">Auto-claiming this call group ...</Alert>;
    } else if (autoAssignStatus === "failure") {
      return <Alert variant="danger">Auto-claiming call group failed!</Alert>;
    }
    let variant = "warning";
    if (autoAssignStatus === "success" || autoAssignStatus === "dead")
      variant = "success";
    else if (autoAssignStatus === "ready") {
      if (isOwnedByLoggedInUser(callGroupData)) variant = "success";
    }

    if (isOwnedByAnyone(callGroupData))
      return (
        <Alert variant={variant} className="owner-alert-box">
          Claimed by: <strong>{callGroupData.claimerName}</strong>
        </Alert>
      );
    else
      return (
        <Alert variant={"danger"} className="owner-alert-box">
          This Call Group is not claimed by anyone
        </Alert>
      );
  };

  const renderDateCell = (date) => {
    return (
      <span
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        {date}
      </span>
    );
  };

  const renderLastModifiedDateCol = (date, days) => {
    if (date) {
      return renderDateCell(formatDateWithTime(date));
    }
    return <span className="center">N/A</span>;
  };

  const redirectToListingPage = useCallback(() => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(`/agent-workspace/list`);
    }
  });

  const unclaimCallGroup = useCallback((doNotRedirect) => {
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/${callGroupId}/unclaim`,
      "GET",
      null,
      (r) => {
        if(!doNotRedirect) redirectToListingPage();
        dispatch(clearCallGroupDetail());
        resetActivityUponClose();
      },
      (r) => {
        showNotification({
          title: "Unable to release Call Group!",
          message:
            getErrorMessage(r.data) || getErrorMessage("SOMETHING_WENT_WRONG"),
          position: "tr",
          type: "error",
        });
      }
    );
  });

  const unassignCallGroup = useCallback(() => {
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/${callGroupId}/unassign`,
      "GET",
      null,
      (r) => {
        redirectToListingPage();
        dispatch(clearCallGroupDetail());
        resetActivityUponClose();
      },
      (r) => {
        showNotification({
          title: "Unable to unassign Call Group!",
          message:
            getErrorMessage(r.data) || getErrorMessage("SOMETHING_WENT_WRONG"),
          position: "tr",
          type: "error",
        });
      }
    );
  });

  const handleRowClick = (rowInfo) => {
    setSelectedActivityId(rowInfo.index);
  };

  const refreshActivity = () => {
    setSelectedActivityId(0);
  }

  const fetchActivityDetails = (activityId) => {
    if (isStringEmptyOrNull(activityId)) {
      dispatch(getActivityDetails(activityDetails.activityId));
    } else {
      dispatch(getActivityDetails(activityId));
    }
  };

  useEffect(() => {
    return () => {
      unclaimCallGroup(true);
      dispatch(resetActivityUponClose());
      dispatch(resetCallGroupActivities());
    };
  }, []);

  useEffect(() => {
    const firstRender = ref.current;
    if (firstRender) {
      ref.current = false;
    }
  });

  useEffect(() => {
    dispatch(clearCallGroupDetail());
    dispatch(getCallGroupDetails(callGroupId));
  }, [callGroupId, isNavigation, status]);

  useEffect(() => {
    updateData(listState);
  }, [callGroupId, isNavigation, status]);

  useEffect(() => {
    if (
      AgentWorkspaceConstants.PREDECESSOR === navigation &&
      activityDetails &&
      activityDetails.predecessorActivity
    ) {
      const index = data.findIndex(
        (activity) =>
          activity.activityId ===
          activityDetails.predecessorActivity.activityId
      );
      setSelectedActivityId(index);
      if (index != selectedActivityId)
        isNavigationCase = true;
      history.replace(location.pathname, { status: status, navigation: null }); //removing navigation state from url to fetch correct listState
    } else if (
      AgentWorkspaceConstants.SUCCESSOR === navigation &&
      activityDetails &&
      activityDetails.successorActivity
    ) {
      const index = data.findIndex(
        (activity) =>
          activity.activityId === activityDetails.successorActivity.activityId
      );
      setSelectedActivityId(index);
      if (index != selectedActivityId)
        isNavigationCase = true;
      history.replace(location.pathname, { status: status, navigation: null }); //removing navigation state from url to fetch correct listState
    } else if (
      AgentWorkspaceConstants.LINKED_ACTIVITY === navigation &&
      activityDetails &&
      activityDetails.linkedActivity
    ) {
      const index = data.findIndex(
        (activity) =>
          activity.activityId === activityDetails.linkedActivity.activityId
      );
      setSelectedActivityId(index);
      if (index != selectedActivityId)
        isNavigationCase = true;
      //history.replace(location.pathname, { status: status, navigation: null }); //removing navigation state from url to fetch correct listState
    }
    if (
      (AgentWorkspaceConstants.PREDECESSOR === navigation ||
        AgentWorkspaceConstants.SUCCESSOR === navigation) &&
      activityDetails &&
      selectedActivityId === -1
    ) {
      // case pred/succ activity does not exist on current page
      updateData({
        pageSize: listState.pageSize,
        page: listState.page + 1,
        sorted: listState.sorted,
        filtered: listState.filtered,
        resized: listState.resized,
        expanded: listState.expanded,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      data.length > 0 &&
      selectedActivityId < data.length &&
      selectedActivityId !== -1 &&
      !isNavigationCase
    ) {
      fetchActivityDetails(data[selectedActivityId].activityId);
    }
  }, [data, selectedActivityId]);

  useEffect(() => {
    if (callGroupData) {
      if (
        (callGroupData.entityType === "PHARMACY" && showPrescriberColumn) ||
        (callGroupData.entityType === "PRESCRIBER" && !showPrescriberColumn) ||
        (callGroupData.entityType === "MEMBER" && !showPrescriberColumn)
      ) {
        setShowPrescriberColumn(!showPrescriberColumn);
      }
      if (autoAssignStatus === "ready") {
        attemptAutoAssign(callGroupData);
      }
      if (autoAssignStatus === "success") {
        dispatch(resetAutoAssignError());
      }
    }
  }, [callGroupData]);

  useEffect(() => {
    if (isError === true) {
      showNotification({
        title: "Error Loading Data!",
        message: "Something went Wrong.",
        position: "bl",
        type: "error",
      });
      dispatch(resetError());
    }
  }, [isError]);

  useEffect(() => {
    if (!isStringEmptyOrNull(autoAssignError)) {
      showNotification({
        title: "Unable to claim Call Group!",
        message: autoAssignError,
        position: "tr",
        type: "error",
      });
      dispatch(resetError());
    }
  }, [autoAssignError]);

  const areActionsCallable =
    callGroupAssignedToLoggedInUser || isOwnedByLoggedInUser(callGroupData);
  const isActivityOpen = !activityDetails
    ? false
    : activityDetails.status !== "CLOSED";

  const alertCondition = data.length === 0 && !activityLoading;

  return (
    <>
      {callGroupData == null ? (
        <Spinner />
      ) : (
        <Container className="list-container" fluid>
          <Row>
            <Collapse in={!alertCondition} dimension="width" timeout={300}>
              <Col md={2}>
                {activityDetails == null || data.length == 0 ? (
                  activityLoading ? (
                    <Spinner />
                  ) : null
                ) : (
                  <>
                    <div>
                      <LeftBar
                        activityDetails={activityDetails}
                        isInAW={true}
                        isDisabled={!areActionsCallable || !isActivityOpen}
                        showPrescriberColumn={showPrescriberColumn}
                        callGroupData={callGroupData}
                        updateData={updateData}
                        fetchActivityDetails={fetchActivityDetails}
                        handleActivityNavigation={handleActivityNavigation}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Collapse>
            <Col md={alertCondition ? 10 : 8}>
              {alertCondition && (
                <Row>
                  <Col md={12}>
                    <Alert variant="warning">
                      <Alert.Heading as="h5">Note!</Alert.Heading>
                      <p>
                        There are no activities against current filters. Please
                        try changing the filters e.g. Status etc.
                      </p>
                    </Alert>
                  </Col>
                </Row>
              )}
              <DisplayBar
                tableTitle={"Activities"}
                totalRecords={totalElements == "loading" ? 0 : totalElements}
                refreshIcon={true}
                refresh={refresh}
                reset={reset}
                dataTestId={"text-total-activity-count"}
              />
              {callGroupData == null ? <Spinner/> : 
              <ServerSideReactTable
                columnDefination={columns}
                data={data}
                currentPage={listState.page}
                sorted={listState.sorted}
                filters={listState.filtered}
                totalPages={pages}
                totalRecords={totalElements == "loading" ? 0 : totalElements}
                loading={loading}
                enableMultiRowSelection={false}
                addRowSelection={false}
                defaultPageSize={pageSize}
                attributeName={ACTIVITY_LIST_ROW_COUNT}
                pageSizeOptions={[DEFAULT_PAGE_SIZE]}
                onUpdateTableData={onUpdateTable}
                onRowClick={(row) => {
                  handleRowClick(row)
                }}
                trStyle={(rowInfo) => {
                  return (getRowStyle(rowInfo))
                }}
                CustomPaginationComponent={<CustomCallGroupDetailPagination />}
                extraPropsForCustomComponent={callGroupData}
                dataTestId="table-activity-list"
              />}

              {activityDetails == null || data.length == 0 ? (
                activityLoading ? (
                  <Spinner />
                ) : null
              ) : (
                <Row>
                  <Col md={12}>
                    <div
                      className="boxed-div-blue"
                      style={{
                        margin: "10px",
                        width: "-webkit-fill-available",
                      }}
                    >
                      <BottomBar
                        activityDetails={activityDetails}
                        areActionsCallable={areActionsCallable}
                        callGroupData={callGroupData}
                        data={data}
                        showPrescriberColumn={showPrescriberColumn}
                        refreshActivity={refreshActivity}
                        resetActivityUponClose={resetActivityUponClose}
                        updateData={updateData}
                        fetchActivityDetails={fetchActivityDetails}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            {callGroupData == null ? null : (
              <Col md={2}>
                <div className="boxed-div-blue">
                  <CustomerAndClientTitle
                    customerName={callGroupData.customerName}
                    clientName={callGroupData.clientName}
                    shouldDisplayDates={false}
                    shouldDisplayGlobalSwitchId={false}
                    nameClassName={"h5"}
                  />
                  {renderOwnerInformation(callGroupData, autoAssignStatus)}
                  {isLoggedInUserAWAdmin() && (
                    <Row className="call-group-action-btns mb-1">
                      <Col md={12} className="d-grid gap-2">
                        <RmPopoverButton
                          disallowEditing={
                            (!isOwnedByLoggedInUser(callGroupData) &&
                              !isLoggedInUserAWAdmin())
                          }
                          btnVariant="warning"
                          btnText="Unclaim"
                          callbackFunction={unclaimCallGroup}
                          confirmationText="Are you sure you want to unclaim this group?"
                          btnClassName="unclaim-callgroup-btn"
                          dataTestId="btn-unclaim-call-group"
                        />
                      </Col>
                    </Row>
                  )}
                  {callGroupData.assigneeName && isLoggedInUserAWAdmin() && (
                    <Row className="call-group-action-btns mb-1">
                      <Col md={12} className="d-grid gap-2">
                        <RmPopoverButton
                          disallowEditing={!callGroupData.assigneeName}
                          btnVariant="warning"
                          btnText="Unassign"
                          callbackFunction={unassignCallGroup}
                          confirmationText={"Are you sure you want to unassign " + callGroupData.assigneeName + " from this group?"}
                          btnClassName="unassign-callgroup-btn"
                          dataTestId="btn-unclaim-call-group"
                        />
                      </Col>
                    </Row>
                  )}
                </div>
                {activityDetails == null || data.length == 0 ? (
                  activityLoading ? (
                    <Spinner />
                  ) : null
                ) : (
                  <div className="boxed-div-blue mt-3">
                    <ScrollableNotes
                      claimedBy={callGroupData.claimerUuid}
                      notes={activityDetails.activityNotes}
                      entityId={activityDetails.activityId}
                      entitySaveUrl={"/agent-workspace/activity-note"}
                      activityStatus={activityDetails.status}
                      activityType={activityDetails.type}
                      activityEntityType={activityDetails.entityType}
                      totalNotesCount={activityDetails.totalNotesCount}
                      pinnedNotesCount={activityDetails.pinnedNotesCount}
                      filtersList={NotesFilterConstants.activityNotesFilter}
                      sortByDate={
                        activityDetails.activityNotes &&
                          activityDetails.activityNotes.length > 0
                          ? activityDetails.activityNotes[
                            activityDetails.activityNotes.length - 1
                          ].creationDate
                          : null
                      }
                      excludeIds = {activityDetails.excludeIds}
                    />
                  </div>
                )}
                &nbsp;
                {activityDetails == null || data.length == 0 ? (
                  activityLoading ? (
                    <Spinner />
                  ) : null
                ) : (
                  <div className="boxed-div-blue">
                    <div className="small-label-notes">
                      <div>PREDECESSOR NOTES</div>
                    </div>
                    <CollapsedNotes
                      expiredSwitchList={activityDetails.predecessorWfItemUuids}
                      entitySaveUrl="/agent-workspace/predecessor-activity-notes"
                    />
                  </div>
                )}
              </Col>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default withRouter(CallGroupDetails);
