import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BatchConstants } from 'lookup/BatchConstants';
import { CycleConstants } from 'lookup/CycleConstants';

import { Container, Row, Col, Button } from 'react-bootstrap/';
import { MdClose } from "react-icons/md";
import ResetListState from '../../../components/ResetListState';

import { formatDateWithTime } from 'services/MomentUtils';
import { twoDecimalPlaceRounder } from 'services/UtilityService';

import { updateCycleList, updateSwitchList, updateCycleListState, updateSwitchListState, resetError } from "./CycleList.actions";

import './CycleList.css';
import { WrapTextAndCenterAlign } from 'components/WrapText';
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import ClientSideReactTable from 'commons/table/ClientSideReactTable';
import { WrapTextAndJustifyCenter } from 'components/WrapText';
import EditCycle from './EditCycle';
import ReactBadge from "react-bootstrap/Badge";
const Badge = (props) => (
  <div>
    <ReactBadge {...props}>{props.children}</ReactBadge>
  </div>
);


const DEFAULT_PAGE_SIZE = 15;
const SELECTED_ROW_BG_COLOR = "#42a5f5";
const SELECTED_ROW_TEXT_COLOR = "#ffffff";

class CycleList extends Component {
  constructor(props) {
    super(props);

    this.handler = this.handler.bind(this);
  }

  state = {
    isCycleListLoading: true,
    cycleListData: this.props.cycleListData,
    totalCycleElements: this.props.totalCycleElements,
    cycleListPages: this.props.cycleListPages,
    cycleListState: this.props.cycleListState,

    isSwitchListLoading: false,
    switchListData: this.props.switchListData,
    totalSwitchElements: this.props.totalSwitchElements,
    switchListPages: this.props.switchListPages,
    switchListState: this.props.switchListState,
    selectedCycleId: this.props.selectedCycleId,
    isError: false,
    displayAllColumn: true,
  }

  updateData = (state, preventUpdate) => {
    const filteredForStatus = state.filtered.filter(element => !(element.id === 'status' && element.value.includes("ALL")))
    if (filteredForStatus.length !== state.filtered.length) {
      state.filtered = filteredForStatus
    }
    if (!preventUpdate) {
      if (state.sorted !== BatchConstants.DEFAULT_BATCH_LIST_SORTING)
        state.sorted.forEach((element, i) => {
          element.isDefault = false
        });
      this.props.updateCycleList(state.pageSize, state.page, state.sorted, state.filtered, state.resized, state.expanded);
    } else {
      // should have a separate action
      this.setState({ cycleListState: state });
    }
  }

  onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    this.state.cycleListState.pageSize = pageSize;
    this.state.cycleListState.page = pageNo;
    this.state.cycleListState.sorted = sortBy ? sortBy : [];
    this.state.cycleListState.filtered = filterValues;
    this.updateData(this.state.cycleListState);
  }

  onUpdateSwitchTable = (filterValues, sortBy, pageSize, pageNo) => {
    this.state.switchListState.pageSize = pageSize;
    this.state.switchListState.page = pageNo;
    this.state.switchListState.sorted = sortBy ? sortBy : [];
    this.state.switchListState.filtered = filterValues;
    this.props.updateSwitchListState(this.state.switchListState);
    this.props.updateSwitchList(this.state.selectedCycleId, this.state.switchListState.pageSize, this.state.switchListState.page, this.state.switchListState.sorted, this.state.switchListState.filtered, this.state.switchListState.resized, this.state.switchListState.expanded);
  }

  handler() {
    this.updateData(this.state.cycleListState, false);
  }

  componentDidMount() {
    this.updateData(this.state.cycleListState);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { isCycleListLoading, cycleListData, totalCycleElements, cycleListPages, cycleListState, isSwitchListLoading, switchListData, totalSwitchElements, switchListPages, switchListState, selectedCycleId, isError } = this.props;
      let selectedCycleIdToUse = selectedCycleId;
      if (this.state.selectedCycleId != -1 && selectedCycleId == -1) {
        selectedCycleIdToUse = this.state.selectedCycleId
      }
      this.setState({ isCycleListLoading, cycleListData, totalCycleElements, cycleListPages, cycleListState, isSwitchListLoading, switchListData, totalSwitchElements, switchListPages, switchListState, selectedCycleIdToUse, isError });
    }
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value, error: "" });
  }

  handleRowClick = async (rowInfo) => {
    this.widthOfTotalSwitch = this.state.displayAllColumn ? 95 : 105
    this.widthOfSwitchDescription = this.state.displayAllColumn ? 600 : 325
    await this.setState({ ...this.state, selectedCycleId: rowInfo.original.wfCycleId }, () => this.handleRowClickAfterCycleId())
  }

  handleRowClickAfterCycleId = () => {
    this.props.updateSwitchList(this.state.selectedCycleId, this.state.switchListState.pageSize, this.state.switchListState.page, this.state.switchListState.sorted, this.state.switchListState.filtered, this.state.switchListState.resized, this.state.switchListState.expanded);
    this.props.updateSwitchListState(this.state.switchListState);
  }

  renderActions(rowData) {
    return (
      <span className="action-button">
        <EditCycle
          cycle={rowData}
          showButton={true}
          handler={this.handler}
        ></EditCycle>
      </span>
    );
  }

  renderRowDescription(rowData) {
    return (
      <span style={{ display: "flex" }}>
        <span style={{ flex: "9" }}>
          {rowData.description}
        </span>

        <span className="edited-tag">
          {rowData.descriptionEdited ?
            <Badge
              variant="primary"
              box="true"
              className="badge-custom-edited"
            >
              Edited
            </Badge>
            : ""}
        </span>
      </span>
    );
  }

  renderDateCell = (date) => {
    return (
      <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {formatDateWithTime(date)}
      </span>
    );
  };

  renderSavingsCell = (savings) => `$ ${twoDecimalPlaceRounder(savings)}`;

  widthOfTotalSwitch = this.state.displayAllColumn ? 95 : 105
  widthOfSwitchDescription = this.state.displayAllColumn ? 600 : 325

  render() {
    // const { isCycleListLoading, cycleListData, totalCycleElements, cycleListPages, cycleListState, isSwitchListLoading, switchListData, totalSwitchElements, switchListpages, switchListState, selectedCycleId, isError } = this.state;
    const { cycleListPages, isCycleListLoading, cycleListData, totalCycleElements, cycleListState, isSwitchListLoading, switchListData, switchListState, selectedCycleId, totalSwitchElements, switchListPages } = this.state;
    var columns = [
      {
        header: "Cycle Description",
        accessor: "description",
        accessorKey: "description",
        width: this.widthOfSwitchDescription,
        enableSorting: false,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.SearchableInput,
        customComponent: (value, row) => {
          return this.renderRowDescription(row.original);
        },
      },
      {
        header: "Cycle Status",
        accessor: "status",
        accessorKey: "status",
        width: 120,
        show: this.state.displayAllColumn,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.SingleSelect,
        filterOptions: CycleConstants.statuses,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Scheduled Date",
        accessor: "dateScheduled",
        accessorKey: "dateScheduled",
        width: 170,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return this.renderDateCell(value)
        },
      },
      {
        header: "Total Switches",
        accessor: "totalSwitches",
        accessorKey: "totalSwitches",
        show: this.state.displayAllColumn,
        width: this.widthOfTotalSwitch,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Projected Savings",
        accessor: "projectedSavings",
        accessorKey: "projectedSavings",
        width: 140,
        show: this.state.displayAllColumn,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{this.renderSavingsCell(value)}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Completed Date/Time",
        accessor: "completionDate",
        accessorKey: "completionDate",
        enableSorting: false,
        width: 210,
        show: this.state.displayAllColumn,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return row.original.status === "CLOSED" ? this.renderDateCell(value) : <WrapTextAndJustifyCenter>N/A</WrapTextAndJustifyCenter>
        },
      },
      {
        header: "Action",
        accessor: "action",
        accessorKey: "action",
        enableSorting: false,
        enableFiltering: false,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return this.renderActions(row.original);
        },
      },
    ];

    switchListData.forEach(element => {
      element.wfBatchId = element.wfBatchId || '-';
    });
    const switchColumns = [
      {
        header: "Switch Id",
        accessor: "switchId",
        accessorKey: "switchId",
        width: 140,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Cust",
        accessor: "customerNameAbbreviation",
        accessorKey: "customerNameAbbreviation",
        width: 60,
        show: true,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Client",
        accessor: "clientNameAbbreviation",
        accessorKey: "clientNameAbbreviation",
        width: 60,
        show: true,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Switch Id",
        accessor: "switchNumber",
        accessorKey: "switchNumber",
        width: 60,
        show: true,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Switch Status",
        accessor: "currentStatus",
        accessorKey: "currentStatus",
        width: 260,
        show: true,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Scheduled Date",
        accessor: "scheduledDispatchTime",
        accessorKey: "scheduledDispatchTime",
        width: 150,
        show: true,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value ? this.renderDateCell(value) : 'N/A'}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Fax Status",
        accessor: "faxBucketStatus",
        accessorKey: "faxBucketStatus",
        width: 150,
        show: true,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value ? value : "N/A"}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Projected Savings",
        accessor: "projectedSavings",
        accessorKey: "projectedSavings",
        width: 140,
        show: true,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{this.renderSavingsCell(value)}</WrapTextAndCenterAlign>
        },
      },
    ];

    return (
      <Container className="list-container" fluid>
        <Row >
          <Col md={12}>
            <div className='codex-version-display-bar'>
              <span style={{ float: 'left' }} >   Total Cycles: <strong>{totalCycleElements}</strong></span>
              <strong style={{ float: 'center' }}>Cycle List</strong>
              {selectedCycleId === -1 ?
                <ResetListState
                  onReset={() => this.updateData({
                    ...cycleListState, displayAllCoulmn: true, sorted: BatchConstants.DEFAULT_BATCH_LIST_SORTING
                    , filtered: []
                  })}
                  resetterDisabled={cycleListState.sorted === BatchConstants.DEFAULT_BATCH_LIST_SORTING &&
                    cycleListState.filtered.length === 0}
                /> :
                null
              }
            </div>
          </Col>
        </Row>
        <Row className="list-container" test-id="cycle-list-test-id">
          <Col md={selectedCycleId === -1 ? 12 : 6}>
            <ServerSideReactTable
              columnDefination={columns}
              data={cycleListData}
              currentPage={cycleListState.page}
              sorted={cycleListState.sorted}
              filters={cycleListState.filtered}
              totalPages={cycleListPages}
              totalRecords={totalCycleElements == "loading" ? 0 : totalCycleElements}
              loading={isCycleListLoading}
              enableMultiRowSelection={false}
              addRowSelection={false}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              pageSizeOptions={[5, 10, 15, 20, 30, 50]}
              onUpdateTableData={this.onUpdateTable}
              onRowClick={(rowInfo) => {
                if (rowInfo !== undefined && rowInfo.column.id != 'action') {
                  this.setState({ ...this.state, displayAllColumn: false }, () => { this.handleRowClick(rowInfo) });
                }
              }}
              trStyle={(rowInfo) => {
                return (
                  {
                    background: (rowInfo && rowInfo.original.wfCycleId === selectedCycleId) ? SELECTED_ROW_BG_COLOR : "",
                    color: (rowInfo && rowInfo.original.wfCycleId === selectedCycleId) ? SELECTED_ROW_TEXT_COLOR : ""
                  })
              }
              }
            />
          </Col>
          {
            selectedCycleId !== -1 ?
              <Col md={6} className="tiny-top-spacer" test-id="cycle-items-list-test-id">
                <Row >
                  <Col md={12}>
                    <Button className={"pull-right"} size="sm" variant="custom-hollow" onClick={() => this.setState({ selectedCycleId: -1, displayAllColumn: true })} > <MdClose /> </Button>
                    <h4 className="pull-left">
                      Switches for Cycle
                    </h4>
                  </Col>
                </Row>
                {
                  <Row className="tiny-top-spacer list-container">
                    <Col md={12}>
                      <ClientSideReactTable
                        columnDefination={switchColumns}
                        data={switchListData}
                        loading={isSwitchListLoading}
                        overrideRowId={'switchId'}
                        defaultPageSize={DEFAULT_PAGE_SIZE}
                        pageSizeOptions={[5, 10, 15, 20, 30, 50]}
                        onUpdateTableData={this.onUpdateSwitchTable}
                        onRowClick={(rowInfo) => {
                          if (rowInfo !== undefined) {
                            this.props.history.push(`/switch/${rowInfo.original.wfItemId}`);
                          }
                        }}
                      />
                    </Col>
                  </Row>

                }
              </Col>
              : ""
          }
        </Row>


      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isCycleListLoading: state.cycleList.isCycleListLoading,
    cycleListData: state.cycleList.cycleListData ? state.cycleList.cycleListData : [],

    totalCycleElements: state.cycleList.isCycleListLoading ? ("Loading...") :
      (state.cycleList.totalCycleElements ? state.cycleList.totalCycleElements : 0),

    cycleListPages: state.cycleList.cycleListPages ? state.cycleList.cycleListPages : 0,
    cycleListState:
      state.cycleList.cycleListState ?
        state.cycleList.cycleListState
        : {
          page: 0,
          pageSize: DEFAULT_PAGE_SIZE,
          sorted: BatchConstants.DEFAULT_BATCH_LIST_SORTING,
          filtered: [],
          resized: [],
          expanded: {}
        },

    isSwitchListLoading: state.cycleList.isSwitchListLoading,
    switchListData: state.cycleList.switchListData ? state.cycleList.switchListData : [],
    totalSwitchElements: state.cycleList.totalSwitchElements ? state.cycleList.totalSwitchElements : "Loading...",
    switchListPages: state.cycleList.switchListPages ? state.cycleList.switchListPages : 0,
    switchListState:
      state.cycleList.switchListState ?
        state.cycleList.switchListState
        : {
          page: 0,
          pageSize: DEFAULT_PAGE_SIZE,
          sorted: [],
          filtered: [],
          resized: [],
          expanded: {}
        },

    selectedCycleId: state.cycleList.selectedCycleId ? state.cycleList.selectedCycleId : -1,
    isError: state.cycleList.isError ? state.cycleList.isError : false
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCycleList: bindActionCreators(updateCycleList, dispatch),
    updateCycleListState: bindActionCreators(updateCycleListState, dispatch),
    updateSwitchList: bindActionCreators(updateSwitchList, dispatch),
    updateSwitchListState: bindActionCreators(updateSwitchListState, dispatch),
    resetError: bindActionCreators(resetError, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CycleList));