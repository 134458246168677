
import { createSlice } from '@reduxjs/toolkit';
import { getTableOfContentsRequestAction, getTableOfContentsSuccessAction, getTableOfContentsFailureAction } from './TableOfContents.actions';
export const INITAL_STATE = {
  data: [],
  totalPages: 0,
  pageSize: 20,
  page: 0,
  error: false,
  loading: false,
  totalElements: "Loading",
  filtered: [],
  isDefaultFilter: true,
  resized: [],
  expanded: {}
}


export const tableOfContentsSlice = createSlice({
  name: "tableOfContents",
  initialState: INITAL_STATE,
  reducers: {
    getTableOfContentsSuccess: (state, action) => {
      state.data = action.payload.records;
      state.totalPages = action.payload.totalPages;
      state.pageSize = action.payload.pageSize;
      state.page = action.payload.page;
      state.sorted = action.payload.sorted;
      state.filtered = action.payload.filtered;
      state.totalElements = action.payload.totalElements;
      state.error = false;
      state.loading = false;
    },
    getTableOfContentsFailure: (state, action) => {
      state.loading = false;
      state.error = true;
      state.totalElements = "Failed";
      state.data = [];
    },
    getTableOfContentsLoading: (state, action) => {
      state.loading = true;
      state.isDefaultFilter = false;
      state.totalElements = "Loading";
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "table of contents" RSAA action.
      .addCase(getTableOfContentsRequestAction, (state, action) => {
        tableOfContentsSlice.caseReducers.getTableOfContentsLoading(state, action);
      })
      .addCase(getTableOfContentsSuccessAction, (state, action) => {
        tableOfContentsSlice.caseReducers.getTableOfContentsSuccess(state, action);
      })
      .addCase(getTableOfContentsFailureAction, (state, action) => {
        tableOfContentsSlice.caseReducers.getTableOfContentsFailure(state, action);
      })

  }
});


export const { getTableOfContentsSuccess, getTableOfContentsFailure, getTableOfContentsLoading } = tableOfContentsSlice.actions;
export const tableOfContentsState = (state) => state.tableOfContents;
export default tableOfContentsSlice.reducer;