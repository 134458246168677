import React from "react";
import Button from "react-bootstrap/Button";
import DismissableModal from "components/DismissableModal";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { useState } from "react";
import Editor from "plugins/Lexical/Editor";
import "plugins/Lexical/styles.css";
import OpenModalButtonIcon from "components/OpenModalButtonIcon";
import { showNotification } from "services/NotificationService";

const removeTags = (str) => {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags
  return str.replace(/(<([^>]+)>)/gi, "");
};

const AddEditNoteModal = (props) => {
  const [note, setNote] = useState(props.note ? props.note : "");
  const [saving, setSaving] = useState(false);
  const [entityId, setEntityId] = useState(props.entityId);
  const [autoCloseModal, setAutoCloseModal] = useState(false);
  const [entitySaveUrl, setEntitySaveUrl] = useState(props.entitySaveUrl);
  const url = props.editMode ? `${entitySaveUrl}/${entityId}/edit-note` : `${entitySaveUrl}/${entityId}/add-note`;

  const save = () => {
    const text = removeTags(props.editNote ? note.note : note);
    if (text.length > 0) {
      setSaving(true);

      if (props.editNote && note.new) delete note["new"];

      sendAuthenticatedAsyncRequest(
        url,
        "POST",

        props.newNote ? { note: note } : props.editNote ? note : null,

        (r) => {
          if (props.newNote) {
            props.newNote(r.data);
          } else if (props.editNote) {
            props.editNote(r.data);
          }
          setNote(r.data);
          setSaving(false);
          showNotification({
            title: props.newNote
              ? "Note saved successfully!"
              : props.editNote
              ? "Note has been edited!"
              : null,
            message: `The ${
              props.editMode ? "edited" : "new"
            } note can be viewed in the Notes box`,
            position: "tr",
            type: "success",
          });
          setAutoCloseModal(!autoCloseModal);
        },
        (r) => {
          setSaving(false);
          showNotification({
            title: props.newNote
              ? "Unable to save note!"
              : props.editNote
              ? "Unable to edit note!"
              : null,
            message: r.data.errorCode
              ? r.data.errorCode
              : "SOMETHING WENT WRONG",
            position: "tr",
            type: "error",
          });
        }
      );
    } else {
      showNotification({
        title: `Unable to ${props.editNote ? "edit" : "save"} note!`,
        message: "Cannot save empty note.",
        position: "tr",
        type: "info",
      });
    }
  };

  const handleNoteUpdate = (note) => {
    if (props.note) {
      var updatedNote = JSON.parse(JSON.stringify(props.note));
      updatedNote.note = note;
      setNote(updatedNote);
    } else setNote(note);
  };

  return (
    <DismissableModal
      key={autoCloseModal}
      openModalButtonStyle="btn"
      openModalButtonDisabled={props.openModalButtonDisabled}
      openModalButtonIcon={
        <OpenModalButtonIcon
          toolTipText={props.toolTipText}
          icon={props.icon}
        />
      }
      openModalButtonSize={
        props.openModalButtonSize ? props.openModalButtonSize : "lg"
      }
      openModalButtonClassName={
        props.openModalButtonClassName ? props.openModalButtonClassName : null
      }
      title={`${props.editMode ? "Edit" : "Add"} Note`}
      centered
      hideFooter={true}
      openModalButtonTestId="btn-activity-add-notes"
    >
      <div>
        <Editor
          note={props.newNote ? "" : note}
          onNoteUpdate={handleNoteUpdate}
        />
        <div>
          <Button
            className="pull-right btn-text-white"
            size="sm"
            variant="success"
            onClick={save}
            disabled={saving}
            data-testid="btn-activity-add-notes-submit"
          >
            {saving ? "Saving ..." : "Save"}
          </Button>
        </div>
      </div>
    </DismissableModal>
  );
};

export default AddEditNoteModal;
