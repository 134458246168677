import React, {Component} from 'react';

import { Modal, Button} from 'react-bootstrap';
import { DraggableModalDialog } from './DraggableModalDialog';

class DismissableModal extends Component {
	constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: this.props.initialStateOpen || false,
    };
  }

  componentDidUpdate() {
    if (this.props.onComponentUpdated && typeof this.props.onComponentUpdated === 'function')
      this.props.onComponentUpdated();
  }

  handleClose(event = null) {
    this.setState({ show: false });
    if (this.props.onClose && typeof this.props.onClose === 'function')
      this.props.onClose();
  }

  handleShow() {
    this.setState({ show: true });
    if (this.props.onShow && typeof this.props.onShow === 'function')
      this.props.onShow();
  }
  render() {
    // whenever an array is returned, all items must have a unique (to parent) key prop
    return (
    	[
        this.props.openModalButton === undefined 
        ? <Button onClick={this.handleShow} key={1}
            variant={this.props.openModalButtonStyle ? this.props.openModalButtonStyle : "info"} 
            size={this.props.openModalButtonSize ? this.props.openModalButtonSize : null}
            block={this.props.openModalButtonBlock ? this.props.openModalModalButtonBlock : null}
            disabled={this.props.openModalButtonDisabled}
            id={this.props.openModalButtonId}
            className={this.props.openModalButtonClassName ? this.props.openModalButtonClassName : null}
            data-testid={this.props.openModalButtonTestId ? this.props.openModalButtonTestId : ''}
            >
            {this.props.openModalButtonIcon ? this.props.openModalButtonIcon : null}
            {this.props.openModalButtonText ? this.props.openModalButtonText : null}
          </Button>
        : this.props.openModalButton

        ,
        
        <Modal 
          id={"modal " + (this.props.modalId ? this.props.modalId : "")}
          key={2}
          show={this.state.show} 
          onHide={this.handleClose}
          style={this.props.customModalStyle}
          size={this.props.size !== undefined ? this.props.size : null}
          dialogClassName={this.props.modalClassName}
          backdrop={this.props.preventCloseWhenClickedOutsideModal ? 'static' : true}
          keyboard={this.props.keyboard !== undefined ? this.props.keyboard : true}
          dialogAs={DraggableModalDialog}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           {this.props.children}
          </Modal.Body>
          {
            this.props.hideFooter
            ? null
            : <Modal.Footer>
              {
                this.props.customFooterContent !== undefined
                ? this.props.customFooterContent
                : <Button onClick={this.handleClose} variant="outline-dark" >Close</Button>
              }
              </Modal.Footer>
          }
          
        </Modal>
      ]
  	);
  }
}

export default DismissableModal;