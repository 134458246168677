import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import DynamicSwitchOptionItemTypeField from '../../../commons/fields/DynamicSwitchOptionItemTypeField';
import { getValidItemType } from './NewDrug.action';
import { getErrorMessage } from 'services/UtilityService';
import { showNotification } from 'services/NotificationService';

// medispanDrugId
class NewDrugRestrictedItemTypeField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            medispanDrugId: null,
            validItemTypes: [],
        }
    }

    static getAllItemTypes(medDrugs) {
        let itemTypesArray = [];
        let shouldAddBoth = true;
        medDrugs.forEach(medDrug => {
            const {canAddDrug, drugValidityInfoList, itemType} = medDrug;
            if (canAddDrug && (!drugValidityInfoList || drugValidityInfoList.length == 0)) {
                itemTypesArray.push({
                    canAdd: true,
                    itemType: itemType,
                    reason: null,
                    additionalInfo: ""
                })
            } else if (!canAddDrug) {
                // if same drug exists with same item type and same group
                itemTypesArray.push({
                    canAdd: false,
                    itemType: itemType,
                    reason: `Cannot add this drug as ${itemType} in this group because it already exists`,
                    additionalInfo: ""
                })
                shouldAddBoth = false;
            } else {
                let additionalInfo = [];
                for (let i = 0; i < drugValidityInfoList.length; i++) {
                    let medNames = drugValidityInfoList[i].medNames.reduce((previousValue, currentValue) => 
                        previousValue == "" ? currentValue : previousValue + ' , ' + currentValue,"");
                    additionalInfo += `    - In ${drugValidityInfoList[i].dynamicSwitchGroupName} group, under ${medNames} drugs \n`;
                }
                itemTypesArray.push({
                    canAdd: true,
                    itemType: itemType,
                    reason: `Some of the strengths will be disabled because these strenghts are active in following groups: `,
                    additionalInfo: additionalInfo
                })
            }
        })
        if(shouldAddBoth) {
            itemTypesArray.push({
                canAdd: true,
                itemType: "both",
                reason: null,
                additionalInfo: ""
            })
        }
        return itemTypesArray;
    }

    
    componentDidUpdate(prevProps, prevState) {
        if (this.state.medispanDrugId !== prevState.medispanDrugId && this.state.medispanDrugId != null) {
            let medDrugs = [{
              medispanDrugId: this.state.medispanDrugId,
              itemType: "target"
            }, {
              medispanDrugId: this.state.medispanDrugId,
              itemType: "switch"
            }]
            this.props.getValidItemType(this.props.workingCodexVersionId, this.props.dynamicSwitchGroup.dynamicSwitchGroupId, medDrugs);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { isLoading, error, medDrugs  } = nextProps;
        let validTypes = [...prevState.validItemTypes];
        let isLoadingValidCases = nextProps.isLoadingValidCases;
        if (nextProps.medispanDrugId !== prevState.medispanDrugId) {
            validTypes = [];
            isLoadingValidCases = nextProps.medispanDrugId ? true : false;
            nextProps.setIsLoadingValidCases(isLoadingValidCases);
            return {
              medispanDrugId: nextProps.medispanDrugId,
                validItemTypes: validTypes,
            }
        }
        if (medDrugs?.length > 0 && medDrugs[0].medispanDrugId == nextProps.medispanDrugId && !isLoading && isLoadingValidCases) {
            nextProps.setIsLoadingValidCases(false);
            if (error) {
                showNotification({
                    title: 'Could not get required information',
                    message: (getErrorMessage(r.data) || "Something went wrong. Please try again later"),
                    position: 'tr',
                    type: "error"
                  });
                return {
                    validItemTypes: [],
                };
            }
            const validTypes = NewDrugRestrictedItemTypeField.getAllItemTypes(medDrugs);
            return {
                validItemTypes: validTypes,
            };

        }
        else {
            return null
        }
    }

    getValidItemTypes = () => {
        const { validItemTypes } = this.state;
        let itemTypes = [];
        let errors = "";
        let warnings = "";
        validItemTypes.forEach(x => {
            if (x.canAdd) {
                itemTypes.push(x.itemType);
                if (x.reason) {
                    warnings += `Adding this drug as ${x.itemType} can have following implications:\n`
                    warnings += `  1. ${x.reason}`;
                    warnings += "\n"
                    warnings += `${x.additionalInfo}`;
                }
            } else {
                if (x.reason) {
                    errors += x.reason + "\n";
                }
            }
        })
        return { itemTypes, errors, warnings };
    }

    render() {
        const { medispanDrugId } = this.state;
        const handleItemTypeChange = (e) => {
            values.itemType = e.target.value;
        }
        const { savedValue, updatedValue, values, handleBlur, isVisible} = this.props;
        const { itemTypes, errors, warnings } = this.getValidItemTypes();

        return (
            isVisible ?
            <DynamicSwitchOptionItemTypeField
                dynamicSwitchOptionId={medispanDrugId}
                key={`itemType${medispanDrugId}`}
                isEditMode={true}
                savedValue={savedValue}
                updatedValue={updatedValue}
                handleChange={handleItemTypeChange}
                handleBlur={handleBlur}
                isDrugTypeBoth={false}
                isHorizontalView={true}
                error={errors}
                warning={warnings}
                isRequired={true}
                itemTypesToDisplay={itemTypes}
            />
            : null
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        workingCodexVersionId: state.codexVersion ? state.codexVersion.codexVersionId : null,
        dynamicSwitchGroup: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.dynamicSwitchGroup : null,
        medDrugs: state.newDrug.medDrugs,
        error: state.newDrug.error,
        isLoading: state.newDrug.isLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getValidItemType: bindActionCreators(getValidItemType, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewDrugRestrictedItemTypeField));