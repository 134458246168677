import React from 'react';
import { Component } from 'react';
import { RiAddCircleFill, RiEditCircleFill, RiIndeterminateCircleFill } from 'react-icons/ri';
import { DELTA_CLASS_NAME } from '../../commons/fields/DeltaFieldUtils';

class DrugDeltaIcon extends Component {
  render() {
    const { showDelta, className } = this.props;
    let updatedClassName = className ? className + "-icon" : "";
    if (showDelta && updatedClassName) {
      if (className == DELTA_CLASS_NAME.NEW) {
        return <RiAddCircleFill className={updatedClassName} />
      } else if (className == DELTA_CLASS_NAME.DELETED) {
        return <RiIndeterminateCircleFill className={updatedClassName} />
      } else if (className == DELTA_CLASS_NAME.MODIFIED) {
        return <RiEditCircleFill className={updatedClassName} />
      }
    }
    return null
  }
}
export default DrugDeltaIcon;