import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { CODEX } from "rbac/RbacContentProvider";

import CAT_CONSTANTS from 'lookup/CatConstants';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { getErrorMessage } from 'services/UtilityService';
import {getLoggedInUser} from 'services/AuthService';
import ROLES from "rbac/Roles.json";
import { saveWorkingDsgInfo } from 'views/Cat/DynamicSwitchGroupDetails/DynamicSwitchGroupDetails.actions';
import { bindActionCreators } from 'redux';
import { showNotification } from 'services/NotificationService';
import { withRouter } from 'react-router-dom';



const LOCK_ACTIONS = {
  ACQUIRE: 'acquire',
  RELEASE: 'release'
}

class EditDynamicSwitchGroupDetails extends Component {

  state = {
    loading: false,
    loggedInUser: getLoggedInUser()
  }
  
  isLoggedInUserCATAdmin() {
    return getLoggedInUser().authorities.includes(ROLES.ADMIN_RELEASE_LOCK);
  }

  lock = (action, dynamicSwitchGroupId) => {
    this.setState({loading: true});
    const url = this.isLoggedInUserCATAdmin() && action === "release" 
    ? `/cat/dynamic-switch-groups/${dynamicSwitchGroupId}/edit-lock-by-admin/release` 
    : `/cat/dynamic-switch-groups/${dynamicSwitchGroupId}/edit-lock/${action}`;
    sendAuthenticatedAsyncRequest(
        `${url}`,
        'PUT', 
      null,
      (r) => {
        this.setState({loading: false});
        const {dynamicSwitchGroupId, editableBy} = r.data; 
       
        this.props.saveWorkingDsgInfo(dynamicSwitchGroupId, editableBy);
        showNotification({
          title: `Action Successful!`,
          message: action === LOCK_ACTIONS.ACQUIRE 
            ? 'You can now work on this Dynamic Switch Group. No other person will be allowed to edit it untill you stop editing.' 
            : 'You have stopped editing the Dynamic Switch Group.',
          position: 'br',
          type: "success"
        });
      },
      (r) => {
        this.setState({loading: false});
        showNotification({
          title: 'Something went wrong!',
          message: getErrorMessage(r.data) || `Unable to ${action === LOCK_ACTIONS.ACQUIRE ? 'edit' : 'stop editing'} the Dynamic Switch Group.`,
          position: 'br',
          type: "error"
        }, 'error');
      },
    );
  }

  render () {
    const {isWorkingDsgEditable, codexStatus, workingDynamicSwitchGroupId, editableBy} = this.props;
    const {loading,loggedInUser} = this.state;
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const allowEditing = queryParams.get('showDisplayCodexBar') ? false : true;

    return ( 
      <div style={{display: "flex", flexDirection: "row-reverse"}}>
        { CODEX.renderComponentIfCanChangeDSG(codexStatus === CAT_CONSTANTS.codexStatuses.IN_DEVELOPMENT && allowEditing
          ? isWorkingDsgEditable 
          ? ((editableBy && editableBy.userId === loggedInUser.uuid) || (loggedInUser.authorities.includes("ADMIN_RELEASE_LOCK"))) // owned by loggedInUser OR CAT Admin
          ? <Button variant='danger' size='sm' disabled={loading} onClick={() => this.lock(LOCK_ACTIONS.RELEASE, workingDynamicSwitchGroupId)}>{loading ? 'Requesting...' : 'Stop Editing'}</Button>
          : <div className='rm-xs-top-label'>Lock acquired by {editableBy.name}</div> 
          : <Button variant='success' size='sm' className="btn-text-white" disabled={loading} onClick={() => this.lock(LOCK_ACTIONS.ACQUIRE, workingDynamicSwitchGroupId)}>{loading ? 'Requesting...' : 'Start Editing'}</Button>
          : null)
        }
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    isWorkingDsgEditable: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.isWorkingDsgEditable : null,
    editableBy: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.editableBy : null,
    codexStatus: state.codexVersion ? state.codexVersion.codexVersionStatus : null,
    workingDynamicSwitchGroupId: state.dynamicSwitchGroupDetails?.dynamicSwitchGroup ? state.dynamicSwitchGroupDetails.dynamicSwitchGroup.dynamicSwitchGroupId : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveWorkingDsgInfo: bindActionCreators(saveWorkingDsgInfo, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditDynamicSwitchGroupDetails));
