import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import {sendAuthenticatedAsyncRequest} from 'services/AsyncRequestService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DismissableModal from 'components/DismissableModal';
import { getErrorMessage } from 'services/UtilityService';
import { FormSelect } from 'react-bootstrap';
import { showNotification } from 'services/NotificationService';

class SyncMemberWithSendGrid extends Component {
    
  state = {
        customers: null,
        asyncInProgress: false,
        loadingCustomers: false
    }
    
  componentDidMount() {
    this.fetchCustomers();
  }

  fetchCustomers() {
    this.setState({loadingCustomers: true})
    sendAuthenticatedAsyncRequest(
      `/customers/list`,
      "GET", 
      null,
      (r) => { this.setState({customers: r.data, loadingCustomers: false})},
      (r) => this.setState({customers: null, loadingCustomers: false})
    );
  }
  
  syncMembers = (values, setSubmitting, closeModalHandle) => {
    
    sendAuthenticatedAsyncRequest(
      `/sendgrid-member-sync/customer/${values.selectedCustomer}`,
      "PUT",
      null,
      (r) => {
        this.setState({asyncInProgress: false});
        setSubmitting(false);
        showNotification({
          title: 'Action successful!',
          message: 'Members Synced successfully.',
          position: 'tc',
          type: "success"
        });
        closeModalHandle();
      },
      (r) => {
        this.setState({asyncInProgress: false});
        setSubmitting(false);
        showNotification({
          title: 'Error!',
          message: getErrorMessage(r.data)|| getErrorMessage('MEMBERS_SYNC_FAILED'),
          position: 'tc',
          type: "error"
        });
      }
    );
  }

  render() {
    const modalRef = 'SyncMembersOfCustomerModal';
    const { customers} = this.state;
    const formattedCustomers = customers ? customers.map(c => ({
      value: c.name, key: c.id})) : [];

    const syncMembersFormSchema = Yup.object().shape({
      selectedCustomer: Yup.string().required('please select a customer')
    });

    return (
      <Card style={{textAlign:'center'}}>
        <Card.Body>
          <Card.Text>
            Sync New Members on SendGrid.
          </Card.Text>
          <DismissableModal 
            ref={modalRef}
            openModalButtonText="Sync New Members"
            openModalButtonStyle="info"
            openModalButtonBlock={true}
            openModalButtonDisabled={false}
            openModalButtonId='sync-new-member-btn-popup'
            preventCloseWhenClickedOutsideModal={true}
            hideFooter={true}
            title="SendGrid Member Sync" >
              <Formik
                initialValues={{ selectedCustomer: ''}}
                onSubmit={(values, { setSubmitting }) => {
                  this.syncMembers(values, setSubmitting, this.refs[modalRef].handleClose)
                }}
                validationSchema={syncMembersFormSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue

                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={{offset: '2', span: '8' }}>
                        <Form.Group>
                          <Form.Label>
                            Select Customers 
                          </Form.Label>
                          <FormSelect  
                            value={values.selectedCustomer}
                            onChange={e =>
                                setFieldValue(
                                  "selectedCustomer",e.target.value)
                            }
                            name='selectedCustomer'
                             onBlur={handleBlur}
                          >
                             <option value="">Select </option>
                            {formattedCustomers.map((s, i) => <option value={s.key} key={i}>{s.value}</option>)  }
                          </FormSelect>
                          <Form.Text className="text-small text-danger">
                            {errors.selectedCustomer && touched.selectedCustomer && errors.selectedCustomer}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='bottom-spacer top-spacer'>
                      <Col md={{offset: '2', span: '4'}}>
                        <Button variant='outline-dark' block onClick={() => this.refs[modalRef].handleClose()} disabled={isSubmitting}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={4}>
                        <Button type="submit" variant='success' block disabled={isSubmitting}>
                          {
                            isSubmitting ? 'Syncing... ' : 'Sync'
                          }
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  )}
              </Formik>
          </DismissableModal>
        </Card.Body>
      </Card>
    );
  }
}

export default SyncMemberWithSendGrid;
