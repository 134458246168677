import { Badge, Button, Col, Row } from "react-bootstrap";
import {
  MdArrowBack,
  MdCancel,
  MdCheckBox,
  MdCheckCircle,
  MdDisabledByDefault,
  MdHelp,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PawGroupStatsItem from "../../../components//PawGroups/PawGroupStatsItem";
import "../../../components/PawGroups/PawGroups.css";
import { handleResetDrugGroupDetail } from "../DrugGroupDetails/DrugGroupDetails.actions";
const PawGroupDisplayBar = ({
  history,
  groupCount,
  showGroupStats = false,
}) => {
  const dispatch = useDispatch();
  const openSwitches = useSelector(
    (state) => state.pawGroupDetail.openSwitches
  );
  const drugGroupLoading = useSelector((state) => state.drugGroup.loading);
  const autoApprovedSwitches = useSelector(
    (state) => state.pawGroupDetail.autoApprovedSwitches
  );
  const manualApprovedSwitches = useSelector(
    (state) => state.pawGroupDetail.manualApprovedSwitches
  );
  const manualRejectedSwitches = useSelector(
    (state) => state.pawGroupDetail.manualRejectedSwitches
  );
  const drugDetails = useSelector(
    (state) => state.drugGroupDetail.drugGroupDetail
  );
  const autoRejectedSwitches = useSelector(
    (state) => state.pawGroupDetail.autoRejectedSwitches
  );
  const groupName = useSelector(
    (state) => state.pawGroupDetail.switchCreationDate
  );
  const clientName = useSelector((state) => state.pawGroupDetail.clientName);
  const isStatusUpdateSuccess = useSelector(
    (state) => state.drugGroupDetail.statusUpdate
  );

  const { pawGroupId, drugGroup } = useParams();

  const statsArray = [
    { iconComponent: MdHelp, label: "Open", value: openSwitches },
    {
      iconComponent: MdCheckCircle,
      label: "Auto Approved",
      value: autoApprovedSwitches,
    },
    {
      iconComponent: MdCheckBox,
      label: "Manual Approved",
      value: manualApprovedSwitches,
    },
    {
      iconComponent: MdCancel,
      label: "Auto Rejected",
      value: autoRejectedSwitches,
    },
    {
      iconComponent: MdDisabledByDefault,
      label: "Manual Rejected",
      value: manualRejectedSwitches,
    },
  ];
  const goBack = () => {
    if (drugGroup != undefined) {
      dispatch(handleResetDrugGroupDetail());
    }
    history.goBack();
  };
  return (
    <div className="paw-display-bar">
      <Row>
        <Col className="d-flex justify-content-start">
          {groupCount == undefined && history ? (
            <Button size="sm" variant="outline-light" onClick={goBack}>
              <MdArrowBack style={{ marginBottom: 4 }} /> Back &nbsp;
            </Button>
          ) : groupCount != undefined ? (
            <>
              {"Total Groups:"} &nbsp;
              <Badge className={"badge-text"} pill bg={"info"} text="dark">
                {groupCount}
              </Badge>
            </>
          ) : null}
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
          {pawGroupId != undefined ? (
            <>
              &nbsp;
              {`PAW Group${groupName ? ": " : ""}`}
              <>
                <strong>{groupName ? groupName : ""}&nbsp;</strong>
                &nbsp;
                {clientName ? ` (${clientName})` : ""}
              </>
            </>
          ) : null}
        </Col>
        <Col />
      </Row>
      {showGroupStats && (
        <Row>
          <Col className="d-flex justify-content-around align-items-center paw-group-stats-container">
            {"PAW Group Stats: "}
            {statsArray.map(({ iconComponent, label, value }, idx) => (
              <PawGroupStatsItem
                key={idx}
                StatIcon={iconComponent}
                statLabel={label}
                statValue={value}
              />
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
};
export default PawGroupDisplayBar;
