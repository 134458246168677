import React from "react";
import { ColumnTypes } from "./Constants";
import { Link } from "react-router-dom";
import { formatDateMMDDYY, formatDateWithTime } from "services/MomentUtils";
import { WrapTextOnNoSpace } from 'components/WrapText';
import { formatDateInEnglish } from "services/MomentUtils";

const LinkColumn = ({ link, value }) => {
  return <a href={link}>{value}</a>;
};

export const RoutableColumn = ({ link, value }) => {
  return (
    <Link to={link} className="column-left">
      {value}
    </Link>
  );
};

const BooleanColumn = ({ value }) => {
  return <span>
    {value ? "Yes" : "No"}
  </span>
}

const TextColumn = ({ value }) => {
  return <span>{( value !== null) ? value : ""}</span>;
};

const DateColumn = ({ value }) => {
  return <span>{formatDateInEnglish(value)}</span>;
};

const DateTimeColumn = ({ value }) => {
  return <span>{formatDateWithTime(value)}</span>
}

export const Column = ({ type, value, link, customComponent = null, row }) => {
  switch (type) {
    case ColumnTypes.Link:
      return <LinkColumn link={link} value={value} />;
    case ColumnTypes.Text:
      return <TextColumn value={value} />;
    case ColumnTypes.Money:
      return <TextColumn value={'$ ' + value} />;
    case ColumnTypes.RouteableLink:
      return <RoutableColumn link={link} value={value} />;
    case ColumnTypes.DateColumn:
      return <DateColumn value={value} />
    case ColumnTypes.WrapTextOnNoSpace:
      return <WrapTextOnNoSpace>{value}</WrapTextOnNoSpace>
    case ColumnTypes.Boolean:
      return <BooleanColumn value={value} />
    case ColumnTypes.DateTimeColumn:
      return <DateTimeColumn value={value} />
    case ColumnTypes.Custom:
      if (customComponent) {
        return customComponent(value, row);
      } else {
        return <TextColumn value={"Custom Failed"} />;
      }
    default:
      return <TextColumn value={value} />;
  }
};






