import React, { useState } from "react";
import { Timeline, StatusTimelineItem } from "plugins/Timeline/Timeline";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";
import FillDetailViewer from "components/FillDetailViewer";
import DismissableModal from "components/DismissableModal";
import SwitchStateChanger from "components/SwitchDetails/SwitchStateChanger/SwitchStateChanger";
import { Alert } from "react-bootstrap";
import SecurePdfViewer from "components/SecurePdfViewer";
import { formatDateForDisplay } from "../../services/MomentUtils";
import Moment from "moment";
import Colors from "lookup/Colors.json";
import { WorkflowConstants } from "lookup/WorkflowConstants";
import UsabilityContext from "lookup/UsabilityContext";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { showNotification } from "services/NotificationService";
import ActivityTimeline from "components/CallGroupDetails/ActivityTimeline";
import { format } from 'date-fns';

//states coming from the backend are mapped to these so refactor carefully
const colors = {
  ancient: Colors["rm-blue-light"],
  completed: Colors["rm-success"],
  error: Colors["rm-warning"],
  failure: Colors["rm-danger"],
  active: Colors["rm-blue"],
  inactiveOnPreviousState: Colors["rm-gray"],
  inactive: Colors["rm-gray"],
};

const SwitchTimeline = (props) => {
  const [showHistory, setShowHistory] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const [activityHistoryDetailsArray, setActivityHistoryDetailsArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStatusColor = (status) => {
    if (status.startsWith("FORWARDED")) return colors.active;
    else if (status.startsWith("ERROR")) return colors.error;
    else if (status.startsWith("FAILED") || status.startsWith("EXPIRED"))
      return colors.failure;
    else if (status.startsWith("READY")) return colors.ancient;
    else return colors.completed;
  }

  const dispayItemHistory = (title, statuses, history) => {
    return (
      <div>
        <Button
          variant="info"
          id="showHistory"
          size="sm"
          onClick={() =>
            setShowHistory(showHistory ? false : true)
          }
        >
          {showHistory ? "Close" : "Expand"}
        </Button>
        {showHistory ? (
          <Timeline>
            {history ? displayHistoryOfChunk(history) : null}
            {statuses.map((internalStatus, jdx) => (
              <StatusTimelineItem
                key={jdx}
                data={{
                  ...internalStatus,
                  date: Moment(internalStatus.date).calendar(),
                }}
                dateText={Moment(internalStatus.date).calendar()}
                dateInnerStyle={{
                  backgroundColor: getStatusColor(internalStatus.status),
                  textIndent: 7,
                  fontSize: "0.7em",
                  wordBreak: "break-all",
                }}
                style={{
                  right: "-1px",
                  color: getStatusColor(internalStatus.status),
                }}
              />
            ))}
          </Timeline>
        ) : null}
      </div>
    );
  }

  const dispayItem = (title, statuses, history) => {
    return (
      <DismissableModal
        openModalButtonId="detailsButton"
        openModalButtonText="Expand"
        openModalButtonStyle="light"
        openModalButtonSize="sm"
        title={title}
        modalId="historyModal"
        onClose={resetModalData}
        size="lg"
      >
        <div id="detailed-history">
          <Timeline>
            {history ? displayHistoryOfChunk(history) : null}
            {statuses.map((internalStatus, jdx) => (
              <StatusTimelineItem
                key={jdx}
                data={{
                  ...internalStatus,
                  date: Moment(internalStatus.date).calendar(),
                }}
                dateText={Moment(internalStatus.date).calendar()}
                dateInnerStyle={{
                  backgroundColor: getStatusColor(internalStatus.status),
                }}
                style={{
                  right: "-1px",
                  color: getStatusColor(internalStatus.status),
                }}
              >
                {props.activityId && props.isAWAdmin ? displayActivityHistory(jdx, statuses) : null}
              </StatusTimelineItem>
            ))}
          </Timeline>
        </div>
      </DismissableModal>
    );
  }

  const displayFax = (switchId, uiState) => {
    return (
      <DismissableModal
        openModalButtonText="View Pdf"
        openModalButtonId="switchdetails-btn-generated-fax-popup"
        title="Redacted Pdf"
        openModalButtonStyle="primary"
        openModalButtonSize="sm"
        modalId="historyModal"
        size="lg"
      >
        <SecurePdfViewer
          displayToolBar={false}
          url={`/pdf/prescriber-inbound-or-generated-redacted?switch_id=${switchId}&ui_state=${uiState}`}
        />
      </DismissableModal>
    );
  }

  const displayHistoryOfChunk = (chunk) => {
    return (
      <StatusTimelineItem
        dateText={chunk.title.toUpperCase()}
        dateInnerStyle={{ background: colors[chunk.state] }}
        style={{ right: "-3px", color: colors[chunk.state] }}
        data={
          chunk.lastEvent
            ? {
              ...chunk.lastEvent,
              date: Moment(chunk.lastEvent.date).format("LLL"),
            }
            : undefined
        }
        showDate={true}
      >
        {chunk.events.length > 0
          ? dispayItemHistory(
            `Detailed History Until Workflow Reset`,
            chunk.events
          )
          : null}
      </StatusTimelineItem>
    );
  }

  const handleToggleExpansion = (index) => {
    const updatedItems = [...expandedItems];
    updatedItems[index] = !updatedItems[index];
    setExpandedItems(updatedItems);
  };

  const resetModalData = () => {
    setExpandedItems([]);
    setActivityHistoryDetailsArray([]);
  }

  const displayActivityHistory = (index, statuses) => {
    const isExpanded = expandedItems[index];
    const isFirstEvent = index > 0 ? false : true;
    const isLastEvent = index < (statuses.length - 1) ? false : true;
    const startEventId = index > 0 ? statuses[index - 1].eventId : statuses[index].eventId;
    const endEventId = index < (statuses.length - 1) ? statuses[index].eventId : statuses[index].eventId;
    
    return (
      <div>
        <Button
          variant="light"
          id="showActivityHistory"
          size="sm"
          onClick={() => fetchActivityHistoryForInterval(index, startEventId, endEventId, isFirstEvent, isLastEvent, isExpanded)}
        >
          {isExpanded ? "Close" : "Expand Activity History"}
        </Button>
        {isExpanded ?
          activityHistoryDetailsArray[index] && activityHistoryDetailsArray[index].events.length > 0 ? (
            <ActivityTimeline
              activityEvents={activityHistoryDetailsArray[index].events}
            />
          ) : (
            <Alert variant="warning" style={{ marginBottom: "10px" }}>
              No Activity history available for this event.
            </Alert>
          )
          : null}
      </div>
    );
  }

  const fetchActivityHistoryForInterval = (index, startEventId, endEventId, isFirstEvent, isLastEvent, isExpanded) => {
    if (!isExpanded) {
      setLoading(true);
      sendAuthenticatedAsyncRequest(
        `/agent-workspace/${props.activityId}/activity-history-for-interval?start_event=${startEventId}&end_event=${endEventId}&is_first_event=${isFirstEvent}&is_last_event=${isLastEvent}`,
        "GET",
        null,
        (r) => {
          const updatedDetails = [...activityHistoryDetailsArray];
          updatedDetails[index] = r.data;
          setActivityHistoryDetailsArray(updatedDetails);
          handleToggleExpansion(index);
          setLoading(false);
        },
        (r) => {
          setLoading(false);
          showNotification({
            title: "Error!",
            message:
              getErrorMessage(r.data) ||
              getErrorMessage("UNABLE_TO_FETCH_ACTIVITY_HISTORY"),
            position: "tr",
            type: "error",
          });
        }
      );
    }
    else {
      handleToggleExpansion(index);
    }
  }

  const renderStateActions = (
    state,
    stateStatus,
    statuses,
    history,
    switchState,
    switchStatus,
    fillDetails,
    isFromAW
  ) => {
    const showResendPrescriber = () =>
      state === "PRESCRIBER" &&
      WorkflowConstants.ALLOWED_RESEND_PRESCRIBER_FAX_STATES.includes(
        stateStatus.status
      ) &&
      stateStatus.status !== "READY" &&
      stateStatus.status !== "WAITING_FOR_PRESCRIBER_FAX_NUM" &&
      stateStatus.status !== "MISSING_PRESCRIBER_CONTACT_INFO" &&
      !isFromAW;
    const showResendPharmacy = () =>
      state === "PHARMACY" &&
      statuses.length > 0 &&
      stateStatus.status !== "WAITING_FOR_PHARMACY_FAX_NUM" &&
      !isFromAW;
    const showReprocessFax = () =>
      state === "RESPONSE" &&
      statuses.length > 0 &&
      stateStatus.status === "PROCESSING_PRESCRIBER_FAX" &&
      !isFromAW;
    const showDisposition = () =>
      state === "RESPONSE" &&
      props.rawId &&
      (stateStatus.status === "ACCEPTED_RESPONSE_FOLLOWUP" || stateStatus.status === "DECLINED_RESPONSE_FOLLOWUP")&&
      !isFromAW;
    const showDispositionButtonWithourResponse = () =>
      state === "PRESCRIBER" &&
      props.rawId &&
      (switchStatus === WorkflowConstants.DELIVERED_PRESCRIBER_FAX ||
        switchStatus === WorkflowConstants.MISSING_PRESCRIBER_CONTACT_INFO) &&
      !isFromAW;
    const showDispositionButtonWithPharmacy = () =>
      state === "PHARMACY" &&
      props.rawId &&
      switchStatus === WorkflowConstants.DELIVERED_PHARMACY_FAX &&
      !isFromAW;
    const showDispositionButtonWithFill = () =>
      state === "FILL" &&
      props.rawId &&
      switchStatus === WorkflowConstants.SUBMITTED_VIA_EHR &&
      !isFromAW;
    const showDispositionButtonWithClosed = () =>
      state === "CLOSED" &&
      props.rawId &&
      switchStatus === WorkflowConstants.DECLINED &&
      !isFromAW;
    const title =
      state.toUpperCase() !== "HISTORY"
        ? `Detailed History for ${state.toUpperCase()} `
        : `Detailed History Until Workflow Reset`;
    const isPrescriberResponseFaxAvailable = props.isPrescriberResponseFaxAvailable;
    return (
      <div className="switch-timeline-action-btns">
        {showResendPrescriber() ? (
          <SwitchStateChanger
            wfItemId={props.wfItemId}
            switchId={props.switchId}
            event="SEND_PRESCRIBER_FAX"
            switchState={switchState}
            onEventExecuted={props.onStateChange}
          />
        ) : null}
        {showResendPharmacy() ? (
          <SwitchStateChanger
            wfItemId={props.wfItemId}
            switchId={props.switchId}
            event="SEND_PHARMACY_FAX"
            switchState={switchState}
            onEventExecuted={props.onStateChange}
          />
        ) : null}
        {showReprocessFax() ? (
          <SwitchStateChanger
            wfItemId={props.wfItemId}
            switchId={props.switchId}
            event="REPROCESS_PRESCRIBER_FAX"
            switchState={switchState}
            onEventExecuted={props.onStateChange}
          />
        ) : null}
        {showDisposition() ? (
          <Button
            variant="outline-warning"
            id="dispositionButton"
            size="sm"
            onClick={() =>
              props.history.push(
                `/switch/disposition/${props.rawId}`,
                {
                  context: UsabilityContext.RAW,
                  isSubmissionRequestFromSwim: true,
                }
              )
            }
          >
            Disposition
          </Button>
        ) : null}
        {showDispositionButtonWithourResponse() ? (
          <Button
            variant="outline-warning"
            id="dispositionButton"
            size="sm"
            onClick={() =>
              props.history.push(
                `/switch/disposition/${props.rawId}`,
                {
                  context: UsabilityContext.RAW,
                  isSubmissionRequestFromSwim: true,
                }
              )
            }
          >
            Disposition
          </Button>
        ) : null}
        {showDispositionButtonWithPharmacy() ? (
          <Button
            variant="outline-warning"
            id="dispositionButton"
            size="sm"
            onClick={() =>
              props.history.push(
                `/switch/disposition/${props.rawId}`,
                {
                  context: UsabilityContext.RAW,
                  isSubmissionRequestFromSwim: true,
                  changeDisposition: true,
                  isForAccepted: true,
                }
              )
            }
          >
            Disposition
          </Button>
        ) : null}
        {showDispositionButtonWithFill() ? (
          <Button
            variant="outline-warning"
            id="dispositionButton"
            size="sm"
            onClick={() =>
              props.history.push(
                `/switch/disposition/${props.rawId}`,
                {
                  context: UsabilityContext.RAW,
                  isSubmissionRequestFromSwim: true,
                  changeDisposition: true,
                  isForAccepted: true,
                  isPrescriberResponseFaxAvailable: isPrescriberResponseFaxAvailable,
                }
              )
            }
          >
            Disposition
          </Button>
        ) : null}
        {showDispositionButtonWithClosed() ? (
          <Button
            variant="outline-warning"
            id="dispositionButton"
            size="sm"
            onClick={() =>
              props.history.push(
                `/switch/disposition/${props.rawId}`,
                {
                  context: UsabilityContext.RAW,
                  isSubmissionRequestFromSwim: true,
                  changeDisposition: true,
                  isForDeclined: true,
                  isPrescriberResponseFaxAvailable: isPrescriberResponseFaxAvailable,
                }
              )
            }
          >
            Disposition
          </Button>
        ) : null}
        {stateStatus.numberOfFills > 0 && !isFromAW ? (
          <FillDetailViewer fillDetails={fillDetails} />
        ) : null}
        {stateStatus.viewPdf && !isFromAW
          ? displayFax(props.switchId, state)
          : null}
        {dispayItem(title, statuses, history)}
      </div>
    );
  }

  const { switchHistory, switchState, switchStatus, fillDetails, isFromAW } = props;
  if (!switchHistory) return "Data required for timeline";
  const data = switchHistory;
  data.sort((chunk1) => (chunk2) => chunk1.order - chunk2.order);
  // const data = this.prepareStubData(switchHistory);
  return (
    <div>
      <Timeline>
        {data.map((chunk, idx) => {
          const numberOfFills = chunk.lastEvent
            ? chunk.lastEvent.numberOfFills
              ? chunk.lastEvent.numberOfFills
              : '0'
            : '0';
          const title = chunk.title === 'FILL' && chunk.state === 'active'
            ? chunk.title + ' ( ' + numberOfFills + ' )'
            : chunk.title;
          const lastEvent = chunk.lastEvent;
          const state = chunk.state;
          if (chunk.title === 'HISTORY' && chunk.events.length === 0)
            return null;
          else
            return (
              <StatusTimelineItem
                key={idx}
                dateText={title.toUpperCase()}
                dateInnerStyle={{ background: colors[state] }}
                style={{ right: '-3px', color: colors[state] }}
                data={lastEvent
                  ? {
                    ...lastEvent,
                    date: formatDateForDisplay(
                      Moment(lastEvent.date)
                    ),
                  }
                  : undefined
                }
                showDate={true}
              >
                {state === 'inactive'
                  ? <span>
                    <div
                      id="timelineStatusItem NOT_STARTED"
                      style={{
                        fontSize: '1.1em',
                        letterSpacing: '1px',
                        color: Colors.rmGray
                      }}>
                      NOT_STARTED
                    </div>
                  </span>
                  : chunk.events.length > 0 || chunk.history
                    ? <div className="mt-1">
                      {renderStateActions(
                        chunk.title,
                        chunk.lastEvent,
                        chunk.events,
                        chunk.history,
                        switchState,
                        switchStatus,
                        fillDetails,
                        isFromAW)}
                    </div> : null
                }
              </StatusTimelineItem>
            );
        })
        }
      </Timeline>

      {/* <div>
        <Container>
          <Row className="bottom-spacer">
            <Col md={{offset: 6, span: 6}}>
              <Row>
                <Col md={12}>
                  <div className='small-label'>
                    legend
                  </div>
                </Col>
                {
                  Object.keys(colors).map((c, i) => (
                    <Col md={6} style={{textTransform: 'capitalize'}}>
                      <span style={{ color: colors[c] }}>
                        &#x25cf; &nbsp; 
                      </span>
                      {c}
                    </Col>
                  ))
                }
              </Row>
            </Col>
          </Row>
        </Container>
      </div> */}
    </div >
  );
}
export default withRouter(SwitchTimeline);
