import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import { MdModeEdit } from "react-icons/md";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import AdminPopUpDismissableModal from "components/adminPopupDismissableModal";
import { getErrorMessage } from "services/UtilityService";
import { showNotification } from "services/NotificationService";
import { formatDateWithTime } from 'services/MomentUtils';
import { twoDecimalPlaceRounder } from 'services/UtilityService';
import "assets/css/user.css";

class EditBatch extends Component {
  state = {
    batch: { description: "", dateScheduled: "", status: "", projectedSavings: 0, numberofSwitch: 0 },
    errorMsg: null,
    selectedBatch: this.props.batch,
    showButton: this.props.showButton,
  };

  editBatch = (values, setSubmitting, closeModalHandle) => {
    let valuesDto = {};
    valuesDto.description = values.description;
    valuesDto.dateScheduled = values.dateScheduled;
    valuesDto.status = values.status;
    valuesDto.projectedSavings = values.projectedSavings;
    valuesDto.numberOfSwitch = values.numberOfSwitch;
    valuesDto.wfBatchId = values.wfBatchId;
    sendAuthenticatedAsyncRequest(
      `/swim/update-batch`,
      "PUT",
      valuesDto,
      (r) => {
        this.setState({ asyncInProgress: false });
        setSubmitting(false);
        showNotification({
          title: "Action successful!",
          message: "Batch edited successfully.",
          position: "tc",
          type: "success",
        });
        closeModalHandle();
        this.props.handler();
      },
      (r) => {
        this.setState({ asyncInProgress: false });
        setSubmitting(false);
        showNotification({
          title: "Error!",
          message:
            getErrorMessage(r.data) || getErrorMessage("UNABLE_TO_EDIT_BATCH"),
          position: "tc",
          type: "error",
        });
      }
    );
  };

  invertShowbutton = () => {
    this.setState((prevState) => ({
      showButton: !prevState.showButton,
    }));
  };

  render() {
    const modalRef = "editBatch";
    const { selectedBatch, showButton } = this.state;

    if (showButton) {
      return (
        <OverlayTrigger overlay={<Tooltip>Edit Batch</Tooltip>}>
          <MdModeEdit
            size={20}
            className="user-action-button edit-user"
            onClick={() => {
              this.invertShowbutton();
            }}
          ></MdModeEdit>
        </OverlayTrigger>
      );
    } else {
      const editBatchFormSchema = Yup.object().shape({
        description: Yup.string()
          .max(100, "Maximum Length: " + 100),
        dateScheduled: Yup.string().required("Date Scheduled is required"),
        status: Yup.string().required("Status is required"),
        projectedSavings: Yup.string().min(0.0, "Minimum Savings: " + 0.0),
        totalSwitches: Yup.string().min(0, "Minimum Switches: " + 0),
      });
      return (
        <>
          <OverlayTrigger overlay={<Tooltip>Edit Batch</Tooltip>}>
            <MdModeEdit
              className="user-action-button edit-user"
              size={20}
              onClick={() => {
                this.invertShowbutton();
              }}
            ></MdModeEdit>
          </OverlayTrigger>
          <AdminPopUpDismissableModal
            ref={modalRef}
            openModalButtonText="Edit Batch"
            openModalButtonStyle="info"
            openModalButtonBlock={true}
            openModalButtonDisabled={false}
            openModalBsuttonId="edit-batch-btn-popup"
            preventCloseWhenClickedOutsideModal={true}
            initialStateOpen={true}
            hideFooter={true}
            onClose={this.invertShowbutton}
            title={"Edit Batch Details"}
            showIcons={true}
          >
            <Formik
               initialValues={{
                ...selectedBatch,
               }}
              onSubmit={(values, { setSubmitting }) => {
                this.editBatch(
                  values,
                  setSubmitting,
                  this.refs[modalRef].handleClose
                );
              }}
              validationSchema={editBatchFormSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.description}
                          onChange={handleChange}
                          name="description"
                          onBlur={handleBlur}
                        ></Form.Control>
                        <Form.Text className="text-small text-danger">
                          {errors.description && touched.description && errors.description}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Batch Status</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.status}
                          onChange={handleChange}
                          name="status"
                          onBlur={handleBlur}
                          readOnly
                        ></Form.Control>
                        <Form.Text className="text-small text-danger">
                          {errors.status && touched.status && errors.status}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Date Scheduled</Form.Label>
                        <Form.Control
                          type="text"
                          value={formatDateWithTime(values.dateScheduled)}
                          onChange={handleChange}
                          name="dateScheduled"
                          onBlur={handleBlur}
                          readOnly
                        ></Form.Control>
                        <Form.Text className="text-small text-danger">
                          {errors.dateScheduled && touched.dateScheduled && errors.dateScheduled}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Total Switches</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.numberOfSwitch}
                          onChange={handleChange}
                          name="numberOfSwitch"
                          onBlur={handleBlur}
                          readOnly
                        ></Form.Control>
                        <Form.Text className="text-small text-danger">
                          {errors.numberOfSwitch && touched.numberOfSwitch && errors.numberOfSwitch}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Projected Savings</Form.Label>
                        <Form.Control
                          type="text"
                          value={twoDecimalPlaceRounder(values.projectedSavings)}
                          onChange={handleChange}
                          name="projectedSavings"
                          onBlur={handleBlur}
                          readOnly
                        ></Form.Control>
                        <Form.Text className="text-small text-danger">
                          {errors.projectedSavings && touched.projectedSavings && errors.projectedSavings}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="bottom-spacer top-spacer">
                    <Col md={{ offset: "4", span: "4" }}>
                      <Button
                        variant="outline-dark"
                        block
                        onClick={() => this.refs[modalRef].handleClose()}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Button
                        type="submit"
                        variant="success"
                        block
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Editing... " : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </AdminPopUpDismissableModal>
        </>
      );
    }
  }
}

export default EditBatch;
