import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';


export const updateUnprocessedFaxList = (pageSize, page, sorted, filtered, resized, expanded) => {
  const filteredDeepCopy = filterModification(filtered);
  const sortedDeepCopy = sortedModification(sorted);
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/fax/unprocessed-fax-list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted: sortedDeepCopy, filtered: filteredDeepCopy }),
      types: [
        {
          type: 'UPDATE_UNPROCESSED_FAX_REQUEST',
          payload: { listState: { pageSize, page, sorted, filtered, resized, expanded } }
        },
        'UPDATE_UNPROCESSED_FAX_SUCCESS',
        'UPDATE_UNPROCESSED_FAX_FAILURE'
      ]
    }
  };
};
export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_UNPROCESSED_FAX"

  }
}
export const updateIndexOnList = (index) => {
  return {
    type: "UPDATE_UNPROCESSED_FAX_INDEX_ON_LIST",
    indexOnList: index
  }
}
const filterModification = (filtered) => {
  let filteredDeepCopy = _.cloneDeep(filtered);

  if (filteredDeepCopy !== undefined && filteredDeepCopy.length > 0) {
    let showMyRecentsFilter = filteredDeepCopy.find(f => f.id === 'showMyRecentsOnly');
    if (showMyRecentsFilter && showMyRecentsFilter.value === true) {
      let recentlyViewedUsersIndex = filteredDeepCopy.findIndex(f => f.id === 'recentlyViewedUsers');
      if (recentlyViewedUsersIndex > -1) {
        filteredDeepCopy.splice(recentlyViewedUsersIndex, 1);
      }
    } else {
      let recentlyViewedUsersIndex = filteredDeepCopy.findIndex(f => f.id === 'recentlyViewedUsers');
      if (recentlyViewedUsersIndex > -1) {
        let filter = filteredDeepCopy[recentlyViewedUsersIndex];
        if (filter.value.length > 0) {
          filteredDeepCopy[recentlyViewedUsersIndex].value = filter.value.map(user => user.id);
        } else {
          filteredDeepCopy.splice(recentlyViewedUsersIndex, 1);
        }
      }
    }
  }

  return filteredDeepCopy;
}

const sortedModification = (sorted) => {
  let sortedCopy = _.cloneDeep(sorted);
  if (sortedCopy !== undefined && sortedCopy.length > 0) {
    let sortedRecentlyViewedUsersIndex = sortedCopy.findIndex(f => f.id === 'recentlyViewedUsers');
    if (sortedRecentlyViewedUsersIndex > -1) {
      let sort = sortedCopy[sortedRecentlyViewedUsersIndex];
      sortedCopy[sortedRecentlyViewedUsersIndex].id = "lastViewedBy";
    }
  }
  return sortedCopy;
}
