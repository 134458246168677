import React, { memo, useState, useEffect } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdLocalOffer } from "react-icons/md";

const MARKET_STATUS_OFF_MARKET = "Off-market";
const MARKET_STATUS_PARTIALLY_OFF_MARKET = "Partially Off-market";

const DynamicSwitchOptionFixedFields = ({
  alternativeDrugNames = "",
  medName = "",
  medForm = "",
  brandGenType = "",
  itemType = "",
  routeOfAdministration = "",
  estimatedCost = "0.00",
  gpiCode = "",
  isPharmacistReviewRequired = true,
  dynamicSwitchItems,
}) => {
  useEffect(() => {
    let numOfOffMarket = 0;
    let numOfTotal = dynamicSwitchItems.filter(
      (item) => item.isActive && !item.isDeleted
    ).length;
    dynamicSwitchItems.forEach((item) => {
      if (item.isNdcPresent === false && item.isActive && !item.isDeleted) {
        numOfOffMarket += 1;
      }
    });
    if (numOfOffMarket === 0) {
      setMarketStatus("");
      setMarketStatusBgClass("");
    } else if (numOfOffMarket > 0) {
      if (numOfOffMarket < numOfTotal) {
        setMarketStatus(MARKET_STATUS_PARTIALLY_OFF_MARKET);
        setMarketStatusBgClass("warning");
      } else if (numOfOffMarket === numOfTotal) {
        setMarketStatus(MARKET_STATUS_OFF_MARKET);
        setMarketStatusBgClass("danger");
      }
    }
  }, [dynamicSwitchItems]);

  const [marketStatus, setMarketStatus] = useState("");
  const [marketStatusBgClass, setMarketStatusBgClass] = useState("");

  return (
    <>
      {alternativeDrugNames ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 50, hide: 400 }}
          overlay={
            <Tooltip id="alternative-drugs">{alternativeDrugNames}</Tooltip>
          }
          enabled
        >
          <span className="drug-title">
            {medName} <MdLocalOffer className="alternative-label" />
          </span>
        </OverlayTrigger>
      ) : (
        <span className="drug-title">{medName} </span>
      )}
      <Badge bg="info" className="drug-option-title-badge">
        {medForm}
      </Badge>{" "}
      <Badge
        bg={brandGenType === "B" ? "secondary" : "success"}
        className="drug-option-title-badge"
      >
        {brandGenType === "B"
          ? "Brand"
          : brandGenType === "G"
          ? "Generic"
          : brandGenType}
      </Badge>{" "}
      <Badge bg="primary" className="drug-option-title-badge">
        {routeOfAdministration}
      </Badge>{" "}
      <Badge bg="success" className="drug-option-title-badge">
        $ {estimatedCost ? parseFloat(estimatedCost).toFixed(2) : "0.00"}
      </Badge>{" "}
      <Badge bg="dark" className="drug-option-title-badge text-capitalize">
        {" "}
        {gpiCode || "None"}
      </Badge>{" "}
      {marketStatus !== "" && (
        <Badge bg={marketStatusBgClass} className="drug-option-title-badge">
          {marketStatus}
        </Badge>
      )}
      {isPharmacistReviewRequired ? (
        <Badge bg="danger" className="drug-option-title-badge text-capitalize">
          {" "}
          Review Required
        </Badge>
      ) : null}
    </>
  );
};
export default memo(DynamicSwitchOptionFixedFields);
