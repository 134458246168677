import React from 'react';

import EventDispatcher from './EventDispatcher';

export default class SwitchStateChanger extends React.Component {
  state = {
    switchId: this.props.switchId,
    event: this.props.event,
    onEventExecuted: this.props.onEventExecuted,
    wfItemId: this.props.wfItemId,
    switchState: this.props.switchState,
    actionDisabled: this.props.actionDisabled 
  }

  eventToPropsMap = {
    SEND_PRESCRIBER_FAX: {
      modalOpenerProps: {variant: 'outline-primary', size: 'sm', children: 'Resend Fax', block: false},
      eventExecuterProps: {variant: 'warning', children: 'Resend message to Prescriber'},
      modalProps: {
        title: 'Resend to Prescriber', 
        children: <span>Are you sure you want to <b>resend</b> this message to the prescriber?</span>
      }
    },
    SEND_PHARMACY_FAX: {
      modalOpenerProps: {variant: 'outline-primary', size: 'sm', children: 'Resend Fax', block: false},
      eventExecuterProps: {variant: 'warning', children: 'Resend message to Pharmacy'},
      modalProps: {
        title: 'Resend to Pharmacy', 
        children: <span>Are you sure you want to <b>resend</b> this message to the pharmacy? </span>
      }
    },
    HOLD: {
      modalOpenerProps: {variant: 'light', size: '', children: 'Hold', block: true, className: "btn-block"},
      eventExecuterProps: {variant: 'warning', children: 'Hold'},
      modalProps: {
        title: 'Hold Switch', 
        children: <span>Are you sure you want to <b>hold</b> this switch? This will make the switch immune to any actions. </span>
      }
    },
    GENERATE_PDF: {
      modalOpenerProps: {variant: 'light', size: '', children: 'Regenerate PDF', block: true, className: "btn-block"},
      eventExecuterProps: {variant: 'warning', children: 'Regenerate PDF'},
      modalProps: {
        title: 'Regenerate PDF', 
        children: <span>Are you sure you want to <b>regenerate</b> pdf? This resets the switch lifecycle. </span>
      }
    },
    RESUME: {
      modalOpenerProps: {variant: 'success', size: '', children: 'Resume', block: true},
      eventExecuterProps: {variant: 'success', children: 'Resume Switch'},
      modalProps: {
        title: 'Resume Switch', 
        children: <span>Are you sure you want to <b>resume</b> previously held switch? This resets the switch lifecycle. </span>
      }
    },
    NO_PRESCRIBER_RESPONSE: {
      modalOpenerProps: {variant: 'warning', size: '', children: 'Close Switch (No Prescriber Response)', block: true},
      eventExecuterProps: {variant: 'warning', children: 'Close Switch'},
      modalProps: {
        title: 'Close Switch', 
        children: <span>Are you sure you want to <b>close</b> this switch because no response has been received from the prescriber?</span>
      }
    },
    NO_PRESCRIBER_CONTACT: {
      modalOpenerProps: {variant: 'warning', size: '', children: 'Close Switch (No Prescriber Contact)', block: true},
      eventExecuterProps: {variant: 'warning', children: 'Close Switch'},
      modalProps: {
        title: 'Close Switch', 
        children: <span>Are you sure you want to <b>close</b> this switch because there is no or invalid prescriber contact?</span>
      }
    },
    REPROCESS_PRESCRIBER_FAX: {
      modalOpenerProps: {variant: 'outline-primary', size: 'sm', children: 'Reprocess', block: false},
      eventExecuterProps: {variant: 'warning', children: "Reprocess prescriber's response"},
      modalProps: {
        title: 'Reprocess Response', 
        children: <span>Are you sure you want to <b>reprocess</b> prescriber's response fax?</span>
      }
    },
  };


  render () {
    const {switchId, event, onEventExecuted, wfItemId, switchState, actionDisabled} = this.state;
    const eventProps = this.eventToPropsMap[event];
    return (
      <EventDispatcher 
        switchId={switchId}
        wfItemId={wfItemId}
        event={event}
        modalOpenerProps={{
          ...eventProps.modalOpenerProps,
          disabled: actionDisabled
        }}
        eventExecuterProps={eventProps.eventExecuterProps}
        modalProps={eventProps.modalProps}
        onEventExecuted={onEventExecuted}
        switchState={switchState}
      />
    );
  }
}
  