import React from 'react';
import { updateRawList, updateRawIndexOnList, resetError } from './RawList.actions';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ResetListState from '../../../components/ResetListState';
import Tip from '../../../components/Tip';
import { useDispatch, useSelector } from "react-redux";

import { RawConstants, FILTER, DEFAULT_FILTER } from 'lookup/RawConstants';
import { DateConstants } from 'lookup/DateConstants';
import Colors from 'lookup/Colors';
import { SWITCH_NUMBER_STRING, SWITCH_NUMBER_OP } from 'lookup/GlobalConstants';


import BootstrapLink from '../../../components/BootstrapLink';
import { twoDecimalPlaceRounder, getColorForPatientAge } from '../../../services/UtilityService';


import { formatDateForDisplay } from 'services/MomentUtils';

import { Badge } from 'react-bootstrap';

import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import { showNotification } from 'services/NotificationService';
import {resetUnlinkDeclinedFax} from "../../../components/UnlinkDeclinedFax/UnlinkDeclinedFax.actions";

const DEFAULT_PAGE_SIZE = 15;

const RawList = ({ history }) => {
  const dispatch = useDispatch();
  const rawState = useSelector((state) => state.rawList);
  const loading = rawState.loading;
  const isError = rawState.isError;
  const totalElements = rawState.totalElements;
  const pages = rawState.pages;
  const data = useSelector((state) => state.rawList.data);
  const statusFilterMap = RawConstants.statusesMultiSelect;
  const isSuccessful = useSelector(state => state.unlinkDeclinedFax.isSuccessful);
  
  if (isSuccessful) {
    showNotification({
        title: "Successfully unlinked Fax!",
        message: "The fax has been successfully unlinked.",
        position: "tr",
        type: "success"
    });
    dispatch(resetUnlinkDeclinedFax());
   

}
  const listState = {
    data: rawState.data,
    page: rawState.currentPage,
    pageSize: rawState.pageSize,
    sorted: rawState.sorted ? rawState.sorted : [],
    filtered: rawState.filtered ? rawState.filtered : [],
    totalPages: rawState.totalPages ? rawState.totalPages : 0,
    resized: rawState.resized,
    expanded: rawState.expanded,
  };

  if (listState && listState.sorted) {
    const sorted = listState.sorted.filter(sort => sort.id !== 'referrerId')
    if (sorted.length !== listState.sorted.length)
      listState.sorted = sorted
  }

  const index = listState.filtered.findIndex(element => element.id === SWITCH_NUMBER_STRING)

  React.useEffect(() => {
    dispatch(updateRawList(rawState.pageSize, 0, rawState.sorted, rawState.filtered));
  }, [dispatch]);
  
  React.useEffect(() => {
    if (isError === true) {
      showNotification({
        title: "Error Loading Data!",
        message: "Something went Wrong.",
        position: "bl",
        type: "error",
      });
      dispatch(resetError());
    }
  }, [isError]);

  const updateData = (state, preventUpdate) => {
    if (!preventUpdate) {
      state.filtered = JSON.parse(JSON.stringify(state.filtered));
      state.filtered = state.filtered.filter(element => element.value.length > 0);

      const index = state.filtered.findIndex(element => element.id === SWITCH_NUMBER_STRING)
      if (index !== -1) {
        state.filtered[index].operator = SWITCH_NUMBER_OP;
        state.filtered[index].id = SWITCH_NUMBER_STRING;
      }
      const index_status = state.filtered.findIndex(element => element.id === 'status')

      if (index_status !== -1) {
        state.filtered[index_status].value = state.filtered[index_status].value.map(value => typeof value === 'string' ? value : value.id);
      }

      let statusIndex = state.filtered.findIndex(x => x.id == 'status' && (x.value === DEFAULT_FILTER.value || x.value === RawConstants.DEFAULT_FILTER_CONST ||
        x.value.includes('Default Filters')));
      if (statusIndex > -1) {
        state.filtered[statusIndex] = FILTER.defaultBackend;
      }
      statusIndex = state.filtered.findIndex(x => x.id == 'status' && (x.value.includes('All Filters')));
      if (statusIndex > -1) {
        state.filtered[statusIndex] = FILTER.allBackend;
      }

      const sorted = state.sorted.filter(element => element.id === 'status' && element.desc)
      if (sorted.length !== 0)
        state.sorted.push(RawConstants.REFFRER_SORT_ASC)
      else {
        const sortedAsc = state.sorted.filter(element => element.id === 'status' && !element.desc)
        if (sortedAsc.length !== 0)
          state.sorted.push(RawConstants.REFFRER_SORT_DESC)
      }
      dispatch(updateRawList(state.pageSize, state.page, state.sorted, state.filtered, state.resized, state.expanded));
    }
  }

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    listState.pageSize = pageSize;
    listState.page = pageNo;
    listState.sorted = sortBy ? sortBy : [];
    listState.filtered = filterValues ? filterValues : [];
    updateData(listState);
  }


  const renderOwnerCell = (name) => {
    if (!name)
      return "No Owner";
    else
      return name;

  }

  const renderStatusCell = (row) => {
    let status = row.original.status;
    if (status === RawConstants.status.CLAIMED && row.original.referrerId)
      status = RawConstants.status.FORWARDED;
    const { workflowStatus } = row.original.status;
    const forwardedFromSwim = workflowStatus === 'PENDING_RESPONSE_REVIEW' ? <Badge size='sm' bg='warning'>Forwarded from SWIM</Badge> : null;
    return (
      <span>
        <span style={{ color: RawConstants.statusColors[status] }}>
          &#x25cf; &nbsp;
        </span>
        {RawConstants.statusLabels[status]} {forwardedFromSwim}
      </span>
    )
  }

  const renderDateCell = (date, days) => {
    return (
      <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {date}
      </span>
    );
  }

  const renderEffectiveDateCell = (date, status) => {
    return (
      <span style={{ width: "100%", display: "inline-block", textAlign: "center" }}>
        <span style={{ color: DateConstants.statusColors[status] }}>
          &#x25cf; &nbsp;
        </span>
        {date}
      </span>
    )
  }

  const renderEffectiveDateCol = (date, days) => {
    if (date) {
      const status = (days >= 0) ? 'ComingDue' : 'PastDue'
      return renderEffectiveDateCell(formatDateForDisplay(date), status);
    }
  }

  const renderLastModifiedDateCol = (date, days) => {
    if (date) {
      return renderDateCell(formatDateForDisplay(date), days);
    }
    return <span className="center">N/A</span>;
  }

  const renderSavingsCell = (savings) => {
    return <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>${twoDecimalPlaceRounder(savings)}</span>;
  }

  const columns = [
    {
      header: "Cust",
      accessor: "customerNameAbbreviation",
      accessorKey: "customerNameAbbreviation",
      width: 60,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Client",
      accessor: "clientNameAbbreviation",
      accessorKey: "clientNameAbbreviation",
      width: 60,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Switch Id",
      accessor: "switchId",
      accessorKey: "switchId",
      width: 95,
      show: false,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Switch Id",
      accessor: "switchNumber",
      accessorKey: "switchNumber",
      width: 80,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Switch Id",
      accessor: "globalSwitchId",
      accessorKey: "globalSwitchId",
      width: 80,
      enableSorting: true,
      enableColumnFilter: true,
      show: false,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Status",
      accessor: "status",
      accessorKey: "status",
      width: 150,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.MultiSelect,
      filterOptions: statusFilterMap,
      customComponent: (value, row) => {
        return renderStatusCell(row)
      },
    },
    {
      header: "Owner",
      accessor: "ownerName",
      accessorKey: "ownerName",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SearchableInput,
      customComponent: (value) => {
        return renderOwnerCell(value)
      },
    },
    {
      header: "daysAfterLastModifiedDate",
      accessor: "daysAfterLastModifiedDate",
      accessorKey: "daysAfterLastModifiedDate",
      width: 95,
      show: false,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Last Modified",
      accessor: "lastModifiedDate",
      accessorKey: "lastModifiedDate",
      width: 125,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return renderLastModifiedDateCol(row.original.lastModifiedDate, row.original.daysAfterLastModifiedDate)
      },
    },
    {
      header: "daysToEffectiveDate",
      accessor: "daysToEffectiveDate",
      accessorKey: "daysToEffectiveDate",
      width: 125,
      show: false,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Effective Date",
      accessor: "effectiveDate",
      accessorKey: "effectiveDate",
      width: 125,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return renderEffectiveDateCol(row.original.effectiveDate, row.original.daysToEffectiveDate)
      },
    },
    {
      header: "Prescriber",
      accessor: "prescriberName",
      accessorKey: "prescriberName",
      width: 180,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Member #",
      accessor: "memberNumberWithoutCustId",
      accessorKey: "memberNumberWithoutCustId",
      width: 110,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Projected Savings",
      accessor: "projectedSavings",
      accessorKey: "projectedSavings",
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value) => {
        return renderSavingsCell(value)
      },
    },
  ];
  return (
    <Container className="list-container" fluid>
      <Row>
        <Col md={12}>
          <div className='codex-version-display-bar'>
            <span style={{ float: 'left' }} > Total Responses: <strong>{totalElements}</strong></span>
            <strong style={{ float: 'center' }}>Response List</strong>
            <ResetListState
              onReset={() => updateData({ ...listState, filtered: [FILTER.defaultBackend], sorted: RawConstants.DEFAULT_SORT })}
              resetterDisabled={listState.filtered === [FILTER.defaultBackend] && listState.sorted === RawConstants.DEFAULT_SORT}
            />

          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ServerSideReactTable
            columnDefination={columns}
            data={data}
            currentPage={listState.page}
            sorted={listState.sorted}
            filters={listState.filtered}
            totalPages={pages}
            totalRecords={totalElements == "loading" ? 0 : totalElements}
            loading={loading}
            enableMultiRowSelection={false}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            pageSizeOptions={[5, 10, 15, 20, 30, 50]}
            onUpdateTableData={onUpdateTable}
            onRowClick={(rowInfo) => {
              if (rowInfo !== undefined) {
                updateRawIndexOnList(rowInfo.index);
                history.push(`/raw/${rowInfo.original.rawId}`);
              }
            }}
          />
          <Tip>
            Tip: Hold shift when sorting to multi-sort and disable sort!
          </Tip>
        </Col>
      </Row>
    </Container>
  );
}


export default RawList;