import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DismissableModal from 'components/DismissableModal';
import { FormGroup } from 'react-bootstrap';
import { showNotification } from 'services/NotificationService';
import { Multiselect } from "multiselect-react-dropdown";
import { getErrorMessage } from "services/UtilityService";
import Spinner from './Spinner';


const AutoAcceptEnablement = () => {

    const modalRef = useRef("autoAcceptEnablement");
    const [customersList, setCustomersList] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchCustomers = () => {
        sendAuthenticatedAsyncRequest(
            `/customers/list`,
            "GET",
            null,
            (r) => {
                setCustomersList(r.data.map((c) => ({
                    value: c.name,
                    label: c.id,
                })));
                setSelectedCustomers(r.data.map((c) => ({
                    value: c.name,
                    label: c.id,
                    flag: c.isAutoAcceptEnabled
                })).filter(c => c.flag));
                setLoading(false);
            },
            (r) => {
                setCustomersList([]);
                setSelectedCustomers([]);
                setLoading(false);
            }
        );
    }

    const submitForm = (originalValues, setSubmitting, closeModal) => {
        const dto = {
            customerIds: originalValues.customers
        }
        sendAuthenticatedAsyncRequest(
            `/customers/auto-accept-enablement/update`,
            "PUT",
            dto,
            (r) => {
                setSubmitting(false);
                showNotification({
                    title: 'Action successful!',
                    message: 'Customer Auto Accept settings updated successfully.',
                    position: 'tr',
                    type: "success"
                });
                closeModal();
            },
            (r) => {
                setSubmitting(false);
                showNotification({
                    title: 'Error!',
                    message: getErrorMessage(r.data) || getErrorMessage('SAVE_CUSTOMER_AUTO_ACCEPT_SETTING_FAILED'),
                    position: 'tr',
                    type: "error"
                });
            }
        );
    }

    const onMultiSelectChange = (options, values) => {
        let tempList = [];
        options.map((x) => tempList.push(x.label));
        values.customers = tempList;
    }

    useEffect(() => {
        fetchCustomers();
    }, []);

    return (<>
        <Card style={{ textAlign: 'center' }}>
            <Card.Body>
                <Card.Text>
                    Enable/Disable Customer Auto Accept.
                </Card.Text>
                <DismissableModal
                    ref={modalRef}
                    openModalButtonText="Auto accept"
                    openModalButtonStyle="info"
                    openModalButtonBlock={true}
                    openModalButtonDisabled={false}
                    openModalButtonId='customer-auto-accept-btn-popup'
                    preventCloseWhenClickedOutsideModal={true}
                    onClose={() => fetchCustomers()}
                    hideFooter={true}
                    title="Customer Auto Accept Enablement" >
                    {loading ? <Spinner /> : (<Formik
                        initialValues={{
                            customers: selectedCustomers.map((customer) => customer.label)
                        }}
                        onSubmit={(values, { setSubmitting, setValues }) => {
                            submitForm(values, setSubmitting, modalRef.current.handleClose);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            dirty,
                            isValid
                        }) => (
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Form.Label>Auto Accept enabled for customers:</Form.Label>
                                                <Multiselect
                                                    placeholder="Select Customer"
                                                    options={customersList}
                                                    displayValue="value"
                                                    name="customers"
                                                    value={values.customers}
                                                    onSelect={(e) => onMultiSelectChange(e, values)}
                                                    onRemove={(e) => onMultiSelectChange(e, values)}
                                                    style={{
                                                        chips: {
                                                            background: "#007BBB",
                                                        },
                                                    }}
                                                    showArrow
                                                    hidePlaceholder
                                                    selectedValues={selectedCustomers}
                                                ></Multiselect>
                                                <Form.Text className="text-small text-danger">
                                                    {errors.switchList}
                                                </Form.Text>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="top-spacer">
                                        <Col md={4} className="bottom-spacer">
                                            <Button
                                                type="submit"
                                                variant="success"
                                                className="btn-block btn-text-white"
                                                block
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? "Saving ... " : "Save"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                        )}
                    </Formik>)}
                </DismissableModal>
            </Card.Body>
        </Card>
    </>)
}

export default AutoAcceptEnablement;