import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateUnprocessedTranscriberFaxList,
  updateTranscriberIndexOnList,
  resetError,
} from "./TranscriberList.actions";
import { withRouter } from "react-router-dom";
import ResetListState from "components/ResetListState";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { TranscriptionConstants } from "lookup/TranscriptionConstants";

import { formatDateForDisplay } from "services/MomentUtils";
import { showNotification } from "services/NotificationService";
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';

const Tip = (props) => (
  <div style={{ textAlign: "center", color: "#888", fontSize: "0.9em" }}>
    <em>{props.children}</em>
  </div>
);

const DEFAULT_PAGE_SIZE = 15;

class TranscriberList extends React.Component {
  state = {
    loading: true,
    data: this.props.data,
    isError: false,
    pages: this.props.pages,
    totalElements: this.props.totalElements,
    listState: this.props.listState,
  };

  updateData = (state, preventUpdate) => {
    if (!preventUpdate) {
      if (
        state.sorted !==
        TranscriptionConstants.DEFAULT_TRANSCRIBED_FAX_LIST_SORTING
      )
        state.sorted.forEach((element, i) => {
          element.isDefault = false;
        });

      this.props.updateUnprocessedTranscriberFaxList(
        state.pageSize,
        state.page,
        state.sorted,
        state.filtered,
        state.resized,
        state.expanded
      );
    } else {
      // should have a separate action
      this.setState({ listState: state });
    }
  };

  onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    this.state.listState.pageSize = pageSize;
    this.state.listState.page = pageNo;
    this.state.listState.sorted = sortBy ? sortBy : [];
    this.state.listState.filtered = filterValues;
    this.updateData(this.state.listState);
  }

  componentDidMount() {
    this.updateData(this.state.listState);
  }
  componentDidUpdate(prevProps) {
    const { data, pages, totalElements, listState, loading, isError, resetError } = this.props;
  
    if (prevProps.data !== data ||
        prevProps.pages !== pages ||
        prevProps.totalElements !== totalElements ||
        prevProps.listState !== listState ||
        prevProps.loading !== loading) {
      this.setState({ data, pages, totalElements, loading, listState });
    }
  
    if (isError === true && prevProps.isError !== isError) {
      showNotification({
        title: "Error Loading Data!",
        message: "You have entered invalid information.",
        position: "bl",
        type: "error",
      });
      resetError();
    }
  }
  

  renderDateCell = (date) => {
    return (
      <span
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        {date}
      </span>
    );
  };

  renderEffectiveDateCol = (date) => {
    if (date) {
      return this.renderDateCell(formatDateForDisplay(date));
    }
    return <span className="center">N/A</span>;
  };

  renderLastModifiedDateCol = (date) => {
    if (date) {
      return this.renderDateCell(formatDateForDisplay(date));
    }
    return <span className="center">N/A</span>;
  };

  render() {
    const { data, pages, loading, totalElements, listState } = this.state;
    const columns = [
      {
        header: "Fax Document",
        accessor: "faxFileKey",
        accessorKey: "faxFileKey",
        width: 400,
        enableSorting: false,
        enableColumnFilter: true,
        columnType: ColumnTypes.WrapTextOnNoSpace,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Associated Switch",
        accessor: "associatedSwitchId",
        accessorKey: "associatedSwitchId",
        width: 200,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.WrapTextOnNoSpace,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Customer",
        accessor: "customer",
        accessorKey: "customer",
        width: 100,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.WrapTextOnNoSpace,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Client",
        accessor: "client",
        accessorKey: "client",
        width: 100,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.WrapTextOnNoSpace,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Disposition",
        accessor: "disposition",
        accessorKey: "disposition",
        width: 200,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.WrapTextOnNoSpace,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Last Modified Date",
        accessor: "lastModifiedDate",
        accessorKey: "lastModifiedDate",
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return this.renderLastModifiedDateCol(value)
        },
        filterType: FilterTypes.DateRangePicker,
        placeDatePickerAtEnd :true
      },
      {
        header: "Received Date",
        accessor: "generatedDate",
        accessorKey: "generatedDate",
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return this.renderEffectiveDateCol(value)
        },
        filterType: FilterTypes.DateRangePicker,
        placeDatePickerAtEnd :true
      },
    ];
    return (
      <Container className="list-container" fluid>
        <Row>
          <Col md={12}>
            <div className="codex-version-display-bar">
              <span style={{ float: "left" }}>
                {" "}
                Total Transcribed Faxes: <strong>{totalElements}</strong>
              </span>
              <strong style={{ float: "center" }}>Transcribed Fax List</strong>
              <ResetListState
                onReset={() =>
               
                    this.updateData(
                      {
                        ...listState,
                        filtered: TranscriptionConstants.DEFAULT_TRANSCRIBED_FAX_LIST_FILTER,
                        sorted: TranscriptionConstants.DEFAULT_TRANSCRIBED_FAX_LIST_SORTING,
                      },
                      false
                    )
                  
                }
                resetterDisabled={
                  listState.filtered ===
                    TranscriptionConstants.DEFAULT_TRANSCRIBED_FAX_LIST_FILTER &&
                  listState.sorted ===
                    TranscriptionConstants.DEFAULT_TRANSCRIBED_FAX_LIST_SORTING
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ServerSideReactTable
              columnDefination={columns}
              data={data}
              currentPage={listState.page}
              sorted={listState.sorted}
              filters={listState.filtered}
              totalPages={pages}
              totalRecords={totalElements=="loading"?0:totalElements}
              loading={loading}
              enableMultiRowSelection={false}
              addRowSelection={false}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              pageSizeOptions={[5, 10, 15, 20, 30, 50]}
              onUpdateTableData={this.onUpdateTable}
              onRowClick={(rowInfo) => {
                if (rowInfo !== undefined) {
                  this.props.updateTranscriberIndexOnList(
                    rowInfo.index
                  );
                  this.props.history.push(
                    `/transcription/${rowInfo.original.faxInfoIdentifier}`
                  );
                }
              }}
            />
            <Tip>
              Tip: Hold shift when sorting to multi-sort and disable sort!
            </Tip>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.transcriberList.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: TranscriptionConstants.DEFAULT_TRANSCRIBED_FAX_LIST_SORTING,
      filtered: TranscriptionConstants.DEFAULT_TRANSCRIBED_FAX_LIST_FILTER,
      resized: [],
      expanded: {},
    };
  }
  return {
    //update to transcriberList global state
    data:
      state.transcriberList.data !== undefined
        ? state.transcriberList.data
        : [],
    pages: state.transcriberList.pages ? state.transcriberList.pages : 0,
    totalElements:
      state.transcriberList.totalElements !== undefined
        ? state.transcriberList.totalElements
        : "Loading ...",
    loading: state.transcriberList.loading,
    listState: listState,
    isError: state.transcriberList.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUnprocessedTranscriberFaxList: bindActionCreators(
      updateUnprocessedTranscriberFaxList,
      dispatch
    ),
    updateTranscriberIndexOnList: bindActionCreators(
      updateTranscriberIndexOnList,
      dispatch
    ),
    resetError: bindActionCreators(resetError, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TranscriberList));
