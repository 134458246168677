import React from "react";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import ButtonWithPopover from "components/ButtonWithPopover";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormSection from "components/AgentWorkspace/FormSection";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import { withRouter } from "react-router-dom";
import "./FormSection.css";
import { Container } from "react-bootstrap";

class TranscriberForm extends React.Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.transForm = this.transForm.bind(this);
  }

  transForm = () => {
    this.forceUpdate();
  };

  state = {
    image: this.props.image,
    pageData: this.props.pageData,
    updateSection: this.props.updateSection,
    sendUpdatedResponse: this.props.sendUpdatedResponse,
    faxId: this.props.faxId,
    formData: this.props.formData,
    gsi: this.props.pageData["Global Switch Id"].globalSwitchId.val,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.pageData !== this.props.pageData ||
      prevProps.image !== this.props.image) {
      this.setState({ pageData: this.props.pageData, image: this.props.image });
    }
  }
  unselectOtherTargets = (targetName) => {
    let pageData = this.state.pageData;
    for (let section in pageData) {
      if (section.includes("target")) {
        if (section !== targetName) {
          pageData[section].selected = false;
        }
        else {
          pageData[section].selected = true;
        }
      }
    }
    this.setState({ pageData: pageData });
  }

  render() {
    const {
      image,
      pageData,
      updateSection,
      sendUpdatedResponse,
      faxId,
      formData,
      gsi,
    } = this.state;
    const reference = "saveConfirmation";
    // send two loading variable for the loading states and then render accordingly
    return (
      <Container fluid>
        <Row>
          <Col md={5} lg={4}>
            <Row>
              <Col>
                <Card className="form-section">
                  <Card.Title className="gsi">
                    Global Switch ID: {gsi}
                    {delete pageData["Global Switch Id"]}
                  </Card.Title>
                </Card>
                {Object.keys(pageData).map((sectionKey, ind) => {
                  return (
                    <FormSection
                      key={sectionKey}
                      sectionName={sectionKey}
                      formSection={pageData[sectionKey]}
                      updateSection={updateSection}
                      unselectOtherTargets={this.unselectOtherTargets}
                      allowEditing={true}
                      imageRef={this.imageRef}
                      rerenderParentCallback={this.props.rerenderParentCallback}
                      transForm={this.transForm}
                    ></FormSection>
                  );
                })}
              </Col>
            </Row>
            <Row className="top-spacer">
              <Col style={{ display: "flex", justifyContent: "right" }}>
                <ButtonWithPopover
                  placement="right"
                  customRef={reference + "bwp"}
                  buttonVariant="success"
                  buttonText="Save Form"
                  buttonIsBlock={false}
                  buttonIsDisabled={false}
                  popoverTitle="Save the current form"
                  buttonId="fax-save-button-popover"
                >
                  <p className="confirmation-text">
                    Do you want to <b>save</b> this fax?
                  </p>
                  <div className="align-content-right">
                    <Button
                      id="fax-btn-save"
                      className="left-spacer"
                      variant="success"
                      onClick={() => sendUpdatedResponse(faxId, formData)}
                    >
                      Save Fax
                    </Button>
                  </div>
                </ButtonWithPopover>
              </Col>
            </Row>
          </Col>
          <Col md={7} lg={8}>
            <img id="my-image" src={image} ref={this.imageRef} width="100%" />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect()(withRouter(TranscriberForm));
