
import React, { Component } from 'react';
import {sendAuthenticatedAsyncRequest} from '../../services/AsyncRequestService';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormLabel from 'react-bootstrap/FormLabel';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { getErrorMessage } from 'services/UtilityService';
import PasswordValidationFeedback from 'components/User/PasswordValidationFeedback';


class ChangePassword extends Component{
  state = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    error: "",
    success: "",
    passwordPolicy: {},
    requestInProgress: false
  }

  componentDidMount() {
    this.fetchPasswordPolicy();
  }

  fetchPasswordPolicy(){
    this.setState({requestInProgress: true})
    sendAuthenticatedAsyncRequest(
      `/user/password-policy`, 
      'GET', 
      null, 
      (response) => {
        const {data} = response;
        this.setState({passwordPolicy: data, requestInProgress: false});
      }, 
      (error) => {
        const data = getErrorMessage(error.data) || getErrorMessage('PASSWORD_POLICY_NOT_FETCHED');
        this.setState({error: data, requestInProgress: false});
      });
  }

  
  handleChange = e => {
    const value = e.target.value;
  	const name = e.target.name;
  	this.setState({[name]: value, error: ""});
  }
  
  handleChangePassword = (e) => {
    e.preventDefault();
    const {currentPassword, newPassword, newPasswordConfirm} = this.state;
    
    let error = "";
    if(!currentPassword)
      error = "Current password cannot be empty.";
    else if(!newPassword)
      error = "New password cannot be empty.";
    else if(newPassword !== newPasswordConfirm)
      error = "Passwords do not match.";

    if(error) {
      this.setState({error: error});
      return;
    }

    let url = "/change-password";
    let method = "POST";
    let data = {previousPassword: currentPassword, proposedPassword: newPassword, accessToken: localStorage.getItem("access_token")};
    this.setState({error: "", success: "", requestInProgress: true});
    sendAuthenticatedAsyncRequest(url,method,data,this.onChangePasswordSuccess,this.onChangePasswordFailure);
  }

  onChangePasswordSuccess = (response) => {
    this.setState({requestInProgress: false, currentPassword: "", newPassword: "", newPasswordConfirm: "", success: "Password changed successfully"});
  }

  onChangePasswordFailure = (error) => {
    this.setState({requestInProgress: false});
  	this.setState({error: (getErrorMessage(error.data) || getErrorMessage('PASSWORD_CHANGE_FAILED'))});
  }

  render() {
    const { currentPassword, newPassword, newPasswordConfirm, error, success, requestInProgress, passwordPolicy } = this.state;

    return (
      <div className="change-password-page">
        <Container fluid >
          <Row>
            <Col md={{ span: 6, offset: 3}}>
              <div className="change-password-box">
                <Card className="big-top-spacer" variant="primary">
                  <Card.Body>
                  <Card.Title className="text-center">Change Password</Card.Title>
                  {
                    error ? <Alert variant="danger">{error}</Alert> : null
                  }
                  {
                    success ? <Alert variant="success">{success}</Alert> : null
                  }
                  <form onSubmit={this.handleChangePassword}>
                    <FormGroup>
                      <FormLabel>Current Password</FormLabel>
                      <FormControl 
                        type="password" 
                        name="currentPassword"
                        value={currentPassword}
                        placeholder="Enter current password" 
                        onChange={this.handleChange}
                        disabled={requestInProgress}
                      /> 
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>New Password</FormLabel>
                      <FormControl 
                        type="password" 
                        name="newPassword" 
                        value={newPassword}
                        placeholder="Enter new password" 
                        onChange={this.handleChange}
                        disabled={requestInProgress}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Confirm New Password</FormLabel>
                      <FormControl 
                        type="password" 
                        name="newPasswordConfirm" 
                        value={newPasswordConfirm}
                        placeholder="Enter new password again" 
                        onChange={this.handleChange}
                        disabled={requestInProgress}
                      />
                    </FormGroup>
                    <hr />
                    <Button type="submit" variant="primary" disabled={requestInProgress} block>Change Password</Button>
                  </form>
                  <PasswordValidationFeedback passwordPolicy={passwordPolicy} password={newPassword} confirmedPassword={newPasswordConfirm} />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ChangePassword;