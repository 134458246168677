import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { resetSwitchActivities, fetchSwitchActivities } from "./ActivitiesPopup.actions";

import AdminPopupDismissableModal from 'components/adminPopupDismissableModal';
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { ColumnTypes } from 'commons/table/Constants';
import { useHistory } from 'react-router-dom';
import { FilterTypes } from 'commons/table/Constants';
import { AgentWorkspaceConstants } from 'lookup/AgentWorkspaceConstants';

const ActivitiesPopup = ({
  switchId = null,
  shouldShowActivityPopup = false,
  changeActivityPopupVisibility = () => { },
  isForSwimSwitchList = false
}) => {
  const dispatch = useDispatch();
  const listState = useSelector(state => state.switchActivities);
  const activitiesList = useSelector(state => state.switchActivities.data);
  const history = useHistory();

  React.useEffect(() => {
    if (switchId)
      dispatch(fetchSwitchActivities(switchId, isForSwimSwitchList, 15, 0, listState.filtered, listState.sorted));
  }, [switchId]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSwitchActivities());
    }
  }, []);

  const onClick = (row) => {
    history.push('/agent-workspace/call-group-details/' + row.original.callGroupDetailId);
  }

  const columns = [
    {
      header: "Switch #",
      accessor: "switchNumber",
      accessorKey: "switchNumber",
      width: 200,
      show: true,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Phone #",
      accessor: "phoneNumber",
      accessorKey: "phoneNumber",
      width: 100,
      show: true,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Name",
      accessor: "entityName",
      accessorKey: "entityName",
      width: 100,
      show: true,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Entity",
      accessor: "entityType",
      accessorKey: "entityType",
      width: 100,
      show: true,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Priority",
      accessor: "priority",
      accessorKey: "priority",
      width: 40,
      show: true,
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Type",
      accessor: "activityType",
      accessorKey: "activityType",
      width: 60,
      show: true,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Activity Status",
      accessor: "status",
      accessorKey: "status",
      width: 60,
      show: true,
      enableSorting: false,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.MultiSelect,
      filterOptions:AgentWorkspaceConstants.activityStatusMultiSelectOptions
    }
  ]

  const onTableUpdate = (filterValues, sortBy, pageSize, pageNo) => {
    const index = filterValues.findIndex(element => element.id === "status");
    
    // Create a shallow copy of filterValues to avoid direct mutation
    const updatedFilterValues = [...filterValues];
    
    if (index !== -1 && Array.isArray(updatedFilterValues[index].value)) {
        // Instead of directly modifying the original object, create a new object for the specific index
        updatedFilterValues[index] = {
            ...updatedFilterValues[index],
            value: updatedFilterValues[index].value.map(value => typeof value === 'string' ? value : value.id),
        };
    }
    dispatch(fetchSwitchActivities(switchId, isForSwimSwitchList, pageSize, pageNo, updatedFilterValues, sortBy));
}

  return (
    <AdminPopupDismissableModal
      hideFooter={true}
      title={"Link Switch Activities"}
      size={'xl'}
      handleModalVisiblityFromParent={true}
      isModalVisible={shouldShowActivityPopup}
      setModalVisibility={changeActivityPopupVisibility}
    >
      <ServerSideReactTable
        columnDefination={columns}
        data={activitiesList}
        currentPage={listState.page}
        filters={listState.filtered}
        totalPages={listState.totalPages}
        totalRecords={listState.totalElements}
        pageSizeOptions={[5, 10, 15]}
        onUpdateTableData={onTableUpdate}
        loading={listState.loading}
        defaultPageSize={listState.pageSize}
        onRowClick={onClick}
        sorted={listState.sorted}
      />
    </AdminPopupDismissableModal>
  )
}
export default ActivitiesPopup;
