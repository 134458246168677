
import { createSlice } from '@reduxjs/toolkit';
import { getMemberProgramsFailureAction, getMemberProgramsRequestAction, getMemberProgramsSuccessAction } from './CurrentPrograms.actions';

export const INITAL_STATE = {
  memberInfoId: null,
  isNotifyEnabled: false,
  hasMemberOptedOut: false,
  isRxDiscountEnabled: false,
  isLoading: true,
  error: null,
}


export const memberProgramsSlice = createSlice({
  name: "memberPrograms",
  initialState: INITAL_STATE,
  reducers: {
    getMemberProgramsSuccess: (state, action) => {
      state.memberInfoId = action.payload.memberInfoId;
      state.isNotifyEnabled = action.payload.isNotifyEnabled;
      state.hasMemberOptedOut = action.payload.hasMemberOptedOut;
      state.isRxDiscountEnabled = action.payload.isRxDiscountEnabled;
      state.isLoading = false;
      state.error = null;
    },
    getMemberProgramsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    getMemberProgramsRequest: (state, action) => {
      state.isLoading = true;
    },
    resetList: (state, action) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "member list" RSAA action.
      .addCase(getMemberProgramsRequestAction, (state, action) => {
        memberProgramsSlice.caseReducers.getMemberProgramsRequest(state, action);
      })
      .addCase(getMemberProgramsSuccessAction, (state, action) => {
        memberProgramsSlice.caseReducers.getMemberProgramsSuccess(state, action);
      })
      .addCase(getMemberProgramsFailureAction, (state, action) => {
        memberProgramsSlice.caseReducers.getMemberProgramsFailure(state, action);
      })

  }
});


export const { getMemberProgramsSuccess, getMemberProgramsFailure, getMemberProgramsRequest, resetList } = memberProgramsSlice.actions;
export const memberProgramsState = (state) => state.memberPrograms;
export default memberProgramsSlice.reducer;