import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUpdateDynamicSwitchGroupList, updatedDynamicSwitchGroupListState, resetError } from './UpdatedDynamicSwitchGroupList.action';
import { withRouter } from 'react-router-dom';
import { showNotification } from 'services/NotificationService';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tip from 'components/Tip';
import CodexVersionDisplayBar from 'components/CodexVersionDisplayBar';

import './UpdatedDynamicSwitchGroupDetails.css';
import { DELTA_CLASS_NAME, BooleanDeltaField, DescriptionDeltaField } from '../../../commons/fields/DeltaFieldUtils';
import DrugDeltaIcon from 'components/DynamicSwitchGroup/DrugDeltaIcon';
import ClientSideReactTable from 'commons/table/ClientSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import { WrapTextOnNoSpace } from 'components/WrapText';

const DEFAULT_PAGE_SIZE = 15;

class UpdatedDynamicSwitchGroupList extends React.Component {

  state = {
    loading: true,
    data: this.props.data,
    listState: this.props.listState,
  }

  updateData = (state, preventUpdate) => {
    const { codexVersionId } = this.props;
    if (!preventUpdate && codexVersionId) {
      const { pageSize, page, sorted, filtered } = state;
      this.props.getUpdateDynamicSwitchGroupList(pageSize, page, sorted, filtered, codexVersionId);
    } else if (!codexVersionId) {
      showNotification({
        title: 'Oops!',
        message: 'You haven\'t selected a Codex Version to work on.',
        position: 'bl',
        type: "success",
        autoDismiss: 4
      });
      this.props.history.push('/cat');
    }

    this.props.updatedDynamicSwitchGroupListState(state);
  }

  onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    listState.pageSize = pageSize;
    listState.page = pageNo;
    listState.sorted = sortBy ? sortBy : [];
    listState.filtered = filterValues;
    updateData(listState);
  }

  componentDidMount() {
    this.updateData(this.state.listState);
  }

  static getDerivedStateFromProps(props, state) {
    const { data, pages, listState, loading, isError } = props;
    if (isError === true && state.isError !== true) {
      showNotification({
        title: 'Error Loading Data!',
        message: 'You have entered invalid information.',
        position: 'bl',
        type: 'error'
      });
      props.resetError();
    }

    if (
      data !== state.data ||
      pages !== state.pages ||
      loading !== state.loading ||
      listState !== state.listState ||
      isError !== state.isError
    ) {
      return { data, pages, loading, listState, isError };
    }

    return null;
  }


  renderIcon = (id, isAdded, isDeleted) => {
    let className = isDeleted ? DELTA_CLASS_NAME.DELETED : isAdded ? DELTA_CLASS_NAME.NEW : DELTA_CLASS_NAME.MODIFIED;
    return <DrugDeltaIcon
      showDelta={true}
      className={className}
    />
  }

  renderCell = (current, predecessor, isUpdated) => {
    return <DescriptionDeltaField
      newField={current}
      oldField={predecessor}
      isUpdated={isUpdated}
    />
  }

  renderBooleanCell = (current, predecessor, isUpdated) => {
    return <BooleanDeltaField 
      newField={current}
      oldField={predecessor}
      isUpdated={isUpdated}
    />
  }

  filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (row[id] !== undefined ? String(row[id] ? row[id].toLowerCase() : "").includes(filter.value.toLowerCase()) : true);
  }

  allColumnsSortable = true;
  allColumnsFilterable = true;

  getBgColor = (rowInfo) => {
    return rowInfo.original["currentDynamicSwitchGroup.isDeleted"] ? '#FEBDB1' : !rowInfo.original["oldDynamicSwitchGroup"] ? '#CDFFCD' : ''
  }

  getData = (row, key1, key2) => {
    return row.original[key1] ? row.original[key1][key2] ? row.original[key1][key2] : null : null
  }

  render() {

    const { data, loading, listState } = this.state;
    const totalElements = data?.length;

    const defaultSorted = [{
      id: "oldDynamicSwitchGroup.dynamicSwitchGroupName",
      desc: false
    }];

    const columns = [
      {
        header: "",
        accessor: "currentDynamicSwitchGroup.isDeleted",
        accessorKey: "currentDynamicSwitchGroup.isDeleted",
        width: 50,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        header: "",
        accessor: "oldDynamicSwitchGroup.dynamicSwitchGroupName",
        accessorKey: "oldDynamicSwitchGroup.dynamicSwitchGroupName",
        width: 50,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        header: "",
        accessor: "oldDynamicSwitchGroup.isActive",
        accessorKey: "oldDynamicSwitchGroup.isActive",
        width: 50,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        header: "",
        accessor: "oldDynamicSwitchGroup.projectedSuccessRate",
        accessorKey: "oldDynamicSwitchGroup.projectedSuccessRate",
        width: 50,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        header: "",
        accessor: "oldDynamicSwitchGroup.pharmacistNotes",
        accessorKey: "oldDynamicSwitchGroup.pharmacistNotes",
        width: 50,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        header: "",
        accessor: "currentDynamicSwitchGroup",
        accessorKey: "currentDynamicSwitchGroup",
        width: 50,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        header: "",
        accessor: "oldDynamicSwitchGroup",
        accessorKey: "oldDynamicSwitchGroup",
        width: 50,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        header: "",
        accessor: "currentDynamicSwitchGroup.dynamicSwitchGroupId",
        accessorKey: "type",
        width: 40,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return this.renderIcon(row.original, !row.original["oldDynamicSwitchGroup"], this.getData(row, "currentDynamicSwitchGroup", "isDeleted"))
        },
      },
      {
        header: "Switch Group Name",
        accessor: "currentDynamicSwitchGroup.dynamicSwitchGroupName",
        accessorKey: "currentDynamicSwitchGroup.dynamicSwitchGroupName",
        width: 400,
        enableSorting: this.allColumnsFilterable,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.SearchableInput,
        customComponent: (value, row) => {
          return this.renderCell(this.getData(row, "currentDynamicSwitchGroup", "dynamicSwitchGroupName"), this.getData(row, "oldDynamicSwitchGroup", "dynamicSwitchGroupName"), !this.getData(row, "currentDynamicSwitchGroup", "isDeleted") && row.original["oldDynamicSwitchGroup"])
        },
      },
      {
        header: "Class Name",
        accessor: "currentDynamicSwitchGroup.dynamicSwitchGroupClassName",
        accessorKey: "currentDynamicSwitchGroup.dynamicSwitchGroupClassName",
        width: 200,
        show: false,
        enableSorting: this.allColumnsFilterable,
        enableColumnFilter: this.allColumnsSortable,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.SearchableInput,
        customComponent: (value, row) => {
          return this.renderCell(this.getData(row, "currentDynamicSwitchGroup", "dynamicSwitchGroupClassName"), this.getData(row, "oldDynamicSwitchGroup", "dynamicSwitchGroupClassName"), !this.getData(row, "currentDynamicSwitchGroup", "isDeleted") && row.original["oldDynamicSwitchGroup"])
        },
      },
      {
        header: "Active",
        accessor: "currentDynamicSwitchGroup.isActive",
        accessorKey: "currentDynamicSwitchGroup.isActive",
        width: 100,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return this.renderBooleanCell(this.getData(row, "currentDynamicSwitchGroup", "isActive"), this.getData(row, "oldDynamicSwitchGroup", "isActive"), !this.getData(row, "currentDynamicSwitchGroup", "isDeleted") && row.original["oldDynamicSwitchGroup"])
        },
      },
      {
        header: "Projected Success Rate",
        accessor: "currentDynamicSwitchGroup.projectedSuccessRate",
        accessorKey: "currentDynamicSwitchGroup.projectedSuccessRate",
        width: 200,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return this.renderCell(this.getData(row, "currentDynamicSwitchGroup", "projectedSuccessRate"), this.getData(row, "oldDynamicSwitchGroup", "projectedSuccessRate"), !this.getData(row, "currentDynamicSwitchGroup", "isDeleted") && row.original["oldDynamicSwitchGroup"])
        },
      },
      {
        header: "Pharmacist Notes",
        accessor: "currentDynamicSwitchGroup.pharmacistNotes",
        accessorKey: "currentDynamicSwitchGroup.pharmacistNotes",
        width: 200,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return this.renderCell(this.getData(row, "currentDynamicSwitchGroup", "pharmacistNotes"), this.getData(row, "oldDynamicSwitchGroup", "pharmacistNotes"), !this.getData(row, "currentDynamicSwitchGroup", "isDeleted") && row.original["oldDynamicSwitchGroup"])
        },
      },
    ]

    return (
      <Container className="list-container" fluid>
        <CodexVersionDisplayBar />
        <Row className="tiny-bottom-spacer">
          <Col md={6}>
            Total Items: <strong id='sllist-total-count'>{
              totalElements !== undefined && !loading ? totalElements : "Loading ..."}</strong>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ClientSideReactTable
              columnDefination={columns}
              data={data}
              currentPage={listState.page}
              sorted={listState.sorted}
              filters={listState.filtered}
              totalRecords={totalElements=="loading"?0:totalElements}
              loading={loading}
              enableMultiRowSelection={false}
              addRowSelection={false}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              pageSizeOptions={[5, 10, 15, 20, 30, 50]}
              onUpdateTableData={this.onUpdateTable}
              overrideRowId={'currentDynamicSwitchGroup.dynamicSwitchGroupId'}
              onRowClick={(rowInfo) => {
                if (rowInfo !== undefined) {// change withe the call to me made to update traget list
                  this.props.history.push(`/cat/codex-version/${this.props.codexVersionId}/dynamic-switch-groups/${rowInfo.original.currentDynamicSwitchGroup.dynamicSwitchGroupId}`);
                }
              }}
              trStyle={(rowInfo) => {
                if (rowInfo) {
                  return (
                    {
                      background: this.getBgColor(rowInfo),
                    }
                  )
                }
                return {};
              }}
            />
            {/* <ReactTable
              defaultSorted={defaultSorted}
              defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
            /> */}
            <Tip>
              Tip: Hold shift when sorting to multi-sort and disable sort!
            </Tip>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.deltaDynamicSwitchGroupList.data !== undefined ? state.deltaDynamicSwitchGroupList.data : [],
    loading: state.deltaDynamicSwitchGroupList.loading,
    errorFetchingList: state.deltaDynamicSwitchGroupList.errorFetchingList,
    listState:
      state.deltaDynamicSwitchGroupList.listState !== undefined ?
        state.deltaDynamicSwitchGroupList.listState
        : {
          page: 0,
          pageSize: DEFAULT_PAGE_SIZE,
          sorted: state.sorted,
          filtered: [],
          resized: [],
          expanded: {}
        },
    codexVersionId: state.codexVersion ? state.codexVersion.codexVersionId : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUpdateDynamicSwitchGroupList: bindActionCreators(getUpdateDynamicSwitchGroupList, dispatch),
    updatedDynamicSwitchGroupListState: bindActionCreators(updatedDynamicSwitchGroupListState, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdatedDynamicSwitchGroupList));