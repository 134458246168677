
export function Reducer(previousState, action) {
    let newState = Object.assign({}, previousState);
  
    switch (action.type) {
      case 'FETCH_ALL_DRUG_LIST_SUCCESS':
        newState = Object.assign({}, previousState, {
          data: action.payload.records,
          pages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
          loading: false,
          isError: false
        });
        newState.listState.page = action.payload.page;
        break;
      case 'FETCH_ALL_DRUG_LIST':
        newState = Object.assign({}, previousState, {
          listState: action.payload.listState,
          totalElements: "Loading",
          loading: true,
          isError: false
        });
        break;
      case 'FETCH_ALL_DRUG_LIST_FAILURE':
        newState = Object.assign({}, previousState, {
          data: [],
          loading: false,
          isError: true,
          totalElements: 0,
          pages: 0,
        });
        break;
      case 'FETCH_ALL_DRUG_LIST_INDEX':
        newState = Object.assign({}, previousState, {
          indexOnList: action.payload.index
        });
        break;
  
      case 'RESET_ERROR_ALL_DRUG_LIST':
        newState = Object.assign({}, previousState, {
          isError: false
        });
        break;
      case 'RESET_ALL_DRUG_LIST':
        newState = Object.assign({}, previousState, {
          data: [],
          loading: false,
          isError: false,
          totalElements: 0,
          pages: 0,
        });
        break;
      default:
        break;
    }
  
    return newState;
  }