
export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case 'GET_SWITCH_DETAILS_SUCCESS':
      newState = Object.assign({}, previousState, { switchData: action.payload, loading: false });
      break;
    case 'GET_SWITCH_DETAILS_REQUEST':
      newState = Object.assign({}, previousState, { switchData: null, loading: true });
      break;
    case 'GET_SWITCH_DETAILS_FAILURE':
      newState = Object.assign({}, previousState, { switchData: null, loading: false });
      break;
    case 'GET_SWITCH_TIMELINE_SUCCESS':
      newState = Object.assign({}, previousState, { switchTimeline: action.payload, timelineLoading: false });
      break;
    case 'GET_SWITCH_TIMELINE_REQUEST':
      newState = Object.assign({}, previousState, { switchTimeline: null, timelineLoading: true });
      break;
    case 'GET_SWITCH_TIMELINE_FAILURE':
      newState = Object.assign({}, previousState, { switchTimeline: null, timelineLoading: false });
      break;
    case 'PERFORM_EVENT_REQUEST':
      newState = Object.assign({}, previousState, { eventExecutorStatus: 'requested', eventInExecution: action.payload.event });
      break;
    case 'PERFORM_EVENT_SUCCESS':
      newState = Object.assign({}, previousState, { eventExecutorStatus: 'success', eventInExecution: action.payload.event });
      break;
    case 'PERFORM_EVENT_FAILURE':
      newState = Object.assign({}, previousState, { eventExecutorStatus: 'failure', eventInExecution: action.payload.event });
      break;
    case 'EVENT_EXECUTOR_STATUS_RESET':
      newState = Object.assign({}, previousState, { eventExecutorStatus: 'idle', eventInExecution: null });
      break;
    default:
      break;
  }

  return newState;
}