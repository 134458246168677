import Form from "react-bootstrap/Form";
import React from "react";
import { BooleanDeltaField } from "./DeltaFieldUtils";
import "./fields.css";

const CheckField = ({
  title = "",
  label = "",
  isEditMode = false,
  id = "",
  compKey = "",
  value = false,
  handleChange = () => {},
  handleBlur = () => {},
  isFieldRequired = () => {},
  defaultValue = undefined,
  oldValue = undefined,
  isDeltaUpdated = false,
  deltaHeading = undefined,
  showDelta = false,
  checkClassName = "",
}) => {
  if (showDelta) {
    if (isDeltaUpdated)
      return (
        <BooleanDeltaField
          oldField={oldValue}
          isUpdated={isDeltaUpdated}
          newField={value}
          heading={deltaHeading}
        />
      );
  } else {
    return (
      <Form.Group className={"check-field"} key={`group-${compKey}`}>
        <div
          className={
            "rm-small-top-label mb-2 " +
            (isFieldRequired(id) ? "rm-required-field" : "")
          }
        >
          {title !== "Active" ? title : undefined}
        </div>
        <Form.Check
          aria-label={title || undefined}
          inline
          disabled={!isEditMode}
          label={label || undefined}
          onBlur={handleBlur}
          onChange={handleChange}
          checked={value}
          name={id}
          id={compKey}
          key={compKey}
          defaultChecked={defaultValue}
          className={checkClassName}
        />
      </Form.Group>
    );
  }
  return null;
};

export default React.memo(CheckField);
