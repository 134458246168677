import React from 'react';
import './TagBox.css';
import { MdCancel } from "react-icons/md";
import { Button } from 'react-bootstrap';

const Tag = ({ tagName, removeSwitch }) => {
    const remove = (e) => {
        e.stopPropagation();
        removeSwitch(tagName);
    }
    return <Button
        color="secondary"
        className="single-tag-div d-flex justify-content-center align-items-center"
        onClick={remove}
    >
        <p className="single-tag-text">
            {tagName}
        </p>
        <MdCancel className="link-icon" />
    </Button>
}
export default Tag;