export const TranscriptionConstants = {
  DEFAULT_TRANSCRIBED_FAX_LIST_SORTING: [
    { id: "generatedDate", desc: true, isDefault: true },
  ],
  DEFAULT_TRANSCRIBED_FAX_LIST_FILTER: [],
  OVERRIDABLE_STATES: [
    "ACCEPTED_RESPONSE_FOLLOWUP",
    "DECLINED_RESPONSE_FOLLOWUP",
    "ERROR_RESPONSE_FOLLOWUP",
  ],
  classifications: [
    { value: "Denied, no reason", label: "D-" },
    { value: "not an appropriate switch", label: "DA-" },
    { value: "Therapy has been discontinued", label: "DC-" },
    { value: "Denied does not like our suggestion", label: "DS-" },
    { value: "Will discuss at follow up", label: "FU-" },
    { value: "Patient failed suggested switch", label: "H-" },
    { value: "Patient not on source medication", label: "NA-" },
    { value: "Not a patient of provider", label: "ND-" },
    { value: "Other", label: "O-" },
    { value: "Needs Office visit", label: "OV-" },
    { value: "Contact Patient to approve change", label: "PC-" },
    { value: "Patient Refused", label: "PR-" },
  ],
  classificationSections: [
  ],
  TRANSCRIBER_FIELDS: { text: "text", list: "list", check: "check" },
  TRANSCRIBER_OBJECT_KEYS: {selected: "selected", pages: "pages", pageNo : "pageNo", 
                            sections: "sections", sectionName: "sectionName",
                            fields: "fields", fieldName: "fieldName"},
};
