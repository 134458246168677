
export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case 'UPDATE_CYCLE_LIST_SUCCESS':
      newState = Object.assign({}, previousState, {
        cycleListData: action.payload.records,
        cycleListPages: action.payload.totalPages,
        totalCycleElements: action.payload.totalElements,
        isCycleListLoading: false,
        isError: false
      });
      newState.cycleListState.page = action.payload.page;
      break;
    case 'UPDATE_CYCLE_LIST_REQUEST':
      newState = Object.assign({}, previousState, {
        cycleListState: action.payload.cycleListState,
        isCycleListLoading: true,
        selectedCycleId: -1,
      });
      break;
    case 'UPDATE_CYCLE_LIST_FAILURE':
      newState = Object.assign({}, previousState, {
        data: undefined,
        pages: 0,
        totalElements: 0,
        isCycleListLoading: false,
        isError: true
      });
      break;
    case 'UPDATE_SWITCHES_FOR_CYCLE_LIST_REQUEST':
      newState = Object.assign({}, previousState, {
        isSwitchListLoading: true,
        selectedCycleId: action.payload.selectedCycleId
      });
      break;
    case 'UPDATE_SWITCHES_FOR_CYCLE_LIST_SUCCESS':
      newState = Object.assign({}, previousState, {
        switchListData: action.payload.records,
        switchListPages: action.payload.totalPages,
        totalSwitchElements: action.payload.totalElements,
        isSwitchListLoading: false,
        isError: false
      });
      break;
    case 'UPDATE_SWITCHES_FOR_CYCLE_LIST_FAILURE':
      newState = Object.assign({}, previousState, {
        isSwitchListLoading: false,
        isError: true
      });
      break;
    case 'UPDATE_CYCLE_LIST_STATE':
      newState = Object.assign({}, previousState, {
        cycleListState: action.payload.cycleListState
      });
      break;
    case 'UPDATE_SWITCH_LIST_STATE':
      newState = Object.assign({}, previousState, {
        switchListState: action.payload.switchListState
      });
      break;
    case 'RESET_ERROR_MESSAGE_CYCLE_LIST':
      newState = Object.assign({}, previousState, {
        isError: false
      });
      break;
    default:
      break;
  }

  return newState;
}