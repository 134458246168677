import React, { useState, useRef } from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { showNotification } from "services/NotificationService";
import "assets/css/user.css";

const FavoriteDashboard = (props) => {
  const [dashboardId, setDashboardId] = useState(props.dashboardId);

  const favoriteDashboard = () => {
    sendAuthenticatedAsyncRequest(
      `/analytics/favorite-dashboard/${dashboardId}`,
      "POST",
      null,
      (r) => {
        showNotification({
          title: "Action successful!",
          message: "Favorite dashboards updated",
          position: "tc",
          type: "success",
        });
        props.handler();
      },
      (r) => {
        showNotification({
          title: "Error!",
          message: "Something went wrong when updating favorite dashboards",
          position: "tc",
          type: "error",
        });
      }
    );
  };

  return (
    <OverlayTrigger overlay={<Tooltip>Favorite Dashboard</Tooltip>}>
      {props.isUserFavorite ? (
        <BsStarFill
          className="user-action-button disable-user"
          size={20}
          onClick={() => {
            favoriteDashboard();
          }}
          style={{ color: "#FFAA3B" }}
        ></BsStarFill>
      ) : (
        <BsStar
          className="user-action-button disable-user"
          size={20}
          onClick={() => {
            favoriteDashboard();
          }}
          style={{ color: "#FFAA3B" }}
        ></BsStar>
      )}
    </OverlayTrigger>
  );
};

export default FavoriteDashboard;
