import { ColumnTypes, FilterTypes } from "commons/table/Constants";
import { WrapTextAndCenterAlign } from "components/WrapText";
import { DateConstants } from "lookup/DateConstants";
import {
  SWIM_STATES_FOR_SELECT,
  PICKUP_OPTIONS_FOR_SELECT,
  SWIM_TASK_INDICATOR_FILTER,
  SWIM_STATUSES_FOR_CYCLE_SELECT,
  SWIM_STATES_FOR_CYCLE_SELECT,
} from "lookup/SwimConstants";
import { bulk_action_item_status } from "lookup/BulkActionListConstants";
import { Badge } from "react-bootstrap";
import { formatDateForDisplay } from "services/MomentUtils";
import { STATUSES_FOR_BULK_ACTION_STATE_SELECT } from "lookup/BulkActionListConstants";

export const COLUMN = {
  customerNameAbbreviation: "customerNameAbbreviation",
  clientNameAbbreviation: "clientNameAbbreviation",
  switchNumber: "switchNumber",
  state: "state",
  status: "status",
  cycleState: "cycleState",
  cycleStatus: "cycleStatus",
  prescriberName: "prescriberName",
  prescriberFax: "prescriberFax",
  prescriberPhone: "prescriberPhone",
  prescriberNPI: "prescriberNPI",
  prescriberCallCounter: "prescriberCallCounter",
  daysAfterLastModifiedDate: "daysAfterLastModifiedDate",
  lastModifiedDate: "lastModifiedDate",
  pharmacyName: "pharmacyName",
  pharmacyPhone: "pharmacyPhone",
  pharmacyFax: "pharmacyFax",
  pharmacyNPI: "pharmacyNPI",
  pharmacyCallCounter: "pharmacyCallCounter",
  isPickupConfirmed: "isPickupConfirmed",
  memberNumberWithoutCustId: "memberNumberWithoutCustId",
  memberNumber: "memberNumber",
  memberCallCounter: 'memberCallCounter',
  effectiveDate: "effectiveDate",
  projectedSavings: "projectedSavings",
  hasOpenTaskActivity: "hasOpenTaskActivity",
  generatedDate: "generatedDate",
  hasPrescriberInReview: "hasPrescriberInReview",
  bulkActionItemStatus: "bulkActionItemStatus",
  bulkActionStatus: "bulkActionStatus",
};

const cycleStatesFilterMap = [];
SWIM_STATES_FOR_CYCLE_SELECT.forEach((s, i) =>
  cycleStatesFilterMap.push({ id: s.key, name: s.value })
);
const cycleStatusFilterMap = [];
SWIM_STATUSES_FOR_CYCLE_SELECT.forEach((s, i) =>
  cycleStatusFilterMap.push({ id: s.key, name: s.value })
);
const taskFilterMap = [];
SWIM_TASK_INDICATOR_FILTER.forEach((s, i) =>
  taskFilterMap.push({ key: s.value, value: s.key })
);
const statesFilterMap = [];
SWIM_STATES_FOR_SELECT.forEach((state, i) =>
  statesFilterMap.push({ id: state.key, name: state.value })
);
const bulkActionStatusFilterMap = [];
STATUSES_FOR_BULK_ACTION_STATE_SELECT.forEach((s, i) =>
  bulkActionStatusFilterMap.push({ id: s.key, name: s.value })
);


export const createColumnListForSwitchList = (
  columns = [],
  enableSortFilterForCust = true
) => {
  let columnList = [];
  for (const element of columns) {
    switch (element) {
      case COLUMN.customerNameAbbreviation:
        columnList.push({
          header: "Customer",
          accessor: "customerNameAbbreviation",
          accessorKey: "customerNameAbbreviation",
          enableSorting: enableSortFilterForCust,
          enableColumnFilter: enableSortFilterForCust,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;
      case COLUMN.clientNameAbbreviation:
        columnList.push({
          header: "Client",
          accessor: "clientNameAbbreviation",
          accessorKey: "clientNameAbbreviation",
          enableSorting: true,
          enableColumnFilter: enableSortFilterForCust,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;
      case COLUMN.switchNumber:
        columnList.push({
          header: "Switch Id",
          accessor: "switchNumber",
          accessorKey: "switchNumber",
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;
      case COLUMN.state:
        columnList.push({
          header: "Switch State",
          accessor: "state",
          accessorKey: "state",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.MultiSelect,
          filterOptions: statesFilterMap,
        });
        break;
      case COLUMN.cycleState:
        columnList.push({
          header: "Switch State",
          accessor: "state",
          accessorKey: "state",
          width: 200,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.MultiSelect,
          filterOptions: cycleStatesFilterMap,
        });
        break;
      case COLUMN.status:
        columnList.push({
          header: "Status",
          accessor: "status",
          accessorKey: "status",
          width: 250,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;
      case COLUMN.cycleStatus:
        columnList.push({
          header: "Status",
          accessor: "status",
          accessorKey: "status",
          width: 250,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.WrapTextOnNoSpace,
          filterType: FilterTypes.MultiSelect,
          filterOptions: cycleStatusFilterMap,
        });
        break;
      case COLUMN.bulkActionStatus:
        columnList.push({
          header: "Status",
          accessor: "status",
          accessorKey: "status",
          width: 250,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.WrapTextOnNoSpace,
          filterType: FilterTypes.MultiSelect,
          filterOptions: bulkActionStatusFilterMap,
        });
        break;
      case COLUMN.prescriberName:
        columnList.push({
          header: "Prescriber",
          accessor: "prescriberName",
          accessorKey: "prescriberName",
          width: 245,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;
      case COLUMN.prescriberCallCounter:
        columnList.push({
          header: "Prescriber Calls",
          accessor: "prescriberCallCounter",
          accessorKey: "prescriberCallCounter",
          width: 140,
          enableSorting: true,
          enableColumnFilter: false,
          columnType: ColumnTypes.Text,
        });
        break;
      case COLUMN.daysAfterLastModifiedDate:
        columnList.push({
          header: "Days After Last Modified Date",
          accessor: "daysAfterLastModifiedDate",
          accessorKey: "daysAfterLastModifiedDate",
          width: 140,
          show: false,
          enableSorting: false,
          enableColumnFilter: false,
          columnType: ColumnTypes.Text,
        });
        break;
      case COLUMN.lastModifiedDate:
        columnList.push({
          header: "Last Modified",
          accessor: "lastModifiedDate",
          accessorKey: "lastModifiedDate",
          width: 110,
          enableSorting: true,
          enableColumnFilter: false,
          columnType: ColumnTypes.Custom,
          filterType: FilterTypes.SearchableInput,
          customComponent: (value, row) => {
            return renderLastModifiedDateCol(
              row.original.lastModifiedDate,
              row.original.daysAfterLastModifiedDate
            );
          },
        });
        break;
      case COLUMN.pharmacyName:
        columnList.push({
          header: "Pharmacy",
          accessor: "pharmacyName",
          accessorKey: "pharmacyName",
          width: 140,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;
      case COLUMN.pharmacyCallCounter:
        columnList.push({
          header: "Pharmacy Calls",
          accessor: "pharmacyCallCounter",
          accessorKey: "pharmacyCallCounter",
          width: 140,
          enableSorting: true,
          enableColumnFilter: false,
          columnType: ColumnTypes.Text,
        });
        break;
      case COLUMN.isPickupConfirmed:
        columnList.push({
          header: "Pickup Confirmed",
          accessor: "isPickupConfirmed",
          accessorKey: "isPickupConfirmed",
          width: 100,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Custom,
          customComponent: (value) => {
            return value ? "Yes" : "No";
          },
          filterType: FilterTypes.SingleSelect,
          filterOptions: PICKUP_OPTIONS_FOR_SELECT,
          filterReturnKey: true,
        });
        break;
      case COLUMN.memberNumberWithoutCustId:
        columnList.push({
          header: "Member #",
          accessor: "memberNumberWithoutCustId",
          accessorKey: "memberNumberWithoutCustId",
          width: 110,
          enableSorting: false,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
        });
        break;
      case COLUMN.memberNumber:
        columnList.push({
          header: "Member #",
          accessor: "memberNumber",
          accessorKey: "memberNumber",
          width: 90,
          enableSorting: false,
          enableColumnFilter: false,
          columnType: ColumnTypes.Text,
          show: false,
        });
        break;
      case COLUMN.memberCallCounter:
        columnList.push({
          header: "Member Calls",
          accessor: "memberCallCounter",
          accessorKey: "memberCallCounter",
          width: 140,
          enableSorting: true,
          enableColumnFilter: false,
          columnType: ColumnTypes.Text,
        });
        break;
      case COLUMN.effectiveDate:
        columnList.push({
          header: "Effective Date",
          accessor: "effectiveDate",
          accessorKey: "effectiveDate",
          width: 110,
          enableSorting: true,
          columnType: ColumnTypes.Custom,
          enableColumnFilter: false,
          customComponent: (value, row) => {
            return renderEffectiveDateCol(
              value,
              row.original.daysToEffectiveDate
            );
          },
        });
        break;
      case COLUMN.projectedSavings:
        columnList.push({
          header: "Projected Savings",
          accessor: "projectedSavings",
          accessorKey: "projectedSavings",
          width: 90,
          enableSorting: true,
          enableColumnFilter: false,
          columnType: ColumnTypes.Money,
        });
        break;
      case COLUMN.hasOpenTaskActivity:
        columnList.push({
          header: "Open Task",
          accessor: "hasOpenTaskActivity",
          accessorKey: "hasOpenTaskActivity",
          width: 150,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Custom,
          customComponent: (value) => {
            return (
              <WrapTextAndCenterAlign>
                {value ? (
                  <Badge bg="danger">True</Badge>
                ) : (
                  <Badge bg="success">False</Badge>
                )}
              </WrapTextAndCenterAlign>
            );
          },
          filterType: FilterTypes.SingleSelect,
          filterOptions: taskFilterMap,
        });
        break;
      case COLUMN.hasPrescriberInReview:
        columnList.push({
          header: "In Review",
          accessor: "hasPrescriberInReview",
          accessorKey: "hasPrescriberInReview",
          width: 150,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Custom,
          customComponent: (value) => {
            return (
              <WrapTextAndCenterAlign>
                {value ? (
                  <Badge bg="danger">True</Badge>
                ) : (
                  <Badge bg="success">False</Badge>
                )}
              </WrapTextAndCenterAlign>
            );
          },
          filterType: FilterTypes.SingleSelect,
          filterOptions: taskFilterMap,
        });
        break;
      case COLUMN.generatedDate:
        columnList.push({
          header: "Created On",
          accessor: "generatedDate",
          accessorKey: "generatedDate",
          width: 190,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.DateColumn,
          filterType: FilterTypes.DateRangePicker,
        });
        break;
      case COLUMN.pharmacyFax:
        columnList.push({
          header: "Pharmacy Fax",
          accessor: "pharmacyFax",
          accessorKey: "pharmacyFax",
          width: 90,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
          show: true,
        });
        break;
      case COLUMN.pharmacyPhone:
        columnList.push({
          header: "Pharmacy #",
          accessor: "pharmacyPhone",
          accessorKey: "pharmacyPhone",
          width: 90,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
          show: true,
        });
        break;
      case COLUMN.pharmacyNPI:
        columnList.push({
          header: "Pharmacy NPI",
          accessor: "pharmacyNpi",
          accessorKey: "pharmacyNpi",
          width: 90,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
          show: true,
        });
        break;
      case COLUMN.prescriberNPI:
        columnList.push({
          header: "Prescriber NPI",
          accessor: "prescriberNpi",
          accessorKey: "prescriberNpi",
          width: 90,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
          show: true,
        });
        break;
      case COLUMN.prescriberFax:
        columnList.push({
          header: "Prescriber Fax",
          accessor: "prescriberFax",
          accessorKey: "prescriberFax",
          width: 90,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
          show: true,
        });
        break;
      case COLUMN.prescriberPhone:
        columnList.push({
          header: "Prescriber Phone",
          accessor: "prescriberPhone",
          accessorKey: "prescriberPhone",
          width: 90,
          enableSorting: true,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.SearchableInput,
          show: true,
        });
        break;
      case COLUMN.bulkActionItemStatus:
        columnList.push({
          header: "Bulk Action Item Status",
          accessor: "bulkActionItemStatus",
          accessorKey: "bulkActionItemStatus",
          width: 90,
          enableSorting: false,
          enableColumnFilter: true,
          columnType: ColumnTypes.Text,
          filterType: FilterTypes.MultiSelect,
          filterOptions: bulk_action_item_status,
        });
    }
  }
  return columnList;
};

const renderDateCell = (date) => {
  return (
    <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {date}
    </span>
  );
};

const renderEffectiveDateCol = (date, days) => {
  if (date) {
    const status = days >= 0 ? "ComingDue" : "PastDue";
    return renderEffectiveDateCell(formatDateForDisplay(date), status);
  }
};

const renderEffectiveDateCell = (date, status) => {
  return (
    <span
      style={{ width: "100%", display: "inline-block", textAlign: "center" }}
    >
      <span style={{ color: DateConstants.statusColors[status] }}>
        &#x25cf; &nbsp;
      </span>
      {date}
    </span>
  );
};

const renderLastModifiedDateCol = (date, days) => {
  if (date) {
    return renderDateCell(formatDateForDisplay(date));
  }
  return <span className="center">N/A</span>;
};
