import React from 'react';

export const WrapTextOnNoSpace = (props) => {

  return (
   <span className="break-no-space-text">{props.children}</span>
  );
}

export const WrapTextAndCenterAlign = (props) => {
  return (
   <span className="break-no-space-text center">{props.children}</span>
  );
}

export const WrapTextAndLimitCharacters = (props) => {
  return (
    <span id={props.id} className= {`limit-text ${props.classes}`} title={props.title}>{props.children}</span>
  );
}

export const WrapTextAndJustifyCenter = (props) => {
  return (
   <span className="table-column-text-center">{props.children}</span>
  );
}