import Container from "react-bootstrap/Container";
import DismissableModal from "components/DismissableModal";
import "assets/css/agentworkspace.css";
import { useRef } from "react";
import CloseActivity from "./CloseActivity";

const CloseActivityModal = (props) => {
    const modalRef = useRef("closeActivity");
    const isActionCallable = props.isActionCallable || false;
    const title = "Closing Activity for " + props.globalSwitchId;

    return (
        <DismissableModal
            ref={modalRef}
            openModalButtonSize="md"
            openModalButtonClassName="btn-block btn-text-white"
            openModalButtonStyle="success"
            openModalButtonDisabled={!isActionCallable}
            openModalButtonText="Close Activity"
            title={title}
            size="lg"
            preventCloseWhenClickedOutsideModal={true}
            hideFooter={true}
            openModalButtonTestId="btn-close-activity"
        >
            <Container>
                <CloseActivity
                    modalRef={modalRef}
                    data={props.data} // passing all ids in call group
                    callGroupId={props.callGroupId}
                    activityState={props.activityState}
                    activityStatus={props.activityStatus}
                    activityName={props.activityName}
                    activityType={props.activityType}
                    entityType={props.entityType}
                    globalSwitchId={props.globalSwitchId}
                    isMemberActivityCreatable={props.isMemberActivityCreatable}
                    prescriberName={props.prescriberName}
                    pharmacyName={props.pharmacyName}
                    activityVersion={props.activityVersion}
                    isActionCallable={props.isActionCallable}
                    activityId={props.activityId}
                    callCounter={props.callCounter}
                    resetActivityUponClose={props.resetActivityUponClose}
                    refreshActivityList={props.refreshActivityList}
                    refreshActivity={props.refreshActivity}
                    goBackIfCallGroupClosed={props.goBackIfCallGroupClosed}
                    goBackIfNoActionTakenOrGroupClose={props.goBackIfNoActionTakenOrGroupClose}
                />
            </Container>
        </DismissableModal>
    );
};

export default CloseActivityModal;