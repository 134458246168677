import React from "react";
import Badge from "react-bootstrap/Badge";
import { Button, Col, Row } from "react-bootstrap";
import { formatDateWithTime } from "services/MomentUtils";
import AddEditNoteModal from "./AddEditNoteModal";
import DOMPurify from "dompurify";
import { BiEdit } from "react-icons/bi";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { BsPinAngle, BsPinAngleFill } from "react-icons/bs";
import "plugins/Lexical/styles.css";
import { getLoggedInUser } from "services/AuthService";
import { isLoggedInUserAWAdmin } from "services/AWUtilityService";
import { showNotification } from "services/NotificationService";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import {
  NotesConstants,
  NotesFilterConstants,
} from "lookup/NotesFilterConstants";

const NoteItem = (props) => {
  const { note, clipped, pinnedNotesCount, noteEditStatus } = props;
  const pin = () => {
    if (
      !note.isPinned &&
      pinnedNotesCount === AgentWorkspaceConstants.PinnedNotesLimit
    ) {
      showNotification({
        title: "Unable to pin note!",
        message: `A maximum of ${AgentWorkspaceConstants.PinnedNotesLimit} notes can be pinned. Unpin a note to save a new pin`,
        position: "tr",
        type: "error",
      });
    } else {
      sendAuthenticatedAsyncRequest(
        `${props.entitySaveUrl}/${props.entityId}/pin-note`,
        "POST",

        { ...note, isPinned: !note.isPinned },

        (r) => {
          let isPinned = false;
          if (note.noteId === r.data.noteDtoList[0]?.noteId) {
            isPinned = true;
          }
          props.pinNote(r.data, isPinned);
          showNotification({
            title: isPinned ? "Note pinned!" : "Note unpinned!",
            message: isPinned
              ? "The new note can be viewed at the top of the Notes box."
              : "It's still there, just not at the top.",
            position: "tr",
            type: "success",
          });
        },
        (r) => {
          showNotification({
            title: "Unable to pin note!",
            message: r.data.errorCode,
            position: "tr",
            type: "error",
          });
        }
      );
    }
  };

  const sanitizer = DOMPurify.sanitize;
  const editUrl = props.entitySaveUrl
    ? props.entitySaveUrl + "/edit-note"
    : null;
  return (
    <div className="rm-switch-note-container">
      <Row>
        <Col md={8}>
          <div className="rm-switch-note-author">
            {note.creator &&
            !note.note.includes(
              AgentWorkspaceConstants.CALL_PRIORTIZATION_SYSTEM
            ) &&
            NotesFilterConstants.SYSTEM != note.type
              ? note.creator
              : NotesFilterConstants.SYSTEM}
          </div>
          {note.activityEntityType ? (
            <div className="rm-switch-note-author">
              {note.activityEntityType ? note.activityEntityType + " - " : null}
              {note.activityType ? note.activityType : null}
            </div>
          ) : null}
          <Row
            style={{
              fontSize: "0.8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                {formatDateWithTime(note.creationDate)}
                {note.callTemp && (
                  <span
                    style={{
                      color: AgentWorkspaceConstants.callGroupTemperature.find(
                        (temp) => temp.value === note.callTemp
                      )?.color,
                      fontSize: "1.2rem",
                      marginLeft: "2px",
                      width: "1.2rem",
                      height: "1.2rem",
                    }}
                  >
                    &#x25cf; &nbsp;
                  </span>
                )}
              </span>
            </div>
          </Row>
          {note.isEdited ? <Badge variant="success">Edited</Badge> : null}
          {note.new ? <Badge variant="success">New</Badge> : null}
        </Col>
        <Col md={4}>
          {props.updateNote ? (
            isLoggedInUserAWAdmin() ||
            (noteEditStatus &&
              (noteEditStatus === "OPEN" ||
                noteEditStatus === "NEW" ||
                noteEditStatus === "ESCALATED" ||
                NotesConstants.noteEditStatus.includes(noteEditStatus)) &&
              getLoggedInUser()?.uuid === props.claimedBy &&
              getLoggedInUser()?.uuid === note.creatorId) ? (
              <AddEditNoteModal
                note={note}
                toolTipText="Edit Note"
                icon={
                  <div>
                    <BiEdit style={{ color: "#FFAA3B" }} />
                  </div>
                }
                openModalButtonSize="sm"
                openModalButtonStyle="btn"
                openModalButtonClassName="pull-right note-action-btns"
                entityId={props.entityId}
                editNote={props.updateNote}
                entitySaveUrl={props.entitySaveUrl}
                editMode={true}
              />
            ) : null
          ) : null}
          {
            <Button
              onClick={props.pinNote ? pin : null}
              className="pull-right note-action-btns"
              disabled={props.pinNote ? false : true}
              variant="btn"
              size="sm"
            >
              {note.isPinned ? (
                <BsPinAngleFill style={{ color: "#FFAA3B" }} />
              ) : props.pinNote ? (
                <BsPinAngle style={{ color: "#FFAA3B" }} />
              ) : null}
            </Button>
          }
        </Col>
      </Row>
      <div className={`rm-switch-note ${clipped ? "clipped" : ""}`}>
        {<div dangerouslySetInnerHTML={{ __html: sanitizer(note.note) }} />}{" "}
        {note.activityRescheduleDate
          ? formatDateWithTime(note.activityRescheduleDate)
          : ""}
      </div>
    </div>
  );
};

export default NoteItem;
