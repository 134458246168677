export const WorkflowConstants = {
  DELIVERED_PRESCRIBER_FAX: "DELIVERED_PRESCRIBER_FAX",
  MISSING_PRESCRIBER_CONTACT_INFO: "MISSING_PRESCRIBER_CONTACT_INFO",
  SUBMITTED_VIA_EHR: "SUBMITTED_VIA_EHR",
  DELIVERED_PHARMACY_FAX: "DELIVERED_PHARMACY_FAX",
  SEND_PRESCRIBER_FAX: "SEND_PRESCRIBER_FAX",
  SEND_PHARMACY_FAX: "SEND_PHARMACY_FAX",
  DECLINED: "DECLINED",
  ALLOWED_RESEND_PRESCRIBER_FAX_STATES: [
    "GENERATED_PDF_ON_RESEND_FAX",
    "FAILED_PRESCRIBER_FAX_QUEUEING_IN_BUCKET",
    "ERROR_PRESCRIBER_FAX",
    "FAILED_PRESCRIBER_FAX",
    "DELIVERED_PRESCRIBER_FAX",
    "QUEUED_PDF_GENERATION_ON_RESEND_FAX",
    "ERROR_PDF_GENERATION_ON_RESEND_FAX",
  ],
};
