import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DismissableModal from 'components/DismissableModal';
import { FormGroup } from 'react-bootstrap';
import { showNotification } from 'services/NotificationService';
import { useRef } from 'react';
import { Multiselect } from "multiselect-react-dropdown";
import { useState, useEffect } from 'react';
import { getErrorMessage } from "services/UtilityService";
import Spinner from './Spinner';


const ClientMemberCommunication = () => {

    const modalRef = useRef("ClientMemberCommunication");
    const [clientsList, setClientsList] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchClients = () => {
        sendAuthenticatedAsyncRequest(
            `/clients/list`,
            "GET",
            null,
            (r) => {
                setClientsList(r.data.map((c) => ({
                    value: c.name,
                    label: c.id,
                })));
                setSelectedClients(r.data.map((c) => ({
                    value: c.name,
                    label: c.id,
                    flag: c.memberCommunicationEnabled
                })).filter(c => c.flag));
                setLoading(false);
            },
            (r) => {
                setClientsList([]);
                setSelectedClients([]);
                setLoading(false);
            }
        );
    }

    const submitForm = (originalValues, setSubmitting, closeModal) => {
        const dto = {
            clientIds: originalValues.clients
        }
        sendAuthenticatedAsyncRequest(
            `/clients/member-communication/update`,
            "PUT",
            dto,
            (r) => {
                setSubmitting(false);
                showNotification({
                    title: 'Action successful!',
                    message: 'Clients Member Communication settings updated successfully.',
                    position: 'tr',
                    type: "success"
                });
                closeModal();
            },
            (r) => {
                setSubmitting(false);
                showNotification({
                    title: 'Error!',
                    message: getErrorMessage(r.data) || getErrorMessage('SAVE_CLIENTS_MEMBER_COMMUNICATION_FAILED'),
                    position: 'tr',
                    type: "error"
                });
            }
        );
    }

    const onMultiSelectChange = (options, values) => {
        let tempList = [];
        options.map((x) => tempList.push(x.label));
        values.clients = tempList;
    }

    useEffect(() => {
        fetchClients();
    }, []);

    return (<>
        <Card style={{ textAlign: 'center' }}>
            <Card.Body>
                <Card.Text>
                    Enable/Disable Client Member communication.
                </Card.Text>
                <DismissableModal
                    ref={modalRef}
                    openModalButtonText="Member Communication"
                    openModalButtonStyle="info"
                    openModalButtonBlock={true}
                    openModalButtonDisabled={false}
                    openModalButtonId='client-member-comm-btn-popup'
                    preventCloseWhenClickedOutsideModal={true}
                    onClose={() => fetchClients()}
                    hideFooter={true}
                    title="Client Member Communication" >
                    {loading ? <Spinner /> : (<Formik
                        initialValues={{
                            clients: selectedClients.map((client) => client.label)
                        }}
                        onSubmit={(values, { setSubmitting, setValues }) => {
                            submitForm(values, setSubmitting, modalRef.current.handleClose);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            dirty,
                            isValid
                        }) => (
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Form.Label>Member communication enabled for clients:</Form.Label>
                                                <Multiselect
                                                    placeholder="Select Client"
                                                    options={clientsList}
                                                    displayValue="value"
                                                    name="clients"
                                                    value={values.clients}
                                                    onSelect={(e) => onMultiSelectChange(e, values)}
                                                    onRemove={(e) => onMultiSelectChange(e, values)}
                                                    style={{
                                                        chips: {
                                                            background: "#007BBB",
                                                        },
                                                    }}
                                                    showArrow
                                                    hidePlaceholder
                                                    selectedValues={selectedClients}
                                                ></Multiselect>
                                                <Form.Text className="text-small text-danger">
                                                    {errors.switchList && errors.switchList}
                                                </Form.Text>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="top-spacer">
                                        <Col md={4} className="bottom-spacer">
                                            <Button
                                                type="submit"
                                                variant="success"
                                                className="btn-block btn-text-white"
                                                block
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? "Saving ... " : "Save"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                        )}
                    </Formik>)}
                </DismissableModal>
            </Card.Body>
        </Card>
    </>)
}

export default ClientMemberCommunication;