import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CloseWorkflowForSwitches from "components/CloseWorkflowForSwitches";
import SyncMembersWithSendGrid from "components/SyncMembersWithSendGrid";
import CheckCustomerConfigurationOnSendGrid from "components/CheckCustomerConfigurationOnSendGrid";
import ClientMemberCommunication from "components/ClientMemberCommunication";
import AutoAcceptEnablement from "components/AutoAcceptEnablement";

export default class Admin extends Component {
  render() {
    return (
      <Container fluid className="top-spacer">
        {/* <Row>
          <Col md={{span: '3', offset: '9'}}>
            <WorkflowStarterForNewSwitches />
          </Col>
        </Row> */}
        <Row className="top-spacer">
          <Col md={{ span: "3", offset: "9" }}>
            <CloseWorkflowForSwitches />
          </Col>
        </Row>
        <Row className="top-spacer">
          <Col md={{ span: "3", offset: "9" }}>
            <SyncMembersWithSendGrid />
          </Col>
        </Row>
        <Row className="top-spacer">
          <Col md={{ span: "3", offset: "9" }}>
            <CheckCustomerConfigurationOnSendGrid />
          </Col>
        </Row>
        <Row className="top-spacer">
          <Col md={{ span: "3", offset: "9" }}>
            <ClientMemberCommunication />
          </Col>
        </Row>
        <Row className="top-spacer">
          <Col md={{ span: "3", offset: "9" }}>
            <AutoAcceptEnablement />
          </Col>
        </Row>
      </Container>
    );
  }
}
