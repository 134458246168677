import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { MdModeEdit } from "react-icons/md";
import { BsCloudPlus } from "react-icons/bs";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import AdminPopUpDismissableModal from "components/adminPopupDismissableModal";
import { getErrorMessage } from "services/UtilityService";
import { showNotification } from "services/NotificationService";
import "assets/css/user.css";

const EditDashboard = (props) => {
  const [showButton, setShowButton] = useState(true);
  const modalRef = useRef(props.isEdit ? "editDashboard" : "addDashboard");

  const editDashboard = (values, setSubmitting, closeModalHandle) => {
    let dashboardDto = {};
    dashboardDto.dashboardId = props.isEdit
      ? props.dashboard.dashboardUuid
      : null;
    dashboardDto.dashboardName = values.dashboardName;
    dashboardDto.description = values.description;
    dashboardDto.quickSightDashboardId = values.quickSightDashboardId;
    dashboardDto.confluenceDocumentLink = values.confluenceDocumentLink;

    sendAuthenticatedAsyncRequest(
      `/analytics/${props.isEdit ? "edit" : "add"}-dashboard`,
      props.isEdit ? "PUT" : "POST",
      dashboardDto,
      (r) => {
        setSubmitting(false);
        showNotification({
          title: "Action successful!",
          message: `Dashboard ${
            props.isEdit ? "edited" : "added"
          } successfully.`,
          position: "tc",
          type: "success",
        });
        closeModalHandle();
        props.handler(); //refresh favs and toc import actions
      },
      (r) => {
        setSubmitting(false);
        showNotification({
          title: "Error!",
          message:"Unable to update Dashboards. Please review that you have the relevant permissions to perform the requested action.",
          position: "tc",
          type: "error",
        });
      }
    );
  };

  const editDashboardFormSchema = Yup.object().shape({
    dashboardName: Yup.string()
      .max(60, "Maximum Length: " + 60)
      .required("Dashboard name is required"),
    description: Yup.string().required("Description is required"),
    quickSightDashboardId: Yup.string()
      .uuid()
      .required("QuickSight dashboard ID is required"),
    confluenceDocumentLink: Yup.string().required(
      "Confluence Document Link is required"
    ),
  });

  const invertShowbutton = () => {
    setShowButton(!showButton);
  };

  return showButton ? (
    <OverlayTrigger
      overlay={
        <Tooltip>{props.isEdit ? "Edit Dashboard" : "Add Dashboard"}</Tooltip>
      }
    >
      {props.isEdit ? (
        <MdModeEdit
          size={20}
          className="user-action-button edit-user"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            invertShowbutton();
          }}
        ></MdModeEdit>
      ) : (
        <BsCloudPlus
          size={20}
          className="user-action-button edit-user"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            invertShowbutton();
          }}
        ></BsCloudPlus>
      )}
    </OverlayTrigger>
  ) : (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip>{props.isEdit ? "Edit Dashboard" : "Add Dashboard"}</Tooltip>
        }
      >
        {props.isEdit ? (
          <MdModeEdit
            size={20}
            className="user-action-button edit-user"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              invertShowbutton();
            }}
          ></MdModeEdit>
        ) : (
          <BsCloudPlus
            size={20}
            className="user-action-button edit-user"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              invertShowbutton();
            }}
          ></BsCloudPlus>
        )}
      </OverlayTrigger>

      <AdminPopUpDismissableModal
        ref={modalRef}
        openModalButtonText={props.isEdit ? "Edit Dashboard" : "Add Dashboard"}
        openModalButtonStyle="info"
        openModalButtonBlock={true}
        openModalButtonDisabled={false}
        openModalBsuttonId="edit-user-role-btn-popup"
        preventCloseWhenClickedOutsideModal={true}
        initialStateOpen={true}
        hideFooter={true}
        onClose={invertShowbutton}
        title={
          props.isEdit ? "Edit dashboard details" : "Add new dashboard details"
        }
        showIcons={true}
      >
        <Formik
          initialValues={{
            dashboardName: props.isEdit ? props.dashboard.dashboardName : "",
            description: props.isEdit ? props.dashboard.description : "",
            quickSightDashboardId: props.isEdit
              ? props.dashboard.quickSightDashboardId
              : "",
            confluenceDocumentLink: props.isEdit
              ? props.dashboard.confluenceDocumentLink
              : "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            editDashboard(values, setSubmitting, modalRef.current.handleClose);
          }}
          validationSchema={editDashboardFormSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Dashboard Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.dashboardName}
                      onChange={handleChange}
                      name="dashboardName"
                      onBlur={handleBlur}
                    ></Form.Control>
                    <Form.Text className="text-small text-danger">
                      {errors.dashboardName &&
                        touched.dashboardName &&
                        errors.dashboardName}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      name="description"
                      onBlur={handleBlur}
                    ></Form.Control>
                    <Form.Text className="text-small text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>QuickSight Dashboard ID</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.quickSightDashboardId}
                      onChange={handleChange}
                      name="quickSightDashboardId"
                      onBlur={handleBlur}
                    ></Form.Control>
                    <Form.Text className="text-small text-danger">
                      {errors.quickSightDashboardId &&
                        touched.quickSightDashboardId &&
                        errors.quickSightDashboardId}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Confluence Document Link</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.confluenceDocumentLink}
                      onChange={handleChange}
                      name="confluenceDocumentLink"
                      onBlur={handleBlur}
                    ></Form.Control>
                    <Form.Text className="text-small text-danger">
                      {errors.confluenceDocumentLink &&
                        touched.confluenceDocumentLink &&
                        errors.confluenceDocumentLink}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bottom-spacer top-spacer">
                <Col md={{ offset: "4", span: "4" }}>
                  <Button
                    variant="outline-dark"
                    block
                    onClick={() => modalRef.current.handleClose()}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={4}>
                  <Button
                    type="submit"
                    variant="success"
                    block
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? props.isEdit
                        ? "Editing... "
                        : "Adding..."
                      : props.isEdit
                      ? "Edit"
                      : "Add"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </AdminPopUpDismissableModal>
    </>
  );
};

export default EditDashboard;
