import React from 'react';
import { formatDateForDisplay } from 'services/MomentUtils';
import { Badge } from 'react-bootstrap';
export default function CustomerAndClientTitle({
  globalSwitchId = "",
  customerName = "",
  clientName = "",
  customerDisplayId = "",
  clientDisplayId = "",
  creationDate = "",
  targetSwitchDate = "",
  shouldDisplayGlobalSwitchId = true,
  shouldDisplayDates = true,
  nameClassName = "",
  dateClassName = "",
  isCallActivityCreationBlocked = false
}) {
  let name = clientName ?
    !customerName || clientName == customerName ? clientName :
      clientName + " (" + customerName + ")"
    : customerName ? "(" + customerName + ")" : "Client/Customer Name N/A";
  let displayIds = clientDisplayId ?
    !customerDisplayId ?
      " (Client# : " + clientDisplayId + ")"
      : " (Client# : " + clientDisplayId + " , Customer# : " + customerDisplayId + ")"
    : customerDisplayId ? " (Customer# : " + customerDisplayId + ")"
      : null;
  return (
    <div>
      {shouldDisplayGlobalSwitchId ?
        <div>
          <div style={{ textAlign: 'center', fontSize: '0.8em' }}>
            <span className='text-muted'>Timezone:</span>&nbsp;
            <span >{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
          </div>
          <div className="text-center even-bigger-text">
            <span style={{verticalAlign: 'middle'}}>
              {globalSwitchId}
              {/* <FDTickets /> */}
              {isCallActivityCreationBlocked && 
                (<Badge style={{ fontSize: '0.8rem', marginLeft: '.5rem', verticalAlign: 'middle'}} bg="danger">Activities Blocked</Badge>)}
            </span>
          </div>
          <hr />
        </div>
        :
        null
      }
      <div className={nameClassName ? nameClassName : "bigger-text"} data-testid="text-customer-client">
        {name}
        {displayIds ?
          <span className={"text-muted normal-text"}>
            {displayIds}
          </span>
          : null}
      </div>
      {
        shouldDisplayDates ?
          <div className={dateClassName ? dateClassName : "text-muted"}>
            Created on
            <strong className="spacey">
              {creationDate ? ' ' + formatDateForDisplay(creationDate) + ' ' : ' N/A '}
            </strong>
            and effective from
            <strong className="spacey">
              {targetSwitchDate ? ' ' + formatDateForDisplay(targetSwitchDate) + ' ' : ' N/A '}
            </strong>
          </div>
          : null
      }
    </div>
  )
}
