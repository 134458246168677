import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUnprocessedFaxList, updateIndexOnList, resetError } from './UnprocessedFaxList.actions';
import { withRouter } from 'react-router-dom';
import ResetListState from 'components/ResetListState';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UnprocessedFaxConstants } from 'lookup/UnprocessedFaxConstants'
import { WrapTextAndCenterAlign } from 'components/WrapText';
import { formatDateForDisplay, formatDateWithName } from 'services/MomentUtils';
import { BOOLEAN_FALSE_FILTER, BOOLEAN_TRUE_FILTER } from 'lookup/GlobalConstants';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { getErrorMessage } from 'services/UtilityService';

import { showNotification } from 'services/NotificationService';
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import "../../../assets/css/switch.css"
import { Button } from 'react-bootstrap';
const Tip = (props) => (
  <div style={{ textAlign: "center", color: "#888", fontSize: "0.9em" }}>
    <em>{props.children}</em>
  </div>
);


const DEFAULT_PAGE_SIZE = 15;

const switchesMap = [
  { key: 'false', value: 'Non-Associated' },
  { key: 'true', value: 'Associated' },
]

class UnprocessedFaxList extends React.Component {

  state = {
    loading: true,
    data: this.props.data,
    isError: false,
    pages: this.props.pages,
    totalElements: this.props.totalElements,
    listState: this.props.listState,
    switchAdminList: [],
  }

  updateData = (state, preventUpdate) => {
    if (!preventUpdate) {
      state.filtered = state.filtered ? state.filtered : [];
      const copiedListState = Object.assign({}, state);
      const filteredForKey = copiedListState.filtered ? copiedListState.filtered : [];
      if (filteredForKey.length === 0) {
        filteredForKey.push(UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_FILTER[0]);
      }
      const defaultFilterForFrontend = filteredForKey.filter(element => !(element.id === 'associatedSwitchId' && element.value === BOOLEAN_FALSE_FILTER.value));
      if (defaultFilterForFrontend.length !== filteredForKey.length) {
        defaultFilterForFrontend.push(UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_FILTER[0])
        copiedListState.filtered = defaultFilterForFrontend;
      }
      else
        copiedListState.filtered = filteredForKey;
      const containsDefault = filteredForKey.filter(element => (element.id === 'associatedSwitchId' && element.value === BOOLEAN_FALSE_FILTER.value));
      if (containsDefault.length === 0) {
        copiedListState.filtered.push(UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_FILTER[0])
      }
      state.filtered = copiedListState.filtered

      const index_default = state.filtered.findIndex(element => element.id === 'associatedSwitchId')
      if (index_default > -1) {
        let defaultFilter = state.filtered[index_default]
        state.filtered = state.filtered.filter(element => element.id !== 'associatedSwitchId')
        state.filtered.unshift(defaultFilter)
      }

      if (state.sorted !== UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_SORTING)
        state.sorted.forEach((element, i) => {
          element.isDefault = false
        });

      this.props.updateUnprocessedFaxList(state.pageSize, state.page, state.sorted, state.filtered, state.resized, state.expanded);
    } else {
      // should have a separate action
      this.setState({ listState: state });
    }

  }

  componentDidMount() {
    this.updateData(this.state.listState);
    sendAuthenticatedAsyncRequest('/fax/unprocessed-fax-list-filters', "GET", null,
      (res) => {
        if (res.status === 200) {
          let recentlyViewedUsers = res.data.filters.recentlyViewedUsers;
          let switchAdminList = recentlyViewedUsers.map((element) => {
            return { id: element.value, name: element.id }
          });
          switchAdminList.sort((a, b) => a.name.localeCompare(b.name));
          this.setState({ switchAdminList: switchAdminList });
        }
      }, (res) => {
        showNotification({
          title: "Unable to fetch filters!",
          message:
            getErrorMessage(res.data) || getErrorMessage("SOMETHING_WENT_WRONG"),
          position: "tr",
          type: "error",
        });
      });
  }


  handleToggle = (e) => {
    this.state.listState.filtered.forEach(filter => {
      if (filter.id === 'showMyRecentsOnly') {
        filter.value = !filter.value;
      }
    })

    this.updateData(this.state.listState)
  }

  componentDidUpdate(prevProps) {
    const { data, pages, totalElements, listState, loading, isError } = this.props;
    if (prevProps != null &&
      (prevProps.data !== data || prevProps.pages !== pages || prevProps.totalElements !== totalElements ||
        prevProps.listState !== listState || prevProps.loading !== loading)) {
      this.setState({ data, pages, totalElements, loading, listState });
    }

    if (isError === true && prevProps.isError !== isError) {
      showNotification({
        title: 'Error Loading Data!',
        message: 'You have entered invalid information.',
        position: 'bl',
        type: "error"
      });
      this.props.resetError();
    }
  }

  renderDateCell = (date) => {
    return (
      <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {date}
      </span>
    );
  }
  renderEffectiveDateCol = (date) => {
    if (date) {
      return this.renderDateCell(formatDateForDisplay(date));
    }
    return <span className="center">N/A</span>;
  }

  renderLastModifiedDateCol = (date, name) => {
    if (date) {
      return this.renderDateCell(formatDateWithName(date, name));
    }
    return <span className="center"></span>;
  }
  updateFilterObjectForTable = (filterValues) => {
    filterObjects = [];
  }

  isShowMyRecentsDisabled = () => {
    var val;
    this.state.listState.filtered.forEach(filter => {
      if (filter.id === 'showMyRecentsOnly') {
        val = filter.value
      }
    })
    return val;
  }

  onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    this.state.listState.pageSize = pageSize;
    this.state.listState.page = pageNo;
    this.state.listState.sorted = sortBy ? sortBy : [];
    this.state.listState.filtered = filterValues;
    this.updateData(this.state.listState);

  }


  render() {
    const { data, pages, loading, totalElements, listState, switchAdminList } = this.state;
    const columns = [
      {
        header: 'Fax Document',
        accessor: 'faxFileKey',
        accessorKey: 'faxFileKey',
        enableSorting: true,
        columnType: ColumnTypes.Text,
        width: 400,
        enableColumnFilter: true,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: 'Fax Identifier',
        accessor: 'faxInfoShortIdentifier',
        accessorKey: 'faxInfoShortIdentifier',
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
        enableColumnFilter: true,
        width: 300,
      },
      {
        header: 'Priority',
        accessor: 'faxPriority',
        accessorKey: 'faxPriority',
        width: 200,
        columnType: ColumnTypes.Custom,
        enableColumnFilter: true,
        enableSorting: true,
        filterType: FilterTypes.SingleSelect,
        customComponent: (value, row) => {
          return <WrapTextAndCenterAlign>{UnprocessedFaxConstants.UNPROCESSED_FAX_PRIORITY[value]}</WrapTextAndCenterAlign>;
        },
        filterOptions: UnprocessedFaxConstants.UNPROCESSED_FAX_PRIORITY_SINGLE_SELECT_OPTIONS,
      },
      {
        header: 'Associated Switch',
        accessor: 'associatedSwitchId',
        accessorKey: 'associatedSwitchId',
        width: 200,
        columnType: ColumnTypes.Text,
        enableColumnFilter: true,
        enableSorting: true,
        filterType: FilterTypes.SingleSelect,
        filterOptions: switchesMap,
        filterReturnKey: true,
      },
      {
        header: 'Last Modified Date',
        accessor: 'lastModifiedDate',
        accessorKey: 'lastModifiedDate',
        columnType: ColumnTypes.DateColumn,
        enableSorting: true,
      }, {
        header: 'Received Date',
        accessor: 'generatedDate',
        accessorKey: 'generatedDate',
        columnType: ColumnTypes.DateColumn,
        enableSorting: true,
      },
      {
        header:"Recently Viewed Users",
        accessor: 'recentlyViewedUsers',
        accessorKey: 'recentlyViewedUsers',
        columnType: ColumnTypes.Custom,
        enableColumnFilter: true,
        enableSorting: true,
        filterType: FilterTypes.MultiSelect,
        filterOptions: switchAdminList,
        hasToggleButton: true,
        handleToggle: this.handleToggle,
        isDisabled: this.isShowMyRecentsDisabled(),
        customComponent: (value, row) => {
          return this.renderLastModifiedDateCol(
            row.original.lastViewedAt,
            row.original.lastViewedBy
          )
        },
      },
    ]

    return (

      <Container className="list-container" fluid>
        <Row >
          <Col md={12}>
            <div className='codex-version-display-bar'>
              <span style={{ float: 'left' }} >   Total Unprocessed Faxes: <strong>{totalElements}</strong></span>
              <strong style={{ float: 'center' }}>Unprocessed Fax List</strong>
              <ResetListState
                onReset={() => this.updateData({ ...listState, filtered: UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_FILTER, sorted: UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_SORTING })}
                resetterDisabled={listState.filtered === UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_FILTER && listState.sorted === UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_SORTING}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ServerSideReactTable
              columnDefination={columns}
              data={data}
              currentPage={listState.page}
              sorted={listState.sorted}
              filters={listState.filtered}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              totalPages={pages}
              totalRecords={totalElements == "loading" ? 0 : totalElements}
              onUpdateTableData={this.onUpdateTable}
              loading={loading}
              onRowClick={(rowInfo) => {
                if (rowInfo !== undefined) {
                  this.props.updateIndexOnList(rowInfo.index);
                  this.props.history.push(`/unprocessed-fax/fax-details/${rowInfo.original.faxInfoIdentifier}`);
                }
              }}
            />
            <Tip>
              Tip: Hold shift when sorting to multi-sort and disable sort!
            </Tip>
          </Col>
        </Row>

      </Container>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.unprocessedFax.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_SORTING,
      filtered: UnprocessedFaxConstants.DEFAULT_UNPROCESSED_FAX_LIST_FILTER,
      resized: [],
      expanded: {},
    }
  }
  return {
    data: state.unprocessedFax.data !== undefined ? state.unprocessedFax.data : [],
    pages: state.unprocessedFax.pages ? state.unprocessedFax.pages : 0,
    totalElements: state.unprocessedFax.totalElements !== undefined ? state.unprocessedFax.totalElements : "Loading ...",
    loading: state.unprocessedFax.loading,
    listState: listState,
    isError: state.unprocessedFax.isError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUnprocessedFaxList: bindActionCreators(updateUnprocessedFaxList, dispatch),
    updateIndexOnList: bindActionCreators(updateIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch)

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UnprocessedFaxList));