import React, { Component } from 'react';

import DismissableModal from '../DismissableModal';
import { PawConstants } from '../../lookup/PawConstants';

import { sendAuthenticatedAsyncRequest } from '../../services/AsyncRequestService';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import './PawStatus.css';
import { AgentWorkspaceConstants } from 'lookup/AgentWorkspaceConstants';
import { FormSelect } from 'react-bootstrap';

class SubmitForReviewPaw extends Component {

  state = {
    isCommentValid: false,
    comments: "",
    usersList: [],
    selectedUserId: "",
    failure: false,
    isUserSelected: false,
    context: this.props.context || 'paw'
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    const { context } = this.state;
    sendAuthenticatedAsyncRequest(
      `/user?exclude_requester=true&context=${context}`,
      "GET",
      null,
      (r) => this.setState({ usersList: r.data }),
      (r) => this.setState({ failure: false })
    );
  }

  submitForReview(closeModal) {
    if (this.state.isCommentValid && this.state.isUserSelected) {
       this.props.attemptStatusUpdate(this.state.comments,this.state.selectedUserId);
      closeModal();
    }
  }

  render() {
    const { comments, isCommentValid, usersList, failure, selectedUserId, isUserSelected } = this.state;
    const modalRef = "unclaimConfirmationModal";
    const customFooterContent = (
      <div>
        <Button id='switchdetails-btn-sfr-cancel' variant="outline-dark" onClick={() => this.refs[modalRef].handleClose()}>Cancel</Button>
        <Button
          id='switchdetails-btn-sfr'
          className="left-spacer"
          variant="warning"
          onClick={() => this.submitForReview(this.refs[modalRef].handleClose)}>
          Yes, submit this for review
        </Button>
      </div>
    );
    return (
      <DismissableModal
        ref={modalRef}
        customFooterContent={customFooterContent}
        openModalButtonText="Submit for Review"
        openModalButtonStyle="info"
        openModalButtonBlock={true}
        openModalButtonDisabled={this.props.disallowEditing}
        openModalButtonId='switchdetails-btn-sfr-popup'
        openModalButtonClassName="btn-block"
        title="Submit Switch for Review" >
        <p className="confirmation-text">
          You have selected to <b>submit</b> this Drug Group for review.
        </p>
        <p className="text-muted">Once you submit this Drug Group for review, the reviewer will claim this Drug Group and would be able to change its status.</p>
        {
          usersList === []
            ? failure
              ? <p className="text-danger"> Failed to load Reviewers</p> : <p className="text-info"> Loading Reviewers ...</p>
            : <Form.Group >
              <Form.Label>Select Reviewer</Form.Label>
              <FormSelect
                id='switchdetails-select-sfr-reviewer'
                
                value={selectedUserId}
                onChange={(e) => this.setState({ selectedUserId: e.target.value, isUserSelected: e.target.value !== "" })}
              >
                <option value="">Click to select a Reviewer</option>
                {
                  usersList.map((u, k) => <option value={u.id} key={k} id={`switchdetails-user-id-${u.id}`}>{u.name}</option>)
                }
              </FormSelect>
              {
                !isUserSelected ? <span className="text-danger smaller-feedback">Selecting a Reviewer is mandatory!</span> : <span className="text-success smaller-feedback">Looks good!</span>
              }
            </Form.Group>

        }
        <Form.Group>
          <Form.Label>Please enter comments for reviewer</Form.Label>
          <Form.Control
            id='switchdetails-textarea-sfr-comments'
            as="textarea"
            rows="3"
            value={comments}
            onChange={(e) => this.setState({ comments: e.target.value, isCommentValid: e.target.value !== "" })} />
          {
            !isCommentValid ? <span className="text-danger smaller-feedback">Comments are mandatory!</span> : <span className="text-success smaller-feedback">Looks good!</span>
          }
        </Form.Group>
      </DismissableModal>
    );
  }
}

export default SubmitForReviewPaw;
