import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useRef } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";
import { getErrorMessage, isObjectEmpty } from "services/UtilityService";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import DismissableModal from "components/DismissableModal";
import CONTACTMANAGEMENT_CONSTANTS from "lookup/ContactManagementConstants";
import { isStringEmptyOrNull, compareEmptyOrNull } from "services/UtilityService";
import { showNotification } from "services/NotificationService";

const UpdatePharmacyInfo = (props) => {
  const { pharmacy, isDisabled } = props;
  const pharmacyInfoCloneForInitialValues = pharmacy ? cloneDeep(pharmacy) : {};
  const title =
    "Edit Detail for " + pharmacyInfoCloneForInitialValues.pharmacyName;
  const modalRef = useRef();
    // "UpdateInfoModal " +
    // (pharmacyInfoCloneForInitialValues.pharmacyGlobalId || "New");
  const modalOpener = (
    <Button
      size="sm"
      variant="light"
      key={1}
      disabled={isObjectEmpty(pharmacy) || isDisabled}
      onClick={() => handleSpecialOpen(modalRef.current.handleShow)}
      data-testid='btn-pharmacy-edit'
    >
      Edit
    </Button>
  );
  const isNotesRequired = (refreshCallback) => {
    if(refreshCallback) {
      return Yup.string()
      .nullable()
      .max(4096, "Max Length: 4096")
      .required("Required!")
    }

    else {
      return Yup.string()
      .nullable()
      .max(4096, "Max Length: 4096")
      .notRequired()
    }
  }
  const UpdateInfoFormSchema = Yup.object().shape({
    pharmacyName: Yup.string()
      .max(
        255,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
      ),
    pharmacyFax: Yup.string()
      .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .required("Required!")
      .nullable(true),
      pharmacyFax2: Yup.string()
      .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .nullable(true),
    pharmacyPhone: Yup.string()
      .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .required("Required!")
      .nullable(true),
      pharmacyPhone2: Yup.string()
      .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .nullable(true),
    pharmacyNpi: Yup.string()
      .matches(/^[0-9]*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .nullable(true),
    pharmacyAddressLine1: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    pharmacyUpdateNotes: Yup.string()
    .nullable()
    .max(4096, "Max Length: 4096")
    .required("Required!")
  });

  const isFieldRequired = (key) => {
    return UpdateInfoFormSchema.fields[key]
      ? UpdateInfoFormSchema.fields[key].exclusiveTests.required
      : false;
  };

  const submitForm = (
    originalValues,
    setSubmitting,
    setValues,
    oldPharmacy,
    closeModal
  ) => {
    // it's best to create a clone because params are passed through reference
    originalValues = {
      ...originalValues,
      isFromAW: props.isFromAW ? true : false,
      wfItemId: props.wfItemId
    };
    const values = cloneDeep(originalValues);
    if (
      (!isStringEmptyOrNull(oldPharmacy.pharmacyFax) &&
        isStringEmptyOrNull(values.pharmacyFax)) ||
      (!isStringEmptyOrNull(oldPharmacy.pharmacyPhone) &&
        isStringEmptyOrNull(values.pharmacyPhone))
    ) {
      showNotification({
        title: "Invalid Change Detected!",
        message: `Fax and phone numbers cannot be empty.`,
        position: "tr",
        type: "warning",
      });
      setSubmitting(false);
      return;
    }

    if (
      (oldPharmacy.pharmacyName === values.pharmacyName || compareEmptyOrNull(oldPharmacy.pharmacyName, values.pharmacyName))
      &&
      (oldPharmacy.pharmacyFax === values.pharmacyFax || compareEmptyOrNull(oldPharmacy.pharmacyFax, values.pharmacyFax))
      &&
      (oldPharmacy.pharmacyPhone === values.pharmacyPhone || compareEmptyOrNull(oldPharmacy.pharmacyPhone, values.pharmacyPhone))
      &&
      (oldPharmacy.pharmacyFax2 === values.pharmacyFax2 || compareEmptyOrNull(oldPharmacy.pharmacyFax2, values.pharmacyFax2))
      &&
      (oldPharmacy.pharmacyPhone2 === values.pharmacyPhone2 || compareEmptyOrNull(oldPharmacy.pharmacyPhone2, values.pharmacyPhone2))
      &&
      (oldPharmacy.pharmacyAddressLine1 === values.pharmacyAddressLine1 || compareEmptyOrNull(oldPharmacy.pharmacyAddressLine1, values.pharmacyAddressLine1))
    ) {
      showNotification({
        title: "No Change Detected!",
        message: `You are trying to save without making any update.`,
        position: "tr",
        type: "warning",
      });
      setSubmitting(false);
      return;
    }

    if(isStringEmptyOrNull(values.pharmacyUpdateNotes.trim())) {
      showNotification({
        title: "Invalid Note!",
        message: `You are trying to save an empty note.`,
        position: "tr",
        type: "warning",
      });
      setSubmitting(false);
      return;
    }

    sendAuthenticatedAsyncRequest(
      `/contact-management/pharmacy-info/${oldPharmacy.pharmacyInfoUuid}`,
      "PUT",
      values,
      (r) => {
        setSubmitting(false);
        const updatePharmacyInfoDto = r.data;
        closeModal();
        if (props.onSubmission && typeof props.onSubmission === "function") {
          props.onSubmission(updatePharmacyInfoDto);
        }

        // dispatch fax resent notification
        if (oldPharmacy.pharmacyFax !== values.pharmacyFax)
          showNotification({
            title: `Auto Fax Resending Process started!`,
            message: `Fax is being resent automatically as the fax number is updated. You dont need to close this activity or other TASK activities of this call group now, they will be closed automatically by the background process. You can go back and work on other call groups.`,
            position: "tr",
            type: "success",
            duration: 20000,
          });


        // dispatch success notification
        showNotification({
          title: `Successful Update!`,
          message: `Pharmacy Info updated successfully`,
          position: "tr",
          type: "success",
        });

        props.updateData();

      },
      (r) => {
        setSubmitting(false);
        setValues(oldPharmacy);
        // dispatch failure notification
        showNotification({
          title: "Something went wrong!",
          message:
            getErrorMessage(r.data) ||
            getErrorMessage("FAILED_TO_UPDATE_PHARMACY_INFO"),
          position: "tr",
          type: "error",
        });
      }
    );
  };

  const handleSpecialOpen = (handleRealOpen) => {
    handleRealOpen();
  };
  //remove all the space from number and enable only entering number
  const onChange = (value, field, setFieldValue) => {
    const numberRegex = /^[0-9\b]+$/;
    value = value.replace(/\s/g, "");

    if (value === "" || numberRegex.test(value)) {
      setFieldValue(field, value);
    }
  };
  return (
    <DismissableModal
      ref={modalRef}
      openModalButton={modalOpener}
      title={title}
      size="lg"
      preventCloseWhenClickedOutsideModal={true}
      hideFooter={true}
      openModalButtonDisabled={isDisabled}
    >
      <Container>
        <Formik
          initialValues={{
            pharmacyName: pharmacyInfoCloneForInitialValues.pharmacyName || "",
            pharmacyPhone:
              pharmacyInfoCloneForInitialValues.pharmacyPhone || "",
              pharmacyPhone2:
              pharmacyInfoCloneForInitialValues.pharmacyPhone2 || "",
            pharmacyFax: pharmacyInfoCloneForInitialValues.pharmacyFax || "",
            pharmacyFax2: pharmacyInfoCloneForInitialValues.pharmacyFax2 || "",
            pharmacyNpi: pharmacyInfoCloneForInitialValues.pharmacyNpi || "",
            pharmacyAddressLine1:
              pharmacyInfoCloneForInitialValues.pharmacyAddressLine1 || "",
            pharmacyUpdateNotes:  "", // initially, notes will always be empty
            version: pharmacyInfoCloneForInitialValues.version || 0,
          }}
          onSubmit={(values, { setSubmitting, setValues }) => {
            submitForm(
              values,
              setSubmitting,
              setValues,
              pharmacy,
              modalRef.current.handleClose
            );
          }}
          validationSchema={UpdateInfoFormSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("pharmacyName")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Pharmacy Name
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pharmacyName}
                      name="pharmacyName"
                      disabled={true}
                      data-testid='input-pharmacy-edit-name'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.pharmacyName &&
                        touched.pharmacyName &&
                        errors.pharmacyName}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("pharmacyNpi")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Pharmacy Npi
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pharmacyNpi}
                      name="pharmacyNpi"
                      disabled
                      data-testid='input-pharmacy-edit-npi'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.pharmacyNpi &&
                        touched.pharmacyNpi &&
                        errors.pharmacyNpi}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("pharmacyPhone")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Pharmacy Phone
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        onChange(
                          e.target.value,
                          "pharmacyPhone",
                          setFieldValue
                        )
                      }
                      onBlur={handleBlur}
                      value={values.pharmacyPhone}
                      name="pharmacyPhone"
                      data-testid='input-pharmacy-edit-phone'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.pharmacyPhone &&
                        touched.pharmacyPhone &&
                        errors.pharmacyPhone}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("pharmacyFax")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Pharmacy Fax
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        onChange(
                          e.target.value,
                          "pharmacyFax",
                          setFieldValue
                        )
                      }
                      onBlur={handleBlur}
                      value={values.pharmacyFax}
                      name="pharmacyFax"
                      data-testid='input-pharmacy-edit-fax'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.pharmacyFax &&
                        touched.pharmacyFax &&
                        errors.pharmacyFax}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("pharmacyPhone")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Alternate Pharmacy Phone
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        onChange(
                          e.target.value,
                          "pharmacyPhone2",
                          setFieldValue
                        )
                      }
                      onBlur={handleBlur}
                      value={values.pharmacyPhone2}
                      name="pharmacyPhone2"
                      data-testid='input-pharmacy-edit-alt-phone'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.pharmacyPhone2 &&
                        touched.pharmacyPhone2 &&
                        errors.pharmacyPhone2}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("pharmacyFax2")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Alternate Pharmacy Fax
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        onChange(
                          e.target.value,
                          "pharmacyFax2",
                          setFieldValue
                        )
                      }
                      onBlur={handleBlur}
                      value={values.pharmacyFax2}
                      name="pharmacyFax2"
                      data-testid='input-pharmacy-edit-alt-fax'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.pharmacyFax2 &&
                        touched.pharmacyFax2 &&
                        errors.pharmacyFax2}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("pharmacyAddressLine1")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Pharmacy Address
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pharmacyAddressLine1}
                      name="pharmacyAddressLine1"
                      data-testid='input-pharmacy-edit-address'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.pharmacyAddressLine1 &&
                        touched.pharmacyAddressLine1 &&
                        errors.pharmacyAddressLine1}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label
                      className={
                        isFieldRequired("pharmacyUpdateNotes")
                          ? "rm-required-field"
                          : ""
                      }
                    >
                      Notes
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      as="textarea"
                      value={values.pharmacyUpdateNotes}
                      name="pharmacyUpdateNotes"
                      data-testid='input-pharmacy-edit-notes'
                    />
                    <Form.Text className="text-small text-danger">
                      {errors.pharmacyUpdateNotes &&
                        touched.pharmacyUpdateNotes &&
                        errors.pharmacyUpdateNotes}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="top-spacer">
                <Col md={{ offset: "4", span: "4" }}>
                  <Button
                    className="btn-block"
                    variant="outline-dark"
                    block
                    onClick={() => modalRef.current.handleClose()}
                    disabled={isSubmitting}
                    data-testid='btn-pharmacy-edit-cancel'
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={4} className="bottom-spacer">
                  <Button
                    type="submit"
                    variant="success"
                    block
                    disabled={isSubmitting}
                    className="btn-block"
                    data-testid='btn-pharmacy-edit-save'
                  >
                    {isSubmitting ? "Updating ... " : "Update"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </DismissableModal>
  );
};

export default withRouter(UpdatePharmacyInfo);
