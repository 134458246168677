import config from "config";
import { RSAA } from "redux-api-middleware";
import {
  AUTH_TOKEN_HEADER_KEY,
  getAuthorizationToken,
} from "../../../services/AuthService";
import {
  RESET_DRUG_GROUP_STATUS,
  resetDrugGroupDetail,
  resetSwitchDetailFetch,
  setDrugGroupApprovalModalStatus,
} from "./DrugGroupDetails.slice";

export const getDrugGroupDetailRequestAction = "GET_DRUG_GROUP_DETAIL_REQUEST";
export const getDrugGroupDetailSuccessAction = "GET_DRUG_GROUP_DETAIL_SUCCESS";
export const getDrugGroupDetailFailureAction = "GET_DRUG_GROUP_DETAIL_FAILURE";

export const updateDrugGroupDetailRequestAction =
  "UPDATE_DRUG_GROUP_DETAIL_REQUEST";
export const updateDrugGroupDetailSuccessAction =
  "UPDATE_DRUG_GROUP_DETAIL_SUCCESS";
export const updateDrugGroupDetailFailureAction =
  "UPDATE_DRUG_GROUP_DETAIL_FAILURE";

export const getDrugGroupSwitchesRequestAction =
  "GET_DRUG_GROUP_SWITCHES_REQUEST";
export const getNextDrugGroupDetailRequestAction =
  "GET_NEXT_DRUG_GROUP_DETAIL_REQUEST";
export const getDrugGroupSwitchesSuccessAction =
  "GET_DRUG_GROUP_SWITCHES_SUCCESS";
export const getDrugGroupSwitchesFailureAction =
  "GET_DRUG_GROUP_SWITCHES_FAILURE";

export const getDrugGroupDetail =
  (pawGroupId, drugGroupId) => (dispatch, getState) => {
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/paw-group/${pawGroupId}/drug-groups/${drugGroupId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        types: [
          getDrugGroupDetailRequestAction,
          {
            type: getDrugGroupDetailSuccessAction,
            payload: async (action, state, res) => {
              const data = await res.json();
              return data;
            },
          },
          getDrugGroupDetailFailureAction,
        ],
      },
    };

    return dispatch(rsaaObject);
  };

export const submitDrugGroupForReview =
  (drugGroupTrackerId, referrerNotes, referrerId) => (dispatch, getState) => {
    const drugGroupTrackerParams = {
      referrerNotes: referrerNotes,
      newOwnerUuid: referrerId,
    };
    const rsaaObject = updateDrugGroupTrackerStaus(
      drugGroupTrackerId,
      "SUBMIT_FOR_REVIEW",
      drugGroupTrackerParams
    );
    return dispatch(rsaaObject);
  };

export const unassignDrugGroup =
  (drugGroupTrackerId) => (dispatch, getState) => {
    const drugGroupTrackerParams = {};
    const rsaaObject = updateDrugGroupTrackerStaus(
      drugGroupTrackerId,
      "UN_ASSIGN",
      drugGroupTrackerParams
    );
    return dispatch(rsaaObject);
  };

export const autoReviewDrugGroup =
  (drugGroupTrackerId) => (dispatch, getState) => {
    const drugGroupTrackerParams = {};
    const rsaaObject = updateDrugGroupTrackerStaus(
      drugGroupTrackerId,
      "AUTO_REVIEW",
      drugGroupTrackerParams
    );
    return dispatch(rsaaObject);
  };

export const autoAssignDrugGroup =
  (drugGroupTrackerId) => (dispatch, getState) => {
    const drugGroupTrackerParams = {};
    const rsaaObject = updateDrugGroupTrackerStaus(
      drugGroupTrackerId,
      "AUTO_ASSIGN",
      drugGroupTrackerParams
    );
    return dispatch(rsaaObject);
  };

export const resetDrugGroupStatusState = () => (dispatch, getState) => {
  dispatch(RESET_DRUG_GROUP_STATUS());
};

export const handleResetDrugGroupDetail = () => (dispatch, getState) => {
  dispatch(resetDrugGroupDetail());
};

export const resetApprovalModalState = () => (dispatch, getState) => {
  dispatch(setDrugGroupApprovalModalStatus(false));
};

export const enableApprovalModalState = () => (dispatch, getState) => {
  dispatch(setDrugGroupApprovalModalStatus(true));
};

export const resetSwitchDetailFetchFlag = () => (dispatch, getState) => {
  dispatch(resetSwitchDetailFetch());
};

const updateDrugGroupTrackerStaus = (drugGroupTrackerId, status, body) => {
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/paw-group/drug-groups/update-status?drug-group-tracker-uuid=${drugGroupTrackerId}&status=${status}`,
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        updateDrugGroupDetailRequestAction,
        {
          type: updateDrugGroupDetailSuccessAction,
          payload: async (action, state, res) => {
            const data = {
              status: status,
            };
            return data;
          },
        },
        updateDrugGroupDetailFailureAction,
      ],
    },
  };
  return rsaaObject;
};

export const assignDrugGroupTracker =
  (drugGroupTrackerId, ownerId) => (dispatch, getState) => {
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/paw-group/drug-groups/assign?drug-group-tracker-uuid=${drugGroupTrackerId}&&user-uuid=${ownerId}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        types: [
          updateDrugGroupDetailRequestAction,
          {
            type: updateDrugGroupDetailSuccessAction,
            payload: async (action, state, res) => {
              const data = {
                status: "AUTO_ASSIGN",
              };
              return data;
            },
          },
          updateDrugGroupDetailFailureAction,
        ],
      },
    };
    return dispatch(rsaaObject);
  };

export const reopenDrugGroupTrackerStaus =
  (drugGroupTrackerId, reopenReason) => (dispatch, getState) => {
    const drugGroupTrackerParams = { reopenReason: reopenReason };
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/paw-group/drug-groups/reopen?drug-group-tracker-uuid=${drugGroupTrackerId}`,
        method: "PUT",
        body: JSON.stringify(drugGroupTrackerParams),
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        types: [
          updateDrugGroupDetailRequestAction,
          {
            type: updateDrugGroupDetailSuccessAction,
            payload: async (action, state, res) => {
              const data = {
                status: "reopen",
              };
              return data;
            },
          },
          updateDrugGroupDetailFailureAction,
        ],
      },
    };
    return dispatch(rsaaObject);
  };

export const updateWorkflowItemStatus =
  (workflowItemId, rejectionReason, status, comments = null) =>
  (dispatch, getState) => {
    let action = "MANUAL_PAW_APPROVE";
    let notes = {};
    if (status == "Rejected") {
      action = "MANUAL_PAW_REJECT";
      notes = { wfRejectionReason: rejectionReason, comments: comments };
    }

    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/paw-group/drug-groups/wf-item/${workflowItemId}?event=${action}`,
        method: "POST",
        body: JSON.stringify(notes),
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        types: [
          updateDrugGroupDetailRequestAction,
          {
            type: updateDrugGroupDetailSuccessAction,
            payload: async (action, state, res) => {
              const data = {
                status: status,
              };
              return data;
            },
          },
          updateDrugGroupDetailFailureAction,
        ],
      },
    };
    return dispatch(rsaaObject);
  };

//drug-groups/{drugGroupUuid}/switch-details
export const getDrugGroupSwitches =
  (drugGroupId, currentPage) => (dispatch, getState) => {
    // const sorted={id: "status", desc: false, isDefault: true};
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/paw-group/drug-groups/${drugGroupId}/switch-details`,
        method: "POST",
        body: JSON.stringify({
          pageSize: 1,
          page: currentPage,
          filtered: [],
          sorted: [],
        }),
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        types: [
          getDrugGroupSwitchesRequestAction,
          {
            type: getDrugGroupSwitchesSuccessAction,
            payload: async (action, state, res) => {
              const data = await res.json();
              return data;
            },
          },
          getDrugGroupSwitchesFailureAction,
        ],
      },
    };
    return dispatch(rsaaObject);
  };

export const disableDsgForDrugGroup = (dsgUUID) => (dispatch, getState) => {
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/paw-group/disable-dsg/${dsgUUID}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      types: [
        updateDrugGroupDetailRequestAction,
        {
          type: updateDrugGroupDetailSuccessAction,
          payload: async (action, state, res) => {
            const data = {
              status: "AUTO_REJECTED_BY_DSG",
            };
            return data;
          },
        },
        updateDrugGroupDetailFailureAction,
      ],
    },
  };
  return dispatch(rsaaObject);
};

export const getNextDrugGroup =
  (pawGroupId, drugGroupId) => (dispatch, getState) => {
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/paw-group/${pawGroupId}/drug-groups/${drugGroupId}/next`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        types: [
          getNextDrugGroupDetailRequestAction,
          {
            type: getDrugGroupDetailSuccessAction,
            payload: async (action, state, res) => {
              const data = await res.json();
              return data;
            },
          },
          getDrugGroupDetailFailureAction,
        ],
      },
    };

    return dispatch(rsaaObject);
  };
