import React from "react";
import Badge from "react-bootstrap/Badge";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Colors from "lookup/Colors.json";

import { formatPhoneNumber } from "services/UtilityService";

import "./Timeline.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InconclusiveBadge from "components/InconclusiveBadge";

// move these out to css
const styles = {
  dateStyle: {
    backgroundColor: "unset",
  },
  dateInnerStyle: {
    textIndent: 15,
    fontSize: "0.9em",
  },
  timelineTitle: {
    fontSize: "1.1em",
    letterSpacing: "1px",
    wordBreak: "break-word",
  },
  timelineBody: {
    fontSize: "0.85em",
  },
};
const getTimelineBodyForStatus = (data) => {
  if (data.status === "RECEIVED_PRESCRIBER_FAX") {
    return (
      <span id="recived-form">
        Received from: {formatPhoneNumber(data.from)}
        <br />
      </span>
    );
  } else if (data.status.indexOf("_FAX") >= 0) {
    if (data.status.indexOf("WAITING_") >= 0) {
      return (
        <Badge pill bg="warning">
          Activity created for missing fax
        </Badge>
      );
    } else if (data.status.indexOf("ERROR_") < 0) {
      return (
        <span id="recipient-number">
          Recipient: {formatPhoneNumber(data.to)}
        </span>
      );
    }
  } else if (data.status === "PENDING_RESPONSE_REVIEW") {
    return (
      <span id="forwarded-to-paw-for-response-evalutaion">
        Forwarded to PAW for response evaluation
      </span>
    );
  } else if (data.status.indexOf("_FOLLOWUP") >= 0) {
    return (
      <span id="container">
        <span id="assigned-for-response-evalutaion">
          Assigned to{" "}
          {data.status === "ACCEPTED_RESPONSE_FOLLOWUP" ? "SWIM" : "PAW"} for
          response evaluation
        </span>
        <InconclusiveBadge inconclusiveResult={data.inconclusiveResult} responseTypeList={data.responseTypeList}/>
        <br />
        {data.confirmedDisposition ? (
          <span id="confirmed-dispoition">
            Confirmed Disposition: &nbsp;
            <Badge
              pill
              style={{ textTransform: "capitalize" }}
              bg={
                data.confirmedDisposition === "DECLINED" ? "danger" : "success" //submitted via ehr and approved
              }
            >
              {data.confirmedDisposition}
            </Badge>
          </span>
        ) : null}
      </span>
    );
  } else if (data.status === "AWAITING_FILL_AND_PICKUP") {
    return (
      // <span>Since <u>{data.date}</u></span>
      null
    );
  } else if (data.status === "COMPLETED") {
    return (
      <span id="verified-through-claim">
        Verified through Claim # {data.claimNumber} received on{" "}
        {data.claimReceivedDate}
      </span>
    );
  } else if (data.status === "CLAIMS_RECEIVED") {
    return (
      <span id="claims-recevied-from">
        Claims received from {data.clientName} on {data.claimReceivedDate}
      </span>
    );
  } else return null;
};

export const StatusTimelineItem = (props) => {
  const { data, style } = props;
  return (
    <VerticalTimelineElement
      id={"timelineItemTitleContainer " + props.dateText}
      date={props.dateText} //date is actually Grouped State.
      style={style}
      contentArrowStyle={{ borderRight: `7px solid  ${style.color}` }}
      iconStyle={{ background: style.color, color: "#fff" }}
      dateClassName={"grouped-state-title"}
    >
      {!data ? null : (
        <span>
          <div
            id={"timelineStatusItem " + data.status}
            style={styles.timelineTitle}
          >
            {data.status}
          </div>
          {props.showDate ? (
            <div className="text-muted">{data.date}</div>
          ) : null}
          <div style={styles.timelineBody}>
            {getTimelineBodyForStatus(data)}
          </div>
        </span>
      )}
      {props.children}
    </VerticalTimelineElement>
  );
};

export const Timeline = (props) => {
  return (
    <VerticalTimeline
      lineColor={Colors["rm-gray-shadow"]}
      className="rm-timeline"
      layout="1-column-left"
    >
      {props.children}
    </VerticalTimeline>
  );
};
