import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  searchDrugs,
  updateDrugListState,
  resetError,
} from "./DrugSearch.action";
import { withRouter } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Tip from "components/Tip";
import debounce from "lodash/debounce";
import { WrapTextOnNoSpace } from "components/WrapText";
import { showNotification } from "services/NotificationService";
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { ColumnTypes } from 'commons/table/Constants';

const DEFAULT_PAGE_SIZE = 15;

class DrugSearch extends React.Component {
  state = {
    loading: true,
    data: this.props.data,
    pages: this.props.pages,
    totalElements: this.props.totalElements,
    searchTerm: this.props.searchTerm,
    page: this.props.page,
    pageSize: this.props.pageSize,
    codexVersionId: this.props.codexVersionId,
    isWorkingVersionEditable: this.props.isWorkingVersionEditable,
  };

  filtered = [];
  sorted = [];

  updateData = (page, pageSize, searchTerm, preventUpdate) => {
    if (!preventUpdate) {
      console.log('updateData', page);
      this.props.searchDrugs(pageSize, page, searchTerm);
    }
    this.props.updateDrugListState(page, pageSize);
  };

  onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    this.updateData(pageNo, pageSize, this.state.searchTerm);
  }

  componentDidMount() {
    this.updateData(0, this.state.pageSize, this.state.searchTerm);
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      pages,
      totalElements,
      page,
      pageSize,
      loading,
      errorFetchingList,
      codexVersionId,
      isWorkingVersionEditable,
      resetError,
    } = this.props;

    if (
      prevProps.data !== data ||
      prevProps.pages !== pages ||
      prevProps.totalElements !== totalElements ||
      prevProps.page !== page ||
      prevProps.loading !== loading ||
      prevProps.codexVersionId !== codexVersionId ||
      prevProps.isWorkingVersionEditable !== isWorkingVersionEditable || 
      prevProps.pageSize !== pageSize
    ) {
      console.log('prev', prevProps.page, page)
      this.setState({
        data,
        pages,
        totalElements,
        loading,
        page,
        pageSize,
        codexVersionId,
        isWorkingVersionEditable,
      });
    }

    if (errorFetchingList === true && prevProps.errorFetchingList !== errorFetchingList) {
      showNotification({
        title: "Error fetching the results!",
        message: "There appears to be an issue while fetching the search results.",
        position: "bl",
        type: "error",
      });
      resetError();
    }
  }


  handleSearchTermChange = debounce((searchTerm) => {
    this.setState({ searchTerm });
    console.log('handleSearchTermChange');
    this.updateData(0, this.state.pageSize, searchTerm); // reset page whenever search term changes
  }, 500);

  render() {
    const {
      data,
      pages,
      page,
      pageSize,
      loading,
      totalElements,
      searchTerm,
      isWorkingVersionEditable,
    } = this.state;
    console.log('lll',this.state)
    const allColumnsSortable = false;
    const allColumnsFilterable = false;

    const renderHighlightedCell = (value, row, accessor) => {
      return (
        <span>
          {row.original?.highlightedFields &&
            row.original?.highlightedFields[accessor] ? (
            <WrapTextOnNoSpace>
              <span
                dangerouslySetInnerHTML={{
                  __html: row.original.highlightedFields[accessor],
                }}
              ></span>
            </WrapTextOnNoSpace>
          ) : (
            <WrapTextOnNoSpace>{value ? value : "--"}</WrapTextOnNoSpace>
          )}
        </span>
      );
    };

    const columns = [
      {
        header: "Group Name",
        accessor: "dynamicSwitchGroupName",
        accessorKey: "dynamicSwitchGroupName",
        width: 100,
        enableSorting: allColumnsSortable,
        enableColumnFilter: allColumnsFilterable,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return renderHighlightedCell(value, row, "dynamicSwitchGroupName")
        },
      },
      {
        header: "Drug Name",
        accessor: "medName",
        accessorKey: "medName",
        width: 100,
        enableSorting: allColumnsSortable,
        enableColumnFilter: allColumnsFilterable,
        columnType: ColumnTypes.Custom,
        customComponent: (value, r) => {
          return renderHighlightedCell(value, r, "medName")
        },
      },
      {
        header: "Form",
        accessor: "medForm",
        accessorKey: "medForm",
        width: 140,
        enableSorting: allColumnsSortable,
        enableColumnFilter: allColumnsFilterable,
        columnType: ColumnTypes.Custom,
        customComponent: (value, r) => {
          return renderHighlightedCell(value, r, "medForm")
        },
      },
      {
        header: "ROA",
        accessor: "routeOfAdministration",
        accessorKey: "routeOfAdministration",
        width: 70,
        enableSorting: allColumnsSortable,
        enableColumnFilter: allColumnsFilterable,
        columnType: ColumnTypes.Custom,
        customComponent: (value, r) => {
          return renderHighlightedCell(value, r, "routeOfAdministration")
        },
      },
      {
        header: "Display Name",
        accessor: "faxDisplayName",
        accessorKey: "faxDisplayName",
        width: 70,
        enableSorting: allColumnsSortable,
        enableColumnFilter: allColumnsFilterable,
        columnType: ColumnTypes.Custom,
        customComponent: (value, r) => {
          return renderHighlightedCell(value, r, "faxDisplayName")
        },
      },
      {
        header: "Active Strengths",
        accessor: "strengthStr",
        accessorKey: "strengthStr",
        enableSorting: allColumnsSortable,
        enableColumnFilter: allColumnsFilterable,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Type",
        accessor: "itemType",
        accessorKey: "itemType",
        width: 80,
        enableSorting: allColumnsSortable,
        enableColumnFilter: allColumnsFilterable,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Brand/Gen",
        accessor: "brandGenType",
        accessorKey: "brandGenType",
        width: 90,
        enableSorting: allColumnsSortable,
        enableColumnFilter: allColumnsFilterable,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
    ];
    return (
      <Container className="list-container" fluid>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Search Drugs</Form.Label>
              <Form.Control
                // value={searchTerm}
                name="searchBar"
                placeholder="e.g. Trulicity Tablet"
                disabled={loading}
                id="searchBar"
                ref={(input) => input && input.focus()}
                onChange={(e) => this.handleSearchTermChange(e.target.value)}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="tiny-bottom-spacer">
          <Col md={6}>
            Total Items:{" "}
            <strong id="sllist-total-count">{totalElements}</strong>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ServerSideReactTable
              columnDefination={columns}
              data={data}
              currentPage={page}
              sorted={this.sorted}
              filters={this.filtered}
              filterable={false}
              totalPages={pages}
              totalRecords={totalElements == "loading" ? 0 : totalElements}
              loading={loading}
              enableMultiRowSelection={false}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              onUpdateTableData={this.onUpdateTable}
              pageSizeOptions={[5, 10, 15, 20, 30, 50]}
              onRowClick={(rowInfo) => {
                if (rowInfo !== undefined) {
                  this.props.history.push(
                    `/cat/dynamic-switch-groups/${rowInfo.original.dynamicSwitchGroupId}`
                  );
                }
              }}
            />
            <Tip>
              Tip: Hold shift when sorting to multi-sort and disable sort!
            </Tip>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.drugSearch.data !== undefined ? state.drugSearch.data : [],
    pages: state.drugSearch.pages ? state.drugSearch.pages : 0,
    totalElements:
      state.drugSearch.totalElements !== undefined
        ? state.drugSearch.totalElements
        : "Loading ...",
    loading: state.drugSearch.loading,
    searchTerm: state.drugSearch.searchTerm ? state.drugSearch.searchTerm : "",
    errorFetchingList: state.drugSearch.errorFetchingList,
    page: state.drugSearch.page ? state.drugSearch.page : 0,
    pageSize: state.drugSearch.pageSize ? state.drugSearch.pageSize : DEFAULT_PAGE_SIZE,
    codexVersionId: state.codexVersion
      ? state.codexVersion.codexVersionId
      : null,
    isWorkingVersionEditable: state.codexVersion
      ? state.codexVersion.isWorkingVersionEditable
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchDrugs: bindActionCreators(searchDrugs, dispatch),
    updateDrugListState: bindActionCreators(updateDrugListState, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DrugSearch));
