import React from 'react';
import { formatDateForDisplay } from 'services/MomentUtils';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

const BatchOrCycle = (props) => (
  <Card>
    <Card.Header>{props.type} &nbsp; <Badge bg={props.data.status === 'OPEN' ? 'warning' : 'success'}>{props.data.status}</Badge></Card.Header>
    <Card.Body>
      <Card.Text>
        <strong>Description: </strong> {props.data.description} <br />
        <strong>Scheduled on: </strong> {formatDateForDisplay(props.data.dateScheduled)}
      </Card.Text>
    </Card.Body>
  </Card>
);

export default BatchOrCycle;