import ClaimInfoDialog from "components/PawGroups/ClaimInfoDialog";
import DrugGroupCard from "components/PawGroups/DrugGroupCard";
import SubmitForReviewPaw from "components/PawGroups/SubmitForReview";
import UnclaimPaw from "components/PawStatus/UnclaimPaw";
import DivLoader from "layouts/components/DivLoader";
import { PawConstants } from "lookup/PawConstants";
import { Button, Col, Row } from "react-bootstrap";
import { Card } from "react-bootstrap/esm";
import { useSelector } from "react-redux";
import { getLoggedInUser } from "services/AuthService";
import {
  status,
  statusBadgeColors,
} from "views/Paw/PawGroup/PawGroup.constants";
import { hasRoleIn } from "../../rbac/RbacHelper";
import ROLES from "../../rbac/Roles.json";
import {
  DRUG_GROUP_STATUSES,
  STATUS_BADGE_COLORS,
} from "../../views/Paw/DrugGroup/DrugGroup.constants";
import "./DrugDetails.css";
import ReOpenDialog from "./ReopenDialog";

const DrugDetails = ({
  getNextDrugGroup,
  pawGroupStatus,
  onSubmitReview,
  onUnclaimPAW,
  onReopenDrugGroup,
  target,
  onAutoReview,
}) => {
  const drugDetails = useSelector(
    (state) => state.drugGroupDetail.drugGroupDetail
  );
  const approvalCount = drugDetails.remainingSwitchCountForAutoApproval;
  const rejectionCount = drugDetails.remainingSwitchCountForAutoRejection;
  const isDrugGroupOpen =
    drugDetails.status == "OPEN" ||
    drugDetails.status == "SUBMITTED_FOR_REVIEW" ||
    drugDetails.status == "REOPEN";
  const loading = useSelector(
    (state) => state.drugGroupDetail.drugDetailLoader
  );

  let currentUserId = getLoggedInUser().uuid;
  let isPAWAdmin = hasRoleIn([ROLES.PAW_ADMIN]);
  const isCurrentUserOwner = currentUserId == drugDetails.ownerId;
  const statusAction = useSelector(
    (state) => state.drugGroupDetail.statusAction
  );
  const getTriggerCount = () => {
    if (approvalCount < rejectionCount) {
      return approvalCount;
    } else {
      return rejectionCount;
    }
  };
  const getTriggerColor = () => {
    if (approvalCount <= rejectionCount) {
      return PawConstants.statusColors.Approved;
    } else {
      return PawConstants.statusColors.Rejected;
    }
  };

  return (
    <Col md={12} lg={5}>
      <DivLoader isLoading={loading}>
        {isDrugGroupOpen && getTriggerCount() > 0 ? (
          <Card className={"progress-container"}>
            <Card.Header className={"card-header"}>
              Auto Review will trigger after:
            </Card.Header>
            <div className={"progress-bar"}>
              <h1 style={{ color: getTriggerColor() }}>{getTriggerCount()}</h1>
              <Card.Header className={"card-header"}>SWITCHES</Card.Header>
            </div>
          </Card>
        ) : isDrugGroupOpen && isCurrentUserOwner ? (
          <Row className="align-self-center">
            <Col className="align-self-stretch auto-review-button">
              <Button onClick={onAutoReview} className={"button"}>
                {approvalCount <= rejectionCount
                  ? "Trigger Auto Approval"
                  : "Trigger Auto Reject"}
              </Button>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col>
            <div className={"next-drug-group-container"}>
              <Button className={"button"} onClick={getNextDrugGroup}>
                {"Go to Next Drug Group"}
              </Button>
            </div>
          </Col>
        </Row>
        <div
          className={
            drugDetails.status != "OPEN" ? "full-container" : "container"
          }
        >
          <Row md={1}>
            <Col className={"d-flex align-items-center"}>
              <ClaimInfoDialog
                ownerId={drugDetails?.ownerName == null ? 0 : 1}
                ownerName={drugDetails?.ownerName}
                status={drugDetails?.status}
                className={"btn"}
              />
            </Col>
          </Row>
          {!isDrugGroupOpen ? (
            <Row className="top-spacer">
              <Col>
                <Card
                  style={
                    pawGroupStatus
                      ? { borderColor: statusBadgeColors[pawGroupStatus] }
                      : {
                          borderColor: STATUS_BADGE_COLORS[drugDetails?.status],
                        }
                  }
                >
                  <Card.Body className="status-box py-2">
                    <Col md={12} className="text-center justify-content-center">
                      Status:
                      <strong
                        className="status-text"
                        style={
                          pawGroupStatus
                            ? { color: statusBadgeColors[pawGroupStatus] }
                            : {
                                color: STATUS_BADGE_COLORS[drugDetails?.status],
                              }
                        }
                      >
                        {pawGroupStatus
                          ? status[pawGroupStatus]
                          : DRUG_GROUP_STATUSES[drugDetails?.status]}
                      </strong>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <DrugGroupCard switchInfo={drugDetails} target={target} />
              {drugDetails.status === "AUTO_REJECTED_BY_DSG" ? (
                <div></div>
              ) : drugDetails.status ===
                "MANUALLY_REVIEWED" ? null : isDrugGroupOpen ? (
                <Row className="justify-content-between py-3">
                  <Col xs={6}>
                    <UnclaimPaw
                      attemptUnclaim={onUnclaimPAW}
                      disallowEditing={!isCurrentUserOwner}
                    />
                  </Col>
                  <Col xs={6}>
                    <SubmitForReviewPaw
                      disallowEditing={!isCurrentUserOwner}
                      attemptStatusUpdate={onSubmitReview}
                    />
                  </Col>
                </Row>
              ) : drugDetails.status != "AUTO_REJECTED_BY_DSG" ? (
                <Row className="d-flex justify-content-center py-3 reopen-button">
                  <ReOpenDialog
                    reOpenDrugGroup={onReopenDrugGroup}
                    disallowEditing={!isPAWAdmin}
                  />
                </Row>
              ) : null}

              {drugDetails.referrerName == null ? null : (
                <div>
                  <p>
                    <b>Referred By : </b>
                    <span>{drugDetails.referrerName}</span>
                  </p>
                  <p>
                    <b>Referred Notes : </b>
                    <span>{drugDetails.referrerNotes}</span>
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </DivLoader>
    </Col>
  );
};
export default DrugDetails;
