import React from "react";
import Button from "react-bootstrap/Button";

export default function ResetListState(props) {
  const { resetterDisabled, onReset, placedOnLightBg } = props;
  let classOnLightBg = "";
  if (placedOnLightBg) {
    if (resetterDisabled) {
      classOnLightBg = "disabled-button-on-light-bg";
    } else {
      classOnLightBg = "enabled-button-on-light-bg";
    }
  }

  return (
    <>
      <Button
        size="sm"
        id="reset-state-btn-filter"
        data-testid="btn-reset-list"
        variant="outline-light"
        onClick={onReset}
        disabled={resetterDisabled}
        className={`float-right ${classOnLightBg}`}
      >
        Reset List{" "}
      </Button>
      <div style={{ clear: "both" }}></div>
    </>
  );
}
