import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'react-bootstrap/Alert';


const MessageBanner = ({
  message = "",
  isDisplayMessage = false,
  icon = undefined,
}) => {
  return (
    <div>
      {
        isDisplayMessage ?
          <Alert variant="warning" style={{ marginBottom: "10px" }}>
            <FontAwesomeIcon icon={icon} />
            &nbsp; {message}
          </Alert> : null
      }
    </div>
  );
}

export default MessageBanner;