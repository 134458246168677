import { withRouter } from "react-router";
import DrugDetails from "components/CallGroupDetails/DrugDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { isStringEmptyOrNull } from "services/UtilityService";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import CloseActivityModal from "components/CallGroupDetails/CloseActivityModal";
import ButtonWithPopover from "components/ButtonWithPopover";
import { Button, Badge } from "react-bootstrap";
import { activityEventEmitter } from "components/CallGroupDetails/ActivityEventEmitter";
import { useRef, useState } from "react";
import {
  isLoggedInUserAWAdmin,
  isOwnedByLoggedInUser,
  goBackIfCallGroupClosed,
  redirectToListingPage
} from "services/AWUtilityService";
import "assets/css/agentworkspace.css";
import ExpandSwitchTimeline from "./ExpandSwitchTimeline";
import ExpandActivityHistory from "./ExpandActivityHistory";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";

const BottomBar = (props) => {
  const {
    activityDetails,
    areActionsCallable,
    callGroupData,
    data,
    showPrescriberColumn,
  } = props;
  const reopenRef = useRef("reopen");
  const openSwim = () => {
    props.history.push(`/switch/${activityDetails.wfItem.wfItemId}`);
  };

  const reopenActivitySchema = Yup.object().shape(
    {
      note: Yup.string().required("Required!").max(4096, "Max Length: 4096")
    },
    [
      [
        "note"
      ]
    ]
  );

  const submitForm = (originalValues, setSubmitting, closeModal) => {
    // it's best to create a clone because params are passed through reference
    const {
      note,
      activityId
    } = originalValues;
    const event = "REOPEN";
    activityEventEmitter(
      { note, version: activityDetails.version, data, event },
      activityId,
      {
        setSubmitting: setSubmitting,
        closeModal: closeModal,
        refreshActivityList: props.updateData,
        refreshActivity: props.refreshActivity,
        resetActivityUponClose: props.resetActivityUponClose,
        goBackIfCallGroupClosed: goBackIfCallGroupClosed.bind(
          this,
          data,
          props.history
        ),
      },
      "SAME_ACTIVITY_ALREADY_OPEN"
    );
  };

  const handleLinkedActivityClick = () => {
    props.history.push({
      pathname: `/agent-workspace/call-group-details/${activityDetails.linkedActivity
        ? activityDetails.linkedActivity.callGroupId
        : ""
        }`,
      state: {
        status: activityDetails.linkedActivity
          ? activityDetails.linkedActivity.status
          : "OPEN",
        navigation: AgentWorkspaceConstants.LINKED_ACTIVITY,
      },
    });
  }

  return (
    <>
      <Row>
        <Col md={3}>
          {activityDetails.status === "CLOSED" ||
            activityDetails.status === "ON_HOLD" ? (
            <ButtonWithPopover
              placement="top"
              customRef={reopenRef}
              buttonVariant="success"
              buttonText="Reopen Activity"
              buttonIsBlock={true}
              buttonIsDisabled={!isOwnedByLoggedInUser(callGroupData)}
              popoverTitle="Reopen Activity"
              buttonId="reopen-activity"
              buttonClassName="btn-text-white btn-block"
              dataTestId="btn-reopen-activity"
            >
              <p className="confirmation-text">
                Do you want to <b>reopen</b> this activity?
              </p>

              <Formik
                initialValues={{
                  note: "",
                  activityId: activityDetails.activityId,
                  // closeModal: reopenRef.current.handleClose
                }}
                onSubmit={(values, { setSubmitting, setValues }) => {
                  submitForm(values, setSubmitting, reopenRef.current.handleClose);
                }}
                validationSchema={reopenActivitySchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  dirty,
                  isValid,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <Form.Group>
                          <Form.Label>Notes</Form.Label>
                          <Form.Control
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.note}
                            name="note"
                            as="textarea"
                            data-testid="input-reopen-activity-notes"
                          />
                          <Form.Text className="text-small text-danger">
                            {errors.note && touched.note && errors.note}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="align-content-right">
                      <Button
                        id="reopen-activity-btn-save"
                        type="submit"
                        className="left-spacer"
                        variant="success"
                        disabled={isSubmitting || !(dirty && isValid)}
                        block
                        onClick={handleSubmit}
                        data-testid="btn-reopen-activity-submit"
                      >
                        {isSubmitting ? "Updating ... " : "Yes"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </ButtonWithPopover>
          ) : (
            <CloseActivityModal
              data={data} // passing all ids in call group
              updateData={props.updateData}
              callGroupId={callGroupData.callGroupId}
              activityState={activityDetails.state}
              activityStatus={activityDetails.status}
              activityName={activityDetails.activityName}
              activityType={activityDetails.type}
              entityType={activityDetails.entityType}
              globalSwitchId={activityDetails.wfItem.switchInfo.globalSwitchId}
              isMemberActivityCreatable={
                activityDetails.isMemberActivityCreatable
              }
              activityVersion={activityDetails.version}
              prescriberName={activityDetails.wfItem.switchInfo.prescriberInfo.prescriberName}
              pharmacyName={activityDetails.wfItem.switchInfo.pharmaInfo.pharmacyName}
              memberNumber={activityDetails.wfItem.switchInfo.rirMemberInfo.memberNumber}
              isActionCallable={areActionsCallable}
              activityId={activityDetails.activityId}
              callCounter={activityDetails.callCounter}
              resetActivityUponClose={() => props.resetActivityUponClose()}
              refreshActivityList={props.updateData.bind(this)}
              refreshActivity={() => props.refreshActivity()}
              goBackIfCallGroupClosed={goBackIfCallGroupClosed.bind(
                this,
                data,
                props.history
              )}
              goBackIfNoActionTakenOrGroupClose={redirectToListingPage.bind(
                this,
                props.history
              )}
            />
          )}
        </Col>
        <Col md={3}>
          {isLoggedInUserAWAdmin() && (
            <Button
              variant="info"
              className="btn-text-white btn-block"
              onClick={() => openSwim()}
              data-testid="btn-activity-open-swim"
            >
              Open in SWIM
            </Button>
          )}
        </Col>
        <Col md={3}>
          <ExpandSwitchTimeline
            activityId={activityDetails.activityId}
            switchData={activityDetails.wfItem}
            isFromAW={true}
            isAWAdmin={isLoggedInUserAWAdmin()}
          />
        </Col>
        <Col md={3}>
          <ExpandActivityHistory activityId={activityDetails.activityId} />
        </Col>
      </Row>
      <Row className="tiny-top-spacer">
        <Col md={6} className="content-centered-col">
          {
            <div className="boxed-div content-align-start">
              <div className="tiny-top-spacer" data-testid="text-health-plan">
                <strong>Health Plan:</strong> {activityDetails.programName}
              </div>
            </div>
          }
          {!isStringEmptyOrNull(activityDetails.state) &&
            activityDetails.state && (
              <div className="boxed-div content-align-start">
                <div data-testid="text-activity-state">
                  <strong>Activity State:</strong> {activityDetails.state}
                </div>
              </div>
            )}
          {activityDetails.isManuallyCreated ? (
            <Badge bg="warning" size="lg" className="ml-1 mt-1 pull-left" data-testid="badge-manually-created">
              {AgentWorkspaceConstants.MANUALLY_CREATED}
            </Badge>
          ) : null}
          {activityDetails.wfItem.isCallActivityCreationBlocked ? (
            <Badge bg="info" size="lg" className="ml-1 mt-1 pull-left">
              {AgentWorkspaceConstants.SWIM_SWITCH}
            </Badge>
          ) : null}
          {activityDetails.linkedActivity ? (
            <Badge onClick={handleLinkedActivityClick.bind(this)} data-testid="badge-linked-activity" bg="success" size="lg" className="link-text ml-1 mt-1 pull-left">
              {activityDetails.isManuallyCreated ? "Go to Parent" : "Go to Child"}
            </Badge>
          ) : null}
          {activityDetails.isFaxUnlinked ? (
            <Badge bg="warning" size="lg" className="ml-1 mt-1 pull-left" data-testid="badge-fax-unlinked">
              {AgentWorkspaceConstants.UNLINKED}
            </Badge>
          ) : null}
          {activityDetails.multipleActivities ? (
            <Badge bg="info" size="lg" className="ml-1 mt-1 pull-left" data-testid="badge-multiple-activities">
              {AgentWorkspaceConstants.MULTIPLE_ACTIVTIES}
            </Badge>
          ) : null}
        </Col>
        <Col md={{ span: 4, offset: 2 }} className="content-centered-col">
          <div className="tiny-top-spacer boxed-div content-align-start">
            <DrugDetails
              sourceDrug={activityDetails.wfItem.switchInfo.claimDrugName}
              targetDrugs={activityDetails.wfItem.switchInfo.targetOptions}
              selectedTarget={activityDetails.wfItem.switchInfo.selectedTarget}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(BottomBar);
