import React, { useState, useRef } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import colors from "lookup/Colors";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import DismissableModal from "components/DismissableModal";
import {
  getErrorMessage,
  isObjectEmpty,
  isStringEmptyOrNull,
} from "services/UtilityService";
import CONTACTMANAGEMENT_CONSTANTS from "lookup/ContactManagementConstants";
import PharmaciesList from "./PharmaciesList";
import { showNotification } from "services/NotificationService";

function SwitchPharmacyInfo(props) {
  const [selectedPharmacy, setSelectedPharmacy] = useState(props.pharmacy);
  const [isCreating, setIsCreating] = useState(false);

  const UpdateInfoFormSchema = Yup.object().shape({
    pharmacyName: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
    pharmacyFax: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .required("Fax is required"),
    pharmacyPhone: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .required("Phone is required"),
    pharmacyNpi: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .length(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldLengthValidationPrefix + "10"
      )
      .required("Npi is required"),
    pharmacyAddressLine1: Yup.string().max(
      255,
      CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
    ),
  });

  const CreateInfoFormSchema = Yup.object().shape({
    pharmacyName: Yup.string()
      .max(
        255,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
      )
      .required("Name is required"),
    pharmacyFax: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .required("Fax is required"),
    pharmacyPhone: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .max(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "10"
      )
      .min(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldMinLengthValidationPrefix + "10"
      )
      .required("Phone is required"),
    pharmacyNpi: Yup.string()
      .matches(/^\d*$/, CONTACTMANAGEMENT_CONSTANTS.numberValidationError)
      .length(
        10,
        CONTACTMANAGEMENT_CONSTANTS.fieldLengthValidationPrefix + "10"
      )
      .required("Npi is required"),
    pharmacyAddressLine1: Yup.string()
      .max(
        255,
        CONTACTMANAGEMENT_CONSTANTS.fieldMaxLengthValidationPrefix + "255"
      )
      .required("Address is required"),
  });
  const isFieldRequired = (key) =>
    isCreating && CreateInfoFormSchema.fields[key]
      ? CreateInfoFormSchema.fields[key].exclusiveTests.required
      : false;

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      ...selectedPharmacy,
    },

    onSubmit: (values, { setSubmitting, setValues }) => {
      values.pharmacyAddress = values.pharmacyAddressLine1;
      if (isCreating) {
        addPharmacy(
          values,
          setSubmitting,
          setValues,
          props.pharmacy,
          modalRef.current.handleClose
        );
      } else {
        switchPharmacy(
          selectedPharmacy,
          setSubmitting,
          setValues,
          props.pharmacy,
          modalRef.current.handleClose
        );
      }
    },
    validationSchema: isCreating ? CreateInfoFormSchema : UpdateInfoFormSchema,
  });

  function addPharmacy(
    originalValues,
    setSubmitting,
    setValues,
    oldPharmacy,
    closeModal
  ) {
    // it's best to create a clone because params are passed through reference
    const values = cloneDeep(originalValues);
    if (
      isStringEmptyOrNull(values.pharmacyName) ||
      isStringEmptyOrNull(values.pharmacyNpi) ||
      isStringEmptyOrNull(values.pharmacyFax) ||
      isStringEmptyOrNull(values.pharmacyPhone) ||
      isStringEmptyOrNull(values.pharmacyAddressLine1)
    ) {
      showNotification({
        title: "Invalid Change Detected!",
        message: `Provide all required fields.`,
        position: "tr",
        type: "warning",
      });
      setSubmitting(false);
      return;
    }

    sendAuthenticatedAsyncRequest(
      `/contact-management/add-and-switch-pharmacy/${props.wfItemId}`,
      "POST",
      values,
      (r) => {
        setSubmitting(false);
        const updatePharmacyInfoDto = r.data;
        closeModal();
        if (props.onSubmission && typeof props.onSubmission === "function") {
          props.onSubmission(updatePharmacyInfoDto);
        }

        // dispatch success notification
        showNotification({
          title: `Successful Update!`,
          message: `Pharmacy Info updated successfully`,
          position: "tr",
          type: "success",
        });
        clearForm();
      },
      (r) => {
        setSubmitting(false);
        // dispatch failure notification
        showNotification({
          title: "Could not change Pharmacy!",
          message:
            getErrorMessage(r.data) ||
            getErrorMessage("FAILED_TO_UPDATE_PHARMACY_INFO"),
          position: "tr",
          type: "error",
        });
      }
    );
  }

  function switchPharmacy(
    originalValues,
    setSubmitting,
    setValues,
    oldPharmacy,
    closeModal
  ) {
    // it's best to create a clone because params are passed through reference
    const values = cloneDeep(originalValues);

    if (
      isStringEmptyOrNull(values.pharmacyNpi) ||
      isStringEmptyOrNull(values.pharmacyFax) ||
      isStringEmptyOrNull(values.pharmacyPhone)
    ) {
      showNotification({
        title: "Invalid Change Detected!",
        message: `NPI, Fax and phone numbers cannot be empty.`,
        position: "tr",
        autoDismiss: 5,
        type: "warning",
      });
      setSubmitting(false);
      return;
    }
    if (
      oldPharmacy.pharmacyAddressLine1 === values.pharmacyAddressLine1 &&
      oldPharmacy.pharmacyName === values.pharmacyName &&
      oldPharmacy.pharmacyFax === values.pharmacyFax &&
      oldPharmacy.pharmacyPhone === values.pharmacyPhone &&
      oldPharmacy.pharmacyNpi === values.pharmacyNpi
    ) {
      showNotification({
        title: "No Switch Detected!",
        message: `You are trying to switch to already selected pharmacy`,
        position: "tr",
        autoDismiss: 5,
        type: "warning",
      });
      setSubmitting(false);
      return;
    }

    sendAuthenticatedAsyncRequest(
      `/contact-management/switch-pharmacy/${props.wfItemId}/pharmacy/${originalValues.pharmacyInfoUuid}`,
      "PUT",
      values,
      (r) => {
        setSubmitting(false);
        const updatePharmacyInfoDto = r.data;
        closeModal();
        if (props.onSubmission && typeof props.onSubmission === "function") {
          props.onSubmission(updatePharmacyInfoDto);
        }

        // dispatch success notification
        showNotification({
          title: `Successful Switch!`,
          message: `Pharmacy switched successfully`,
          position: "tr",
          type: "success",
        });
      },
      (r) => {
        setSubmitting(false);
        setValues(oldPharmacy);
        // dispatch failure notification
        showNotification({
          title: "Could not change Pharmacy!",
          message:
            getErrorMessage(r.data) ||
            getErrorMessage("FAILED_TO_UPDATE_PHARMACY_INFO"),
          position: "tr",
          type: "error",
        });
      }
    );
  }

  const handleSpecialOpen = (handleRealOpen) => {
    handleRealOpen();
  };

  const onChange = (value, field, setFieldValue) => {
    const numberRegex = /^[0-9\b]+$/;
    value = value.replace(/\s/g, "");

    if (value === "" || numberRegex.test(value)) {
      setFieldValue(field, value);
    }
  };

  const clearForm = () => {
    setFieldValue("pharmacyName", "");
    setFieldValue("pharmacyPhone", "");
    setFieldValue("pharmacyAddressLine1", "");
    setFieldValue("pharmacyFax", "");
    setFieldValue("pharmacyNpi", "");
    setSelectedPharmacy(null);
  };

  const handleSelectionChange = (selectedPharmacyValue) => {
    setFieldValue("pharmacyName", selectedPharmacyValue.pharmacyName ?? "");
    setFieldValue("pharmacyPhone", selectedPharmacyValue.pharmacyPhone ?? "");
    setFieldValue(
      "pharmacyAddressLine1",
      selectedPharmacyValue.pharmacyAddressLine1 ?? ""
    );
    setFieldValue("pharmacyNpi", selectedPharmacyValue.pharmacyNpi ?? "");
    setFieldValue("pharmacyFax", selectedPharmacyValue.pharmacyFax ?? "");
    setSelectedPharmacy(selectedPharmacyValue);
  };

  const { isDisabled } = props;
  const title = "Switch Pharmacy";
  const modalRef = useRef(
    "UpdateInfoModal " +
      ((selectedPharmacy && selectedPharmacy.pharmacyGlobalId) || "New")
  );
  const modalOpener = (
    <Button
      size="sm"
      variant="primary"
      style={{ marginLeft: "10px", marginBlock: "5px" }}
      key={1}
      disabled={
        (selectedPharmacy && isObjectEmpty(selectedPharmacy)) || isDisabled
      }
      onClick={() => {
        setIsCreating(false);
        setSelectedPharmacy(props.pharmacy);
        handleSelectionChange(props.pharmacy);
        handleSpecialOpen(modalRef.current.handleShow);
      }}
      data-testid='btn-pharmacy-switch'
    >
      Change
    </Button>
  );

  const getButtonText = () => {
    let buttonText = "";
    if (isSubmitting && !isCreating) {
      buttonText = "Switching ... ";
    } else if (isSubmitting && isCreating) {
      buttonText = "Creating...";
    } else if (!isSubmitting && isCreating) {
      buttonText = "Create and Switch";
    } else {
      buttonText = "Switch";
    }
    return buttonText;
  };

  return (
    <DismissableModal
      ref={modalRef}
      openModalButton={modalOpener}
      title={title}
      size="xl"
      preventCloseWhenClickedOutsideModal={true}
      hideFooter={true}
    >
      <Row
        style={{ margin: "10px", fontSize: "15px", color: colors["rm-orange"] }}
      >
        <p>
          {isCreating
            ? "Switch to an already present pharmacy?"
            : "Don't see the pharmacy you want to switch to?"}
          <span
            onClick={() => {
              if (isCreating) {
                setSelectedPharmacy(props.pharmacy);
                handleSelectionChange(props.pharmacy);
              } else {
                setSelectedPharmacy(null);
                clearForm();
              }
              setIsCreating((prev) => !prev);
            }}
            style={{
              backgroundColor: "transparent",
              color: "gray",
              textDecoration: "underline",
              border: "none",
              marginBlock: "2px",
            }}
            data-testid={`link-pharmacy-switch-${isCreating ?  'existing': "new"}`}
          >
            &nbsp;
            {isCreating ? "Update to Existing" : "Add new Pharmacy"}
          </span>
        </p>
      </Row>
      <Container>
        {!isCreating && (
          <Row>
            <span
              style={{
                backgroundColor: "transparent",
                color: "gray",
                border: "none",
                marginBlock: "15px",
                fontSize: "15px",
              }}
            >
              Select a pharmacy from the table below:{" "}
            </span>
          </Row>
        )}

        <Form onSubmit={handleSubmit} context={{ isCreating }}>
          {isCreating && (
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label
                    className={
                      isFieldRequired("pharmacyNpi") ? "rm-required-field" : ""
                    }
                  >
                    Pharmacy NPI
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pharmacyNpi}
                    name="pharmacyNpi"
                    data-testid='input-pharmacy-create-npi'
                  />
                  <Form.Text className="text-small text-danger">
                    {errors.pharmacyNpi &&
                      touched.pharmacyNpi &&
                      errors.pharmacyNpi}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label
                  className={
                    isFieldRequired("pharmacyName") ? "rm-required-field" : ""
                  }
                >
                  Pharmacy Name
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pharmacyName}
                  name="pharmacyName"
                  disabled={!isCreating}
                  data-testid='input-pharmacy-create-name'
                />
                <Form.Text className="text-small text-danger">
                  {errors.pharmacyName &&
                    touched.pharmacyName &&
                    errors.pharmacyName}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label
                  className={
                    isFieldRequired("pharmacyAddressLine1")
                      ? "rm-required-field"
                      : ""
                  }
                >
                  Pharmacy Address
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pharmacyAddressLine1}
                  name="pharmacyAddressLine1"
                  disabled={!isCreating}
                  data-testid='input-pharmacy-create-address'
                />
                <Form.Text className="text-small text-danger">
                  {errors.pharmacyAddressLine1 &&
                    touched.pharmacyAddressLine1 &&
                    errors.pharmacyAddressLine1}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label
                  className={
                    isFieldRequired("pharmacyPhone") ? "rm-required-field" : ""
                  }
                >
                  Pharmacy Phone
                </Form.Label>
                <Form.Control
                  onChange={(e) =>
                    onChange(e.target.value, "pharmacyPhone", setFieldValue)
                  }
                  onBlur={handleBlur}
                  value={values.pharmacyPhone}
                  name="pharmacyPhone"
                  disabled={!isCreating}
                  data-testid='input-pharmacy-create-phone'
                />
                <Form.Text className="text-small text-danger">
                  {errors.pharmacyPhone &&
                    touched.pharmacyPhone &&
                    errors.pharmacyPhone}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label
                  className={
                    isFieldRequired("pharmacyFax") ? "rm-required-field" : ""
                  }
                >
                  Pharmacy Fax
                </Form.Label>
                <Form.Control
                  onChange={(e) =>
                    onChange(e.target.value, "pharmacyFax", setFieldValue)
                  }
                  onBlur={handleBlur}
                  value={values.pharmacyFax}
                  name="pharmacyFax"
                  disabled={!isCreating}
                  data-testid='input-pharmacy-create-fax'
                />
                <Form.Text className="text-small text-danger">
                  {errors.pharmacyFax &&
                    touched.pharmacyFax &&
                    errors.pharmacyFax}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          {!isCreating && (
            <PharmaciesList
              selectedPharmacy={selectedPharmacy}
              onSelect={handleSelectionChange}
            />
          )}

          <Row className="top-spacer">
            <Col md={{ offset: "4", span: "4" }}>
              <Button
                className="btn-block"
                variant="outline-dark"
                block
                onClick={() => modalRef.current.handleClose()}
                disabled={isSubmitting}
                data-testid='btn-pharmacy-create-cancel'
              >
                Cancel
              </Button>
            </Col>
            <Col md={4} className="bottom-spacer">
              <Button
                type="submit"
                variant="success"
                block
                disabled={isSubmitting}
                className="btn-block"
                data-testid='btn-pharmacy-create-save'
              >
                {getButtonText()}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </DismissableModal>
  );
}

export default connect()(withRouter(SwitchPharmacyInfo));
