import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  dispatchEvent,
  resetEventExecutorStatus,
} from "./SwitchStateChanger.actions";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";

import DismissableModal from "components/DismissableModal";
import { showNotification } from "services/NotificationService";
import EventNoteRecorder from "./EventNoteRecorder";

/**
 * switchId
 * wfItemId
 * event
 * modalOpenerProps : {variant, size, children, block}
 * eventExecuterProps : {variant, children}
 * modalProps : {title, children}
 * onEventExecuted
 */
class EventDispatcher extends Component {
  state = {
    event: this.props.event,
    eventExecutorStatus: this.props.eventExecutorStatus,
    notes: "",
  };

  modalRef = `${this.props.event}_INVOKER`;

  updateNotes(notes) {
    this.setState({ notes });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.event !== props.eventInExecution) {
      return null;
    }
    
    let nextState = { eventExecutorStatus: props.eventExecutorStatus };
    
    if (props.eventExecutorStatus === "success") {
      showNotification({
        title: "Successfully Executed Event!",
        message: `The event: ${props.event} has been executed successfully.`,
        position: "bl",
        type: "success",
      });
      props.resetEventExecutorStatus();
      props.onEventExecuted();
      nextState.modalShouldClose = true;
    }
    if (props.eventExecutorStatus === "failure") {
      showNotification({
        title: "Event Execution Failed!",
        message: `Failed to execute event: ${props.event}.`,
        position: "bl",
        type: "error",
      });
      props.resetEventExecutorStatus();
    }
    
    return nextState;
  }

  dispatchEvent() {
    this.props.dispatchEvent(
      this.props.event,
      this.props.wfItemId,
      this.state.notes
    );
  }

  render() {
    const { eventExecutorStatus } = this.state;
    const { modalOpenerProps, eventExecuterProps, modalProps, switchState } =
      this.props;
    const modalOpener = (
      <Button
        key={1}
        onClick={() => this.refs[this.modalRef].handleShow()}
        variant={modalOpenerProps.variant}
        size={modalOpenerProps.size}
        block={modalOpenerProps.block}
        disabled={
          (switchState && switchState === "CLOSED") || modalOpenerProps.disabled
        }
        className={modalOpenerProps.className}
      >
        {modalOpenerProps.children}
      </Button>
    );
    const customFooterContent = (
      <div>
        <Button
          variant="outline-dark"
          onClick={() => this.refs[this.modalRef].handleClose()}
        >
          Cancel
        </Button>
        <Button
          disabled={eventExecutorStatus === "requested"}
          className="left-spacer"
          variant={eventExecuterProps.variant}
          onClick={() => this.dispatchEvent()}
        >
          {eventExecutorStatus === "requested"
            ? "Executing ..."
            : eventExecuterProps.children}
        </Button>
      </div>
    );
    return (
      <DismissableModal
        ref={this.modalRef}
        openModalButton={modalOpener}
        openModalButtonClassName="btn-block"
        customFooterContent={customFooterContent}
        title={modalProps.title}
        centered
      >
        <div>
          {modalProps.children}
          <EventNoteRecorder
            handleChange={(notes) => this.setState({ notes })}
          />
        </div>
      </DismissableModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    eventExecutorStatus: state.switchDetails.eventExecutorStatus,
    eventInExecution: state.switchDetails.eventInExecution,
    //TODO: hoping the real props are forwarded as is
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchEvent: bindActionCreators(dispatchEvent, dispatch),
    resetEventExecutorStatus: bindActionCreators(
      resetEventExecutorStatus,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventDispatcher));
