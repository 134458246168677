import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DismissableModal from 'components/DismissableModal';
import { FaArrowRight } from "react-icons/fa";
import { FormSelect } from 'react-bootstrap';
import { showNotification } from 'services/NotificationService';

function Feedback(props) {
  const { children } = props;
  return (
    <Row className="m-1" style={{ color:"red", fontSize: 'x-small'}}>
    <Col md={1} className="pt-1">

        <FaArrowRight />
    </Col>
    <Col>{children}</Col>
  </Row>

  );
}


class CheckCustomerConfigurationOnSendGrid extends Component {

  state = {
    customers: null,
    requestInProgress: false,
    loadingCustomers: false,
    isNotSetupCorrectly: false,
    errorMsgList: []
  }

  componentDidMount() {
    this.fetchCustomers();
  }

  fetchCustomers() {
    this.setState({ loadingCustomers: true, isNotSetupCorrectly: false })
    sendAuthenticatedAsyncRequest(
      `/customers/list`,
      "GET",
      null,
      (r) => { this.setState({ customers: r.data, loadingCustomers: false, isNotSetupCorrectly: false  }) },
      (r) => this.setState({ customers: null, loadingCustomers: false, isNotSetupCorrectly: false  })
    );
  }

  checkSettingsOnSendGrid = (values, setSubmitting, closeModalHandle) => {

    sendAuthenticatedAsyncRequest(
      `/sendgrid-member-sync/check-configuration/customer/${values.selectedCustomer}`,
      "PUT",
      null,
      (r) => {
        this.setState({ requestInProgress: false, isNotSetupCorrectly: false });
        setSubmitting(false);
        showNotification({
          title: 'Action successful!',
          message: 'Customer is setup correctly on sendgrid',
          position: 'tc',
          type: "success"
        });
        closeModalHandle();
      },
      (r) => {
        console.log(r.data)
        this.setState({
          requestInProgress: false, isNotSetupCorrectly: true,
          errorMsgList: r.data.additionalErrorInfo
        });
        setSubmitting(false);
        showNotification({
          title: 'Error!',
          message: "Sendgrid not setup correctly for the selected customer",
          position: 'tc',
          type: "error"
        });
      }
    );
  }

  render() {
    const modalRef = 'CheckCustomerConfigurationOnSendGridModal';
    const { customers, isNotSetupCorrectly, errorMsgList } = this.state;
    const formattedCustomers = customers ? customers.map(c => ({
      value: c.name, key: c.id
    })) : [];

    const checkSettingsFormSchema = Yup.object().shape({
      selectedCustomer: Yup.string().required('please select a customer')
    });

    return (
      <Card style={{ textAlign: 'center' }}>
        <Card.Body>
          <Card.Text>
            Check Customer Configuration on SendGrid.
          </Card.Text>
          <DismissableModal
            ref={modalRef}
            openModalButtonText="Check Config"
            openModalButtonStyle="info"
            openModalButtonBlock={true}
            openModalButtonDisabled={false}
            openModalButtonId='check-cust-config-btn-popup'
            preventCloseWhenClickedOutsideModal={true}
            onClose={()=>this.setState({ errorMsgList: [], isNotSetupCorrectly: false })}
            hideFooter={true}
            title="SendGrid Configuration Check" >
            <Formik
              initialValues={{ selectedCustomer: '' }}
              onSubmit={(values, { setSubmitting }) => {
                this.checkSettingsOnSendGrid(values, setSubmitting, this.refs[modalRef].handleClose) 
          
              }}
              validationSchema={checkSettingsFormSchema}
              
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue

              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={{ offset: '1', span: '10' }}>
                      <Form.Group>
                        <Form.Label>
                          Select Customers
                        </Form.Label>
                        <FormSelect 
                          value={values.selectedCustomer}
                          onChange={e =>
                            setFieldValue(
                              "selectedCustomer", e.target.value)
                          }
                          name='selectedCustomer'
                          onBlur={handleBlur}
                        >
                          <option value="">Select </option>
                          {formattedCustomers.map((s, i) => <option value={s.key} key={i}>{s.value}</option>)}
                        </FormSelect>
                        <Form.Text className="text-small text-danger">
                          {errors.selectedCustomer && touched.selectedCustomer && errors.selectedCustomer}
                        </Form.Text>
                        {isNotSetupCorrectly ?
                          errorMsgList.map(i => <Feedback> {i} </Feedback>)
                          : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='bottom-spacer top-spacer'>
                    <Col md={{ offset: '2', span: '4' }}>
                      <Button variant='outline-dark' block onClick={() =>  this.refs[modalRef].handleClose()} disabled={isSubmitting}>
                        Cancel
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Button type="submit" variant='success' block disabled={isSubmitting}>
                        {
                          isSubmitting ? 'Checking... ' : 'Check'
                        }
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </DismissableModal>
        </Card.Body>
      </Card>
    );
  }
}

export default CheckCustomerConfigurationOnSendGrid;
