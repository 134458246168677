import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';


export const updateInterventionList = (pageSize, page, sorted, filtered, resized, expanded) => {

  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/swim/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        {
          type: 'UPDATE_SWITCH_LIST_REQUEST',
          payload: { listState: { pageSize, page, sorted, filtered, resized, expanded } }
        },
        'UPDATE_SWITCH_LIST_SUCCESS',
        'UPDATE_SWITCH_LIST_FAILURE'
      ]
    }
  };
};
export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_SWITCH_LIST"

  }
}
export const updateIndexOnList = (index) => {
  return {
    type: "UPDATE_SWITCH_INDEX_ON_LIST",
    indexOnList: index
  }
}
