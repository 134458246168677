import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import DismissableModal from 'components/DismissableModal';
import { MdDeleteForever } from 'react-icons/md';
import ToggleIconLinkButton from 'components/ToggleIconLinkButton';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { getErrorMessage } from 'services/UtilityService';
import { showNotification } from 'services/NotificationService';

// groupName, dsgId, onSuccessDelete, codexId
class DynamicSwitchGroupDeleteModal extends Component {
  state = {
    modalRefId: `DynamicSwitchGroupDeleteModal_${this.props.dsgId}`
  }

  onSuccess = () => {
    this.props.onSuccessDelete();
    // dispatch success notification
    showNotification({
      title: `Group Deleted!`,
      message: `Group deleted successfully`,
      position: "tr",
      type: "success"
    });
  }

  onError = (r) => {
    // dispatch failure notification
    showNotification({
      title: "Something went wrong!",
      message:
        getErrorMessage(r.data) ||
        getErrorMessage("UNABLE_TO_DELETE_DYNAMIC_SWITCH_GROUP"),
      position: "tr",
      type: "error"
    });
  }

  handleDeleteItem = () => {
    const { codexId, dsgId } = this.props;
    sendAuthenticatedAsyncRequest(
      `/cat/codex-version/${codexId}/dynamic-switch-groups/${dsgId}`,
      "DELETE",
      null, this.onSuccess, this.onError);
    this.closeModal();
  }

  openModal = () => {
    let { modalRefId } = this.state;
    this.refs && this.refs[modalRefId] ? this.refs[modalRefId].handleShow() : null;
  }
  
  closeModal = () => {
    let { modalRefId } = this.state;
    this.refs && this.refs[modalRefId] ? this.refs[modalRefId].handleClose() : null;
  }

  render() {
    const { dsgId, groupName } = this.props;
    const { modalRefId } = this.state;
    const modalOpener = <ToggleIconLinkButton
      key={`delete-${dsgId}`}
      shouldDisplayFirstTag={true}
      toggleButton={this.openModal}
      FirstIcon={MdDeleteForever}
      firstTag={"Delete"}
      linkClassName={'delete-link'}
      SecondIcon={null}
      secondTag={null}
    />;
    const customFooterContent = <div>
      <Button key={`dynamic-switch-group-delete-modal-cancel-${dsgId}`} id='dynamic-switch-group-delete-modal-cancel-button' variant="outline-dark" onClick={this.closeModal}>Cancel</Button>
      <Button
        key={`dynamic-switch-group-delete-modal-${dsgId}`}
        id='dynamic-switch-group-modal-delete-button'
        className="left-spacer"
        variant="danger"
        onClick={this.handleDeleteItem}>
        Delete
      </Button>
    </div>;
    const title = "Delete Group ?";
    return (
      <DismissableModal
        ref={modalRefId}
        key={dsgId}
        customFooterContent={customFooterContent}
        openModalButton={modalOpener}
        title={title}
        id='dynamic-switch-group-delete-modal-dismissable-modal'
      >
        <p >
          Are you sure you want to delete the group <strong >{groupName}</strong>?
        </p>

      </DismissableModal>
    );
  }
}

export default DynamicSwitchGroupDeleteModal;