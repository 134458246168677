
import { createSlice } from '@reduxjs/toolkit';
import { getMemberListRequestAction, getMemberListSuccessAction, getMemberListFailureAction } from './MemberList.actions';
export const INITAL_STATE = {
  data: [],
  totalPages: 0,
  pageSize: 20,
  page: 0,
  error: false,
  loading: false,
  totalElements: "Loading",
  filtered: [],
  isDefaultFilter: true,
  resized: [],
  expanded: {},
  lastName: "",
  dob: ""
}


export const memberListSlice = createSlice({
  name: "memberList",
  initialState: INITAL_STATE,
  reducers: {
    getMemberListSuccess: (state, action) => {
      state.data = action.payload.records;
      state.totalPages = action.payload.totalPages;
      state.pageSize = action.payload.pageSize;
      state.page = action.payload.page;
      state.sorted = action.payload.sorted;
      state.filtered = action.payload.filtered;
      state.totalElements = action.payload.totalElements;
      state.error = false;
      state.loading = false;
      state.dob = action.payload.dob;
      state.lastName = action.payload.lastName;
    },
    getMemberListFailure: (state, action) => {
      state.loading = false;
      state.error = true;
      state.totalElements = "Failed";
      state.data = [];
    },
    getMemberListLoading: (state, action) => {
      state.loading = true;
      state.isDefaultFilter = false;
      state.totalElements = "Loading";
    },
    resetList: (state, action) => {
      return INITAL_STATE;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "member list" RSAA action.
      .addCase(getMemberListRequestAction, (state, action) => {
        memberListSlice.caseReducers.getMemberListLoading(state, action);
      })
      .addCase(getMemberListSuccessAction, (state, action) => {
        memberListSlice.caseReducers.getMemberListSuccess(state, action);
      })
      .addCase(getMemberListFailureAction, (state, action) => {
        memberListSlice.caseReducers.getMemberListFailure(state, action);
      })

  }
});


export const { getMemberListSuccess, getMemberListFailure, getMemberListLoading, resetList } = memberListSlice.actions;
export const memberListState = (state) => state.memberList;
export default memberListSlice.reducer;