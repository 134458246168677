import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { MdArrowBack } from "react-icons/md";

import { CodexVersionStatus } from "./CodexDisplay";
import { CODEX } from "rbac/RbacContentProvider";
import { getLoggedInUser } from "services/AuthService";
import { Col, Row } from "react-bootstrap";

class CodexVersionDisplayBar extends Component {
  state = {
    workingCodexVersion: this.props.workingCodexVersion,
    workingCodexCreationDate: this.props.workingCodexCreationDate,
    workingCodexVersionId: this.props.workingCodexVersionId,
    workingCodexVersionTag: this.props.workingCodexVersionTag,
    isWorkingVersionEditable: this.props.isWorkingVersionEditable,
    codexVersionStatus: this.props.codexVersionStatus,
    editableBy: this.props.editableBy,
    selectedCodexCustomer: this.props.selectedCodexCustomer,
    loggedInUser: getLoggedInUser(),
    firstApprovalBy: this.props.firstApprovalBy,
    disableApproveButton: this.props.disableApproveButton,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      workingCodexVersion,
      workingCodexCreationDate,
      workingCodexVersionId,
      workingCodexVersionTag,
      isWorkingVersionEditable,
      codexVersionStatus,
      editableBy,
      firstApprovalBy,
      disableApproveButton,
    } = nextProps;

    // Check if any of the properties have changed
    if (
      workingCodexVersion !== prevState.workingCodexVersion ||
      workingCodexCreationDate !== prevState.workingCodexCreationDate ||
      workingCodexVersionId !== prevState.workingCodexVersionId ||
      workingCodexVersionTag !== prevState.workingCodexVersionTag ||
      isWorkingVersionEditable !== prevState.isWorkingVersionEditable ||
      codexVersionStatus !== prevState.codexVersionStatus ||
      editableBy !== prevState.editableBy ||
      firstApprovalBy !== prevState.firstApprovalBy ||
      disableApproveButton !== prevState.disableApproveButton
    ) {
      return {
        workingCodexVersion,
        workingCodexCreationDate,
        workingCodexVersionId,
        workingCodexVersionTag,
        isWorkingVersionEditable,
        codexVersionStatus,
        editableBy,
        firstApprovalBy,
        disableApproveButton,
      };
    }
    return null;
  }

  render() {
    const {
      workingCodexVersion,
      isWorkingVersionEditable,
      codexVersionStatus,
      workingCodexVersionId,
      editableBy,
      loggedInUser,
      selectedCodexCustomer,
      disableApproveButton,
      firstApprovalBy,
    } = this.state;
    const disableDisplayCodexButton =
      this.props.location.pathname === "/cat" ||
      this.props.location.pathname === "/cat/codex";
    //const disabledButton = false;
    if (
      this.state.firstApprovalBy?.userId == getLoggedInUser()?.uuid &&
      codexVersionStatus == "PARTIALLY_APPROVED"
    ) {
      this.state.disableApproveButton = true;
    }

    // improve this layout, run away from floating objects
    return (
      <Row>
        <Col md={12}>
          <Row className="codex-version-display-bar">
            <Col md={2} className="my-auto">
              <Button
                size="sm"
                variant="outline-light"
                style={{ float: "left" }}
                onClick={() => this.props.history.push("/cat/codex/dsg")}
                disabled={disableDisplayCodexButton}
              >
                <MdArrowBack /> &nbsp; Display Codex
              </Button>
            </Col>
            <Col md={8} className="my-auto">
              &nbsp; selected codex: &nbsp;
              {workingCodexVersion ? (
                <>
                  <strong>
                    {selectedCodexCustomer} (V{workingCodexVersion}) &nbsp;
                  </strong>
                  <CodexVersionStatus codexVersionStatus={codexVersionStatus} />{" "}
                  &nbsp;
                  {!isWorkingVersionEditable ? (
                    <>
                      <Badge bg="danger" pill>
                        READ ONLY
                      </Badge>
                      &nbsp;
                    </>
                  ) : null}
                </>
              ) : (
                "Not Selected"
              )}
            </Col>
            <Col md={2} className="my-auto">
              {editableBy && editableBy.userId === loggedInUser.uuid
                ? null
                : CODEX.provideCodexStatusModal(
                  this.state.disableApproveButton
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    workingCodexVersion: state.codexVersion
      ? state.codexVersion.codexVersion
      : null,
    workingCodexCreationDate: state.codexVersion
      ? state.codexVersion.codexCreationDate
      : null,
    workingCodexVersionId: state.codexVersion
      ? state.codexVersion.codexVersionId
      : null,
    workingCodexVersionTag: state.codexVersion
      ? state.codexVersion.codexTag
      : null,
    isWorkingVersionEditable: state.codexVersion
      ? state.codexVersion.isWorkingVersionEditable
      : null,
    codexVersionStatus: state.codexVersion
      ? state.codexVersion.codexVersionStatus
      : null,
    selectedCodexCustomer: state.codexVersion
      ? state.codexVersion.customerLabel
      : null,
    editableBy: state.codexVersion ? state.codexVersion.editableBy : null,
    firstApprovalBy: state.codexVersion
      ? state.codexVersion.firstApprovalBy
      : null,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(CodexVersionDisplayBar));
