import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchMetrics,
  resetMetricsFetcherStatus,
} from "./DashboardMetrics.actions";
import Moment from "moment";

import { MdRefresh } from "react-icons/md";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./DashboardMetrics.css";

const Metrics = (props) => (
  <div className="text-muted">
    <strong>Switches to Approve</strong>
    <Row>
      <Col>Open:</Col>
      <Col>
        <Badge bg="info" pill>
          <span
            id="metrics-open-switch-count"
            className="dashboard-metrics-count"
          >
            {props.metrics.openInterventions?props.metrics.openInterventions:"N/A"}
          </span>
        </Badge>
      </Col>
    </Row>
    <Row className="tiny-top-spacer">
      <Col>Owned:</Col>
      <Col>
        <Badge bg="success" pill>
          <span
            id="metrics-owned-switch-count"
            className="dashboard-metrics-count"
          >
            {props.metrics.ownedInterventions?props.metrics.ownedInterventions:"N/A"}
          </span>
        </Badge>
      </Col>
    </Row>
    <div className="small-top-spacer">
      <strong>Responses to Confirm</strong>
    </div>
    <Row>
      <Col>Open:</Col>
      <Col>
        <Badge bg="info" pill>
          <span
            id="metrics-open-response-count"
            className="dashboard-metrics-count"
          >
            {props.metrics.openResponses?props.metrics.openResponses:"N/A"}
          </span>
        </Badge>
      </Col>
    </Row>
    <Row className="tiny-top-spacer">
      <Col>Owned:</Col>
      <Col>
        <Badge bg="success" pill>
          <span
            id="metrics-owned-response-count"
            className="dashboard-metrics-count"
          >
            {props.metrics.ownedResponses?props.metrics.ownedResponses:"N/A"}
          </span>
        </Badge>
      </Col>
    </Row>
    <Row>
      <Col>
        <span className="text-muted" style={{ fontSize: "0.8em" }}>
          Updated:{" "}
          <strong>
            {Moment(props.metrics.requestDateTime).format("MM/DD hh:mm:ss")}
          </strong>
        </span>{" "}
        &nbsp;
        <span onClick={props.fetchMetrics}>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Refresh Metrics</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <MdRefresh className="refresh-link text-muted" ref={ref} />
            )}
          </OverlayTrigger>
        </span>
      </Col>
    </Row>
  </div>
);

class DashboardMetrics extends Component {
  state = {
    metricsFetcherStatus: this.props.metricsFetcherStatus,
    metrics: this.props.metrics,
  };

  componentDidMount() {
    this.props.fetchMetrics();
  }

  static getDerivedStateFromProps(props, state) {
    const { metricsFetcherStatus, metrics, loggedInUser } = props;

    if (
      metricsFetcherStatus !== state.metricsFetcherStatus ||
      metrics !== state.metrics ||
      loggedInUser !== state.loggedInUser
    ) {
      return {
        metricsFetcherStatus,
        metrics,
        loggedInUser,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.metricsFetcherStatus === "success" && prevProps.metricsFetcherStatus !== "success") {
      this.props.resetMetricsFetcherStatus();
    }
  }


  render() {
    const { metricsFetcherStatus, metrics } = this.state;
    return (
      <div>
        <Alert
          variant={metricsFetcherStatus === "failure" ? "danger" : "secondary"}
          className="rm-gray-light"
        >
          {metricsFetcherStatus === "requested" ? (
            <p>Fetching metrics ...</p>
          ) : metricsFetcherStatus === "success" ||
            metricsFetcherStatus === "idle" ? (
            <Metrics metrics={metrics} fetchMetrics={this.props.fetchMetrics} />
          ) : (
            <p>Failed to fetch metrics ...</p>
          )}
        </Alert>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metrics: state.dashboardMetrics.metrics,
    metricsFetcherStatus: state.dashboardMetrics.metricsFetcherStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMetrics: bindActionCreators(fetchMetrics, dispatch),
    resetMetricsFetcherStatus: bindActionCreators(
      resetMetricsFetcherStatus,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMetrics);
