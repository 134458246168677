import { memo } from "react";
import Col from "react-bootstrap/Col";
import { DescriptionDeltaField } from "../../../commons/fields/DeltaFieldUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CheckField from "../../../commons/fields/CheckField";
import { MdInfoOutline } from "react-icons/md";
import CAT_CONSTANTS from "../../../lookup/CatConstants";

const DrugOptionStrengths = ({
  showDelta = false,
  isDeltaUpdated = false,
  savedValue = undefined,
  handleChange = () => {},
  handleBlur = () => {},
  updatedValue = undefined,
  isEditMode = false,
  predecessorValue = undefined,
}) => {
  const getStrengthsInString = (forNewDSO = true) => {
    const dsi = forNewDSO ? savedValue : predecessorValue;
    let strengths = dsi
      ? dsi.reduce((x, current) => {
          if (
            current.isActive &&
            !current.isDeleted &&
            !current.isNotMainTargetStrength
          )
            return (
              x + current.itemStrength + " " + current.itemUnitOfMeasure + " , "
            );
          return x;
        }, "")
      : "";
    strengths = strengths.slice(0, strengths.length - 3);
    return strengths;
  };

  const getCheckClassName = (dynamicSwitchOptionItem) => {
    if (dynamicSwitchOptionItem.isNdcPresent === false) {
      return "orange-check";
    } else if (
      dynamicSwitchOptionItem.selectionStatus &&
      dynamicSwitchOptionItem.selectionStatus ===
        CAT_CONSTANTS.dynamicSwitchItemSelectionStatus.PARTIALLY_SELECTED
    ) {
      return "yellow-check";
    }
    return "";
  };

  const valueFiltered = savedValue.filter((it) => it.isActive || isEditMode);

  return (
    <Col md={12}>
      <div>
        <OverlayTrigger
          placement="top"
          delay={{ show: 50, hide: 400 }}
          overlay={
            <Tooltip>
              <div>
                On-market strength <span style={{ color: "#0d6efd" }}>●</span>
                <br />
                Off-market strength <span style={{ color: "#FFAA3B" }}>●</span>
                <br />
                Partially selected strength{" "}
                <span style={{ color: "#bac700" }}>●</span>
              </div>
            </Tooltip>
          }
          enabled
        >
          <span className="rm-small-top-label">
            Strengths <MdInfoOutline className="alternative-label" />
          </span>
        </OverlayTrigger>
      </div>
      {showDelta ? (
        <DescriptionDeltaField
          newField={getStrengthsInString(true)}
          oldField={getStrengthsInString(false)}
          isUpdated={isDeltaUpdated}
        />
      ) : (
        <div className={"filtered-switch-items"}>
          {valueFiltered?.length > 0
            ? valueFiltered.map((dynamicSwitchOptionItem, idx) => (
                <CheckField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  label={`${dynamicSwitchOptionItem.itemStrength} ${dynamicSwitchOptionItem.itemUnitOfMeasure}`}
                  id={`dynamicSwitchItems.${dynamicSwitchOptionItem?.dynamicSwitchItemId}.isActive`}
                  compKey={`item-${idx}`}
                  value={
                    updatedValue[dynamicSwitchOptionItem.dynamicSwitchItemId]
                      ?.isActive
                  }
                  isEditMode={
                    isEditMode &&
                    !dynamicSwitchOptionItem.isNotMainTargetStrength
                  }
                  key={`dynamicSwitchItems.${dynamicSwitchOptionItem?.dynamicSwitchItemId}.isActive`}
                  checkClassName={getCheckClassName(dynamicSwitchOptionItem)}
                />
              ))
            : "No active strengths available"}
        </div>
      )}
    </Col>
  );
};

export default memo(DrugOptionStrengths);
