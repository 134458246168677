import React from "react";
import Form from "react-bootstrap/Form";

export default class EventNoteRecorder extends React.Component {
    state = {
      enableNotes: false,
      notes: "",
    };
  
    handleNotesChange = (notes) => {
      this.setState({ notes });
      this.props.handleChange(notes);
    };
  
    handleCheckboxChange = (e) => {
      const { checked } = e.target;
      const { notes } = this.state;
      this.setState({
        enableNotes: checked,
        notes: checked ? notes : "",
      });
    };
  
    render() {
      const { enableNotes, notes } = this.state;
      return (
        <div className="top-spacer">
          <Form.Check
            type="checkbox"
            checked={enableNotes}
            label={"Add Notes"}
            onChange={this.handleCheckboxChange}
          />
          <Form.Group hidden={!enableNotes}>
            {/* <Form.Label>Notes</Form.Label> */}
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Notes or comments about this event"
              onChange={(e) => this.handleNotesChange(e.target.value)}
              value={notes}
            />
            <Form.Text className="text-muted">
              Notes or comments about this event
            </Form.Text>
          </Form.Group>
        </div>
      );
    }
  }