import React from 'react';
import { memo } from 'react';

const DeltaField = ({ oldField, newField, className, heading, isUpdated }) => {
  if (isUpdated && oldField != newField) {
    return (
      <>
        {heading ? <div className="rm-small-top-label">{heading}</div> : null}
        <span className={"cell " + className}>
          {oldField ? <span className="removed-text">
            {oldField} </span> : null}
          {oldField ? "  " : null}
          {newField ? <span className="added-text"> {newField}</span> : null}
        </span>
      </>
    )
  }
  return <>
    {heading ? <div className="rm-small-top-label">{heading}</div> : null}
    <span className={"cell " + className}>{newField ? newField : "-"}</span>
  </>
}

const CheckDeltaField = ({ isUpdated, oldField, newField, className, heading = null }) => {
  let oldF = typeof (oldField) == "boolean" ? oldField ? "Yes" : "No" : "No";
  let newF = typeof (newField) == "boolean" ? newField ? "Yes" : "No" : "No";
  if (isUpdated && oldF != newF) {
    return (
      <>
        {heading ? <div className="rm-small-top-label">{heading}</div> : null}
        <span className={"cell " + className} >
          {oldF ? <span className="removed-text"> {oldF} </span> : null}
          {oldF ? "  " : null}
          <span className="added-text"> {newF}</span>
        </span>
      </>
    )
  }
  return <>
    {heading ? <div className="rm-small-top-label">{heading}</div> : null}
    <span className={"cell " + className}>{newF ? newF : "-"}</span>
  </>
}

const DELTA_CLASS_NAME = {
  NEW: 'new', MODIFIED: 'changed', DELETED: 'deleted'
}

const getDeltaBoxClassName = (showDelta, isChanged, isOldDrugPresent, isNewObjectDeleted) => {
  if (showDelta) {
    if (isNewObjectDeleted) {
      return DELTA_CLASS_NAME.DELETED
    } else if (isChanged) {
      return DELTA_CLASS_NAME.MODIFIED;
    } else if (!isOldDrugPresent) {
      return DELTA_CLASS_NAME.NEW
    } 
  }
  return ""
}

const DescriptionDeltaField = memo(DeltaField);
const BooleanDeltaField = memo(CheckDeltaField);

export { DescriptionDeltaField, BooleanDeltaField, getDeltaBoxClassName, DELTA_CLASS_NAME };