import { withRouter } from "react-router-dom";
import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberSwitches } from "./MemberSwitchGrid.actions"
import Tip from "components/Tip";
import { ColumnTypes, FilterTypes } from "commons/table/Constants";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import { Link, useParams } from 'react-router-dom';
import { hasRoleIn } from 'rbac/RbacHelper';
import ROLES from 'rbac/Roles.json';

const DEFAULT_PAGE_SIZE = 7;

const MemberSwitchesGrid = (props) => {
  const { memberInfoId, onSelect } = props;
  const dispatch = useDispatch();
  const listState = useSelector(state => state.memberSwitches);
  const memberSwitches = useSelector(state => state.memberSwitches.data);

  React.useEffect(() => {
    if (memberInfoId)//memberInfoId, pageSize, pageNo, sorted, filtered)[]
      dispatch(fetchMemberSwitches(memberInfoId, listState.pageSize, listState.page, listState.sorted, listState.filtered));
  }, [memberInfoId]);

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    dispatch(fetchMemberSwitches(memberInfoId, pageSize, pageNo, sortBy, filterValues));
  }

  const columns = [
    {
      header: 'Switch #',
      accessor: 'switchNumberAsString',
      accessorKey: 'switchNumberAsString',
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        if (hasRoleIn([ROLES.SWIM_USER])) {
          return <Link
            to={`/switch/${row.original.wfItemId}`}
            className={"column-left"}
          >
            {value}
          </Link>
        }
        else {
          return value;
        }

      },

    },
    {
      header: "Prescriber Name",
      accessor: "prescriberName",
      accessorKey: "prescriberName",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.WrapTextOnNoSpace,
    },
    {
      header: "Medicine Name",
      accessor: "sourceDrugName",
      accessorKey: "sourceDrugName",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.WrapTextOnNoSpace,
    },
    {
      header: "Sw Status",
      accessor: "status",
      accessorKey: "status",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.WrapTextOnNoSpace,
    },
    {
      header: "Activity Count",
      accessor: "openActivityCount",
      accessorKey: "openActivityCount",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text
    },
  ];

  return (
    <div className="list-container">
      <ServerSideReactTable
        currentPage={listState.page}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={listState.totalPages}
        onUpdateTableData={onUpdateTable}
        columnDefination={columns}
        data={memberSwitches}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        addRowSelection={false}
        loading={listState.loading}
        totalRecords={listState.totalElements}
        enableMultiRowSelection={false}
        pageSizeOptions={[5, 7, 10]}
        onRowClick={(rowInfo) => {
          if (rowInfo !== undefined) {
            onSelect(rowInfo.original);
          }
        }}
      />
      <Tip>Tip: Hold shift when sorting to multi-sort and disable sort!</Tip>
    </div>
  );
};

export default withRouter(MemberSwitchesGrid);
