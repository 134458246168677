import React from "react";

import Badge from "react-bootstrap/Badge";

import CAT_CONSTANTS from "lookup/CatConstants";

export const CodexVersionStatus = (props) => (
  <Badge
    pill
    bg={CAT_CONSTANTS.codexColorsForStatuses[props.codexVersionStatus].variant}
    style={{
      color:
        CAT_CONSTANTS.codexColorsForStatuses[props.codexVersionStatus].text,
    }}
  >
    {props.codexVersionStatus}
  </Badge>
);
