import React,{Component} from "react";
import { Col, Row } from "react-bootstrap";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";

export function validatePassword(passwordPolicy, password, confirmedPassword) {
  const {
    minimumLength,
    requireUppercase,
    requireLowercase,
    requireNumbers,
    requireSymbols,
  } = passwordPolicy;
  const result = { passed: true, failedMatches: {} };

  if (!password || typeof password !== "string") {
    result.passed = false;
    result.failedMatches = {
      minimumLength: true,
      requireUppercase: true,
      requireLowercase: true,
      requireNumbers: true,
      requireSymbols: true,
      matchWithConfirmed: true,
    };
    return result;
  }

  if (!confirmedPassword || password !== confirmedPassword) {
    result.passed = false;
    result.failedMatches.matchWithConfirmed = true;
  }

  if (password.length < minimumLength) {
    result.passed = false;
    result.failedMatches.minimumLength = true;
  }

  if (requireUppercase) {
    if (!/[A-Z]/.test(password)) {
      result.passed = false;
      result.failedMatches.requireUppercase = true;
    }
  }

  if (requireLowercase) {
    if (!/[a-z]/.test(password)) {
      result.passed = false;
      result.failedMatches.requireLowercase = true;
    }
  }

  if (requireNumbers) {
    if (!/[0-9]/.test(password)) {
      result.passed = false;
      result.failedMatches.requireNumbers = true;
    }
  }

  if (requireSymbols) {
    if (
      !(
        /[-!$%^&*()_|~`{}[\]:/;<>?,.@#'"]/.test(password) ||
        password.indexOf("\\") >= 0
      )
    ) {
      result.passed = false;
      result.failedMatches.requireSymbols = true;
    }
  }

  return result;
}

function Feedback(props) {
  const { children, success } = props;
  return (
    <Row className="m-1" style={{ color: success ? "green" : "red" }}>
      <Col md={1} className="pt-1">
        {success ? (
          <FaThumbsUp fontSize={"large"} />
        ) : (
          <FaThumbsDown fontSize={"large"} />
        )}{" "}
      </Col>
      <Col>{children}</Col>
    </Row>
  );
}

class PasswordValidationFeedback extends Component {
  render() {
    const { passwordPolicy, password, confirmedPassword } = this.props;
    const {
      minimumLength,
      requireUppercase,
      requireLowercase,
      requireNumbers,
      requireSymbols,
    } = passwordPolicy;
    const result = validatePassword(
      passwordPolicy,
      password,
      confirmedPassword
    );
    return (
      <div className="mt-3">
        {requireUppercase && (
          <Feedback success={!result.failedMatches.requireUppercase}>
            have an upper case letter
          </Feedback>
        )}
        {requireLowercase && (
          <Feedback success={!result.failedMatches.requireLowercase}>
            have a lower case letter
          </Feedback>
        )}
        {requireNumbers && (
          <Feedback success={!result.failedMatches.requireNumbers}>
            have a number
          </Feedback>
        )}
        {requireSymbols && (
          <Feedback success={!result.failedMatches.requireSymbols}>
            have a special character
          </Feedback>
        )}
        <Feedback success={!result.failedMatches.minimumLength}>
          have at least {minimumLength} characters
        </Feedback>
        <Feedback success={!result.failedMatches.matchWithConfirmed}>
          match with confirmed password
        </Feedback>
      </div>
    );
  }
}

export default PasswordValidationFeedback;
