import { createSlice } from '@reduxjs/toolkit';
import {RESET_MEMBER_SWITCH_LIST,GET_MEMBER_SWITCH_LIST_FAILURE,GET_MEMBER_SWITCH_LIST_REQUEST,GET_MEMBER_SWITCH_LIST_SUCCESS} from "./MemberSwitchList.constant";

const initialState = {
  data: [],
  totalPages: 0,
  pageSize: 5,
  currentPage:0,
  error: false,
  loading: false,
  totalElements: "Loading",
  sorted:[],
  filtered:[],
  resized: [],
  expanded: {}
}


export const memberSpecificSwitchListSlice = createSlice({
  name: "memberSpecificSwitchList",
  initialState: initialState,
  reducers: {
    getMemberSwitchListSuccess: (state, action) => {
      state.data = action.payload.records;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.page;
      state.pageSize=action.payload.pageSize;
      state.sorted = action.payload.sorted;
      state.filtered = action.payload.filtered;
      state.totalElements=action.payload.totalElements;
      state.error = false;
      state.loading = false;
    },
    getMemberSwitchListFailure: (state, action) => {
      state.loading = false;
      state.error = false;
      state.totalElements = "Failed";
    },
    getMemberSwitchListLoading: (state, action) => {
      state.loading = true;
      state.totalElements = "Loading";
    },
    resetList: (state, action) => {
      return initialState;
  }

  },
  extraReducers: (builder) => {
    builder
      .addCase(GET_MEMBER_SWITCH_LIST_REQUEST, (state, action) => {
        memberSpecificSwitchListSlice.caseReducers.getMemberSwitchListLoading(state, action);
      })
      .addCase(GET_MEMBER_SWITCH_LIST_SUCCESS, (state, action) => {
        memberSpecificSwitchListSlice.caseReducers.getMemberSwitchListSuccess(state, action);
      })
      .addCase(GET_MEMBER_SWITCH_LIST_FAILURE, (state, action) => {
        memberSpecificSwitchListSlice.caseReducers.getMemberSwitchListFailure(state, action);
      })
      .addCase(RESET_MEMBER_SWITCH_LIST, (state, action) => {
        memberSpecificSwitchListSlice.caseReducers.getMemberSwitchListFailure(state, action);
      });
  }
});


export const { getSwitchListSuccess, getSwitchListFailure,resetList } = memberSpecificSwitchListSlice.actions;
export const memberSpecificSwitchListState = (state) => state.memberSpecificSwitchListState;
export default memberSpecificSwitchListSlice.reducer;