import { RSAA } from 'redux-api-middleware';
import config from '../../../config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';
import { resetList } from './MemberSwitchesGrid.slice';

export const getMemberSwitchesRequestAction = "GET_MEMBER_SWITCHES_REQUEST";
export const getMemberSwitchesSuccessAction = "GET_MEMBER_SWITCHES_SUCCESS";
export const getMemberSwitchesFailureAction = "GET_MEMBER_SWITCHES_FAILURE";




export const fetchMemberSwitches = (memberInfoId, pageSize, pageNo, sorted, filtered) => (dispatch, getState) => {
    const rsaaObject = {
        [RSAA]: {
            endpoint: `${config.apiRoot}/member/${memberInfoId}/switch/list`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
            body: JSON.stringify({ pageSize, page:pageNo, filtered, sorted }),
            types: [
                getMemberSwitchesRequestAction,
                {
                    type: getMemberSwitchesSuccessAction,
                    payload: async (action, state, res) => {
                        const data = await res.json();
                        return { pageSize: pageSize, filtered: filtered, sorted, ...data };
                    }
                },
                getMemberSwitchesFailureAction
            ],
        }
    }
    return dispatch(rsaaObject);
};

export const resetMemberSwitches = () => (dispatch, getState) => {
    dispatch(resetList);
}


