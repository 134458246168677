import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import DismissableModal from "components/DismissableModal";
import { FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import {
  SWITCH_CLOSE_REASONS,
  SWITCH_CLOSE_REASONS_MAPPING,
  FUTURE_SWITCH_TREATMENT_OPTIONS,
  HIDE_FUTURE_TREATMENT_FIELD_REASONS,
} from "lookup/SwimConstants";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { getErrorMessage } from "services/UtilityService";
import { showNotification } from "services/NotificationService";

class CloseSwitchModal extends Component {
  state = {
    wfItemId: this.props.wfItemId,
    isActionCallable: this.props.isActionCallable || false,
    onEventExecuted: this.props.onEventExecuted,
    originalSwitchGlobalId: this.props.closestChainSwitchId || "",
  };

  submitForm(originalValues, setSubmitting, closeModal) {
    const { wfItemId, onEventExecuted } = this.state;
    sendAuthenticatedAsyncRequest(
      `/wf/${wfItemId}/close`,
      "POST",
      originalValues,
      (r) => {
        setSubmitting(false);
        showNotification({
          title: "Close Switch",
          message: "Switch closed successfully.",
          position: "tr",
		  type: "success"
        });
        onEventExecuted();
        closeModal();
      },
      (r) => {
        setSubmitting(false);
        showNotification({
          title: `Cannot execute ${originalValues.event}`,
          message:
            getErrorMessage(r.data) ||
            getErrorMessage("INVALID_GLOBAL_SWITCH_ID_WHILE_CLOSING"),
          position: "tr",
		  type: "error"
        });
      }
    );
  }

  hideFutureSwitchTreatmentField(event) {
    return HIDE_FUTURE_TREATMENT_FIELD_REASONS.includes(event);
  }

  handleSpecialOpen(handleRealOpen) {
    handleRealOpen();
  }

  render() {
    const { isActionCallable, originalSwitchGlobalId } = this.state;
    const title = "Closing Switch";
    const modalRef = "CloseSwitchModal";
    const closeActivitySchema = Yup.object().shape(
      {
        note: Yup.string()
          .nullable()
          .max(4096, "Max Length: 4096")
          .ensure()
          .when("event", {
            is: (event) => HIDE_FUTURE_TREATMENT_FIELD_REASONS.includes(event),
            then: Yup.string().required("Required!"),
          }),
        event: Yup.string()
          .oneOf(SWITCH_CLOSE_REASONS, "Select a valid close event")
          .required("Required!"),
        futureSwitchTreatment: Yup.string().nullable(),
        originalSwitchGlobalId: Yup.string()
          .nullable()
          .max(13, "Max Length: 13")
          .when("event", {
            is: (event) => HIDE_FUTURE_TREATMENT_FIELD_REASONS.includes(event),
            then: Yup.string().required("Required!"),
          }),
      },
      [["event", "note", "futureSwitchTreatment", "originalSwitchGlobalId"]]
    );

    return (
      <DismissableModal
        ref={modalRef}
        openModalButton={
          <Button
            size="md"
            className="btn-block"
            variant="warning"
            key={"closeSwitchModalBtn"}
            disabled={isActionCallable}
            onClick={() =>
              this.handleSpecialOpen(this.refs[modalRef].handleShow)
            }
          >
            Close Switch
          </Button>
        }
        title={title}
        size="lg"
        preventCloseWhenClickedOutsideModal={true}
        hideFooter={true}
      >
        <Container>
          <Formik
            initialValues={{
              event: "",
              note: "",
              futureSwitchTreatment: "",
              originalSwitchGlobalId: originalSwitchGlobalId,
            }}
            onSubmit={(values, { setSubmitting, setValues }) => {
              this.submitForm(
                values,
                setSubmitting,
                this.refs[modalRef].handleClose
              );
            }}
            validationSchema={closeActivitySchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Close Reason</Form.Label>
                        <FormSelect
                          value={values.event}
                          onChange={(e) =>
                            setFieldValue("event", e.target.value)
                          }
                          name="event"
                          onBlur={handleBlur}
                        >
                          <option value="">Select Reason To Close</option>
                          {SWITCH_CLOSE_REASONS_MAPPING.map((reason, i) => (
                            <option key={reason.key} value={reason.key}>
                              {reason.value}
                            </option>
                          ))}
                        </FormSelect>
                        <Form.Text className="text-small text-danger">
                          {errors.event && touched.event && errors.event}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      {!this.hideFutureSwitchTreatmentField(values.event) ? (
                        <FormGroup>
                          <FormLabel>Future Switch Treatment</FormLabel>
                          <FormSelect
                            value={values.futureSwitchTreatment}
                            onChange={(e) =>
                              setFieldValue(
                                "futureSwitchTreatment",
                                e.target.value
                              )
                            }
                            name="futureSwitchTreatment"
                            onBlur={handleBlur}
                          >
                            <option value="">
                              Select Future Switch Treatment
                            </option>
                            {FUTURE_SWITCH_TREATMENT_OPTIONS.map(
                              (option, i) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              )
                            )}
                          </FormSelect>
                          <Form.Text className="text-small text-danger">
                            {errors.futureSwitchTreatment &&
                              touched.futureSwitchTreatment &&
                              errors.futureSwitchTreatment}
                          </Form.Text>
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <FormLabel>Original Switch Global Id</FormLabel>
                          <Form.Control
                            disabled={originalSwitchGlobalId !== ""}
                            value={values.originalSwitchGlobalId}
                            onChange={(e) =>
                              setFieldValue(
                                "originalSwitchGlobalId",
                                e.target.value
                              )
                            }
                            name="originalSwitchGlobalId"
                            onBlur={handleBlur}
                          />
                          <Form.Text className="text-small text-danger">
                            {errors.originalSwitchGlobalId &&
                              touched.originalSwitchGlobalId &&
                              errors.originalSwitchGlobalId}
                          </Form.Text>
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.note}
                          name="note"
                          as="textarea"
                        />
                        <Form.Text className="text-small text-danger">
                          {errors.note && touched.note && errors.note}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="top-spacer">
                    <Col md={{ offset: "4", span: "4" }}>
                      <Button
                        variant="outline-dark"
                        block
                        onClick={() => this.refs[modalRef].handleClose()}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col md={4} className="bottom-spacer">
                      <Button
                        type="submit"
                        variant="warning"
                        block
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Closing ... " : "Close"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Formik>
        </Container>
      </DismissableModal>
    );
  }
}


export default withRouter(CloseSwitchModal);
