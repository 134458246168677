import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';
import { dateFormatter } from 'services/UtilityService';

export const getCallGroupDetails = (callGroupId) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/agent-workspace/${callGroupId}/call-group-details`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        'GET_CALL_GROUP_DETAILS_REQUEST',
        'GET_CALL_GROUP_DETAILS_SUCCESS',
        'GET_CALL_GROUP_DETAILS_FAILURE'
      ]
    }
  };
};

export const getCallGroupActivities = (callGroupId, pageSize, page, sorted, filtered, resized, expanded) => {
  const filteredDeepCopy = _.cloneDeep(filtered);
  filteredDeepCopy.forEach(function(part, index, filteredArray) {
    if(filteredArray[index].id === 'scheduledDate' && filteredArray[index].value != null) {
      filteredArray[index].value[0] = dateFormatter(filteredArray[index].value[0])
      filteredArray[index].value[1] = dateFormatter(filteredArray[index].value[1])
    }
  });
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/agent-workspace/${callGroupId}/call-group-activities`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered: filteredDeepCopy }),
      types: [
        {
          type: 'GET_CALL_GROUP_ACTIVITIES_REQUEST',
          payload: { listState: { pageSize, page, sorted, filtered, resized, expanded } }
        },
        'GET_CALL_GROUP_ACTIVITIES_SUCCESS',
        'GET_CALL_GROUP_ACTIVITIES_FAILURE'
      ]
    }
  };
};

export const getActivityDetails = (activityId) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/agent-workspace/${activityId}/activity-details`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        'GET_ACTIVITY_DETAILS_REQUEST',
        'GET_ACTIVITY_DETAILS_SUCCESS',
        'GET_ACTIVITY_DETAILS_FAILURE'
      ]
    }
  };
}

export const autoAssignCallGroup = (callGroupId) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/agent-workspace/${callGroupId}/auto-assign`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        'AUTO_ASSIGN_CALL_GROUP_REQUEST',
        'AUTO_ASSIGN_CALL_GROUP_SUCCESS',
        'AUTO_ASSIGN_CALL_GROUP_FAILURE'
      ]
    }
  };
};

export const clearCallGroupDetail = () => {
  return {
    type: "RESET_CALL_GROUP_DETAILS"
  }
}

export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_CALL_GROUP_PAGE"
  }
}

export const resetActivityUponClose = () => {
  return {
    type: "RESET_ACTIVITY_DETAILS"
  }
}

export const resetAutoAssignError = () => {
  return {
    type: "RESET_AUTO_ASSIGN_ERROR"
  }
}

export const resetCallGroupActivities = () => {
  return {
    type: "RESET_CALL_GROUP_ACTIVITIES"
  }
}