import { createSlice } from "@reduxjs/toolkit";
import {
  LINK_SWITCH_REQUEST,
  LINK_SWITCH_SUCCESS,
  LINK_SWITCH_FAILURE,
  DISCARD_FAX_REQUEST,
  DISCARD_FAX_SUCCESS,
  DISCARD_FAX_FAILURE,
  FETCH_UNPROCESSED_FAX_REQUEST,
  FETCH_UNPROCESSED_FAX_SUCCESS,
  FETCH_UNPROCESSED_FAX_FAILURE,
  LINK_REQUEST_STATUS_RESET,
  SAVE_FAX_NOTE_REQUEST,
  SAVE_FAX_NOTE_SUCCESS,
  SAVE_FAX_NOTE_FAILURE,
  RESET_SAVE_NOTE_SUCCESS,
  UPDATE_FAX_NOTE_REQUEST,
  UPDATE_FAX_NOTE_SUCCESS,
  UPDATE_FAX_NOTE_FAILURE,
  RESET_UPDATE_NOTE_SUCCESS,
  DELETE_FAX_NOTE_REQUEST,
  DELETE_FAX_NOTE_SUCCESS,
  DELETE_FAX_NOTE_FAILURE,
  RESET_DELETE_NOTE_SUCCESS,
  RESET_DISCARDED_FAX,
} from "./UnprocessedFaxDetails.constant";

const INITIAL_STATE = {
  unprocessedFaxDetailsStatus: "requested",
  unprocessedFaxDetails: null,
  discardRequestCompleted: false,
  linkRequestCompleted: false,
  linkRequestStatus: null,
  errorMessage: null,
  saveNoteRequested: "requested",
  saveNoteSuccess: null,
  saveNoteError: null,
  updateNoteRequested: "requested",
  updateNoteSuccess: null,
  updateNoteError: null,
  savedNote: null,
  updatedNote: null,
  deleteNoteSuccess: null,
  deleteNoteError: null,
  deleteNoteRequested: "requested",
  fetchNoteRequested: "requested",
  fetchNoteSuccess: null,
  fetchNoteFailure: null,
  noteObject: null,
};

export const unprocessedFaxDetailsSlice = createSlice({
  name: "unprocessedFaxDetails",
  initialState: INITIAL_STATE,
  reducers: {
    fetchUnprocessedFaxRequest: (state, action) => {
      state.unprocessedFaxDetailsStatus = "requested";
      state.unprocessedFaxDetails = {};
    },
    fetchUnprocessedFaxFailed: (state, action) => {
      state.unprocessedFaxDetailsStatus = "failed";
      state.unprocessedFaxDetails = {
        faxInfoId: "1211",
        faxId: "1211",
        noOfPages: 2,
        associatedSwitch: "00000034-000232",
        wfItemStatus: null,
      };
    },
    fetchUnprocessedFaxSuccess: (state, action) => {
      state.unprocessedFaxDetailsStatus = "success";
      state.unprocessedFaxDetails = action.payload;
      state.discardRequestCompleted = false;
      state.linkRequestCompleted = false;
    },
    fetchNoteForUnlinkedFaxRequest: (state, action) => {
      state.fetchNoteRequested = "requested";
      state.note = null;
    },
    fetchNoteForUnlinkedFaxSuccess: (state, action) => {
      state.fetchNoteRequested = "success";
      state.noteObject = action.payload;
      state.fetchNoteSuccess = true;
      state.fetchNoteFailure = false;
    },
    fetchNoteForUnlinkedFaxFailure: (state, action) => {
      state.fetchNoteRequested = "failure";
      state.note = null;
      state.fetchNoteSuccess = false;
      state.fetchNoteFailure = true;
    },

    linkSwitchSuccess: (state, action) => {
      state.linkRequestStatus = "success";
    },
    linkSwitchFailure: (state, action) => {
      state.linkRequestStatus = "failure";
      state.errorMessage = action.payload.response;
    },
    linkSwitchRequest: (state, action) => {
      state.linkRequestStatus = "requested";
      state.errorMessage = null;
    },
    resetLinkSwitchRequest: (state, action) => {
      state.linkRequestStatus = null;
      state.errorMessage = null;
    },
    discardFaxSuccess: (state, action) => {
      state.discardRequestCompleted = action.payload;
    },
    discardFaxFailed: (state, action) => {
      state.discardRequestCompleted = false;
    },
    discardFaxRequest: (state, action) => {
      state.discardRequestCompleted = "requested";
    },
    saveNoteRequest: (state, action) => {
      state.saveNoteRequested = "requested";
      state.saveNoteSuccess = false;
      state.saveNoteError = false;
    },
    saveNoteSuccess: (state, action) => {
      state.savedNote = action.payload;
      state.saveNoteRequested = "success";
      state.saveNoteSuccess = true;
      state.saveNoteError = false;
    },
    saveNoteFailure: (state, action) => {
      state.saveNoteRequested = "failure";
      state.saveNoteSuccess = false;
      state.saveNoteError = true;
    },
    resetNoteSaveRequest: (state, action) => {
      state.saveNoteRequested = "requested";
      state.saveNoteSuccess = null;
      state.saveNoteError = null;
    },
    updateNoteRequest: (state, action) => {
      state.updateNoteRequested = "requested";
      state.updateNoteSuccess = false;
      state.updateNoteError = false;
    },
    updateNoteSuccess: (state, action) => {
      state.updatedNote = action.payload;
      state.updateNoteRequested = "success";
      state.updateNoteSuccess = true;
      state.updateNoteError = false;
    },
    updateNoteFailure: (state, action) => {
      state.updateNoteRequested = "failure";
      state.updateNoteSuccess = false;
      state.updateNoteError = true;
    },
    resetUpdateNoteSuccess: (state, action) => {
      state.updateNoteRequested = "requested";
      state.updateNoteSuccess = null;
      state.updateNoteError = null;
    },
    deleteNoteRequest: (state, action) => {
      state.deleteNoteRequested = "requested";
      state.deleteNoteSuccess = false;
      state.deleteNoteError = false;
    },
    deleteNoteSuccess: (state, action) => {
      state.deleteNoteRequested = "success";
      state.deleteNoteSuccess = true;
      state.deleteNoteError = false;
    },
    deleteNoteFailure: (state, action) => {
      state.deleteNoteRequested = "error";
      state.deleteNoteSuccess = false;
      state.deleteNoteError = true;
    },
    resetDeleteNoteSuccess: (state, action) => {
      state.deleteNoteRequested = "requested";
      state.deleteNoteSuccess = null;
      state.deleteNoteError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FETCH_UNPROCESSED_FAX_REQUEST, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.fetchUnprocessedFaxRequest(
          state,
          action
        );
      })
      .addCase(FETCH_UNPROCESSED_FAX_FAILURE, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.fetchUnprocessedFaxFailed(
          state,
          action
        );
      })
      .addCase(FETCH_UNPROCESSED_FAX_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.fetchUnprocessedFaxSuccess(
          state,
          action
        );
      })
      .addCase(LINK_SWITCH_REQUEST, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.linkSwitchRequest(
          state,
          action
        );
      })
      .addCase(LINK_SWITCH_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.linkSwitchSuccess(
          state,
          action
        );
      })
      .addCase(LINK_SWITCH_FAILURE, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.linkSwitchFailure(
          state,
          action
        );
      })
      .addCase(DISCARD_FAX_REQUEST, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.discardFaxRequest(
          state,
          action
        );
      })
      .addCase(DISCARD_FAX_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.discardFaxSuccess(
          state,
          action
        );
      })
      .addCase(DISCARD_FAX_FAILURE, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.discardFaxFailed(state, action);
      })
      .addCase(RESET_DISCARDED_FAX, (state, action) => {
        state.discardRequestCompleted = false;
      })
      .addCase(SAVE_FAX_NOTE_REQUEST, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.saveNoteRequest(state, action);
      })
      .addCase(SAVE_FAX_NOTE_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.saveNoteSuccess(state, action);
      })
      .addCase(SAVE_FAX_NOTE_FAILURE, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.saveNoteFailure(state, action);
      })
      .addCase(RESET_SAVE_NOTE_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.resetNoteSaveRequest(
          state,
          action
        );
      })
      .addCase(UPDATE_FAX_NOTE_REQUEST, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.updateNoteRequest(
          state,
          action
        );
      })
      .addCase(UPDATE_FAX_NOTE_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.updateNoteSuccess(
          state,
          action
        );
      })
      .addCase(UPDATE_FAX_NOTE_FAILURE, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.updateNoteFailure(
          state,
          action
        );
      })
      .addCase(RESET_UPDATE_NOTE_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.resetUpdateNoteSuccess(
          state,
          action
        );
      })
      .addCase(DELETE_FAX_NOTE_REQUEST, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.deleteNoteRequest(
          state,
          action
        );
      })
      .addCase(DELETE_FAX_NOTE_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.deleteNoteSuccess(
          state,
          action
        );
      })
      .addCase(DELETE_FAX_NOTE_FAILURE, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.deleteNoteFailure(
          state,
          action
        );
      })
      .addCase(RESET_DELETE_NOTE_SUCCESS, (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.resetDeleteNoteSuccess(
          state,
          action
        );
      })
      .addCase("GET_FAX_NOTE_REQUEST", (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.fetchNoteForUnlinkedFaxRequest(
          state,
          action
        );
      })
      .addCase("GET_FAX_NOTE_SUCCESS", (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.fetchNoteForUnlinkedFaxSuccess(
          state,
          action
        );
      })
      .addCase("GET_FAX_NOTE_FAILURE", (state, action) => {
        unprocessedFaxDetailsSlice.caseReducers.fetchNoteForUnlinkedFaxFailure(
          state,
          action
        );
      });
  },
});

export const { resetLinkSwitchRequest } = unprocessedFaxDetailsSlice.actions;

export const unprocessedFaxDetails = (state) => state.unprocessedFaxDetails;
export default unprocessedFaxDetailsSlice.reducer;
