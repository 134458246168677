import React from "react";

class Footer extends React.Component {
  render() {
    return (
      null
    );
  }
}


export default Footer;
