import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BPagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "./Pagination.css";

class TranscriberFaxPaging extends React.Component {
  state = this.prepareState(this.props);

  componentDidUpdate(prevProps) {
    const { currentPage, totalPages } = this.props;
    if (
      currentPage !== prevProps.currentPage ||
      totalPages !== prevProps.totalPages
    ) {
      const newState = this.prepareState(this.props);
      this.setState(newState);
    }
  }
  
  prepareState(props) {
    const { currentPage, totalPages } = props;
    const firstPage = 1;
    const lastPage = totalPages > 0 ? totalPages : 1;
    const previousPage = currentPage > firstPage ? currentPage - 1 : firstPage;
    const nextPage = currentPage < lastPage ? currentPage + 1 : lastPage;
  
    return {
      currentPage,
      firstPage,
      lastPage,
      previousPage,
      nextPage,
      totalPages,
    };
  }
  

  onPageChangeManually = (e) => {
    const { value } = e.target;
    const { lastPage, firstPage } = this.state;
    if (value) {
      let page = parseInt(e.target.value, 10);
      if (page > lastPage || page < firstPage) return;
      this.props.onPageChange(page);
    } else {
      this.setState({ currentPage: "" });
    }
  };

  render() {
    const {
      currentPage,
      firstPage,
      lastPage,
      previousPage,
      nextPage,
      totalPages,
    } = this.state;
    const { onPageChange } = this.props;
    return (
      <div className="rm-pagination-box">
        <BPagination className="rm-pagination">
          <OverlayTrigger overlay={<Tooltip>Previous</Tooltip>}>
            <BPagination.Prev
              id="paginator-btn-prev"
              onClick={() => onPageChange(previousPage)}
              disabled={currentPage === firstPage}
            />
          </OverlayTrigger>
          <div className="rm-page-selector-box">
            Page
            <Form.Control
              className="rm-page-selector"
              type="number"
              min={firstPage}
              max={lastPage}
              value={currentPage !== "" ? currentPage : ""}
              onChange={this.onPageChangeManually}
              id={`paginator-page-textbox`}
            />
            of {totalPages === 0 ? 1 : totalPages}
          </div>
          <OverlayTrigger overlay={<Tooltip>Next</Tooltip>}>
            <BPagination.Next
              id="paginator-btn-next"
              onClick={() => onPageChange(nextPage)}
              disabled={currentPage === lastPage}
            />
          </OverlayTrigger>
        </BPagination>
      </div>
    );
  }
}

export default connect()(withRouter(TranscriberFaxPaging));
