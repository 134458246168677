import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchRawDetails } from "./SwitchDisposition.actions";
import { withRouter } from "react-router-dom";

import SecurePdfViewer from "components/SecurePdfViewer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";

import { sendAuthenticatedAsyncRequest } from "../../../services/AsyncRequestService";
import { getLoggedInUser } from "../../../services/AuthService";
import { SWITCH_DISPOSITION } from "rbac/RbacContentProvider";
import Colors from "../../../lookup/Colors.json";
import DismissableModal from "../../../components/DismissableModal";
import UsabilityContext from "lookup/UsabilityContext";
import SwitchDispositionForm from "components/SwitchDisposition/SwitchDispositionForm";
import { WorkflowConstants } from "lookup/WorkflowConstants";
import PersonBadge from "components/PersonBadge";
import { FormSelect, OverlayTrigger, Tooltip } from "react-bootstrap";
import InconclusiveBadge from "components/InconclusiveBadge";

const detectedDispositionColors = {
  ACCEPTED: Colors["rm-success"],
  DECLINED: Colors["rm-danger"],
  UNKNOWN: Colors["rm-warning"],
};

export const RAW_STATUSES = {
  READY: "READY",
  UNCLAIMED: "UNCLAIMED",
  CLAIMED: "CLAIMED",
  CONFIRMED: "CONFIRMED",
};

class RawSubmitForReview extends Component {
  state = {
    isCommentValid: false,
    comments: "",
    usersList: [],
    selectedUserId: "",
    failure: false,
    isUserSelected: false,
    wfItemId: null,
  };

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    sendAuthenticatedAsyncRequest(
      `/user?exclude_requester=true&context=${
        this.props.contextIsRaw ? "paw" : "swim"
      }`,
      "GET",
      null,
      (r) => this.setState({ usersList: r.data }),
      (r) => this.setState({ failure: false })
    );
  }

  submitForReview(closeModal) {
    if (this.state.isCommentValid && this.state.isUserSelected) {
      const { selectedUserId, comments } = this.state;
      this.props.triggerEventOnRaw(this.props.rawId, "ASSIGN", {
        reviewerId: selectedUserId !== "PAW" ? selectedUserId : null,
        submitToPaw: selectedUserId === "PAW",
        notesForReviewer: { note: comments },
      });
      closeModal();
    }
  }

  render() {
    const {
      comments,
      isCommentValid,
      usersList,
      failure,
      selectedUserId,
      isUserSelected,
    } = this.state;
    const modalRef = "unclaimConfirmationModal";
    const customFooterContent = (
      <div>
        <Button
          id="switchdisposition-sfr-modal-btn-cancel"
          variant="outline-dark"
          onClick={() => this.refs[modalRef].handleClose()}
        >
          Cancel
        </Button>
        <Button
          id="switchdisposition-sfr-modal-btn-submit"
          className="left-spacer"
          variant="warning"
          onClick={() => this.submitForReview(this.refs[modalRef].handleClose)}
        >
          Yes, submit this response for review
        </Button>
      </div>
    );
    return (
      <DismissableModal
        ref={modalRef}
        customFooterContent={customFooterContent}
        openModalButtonText="Submit for Review"
        openModalButtonStyle="warning"
        openModalButtonBlock={true}
        openModalButtonDisabled={this.props.disallowEditing}
        openModalButtonId="switchdisposition-btn-sfr"
        title="Submit Response for Review"
      >
        <p className="confirmation-text">
          You have selected to <b>submit</b> this response for review.
        </p>
        <p className="text-muted">
          Once you submit this response for review, the reviewer will claim this
          RAW and would be able to change its status.
        </p>
        {usersList === [] ? (
          failure ? (
            <p className="text-danger"> Failed to load operators</p>
          ) : (
            <p className="text-info"> Loading Operators ...</p>
          )
        ) : (
          <Form.Group>
            <Form.Label>Select Reviewer</Form.Label>
            <FormSelect
              id="switchdisposition-sfr-reviewer"
              value={selectedUserId}
              onChange={(e) =>
                this.setState({
                  selectedUserId: e.target.value,
                  isUserSelected: e.target.value !== "",
                })
              }
            >
              <option value="">Click to select</option>
              {usersList.map((u, k) => (
                <option value={u.id} key={k}>
                  {u.name}
                </option>
              ))}
              {SWITCH_DISPOSITION.provideSubmitToPaw(
                this.props.contextIsRaw
                  ? UsabilityContext.RAW
                  : UsabilityContext.SWIM
              )}
            </FormSelect>
            {!isUserSelected ? (
              <span className="text-danger smaller-feedback">
                Selecting a Reviewer is mandatory!
              </span>
            ) : (
              <span className="text-success smaller-feedback">Looks good!</span>
            )}
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Please enter comments for reviewer</Form.Label>
          <Form.Control
            id="switchdisposition-sfr-comments"
            as="textarea"
            rows="3"
            value={comments}
            onChange={(e) =>
              this.setState({
                comments: e.target.value,
                isCommentValid: e.target.value !== "",
              })
            }
          />
          {!isCommentValid ? (
            <span className="text-danger smaller-feedback">
              Comments are mandatory!
            </span>
          ) : (
            <span className="text-success smaller-feedback">Looks good!</span>
          )}
        </Form.Group>
      </DismissableModal>
    );
  }
}
export { RawSubmitForReview };

class SwitchDisposition extends Component {
  CONTEXT =
    this.props.location.pathname.startsWith("/raw") ||
    (this.props.location.state &&
      this.props.location.state.context === UsabilityContext.RAW)
      ? UsabilityContext.RAW
      : UsabilityContext.SWIM;

  SELECTABLE_DISPOSITIONS = SWITCH_DISPOSITION.provideSelectableDispositions(
    this.CONTEXT
  );
  state = {
    rawId: this.props.match.params.rawId,
    rawDetailsStatus: this.props.rawDetailsStatus,
    rawDetails: undefined,
    redactedFax: undefined,
    isMandatory: false,
  };

  componentDidMount() {
    this.props.fetchRawDetails(this.state.rawId);
  }

  static getDerivedStateFromProps(props, state) {
    const { rawDetailsStatus, rawDetails } = props;
    let newState = {};

    if (rawDetailsStatus !== state.rawDetailsStatus) {
      newState.rawDetailsStatus = rawDetailsStatus;
    }

    if (rawDetails !== state.rawDetails) {
      newState.rawDetails = rawDetails;
      if (rawDetailsStatus === "success" && rawDetails && !state.redactedFax) {
        newState.wfItemId = rawDetails.wfItemId;
      }
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  isOwnedByLoggedInUser = (rawDetails) =>
    rawDetails.ownerId === getLoggedInUser().uuid;
  isOwnedByAnyone = (rawDetails) =>
    rawDetails.ownerId !== 0 && rawDetails.ownerId !== null;

  renderOwnerInformation = (rawDetails) => {
    if (rawDetails.status === RAW_STATUSES.CONFIRMED) {
      return (
        <Alert variant="info">
          Disposition confirmed by{" "}
          <PersonBadge variant="info" name={rawDetails.ownerName} />
          <strong id="dispositionConfirmedBy">
            {rawDetails.ownerName || "Anonymous"}
          </strong>
        </Alert>
      );
    } else if (!this.isOwnedByAnyone(rawDetails))
      return <Alert variant="danger">Unclaimed</Alert>;
    else {
      let variant = this.isOwnedByLoggedInUser(rawDetails)
        ? "success"
        : "warning";
      return (
        <Alert variant={variant}>
          {rawDetails.referrerId ? (
            <span>
              Referred by{" "}
              <PersonBadge variant={variant} name={rawDetails.referrerName} />
              <strong id="dispositionReferredBy">
                {rawDetails.referrerName}
              </strong>{" "}
              to
              <PersonBadge variant={variant} name={rawDetails.ownerName} />
              <strong id="dispositionOwner">{rawDetails.ownerName}</strong>
              <br />
              <span id="dispositionNotes" style={{ fontSize: "0.9em" }}>
                <strong>Notes: </strong> {rawDetails.statusNote}
              </span>
            </span>
          ) : (
            <span id="dispositionClaimedByContainer">
              {rawDetails.autoClaimed ? "Auto-c" : "C"}laimed by{" "}
              <PersonBadge variant={variant} name={rawDetails.ownerName} />
              <span id="dispositionOwner">{rawDetails.ownerName}</span>
            </span>
          )}
        </Alert>
      );
    }
  };

  render() {
    const changeDisposition =
      this.props.location.state && this.props.location.state.changeDisposition
        ? true
        : false;
    const isForAccepted =
      this.props.location.state && this.props.location.state.isForAccepted
        ? true
        : false;
    const isForDeclined =
      this.props.location.state && this.props.location.state.isForDeclined
        ? true
        : false;
    const isPrescriberResponseFaxAvailable =
      this.props.location.state &&
      this.props.location.state.isPrescriberResponseFaxAvailable
        ? true
        : false;
    const { wfItemId, rawDetails, rawDetailsStatus } = this.state;
    const selectableDispositions = this.SELECTABLE_DISPOSITIONS;
    if (
      rawDetails &&
      (rawDetails.wfItemStatus === WorkflowConstants.DELIVERED_PRESCRIBER_FAX ||
        rawDetails.wfItemStatus ===
          WorkflowConstants.MISSING_PRESCRIBER_CONTACT_INFO)
    ) {
      delete selectableDispositions.ACCEPTED;
    }
    if (isForAccepted) {
      delete selectableDispositions.SUBMITTED_VIA_EHR;
    }
    if (isForDeclined) {
      delete selectableDispositions.DECLINED;
    }
    if (changeDisposition && !isPrescriberResponseFaxAvailable) {
      delete selectableDispositions.ACCEPTED;
    }
    const contextIsRaw = this.CONTEXT === UsabilityContext.RAW;
    if (rawDetailsStatus === "failure") return "Failed to fetch RAW details";
    else if (rawDetailsStatus === "requested" || !rawDetails)
      return "Fetching Response Assessment Workflow ... ";
    const {
      globalSwitchId,
      detectedDisposition,
      disposition,
      targetOptions,
      isInconclusive,
      responseTypeList,
    } = rawDetails;
    const isRawConfirmed = rawDetails.status === RAW_STATUSES.CONFIRMED;
    const disableActions =
      (!this.isOwnedByLoggedInUser(rawDetails) && !changeDisposition) ||
      (isRawConfirmed && !changeDisposition);

    const pdfUrl =
      rawDetails.wfItemStatus === WorkflowConstants.DELIVERED_PRESCRIBER_FAX
        ? `/pdf/${rawDetails.customerId}/prescriber-inbound-or-generated-redacted?switch_id=${rawDetails.switchId}`
        : `/pdf/prescriber-inbound-redacted-fax/${wfItemId}`;
    return (
      <Container fluid>
        <Row>
          <Col md={5}>
            {contextIsRaw ? (
              <Breadcrumb>
                <Breadcrumb.Item href={`/raw/list`}>
                  Response List
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Switch {rawDetails.globalSwitchId}
                </Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              <Breadcrumb>
                <Breadcrumb.Item href={`/switch/list`}>
                  Switch List
                </Breadcrumb.Item>
                <Breadcrumb.Item href={`/switch/${wfItemId}`}>
                  Switch {globalSwitchId}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Disposition</Breadcrumb.Item>
              </Breadcrumb>
            )}
            {rawDetails.workflowItemStatus === "PENDING_RESPONSE_REVIEW" ? (
              <Row>
                <Col xs={12}>
                  <p style={{ fontSize: "1.2em" }}>
                    <Badge bg="warning" pill>
                      Forwarded from SWIM
                    </Badge>{" "}
                    &nbsp;
                    {rawDetails.forwardToPawNote ? (
                      <small>
                        <strong>Comment:</strong> {rawDetails.forwardToPawNote}
                      </small>
                    ) : null}
                  </p>
                </Col>
              </Row>
            ) : null}

            <Row className="tiny-top-spacer">
              <Col id="dispositionOwnerInfo" md={12}>
                {this.renderOwnerInformation(rawDetails)}
              </Col>
              <Col
                id="dispositionForContainer"
                md={12}
                style={{ fontSize: "1.5em" }}
              >
                <span className="text-muted">Disposition for </span>
                {contextIsRaw ? (
                  <a
                    href={`/raw/${rawDetails.rawId}/wf/${rawDetails.wfItemId}`}
                  >
                    <u>Switch # {rawDetails.globalSwitchId}</u>
                  </a>
                ) : (
                  <span>
                    Switch # <span id="globalSwitchId">{globalSwitchId}</span>
                  </span>
                )}
                &nbsp;
                {rawDetails.isControlled ? (
                  <Badge bg="warning" pill>
                    Controlled
                  </Badge>
                ) : null}
              </Col>
            </Row>
            <hr />
            {isRawConfirmed && disposition && !changeDisposition ? (
              <div
                id="confirmedDispositionContainer"
                style={{
                  border: `2px solid ${
                    detectedDispositionColors[disposition.disposition]
                  }`,
                  borderRadius: "10px",
                  padding: 10,
                }}
                className="text-center top-spacer bottom-spacer"
              >
                Confirmed Disposition:{" "}
                <span
                  id="confirmedDisposition"
                  style={{
                    fontSize: "1.2em",
                    letterSpacing: "1px",
                    textDecoration: "underline",
                  }}
                >
                  {disposition.disposition}
                  <InconclusiveBadge
                    inconclusiveResult={disposition.inconclusiveResult}
                    responseTypeList={disposition.responseTypeList}
                  />
                </span>
              </div>
            ) : detectedDisposition && !changeDisposition ? (
              <div
                id="switchDispositionContainer"
                style={{
                  border: `2px solid ${detectedDispositionColors[detectedDisposition]}`,
                  borderRadius: "10px",
                  padding: 10,
                }}
                className="text-center top-spacer bottom-spacer"
              >
                Auto Accept Detection:{" "}
                <span
                  id="detectedDisposition"
                  style={{
                    fontSize: "1.2em",
                    letterSpacing: "1px",
                    textDecoration: "underline",
                  }}
                >
                  {detectedDisposition}
                  <InconclusiveBadge
                    inconclusiveResult={isInconclusive}
                    responseTypeList={responseTypeList}
                  />
                </span>
              </div>
            ) : null}
            <SwitchDispositionForm
              disposition={disposition}
              isRawConfirmed={isRawConfirmed}
              disableActions={disableActions}
              globalSwitchId={globalSwitchId}
              selectableDispositions={selectableDispositions}
              rawDetails={rawDetails}
              targetOptions={targetOptions}
              contextIsRaw={contextIsRaw}
              isSubmissionRequestFromSwim={
                this.props.location.state &&
                this.props.location.state.isSubmissionRequestFromSwim
                  ? true
                  : false
              }
              changeDisposition={
                this.props.location.state &&
                this.props.location.state.changeDisposition
                  ? true
                  : false
              }
            />
          </Col>

          <Col md={7}>
            {
              <SecurePdfViewer
                pdfHeight={705}
                displayToolBar={false}
                url={pdfUrl}
              />
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    rawDetailsStatus: state.switchDisposition.rawDetailsStatus,
    rawDetails: state.switchDisposition.rawDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRawDetails: bindActionCreators(fetchRawDetails, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwitchDisposition));
