import React, { useMemo, useState, useEffect, useReducer } from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "react-table-7";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import PaginationComponent from "./PaginationComponent";
import "./style.css";
import {
  columnsContent,
  useReactTableHooks,
  renderNoDataComponent,
  renderTableHeaderContent,
  renderTableRowsContent,
  getRowId,
} from "./commonReactTableFunctions";

const ServerSideReactTable = ({
  columnDefination,
  data = [],
  currentPage,
  totalPages,
  totalRecords,
  defaultPageSize,
  attributeName,
  onUpdateTableData,
  loading,
  enableMultiRowSelection = true,
  addRowSelection = false,
  overrideRowId = null,
  onRowSelect,
  filters,
  onRowClick,
  sorted,
  CustomPaginationComponent,
  extraPropsForCustomComponent,
  customPagination = true,
  customFilters = true,
  customSorting = true,
  enableMultiSort = true,
  enableExpansion = false,
  toggleAllRowsSelect = null,
  enableAllRowSelect = true,
  enableRowSelection = true,
  globalSelected = null,
  pageSizeOptions = [5, 10, 15, 20, 30, 50],
  resetTableSelection = false,
  // addSubrows = null,
  columnShow = Object.fromEntries(
    columnDefination.map((column) => [
      column.accessor,
      column.show === undefined || column.show ? true : column.show,
    ])
  ),
  trStyle = (row) => {
    return {};
  },
  tdStyle = (row, column) => {
    return {};
  },
  clicked = [],
  dataTestId,
}) => {
  const [filterValues, setFilterValues] = useState(filters);
  const [sortValues, setSortBy] = useState(sorted);
  const [toggleAllRowsSelectValue, setToggleAllRowsSelect] =
    useState(toggleAllRowsSelect);
  const [selectedRowIds, setSelectedRows] = useState({}); // NEW: Selected row IDs state
  const [columnVisibility, setColumnVisibility] = useReducer(
    columnVisibilityReducer,
    columnShow
  );
  const [expanded, setExpanded] = useState({});
  function columnVisibilityReducer(state, action) {
    let newState = {};
    newState = Object.assign(newState, action);
    return newState;
  }
  const handleRowClick = (row, cell) => {
    row.column = cell.column;
    // Call the onRowClick function with the row data
    if (
      onRowClick &&
      cell.column.id !== "select" &&
      cell.column.id !== "option"
    ) {
      onRowClick(row);
      clicked.push(true);
    }
  };
  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  const [{ pageIndexPrior, pageSizePrior }, setPaginationPrior] =
    React.useState({
      pageIndexPrior: pageIndex,
      pageSizePrior: pageSize,
    });

  const columns = useMemo(
    () =>
      columnsContent(
        columnDefination,
        addRowSelection,
        enableExpansion,
        enableAllRowSelect
      ),
    [columnDefination]
  );

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const getRowKey = (row, index, page) => {
    return getRowId(row, index, overrideRowId, currentPage);
  };

  const tableConfig = {
    columns,
    data: data ? data : [],
    initialState: {
      filters: [],
      selectedRowIds: {},
      pagination: {
        pageSize: defaultPageSize,
      },
    },
    state: {
      rowSelection: selectedRowIds,
      sorting: sortValues,
      columnFilters: filterValues,
      pagination: pagination,
      columnVisibility: columnVisibility,
      expanded,
    },
    pageCount: totalPages,
    enableRowSelection: addRowSelection,
    onRowSelectionChange: setSelectedRows,
    enableColumnResizing: true,
    enableMultiRowSelection: enableMultiRowSelection,
    enableRowSelection: enableRowSelection,
    enableMultiSort: true,
    manualFiltering: customFilters,
    manualPagination: customPagination,
    onColumnFiltersChange: setFilterValues,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    getSubRows: enableExpansion ? (originalRow) => originalRow[pivot] : null,
    getRowId: getRowKey,
  };
  if (customSorting) {
    tableConfig.manualSorting = true;
    tableConfig.onSortingChange = setSortBy;
  }

  const table = useReactTable(tableConfig, (hooks) => {
    useReactTableHooks(hooks);
  });

  useEffect(() => {
    if (addRowSelection) {
      onRowSelect(selectedRowIds);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (
      JSON.stringify(sorted) !== JSON.stringify(sortValues) ||
      JSON.stringify(filters) !== JSON.stringify(filterValues)
    ) {
      table.setPageIndex(0);
      onUpdateTable(
        filterValues,
        sortValues,
        pagination.pageSize,
        pagination.pageIndex
      );
    }
    if (selectedRowIds > 0) {
      table.resetRowSelection(true);
    }
  }, [filterValues, sortValues]);

  useEffect(() => {
    if (
      pagination.pageIndex != pageIndexPrior ||
      pagination.pageSize != pageSizePrior
    ) {
      setPaginationPrior({
        pageIndexPrior: pagination.pageIndex,
        pageSizePrior: pagination.pageSize,
      });
      if (data.length > 0) {
        onUpdateTable(
          filterValues,
          sortValues,
          pagination.pageSize,
          pagination.pageIndex
        );
      }
    }

    if (selectedRowIds > 0) {
      table.resetRowSelection(true);
    }
  }, [pagination]);

  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(filterValues)) {
      setFilterValues(filters);
    }
  }, [filters]);

  useEffect(() => {
    toggleColumnVisibility();
    //onUpdateTable(filterValues, sortValues, pagination.pageSize, pagination.pageIndex);
  }, [columnVisibility]);

  useEffect(() => {
    if (JSON.stringify(columnShow) !== JSON.stringify(columnVisibility)) {
      setColumnVisibility(columnShow, true);
    }
  }, [columnShow]);

  useEffect(() => {
    if (JSON.stringify(sorted) !== JSON.stringify(sortValues)) {
      setSortBy(sorted);
    }
  }, [sorted]);

  useEffect(() => {
    if (toggleAllRowsSelect !== toggleAllRowsSelectValue) {
      setToggleAllRowsSelect(toggleAllRowsSelect);
    }
  }, [toggleAllRowsSelect]);

  useEffect(() => {
    if (toggleAllRowsSelectValue) {
      if (globalSelected) {
        let selection = {};
        globalSelected.forEach((id, i) => (selection[id] = true));
        setSelectedRows(selection);
      }
    } else {
      table.resetRowSelection(true);
    }
  }, [toggleAllRowsSelectValue]);

  useEffect(() => {
    table.resetRowSelection(true);
  }, [resetTableSelection]);

  const onUpdateTable = (filterValues, sortValues, pageSize, page) => {
    onUpdateTableData(filterValues, sortValues, pageSize, page);
  };

  const toggleColumnVisibility = () => {
    table.getAllLeafColumns().forEach((column) => {
      column.toggleVisibility(
        columns.filter((col) => col.id === column.id)[0].show
      );
    });
  };

  const renderLoader = () => {
    if (loading) {
      return (
        <div className={"overlay"}>
          <Spinner
            animation="border"
            variant="primary"
            className={"table-spinner"}
          />
        </div>
      );
    }
    return null;
  };

  const renderTableHeader = useMemo(
    () => renderTableHeaderContent(table.getHeaderGroups()),
    [
      table,
      filterValues,
      filters,
      selectedRowIds,
      data,
      table.getState().pagination.pageIndex,
      table.getState().pagination.pageSize,
      clicked,
    ]
  );

  const renderTableRows = useMemo(
    () =>
      renderTableRowsContent(
        table.getRowModel().rows,
        handleRowClick,
        trStyle,
        tdStyle
      ),
    [
      table,
      selectedRowIds,
      data,
      table.getState().pagination.pageIndex,
      table.getState().pagination.pageSize,
      clicked,
    ]
  );

  return (
    <Row>
      <Col>
        <div className={'table-horizontal-scroll'}>
          <Table bordered>
            <thead data-testid={`thead-${dataTestId}`}>
              {renderTableHeader}
            </thead>
            <tbody data-testid={`tbody-${dataTestId}`}>
              <>
                {renderTableRows}
                {renderNoDataComponent(
                  table.getRowModel().rows.length,
                  columns.length
                )}
              </>
            </tbody>
          </Table>
        </div>
        {CustomPaginationComponent ? (
          React.cloneElement(
            React.cloneElement(CustomPaginationComponent, {
              callGroupData: extraPropsForCustomComponent,
              currentPage: table.getState().pagination.pageIndex,
              totalPages: table.getPageCount(),
              pageSizeOptions: pageSizeOptions,
              selectedPageSize: table.getState().pagination.pageSize,
              attributeName: attributeName,
              onChangePage: (pageNo) => {
                table.setPageIndex(pageNo);
              },
              onChangePageSize: (e) => {
                table.setPageIndex(0);
                table.setPageSize(e);
              },
              totalRecords: totalRecords,
            })
          )
        ) : (
          <PaginationComponent
            currentPage={table.getState().pagination.pageIndex}
            totalPages={table.getPageCount()}
            pageSizeOptions={pageSizeOptions}
            selectedPageSize={table.getState().pagination.pageSize}
            attributeName={attributeName}
            onChangePage={(pageNo) => {
              table.setPageIndex(pageNo);
            }}
            onChangePageSize={(e) => {
              table.setPageIndex(0);
              table.setPageSize(e);
            }}
            totalRecords={totalRecords}
          />
        )}
        {renderLoader()}
      </Col>
    </Row>
  );
};

export default ServerSideReactTable;
