import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

const OverlayBadge = (props) => {
  return (
    <OverlayTrigger
      placement={props.placement}
      overlay={
        <Tooltip>
          {props.hoverText}
        </Tooltip>
      }
    >
      <Badge pill bg={props.bg}>
        {props.badgeText}
      </Badge>
    </OverlayTrigger>
  );
};

export default OverlayBadge;
