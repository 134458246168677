const CAT_CONSTANTS = {
  switchLookupPerspectives: {
    SourceGroup: "source-group",
    Target: "target",
  },
  codexEntities: {
    SwitchLookup: "switch-lookup",
    Target: "target",
  },
  targetGroupTypes: {
    SINGLEDRUG: "SINGLEDRUG",
    MULTIDRUG: "MULTIDRUG",
    UNSET: "UNSET",
  },
  defaultNewTargetGroupName: "NEW_TARGET_GROUP",
  defaultNewTargetName: "ENTER TARGET NAME",
  multiDrugRegimenName: "MULTI DRUG REGIMEN",
  customFilters: {
    NO_TARGET_GROUP: {
      value: "CUSTOM_FILTER_NO_TARGET_GROUP",
      label: "Missing Target",
    },
  },
  noTargetIndicatorId: "NO_TARGET_FOR_SELECTED_SWITCH_LOOKUP",
  noSwitchLookupIndicatorId: "NO_SWITCH_LOOKUP",
  fieldMaxLengthValidationPrefix: "Maximum Length: ",
  fieldMinLengthValidationPrefix: "Minimum Length: ",
  defaultNewSourceGroupId: "NEW_SOURCE_GROUP_ID",
  codexStatuses: {
    ACTIVE: "ACTIVE",
    IN_DEVELOPMENT: "IN_DEVELOPMENT",
    PARTIALLY_APPROVED: "PARTIALLY_APPROVED",
    APPROVED: "APPROVED",
    ARCHIVED: "ARCHIVED",
  },
  codexColorsForStatuses: {
    ACTIVE: { color: "red", variant: "success", text: "white" },
    IN_DEVELOPMENT: { color: "green", variant: "info", text: "black" },
    PARTIALLY_APPROVED: { color: "yellow", variant: "warning", text: "white" },
    APPROVED: { color: "yellow", variant: "warning", text: "black" },
    ARCHIVED: { color: "grey", variant: "light", text: "black" },
  },

  dynamicSwitchGroupListSortValues: [
    { label: "Switches", value: "Switch-Target" },
    { label: "Targets", value: "Target-Switch" },
  ],

  dynamicSwitchGroupListSortConsts: {
    SWITCH_TO_TARGET: "Switch-Target",
    TARGET_TO_SWITCH: "Target-Switch",
  },

  dynamicSwitchOptionTypes: {
    SWITCH: "switch",
    TARGET: "target",
  },
  dynamicSwitchOptionTypeLabels: {
    SWITCH: "Switches",
    TARGET: "Targets",
  },
  negativeFieldValueError: "Value should greater than or equal to zero",
  maxIntegerValueValidationPrefix: "Value cannot be greater than ",
  minIntegerValueValidationPrefix: "Value cannot be less than ",
  defaultMedDrugsSort: [
    {
      id: "medName",
      desc: false,
      isDefault: true,
    },
  ],
  bgCodeFilter: [
    { key: "All", value: "All" },
    { key: "B", value: "B" },
    { key: "G", value: "G" },
  ],
  defaultActiveRadarSort: [
    {
      id: "arDrugCategory",
      desc: false,
      isDefault: true,
    },
  ],
  dynamicSwitchItemSelectionStatus: {
    PARTIALLY_SELECTED: "PARTIALLY_SELECTED",
    FULLY_SELECTED_OR_UNSELECTED: "FULLY_SELECTED_OR_UNSELECTED",
  },
};

export default CAT_CONSTANTS;

export const ROUTES_OF_ADMINISTRATION_FOR_SELECT = [
  { key: "CO", value: "Combination" },
  { key: "EX", value: "External" },
  { key: "IJ", value: "Injection" },
  { key: "IM", value: "Intramuscular" },
  { key: "IN", value: "Inhalation" },
  { key: "NA", value: "Nasal" },
  { key: "OP", value: "Ophthalmic" },
  { key: "PO", value: "Oral" },
  { key: "SC", value: "Subcutaneous" },
  { key: "SL", value: "Sublingual" },
  { key: "TD", value: "Transdermal" },
  { key: "VA", value: "Vaginal" },
];

/* Route of Administration Ref

Drug Administration Route - the allowed values are as follows:
BU = Bucal
CO = Combination
DT = Dental
EP = Epidural
EX = External
IA = Intra-arterial
IC = Intracavernosal
ID = Intradermal
IJ = Injection
IL = Implant
IM = Intramuscular
IN = Inhalation
IO = Intraocular
IP = Intraperitoneal
IR = Irrigation
IS = Intravesical
IT = Intrathecal
IU = Intrauterine
IV = Intravenous
IX = Intra-articular
MT = Mouth/Throat
NA = Nasal
OP = Ophthalmic
OR = Oral
OT = Otic
PF = Perfusion
PL = Intrapleural
RE = Rectal
SC = Subcutaneous
SL = Sublingual
TD = Transdermal
TL = Translingual
UR = Urethral
VA = Vaginal
VI = In Vitro
XX = Does Not Apply
________________________________________
AU	Auricular (Otic); administration to or by way of the ear.
BUC	Buccal; referring to the inside lining of the cheeks and is part of the lining mucosa.
EPI	Epidural; administration upon or over the dura mater.
IA	Intra-arterial; administration within an artery or arteries.
IART	Intra-articular; administration within a joint.
ICAR	Intracardiac; administration with the heart.
IDUC	Intraductal; administration within the duct of a gland.
IFOL	Intrafollicular; administered within the follicle.
IGAS	Intragastric; administration within the stomach.
ILES	Intralesional; administration within or introduced directly into a localized lesion.
IM	Intramuscular; administration within a muscle.
IMAM	Intramammary; administered within the teat canal.
IMRS	Immersion; administered by immersing completely in solution.
INF	Infiltration; administered in a diffuse pattern to multiple sites.
INH	Respiratory (Inhalation); administration within the respiratory tract by inhaling orally or nasally for local or systemic effect.
IP	Intraperitoneal; administration within the peritoneal cavity.
ISIN	Intrasinal; administration within the nasal or periorbital sinuses.
ISYN	Intrasynovial; administration within the synovial cavity of a joint.
IT	Intrathecal; administration within the cerebrospinal fluid at any level of the cerebrospinal axis, including injection into the cerebral ventricles.
ITES	Intratesticular; administration within the testicle.
IU	Intrauterine; administration within the uterus.
IV	Intravenous; administration within or into a vein or veins.
IVES	Intravesical; administration within the bladder.
NAS	Nasal; administration to the nose; administered by way of the nose.
NG	Nasogastric; administration through the nose and into the stomach, usually by means of a tube.
OU	Ophthalmic; administration to the external eye.
PAR	Parenteral; administration by injection, infusion, or implantation.
PNEU	Perineural; administration surrounding a nerve or nerves.
PO	Oral; administration to or by way of the mouth.
PV	Vaginal; administration into the vagina.
REC	Rectal; administration to the rectum.
SC	Subcutaneous; administration beneath the skin; hypodermic. Synonymous with the term SUBDERMAL.
SCi	Subcutaneous Implant; administered by placing under the skin.
SL	Sublingual; administration beneath the tongue.
TOP	Topical; administration to a particular spot on the outer surface of the body.
TOPp	Topical, Pour On; administered to the skin by pouring liquid over the back.
TD	Transdermal; administered through the dermal layer of the skin.
*/
