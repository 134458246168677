import { RSAA } from 'redux-api-middleware';
import config from 'config';
import {AUTH_TOKEN_HEADER_KEY, getAuthorizationToken} from 'services/AuthService';

export const getSwitchDetails = (wfItemId) => {
  return {
  
    [RSAA]: {
      endpoint: `${config.apiRoot}/swim/switch-details?wf_item_id=${wfItemId}`,
      method: 'GET',
      headers: { 'Content-Type':'application/json', [AUTH_TOKEN_HEADER_KEY] : getAuthorizationToken()},
      types: [
        'GET_SWITCH_DETAILS_REQUEST',
        'GET_SWITCH_DETAILS_SUCCESS',
        'GET_SWITCH_DETAILS_FAILURE'
      ]
    }
  };
};

export const getSwitchTimeline = (wfItemId) => {
  return {
  
    [RSAA]: {
      endpoint: `${config.apiRoot}/swim/switch-timeline?wf_item_id=${wfItemId}`,
      method: 'GET',
      headers: { 'Content-Type':'application/json', [AUTH_TOKEN_HEADER_KEY] : getAuthorizationToken()},
      types: [
        'GET_SWITCH_TIMELINE_REQUEST',
        'GET_SWITCH_TIMELINE_SUCCESS',
        'GET_SWITCH_TIMELINE_FAILURE'
      ]
    }
  };
};

