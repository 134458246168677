import { memo } from "react"
import Col from "react-bootstrap/Col";
import ToggleIconLinkButton from "components/ToggleIconLinkButton";
import DescriptionField from "../../../commons/fields/DescriptionField";
import { MdNoteAdd } from "react-icons/md";
import { useState } from "react";

const DrugOptionPharmacistNotes = ({
  isEditMode = false,
  value = undefined,
  error = undefined,
  updatedValue = undefined,
  handleChange = () => { },
  handleBlur = () => { },
  isFieldRequired = () => { },
  dynamicSwitchOptionId = undefined,
  predecessorValue = undefined,
  isDeltaUpdated = undefined,
}) => {
  const [showNotesTextArea, setShowNotesTextArea] = useState(value ? true : false);

  const toggleButton = () => {
    setShowNotesTextArea(!showNotesTextArea);
  }

  return (
    <Col md={12}>
      {isEditMode && !value &&
        !showNotesTextArea ? (
        <ToggleIconLinkButton
          shouldDisplayFirstTag={true}
          toggleButton={toggleButton}
          FirstIcon={MdNoteAdd}
          firstTag={"Add Note"}
          SecondIcon={null}
          secondTag={null}
        />
      ) : (
        <DescriptionField
          isEditMode={isEditMode}
          name={"Notes"}
          id={"pharmacistNotes"}
          error={error}
          savedValue={value}
          updatedValue={updatedValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          isFieldRequired={isFieldRequired}
          compKey={"drug-option-form-pharmacist-notes"}
          controlProps={{
            as: "textarea",
            rows: 1,
            placeholder: "Notes or comments about this drug"
          }}
          displayIfEmpty={false}
          key={`drug-option-notes-${dynamicSwitchOptionId}`}
          predecessorValue={predecessorValue}
          isUpdated={isDeltaUpdated}
        />
      )}
    </Col>
  );
};

export default memo(DrugOptionPharmacistNotes);