import DismissableModal from 'components/DismissableModal';
import OriginalClaimPopup from 'components/PawDetails/OriginalClaimPopup';
import PatientHistory from 'components/PawDetails/PatientHistory';
import PatientInformationCard from 'components/PawDetails/PatientInformationCard';
import ProjectedSavingsCard from 'components/PawDetails/ProjectedSavingsCard';
import SecurePdfViewer from 'components/SecurePdfViewer';
import ProtectedHealthInformation from 'components/SwitchDetails/ProtectedHealthInformation';
import { formatDateForDisplay } from '../../services/MomentUtils';
import React from 'react';
import { Button, Col, Container, Row, Table, ProgressBar, Card } from 'react-bootstrap';
import { STATUS_BADGE_COLORS, SWITCHES_STATUSES } from "../../views/Paw/DrugGroup/DrugGroup.constants";
import ApprovePaw from 'components/PawStatus/ApprovePaw';
import { getLoggedInUser } from 'services/AuthService';
import RejectPaw from 'components/PawStatus/RejectPaw';
import { useSelector } from "react-redux";
import DivLoader from 'layouts/components/DivLoader';
const headerStyles = {
  div: {
    display: "flex",
    justifyContent: "space-between"
  },
  switchNameDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  textStyle: {
    fontSize: "1rem",
    fontWeight: "900"
  },
  container: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: 10,
    marginLeft: 20,
  },
  rejectionReason: {
    fontSize: "1rem",
    color: '#AA483E',
    fontWeight: '900',
    whiteSpace: 'pre-wrap'
  }
}

const SwitchDetails = (props) => {
  let customerId = props.switchdetails.rirCustomerInfo.customerId;
  let pdfSwitchId = props.switchdetails.rirSwitchId;
  let currentUserId = getLoggedInUser().uuid;
  const drugDetails = useSelector(state => state.drugGroupDetail.drugGroupDetail);
  const switchLoader = useSelector(state => state.drugGroupDetail.switchLoader);
  const isCurrentUserOwner = currentUserId == drugDetails.ownerId;
  let status = props.status;
  const currentIndex=props.currentIndex+1;
  const totalSwitches=props.totalCount;
  const isStatusVisible=props.status == 'OPEN' || props.status == 'REOPEN' || props.status == 'SUBMITTED_FOR_REVIEW';
  
  const pawActionType = (history) => {
    if (history.actionType === "PULLED_FROM_RIR") {
      return "Pharmacist Approval Workflow started for this switch";
    } else if (history.actionType === "OWNER_CHANGED") {
      return (
        <span>
          {
            history.owner === null || history.owner === undefined
              ? <span>Unclaimed by <strong>{history.modifier.name}</strong></span>
              : <span>Claimed by <strong>{history.owner.name}</strong></span>
          }
        </span>
      );

    } else if (history.actionType === "STATUS_CHANGED") {
      return (
        <span>
          Status changed to <strong>{PawConstants.statusLabels[history.status]}</strong>
          {
            history.status === PawConstants.status.SubmittedForReview
              ? <span> to <strong>{history.owner.name}</strong> </span>
              : null
          }
        </span>
      );
    } else if (history.actionType === "NOTE_ADDED") {
      return (
        <span>
          Note added: {history.comments}
        </span>
      );

    } else return "No action available";
  }
  return (
    <Col md={12} lg={7}>
      <DivLoader isLoading={switchLoader}>
      <div style={headerStyles.container}>
        <Row xs={12}>
          <div style={headerStyles.div}>
            <Button disabled={props.currentIndex == 0 ? "disabled" : ""} onClick={() => { props.moveIndexBackward() }} style={headerStyles.textStyle}>{"<"}</Button>
            <div style={headerStyles.switchNameDiv}>
              <span>{currentIndex + " / " + totalSwitches}</span>
              <div style={headerStyles.div}>
                <span style={headerStyles.textStyle}>{props.switchdetails.globalSwitchId}</span>
                <ProgressBar now={60} />
              </div>
            </div>
            <Button disabled={currentIndex < props.totalCount ? "" : "disabled"} onClick={() => { props.moveIndexForward() }} style={headerStyles.textStyle}>{">"}</Button>
          </div>
        </Row>
        <div>
          <Row xs={11}>
            <Col sm={12} md={5} className={"pb-3 pt-3"}>
              <PatientHistory
                listGroupStyle={{ maxHeight: 500 }}
                patientHistory={props.switchdetails.rirMedHistInfoList}
                switchId={props.switchdetails.globalSwitchId}
              />
            </Col>
            <Col className="d-flex flex-column justify-content-between pb-3">
              <PatientInformationCard
                patient={{
                  dateOfBirth: props.switchdetails.rirMemberInfo.memberDateOfBirth,
                  memberNumber: props.switchdetails.rirMemberInfo.memberNumber,
                  gender: props.switchdetails.rirMemberInfo.gender
                }}
              />
              <ProjectedSavingsCard
                paid={props.switchdetails.currentCost}
                projected={props.switchdetails.projectedCost}
                savings={props.switchdetails.projectedSavings} />
              <OriginalClaimPopup switchInfo={props.switchdetails} />
              <DismissableModal
                openModalButtonText="Generated Pdf"
                openModalButtonBlock={true}
                openModalButtonId='druggroupdetail-btn-generated-fax-popup'
                title="Redacted Generated Pdf"
                size="lg"
              >
                {<SecurePdfViewer displayToolBar={false} url={`/pdf/${customerId}/prescriber-inbound-or-generated-redacted?switch_id=${pdfSwitchId}`} />}
              </DismissableModal>
            </Col>
          </Row>
          {isStatusVisible? <Row className='justify-content-between pb-3'>
            <Col xs={4}>
              <RejectPaw
                rejectionReasonsToNotConsiderTargetDrug={["History of suggested medication(s)",
                "Not a chronic condition",
                "Incorrect source medication",
                "Patient failed multiple alternatives",
                "Wait for future codex",
                "Switch on Hold",
                "Incorrect fax template",
                "Clinical Parameters- patient too young or too old",
                "Patient no longer on source medication",
                "Patient previously declined",
                "Fax content or generation error",
                "Denied cost savings do not justify the switch",
                "other"]}
                disallowEditing={!isCurrentUserOwner}
                rejectionReasons={["History of suggested medication(s)",
                  "Not a chronic condition",
                  "Incorrect source medication",
                  "Patient failed multiple alternatives",
                  "Wait for future codex",
                  "Switch on Hold",
                  "Incorrect fax template",
                  "Clinical Parameters- patient too young or too old",
                  "Patient no longer on source medication",
                  "Denied cost savings do not justify the switch",
                  "Patient previously declined",
                  "Fax content or generation error",
                  "other"]}
                targetOptions={props.switchdetails.targetOptions}
                attemptStatusUpdate={props.rejectSwitch}
                wfId={props.switchdetails.wfItemId} />
            </Col>
            <Col xs={4}>
              <ApprovePaw 
                disallowEditing={!isCurrentUserOwner}
                attemptStatusUpdate={props.approveSwitch} 
                wfId={props.switchdetails.wfItemId} />
            </Col>
          </Row> : 
          status ? 
            <Row className="top-spacer">
              <Col>
                {props.switchdetails.rejectionReason ? 
                  <Row>
                  <div className="d-flex justify-cotent-center align-items-center">
                    <div style={headerStyles.rejectionReason}>{"Rejection Reason:   "}</div>
                    {props.switchdetails.rejectionReason}
                  </div>
                </Row> 
                : null}
                <Row>
                  <Col className="top-spacer">
                    <Card style={{ borderColor: STATUS_BADGE_COLORS[status]  }}>
                      <Card.Body className="status-box py-2">
                        <Col md={12} className="text-center justify-content-center">
                          Status:
                          <strong className="status-text" style={{ color: STATUS_BADGE_COLORS[status] }}>
                            {SWITCHES_STATUSES[status]}
                          </strong>
                        </Col>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          : null }
        </div>
      </div>
      </DivLoader>
    </Col>
  )
}
export default SwitchDetails;