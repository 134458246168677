import config from "config";
import { RSAA } from "redux-api-middleware";
import {
  AUTH_TOKEN_HEADER_KEY,
  getAuthorizationToken,
} from "../../../services/AuthService";
import { DEFAULT_FILTER } from "./DrugGroup.constants";
import { RESET_PAGE_TO_ZERO } from "./DrugGroup.slice";

export const getDrugGroupsRequestAction = "GET_DRUG_GROUPS_REQUEST";
export const getDrugGroupsSuccessAction = "GET_DRUG_GROUPS_SUCCESS";
export const getDrugGroupsFailureAction = "GET_DRUG_GROUPS_FAILURE";

export const resetList = () => (dispatch) => {
  dispatch(RESET_PAGE_TO_ZERO());
};

export const getDrugGroupList =
  (pageSize, page, sorted, filteredData, pawGroupId) =>
  (dispatch, getState) => {
    let filtered = JSON.parse(JSON.stringify(filteredData));
    const allStatusIndex = filtered.findIndex(
      (x) => x.id == "status" && x.value == "All"
    );
    if (allStatusIndex > -1) {
      filtered.splice(allStatusIndex, 1);
    }
    const defaultFilterIndex = filtered.findIndex(
      (x) => x.id == "status" && x.value == "DEFAULT_FILTERS"
    );
    if (defaultFilterIndex > -1) {
      filtered[defaultFilterIndex].value = DEFAULT_FILTER[0].value;
      filtered[defaultFilterIndex].operator = DEFAULT_FILTER[0].operator;
      filtered[defaultFilterIndex].isdefault = DEFAULT_FILTER[0].isdefault;
    }
    const openFilterIndex = filtered.findIndex(
      (x) => x.id == "status" && x.value == "OPEN" && !x.operator
    );
    if (openFilterIndex > -1) {
      filtered[openFilterIndex].operator = "EQ";
    }
    const ownerNameIndex = filtered.findIndex(
      (x) =>
        x.id == "ownerName" && !Array.isArray(x.value) && x.value.includes(",")
    );
    if (ownerNameIndex > -1) {
      filtered[ownerNameIndex].value =
        filtered[ownerNameIndex].value.split(",");
    }
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/paw-group/${pawGroupId}/drug-groups/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        body: JSON.stringify({ pageSize, page, sorted, filtered }),
        types: [
          getDrugGroupsRequestAction,
          {
            type: getDrugGroupsSuccessAction,
            payload: async (action, state, res) => {
              const data = await res.json();
              return {
                pageSize: pageSize,
                sorted: sorted,
                filtered: filteredData,
                ...data,
              };
            },
          },
          getDrugGroupsFailureAction,
        ],
      },
    };

    return dispatch(rsaaObject);
  };
