import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const getUpdateDynamicSwitchGroupList = (pageSize, page, sorted, filtered, codexVersionId) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/cat/codex-version/${codexVersionId}/updated-dynamic-switch-groups/`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        'DELTA_DSG_LIST_REQUEST',
        'DELTA_DSG_LIST_SUCCESS',
        'DELTA_DSG_LIST_FAILURE'
      ]
    }
  };
};

export const updatedDynamicSwitchGroupListState = (state) => {
  return {
    type: "DELTA_DSG_LIST_STATE",
    payload: {listState: state}
  }
};

export const resetError = () => {
  return {
    type: "RESET_ERROR_DELTA_DSG_LIST"
  }
};
