import { RSAA } from 'redux-api-middleware';
import config from '../../../config';
import {AUTH_TOKEN_HEADER_KEY, getAuthorizationToken} from '../../../services/AuthService';

// unclaim yes
// submit for review no
// forward to paw yes

export const triggerEventOnRaw = (rawId, event, body) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/raw/wf/${rawId}?event=${event}`, 
      method: 'POST',
      body: JSON.stringify(body),
      headers: {'Content-Type':'application/json',[AUTH_TOKEN_HEADER_KEY] : getAuthorizationToken()},
      types: [
        'TRIGGER_EVENT_REQUEST',
        'TRIGGER_EVENT_SUCCESS',
        'TRIGGER_EVENT_FAILURE'
      ]
    }
  };
}

export const fetchRawDetails = (rawId) => {
  return {
  
    [RSAA]: {
      endpoint: `${config.apiRoot}/raw/${rawId}`,
      method: 'GET',
      headers: {'Content-Type':'application/json',[AUTH_TOKEN_HEADER_KEY] : getAuthorizationToken()},
      types: [
        'FETCH_RAW_REQUEST',
        'FETCH_RAW_SUCCESS',
        'FETCH_RAW_FAILURE'
      ]
    }
  };
};

export const submitDisposition = (rawId, disposition) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/raw/wf/${rawId}?event=CONFIRM`, 
      method: 'POST',
      headers: {'Content-Type':'application/json',[AUTH_TOKEN_HEADER_KEY] : getAuthorizationToken()},
      body: JSON.stringify({disposition}),
      types: [
        'SUBMIT_DISPOSITION_REQUEST',
        'SUBMIT_DISPOSITION_SUCCESS',
        'SUBMIT_DISPOSITION_FAILURE'
      ]
    }
  };
};

export const resetSubmittedDisposition = () => {
  return {
    type: "RESET_SUBMITTED_DISPOSITION"
  }
}

export const resetEventRequest = () => {
  return {
    type: "RESET_EVENT_REQUEST"
  }
}

export const resetError = () => {
  return {
    type: "RESET_SUBMIT_DISPOSITION_ERROR"
  }
}