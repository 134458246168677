import React from 'react';
import DismissableModal from '../DismissableModal';

import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import { compareDates } from '../../services/MomentUtils';
import { formatDateForDisplay } from '../../services/MomentUtils'

const styles = {
  listGroupStyle: {
    overflowY: "auto",
    maxHeight: '600px'
  },
  listItem: {
    width: 'inherit',
    marginLeft: 10,
    paddingLeft: 5,
    marginRight: 15,
    fontSize: "0.8em",
    bottomBottomColor: 'grey',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    marginBottom: 0
  }
}

const prepareData = (history) => {
  const groupedHistory = {};
  let historyList=history;
  // group by claimDrugName
  if (historyList) {
    historyList.forEach((h) => {
      const newH = {...h}; 
      newH.claimFillDate = formatDateForDisplay(h.claimFillDate)
      if (!newH.claimDrugName)
      newH.claimDrugName = "DRUG NAME N/A";

      if (groupedHistory[newH.claimDrugName] === undefined)
        groupedHistory[newH.claimDrugName] = [];
      groupedHistory[newH.claimDrugName].push(newH);

    });
  }
  const data = [];

  Object.keys(groupedHistory).forEach(key => {
    // sort in desc order of claimFillDate for each group
    groupedHistory[key].sort((i1, i2) => -1 * compareDates(i1.claimFillDate, i2.claimFillDate))
    // prepare aggregated pivotable data for ReactTable
    data.push({ ...groupedHistory[key][0], children: groupedHistory[key] });
  })

  return data;
}

class MedicineListItem extends React.Component {
  render() {
    const reference = "mli" + this.props.uniqueKey;
    const ModalOpener = () => <ListGroup.Item action onClick={() => this.refs[reference].handleShow()}
      style={styles.listItem}>{this.props.label}</ListGroup.Item>;
    return (
      <DismissableModal openModalButton={<ModalOpener key={this.props.uniqueKey + "lgi"} />} ref={reference} modalClassName="super-wide-modal" title={this.props.label}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Filled on</th>
              <th>Rx Normalized Name</th>
              <th>Strength</th>
              <th>Supply (days)</th>
              <th>NDC #</th>
              <th>Controlled Substance</th>
            </tr>
          </thead>
          <tbody>
            {this.props.medicines
              ? this.props.medicines.map((history, idx) => {
                return (
                  <tr key={idx}>
                    <td>{history.claimFillDate}</td>
                    <td>{history.sourceRxnormName}</td>
                    <td>{history.drugStrength}</td>
                    <td>{history.sourceDaysSupply}</td>
                    <td>{history.sourceNdcNumber}</td>
                    <td>{history.targetControlledSubstance}</td>
                  </tr>
                );
              })
              : null
            }
          </tbody>
        </Table>
      </DismissableModal>
    );
  }
}

const PatientMedicineList = (props) => {
  const groupedData = prepareData(props.history);
  const listGroupStyle = props.listGroupStyle ? props.listGroupStyle : {}
  groupedData.sort((m1, m2) => m1.claimDrugName.localeCompare(m2.claimDrugName));
  return (
    <ListGroup variant="flush" style={{ ...styles.listGroupStyle, ...listGroupStyle }}>
      {
        groupedData.map((m, i) => (
          <MedicineListItem key={i} uniqueKey={i} label={m.claimDrugName} medicines={m.children} />
        ))
      }
    </ListGroup>
  );
}

export default PatientMedicineList;