import React, {Component} from 'react';
import { getErrorMessage } from 'services/UtilityService';

import { sendAuthenticatedAsyncRequestWithBlobReturn } from '../services/AsyncRequestService';
class SecurePdfViewer extends Component {

  state = {
    isLoading: true,
    url: this.props.url,
    errorMsg: null,
    pdfHeight: this.props.pdfHeight ? this.props.pdfHeight : "480",
    displayToolBar: this.props.displayToolBar,
    pdfTobeDisplayed: null
  }

  componentDidMount() {
    this.fetchSecurePdf(this.state.url);
  } 

  static getDerivedStateFromProps(props, state) {
    const { url, displayToolBar, pdfHeight } = props;
    const newState = {
      displayToolBar,
      pdfHeight: pdfHeight || "480"
    };

    if (url !== state.url) {
      newState.url = url;
    }

    return newState;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.url !== this.state.url) {
      this.fetchSecurePdf(url);
    }
  }

  async blobToJson(blob) {
    return await new Response(blob).json();
  }

  onFetchSuccess(response) {
    let blob = new Blob([response.data], { type: response.headers['content-type'] } );
    // let blob = response.blob();
    let pdfTobeDisplayed = window.URL.createObjectURL(blob);
    this.setState({isLoading: false, pdfTobeDisplayed});
  }

  onFetchFailure(response) {
    // converting blob to JSON, because response type was 
    // set to blob but in case of error a JSON object would be returned
    this.blobToJson(response.data)
      .then(res => this.setState({ isLoading: false, errorMsg: res ? getErrorMessage(res) : getErrorMessage('ERROR_FETCHING_PDF') }) )
      .catch(res => this.setState({ isLoading: false, errorMsg: getErrorMessage('ERROR_FETCHING_PDF') }) );
  }

  fetchSecurePdf(url) {
    this.setState({ 
      pdfTobeDisplayed: null,
      isLoading: true, 
      errorMsg: null
    });
    sendAuthenticatedAsyncRequestWithBlobReturn(
      url,
      "GET", 
      null,
      (r) => this.onFetchSuccess(r),
      (r) => this.onFetchFailure(r)
  );
}
renderpdf = (pdfTobeDisplayed, pdfHeight)=>{
  return (
    <object data={pdfTobeDisplayed} width='100%' height={pdfHeight} type="application/pdf">
       <embed src={pdfTobeDisplayed} type="application/pdf" width='100%' height={pdfHeight} />
     </object>
  );
}

displayError = (errorMsg) => {
  return(
    <div>
      <strong>Error!</strong> {errorMsg}
    </div>
  );
}

 render () {
    const {isLoading, errorMsg, pdfHeight, displayToolBar, pdfTobeDisplayed} = this.state;
    const pdf = displayToolBar ? pdfTobeDisplayed : pdfTobeDisplayed+"#toolbar=0"; 
    return (
      <div>
        {  
          !isLoading && pdfTobeDisplayed
          ? this.renderpdf(pdf, pdfHeight)
          : !isLoading && errorMsg 
            ? this.displayError(errorMsg)
            : <div >
                Loading...
              </div>
        }
      </div>
    );
  }
}
export default SecurePdfViewer;