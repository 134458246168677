import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getDrugs,
  updateIndexOnList,
  resetError,
} from "./MedispanDrugs.action";
import { withRouter } from "react-router-dom";
import ResetListState from "components/ResetListState";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CAT_CONSTANTS from "lookup/CatConstants";
import MedispanDrugList from "./MedispanDrugList";

const Tip = (props) => (
  <div style={{ textAlign: "center", color: "#888", fontSize: "0.9em" }}>
    <em>{props.children}</em>
  </div>
);

const DEFAULT_PAGE_SIZE = 15;

class MedispanDrugs extends React.Component {
  state = {
    listState: this.props.listState,
  };

  // TODO: This should be somehow common in both medispanDrugList
  updateData = (state, preventUpdate) => {
    if (!preventUpdate) {
      if (state.sorted !== CAT_CONSTANTS.defaultMedDrugsSort)
        state.sorted.forEach((element, i) => {
          element.isDefault = false;
        });

      let indexOfBgDefaultFilter = state.filtered.findIndex(
        (x) => x.id == "brandGenType" && x.value == "All"
      );
      if (indexOfBgDefaultFilter > -1) {
        state.filtered.splice(indexOfBgDefaultFilter, 1);
      }
      this.props.getDrugs(
        state.pageSize,
        state.page,
        state.sorted,
        state.filtered,
        state.resized,
        state.expanded
      );
      this.setState({ listState: state });
    } else {
      // should have a separate action
      this.setState({ listState: state });
    }
  };

  render() {
    const { totalElements, listState } = this.props;

    return (
      <Container className="list-container" fluid>
        <Row>
          <Col md={12}>
            <div className="codex-version-display-bar">
              <span style={{ float: "left" }}>
                {" "}
                Total Drugs: <strong>{totalElements}</strong>
              </span>
              <strong style={{ float: "center" }}>Drug List</strong>
              <ResetListState
                onReset={() =>
                  this.updateData({
                    ...listState,
                    filtered: [],
                    sorted: CAT_CONSTANTS.defaultMedDrugsSort,
                  })
                }
                resetterDisabled={
                  listState.filtered.length === 0 &&
                  listState.sorted === CAT_CONSTANTS.defaultMedDrugsSort
                }
              />
            </div>
          </Col>
        </Row>
        <MedispanDrugList />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.allDrugList?.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: CAT_CONSTANTS.defaultMedDrugsSort,
      filtered: [],
      resized: [],
      expanded: {},
    };
  }
  return {
    listState: listState,
    totalElements:
      state.allDrugList.totalElements !== undefined
        ? state.allDrugList.totalElements
        : "Loading ...",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDrugs: bindActionCreators(getDrugs, dispatch),
    updateIndexOnList: bindActionCreators(updateIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MedispanDrugs));
