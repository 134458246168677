import React from 'react';
import Table from 'react-bootstrap/Table';

const KeyValueTable = (props) => (
  <span>
  {
    props.title 
    ? <div className="medicine-name text-center">{props.title}</div>
    : null 
  }
  <Table hover className="rm-key-value-table" >
    <tbody>
      {
        props.rows.map((r, i) =>
          <tr key={i}>
            <th>{r.label}</th>
            <td>{r.value}</td>
          </tr>
        )
      }
    </tbody>
  </Table>
  </span>
);

export default KeyValueTable;