
export const GlobalConstants = {
  filters: {
    defaultAllFilter: {
      label: 'All Filters',
      value: ''
    }
  }
}
export const ALL_FILTER = { id: 'state', value: 'ALL_FILTERS' };
export const ALL_FILTER_Status = { id: 'status', value: 'All_Filter' };
export const SWITCH_NUMBER_STRING = 'switchNumberAsString';
export const SWITCH_NUMBER_OP = 'STARTS_WITH';
export const SWITCH_STATE_OP = 'EQ';
export const BOOLEAN_TRUE_FILTER = { label: 'Associated', value: true };
export const BOOLEAN_FALSE_FILTER = { label: 'Non-Associated', value: false };