import moment from 'moment';
import { oneDecimalPlaceRounder } from './UtilityService';

export const formatDate = (date) => {
  return moment(date).calendar();
}

export const formatDateInEnglish = (date) => {
  return moment(date).format('ll');
}

export const formatDateForDisplay = (date) => {
  return moment(date).format('L');
}

export const formatDateMMDDYY = (date) => {
  return moment(date).format('MM/DD/YY');
}

export const formatDateWithTime = (date) => {
  return moment(date).format('MM/DD/YY, hh:mm A');
}

export const formatDateWithName = (date, name) => {
  return `${moment(date).format('MM/DD/YY, hh:mm A')} by ${name}`
};

export const formatDateWithTimeforAPICall = (dateTime) => {
  //convert Date in formate MM-DD-YYYY HH:MM:SS
  return ("0" + (dateTime.getMonth() + 1)).slice(-2) + "-" + ("0" + dateTime.getDate()).slice(-2) + "-" + dateTime.getFullYear() + " " +
    ("0" + dateTime.getHours()).slice(-2) + ":" + ("0" + dateTime.getMinutes()).slice(-2) + ":" + ("0" + dateTime.getSeconds()).slice(-2);
}

export const calculateAge = (date) => {
  return oneDecimalPlaceRounder(moment().diff(date, 'years', true));
}

export const compareDates = (d1, d2) => {
  if (moment(d1).isBefore(moment(d2))) return -1;
  if (moment(d1).isSame(moment(d2))) return 0;
  if (moment(d1).isAfter(moment(d2))) return 1;
}

export const getCurrentDatePlusDays = (days) => {
  var result = new Date();
  result.setDate(result.getDate() + days);
  return result;
}