import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getDrugs,
  resetError,
  resetMedispanDrugList,
  updateIndexOnList,
} from "./MedispanDrugs.action";

import { ColumnTypes, FilterTypes } from "commons/table/Constants";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import ResetListState from "components/ResetListState";
import Tip from "components/Tip";
import CAT_CONSTANTS from "lookup/CatConstants";
import { showNotification } from "services/NotificationService";

const DEFAULT_PAGE_SIZE = 15;
class MedispanDrugList extends React.Component {
  state = {
    loading: true,
    data: this.props.data,
    isError: false,
    pages: this.props.pages,
    listState: this.props.listState,
    totalElements: this.props.totalElements,
  };

  updateData = (state, preventUpdate) => {
    if (!preventUpdate) {
      if (state.sorted !== CAT_CONSTANTS.defaultMedDrugsSort)
        state.sorted.forEach((element, i) => {
          element.isDefault = false;
        });

      let indexOfBgDefaultFilter =
        state.filtered && state.filtered.length > 0
          ? state.filtered.findIndex(
              (x) => x.id == "brandGenType" && x.value == "All"
            )
          : -1;
      if (indexOfBgDefaultFilter > -1) {
        state.filtered.splice(indexOfBgDefaultFilter, 1);
      }
      this.props.getDrugs(
        state.pageSize,
        state.page,
        state.sorted,
        state.filtered,
        state.resized,
        state.expanded
      );
    } else {
      // should have a separate action
      this.setState({ listState: state });
    }
  };

  componentWillUnmount() {
    this.props.resetMedispanDrugList();
  }

  componentDidMount() {
    this.updateData(this.state.listState);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.pages !== this.props.pages ||
      prevProps.listState !== this.props.listState ||
      prevProps.loading !== this.props.loading
    ) {
      const { data, pages, listState, loading, totalElements } = this.props;
      this.setState({ data, pages, loading, listState, totalElements });
    }

    if (
      this.props.isError === true &&
      prevProps.isError !== this.props.isError
    ) {
      showNotification({
        title: "Error Loading Data!",
        message: "You have entered invalid information.",
        position: "bl",
        type: "error",
      });
      this.props.resetError();
    }
  }

  renderStatusCell = (status) => {
    return (
      <span
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        {status}
      </span>
    );
  };

  isSelected = (selectProps) => {
    let medispanDrugId = ("" + selectProps).replace("select-", "");
    let { selected } = this.props;
    if (selected?.medispanDrugId && selected.medispanDrugId == medispanDrugId)
      return true;
    return false;
  };

  toggleSelection = (selectProps) => {
    let medispanDrugId = selectProps.replace("select-", "");
    let selected = {};
    let selectedMed = this.props.selected?.medispanDrugId
      ? this.props.selected.medispanDrugId
      : -1;
    if (selectedMed != medispanDrugId) {
      // add to the object
      selected = this.state.data.find(
        (x) => x.medispanDrugId == medispanDrugId
      );
    }
    this.props.onSelect(selected);
  };

  columns = [
    {
      Header: "Drug Id",
      accessor: "medispanDrugId",
      accessorKey: "medispanDrugId",
      width: 60,
      show: false,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Drug Name",
      accessorKey: "medName",
      accessor: "medName",
      width: 250,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "B/G Code",
      accessor: "brandGenType",
      accessorKey: "brandGenType",
      width: 80,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SingleSelect,
      filterOptions: CAT_CONSTANTS.bgCodeFilter,
    },
    {
      header: "Ingredient",
      accessor: "ingredient",
      accessorKey: "ingredient",
      width: 300,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "GPI Code",
      accessor: "gpiCode",
      accessorKey: "gpiCode",
      width: 300,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Form",
      accessor: "medForm",
      accessorKey: "medForm",
      width: 200,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Route of Administration",
      accessor: "routeOfAdministration",
      accessorKey: "routeOfAdministration",
      width: 130,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Text,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Dynamic Switch Group",
      accessor: "dynamicSwitchGroupNames",
      accessorKey: "dynamicSwitchGroupNames",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        return this.renderAllDsgs(row.original.dynamicSwitchGroupNames);
      },
    },
    {
      header: "On-market Strengths",
      accessor: "onMarketStrengths",
      accessorKey: "onMarketStrengths",
      width: 300,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Off-market Strengths",
      accessor: "offMarketStrengths",
      accessorKey: "offMarketStrengths",
      width: 300,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
  ];
  onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    const listState = {
      page: pageNo,
      pageSize: pageSize,
      sorted: sortBy ? sortBy : [],
      filtered: filterValues,
      resized: [],
      expanded: {},
    };
    this.updateData(listState);
  };
  renderDsgCell = (id, dsgName, shouldAddCommas) => {
    return (
      <Link
        to={`/cat/dynamic-switch-groups/${id}?showDisplayCodexBar=false`}
        className={"space-pre-wrap"}
      >
        {dsgName + (shouldAddCommas ? " , " : "")}
      </Link>
    );
  };

  renderAllDsgs = (dynamicSwitchGroupNames) => {
    let dsgs = dynamicSwitchGroupNames
      ? Object.keys(dynamicSwitchGroupNames)
      : [];
    if (dsgs.length > 0) {
      return (
        <span
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {dsgs.map((key, index) => {
            return this.renderDsgCell(
              key,
              dynamicSwitchGroupNames[key],
              index + 1 != dsgs.length
            );
          })}
        </span>
      );
    }
    return (
      <span
        style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      >
        {" "}
        {"--"}{" "}
      </span>
    );
  };

  onRowSelect = (selectedRows) => {
    if (Object.keys(selectedRows).length > 0) {
      const rowId = Object.keys(selectedRows)[0];
      let selected = this.state.data.find((x) => x.medispanDrugId == rowId);
      if (selected) {
        this.props.onSelect(selected);
        return;
      }
    }
    this.props.onSelect();
  };

  render() {
    const { data, pages, loading, listState, totalElements } = this.state;
    const { isSelectRow, showResetListButton, placedOnLightBg } = this.props;

    return (
      <>
        {showResetListButton && (
          <div className="float-right tiny-right-spacer">
            <ResetListState
              placedOnLightBg={placedOnLightBg}
              onReset={() =>
                this.updateData({
                  ...listState,
                  filtered: [],
                  sorted: CAT_CONSTANTS.defaultMedDrugsSort,
                })
              }
              resetterDisabled={
                listState.filtered.length === 0 &&
                listState.sorted === CAT_CONSTANTS.defaultMedDrugsSort
              }
            />
          </div>
        )}
        <ServerSideReactTable
          columnDefination={this.columns}
          data={data}
          currentPage={listState.page}
          sorted={listState.sorted}
          filters={listState.filtered}
          defaultPageSize={listState.pageSize}
          totalPages={pages}
          totalRecords={totalElements == "loading" ? 0 : totalElements}
          onUpdateTableData={this.onUpdateTable}
          onRowSelect={this.onRowSelect}
          loading={loading}
          overrideRowId={"medispanDrugId"}
          addRowSelection={isSelectRow}
          enableMultiRowSelection={false}
        />
        <Tip>Tip: Hold shift when sorting to multi-sort and disable sort!</Tip>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.allDrugList?.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: CAT_CONSTANTS.defaultMedDrugsSort,
      filtered: [{ id: "brandGenType", value: "All" }],
      resized: [],
      expanded: {},
    };
  }
  return {
    data: state.allDrugList.data !== undefined ? state.allDrugList.data : [],
    pages: state.allDrugList.pages ? state.allDrugList.pages : 0,
    loading: state.allDrugList.loading,
    listState: listState,
    isError: state.allDrugList.isError,
    totalElements: state.allDrugList.totalElements,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDrugs: bindActionCreators(getDrugs, dispatch),
    updateIndexOnList: bindActionCreators(updateIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
    resetMedispanDrugList: bindActionCreators(resetMedispanDrugList, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MedispanDrugList));
