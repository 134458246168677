import { FaBullseye } from "react-icons/fa";

export function Reducer (previousState, action) {
  let newState = Object.assign({}, previousState);
  switch (action.type) {
    case 'SUBMIT_DISPOSITION_REQUEST':
      newState = Object.assign({}, previousState, {submittingDisposition: true, submittedDisposition: false, submitDispositionFailed: false});
      break;
    case 'SUBMIT_DISPOSITION_SUCCESS':
      newState = Object.assign({}, previousState, {submittingDisposition: false, submittedDisposition: true, submitDispositionFailed: false});
      break;
    case 'SUBMIT_DISPOSITION_FAILURE':
      newState = Object.assign({}, previousState, {submittingDisposition: false, submittedDisposition: false, submitDispositionFailed: true});
      break;
    case 'RESET_SUBMIT_DISPOSITION_ERROR':
      newState = Object.assign({}, previousState, {submitDispositionFailed: false});
      break;
    case 'FETCH_RAW_REQUEST':
      newState = Object.assign({}, previousState, {rawDetailsStatus: 'requested', rawDetails: null});
      break;
    case 'FETCH_RAW_SUCCESS':
      newState = Object.assign({}, previousState, {rawDetailsStatus: 'success', rawDetails: action.payload});
      break;
    case 'FETCH_RAW_FAILURE':
      newState = Object.assign({}, previousState, {rawDetailsStatus: 'failure', rawDetails: null});
      break;
    case 'TRIGGER_EVENT_SUCCESS':
      newState = Object.assign({}, previousState, {eventRequestCompleted: true});
      break;
    case 'RESET_SUBMITTED_DISPOSITION':
      newState = Object.assign({}, previousState, {submittedDisposition: false});
      break;
    case 'RESET_EVENT_REQUEST':
      newState = Object.assign({}, previousState, {eventRequestCompleted: false});
      break;
    default:
      break;
  }

  return newState;
}