import Colors from './Colors';

const   LAST_MODIFIED_DATE= {id: "lastModifiedDate", desc: false, isDefault: true}
export const RawConstants = {
  status: {
    CLAIMED : "CLAIMED",
    UNCLAIMED : "UNCLAIMED",
    FORWARDED : "FORWARDED",
    CONFIRMED : "CONFIRMED"
  },
  statuses: [
    { key: 'Default Filters', value: 'Default Filters'},
    {key: "CLAIMED", value: "Claimed"},
    {key: "UNCLAIMED", value: "Unclaimed"},
    {key: "FORWARDED", value: "Forwarded"},
    {key: "CONFIRMED", value: "Confirmed"}
  ],
  statusesMultiSelect: [
    {id: 'Default Filters', name: 'Default Filters'},
    {id: "CLAIMED", name: "Claimed"},
    {id: "UNCLAIMED", name: "Unclaimed"},
    {id: "FORWARDED", name: "Forwarded"},
    {id: "CONFIRMED", name: "Confirmed"},
    {id: "All Filters", name: "All Filters"},
  ],
  statusLabels: {
    CLAIMED : "Claimed",
    UNCLAIMED : "Unclaimed",
    FORWARDED : "Forwarded",
    CONFIRMED : "Confirmed"
  },
  statusColors : {
    CLAIMED : Colors['rm-blue'],
    UNCLAIMED : Colors['rm-danger'],
    FORWARDED : Colors['rm-warning'],
    CONFIRMED: Colors['rm-success']
  },

  DEFAULT_RAW_LIST_SORTING: {
    lastModifiedDate:  LAST_MODIFIED_DATE
  },
  DEFAULT_SORT:[ LAST_MODIFIED_DATE],
  DEFAULT_FILTER_CONST: 'Default Filters',
  REFFRER_SORT_DESC :{id: "referrerId", desc: true, isDefault:false},
  REFFRER_SORT_ASC:{id: "referrerId", desc: false, isDefault:false}
  
  
}
const DEFAULT_FILTER_BACKEND= { id: 'status', value:[RawConstants.status.UNCLAIMED,
  RawConstants.status.CLAIMED,
  RawConstants.status.FORWARDED], isdefault: true };

const ALL_FILTER_BACKEND= { id: 'status', value:[RawConstants.status.UNCLAIMED,
  RawConstants.status.CLAIMED,
  RawConstants.status.FORWARDED, RawConstants.status.CONFIRMED], isdefault: true };

export const DEFAULT_FILTER= { id: 'status', value:[RawConstants.statusLabels.UNCLAIMED,
  RawConstants.statusLabels.CLAIMED,
  RawConstants.statusLabels.FORWARDED], isdefault: true };

export const FILTER={default:[DEFAULT_FILTER],
  defaultBackend: DEFAULT_FILTER_BACKEND,
  allBackend: ALL_FILTER_BACKEND,
};
