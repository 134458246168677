import CheckField from 'commons/fields/CheckField';
import DescriptionField from 'commons/fields/DescriptionField';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export const AgeFields = ({
  handleChange,
  values,
  drugValue,
  handleBlur,
  setAgeError,
  ageError,
  isEditMode = true,
  isNewDrugScreen = true,
  predecessorValue = undefined,
  showDelta = false,
  isDeltaUpdated = false
}) => {

  const handleMinAgeChange = (args) => {
    handleAgeChange(values.ageLmtReqd, args.target.value, values.maxAge);
    handleChange(args);
  }

  const handleMaxAgeChange = (args) => {
    handleAgeChange(values.ageLmtReqd, values.minAge, args.target.value);
    handleChange(args);
  }

  const handleAgeChange = (ageLmtReqd, minAge, maxAge) => {
    let error = null;
    if (minAge < 0 || maxAge < 0) {
      error = "The ages should not be negative";
    } else if (maxAge == "0") {
      error = "Max age should not be zero";
    } else if (minAge != null && maxAge != null && maxAge <= minAge) {
      error = "Max age should be greater than min age";
    } else if (ageLmtReqd && !minAge && !maxAge) {
      error = "The age fields should not be empty";
    }
    setAgeError(error);
  }

  const handleAgeCheckChange = (args) => {
    if (!args.target.checked) {
      setAgeError(null);
    } else {
      handleAgeChange(args.target.checked, values.minAge, values.maxAge);
    }
    handleChange(args);
  }

  return (
    <div className="mb-2 row">
      {isEditMode && isNewDrugScreen && <Col md={3}>
        <Form.Label className={"rm-small-top-label"}>
          {"Age Limits Required?"}
        </Form.Label>
      </Col>}
      <Col md={isEditMode ? 9 : 12}>
        <Row className={"days-supply"}>
          {(isEditMode || showDelta) && <Col md={isNewDrugScreen ? 2 : 12}>
            <CheckField
              isEditMode={isEditMode}
              key={`ageLmtReqd`}
              shouldDisplayTitle={false}
              compKey={"age-lmt-reqd"}
              id={"ageLmtReqd"}
              title={isNewDrugScreen ? "" : "Age Limit Required?"}
              label={""}
              handleChange={handleAgeCheckChange}
              handleBlur={handleBlur}
              value={values.ageLmtReqd}
              checkClassName={"pb-1"}
              showDelta={showDelta}
              isDeltaUpdated={isDeltaUpdated}
              deltaHeading={"Age Limit Required?"}
              oldValue={predecessorValue?.ageLmtReqd}
            />
          </Col>}
          <Col md={isEditMode ? 5 : 4}>
            <DescriptionField
              isEditMode={isEditMode}
              isDisabled={!values.ageLmtReqd}
              name={"Min Age"}
              id={"minAge"}
              savedValue={values.ageLmtReqd ? drugValue.minAge : null}
              updatedValue={values.ageLmtReqd ? values.minAge : null}
              handleChange={handleMinAgeChange}
              handleBlur={handleBlur}
              compKey={"minAge"}
              key={`minAge`}
              isHorizontalView={isNewDrugScreen}
              placeholder={"0"}
              inputType={"number"}
              additionalClassName={"age-field"}
              additionalTitleClassName={"age-field-title"}
              showDelta={showDelta}
              isUpdated={isDeltaUpdated}
              predecessorValue={predecessorValue?.minAge}
            />
          </Col>
          <Col md={isEditMode ? 5 : 4}>
            <DescriptionField
              isEditMode={isEditMode}
              isDisabled={!values.ageLmtReqd}
              name={"Max Age"}
              id={"maxAge"}
              savedValue={values.ageLmtReqd ? drugValue.maxAge : null}
              updatedValue={values.ageLmtReqd ? values.maxAge : null}
              handleChange={handleMaxAgeChange}
              handleBlur={handleBlur}
              compKey={"maxAge"}
              key={`maxAge`}
              isHorizontalView={isNewDrugScreen}
              placeholder={"100"}
              inputType={"number"}
              additionalClassName={"age-field"}
              additionalTitleClassName={"age-field-title"}
              showDelta={showDelta}
              isUpdated={isDeltaUpdated}
              predecessorValue={predecessorValue?.maxAge}
            />
          </Col>
        </Row>
        {isEditMode &&
          <Row>
            <Form.Text
              id={"age-error-msg"}
              className="text-small text-danger"
            >
              {ageError}
            </Form.Text>
          </Row>
        }
      </Col>
    </div>
  )
}