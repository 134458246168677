
import { createSlice } from '@reduxjs/toolkit';
import { PawConstants } from 'lookup/PawConstants';
import { getDrugGroupsRequestAction, getDrugGroupsSuccessAction, getDrugGroupsFailureAction } from './DrugGroup.actions';
import { DEFAULT_FILTER, DEFAULT_SORT } from './DrugGroup.constants';
export const INITAL_STATE = {
  data: [],
  totalPages: 0,
  pageSize: 20,
  page: 0,
  error: false,
  loading: false,
  totalElements: "Loading",
  sorted:DEFAULT_SORT,
  filtered:DEFAULT_FILTER,
  isDefaultFilter:true,
  resized: [],
  expanded: {},
}


export const drugGroupSlice = createSlice({
  name: "drugGroup",
  initialState: INITAL_STATE,
  reducers: {
    getDrugGroupListSuccess: (state, action) => {
      state.data = action.payload.records;
      state.totalPages = Math.ceil(action.payload.totalElements / action.payload.pageSize);
      state.pageSize = action.payload.pageSize
      state.page = action.payload.page;
      state.sorted = action.payload.sorted;
      state.filtered = action.payload.filtered;
      state.totalElements = action.payload.totalElements;
      state.error = false;
      state.loading = false;
    },
    getDrugGroupListFailure: (state, action) => {
      state.loading = false;
      state.error = false;
      state.totalElements = "Failed";
    },
    getDrugGroupListLoading: (state, action) => {
      state.loading = true;
      state.isDefaultFilter = false;
      state.totalElements = "Loading";
    },
    RESET_PAGE_TO_ZERO: (state, action) => {
      state.page = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "DRUG_GROUP" RSAA action.
      .addCase(getDrugGroupsRequestAction, (state, action) => {
        drugGroupSlice.caseReducers.getDrugGroupListLoading(state, action);
      })
      .addCase(getDrugGroupsSuccessAction, (state, action) => {
        drugGroupSlice.caseReducers.getDrugGroupListSuccess(state, action);
      })
      .addCase(getDrugGroupsFailureAction, (state, action) => {
        drugGroupSlice.caseReducers.getDrugGroupListFailure(state, action);
      })

  }
});


export const { getDrugGroupListSuccess, getDrugGroupListFailure, getDrugGroupListLoading, RESET_PAGE_TO_ZERO } = drugGroupSlice.actions;
export const drugGroupState = (state) => state.drugGroup;
export default drugGroupSlice.reducer;