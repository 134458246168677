import React from 'react';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DismissableModal from 'components/DismissableModal';
import Form from "react-bootstrap/Form";
import { Row, Col, Button } from "react-bootstrap";
import { unlinkDeclinedFaxAction, resetUnlinkDeclinedFax } from "./UnlinkDeclinedFax.actions";
import { showNotification } from 'services/NotificationService';

const UnlinkDeclinedFax = ({ wfItemId, globalSwitchId, history }) => {
    const [notes, setNotes] = useState("");
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const isSubmitting = useSelector(state => state.unlinkDeclinedFax.isSubmitting);
    const modalRef = useRef("UnlinkFaxModal");

    const unlinkFaxClick = () => {
        dispatch(unlinkDeclinedFaxAction(wfItemId, notes));
        modalRef.current.handleClose();
        history.goBack();
    };
    return (
        <DismissableModal
            title="Unlink Fax"
            ref={modalRef}
            initialStateOpen={showModal}
            openModalButtonText="Unlink Fax"
            openModalButtonBlock={false}
            openModalButtonStyle="danger"
            size="lg"
        >
            <Form.Group>
                <Form.Label>Notes are Required</Form.Label>
                <Form.Control
                    id="switchdisposition-form-notes"
                    as="textarea"
                    rows={3}
                    placeholder="Notes or comments about this switch"
                    onChange={(e) =>
                        setNotes(e.target.value)
                    }
                    value={notes}
                    name="notes"
                />
                <Form.Text className="text-muted">
                    {`You are about to unlink Confirmed disposition DECLINED for Switch # ${globalSwitchId}. `}
                    <br /><br />
                    {`This will return the switch to switch state: Awaiting Response.`}
                    <br /><br />
                    {`Are you sure you want to unlink the fax?`}
                </Form.Text>
                <Row className="top-spacer">
                    <Col md={4} className="bottom-spacer">
                        <Button
                            type="submit"
                            variant="success"
                            block
                            disabled={isSubmitting || notes == ""}
                            onClick={unlinkFaxClick}
                        >
                            {isSubmitting ? "Unlinking" : "Yes"}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>
        </DismissableModal>
    );



};
export default UnlinkDeclinedFax;