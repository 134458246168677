import React from "react";

const DrugDetails = (props) => {
  const { sourceDrug, targetDrugs, selectedTarget } = props;

  return (
    <>
      <div data-testid="text-source-drug">
        <strong>Source:</strong> {sourceDrug}
      </div>
      <div className="tiny-top-spacer" data-testid="text-target-drugs">
        <strong>Targets:</strong>
        <ul>
          {
            targetDrugs && targetDrugs.map((targetDrug, i) => (
              <li key={i}>
                {targetDrug.medName}
              </li>
            ))
          }
        </ul>
      </div>
      {selectedTarget?<div className="tiny-top-spacer" data-testid="text-target-drugs">
        <strong>Target Accepted: </strong>{selectedTarget.displayName}<br />
        <><strong>Strengths: </strong>{selectedTarget.strength}</><br />
        <><strong>Refills: </strong>{selectedTarget.targetRefill}</><br />
        <><strong>Instructions: </strong>{selectedTarget.instructions}</><br />
      </div>: <><strong>Target Accepted: </strong>{"None"}<br /></>}
    </>
  );
}
export default DrugDetails;