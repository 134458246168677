import { RSAA } from "redux-api-middleware";
import config from "config";
import {
  AUTH_TOKEN_HEADER_KEY,
  getAuthorizationToken,
} from "services/AuthService";

export const updateInterventionList = (
  pageSize,
  page,
  sorted,
  filtered,
  resized,
  expanded,
  uuid
) => {
  return {
    [RSAA]: {
      endpoint:
        uuid !== undefined
          ? `${config.apiRoot}/bulk-action/${uuid}/items`
          : `${config.apiRoot}/swim/cycle/list`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      body: JSON.stringify({ pageSize, page, sorted, filtered }),
      types: [
        {
          type: "UPDATE_BULK_ACTION_SWITCH_LIST_REQUEST",
          payload: {
            listState: { pageSize, page, sorted, filtered, resized, expanded },
          },
        },
        "UPDATE_BULK_ACTION_SWITCH_LIST_SUCCESS",
        "UPDATE_BULK_ACTION_SWITCH_LIST_FAILURE",
      ],
    },
  };
};
export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_BULK_ACTION_SWITCH_LIST",
  };
};
export const updateBulkActionListState = (state) => {
  return {
    type: "UPDATE_BULK_ACTION_SWITCH_LIST_STATE",
    payload: { listState: state },
  };
};
export const updateIndexOnList = (index) => {
  return {
    type: "UPDATE_BULK_ACTION_SWITCH_INDEX_ON_LIST",
    indexOnList: index,
  };
};
