import React from "react";
import "plugins/Lexical/styles.css";
import { MdNoteAdd } from "react-icons/md";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import DOMPurify from "dompurify";
import DismissableModal from "components/DismissableModal";
import { compareDates, formatDateWithTime } from "services/MomentUtils";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { useState } from "react";
import { isNull } from "lodash";
import { FormSelect } from "react-bootstrap";
import { NotesFilterConstants } from "lookup/NotesFilterConstants";

const NoteItem = (props) => {
  const { note, clipped } = props;
  const sanitizer = DOMPurify.sanitize;
  return (
    <div className="rm-switch-note-container">
      <span className="rm-switch-note-author">{note.type == NotesFilterConstants.SYSTEM ? NotesFilterConstants.SYSTEM : note.creator}</span>&nbsp;
      <Badge bg="info">{note.type}</Badge>&nbsp;
      <Badge bg="info">{formatDateWithTime(note.creationDate)}</Badge>&nbsp;
      {note.new ? <Badge bg="success">New</Badge> : null}
      <div className={`rm-switch-note ${clipped ? "clipped" : ""}`}>
        {<div dangerouslySetInnerHTML={{ __html: sanitizer(note.note) }} />}{" "}
        {note.activityRescheduleDate
          ? formatDateWithTime(note.activityRescheduleDate)
          : ""}
      </div>
    </div>
  );
};

const NotesModal = (props) => {
  const [notes, setNotes] = useState(props.notes);
  const [type, setType] = useState("All");

  const typeFilter = (event) => {
    const selectedType = event.target.value;
    setType(selectedType);
    setNotes(
      selectedType !== "All"
        ? props.notes.filter((note) => note.type === selectedType)
        : props.notes
    );
  };

  return (
    <DismissableModal
      openModalButtonText="Show All"
      openModalButtonSize="sm"
      openModalButtonStyle="light"
      title="Notes and Comments"
      centered
    >
      {props.filtersList ? (
        <div className="pull-right rm-notes-filter-container">
          <Form.Label className="rm-notes-filter-label">
            Select Note Type:{" "}
          </Form.Label>
          <FormSelect
            className="pull-right rm-notes-filter-select"
            onChange={typeFilter}
            value={type}
          >
            <option value="All" key="all-filter">
              All
            </option>
            {props.filtersList.map((type, i) => (
              <option value={type} key={i}>
                {type}
              </option>
            ))}
          </FormSelect>
          <hr />
        </div>
      ) : null}
      <div style={{ padding: "0 15px" }}>
        {notes.map((note, idx) => {
          return (
            <span key={idx}>
              <NoteItem note={note} clipped={false} />
              {idx !== notes.length - 1 ? <hr /> : null}
            </span>
          );
        })}
      </div>
    </DismissableModal>
  );
};

class Noter extends React.Component {
  state = {
    enabled: false,
    note: "",
    saving: false,
    entityId: this.props.entityId,
    entitySaveUrl: this.props.entitySaveUrl,
    errorNotification: this.props.errorNotification || null,
  };

  save() {
    const { note, entityId, entitySaveUrl, errorNotification } = this.state;
    this.setState({ saving: true });
    this.setState({ redactedFax: undefined });
    sendAuthenticatedAsyncRequest(
      `${entitySaveUrl}/${entityId}`,
      "POST",
      {
        note: note,
      },
      (r) => {
        this.props.newNote(r.data);
        this.setState({ note: "", enabled: false, saving: false });
      },
      (r) => {
        this.setState({ saving: false });
        if (!isNull(errorNotification))
          errorNotification("Unable To save Notes", r.data);
      }
    );
  }

  render() {
    const { enabled, note, saving } = this.state;
    if (!enabled)
      return (
        <OverlayTrigger
          overlay={<Tooltip>Add New Note</Tooltip>}
          placement="top"
        >
          {({ ref, ...triggerHandler }) => (
            <Button
              size="sm"
              ref={ref}
              variant="primary"
              className="pull-right"
              onClick={() => this.setState({ enabled: true })}
            >
              <MdNoteAdd />
            </Button>
          )}
        </OverlayTrigger>
      );
    else
      return (
        <div>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Notes or comments about this switch"
              onChange={(e) => this.setState({ note: e.target.value })}
              value={note}
              disabled={!enabled}
              className="rm-switch-note"
            />
            <div style={{ paddingTop: 5 }}>
              <Button
                size="sm"
                variant="warning"
                onClick={() => this.setState({ enabled: false })}
                disabled={saving}
              >
                Cancel
              </Button>{" "}
              &nbsp;
              <Button
                size="sm"
                variant="success"
                onClick={this.save.bind(this)}
                disabled={saving}
              >
                {saving ? "Saving ..." : "Save"}
              </Button>
            </div>
          </Form.Group>
        </div>
      );
  }
}

// set a max height
// if one note, display complete note
// if two notes, display two notes' first line
// if more than two, display latest two and a show all button which opens a popup

class Notes extends React.Component {
  state = {
    notes: this.props.notes,
    entityId: this.props.entityId,
    entitySaveUrl: this.props.entitySaveUrl,
    filtersList: this.props.filtersList,
    noOfNotesToShow: this.props.noOfNotesToShow || 2,
  };
  static getDerivedStateFromProps(props, state) {
    if (props.entityId !== state.entityId) {
      return {
        notes: props.notes,
        entityId: props.entityId,
        entitySaveUrl: props.entitySaveUrl,
      };
    }
    return null;
  }


  addNote = (note) => {
    const { notes } = this.state;
    notes.forEach((n) => (n.new = false));
    note.new = true;
    notes.push(note);
    this.setState({ notes });
  };

  render() {
    const { notes, entityId, entitySaveUrl, noOfNotesToShow, filtersList } =
      this.state;
    notes.sort((n1, n2) => compareDates(n2.creationDate, n1.creationDate));
    return (
      <div>
        <div className="small-label">notes and comments ({notes.length})</div>
        <div className="rm-notes">
          {notes.length > 0 ? (
            <span>
              {notes.map((note, idx) => {
                if (idx < noOfNotesToShow) {
                  return <NoteItem note={note} key={idx} clipped={true} />;
                } else return null;
              })}
              <NotesModal notes={notes} filtersList={filtersList} />
            </span>
          ) : (
            <div className="text-muted tiny-top-spacer">
              No notes have been added
            </div>
          )}
          <Noter
            entityId={entityId}
            newNote={this.addNote}
            entitySaveUrl={entitySaveUrl}
          />
        </div>
      </div>
    );
  }
}

export default Notes;
