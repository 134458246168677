import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';

export const getTableOfContentsRequestAction = 'GET_TABLE_OF_CONTENTS_REQUEST';
export const getTableOfContentsSuccessAction = 'GET_TABLE_OF_CONTENTS_SUCCESS';
export const getTableOfContentsFailureAction = 'GET_TABLE_OF_CONTENTS_FAILURE';


export const getTableOfContents = (pageSize, page, sorted, filteredData) => (dispatch, getState) => {
  let filtered = JSON.parse(JSON.stringify(filteredData));
  const rsaaObject = {
      [RSAA]: {
          endpoint: `${config.apiRoot}/analytics/table-of-contents`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
          body: JSON.stringify({ pageSize, page, filtered, sorted }),
          types: [
            getTableOfContentsRequestAction,
              {
                  type: getTableOfContentsSuccessAction,
                  payload: async (action, state, res) => {
                      const data =await res.json();
                      return { pageSize: pageSize, filtered: filteredData, ...data };
                  }
              },
              getTableOfContentsFailureAction
          ],
      }
  }

  return dispatch(rsaaObject);
}