import { useState } from "react";
import { MdAdd } from "react-icons/md";

import { Button } from "react-bootstrap";

const FloatingButton = ({ name, onClick }) => {

  return (
    <Button
      className="fab-container"
      onClick={onClick}
    >
      <MdAdd/> 
      {"   " + name}
    </Button>
  );
};

export default FloatingButton;