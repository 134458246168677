import React from 'react';
import Alert from 'react-bootstrap/Alert';

export const VersionBadge = (props) => {

    return (
        <Alert variant={'secondary'} className='tiny-top-spacer rm-gray-light'>
            <span className='text-muted' style={{ fontSize: '0.8em' }}>
                Frontend-ver: <strong>
                    {props.frontendCommitVersionId ? props.frontendCommitVersionId.substring(0, 8) : 'local-dev'}
                </strong>
            </span> <br />
            {props.backendCommitVersionId ?
                <>
                    <span className='text-muted' style={{ fontSize: '0.8em' }}>
                        Backend-ver: <strong>
                            {props.backendCommitVersionId ? props.backendCommitVersionId.substring(0, 8) : 'local'}
                        </strong>
                    </span><br />
                </> : ''
            }
            <span className='text-muted' style={{ fontSize: '0.8em' }}>
                Frontend-release: <strong>
                    {props.frontendReleaseNumber ? props.frontendReleaseNumber : 'local'}
                </strong>
            </span><br />
            {props.backendReleaseNumber ?
                <span className='text-muted' style={{ fontSize: '0.8em' }}>
                    Backend-release: <strong>
                        {props.backendReleaseNumber ? props.backendReleaseNumber : 'local'}
                    </strong>
                </span> : ""
            }
        </Alert>

    );
}

