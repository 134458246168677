import React from 'react';
import Form from 'react-bootstrap/Form';

const DateFilter = ({ column, handleFilterChange, filterValue }) => {
    const { id } = column;
    const value = filterValue || '';
    return (
        <Form.Control
            id={`datefilter${id}`}
            type="date"
            value={value}
            onChange={e => handleFilterChange(id, e.target.value,column)}
        />
    );

}

export default DateFilter;