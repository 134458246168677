export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case "SCHEDULE_BULK_ACTION_REQUEST":
      newState = Object.assign({}, previousState, {
        error: "",
        submittingRequest: "Submiting Scheduled Bulk Action",
      });
      break;
    case "SCHEDULE_BULK_ACTION_SUCCESS":
      newState = Object.assign({}, previousState, {
        error: "",
        submittingRequest: "",
        isSuccess: true,
        description: "",
        switchList: [],
        eventRequestCompleted: true,
      });
      break;
    case "SCHEDULE_BULK_ACTION_FAILURE":
      newState = Object.assign({}, previousState, {
        error: "Unable to create bulk action",
        submittingRequest: "",
        description: "",
        eventRequestCompleted: false,
      });
      break;
    case "RESET_SUCCESS_MESSAGE_BULK_ACTION_SCHEDULER":
      newState = Object.assign({}, previousState, {
        error: "",
        isSuccess: false,
        eventRequestCompleted: false,
      });
      break;
    case "CANCEL_BULK_ACTION_REQUEST":
      newState = Object.assign({}, previousState, {
        error: "",
        submittingRequest: "Cancelling Bulk Action",
      });
      break;
    case "CANCEL_BULK_ACTION_SUCCESS":
      newState = Object.assign({}, previousState, {
        error: "",
        submittingRequest: "",
        cancelEventRequestCompleted: true,
        isSuccessfullCancel: true,
      });
      break;
    case "CANCEL_BULK_ACTION_FAILURE":
      newState = Object.assign({}, previousState, {
        error: "Unable to cancel bulk action",
        submittingRequest: "",
        cancelEventRequestCompleted: false,
      });
      break;
    case "RESET_SUCCESS_MESSAGE_BULK_ACTION_CANCEL":
      newState = Object.assign({}, previousState, {
        error: "",
        isSuccessfullCancel: false,
        cancelEventRequestCompleted: false,
      });
      break;
    case "UPDATE_BULK_ACTION_REQUEST":
      newState = Object.assign({}, previousState, {
        error: "",
        submittingRequest: "Updating Bulk Action",
      });
      break;
    case "UPDATE_BULK_ACTION_SUCCESS":
      newState = Object.assign({}, previousState, {
        error: "",
        submittingRequest: "",
        updateEventRequestCompleted: true,
        isSuccessfulUpdation: true,
      });
      break;
    case "UPDATE_BULK_ACTION_FAILURE":
      newState = Object.assign({}, previousState, {
        error: "Unable to Update bulk action due to time limit",
        submittingRequest: "",
        updateEventRequestCompleted: false,
      });
      break;
    case "RESET_SUCCESS_MESSAGE_BULK_ACTION_UPDATION":
      newState = Object.assign({}, previousState, {
        error: "",
        isSuccessfulUpdation: false,
        updateEventRequestCompleted: false,
      });
      break;
    case "CREATE_NEW_CHILD_BULK_ACTION_REQUEST":
      newState = Object.assign({}, previousState, {
        error: "",
        submittingRequest: "Creating New Child from Bulk Action",
      });
      break;
    case "CREATE_NEW_CHILD_BULK_ACTION_SUCCESS":
      newState = Object.assign({}, previousState, {
        error: "",
        submittingRequest: "",
        createNewChildEventRequestCompleted: true,
        isSuccessfulChildCreation: true,
      });
      break;
    case "CREATE_NEW_CHILD_BULK_ACTION_FAILURE":
      newState = Object.assign({}, previousState, {
        error: "Unable to create a new child from the bulk action",
        submittingRequest: "",
        createNewChildEventRequestCompleted: false,
      });
      break;
    case "RESET_SUCCESS_MESSAGE_BULK_ACTION_CHILD_CREATION":
      newState = Object.assign({}, previousState, {
        error: "",
        isSuccessfulChildCreation: false,
        createNewChildEventRequestCompleted: false,
      });
      break;
    default:
      break;
  }

  return newState;
}
