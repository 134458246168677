import Button from "react-bootstrap/Button";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { useState } from "react";
import { BiMessageAdd } from "react-icons/bi";
import { compareDates } from "services/MomentUtils";
import "plugins/Lexical/styles.css";
import AddEditNoteModal from "./AddEditNoteModal";
import { showNotification } from "services/NotificationService";
import { Container } from "react-bootstrap";
import { isLoggedInUserAWAdmin } from "services/AWUtilityService";
import { getLoggedInUser } from "services/AuthService";
import NoteItem from "./NoteItem";

const ScrollableNotes = (props) => {
  const [notes, setNotes] = useState(props.notes);
  const entityId = props.entityId;
  const claimerUuid = props.claimedBy;
  const entitySaveUrl = props.entitySaveUrl;
  const activityStatus = props.activityStatus;
  const [totalNotes, setTotalNotes] = useState(props.totalNotesCount)
  const activityType = props.activityType;
  const activityEntityType = props.activityEntityType;
  const [showMore, setShowMore] = useState(notes.length === totalNotes ? false : true);
  const [page, setPage] = useState(0);
  const [creationDate, setCreationDate] = useState(props.sortByDate);
  const [pinnedNotesCount, setPinnedNotesCount] = useState(props.pinnedNotesCount);
  const [excludeIds, setExcludeIds] = useState(props.excludeIds);


  const pageSize = 15;

  const addNote = (note) => {
    const prePinnedNotes = notes.filter((n) => n.isPinned); // pinned notes -- sorted
    var unPinnedNotes = notes.filter((n) => !n.isPinned); // unpinned notes -- sorted

    prePinnedNotes.forEach((n) => (n.new = false)); // !new
    unPinnedNotes.forEach((n) => (n.new = false)); // !new
    note.new = true; // new note tag
    setExcludeIds([...excludeIds, note.noteId])
    setTotalNotes((prev) => prev + 1);
    setNotes([...prePinnedNotes, note, ...unPinnedNotes]);
  };

  const shouldAddNoteButtonBeDisabled = () => {
    const isAWAdmin = isLoggedInUserAWAdmin()
    const isAssignee = getLoggedInUser()?.uuid === claimerUuid;

    if (isAWAdmin || isAssignee) return false;
    return true;
  }

  const editNote = (note) => {
    const updatedNotes = notes.map((n) =>
      n.noteId === note.noteId
        ? { ...n, note: note.note, isEdited: true, new: false }
        : n
    );
    setNotes(updatedNotes);
  };


  const pinNote = (data, isPinned) => {
    setNotes(data.noteDtoList);
    setPage(0) //this method invokes a backend api call to retrive all notes in correct order with the pinned at top (if pinned) hence the page is set to zero in case if this page is used in future to load more notes
    setExcludeIds(data.excludeIds); //since this retrieves all the notes again we need to reset the excludes ids to make sure they are set to correct avlues
    data.totalElements === totalNotes ? setShowMore(false) : setShowMore(true) //need to set this again here bcz of notes being retrieved again
    if (isPinned) {
      setPinnedNotesCount(prevCount => prevCount + 1);
    } else {
      setPinnedNotesCount(prevCount => prevCount - 1);
    }
  };

  const loadMore = () => {
    const activityId = entityId;
    let date = creationDate;
    if (!creationDate) {
      date = notes[notes.length - 1].creationDate;
      setCreationDate(date);
    }
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/fetch-activity-notes/${activityId}`,
      "POST",
      { creationDate: date, page, pageSize , excludeIds : excludeIds? excludeIds : []},
      (r) => {
        if (r.data.totalElements > 0 && showMore) {
          if (notes.length + r.data.totalElements === totalNotes)
            setShowMore(false);
          setNotes([...notes, ...r.data.noteDtoList]);
          setPage(page + 1);
        } else {
          setShowMore(false);
        }
      },
      (r) => {
        showNotification({
          title: "Unable to load Activity Notes!",
          message: "SOMETHING WENT WRONG",
          position: "tr",
          type: "error",
        });
      }
    );
  };

  return (
    <Container fluid className="notes-container">
      <div className="small-label-notes">
        <div>NOTES ({notes.length})</div>
        <div className="add-notes-modal">
          <AddEditNoteModal
            toolTipText={shouldAddNoteButtonBeDisabled() ? "Claim call group to add notes" : "Add Note"}
            icon={
              <BiMessageAdd style={{ color: "#FFAA3B" }} />
            }
            entityId={entityId}
            newNote={addNote}
            entitySaveUrl={entitySaveUrl}
            openModalButtonDisabled={shouldAddNoteButtonBeDisabled()}
            openModalButtonClassName={"create-note-btn"}
          />
        </div>
      </div>
      <div className="rm-notes-activity">
        {notes.length > 0 ? (
          <>
            {notes.map((note, idx) => {
              if (idx < notes.length) {
                return (
                  <NoteItem
                    key={note.noteId}
                    claimedBy={claimerUuid}
                    pinNote={pinNote}
                    noteEditStatus={activityStatus}
                    pinnedNotesCount={pinnedNotesCount}
                    updateNote={editNote}
                    entityId={entityId}
                    entitySaveUrl={entitySaveUrl}
                    note={note}
                    clipped={false}
                  />
                );
              } else return null;
            })}
            {showMore ? (
              <Button variant="link" size="sm" onClick={loadMore} className="btn-block" data-testid="btn-activity-show-more-notes">
                Show More
              </Button>
            ) : (
              <span className="rm-horizontal-center rm-switch-notes-end" data-testid="text-activity-no-more-notes">NO MORE NOTES</span>
            )}
          </>
        ) : (
          <div 
            className="rm-horizontal-center text-muted tiny-top-spacer rm-switch-notes-end" 
            data-testid="" 
            text-activity-no-notes="true"
          >
            NO NOTES
          </div>
        )}
      </div>
    </Container>
  );
};

export default ScrollableNotes;
