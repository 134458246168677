import React from "react";
import DismissableModal from "components/DismissableModal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActivityHistory from "./ActivityHistory";

const ExpandActivityHistory = (props) => {
  return (
    <DismissableModal
      openModalButtonText="Activity History"
      openModalButtonStyle="dark"
      openModalButtonClassName="btn-text-white btn-block"
      title="Activity History"
      size="lg"
      centered
      openModalButtonTestId="btn-expand-activity-history"
    >
      <Container>
        <Row>
          <Col md={12}>
            <ActivityHistory activityId={props.activityId} />
          </Col>
        </Row>
      </Container>
    </DismissableModal>
  );
};

export default ExpandActivityHistory;
