import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import DismissableModal from 'components/DismissableModal';
import { MdDeleteForever } from 'react-icons/md';
import ToggleIconLinkButton from 'components/ToggleIconLinkButton';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { getErrorMessage } from 'services/UtilityService';
import { showNotification } from 'services/NotificationService';

// dsoId, medName, dsgId, onSuccessDelete, codexId
class DynamicSwitchOptionDeleteModal extends Component {
  state = {
    modalRefId: `DynamicSwitchOptionDeleteModal_${this.props.dsoId}`
  }

  static getDerivedStateFromProps(props, state) {
    if (state.modalRefId.indexOf(props.dsoId) > -1) {
      return null;
    }
    return {
      modalRefId: `DynamicSwitchOptionDeleteModal_${props.dsoId}`
    }
  }

  onSuccess = () => {
    this.props.onSuccessDelete();

    // dispatch success notification
    showNotification({
      title: `Drug Deleted!`,
      message: `Drug Info deleted successfully`,
      position: "tr",
      type: "success"
    });
  }

  onError = (r) => {
    // dispatch failure notification
    showNotification({
      title: "Something went wrong!",
      message:
        getErrorMessage(r.data) ||
        getErrorMessage("UNABLE_TO_DELETE_DYNAMIC_SWITCH_OPTION"),
      position: "tr",
      type: "error"
    });
  }

  handleDeleteItem = () => {
    const { codexId, dsgId, dsoId } = this.props;
    sendAuthenticatedAsyncRequest(
      `/cat/codex-version/${codexId}/dynamic-switch-groups/${dsgId}/dynamic-switch-options/${dsoId}`,
      "DELETE",
      null, this.onSuccess, this.onError);
    this.closeModal();
  }

  openModal = () => {
    let { modalRefId } = this.state;
    this.refs && this.refs[modalRefId] ? this.refs[modalRefId].handleShow() : null;
  }

  closeModal = () => {
    let { modalRefId } = this.state;
    this.refs && this.refs[modalRefId] ? this.refs[modalRefId].handleClose() : null;
  }

  render() {
    const { dsoId, medName } = this.props;
    const { modalRefId } = this.state;
    const modalOpener = <ToggleIconLinkButton
      key={`delete-${dsoId}`}
      shouldDisplayFirstTag={true}
      toggleButton={this.openModal}
      FirstIcon={MdDeleteForever}
      firstTag={"Delete"}
      linkClassName={'delete-link'}
      SecondIcon={null}
      secondTag={null}
    />;
    const customFooterContent = <div>
      <Button key={`dynamic-switch-option-delete-modal-cancel-${dsoId}`} id='dynamic-switch-option-delete-modal-cancel-button' variant="outline-dark" onClick={this.closeModal}>Cancel</Button>
      <Button
        key={`dynamic-switch-option-delete-modal-${dsoId}`}
        id='dynamic-switch-option-modal-delete-button'
        className="left-spacer"
        variant="danger"
        onClick={this.handleDeleteItem}>
        Delete
      </Button>
    </div>;
    const title = "Delete Drug Option?";
    return (
      <DismissableModal
        ref={modalRefId}
        key={dsoId}
        customFooterContent={customFooterContent}
        openModalButton={modalOpener}
        title={title}
        id='dynamic-switch-option-delete-modal-dismissable-modal'
      >
        <p >
          Are you sure you want to delete the drug option <strong >{medName}</strong>?
        </p>

      </DismissableModal>
    );
  }
}

export default DynamicSwitchOptionDeleteModal;