import React, { useState, useEffect } from "react";

import Tip from "components/Tip";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { ColumnTypes, FilterTypes} from "commons/table/Constants";
import ServerSideReactTable from "commons/table/ServerSideReactTable";

const DEFAULT_PAGE_SIZE = 7;

export default function PharmaciesList(props) {
  const { selectedPharmacy, onSelect } = props;
  const [pharmacies, setPharmacies] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [listState, setListState] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sorted: [],
    filtered: [],
  });

  const updateData = (state) => {
    setListState(state);
    fetchPharmacies(state);
  };

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    const state = {
      page: pageNo,
      pageSize: pageSize,
      sorted: sortBy ? sortBy : [],
      filtered: filterValues,
      resized: [],
      expanded: {}
    }
    updateData(state);
  }

  const fetchPharmacies = (state) => {
    setLoading(true);
    sendAuthenticatedAsyncRequest(
      `/contact-management/pharmacies`,
      "POST",
      state,
      (r) => {
        setTotalPages(r.data.totalPages);
        setPharmacies(r.data.records);
        setTotalElements(r.data.totalElements);
        setLoading(false);
      },
      (r) => {
        setTotalPages(0);
        setPharmacies([]);
        setTotalElements(0);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchPharmacies(listState);
  }, []);

  const columns = [
    {
      header: 'Pharmacy Name',
      accessor: 'pharmacyName',
      accessorKey: 'pharmacyName',
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Pharmacy Npi",
      accessor: "pharmacyNpi",
      accessorKey: "pharmacyNpi",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Pharmacy Phone",
      accessor: "pharmacyPhone",
      accessorKey: "pharmacyPhone",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Pharmacy Fax",
      accessor: "pharmacyFax",
      accessorKey: "pharmacyFax",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
    {
      header: "Pharmacy Address",
      accessor: "pharmacyAddressLine1",
      accessorKey: "pharmacyAddressLine1",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.WrapTextOnNoSpace,
      filterType: FilterTypes.SearchableInput,
    },
  ]
  return (
    <div className="list-container">
      <ServerSideReactTable
        currentPage={listState.page}
        overrideRowId={'pharmacyId'}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={totalPages}
        onUpdateTableData={onUpdateTable}
        columnDefination={columns}
        data={pharmacies}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        addRowSelection={false}
        loading={loading}
        totalRecords={totalElements}
        enableMultiRowSelection={false}
        pageSizeOptions={[5, 7, 10]}
        onRowClick={(rowInfo) => {
          if (rowInfo !== undefined) {
            onSelect(rowInfo.original);
          }
        }}
        trStyle={(rowInfo) => {
          return (
            {
              background:
                rowInfo &&
                  rowInfo.original.pharmacyInfoUuid ===
                  selectedPharmacy.pharmacyInfoUuid
                  ? "#c6e4f9"
                  : "#ffffff",
              color:
                rowInfo &&
                  rowInfo.original.pharmacyInfoUuid ===
                  selectedPharmacy.pharmacyInfoUuid
                  ? "#008df1"
                  : "",
            })
        }}
      />
      <Tip>Tip: Hold shift when sorting to multi-sort and disable sort!</Tip>
    </div>
  );
}
