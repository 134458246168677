export const CycleConstants = {

    statuses: [
     {key: '', value: 'ALL'},
      { key: 'CLOSED', value: 'CLOSED'},
      {key: 'OPEN', value: 'OPEN'},
      {key: 'PENDING', value: 'PENDING'},
      {key: 'IN_PROGRESS', value: 'IN_PROGRESS'},
      {key: 'ERROR', value: 'ERROR'},
      {key: 'IMMEDIATE', value: 'IMMEDIATE'},
    ],
    
  ALL_FILTER:{ id: 'status', value: 'ALL'},
  DEFAULT_CYCLE_LIST_FILTERS:[{ id: 'status', value:'', isdefault: true }],
  DEFAULT_CYCLE_LIST_FILTERS_FRONTEND:{ id: 'status', value:'ALL', isdefault: true }
  
}
